import React, { useEffect, useState } from "react"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import algoliasearch from "algoliasearch/lite"
import {
  Configure,
  Hits,
  InstantSearch,
  SearchBox,
} from "react-instantsearch-hooks-web"
import { useDispatch, useSelector } from "react-redux"
import { PageType } from "../../../Model/ProjectManagement"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import ProjectKOLItem from "./ProjectKOLItem"
import { Trans, useTranslation } from "react-i18next"
import { isLocalhost } from "../../../Utils/utiltyHelper"
import classNames from "classnames"
import { KOLType } from "../../../Model/KOL"
import { getChatroomKolList } from "../../../HelpingFunction/ProjectManagement/ProjectDBHelper"
import { isAuth } from "../../../Utils/FirebaseUtils"
import { SearchClient } from "algoliasearch"

type HitProps = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  hit: AlgoliaHit<KOLType>
}

const Hit = ({ hit }: HitProps) => {
  return <ProjectKOLItem data={hit} />
}

const KOLListProject = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const isAllKOL = useSelector((state: any) => {
    return state.ProjectManager.allKOLsTab
  })

  const setPage = (page: PageType) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_PROJECT_PAGE,
      data: page,
    })
  }
  const setIsExpanded = (expand: boolean) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_PROJECT_PAGE_EXPAND,
      data: expand,
    })
  }
  const setIsAllKOL = (state: boolean) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_ALL_KOLS_TAB,
      data: state,
    })
  }

  const [chatKOLs, setChatKOLs] = useState<KOLType[]>([])
  const [isLoadedKOLs, setIsLoadedKOLs] = useState<boolean>(false)
  const [searchClient, setSearchClient] = useState<SearchClient | null>(null)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const appId: string =
    window.location.hostname === "localhost"
      ? process.env.REACT_APP_ALGOLIA_TEST_APP_ID
      : process.env.REACT_APP_ALGOLIA_APP_ID

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const apiKey: string =
    window.location.hostname === "localhost"
      ? process.env.REACT_APP_ALGOLIA_TEST_API_KEY
      : process.env.REACT_APP_ALGOLIA_API_KEY

  const LOCAL_HOST_ID =
    isLocalhost() && process.env.REACT_APP_LOCALHOST_ID
      ? process.env.REACT_APP_LOCALHOST_ID
      : ""

  useEffect(() => {
    if (isAuth(auth)) {
      getAllChatKOLs()
    }
  }, [auth])

  useEffect(() => {
    let newSearchClient = algoliasearch(appId, apiKey)
    setSearchClient(newSearchClient as SearchClient)
  }, [])

  const getAllChatKOLs = async () => {
    const kols = await getChatroomKolList(auth.uid)

    if (kols.success) {
      setIsLoadedKOLs(true)

      setChatKOLs(kols.kols)
    }
  }

  return (
    <div className=" w-full relative h-full md:max-h-[90svh] max-h-[65svh]">
      {searchClient !== null && (
        <InstantSearch searchClient={searchClient} indexName="KOLCombine">
          {/*
        Nav with search bar
        */}
          <Configure
            ruleContexts={[]}
            filters={
              LOCAL_HOST_ID
                ? "isLook4kolReg:true AND isPublish:true AND localhost_id:" +
                  LOCAL_HOST_ID
                : "isLook4kolReg:true AND isPublish:true"
            }
          />
          <div className="flex flex-col gap-2 h-full">
            <div className="sticky h-16 top-0 px-1 w-full bg-white z-10 border-b">
              <button
                onClick={() => {
                  setIsExpanded(false)
                  setPage(PageType.Main)
                }}
                className="font-medium text-lg my-2 btn btn-ghost">
                <span className="cursor-pointer mr-2 ">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </span>
                <Trans>ProjectOptionBar.select-kol</Trans>
              </button>
            </div>

            <div className="mx-auto tabs tabs-boxed w-fit my-2">
              <a
                onClick={() => setIsAllKOL(true)}
                className={classNames("text-sm tab", isAllKOL && "tab-active")}>
                <Trans>ProjectOptionBar.all-kol</Trans>
              </a>
              <a
                onClick={() => setIsAllKOL(false)}
                className={classNames("tab", !isAllKOL && "tab-active")}>
                <Trans>ProjectOptionBar.chat-kol</Trans>
              </a>
            </div>

            {isAllKOL && (
              <div className=" rounded-xl shadow border border-gray-100 p-1 mb-2 mx-2 ">
                <SearchBox
                  autoFocus={true}
                  placeholder={t("ProjectOptionBar.search-kol") as string}
                />
              </div>
            )}

            <div className="flex-grow overflow-y-auto flex-col gap-2 px-2">
              {isAllKOL && <Hits hitComponent={Hit} />}
              {isLoadedKOLs &&
                !isAllKOL &&
                chatKOLs.map((k, index) => {
                  return <ProjectKOLItem key={index} data={k} />
                })}
            </div>

            <div className="sticky bottom-0 bg-white text-center w-full px-2 py-2">
              <button
                onClick={() => {
                  setIsExpanded(false)
                  setPage(PageType.Main)
                }}
                className="btn btn-info  w-full mx-auto ">
                <Trans>ProjectOptionBar.done</Trans>
              </button>
            </div>
          </div>
        </InstantSearch>
      )}
    </div>
  )
}

export default KOLListProject
