import React from "react"
import { Trans } from "react-i18next"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { useDispatch, useSelector } from "react-redux"
import { CHATROOM_TYPE } from "../../../Enum/APP_TYPE"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ChatHelper: React.FC = (props) => {
  const dispatch = useDispatch()

  const chatroomInfo = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomInfo
  })
  const onHelpDocDirect = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.DIRECT_TO_HELP_DOC,
      data: state,
    })
  }

  if (chatroomInfo.type === CHATROOM_TYPE.CHATGPT) {
    return (
      <div className="bg-red-50 text-red-default p-4 xl:p-6 rounded-b-2xl">
        <div className="col-span-1">
          <div className="font-bold text-2xl">
            <Trans>ChatText.hello-gpt</Trans>
          </div>
          <div className=" mt-2">
            <div className="badge badge-accent mr-1">BETA</div>
            <Trans>ChatText.gpt-des</Trans>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="bg-red-50 text-red-default p-4 xl:p-6 rounded-b-2xl">
      <div>
        <div className="flex flex-row gap-6 items-center  ">
          <div className="w-12 h-12 flex items-center justify-center rounded-full text-bold bg-red-default text-white text-center my-auto text-4xl">
            ?
          </div>
          <div className="font-bold text-2xl">
            <Trans>ChatText.hello</Trans>
          </div>
        </div>
        <div className="text-base font-medium my-2">
          <Trans>ChatText.des</Trans>
        </div>
        <div className="col-span-1">
          <button
            className="btn btn-primary w-full rounded-xl my-1"
            onClick={() =>
              onHelpDocDirect("/contract/how-does-the-contract-work")
            }>
            <Trans>ChatText.btn-1</Trans>
          </button>
          {/* <button
            className="btn btn-primary w-full my-1"
            onClick={() => onHelpDocDirect("/contract-and-chat/payment")}>
            <Trans>ChatText.btn-2</Trans>
          </button> */}
        </div>
      </div>
    </div>
  )
}
export default ChatHelper
