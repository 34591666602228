import { Component } from "react"
import LogoImage from "../../Assets/logo_top_nobg.png"
import bannerBg from "../../Assets/t8mxq-q5u25.png"
import { Trans } from "react-i18next"
import { withRouter } from "react-router-dom"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      errorMsg: "",
    }
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    }
  }

  componentDidCatch(error, errorInfo) {
    console.log("Logging", error, errorInfo)
  }

  render() {
    const sendMail = () => {
      window.location = "mailto:team@look4kol.com"
    }

    const backToHome = () => {
      const lang = localStorage.getItem("i18n-lang")
      window.location.pathname = "/" + lang
    }

    const refresh = () => {
      location.reload()
    }

    if (this.state.hasError) {
      return (
        <div>
          <div className={"w-full mx-auto"}>
            <div
              className="md:pt-24 md:pl-16 min-h-screen bg-base-200"
              style={{
                backgroundPosition: "0px -180px",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${bannerBg})`,
                backgroundSize: "cover",
                maxWidth: "1920px",
              }}>
              <div className="hero-content flex-col lg:flex-row gap-8 md:gap-0 p-4">
                <img src={LogoImage} className="max-w-sm mr-4" />
                <div>
                  <h1 className="text-5xl font-bold">
                    <Trans>ErrorPage.error-404</Trans>
                  </h1>
                  <h2 className="text-3xl">
                    <Trans>ErrorPage.oops</Trans>
                  </h2>
                  <p className="py-6">
                    <Trans>ErrorPage.error-desc</Trans>
                  </p>

                  <button onClick={() => refresh()} className="btn btn-primary">
                    <Trans>ErrorPage.refresh</Trans>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}

export default withRouter(ErrorBoundary)
