import React from "react"
import classes from "./Spinner.module.css"
const Spinner = (props) => {
  return (
    <div
      className={classes.loader}
      style={
        props.hasOwnProperty("size") && typeof props.size === "number"
          ? { width: props.size * 15 + "px", height: props.size * 15 + "px" }
          : { width: 15 + "px", height: 15 + "px" }
      }></div>
  )
}
export default Spinner
