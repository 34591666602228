import {
  IG_USER_DATA,
  IG_USER_PROFILE_DATA,
  NEW_KOL,
} from "../../Enum/LOGIN_PAGE_TYPE"
import { getAPIPath } from "../../Utils/HelpingFunction"
import KOL, { KOLDefault, KOLType } from "../../Model/KOL"
import { CaseStudy } from "../../Model/CaseStudy"
import { Plan } from "../../Model/Plan"
import { Balance, Bank } from "../../Model/Bank"
import {
  createNewProduct,
  updateProduct,
} from "../EditKOLProfile/EditKOLProfile"
import { returnIdTokenResult } from "../../Utils/FirebaseUtils"
import {
  collection,
  deleteDoc,
  doc,
  documentId,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore"
import firebaseApp from "../../Config/firebase"
import { KOLFollowersPercentage } from "../../Model/KOLFollowersPercentage"
import {
  KOLNeo4jSearchType,
  KOLSearchType,
} from "../../Redux/Reducer/ToolsManager"
import { KOLCombine, KOLCombineWithAvatar } from "../../Model/KOLCombine"
import { error } from "console"

const db = getFirestore(firebaseApp)

export interface kolCrawlerFollowerData {
  numOfPosts: number
  numOfFollowers: number
  numOfFollowing: number
  averageEngagementRate: number
  imagesList: []
}

export const kolCrawlerFollowerDataDefault: kolCrawlerFollowerData = {
  numOfPosts: 0,
  numOfFollowers: 0,
  numOfFollowing: 0,
  averageEngagementRate: 0,
  imagesList: [],
}

const requestKOLCrawler = (
  uid: string
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await fetch(getAPIPath("/api/kol/" + uid + "/ig/crawl"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(async (finalResponse) => {
        return resolve({ success: true })
      })
  })
}

const setPayoutRequest = (
  uid: string,
  withdrawAmount: number,
  bankDetail: Bank,
  agencyID?: string,
  isFive?: boolean
): Promise<
  | {
      success: true
      message: string
    }
  | {
      success: false
      message: string
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      //send payout request
      let headerInfo: any = {
        "Content-Type": "application/json",
        idToken: res.token,
      }
      if (agencyID) {
        headerInfo = {
          ...headerInfo,
          agencyID: agencyID,
        }
      }

      await fetch(getAPIPath("/api/kol/" + uid + "/payout"), {
        method: "POST",
        headers: headerInfo,
        body: JSON.stringify({
          withdrawAmount: withdrawAmount,
          bankDetail: bankDetail,
          isFive: isFive ? isFive : false,
        }),
      })
        .then((res) => res.json())
        .then(async (finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: finalResponse.success,
              message: "",
            })
          }
          return resolve({
            success: finalResponse.success,
            message: finalResponse.message,
          })
        })
    })
  })
}

const getKOLPlan = (
  uid: string,
  planID: string
): Promise<
  | {
      success: true
      data: Plan
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "KOL", uid, "Plan", planID))
      .then((doc: any) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: {
              id: doc.id,
              ...doc.data(),
            },
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err: any) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const getKOLPlans = (
  uid: string,
  isKOL: boolean
): Promise<
  | {
      success: true
      data: Plan[]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "KOL", uid, "Plan")

    // if (!isKOL) {
    //   dbRef = query(dbRef, where("isVisible", "==", true))
    // }

    await getDocs(query(dbRef, limit(3)))
      .then((docs: any) => {
        if (!docs.empty) {
          let allPlan: Plan[] = []
          docs.forEach((doc: any) => {
            allPlan.push({
              id: doc.id,
              ...doc.data(),
            } as Plan)
          })
          return resolve({
            success: true,
            data: allPlan,
          })
        }
        return resolve({
          success: true,
          data: [],
        })
      })
      .catch((err: any) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const getKOLWallet = (
  uid: string,
  agencyID?: string
): Promise<
  | {
      success: true
      data: Balance
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      let headerInfo: any = {
        "Content-Type": "application/json",
        idToken: res.token,
      }
      if (agencyID) {
        headerInfo = {
          ...headerInfo,
          agencyID: agencyID,
        }
      }
      await fetch(getAPIPath("/api/kol/" + uid + "/balance"), {
        method: "GET",
        headers: headerInfo,
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
              data: finalResponse.data,
            })
          }
          return resolve({
            success: false,
          })
        })
    })
  })
}

const setWalletBank = (
  uid: string,
  bankDetail: Bank,
  agencyID?: string
): Promise<
  | {
      success: true
      data: Balance
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      let headerInfo: any = {
        "Content-Type": "application/json",
        idToken: res.token,
      }
      if (agencyID) {
        headerInfo = {
          ...headerInfo,
          agencyID: agencyID,
        }
      }
      await fetch(getAPIPath("/api/kol/" + uid + "/bank"), {
        method: "PATCH",
        headers: headerInfo,
        body: JSON.stringify({
          bankDetail: bankDetail,
        }),
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
              data: finalResponse.data,
            })
          }
          return resolve({
            success: false,
          })
        })
    })
  })
}

const getWalletBank = (
  uid: string,
  agencyID?: string
): Promise<
  | {
      success: true
      data: Bank
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      let headerInfo: any = {
        "Content-Type": "application/json",
        idToken: res.token,
      }
      if (agencyID) {
        headerInfo = {
          ...headerInfo,
          agencyID: agencyID,
        }
      }
      await fetch(getAPIPath("/api/kol/" + uid + "/bank"), {
        method: "GET",
        headers: headerInfo,
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
              data: finalResponse.data as Bank,
            })
          }
          return resolve({
            success: false,
          })
        })
    })
  })
}

const setCaseStudy = (
  kolID: string,
  cs: CaseStudy
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await setDoc(doc(db, "KOL", kolID, "CaseStudy", cs.id), cs)
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const updateCaseStudy = (
  kolID: string,
  cs: CaseStudy
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await updateDoc(doc(db, "KOL", kolID, "CaseStudy", cs.id), cs)
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const deleteCaseStudy = (
  kolID: string,
  cs: CaseStudy
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await deleteDoc(doc(db, "KOL", kolID, "CaseStudy", cs.id))
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const editKOLPlan = async (
  kolID: string,
  plan: Plan,
  ownID?: string
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    let id = plan.id
    let isPriceChange = plan.isPriceChange
    delete plan.isPriceChange

    const updateProductState = await updateProduct(
      plan,
      kolID,
      isPriceChange,
      ownID
    )
    if (updateProductState.success) {
      await updateDoc(doc(db, "KOL", kolID, "Plan", id), {
        ...plan,
        priceID: isPriceChange
          ? updateProductState.data.newPrice.id
          : plan.priceID,
      })
        .then((res) => {
          return resolve({
            success: true,
          })
        })
        .catch((err) => {
          console.log(err)
          return resolve({
            success: false,
          })
        })
    }
  })
}

const removeKOLPlan = (
  kolID: string,
  planID: string
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await deleteDoc(doc(db, "KOL", kolID, "Plan", planID))
      .then((re) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const setKOLPlan = (
  kolID: string,
  plan: Plan,
  ownID?: string
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    let p = plan
    let id = p.id
    delete p.isNew
    delete p.isPriceChange

    const createProductState = await createNewProduct(plan, kolID, ownID)
    if (createProductState.success) {
      await setDoc(doc(db, "KOL", kolID, "Plan", id), {
        ...p,
        priceID: createProductState.data.newPrice.id,
      })
        .then((res) => {
          return resolve({
            success: true,
          })
        })
        .catch((err) => {
          console.log(err)
          return resolve({
            success: false,
          })
        })
    }
  })
}

const saveKOL = (
  requestUID: string,
  kolProfile: KOLType,
  plans?: Plan[],
  caseStudies?: CaseStudy[],
  igUserData?: IG_USER_DATA,
  viewID?: string
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    try {
      // Save KOL Plan
      console.log("saving kol plan")
      const planPromise: {
        success: boolean
      } = await new Promise((resolve1) => {
        // let hasPlanError =
        //   typeof plans?.find((e) => e.hasError) !== "undefined"

        if (Array.isArray(plans)) {
          let allPromise: any = []
          plans.map(async (e: Plan) => {
            console.log("plan", e)
            if (e.isNew) {
              allPromise.push(await setKOLPlan(kolProfile.id, e, viewID))
            } else if (e.isRemoved) {
              allPromise.push(await removeKOLPlan(kolProfile.id, e.id))
            } else {
              allPromise.push(await editKOLPlan(kolProfile.id, e, viewID))
            }
          })

          Promise.all(allPromise).then((res) => {
            console.log("res", res)
            console.log("done kol plan")
            resolve1({
              success: true,
            })
          })
        } else {
          resolve1({
            success: false,
          })
        }
      })

      //case study
      console.log("saving case study")
      const caseStudyPromise: {
        success: boolean
      } = await new Promise((resolve1) => {
        if (Array.isArray(caseStudies)) {
          let allPromise: any = []
          caseStudies.map(async (e: CaseStudy) => {
            let eAction = e.action
            let cs = { ...e }
            delete cs.action
            if (eAction === "add") {
              allPromise.push(await setCaseStudy(kolProfile.id, cs))
            } else if (eAction === "update") {
              allPromise.push(await updateCaseStudy(kolProfile.id, cs))
            } else if (eAction === "delete") {
              allPromise.push(await deleteCaseStudy(kolProfile.id, cs))
            }
          })

          Promise.all(allPromise).then((res) => {
            console.log("done case study")
            return resolve1({
              success: true,
            })
          })
        } else {
          return resolve1({
            success: true,
          })
        }
      })

      if (planPromise.success && caseStudyPromise.success) {
        returnIdTokenResult().then(async (res) => {
          console.log("done everything")

          let headerInfo: any = {
            "Content-Type": "application/json",
            idToken: res.token,
          }
          if (viewID) {
            headerInfo = {
              ...headerInfo,
              agencyID: viewID,
            }
          }
          await fetch(getAPIPath("/api/kol/" + kolProfile.id), {
            method: "PATCH",
            headers: headerInfo,
            body: JSON.stringify({
              ...kolProfile,
              igUserData: igUserData,
            }),
          })
            .then((res) => res.json())
            .then((finalResponse) => {
              if (finalResponse.success) {
                return resolve({
                  success: true,
                })
              }
              return resolve({
                success: false,
              })
            })
            .catch((err) => console.log(err))
        })
      }
    } catch (e) {
      return resolve({
        success: false,
      })
    }
  })
}

/**
 * Get kol profile by kolID
 * @param kolID , allow uid or username
 */
const getKOL = (
  kolID: string
): Promise<
  | {
      success: true
      data: KOLType
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "KOL", kolID))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: {
              ...doc.data(),
              id: doc.id,
            } as KOLType,
          })
        }

        getDocs(
          query(collection(db, "KOL"), where("userName", "==", kolID), limit(1))
        ).then((docs) => {
          if (!docs.empty) {
            return resolve({
              success: true,
              data: {
                ...docs.docs[0].data(),
                id: docs.docs[0].id,
              } as KOLType,
            })
          }
          //if profile not found or not yet published
          return resolve({
            success: false,
          })
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

/**
 * Set KOL for registration
 * @param {string} uid - firebase uid
 * @param {NEW_KOL} data - data will be store in KOL/{:uid}
 */

const setKOL = (
  uid: string,
  data: NEW_KOL
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await setDoc(doc(db, "KOL", uid), data)
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => console.log(err))
  })
}

/**
 * Create a new KOL account using email and password
 * @param {NEW_KOL} newKOLUser - data for new kol user
 * @param {string} password - user password
 * @param {boolean} isVerified - whether this user is isVerified by ig login
 * @param {IG_USER_DATA} igUserData : [Optional] only while the kol connected the account with FB api
 */

const createKOL = (
  newKOLUser: NEW_KOL,
  password: string,
  isVerified: boolean,
  igUserData?: IG_USER_DATA
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await fetch(getAPIPath("/api/kol"), {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...newKOLUser,
        password: password,
        igUserData: igUserData,
        isVerified: isVerified,
      }),
    })
      .then((res) => res.json())
      .then((finalResponse) => {
        return resolve(finalResponse)
      })
  })
}

/**
 * Check whether this id is exists or not
 * @param {string} id - kol id
 * @return {Promise} - whether the kol is exists
 *
 */

const checkKOLIDExist = (
  id: string
): Promise<{
  success: boolean
  exists: boolean
}> => {
  return new Promise(async (resolve, reject) => {
    await fetch(getAPIPath("/api/kol/exists"), {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => res.json())
      .then((finalResponse) => {
        return resolve(finalResponse)
      })
  })
}

const getKOLFollowerGroup = async (
  kolID: string
): Promise<{
  success: boolean
  data: IG_USER_PROFILE_DATA
}> => {
  return new Promise(async (resolve) => {
    await fetch(getAPIPath("/api/statistics/kol/" + kolID + "/igPreview"), {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((finalResponse) => {
        return resolve(finalResponse)
      })
  })
}
const getKOLUserName = (
  uid: string
): Promise<
  | {
      success: true
      userName: string
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    fetch(getAPIPath("/api/kol/" + uid + "/username"), {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((finalResponse) => {
        if (finalResponse.success) {
          resolve({
            success: true,
            userName: finalResponse.userName,
          })
        } else {
          resolve({
            success: false,
          })
        }
      })
      .catch((err) => console.log(err))
  })
}

const getKOLDataFromCrawler = (
  uid: string,
  username: string
): Promise<
  | {
      success: true
      data: kolCrawlerFollowerData
    }
  | {
      success: false
      status: number
    }
> => {
  return new Promise((resolve) => {
    if (username.length > 0) {
      returnIdTokenResult().then(async (res) => {
        fetch(
          getAPIPath(
            "/api/admin/crawler/kol/" +
              uid +
              "?" +
              new URLSearchParams({
                username: username,
              })
          ),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              idToken: res.token,
            },
          }
        )
          .then((res) => res.json())
          .then((finalResponse) => {
            if (finalResponse.success) {
              resolve({
                success: true,
                data: finalResponse.data,
              })
            } else {
              resolve({
                success: false,
                status: finalResponse.status,
              })
            }
          })
      })
    } else {
      return resolve({
        success: false,
        status: 403,
      })
    }
  })
}

/**
 * get one case study by id from KOL
 * @param {string} uid - firebase uid
 * @param {string} caseID - case study id
 */

const getCaseStudy = (
  uid: string,
  caseID: string
): Promise<
  | {
      success: true
      data: CaseStudy
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "KOL", uid, "CaseStudy", caseID))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: {
              id: doc.id,
              ...doc.data(),
            } as CaseStudy,
          })
        }
      })
      .catch((err) => console.log(err))
  })
}

/**
 * get case studies from KOL
 * @param {string} uid - firebase uid
 * @param {boolean} isKOL - whether the request user is the the kol same as the uid or not
 */

const getCaseStudies = (
  uid: string,
  isKOL: boolean,
  isEditPage: boolean
): Promise<
  | {
      success: true
      data: CaseStudy[]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "KOL", uid, "CaseStudy")
    // if (isKOL) {
    //   dbRef = dbRef.where("isVisible", "==", true)
    // }

    if (!isEditPage) {
      dbRef = query(dbRef, where("isPublish", "==", true))
    }

    await getDocs(dbRef)
      .then((docs: any) => {
        if (!docs.empty) {
          let caseStudies: CaseStudy[] = []
          docs.forEach((doc: any) => {
            caseStudies.push({
              id: doc.id,
              ...doc.data(),
            } as CaseStudy)
          })
          return resolve({
            success: true,
            data: caseStudies,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err: any) => console.log(err))
  })
}

const addToConditionArr = (con: object): Record<any, any> => {
  let condition = {}
  Object.keys(con).map((k: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (con[k] && con[k] !== "") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      condition[k] = con[k].toString()
    }
  })
  return condition
}

const getKOLFollowerPercentage = (
  userName: string
): Promise<
  | {
      success: true
      data: KOLFollowersPercentage[]
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(
        getAPIPath(
          "/api/search/kol/percentage?" +
            new URLSearchParams({
              uid: "",
              limit: "",
              userName: userName as string,
              isFace: "",
              gender: "",
              targetArea: "",
              jobType: "",
              genreCategory: "",
              genre: "",
              min: "",
              max: "",
              orderBy: "",
              hasData: "",
              focusPercentage: "",
              filters: "",
              page: "",
            })
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            idToken: res.token,
          },
        }
      )
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
              data: finalResponse.data[0]?.percentages,
            })
          }
          return resolve({
            success: true,
            data: [],
          })
        })
    })
  })
}

/**
 * get promoted kol in the homepage
 */

const getPromotedKOL = (): Promise<
  | {
      success: true
      data: KOL[]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let docRef = doc(db, "Utils", "Homepage")

    await getDoc(docRef)
      .then((doc) => {
        let kols: KOL[] = []
        let allPromise: Promise<any>[] = []
        if (doc.exists()) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const kolList = doc.data().promotedKOLList
          if (kolList && Array.isArray(kolList)) {
            kolList.map((kolID: string) => {
              allPromise.push(
                new Promise(async (resolve1) => {
                  await getKOL(kolID)
                    .then((result) => {
                      if (result.success) {
                        kols.push(result.data)
                        return resolve1(null)
                      }
                      return resolve1(null)
                    })
                    .catch((err) => console.log(err))
                })
              )
            })

            Promise.all(allPromise).then((result) => {
              return resolve({
                success: true,
                data: kols,
              })
            })
          }
        }
      })
      .catch((err) => console.log(err))
  })
}

const getKOLs = (
  kolIDs: string[]
): Promise<{ success: true; data: KOLType[] } | { success: false }> => {
  return new Promise(async (resolve) => {
    if (Array.isArray(kolIDs) && kolIDs.length) {
      let kolList: any[] = []
      await getDocs(
        query(collection(db, "KOL"), where(documentId(), "in", kolIDs))
      )
        .then((docs) => {
          if (!docs.empty) {
            docs.forEach((doc) => {
              kolList.push({
                ...doc.data(),
                id: doc.id,
              })
            })
            return resolve({
              success: true,
              data: kolList,
            })
          }
          return resolve({
            success: true,
            data: [],
          })
        })
        .catch((e) => {
          console.log(e)
          return resolve({
            success: false,
          })
        })
    } else {
      return resolve({
        success: false,
      })
    }
  })
}

const searchKOLAlgolia = (
  condition: KOLSearchType | object
): Promise<
  | {
      success: true
      data: KOLCombine[]
      hasMore: boolean
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(
        getAPIPath("/api/search/kol?") +
          new URLSearchParams(addToConditionArr(condition)),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            idToken: res.token,
          },
        }
      )
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
              data: finalResponse.data,
              hasMore: finalResponse.hasMore,
            })
          }
          return resolve({
            success: true,
            data: [],
            hasMore: false,
          })
        })
    })
  })
}

const searchKOLSqlite = (
  condition: KOLNeo4jSearchType
): Promise<
  | {
      success: true
      data: string[]
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(
        getAPIPath("/api/search/kol-sqlite?") +
          new URLSearchParams(addToConditionArr(condition)),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            idToken: res.token,
          },
        }
      )
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
              data: finalResponse.data,
            })
          }
          return resolve({
            success: true,
            data: [],
          })
        })
    })
  })
}

const searchKOLsAlgolia = (
  userNames: string[]
): Promise<
  | {
      success: true
      data: KOLCombine[]
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(
        getAPIPath(
          "/api/search/kols?" +
            new URLSearchParams({
              userNames: userNames.join(","),
            })
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            idToken: res.token,
          },
        }
      )
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
              data: finalResponse.data,
            })
          }
          return resolve({
            success: true,
            data: [],
          })
        })
    })
  })
}

const searchKOLIdsAlgolia = (
  ids: string[]
): Promise<
  | {
      success: true
      data: KOLCombine[]
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(
        getAPIPath(
          "/api/search/kols?" +
            new URLSearchParams({
              ids: ids.join(","),
            })
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            idToken: res.token,
          },
        }
      )
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
              data: finalResponse.data,
            })
          }
          return resolve({
            success: true,
            data: [],
          })
        })
    })
  })
}

const filterTopTypes = (
  arr: {
    success: true
    userName: string
    data: KOLCombine
  }[]
) => {
  let newFilteredArray: {
    success: true
    userName: string
    data: KOLCombine
  }[] = []
  arr.forEach((d) => {
    let newTopGenreData = {
      count: 0,
      item: d.data.topGenreID,
      percentages: d.data.topGenrePercentage,
    }
    if (
      d.data.percentages &&
      d.data.topGenreID &&
      (d.data.topGenreID.includes("12.7") || d.data.topGenreID.includes("12.8"))
    ) {
      d.data.percentages.forEach((per) => {
        if (
          per.count > newTopGenreData.count &&
          !(per.item.includes("12.7") || per.item.includes("12.8"))
        ) {
          newTopGenreData = {
            count: per.count,
            item: per.item,
            percentages: per.percentages,
          }
        }
      })
    }
    newFilteredArray.push({
      success: true,
      userName: d.userName,
      data: {
        ...d.data,
        topGenreID: newTopGenreData.item,
        topGenrePercentage: newTopGenreData.percentages,
      },
    })
  })
  return newFilteredArray
}

const compareKOLAlgolia = (
  searchUserIDs: string,
  uid: string
): Promise<
  | {
      success: true
      data: {
        success: true
        userName: string
        data: KOLCombine
      }[]
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(
        getAPIPath(
          "/api/search/kol/compare?" +
            new URLSearchParams({
              userNames: searchUserIDs,
              uid: uid,
            })
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            idToken: res.token,
          },
        }
      )
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
              data: filterTopTypes(finalResponse.data),
            })
          }
          return resolve({
            success: true,
            data: [],
          })
        })
    })
  })
}

const searchKOLsAlgoliaWithAvatar = (
  usernames: string[]
): Promise<
  | {
      success: true
      kols: KOLCombineWithAvatar[]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    try {
      const state1 = await searchKOLsAlgolia(usernames)
      if (state1.success) {
        const kolsWithAvatar: KOLCombineWithAvatar[] = await Promise.all(
          state1.data.map(async (k) => {
            const state2 = await getKOL(k.userName)
            if (state2.success) {
              return {
                ...k,
                coverImages: state2.data.coverImages,
                avatarUrl: state2.data.avatarUrl,
              }
            }
            throw new Error("Failed to get avatar")
          })
        )

        resolve({ success: true, kols: kolsWithAvatar })
      } else {
        resolve({ success: false })
      }
    } catch (error) {
      resolve({ success: false })
    }
  })
}

const searchKOLIdsAlgoliaWithAvatar = (
  ids: string[]
): Promise<
  | {
      success: true
      kols: KOLCombineWithAvatar[]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    try {
      const state1 = await searchKOLIdsAlgolia(ids)
      const kolState = await getKOLs(ids)
      if (state1.success && kolState.success) {
        const finalKOLs: KOLCombineWithAvatar[] = []
        state1.data.map((k) => {
          const currKOL = kolState.data.find((kol) => kol.id === k.id)
          if (currKOL) {
            finalKOLs.push({
              ...k,
              avatarUrl: currKOL.avatarUrl,
              coverImages: currKOL.coverImages,
            })
          } else {
            throw new Error("Failed to get avatar")
          }
        })

        resolve({ success: true, kols: finalKOLs })
      } else {
        resolve({ success: false })
      }
    } catch (error) {
      resolve({ success: false })
    }
  })
}

const fetchSimilarGenreKOLNeo4j = (
  kolID: string
): Promise<{ success: true; kols: string[] } | { success: false }> => {
  return new Promise(async (resolve) => {
    //Fetching KOLs Of Similar Genre
    await fetch(getAPIPath("/api/neo4j/similar/genre/" + kolID), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((finalResponse) => {
        if (finalResponse.success) {
          return resolve({
            success: true,
            kols: finalResponse.data,
          })
        }
        return resolve({
          success: false,
        })
      })
  })
}

const fetchSimilarDataKOLNeo4j = (
  kolID: string
): Promise<{ success: true; kols: string[] } | { success: false }> => {
  return new Promise(async (resolve) => {
    //Fetching KOLs Of Similar Genre
    await fetch(getAPIPath("/api/neo4j/similar/data/" + kolID), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((finalResponse) => {
        if (finalResponse.success) {
          return resolve({
            success: true,
            kols: finalResponse.data,
          })
        }
        return resolve({
          success: false,
        })
      })
  })
}

const returnSearchKOLsWithAvatar = (
  kols: KOLCombine[]
): Promise<
  { success: true; data: KOLCombineWithAvatar[] } | { success: false }
> => {
  return new Promise(async (resolve) => {
    if (Array.isArray(kols) && kols.length) {
      const kolIDs = kols.map((k) => k.id)
      const kolData = await getKOLs(kolIDs)

      if (kolData.success) {
        const newKOLList: KOLCombineWithAvatar[] = []
        kols.map((k) => {
          const currKOL = kolData.data.find((kol) => kol.id === k.id)
          if (currKOL) {
            newKOLList.push({
              ...k,
              avatarUrl: currKOL.avatarUrl,
              coverImages: currKOL.coverImages,
            })
          } else {
            newKOLList.push({
              ...k,
              avatarUrl: "",
            })
          }
        })
        return resolve({
          success: true,
          data: newKOLList,
        })
      }
      return resolve({
        success: false,
      })
    }
    return resolve({
      success: false,
    })
  })
}

export {
  requestKOLCrawler,
  setPayoutRequest,
  getKOLWallet,
  setWalletBank,
  getWalletBank,
  saveKOL,
  getKOL,
  getKOLPlan,
  getKOLPlans,
  setKOL,
  createKOL,
  getKOLFollowerGroup,
  checkKOLIDExist,
  getKOLUserName,
  getKOLDataFromCrawler,
  getCaseStudy,
  getCaseStudies,
  getKOLFollowerPercentage,
  getPromotedKOL,
  getKOLs,
  searchKOLAlgolia,
  searchKOLIdsAlgolia,
  searchKOLSqlite,
  searchKOLsAlgolia,
  compareKOLAlgolia,
  setCaseStudy,
  updateCaseStudy,
  searchKOLsAlgoliaWithAvatar,
  searchKOLIdsAlgoliaWithAvatar,
  fetchSimilarGenreKOLNeo4j,
  fetchSimilarDataKOLNeo4j,
  returnSearchKOLsWithAvatar,
}
