import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"

const initialState = {
  selectedItem: [],
}

const ServiceTagManager = (state = initialState, action) => {
  if (action.type === SUPPORTED_REDUX_FUNCTIONS.ADD_SERVICE_TAG) {
    return {
      ...state,
      selectedItem: state.selectedItem.concat(action.item),
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.REMOVE_SERVICE_TAG) {
    return {
      ...state,
      selectedItem: state.selectedItem.filter((e) => e.name !== action.itemID),
    }
  }

  return state
}

export default ServiceTagManager
