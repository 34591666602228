import css from "../Assets/FileExplorer/css.svg"
import html from "../Assets/FileExplorer/html.svg"
import js from "../Assets/FileExplorer/js.svg"
import pdf from "../Assets/FileExplorer/pdf.svg"
import txt from "../Assets/FileExplorer/txt.svg"
import jpg from "../Assets/FileExplorer/jpg.svg"
import png from "../Assets/FileExplorer/png.svg"
import tiff from "../Assets/FileExplorer/tiff.svg"
import bmp from "../Assets/FileExplorer/bmp.svg"
import gif from "../Assets/FileExplorer/gif.svg"
import svg from "../Assets/FileExplorer/svg.svg"
import eps from "../Assets/FileExplorer/eps.svg"
import ai from "../Assets/FileExplorer/ai.svg"
import zip from "../Assets/FileExplorer/zip.svg"
import xls from "../Assets/FileExplorer/xls.svg"
import ppt from "../Assets/FileExplorer/ppt.svg"
import avi from "../Assets/FileExplorer/avi.svg"
import mp3 from "../Assets/FileExplorer/mp3.svg"
import doc from "../Assets/FileExplorer/doc.svg"
import dll from "../Assets/FileExplorer/dll.svg"

export function getFileTypeImage(fileType) {
  switch (fileType.toLowerCase()) {
    case "css":
      return css
      break
    case "html":
      return html
      break
    case "js":
      return js
      break
    case "pdf":
      return pdf
      break
    case "txt":
      return txt
      break
    case "jpg":
      return jpg
      break
    case "jpeg":
      return jpg
      break
    case "png":
      return png
      break
    case "tiff":
      return tiff
      break
    case "bmp":
      return bmp
      break
    case "gif":
      return gif
      break
    case "svg":
      return svg
      break
    case "eps":
      return eps
      break
    case "ai":
      return ai
      break
    case "zip":
      return zip
      break
    case "xls":
      return xls
      break
    case "xlsx":
      return xls
      break
    case "ppt":
      return ppt
      break
    case "pptx":
      return ppt
      break
    case "avi":
      return avi
      break
    case "mp3":
      return mp3
      break
    case "doc":
      return doc
      break
    case "docx":
      return doc
      break
    case "dll":
      return dll
      break
    default:
      return dll
      break
  }
}

export const SupportingImageType = [
  "png",
  "jpeg",
  "jpg",
  "gif",
  "svg",
  "tiff",
  "bmp",
]
