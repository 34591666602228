import React from "react"
import { LoginModalData } from "../../Model/Modal"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from "../../Assets/icons-72.png"
import LoginForm from "../../page-components/login/LoginForm"
import { Trans } from "react-i18next"
import { LOGIN_PAGE_TYPE } from "../../Enum/LOGIN_PAGE_TYPE"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../Utils/ModalType"

const LoginModalComponent = (props: LoginModalData) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const isJobPreviewPage = location.pathname.includes("/job-preview")

  const openCustomerRegisterModal = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.REGISTER_CUSTOMER_MODAL,
    })
  }

  const redirectSignUp = () => {
    if (isJobPreviewPage) {
      props.closeModal()
      openCustomerRegisterModal()
    } else {
      let redirectObj = {
        // requiredRedirect: true,
        // path: "/partnership-program"
        registerType: LOGIN_PAGE_TYPE.CUSTOMER,
      }
      localStorage.setItem("redirectObj", JSON.stringify(redirectObj))
      history.push(
        "/" +
          (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
          "/login"
      )
      props.closeModal()
    }
  }

  const jobId =
    props.metaData && props.metaData.jobID ? props.metaData.jobID : ""

  return (
    <div className="flex flex-col w-full h-auto ">
      {/*Modal*/}
      <div className="mx-auto">
        <img className="w-16" src={logo} />
      </div>
      <div
        className="container mx-auto px-12"
        style={{
          minHeight: "150px",
          minWidth: "300px",
          maxHeight: "67vh",
        }}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <LoginForm closeModal={props.closeModal} jobID={jobId} />
      </div>

      <hr className="h-4" />

      <div className="w-full  bg-white px-2 text-center">
        <Trans>LoginModal.no-account</Trans>{" "}
        <span
          onClick={() => redirectSignUp()}
          className="text-primary font-bold cursor-pointer">
          <Trans>LoginModal.sign-up</Trans>
        </span>
      </div>
    </div>
  )
}

export default LoginModalComponent
