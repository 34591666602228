import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCopy,
  faTrash,
  faExclamationCircle,
  faReply,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, withRouter } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../../Utils/ModalType"
import {
  ASSET_TYPE,
  CHATROOM_PAGE_TYPE,
  CHATROOM_TYPE,
} from "../../../Enum/APP_TYPE"
import DivContentLoader from "../../../Component/DivContentLoader"
import {
  getChatroomAvatar,
  getChatroomTitle,
} from "../../../HelpingFunction/Chatroom/chatroomDBHelper"
import { getKOLUserName } from "../../../HelpingFunction/KOL/kolDBHelper"
import { isAuth } from "../../../Utils/FirebaseUtils"
import { ALERT_TYPE, BUTTON_TYPE } from "../../../Enum/ALERT_SYSYEM"
import CustomAlert from "../../../Component/NewAlert"
import { useTranslation } from "react-i18next"
import {
  getUserLang,
  IMGUR_THUMBNAIL_TYPE,
  returnThumbnailUrl,
} from "../../../Utils/utiltyHelper"
import { DateObj } from "../../../Model/Utilities"
import { returnChatGPTSetting } from "../../../HelpingFunction/Chatroom/chatgptHelper"
import {
  CHATGPT_SETTING_TYPE,
  CHATGPT_SETTING_TYPE_COMBINE,
  COMMEND_TYPE,
  defaultChatGPTTextSettingType,
} from "../../../Model/ChatMessage"
import classNames from "classnames"
import { CHATGPT_HELPER_TYPE } from "../../../Redux/Reducer/ChatGPTManager"
import { getAuthUID, getQuery, useQuery } from "../../../Utils/QueryHelper"

const ToolBar: React.FC = (props) => {
  let history = useHistory()
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const paramList = useSelector((state: any) => {
    return state.QueryManager.params
  })

  const chatroomInfo = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomInfo
  })

  const chatroomJob = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomJob
  })

  const isContainDraftMsg = useSelector((state: any) => {
    return state.FileExplorer.isContainDraftMsg
  })

  const hasSelectedMessage = useSelector((state: any) => {
    return state.ChatRoomInfo.hasSelectedMessage
  })

  const selectedMessage = useSelector((state: any) => {
    return state.ChatRoomInfo.selectedMessage
  })

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const selectedChatroom = useSelector((state: any) => {
    return state.ChatRoomInfo.selectedChatroom
  })

  const addDraftMsgToChat = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SHARE_DRAFT_MSG,
      draftMsg: state,
    })
  }
  const onChangeChatGPTTabType = (type: CHATGPT_HELPER_TYPE) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATGPT_TAB,
      data: type,
    })
  }

  const updateGPTCommend = (data: COMMEND_TYPE | "") => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.UPDATE_COMMEND_TYPE,
      data: data,
    })
  }

  const updateChatGPTSetting = (data: CHATGPT_SETTING_TYPE) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.UPDATE_CHATGPT_SETTING,
      data: data,
    })
  }

  const deleteMessageModal = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.DELETE_MESSAGE_MODAL,
      returnFn: state.returnFn,
      metaData: state.metaData,
    })
  }

  const [title, setTitle] = useState<string>("")
  const [avatarUrl, setAvatarUrl] = useState<string>("")
  const [currentChatRoomID, setCurrentChatRoomID] = useState<string>("")

  let params = {
    type: getQuery(paramList, "type"),
    chatroomID: getQuery(paramList, "chatroomID"),
    metaPath: getQuery(paramList, "metaPath"),
    viewAs: getQuery(paramList, "viewAs"),
  }

  useEffect(() => {
    if (isAuth(auth)) {
      if (chatroomInfo.type === CHATROOM_TYPE.ADMIN) {
        if (auth.uid === chatroomInfo.participateUID[1]) {
          setTitle("Admin")
        } else {
          getKOLUserName(chatroomInfo.participateUID[1]).then((res) => {
            if (res.success) {
              setTitle(res.userName)
            }
          })
        }
      } else if (chatroomInfo.id !== currentChatRoomID) {
        setCurrentChatRoomID(chatroomInfo.id)

        if (selectedChatroom.id) {
          if (selectedChatroom.title) {
            setTitle(selectedChatroom.title)
          }
          if (selectedChatroom.avatar) {
            setAvatarUrl(selectedChatroom.avatar)
          }
        } else {
          getChatTitle()
          getChatAvatar()
        }
      }
    }
  }, [auth, chatroomInfo])

  const getChatTitle = async () => {
    let role = ""
    let pid = ""
    if (getAuthUID(paramList) === chatroomInfo.participateUID[1]) {
      role = "kol"
      pid = chatroomInfo.participateUID[0]
    } else {
      role = "customer"
      pid = chatroomInfo.participateUID[1]
    }
    await getChatroomTitle(pid, role, chatroomInfo.type).then((res) =>
      setTitle(res.title)
    )
  }

  const getChatAvatar = async () => {
    await getChatroomAvatar(getAuthUID(paramList), chatroomInfo).then((res) => {
      if (res.success) {
        setAvatarUrl(res.avatarURL)
      }
    })
  }

  const setChatText = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_PAGE_TYPE,
      data: CHATROOM_PAGE_TYPE.CHAT_TEXT,
    })
  }

  const requestQuotation = () => {
    setChatText()
    addDraftMsgToChat({
      type: ASSET_TYPE.LINK_TEXT,
      msg: t("DefaultMessage.invite-customer-quotation"),
      path:
        "/c?chatroomID=" +
        params.chatroomID +
        "&type=contract&metaPath=" +
        params.metaPath,
    })
  }

  const hasMsg =
    hasSelectedMessage && window.innerWidth > 768 && !isContainDraftMsg

  const nonTextChatGPTMsg =
    selectedMessage.type === ASSET_TYPE.CHATGPT_IG ||
    selectedMessage.type === ASSET_TYPE.CHATGPT_IMAGE ||
    selectedMessage.type === ASSET_TYPE.IMAGE

  if (chatroomInfo.type === CHATROOM_TYPE.JOB && chatroomJob) {
    const isRequiredFillInContract =
      chatroomJob &&
      !chatroomJob.isSubmitQuotation &&
      chatroomJob.CustomerID === auth.uid

    return (
      <>
        <div className="hidden md:inline relative h-16   bg-white">
          <div className="flex flex-row items-center h-16">
            {avatarUrl && (
              <div className="pl-2">
                <img
                  className="h-10 w-10 mt-2 rounded-full object-cover"
                  src={returnThumbnailUrl(avatarUrl, IMGUR_THUMBNAIL_TYPE.s)}
                />
              </div>
            )}
            <h1
              className={classNames(
                " text-lg md:text-md pl-4 font-bold col-span-3",
                {
                  "hover:underline cursor-pointer":
                    getAuthUID(paramList) === chatroomInfo.participateUID[0],
                }
              )}
              onClick={() => {
                if (getAuthUID(paramList) === chatroomInfo.participateUID[0]) {
                  history.push("/" + getUserLang() + "/p/" + title)
                }
              }}>
              {title}
            </h1>
            <div
              className="ml-auto mr-8 tooltip tooltip-bottom"
              data-tip={t("ToolBar.create-job")}>
              <button
                onClick={() =>
                  history.push(
                    "/" +
                      getUserLang() +
                      `/c?chatroomID=${chatroomInfo.id}&type=contract&metaPath=new`
                  )
                }>
                <FontAwesomeIcon icon={faBriefcase} />
              </button>
            </div>
          </div>

          <div className=" absolute z-10 top-16 w-full">
            {isRequiredFillInContract && (
              <CustomAlert
                title={"ToolBar.request-quotation"}
                alertType={ALERT_TYPE.REQUEST}
                buttonType={{
                  type: BUTTON_TYPE.TEXT_ONLY,
                  buttonText: "ToolBar.get-quotation",
                }}
                icon={faExclamationCircle}
                buttonFn={requestQuotation}
              />
            )}
          </div>
        </div>
      </>
    )
  } else if (
    chatroomInfo.type === CHATROOM_TYPE.ADMIN ||
    chatroomInfo.type === CHATROOM_TYPE.CHATGPT
  ) {
    return (
      <>
        <div className="hidden md:inline relative h-16 grid  block bg-white">
          <h1 className=" text-lg md:text-md pl-4 mt-4 font-bold col-span-3">
            {chatroomInfo.type === CHATROOM_TYPE.CHATGPT ? "ChatGPT" : title}
          </h1>
          {hasMsg && (
            <div className=" col-span-2 md:t-0 md:absolute md:right-0 md:top-3">
              <div className="flex float-right relative">
                <button
                  className="btn btn-circle mx-2 btn-sm btn-ghost bg-gray-50 hover:bg-gray-100"
                  onClick={() => {
                    //new message don't require id field
                    let newMsg = selectedMessage
                    delete newMsg.id
                    addDraftMsgToChat({
                      ...newMsg,
                    })
                  }}>
                  <FontAwesomeIcon
                    icon={faCopy}
                    className="text-lg text-gray-500"
                  />
                </button>
                {
                  // Math.floor((new Date().valueOf() / 1000 - new Date(props.selectedMessage.createDate.seconds)) / 60) <= 10 &&
                  selectedMessage.createUserID === getAuthUID(paramList) ? (
                    <button
                      className="btn btn-circle mx-2 btn-sm btn-ghost bg-gray-50 hover:bg-gray-100"
                      disabled={
                        Math.floor(
                          (new Date().valueOf() / 1000 -
                            new Date(
                              selectedMessage.createDate.seconds
                            ).valueOf()) /
                            60
                        ) > 10
                      }
                      onClick={() => {
                        deleteMessageModal({
                          returnFn: () => {
                            console.log("done")
                          },
                          metaData: {
                            inTenMins:
                              Math.floor(
                                (new Date().valueOf() / 1000 -
                                  new Date(
                                    selectedMessage.createDate.seconds
                                  ).valueOf()) /
                                  60
                              ) <= 10,
                            chatroomID: params.chatroomID,
                            messageID: selectedMessage.id,
                          },
                        })
                      }}>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="text-lg text-gray-500"
                      />
                    </button>
                  ) : (
                    <div
                      className={classNames("z-10 w-full", {
                        "tooltip tooltip-bottom": nonTextChatGPTMsg,
                      })}
                      data-tip={t("ToolBar.text-reply-only")}>
                      <button
                        className="btn btn-circle mx-2 btn-sm btn-ghost bg-gray-50 hover:bg-gray-100"
                        disabled={nonTextChatGPTMsg}
                        onClick={() => {
                          onChangeChatGPTTabType(CHATGPT_HELPER_TYPE.SETTING)
                          updateGPTCommend(COMMEND_TYPE.TEXT)
                          updateChatGPTSetting(defaultChatGPTTextSettingType)
                          addDraftMsgToChat({
                            ...selectedMessage,
                            id: uuidv4(),
                            setting: returnChatGPTSetting(
                              COMMEND_TYPE.TEXT,
                              defaultChatGPTTextSettingType
                            ),
                            type: ASSET_TYPE.CHATGPT_TEXT_REPLY,
                            replyMsg: selectedMessage.msg,
                          })
                        }}>
                        <FontAwesomeIcon
                          icon={faReply}
                          className="text-lg text-gray-500"
                        />
                      </button>
                    </div>
                  )
                }

                {/*<button className="w-8 h-8 py-2 mx-2 rounded-full bg-gray-50 hover:bg-gray-100">*/}
                {/*    <FontAwesomeIcon icon={faReply} className="text-lg text-gray-500"/>*/}
                {/*</button>*/}
              </div>
              {/*<div className="block text-xs text-gray-500 absolute bottom-0 right-1">*/}
              {/*    <Trans>ToolBar.you-can-only-delete-the-message-within-10-min</Trans>*/}
              {/*</div>*/}
            </div>
          )}
        </div>
      </>
    )
  }
  return (
    <div className="hidden md:inline relative h-16 grid  block bg-white">
      <h1 className=" text-lg md:text-md pl-4 mt-2 font-bold col-span-3">
        <span className="hidden md:inline">
          <DivContentLoader w={"60%"} h={30} r={4} />
        </span>
      </h1>
    </div>
  )
}

export default withRouter(ToolBar)
