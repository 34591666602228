import * as List from "../../Redux/SUPPORTED_REDUX_FUNCTION"

const initialState = {
  HeadingSideBar: false,
}

const SideBarManager = (state = initialState, action) => {
  if (action.type === List.SUPPORTED_REDUX_FUNCTIONS.SET_SIDEBAR_ACTIVE) {
    return {
      ...state,
      [action.sideBarName]: action.isOpen,
    }
  }

  return state
}

export default SideBarManager
