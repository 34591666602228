import React, { useEffect, useState } from "react"
import {
  CaseStudy,
  CSIgFinalMedia,
  CSMediaInsight,
  CSMediaListData,
} from "../../../Model/CaseStudy"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faComment, faHeart } from "@fortawesome/free-regular-svg-icons"
import {
  faCircleCheck,
  faCircleExclamation,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons"
import {
  IgMediaType,
  getCSMediaInsight,
  getCSMediaStoryData,
  listCSMedia,
} from "../../../HelpingFunction/FBLogin/FBLoginHelper"
import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import classNames from "classnames"
import { uploadImagesToImgur } from "../../../Utils/utiltyHelper"
import { Trans } from "react-i18next"
import { LocationType } from "../CaseStudyIGModalComponent"
import { uuid4 } from "@sentry/utils"
import {
  setCaseStudy,
  updateCaseStudy,
} from "../../../HelpingFunction/KOL/kolDBHelper"
import { setJobCaseStudyID } from "../../../HelpingFunction/Job/JobDBHelper"
import CustomAlert from "../../NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../../Enum/ALERT_SYSYEM"
import { t } from "i18next"

export enum igTab {
  POSTS,
  STORIES,
}

const CaseStudySelectMedia = ({
  metaData,
  closeModal,
  imagesList,
  nextPage,
  type,
  jobID,
}: {
  metaData: CaseStudy
  closeModal: () => void
  imagesList: CSMediaListData[]
  nextPage: string
  type: LocationType
  jobID?: string
}) => {
  const dispatch = useDispatch()
  let caseStudy = metaData

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })

  const currentFbPageID = useSelector((state: any) => {
    return state.MemberManager.currentFbPageID
  })

  const [selectedImage, setSelectedImage] = useState<{
    id: string
    mediaType: IgMediaType | null
  }>({ id: "", mediaType: null })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isGettingMedia, setIsGettingMedia] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [allImages, setAllImages] = useState<CSMediaListData[]>(imagesList)
  const [newPage, setNewPage] = useState<string>(nextPage)
  const [tab, setTab] = useState<igTab>(igTab.POSTS)

  const setIGMedia = (data: CaseStudy) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.EDIT_CASE_STUDY,
      caseStudy: data,
    })
  }

  const setJobCaseStudy = (data: CaseStudy) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_JOB_CASE_STUDY,
      data: data,
    })
  }

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false)
      }, 3000)
    }
  }, [error])

  const getIGMedia = async (type: igTab, morePosts: boolean) => {
    setIsGettingMedia(true)
    if (type === igTab.POSTS) {
      await listCSMedia(currentFbPageID, igTab.POSTS, newPage).then((res) => {
        if (res.success) {
          if (morePosts) {
            let allPosts = allImages.concat(res.igMediaList)
            setAllImages(allPosts)
          } else {
            setAllImages(res.igMediaList)
          }

          setNewPage(res.nextPage ? res.nextPage : "")
          setIsGettingMedia(false)
        } else {
          setAllImages([])
          setIsGettingMedia(false)
        }
      })
    } else if (type === igTab.STORIES) {
      await listCSMedia(currentFbPageID, igTab.STORIES).then((res) => {
        if (res.success) {
          setAllImages(res.igMediaList)
          setIsGettingMedia(false)
        } else {
          setAllImages([])
          setIsGettingMedia(false)
        }
      })
    }
  }

  const getInsight = async (id: string, mediaType: IgMediaType) => {
    setIsLoading(true)
    if (tab === igTab.STORIES) {
      mediaType = IgMediaType.STORY
    }
    await getCSMediaInsight(id, mediaType).then((res) => {
      if (res.success) {
        if (type === LocationType.JOB_NEW) {
          setNewCaseStudy(res.insight)
        } else {
          updateExisitingCS(res.insight)
        }
      }
    })
  }

  const updateExisitingCS = async (insightData: CSMediaInsight) => {
    const medData = allImages.filter((img) => img.id === selectedImage.id)
    const imageURL = await uploadImagesToImgur(auth.uid, [
      selectedImage.mediaType === IgMediaType.REEL
        ? (medData[0].thumbnail_url as string)
        : medData[0].media_url,
    ])
    caseStudy = {
      ...caseStudy,
      ig_comment: medData[0].comments_count,
      ig_like: medData[0].like_count,
      ig_save: insightData?.saved as number,
      ig_reach: insightData?.reach as number,
      ig_total_engagement: insightData?.engagement as number,
      ig_permalink: medData[0].permalink,
      ig_total_action: insightData?.impressions as number,
      ig_id: selectedImage.id,
      ig_media_type: selectedImage.mediaType as IgMediaType,
      imageUrl: imageURL.success ? imageURL.data : [],
      ig_share: 0,
      verified: true,
    }

    if (type === LocationType.KOL_PROFILE) {
      setIGMedia(caseStudy)
      setIsLoading(false)
      closeModal()
    } else if (type === LocationType.JOB_OLD) {
      const caseStudyEdit = await updateCaseStudy(auth.uid, caseStudy)
      if (caseStudyEdit.success) {
        setIsLoading(false)
        setJobCaseStudy(caseStudy)
        closeModal()
      }
    }
  }

  const setNewCaseStudy = async (insightData: CSMediaInsight) => {
    const medData = allImages.filter((img) => img.id === selectedImage.id)
    const imageURL = await uploadImagesToImgur(auth.uid, [
      selectedImage.mediaType === IgMediaType.REEL
        ? (medData[0].thumbnail_url as string)
        : medData[0].media_url,
    ])

    const csID = uuid4()

    caseStudy = {
      id: csID,
      jobID: jobID,
      ig_comment: medData[0].comments_count,
      ig_like: medData[0].like_count,
      ig_save: insightData?.saved as number,
      ig_reach: insightData?.reach as number,
      ig_total_engagement: insightData?.engagement as number,
      ig_permalink: medData[0].permalink,
      ig_total_action: insightData?.impressions as number,
      ig_id: selectedImage.id,
      ig_media_type: selectedImage.mediaType as IgMediaType,
      imageUrl: imageURL.success ? imageURL.data : [],
      ig_share: 0,
      verified: true,
      isPublish: false,
      title: "Case Study",
    }

    const caseStudyKOL = await setCaseStudy(auth.uid, caseStudy)

    if (caseStudyKOL.success) {
      const jobSetCaseStudy = await setJobCaseStudyID(jobID as string, csID)

      if (jobSetCaseStudy.success) {
        setIsLoading(false)
        closeModal()
      } else {
        setError(true)
        setIsLoading(false)
      }
    } else {
      setError(true)
      setIsLoading(false)
    }
  }

  return (
    <div className="relative md:flex md:flex-col h-full">
      <div className="flex-grow">
        {screenWidth < 768 && (
          <section className="absolute sticky -top-2  z-50 bg-white">
            <div className="flex flex-row items-center text-2xl font-semibold text-left pt-6 pb-2">
              <div className="my-auto">
                <Trans>CaseStudyIGModal.title-3</Trans>
              </div>

              <div className="float-right ml-auto my-auto">
                <button
                  onClick={async () =>
                    await getInsight(
                      selectedImage.id,
                      selectedImage.mediaType as IgMediaType
                    )
                  }
                  className={classNames("btn w-24 normal-case ", {
                    "btn-disabled": selectedImage.id === "",
                    loading: isLoading,
                  })}>
                  <Trans>CaseStudyIGModal.button-2</Trans>
                </button>
              </div>
            </div>

            <div className="tabs tabs-boxed w-40 text-2xs mb-4">
              <a
                className={classNames("tab", {
                  "tab-active": tab === igTab.POSTS,
                })}
                onClick={() => {
                  if (tab !== igTab.POSTS) {
                    setNewPage("")
                    setTab(igTab.POSTS)
                    getIGMedia(igTab.POSTS, false)
                  }
                }}>
                <Trans>CaseStudyIGModal.posts</Trans>
              </a>
              <a
                className={classNames("tab", {
                  "tab-active": tab === igTab.STORIES,
                })}
                onClick={() => {
                  if (tab !== igTab.STORIES) {
                    setNewPage("")
                    setTab(igTab.STORIES)
                    getIGMedia(igTab.STORIES, false)
                  }
                }}>
                <Trans>CaseStudyIGModal.stories</Trans>
              </a>
            </div>
            {error && (
              <div className="w-full">
                <CustomAlert
                  icon={faCircleExclamation}
                  title={"VerifyPhone.again"}
                  alertType={ALERT_TYPE.ERROR}
                  buttonType={{
                    type: BUTTON_TYPE.NONE,
                    buttonText: undefined,
                    buttonDisabledText: undefined,
                    buttonIcon: undefined,
                  }}
                />
              </div>
            )}
          </section>
        )}
        {!isGettingMedia && (
          <div className="flex-grow h-full">
            {tab === igTab.STORIES && (
              <CustomAlert
                alertType={ALERT_TYPE.WARNING}
                title={t("CaseStudyIGModal.warning-story")}
                icon={faTriangleExclamation}
                buttonType={{
                  type: BUTTON_TYPE.NONE,
                }}
              />
            )}

            <div className="grid md:grid-cols-3 md:mx-4 grid-cols-2 ">
              {allImages.map((image) => {
                return (
                  <div
                    onClick={async () => {
                      setSelectedImage({
                        id: image.id,
                        mediaType: image.media_type,
                      })
                    }}
                    className="my-2 cursor-pointer relative group">
                    <div className="absolute top-2 left-2">
                      {image.media_type === IgMediaType.REEL && (
                        <div className="bg-amber-200  rounded-full w-16 text-sm text-amber-500 text-center">
                          <Trans>CaseStudyCard.reel</Trans>
                        </div>
                      )}
                      {image.media_type === IgMediaType.IMAGE && (
                        <div className="bg-blue-200  rounded-full w-16 text-sm text-blue-500 text-center">
                          <Trans>CaseStudyCard.image</Trans>
                        </div>
                      )}
                      {image.media_type === IgMediaType.CAROUSEL && (
                        <div className="bg-purple-200  rounded-full w-20 text-sm text-purple-500 text-center">
                          <Trans>CaseStudyCard.carousel</Trans>
                        </div>
                      )}
                    </div>
                    {selectedImage.id === image.id && (
                      <div className="absolute top-2 right-6">
                        <FontAwesomeIcon
                          className="bg-white rounded-full absolute top-2 right-3 text-green-700 text-2xl animated animatedFadeInUp fadeInUp"
                          icon={faCircleCheck}
                        />
                      </div>
                    )}
                    <img
                      className="rounded-xl md:w-64 md:h-64 w-40 h-40 group-hover:opacity-20"
                      src={
                        image.media_type === "VIDEO"
                          ? image.thumbnail_url
                          : image.media_url
                      }
                    />
                    <p className="mx-2 lg:mx-8 mt-1 md:hidden block">
                      <span>
                        <FontAwesomeIcon icon={faHeart} />
                        <span className="font-medium ml-2 mr-4">
                          {image.like_count}
                        </span>
                      </span>
                      <span>
                        <FontAwesomeIcon icon={faComment} />
                        <span className="font-medium mx-2">
                          {image.comments_count}
                        </span>
                      </span>
                    </p>
                    <p className="absolute top-32 left-20 md:block hidden">
                      <span className="group-hover:inline-block hidden">
                        <FontAwesomeIcon className="text-xl" icon={faHeart} />
                        <span className="font-medium text-xl ml-2 mr-4">
                          {image.like_count}
                        </span>
                      </span>
                      <span className="group-hover:inline-block hidden">
                        <FontAwesomeIcon className="text-xl" icon={faComment} />
                        <span className="font-medium text-xl mx-2">
                          {image.comments_count}
                        </span>
                      </span>
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        )}

        {isGettingMedia && <div className="flex-grow">Loading...</div>}
      </div>
      {newPage && (
        <div className="mx-auto text-center my-4 pb-12 md:pb-0 flex-grow">
          <button
            onClick={() => {
              getIGMedia(tab, true)
            }}
            className={classNames("w-44 normal-case btn btn-primary", {
              loading: isGettingMedia,
            })}>
            <Trans>CaseStudyIGModal.get-posts</Trans>
          </button>
        </div>
      )}

      {screenWidth > 768 && (
        <section
          className=" border-t border-gray-100 grid sticky bottom-0  z-50 bg-white pt-3"
          style={{
            gridTemplateColumns: "auto auto 120px",
          }}>
          <div className="text-3xl font-semibold text-left my-auto">
            <Trans>CaseStudyIGModal.title-3</Trans>
          </div>
          <div className="tabs tabs-boxed w-40">
            <a
              className={classNames("tab", {
                "tab-active": tab === igTab.POSTS,
              })}
              onClick={() => {
                if (tab !== igTab.POSTS) {
                  setNewPage("")
                  setTab(igTab.POSTS)
                  getIGMedia(igTab.POSTS, false)
                }
              }}>
              <Trans>CaseStudyIGModal.posts</Trans>
            </a>
            <a
              className={classNames("tab", {
                "tab-active": tab === igTab.STORIES,
              })}
              onClick={() => {
                if (tab !== igTab.STORIES) {
                  setNewPage("")
                  setTab(igTab.STORIES)
                  getIGMedia(igTab.STORIES, false)
                }
              }}>
              <Trans>CaseStudyIGModal.stories</Trans>
            </a>
          </div>
          <button
            onClick={async () =>
              await getInsight(
                selectedImage.id,
                selectedImage.mediaType as IgMediaType
              )
            }
            className={classNames("btn w-24 normal-case ", {
              "btn-disabled": selectedImage.id === "",
              loading: isLoading,
            })}>
            <Trans>CaseStudyIGModal.button-2</Trans>
          </button>
          {error && (
            <div className="w-1/2 ">
              <CustomAlert
                icon={faCircleExclamation}
                title={"VerifyPhone.again"}
                alertType={ALERT_TYPE.ERROR}
                buttonType={{
                  type: BUTTON_TYPE.NONE,
                  buttonText: undefined,
                  buttonDisabledText: undefined,
                  buttonIcon: undefined,
                }}
              />
            </div>
          )}
        </section>
      )}
    </div>
  )
}

export default CaseStudySelectMedia
