export interface helpDoc {
  label: string
  url: string
}

export interface headerHelpDocs {
  label: string
  helpDocs: helpDoc[]
}

export const LOGIN_HOW_TO_USE_LOGIN: helpDoc = {
  label: "HelpDoc.how-to-login",
  url: "login/how-to-login",
}

// export const REGISTER_KOL_ACCOUNT: helpDoc = {
//   label: "KOL Account",
//   url: "register/kol",
// }
export const REGISTER_KOL_HOW_TO: helpDoc = {
  label: "HelpDoc.how-to-signup-KOL",
  url: "kol-registration/how-to-sign-up-as-kol",
}
export const REGISTER_KOL_HOW_TO_BLUE_TICK: helpDoc = {
  label: "HelpDoc.how-to-get-blue-tick",
  url: "kol-profile/how-be-a-verified-kol",
}

export const REGISTER_KOL_FACEBOOK_IG: helpDoc = {
  label: "HelpDoc.how-to-link-ig-to-fb",
  url: "kol-registration/facebook-page-link-to-ig-creator-account",
}

export const REGISTER_KOL_HOW_TO_FACEBOOK: helpDoc = {
  label: "HelpDoc.how-to-register-facebook",
  url: "kol-registration/create-a-facebook-business-page",
}
export const REGISTER_KOL_FACEBOOK: helpDoc = {
  label: "HelpDoc.why-register-facebook",
  url: "kol-registration/why-look4kol-needs-you-to-connect-with-facebook",
}
export const REGISTER_HOW_TO_REGISTER_IG: helpDoc = {
  label: "HelpDoc.why-register-facebook",
  url: "kol-registration/ig-business-account-creator-account",
}
export const REGISTER_CUSTOMER_HOW_TO: helpDoc = {
  label: "HelpDoc.how-to-signup-customer",
  url: "customer-registration/how-to-sign-up-as-a-customer",
}
// export const REGISTER_CUSTOMER_ACCOUNT: helpDoc = {
//   label: "Customer Account",
//   url: "register/customer",
// }
export const PROFILE_KOL_HOW_TO_ATTRACT: helpDoc = {
  label: "HelpDoc.how-to-make-attractive-profile",
  url: "kol-profile/what-is-kol-profile",
}
export const PROFILE_KOL_HOW_TO_EDIT: helpDoc = {
  label: "HelpDoc.how-to-edit-KOL-profile",
  url: "kol-profile/how-to-edit-kol-profile",
}
export const LINKTREE_KOL: helpDoc = {
  label: "HelpDoc.why-linktree",
  url: "linktree",
}
export const CONTRACT_WHAT_IS_CONTRACT: helpDoc = {
  label: "HelpDoc.what-is-contract",
  url: "contract",
}
export const CONTRACT_HOW_TO_USE_CONTRACT: helpDoc = {
  label: "HelpDoc.contract-main-steps",
  url: "contract/how-does-the-contract-work",
}
export const CHATROOM_HOW_TO_USE_CHATROOM: helpDoc = {
  label: "HelpDoc.how-to-chatroom",
  url: "chatroom/how-to-use-chatroom",
}
export const SEARCH_KOL: helpDoc = {
  label: "HelpDoc.how-to-tools-search-KOL",
  url: "tool/search-page",
}
export const COMPARE_KOL: helpDoc = {
  label: "HelpDoc.how-to-tools-compare-KOL",
  url: "tool/compare/kol",
}
export const PROJECT_HOW_TO: helpDoc = {
  label: "HelpDoc.how-to-tools-project",
  url: "project-tools",
}
export const RECOMMENDATION_HOW_TO: helpDoc = {
  label: "HelpDoc.how-to-tools-recommendation",
  url: "tool/recommendation/the-need-for-a-recommendation-tool",
}
export const SEARCH_PAGE_HOW_TO: helpDoc = {
  label: "HelpDoc.what-is-KOL-search",
  url: "search/how-to",
}

export const ERROR_HEADER: headerHelpDocs = {
  label: "ErrorPage.error-404",
  helpDocs: [],
}
export const KOL_PROFILE_HEADER: headerHelpDocs = {
  label: "HelpDocHeader.kol-profile-header",
  helpDocs: [PROFILE_KOL_HOW_TO_ATTRACT, LINKTREE_KOL],
}
export const CHATROOM_HEADER: headerHelpDocs = {
  label: "HelpDocHeader.chatroom-header",
  helpDocs: [CHATROOM_HOW_TO_USE_CHATROOM],
}
export const CONTRACT_HEADER: headerHelpDocs = {
  label: "HelpDocHeader.contract-header",
  helpDocs: [CONTRACT_WHAT_IS_CONTRACT, CONTRACT_HOW_TO_USE_CONTRACT],
}
export const REGISTER_HEADER: headerHelpDocs = {
  label: "HelpDocHeader.register-header",
  helpDocs: [REGISTER_KOL_HOW_TO, REGISTER_CUSTOMER_HOW_TO],
}
export const SIGNUP_HEADER: headerHelpDocs = {
  label: "HelpDocHeader.sign-up-header",
  helpDocs: [
    REGISTER_KOL_FACEBOOK_IG,
    REGISTER_KOL_HOW_TO_FACEBOOK,
    REGISTER_KOL_FACEBOOK,
    REGISTER_KOL_HOW_TO_BLUE_TICK,
    REGISTER_HOW_TO_REGISTER_IG,
  ],
}
export const PROJECT_HEADER: headerHelpDocs = {
  label: "HelpDocHeader.kol-profile-header",
  helpDocs: [PROJECT_HOW_TO],
}
export const TOOLS_HEADER: headerHelpDocs = {
  label: "HelpDocHeader.tools-header",
  helpDocs: [SEARCH_KOL, COMPARE_KOL, PROJECT_HOW_TO, RECOMMENDATION_HOW_TO],
}
