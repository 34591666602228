import { ASSET_TYPE } from "../../Enum/APP_TYPE"
import {
  CHATGPT_SETTING_TYPE,
  ChatMessage,
  COMMEND_TYPE,
  FORMAL_LEVEL,
} from "../../Model/ChatMessage"

/**
 * return the setting based on the chat type .i.e TEXT / IMAGE
 * @param chatgptType
 * @param setting
 */
const returnChatGPTSetting = (
  chatgptType: COMMEND_TYPE,
  setting: CHATGPT_SETTING_TYPE
): any => {
  switch (chatgptType) {
    case COMMEND_TYPE.TEXT:
      return {
        commendType: COMMEND_TYPE.TEXT,
        formalLevel: setting.text.formalLevel,
        friendlyLevel: setting.text.friendlyLevel,
        tone: setting.text.tone,
      }
    case COMMEND_TYPE.INSTAGRAM:
      return {
        commendType: COMMEND_TYPE.INSTAGRAM,
        language: setting.ig.language,
        location: setting.ig.location,
        product_name: setting.ig.product_name,
        product_desc: setting.ig.product_desc,
        product_brand: setting.ig.product_brand,
        product_url: setting.ig.product_url,
        keywords: setting.ig.keywords,
        desc_length: setting.ig.desc_length,
      }
    case COMMEND_TYPE.CHATBOT:
      return {
        commendType: COMMEND_TYPE.CHATBOT,
        pastHistory: setting.pastHistory,
      }

    default:
      return {
        commendType: chatgptType,
      }
  }
}

const pastChatGPTMessages = (allMessages: ChatMessage[]) => {
  let chatbotMessages: {
    role: string
    content: string
  }[] = []
  let continueLoop = true
  if (allMessages.length) {
    allMessages
      .slice()
      .reverse()
      .forEach((message, index) => {
        if (continueLoop) {
          // eslint-disable-next-line default-case
          switch (message.type) {
            case ASSET_TYPE.CHATGPT_TEXT_QUESTION:
              chatbotMessages.push({ role: "user", content: message.msg })
              break
            case ASSET_TYPE.CHATGPT_TEXT:
              chatbotMessages.push({
                role: "assistant",
                content: JSON.stringify({
                  answer: message.msg,
                  success: true,
                  followUpPrompts: message.followUpPrompts,
                }),
              })

              break
          }
        }
      })

    chatbotMessages = chatbotMessages.slice().reverse()

    return chatbotMessages
  }
  return chatbotMessages
}

export { returnChatGPTSetting, pastChatGPTMessages }
