import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { RecomData } from "../../Model/RecomTools"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"

export const ViewContractExampleComponent: React.FC = (props: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const currentRecommendation: RecomData = useSelector((state: any) => {
    return state.RecommendationManager.currentRecommendation
  })

  const setCurrentRecommendation = (recommendation: RecomData) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CURRENT_RECOMMENDATION,
      data: recommendation,
    })
  }

  return (
    <div className="h-full overflow-y-scroll container">
      <div className="font-bold mb-2 text-gray-700">
        <Trans>RecomContract.need-help-title</Trans>
      </div>
      <div className="whitespace-pre-wrap my-4">
        <ul className="list-disc list-inside text-xs text-gray-400">
          <li>
            <Trans>RecomContract.need-help-1</Trans>
          </li>
          <li>
            <Trans>RecomContract.need-help-2</Trans>
          </li>
          <li>
            <Trans>RecomContract.need-help-3</Trans>
          </li>
          <li>
            <Trans>RecomContract.need-help-4</Trans>
          </li>
          <li>
            <Trans>RecomContract.need-help-5</Trans>
          </li>
        </ul>
      </div>
      <textarea
        className="textarea textarea-ghost text-xs w-full h-96"
        placeholder=""
        disabled={true}>
        {t("RecomContract.need-help-example")}
      </textarea>
      <button
        className="btn w-full my-4 lg:mb-16"
        onClick={() => {
          let temp = {
            ...currentRecommendation,
            contractDescription: t("RecomContract.need-help-example") as string,
          }
          setCurrentRecommendation(temp)
          if (props.closeModal) {
            props.closeModal()
          }
        }}>
        <Trans>RecomContract.use-directly</Trans>
      </button>
    </div>
  )
}
