import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import {
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"
import { isAuth, logout } from "../../Utils/FirebaseUtils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleXmark,
  faEye,
  faEyeSlash,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons"
import Spinner from "../../Component/Spinner"
import { analytics } from "../../Config/firebase"
import { validateEmail } from "../../Utils/FormValidate"
import { NEW_KOL } from "../../Enum/LOGIN_PAGE_TYPE"
import { createKOL, setKOL } from "../../HelpingFunction/KOL/kolDBHelper"
import { checkEmailExist } from "../../HelpingFunction/Member/memberDBHelper"
import { getAllCountries } from "../../Utils/Countries"
import { logEvent } from "firebase/analytics"
import { RegisterPageType } from "../../pages/register"
import CustomAlert from "../../Component/NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../Enum/ALERT_SYSYEM"
import { getUserLang, isWebView } from "../../Utils/utiltyHelper"
import { REGISTER_KOL_HOW_TO } from "../../Utils/HelpButtonHelperFunction"
import { TIP_DIRECTION } from "../../Enum/HELP_BTN_SYSYEM"
import CustomHelpButton from "../../Component/NewHelpButton"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import iosWV from "../../Assets/iOSWV.png"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import iosWV2 from "../../Assets/iOSVW2.jpg"
import { isAndroid, isIOS } from "../../Utils/HelpingFunction"

const KOLRegForm = (props: any) => {
  const { t } = useTranslation()
  const history = useHistory()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const refer = queryParams.get("refer")

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const lang = localStorage.getItem("i18n-lang")

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const localUser = JSON.parse(localStorage.getItem("kol-data"))
  let localState
  if (localUser) {
    localState = localUser
  } else {
    localState = {
      uid: "",
      email: "",
      avatarUrl: "",
      nationality: 344,
      // languagePreference: "",
      userName: "",
      firstName: "",
      lastName: "",
      description: "",
      type: [],
      platform: [],
      coverImages: [],
      referralUser: "",
    }
  }

  const [password, setPassword] = useState<string>("")
  const [reTypePassword, setReTypePassword] = useState<string>("")
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [error, setError] = useState<{
    signal: boolean
    value: string
  }>({ signal: false, value: "" })

  const [newKOLUser, setNewKOLUser] = useState<NEW_KOL>(localState)
  const [showPass, setShowPass] = useState<boolean>(false)

  useEffect(() => {
    window.localStorage.setItem("kol-data", JSON.stringify(newKOLUser))
  }, [newKOLUser])

  useEffect(() => {
    if (isAuth(auth)) {
      history.push("/" + getUserLang())
    }
  }, [auth])

  useEffect(() => {
    if (isAuth(auth)) {
      setNewKOLUser({
        ...newKOLUser,
        email: auth.email,
        uid: auth.uid,
      })
    }
  }, [auth])

  useEffect(() => {
    setNewKOLUser({
      ...newKOLUser,
      referralUser: refer ? refer : "",
    })
  }, [])

  const submitKOL = async (): Promise<void> => {
    setIsSubmitting(true)

    if (isAuth(auth)) {
      //logged in through social login
      await setKOL(auth.uid, newKOLUser)
        .then((r) => {
          if (r.success) {
            setIsSubmitting(false)
            localStorage.setItem("look4kol-require-logout", "true")
            localStorage.setItem("look4kol-require-login", "true")
            setTimeout(() => {
              logout()
            }, 5000)
          }
        })
        .catch((err) => console.log(err))
    } else {
      const checkEmailState = await checkEmailExist(newKOLUser.email)
      if (
        validateEmail(newKOLUser.email) &&
        !checkEmailState.exists &&
        password.length >= 8 &&
        password === reTypePassword &&
        newKOLUser.firstName !== ""
      ) {
        await createKOL(newKOLUser, password, false)
          .then((r) => {
            if (r.success) {
              setIsSubmitting(false)
              props.setPageType(RegisterPageType.REQUIRE_LOGIN)
              localStorage.setItem("look4kol-require-logout", "true")
              localStorage.setItem("look4kol-require-login", "true")
              // remove temp data
              localStorage.removeItem("kol-data")
            } else {
              // console.log(finalResponse.message)
            }
          })
          .catch((err) => console.log(err))
      } else if (validateEmail(newKOLUser.email)) {
        if (
          newKOLUser.email === "" ||
          password === "" ||
          newKOLUser.firstName === "" ||
          newKOLUser.lastName === "" ||
          reTypePassword === ""
        ) {
          setIsSubmitting(false)
          setError({
            signal: true,
            value: t("NewKOLReg.alert-enter-fields"),
          })
        } else if (checkEmailState.exists) {
          setIsSubmitting(false)
          setError({
            signal: true,
            value: t("NewKOLReg.alert-email-exists"),
          })
        } else if (password.length < 8) {
          setIsSubmitting(false)
          setError({
            signal: true,
            value: t("NewKOLReg.alert-password-length"),
          })
        } else if (password !== reTypePassword) {
          setIsSubmitting(false)
          setError({
            signal: true,
            value: t("NewKOLReg.alert-not-match-password"),
          })
        } else {
          setError({
            signal: false,
            value: "",
          })
        }
      } else {
        setIsSubmitting(false)
        setError({
          signal: true,
          value: t("NewKOLReg.alert-valid-email"),
        })
      }
    }
  }

  const arr = getAllCountries()

  // if ig browser
  if (isWebView() && !(isAndroid() || isIOS())) {
    return (
      <div className={"w-full  py-6"}>
        {/*BrowserNotSupportAlert*/}
        <CustomAlert
          title={"KOLRegForm.browser-not-support-title"}
          alertType={ALERT_TYPE.ERROR}
          buttonType={{
            type: BUTTON_TYPE.NONE,
          }}
          description={"KOLRegForm.browser-not-support-des"}
          icon={faTimesCircle}
          visible={true}
        />
        <div>
          <div className="mb-12">
            <h2 className="text-xl mb-2">
              <Trans>KOLRegForm.step-1</Trans>
            </h2>
            <img className="w-48 mx-auto" loading="lazy" src={iosWV2} />
          </div>
          <div>
            <h2 className="text-xl mb-2">
              <Trans>KOLRegForm.step-2</Trans>
            </h2>
            <img className="w-48 mx-auto" loading="lazy" src={iosWV} />
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className={"w-full relative h-fit py-2"}>
      <div className="mx-auto border rounded-lg p-3 mt-3">
        <div className="block pl-2 font-semibold text-xl self-start text-gray-700">
          <h2 className="leading-relaxed">
            <Trans>RegForm.register-as-kol</Trans>
            <CustomHelpButton
              helpDoc={REGISTER_KOL_HOW_TO}
              tipDirection={TIP_DIRECTION.RIGHT}
            />
          </h2>
          <p className="text-sm text-gray-500 font-normal leading-relaxed">
            <Trans>RegForm.register-as-kol-des</Trans>
          </p>
        </div>
      </div>
      <div className="pb-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7 pt-4">
        <div className="flex flex-col">
          <label className="leading-loose">
            <Trans>NewKOLReg.email</Trans>
          </label>
          <input
            type="email"
            placeholder={t("NewKOLReg.email-placeholder") as string}
            className="input input-bordered w-full"
            value={newKOLUser.email}
            onChange={(e) => {
              setNewKOLUser({
                ...newKOLUser,
                email: e.target.value,
              })
            }}
          />
        </div>
        <div className="flex flex-col">
          <label className="leading-loose">
            <Trans>NewKOLReg.password</Trans>
          </label>
          <div className="form-control">
            <div className="input-group">
              <input
                type={showPass ? "text" : "password"}
                placeholder={t("NewKOLReg.password-placeholder") as string}
                className="input input-bordered w-full"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                className="btn btn-square btn-primary"
                onClick={() => setShowPass(!showPass)}>
                {showPass ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <label className="leading-loose">
            <Trans>NewKOLReg.retype-password</Trans>
          </label>
          <input
            type="password"
            placeholder={t("NewKOLReg.retype-password-placeholder") as string}
            className="input input-bordered w-full "
            value={reTypePassword}
            onChange={(e) => setReTypePassword(e.target.value)}
          />
          {reTypePassword !== "" && reTypePassword !== password && (
            <label className="text-sm my-1 text-red-500">
              <Trans>NewKOLReg.password-not-match</Trans>
            </label>
          )}
        </div>
        <div className={"grid grid-cols-2 gap-4"}>
          <div>
            <div className="flex flex-col">
              <label className="leading-loose">
                <Trans>NewKOLReg.first-name</Trans>
              </label>
            </div>
            <input
              type="text"
              placeholder={t("NewKOLReg.first-name-placeholder") as string}
              className="input input-bordered w-full max-w-sm"
              value={newKOLUser.firstName}
              onChange={(e) => {
                setNewKOLUser({
                  ...newKOLUser,
                  firstName: e.target.value,
                })
              }}
            />
          </div>
          <div>
            <div className="flex flex-col">
              <label className="leading-loose">
                <Trans>NewKOLReg.last-name</Trans>
              </label>
            </div>
            <input
              type="text"
              placeholder={t("NewKOLReg.last-name-placeholder") as string}
              className="input input-bordered w-full max-w-sm"
              value={newKOLUser.lastName}
              onChange={(e) => {
                setNewKOLUser({
                  ...newKOLUser,
                  lastName: e.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <label className="leading-loose">
            <Trans>NewKOLReg.location</Trans>
          </label>
          <select
            className="select select-bordered w-full"
            value={newKOLUser.nationality}
            onChange={(e) => {
              setNewKOLUser({
                ...newKOLUser,
                nationality: e.target.value,
              })
            }}>
            {lang !== null && (
              <option disabled selected>
                {lang === "en" ? "Pick Your Location" : "選擇您的位置"}
              </option>
            )}
            <option value={344}>{"Hong Kong"}</option>
            <option value={158}>{"Taiwan"}</option>
            {arr.map((country) => {
              return (
                // eslint-disable-next-line prettier/prettier
                <option value={country.value}>{country.label}</option>
              )
            })}
          </select>
        </div>
        <div className="flex flex-col">
          <label className="leading-loose">
            <Trans>NewKOLReg.referral-code</Trans>
          </label>
          <input
            type="text"
            placeholder={t("NewKOLReg.referral-code") as string}
            className="input input-bordered w-full "
            value={newKOLUser.referralUser}
            onChange={(e) => {
              setNewKOLUser({
                ...newKOLUser,
                referralUser: e.target.value,
              })
            }}
          />
        </div>
      </div>
      {error.signal && (
        //ErrorAlerts
        <CustomAlert
          title={error.value}
          alertType={ALERT_TYPE.ERROR}
          buttonType={{
            type: BUTTON_TYPE.NONE,
          }}
          icon={faCircleXmark}
          visible={true}
        />
      )}
      <div className={"text-left mt-8 mb-4"}>
        <button
          disabled={isSubmitting}
          onClick={() => {
            submitKOL()
            logEvent(analytics, "registerKOL", {
              registerStep: 0,
            })
          }}
          className="btn w-40 btn-primary">
          {isSubmitting ? <Spinner /> : <Trans>RegForm.create-profile</Trans>}
        </button>
      </div>
      <div className="text-sm flex flex-row gap-1">
        <p>
          <Trans>RegisterPage.already-account</Trans>
        </p>
        <span
          onClick={() => history.push("/" + getUserLang() + "/login")}
          className="text-primary cursor-pointer font-medium">
          <Trans>RegForm.login</Trans>
        </span>
      </div>
    </div>
  )
}

export default withRouter(KOLRegForm)
