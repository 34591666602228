import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation, withRouter } from "react-router-dom"
import { Trans } from "react-i18next"
import { motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "../HeadingMessageManager/HeadingMessageManager.module.css"
import { faUser, faWallet } from "@fortawesome/free-solid-svg-icons"
import { ownedType } from "./OwnedKOLNavbar"
import classNames from "classnames"
import { getUserLang } from "../../Utils/utiltyHelper"

interface PropsType {
  owned: ownedType
  isSelected: boolean
  isCustomer: boolean
  closeModal?: () => void
}

const OwnedElement: React.FC<PropsType> = ({
  owned,
  isSelected,
  isCustomer,
  closeModal,
}) => {
  let history = useHistory()
  const location = useLocation()

  const queryGenerator = () => {
    const params = new URLSearchParams("")
    if (isCustomer) {
      params.append("returnToCustomer", "true")
    } else {
      params.append("viewAs", owned.id)
    }
    return params
  }

  return (
    <div
      className={classNames("rounded-xl p-1 md:p-3 mb-4 md:mb-2", {
        "bg-red-100": isSelected,
        "border border-gray-100 bg-gray-0 hover:bg-gray-50 cursor-pointer":
          !isSelected,
        "flex justify-between": !isCustomer,
      })}
      onClick={() => {
        if (!isSelected) {
          let params = queryGenerator()
          history.push({
            pathname: "/" + getUserLang(),
            search: params.toString(),
          })
          if (closeModal) {
            closeModal()
          }
        }
      }}>
      <div className={classNames("flex-col ", { "md:mx-auto": isCustomer })}>
        <li
          className={classNames(
            "grid focus:text-white text-gray-500 pl-2 my-2 gap-1"
          )}
          style={{ gridTemplateColumns: "50px auto" }}>
          <div>
            {owned.avatarUrl ? (
              <img src={owned.avatarUrl} className="h-10 w-10 rounded-full " />
            ) : (
              <div className="h-10 w-10 rounded-full text-center bg-red-50 py-1.5">
                <FontAwesomeIcon
                  icon={faUser}
                  className="text-red-200 my-auto text-lg"
                />
              </div>
            )}
          </div>
          <div>
            <div className="flex font-bold text-gray-700">
              <div className="truncate max-w-48">{owned.userName}</div>
            </div>
            <span className="block truncate text-xs text-gray-500">
              {owned.email}
            </span>
          </div>
        </li>
      </div>
      {!isCustomer && (
        <div className="flex gap-2 my-auto mr-4 md:mr-0">
          <button
            className="btn btn-circle btn-sm btn-primary"
            onClick={(e) => {
              e.stopPropagation()
              let params = queryGenerator()
              history.push({
                pathname: "/" + getUserLang() + "/kol/wallet",
                search: params.toString(),
              })
              if (closeModal) {
                closeModal()
              }
            }}>
            <FontAwesomeIcon icon={faWallet} className="w-8 " />
          </button>
          <button
            className="btn btn-circle btn-sm btn-primary"
            onClick={(e) => {
              e.stopPropagation()
              let params = queryGenerator()
              history.push({
                pathname:
                  "/" +
                  getUserLang() +
                  "/p/" +
                  (owned.userName ? owned.userName : owned.id),
                search: params.toString(),
              })
              if (closeModal) {
                closeModal()
              }
            }}>
            <FontAwesomeIcon icon={faUser} className="w-8" />
          </button>
        </div>
      )}
    </div>
  )
}

export default OwnedElement
