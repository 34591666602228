import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Trans, useTranslation } from "react-i18next"
import { getKOLs } from "../../../HelpingFunction/KOL/kolDBHelper"
import { ProjectGroupQuotePage } from "../../../Model/ProjectManagement"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"

import KOL from "../../../Model/KOL"
import CustomAlert from "../../../Component/NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../../Enum/ALERT_SYSYEM"

const GroupQuoteContract = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const tableKOLs: string[] = useSelector((state: any) => {
    return state.ProjectManager.tableSelectedKOLs
  })
  const contractDetails: { title: string; description: string } = useSelector(
    (state: any) => {
      return state.ProjectManager.groupContractDetails
    }
  )

  const [error, setError] = useState<{
    type: ALERT_TYPE
    visible: boolean
    msg: string
  }>({
    type: ALERT_TYPE.ERROR,
    visible: false,
    msg: "",
  })
  const setGroupPageType = (page: ProjectGroupQuotePage) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_GROUP_QUOTATION_PAGE,
      data: page,
    })
  }
  const setContractDetails = (details: {
    title: string
    description: string
  }) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_GROUP_CONTRACT_DETAILS,
      data: details,
    })
  }

  const [kols, setKOLs] = useState<KOL[]>([])
  useEffect(() => {
    getKOLInfo()
    setContractDetails({ title: "", description: "" })
  }, [])

  const getKOLInfo = async () => {
    const result = await getKOLs(tableKOLs)
    if (result.success) {
      setKOLs(result.data)
    }
  }
  return (
    <div className="mt-4 md:grid md:grid-cols-3 flex flex-col-reverse gap-2">
      <section className="md:col-span-2 md:pr-6  md:border-r">
        <section className="mt-1">
          <p className="font-medium">
            <Trans>GroupQuoteProject.contract-title</Trans>
          </p>
          <input
            type="text"
            value={contractDetails.title}
            onChange={(e) => {
              setContractDetails({
                ...contractDetails,
                title: e.target.value,
              })
            }}
            placeholder={t("GroupQuoteProject.placeholder-title") as string}
            className="input input-bordered w-full  my-2"
          />
        </section>
        <section className="mt-4">
          <p className="font-medium">
            {" "}
            <Trans>GroupQuoteProject.contract-desc</Trans>
          </p>
          <textarea
            value={contractDetails.description}
            onChange={(e) => {
              setContractDetails({
                ...contractDetails,
                description: e.target.value,
              })
            }}
            className="textarea textarea-bordered my-2 w-full h-[200px] resize-none"
            placeholder={t("GroupQuoteProject.placeholder-desc") as string}
          />
        </section>

        {error.visible && (
          <section className="my-4 md:px-0 px-8">
            <CustomAlert
              title={error.msg}
              alertType={error.type}
              buttonType={{
                type: BUTTON_TYPE.NONE,
                buttonText: undefined,
                buttonDisabledText: undefined,
                buttonIcon: undefined,
              }}
            />
          </section>
        )}

        <button
          onClick={() => {
            if (contractDetails.title && contractDetails.description) {
              setGroupPageType(ProjectGroupQuotePage.PROGRESS)
            } else {
              setError({
                type: ALERT_TYPE.ERROR,
                visible: true,
                msg: t("ErrorList.fill-in-the-field"),
              })
            }
          }}
          className="btn btn-info  w-full mx-auto sticky bottom-10 md:bottom-0">
          <Trans>GroupQuoteProject.create-jobs</Trans>
        </button>
      </section>
      <section className="mt-2 px-2">
        <p className="text-sm text-gray-500 my-2">
          <Trans>GroupQuoteProject.kols-selected</Trans>
        </p>
        {kols.map((kol) => {
          return (
            <div className="flex flex-row gap-2 items-center my-2">
              <div>
                <img
                  className="w-6 h-6 object-cover rounded-full"
                  src={kol.avatarUrl}
                  alt={kol.userName}
                />
              </div>
              <div>{kol.userName}</div>
            </div>
          )
        })}
      </section>
    </div>
  )
}

export default GroupQuoteContract
