import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"
import { Reducer } from "react"
import { Schedule, SCHEDULER_DEFAULT } from "../../Model/Schedule"
import { v4 as uuidv4 } from "uuid"
import { CaseStudy } from "../../Model/CaseStudy"
import { ADMIN_REQUEST } from "../../Model/AdminRequest"
import { CONTRACT_STEP } from "../../Enum/CONTRACT"

interface actionTypes {
  type: string
  data:
    | string
    | boolean
    | Schedule
    | Schedule[]
    | CaseStudy
    | ADMIN_REQUEST
    | CONTRACT_STEP
}

export interface modalInitStateType {
  schedules: Schedule[]
  isEditMode: boolean
  jobCaseStudy: CaseStudy | undefined
  adminRequest: ADMIN_REQUEST | null
  stepHeader: CONTRACT_STEP
}

const initialState: modalInitStateType = {
  schedules: [],
  isEditMode: false,
  jobCaseStudy: undefined,
  adminRequest: null,
  stepHeader: CONTRACT_STEP.QUOTATION,
}

const newSchedule = () => {
  return {
    ...SCHEDULER_DEFAULT,
    id: uuidv4(),
  }
}

const editSchedule = (
  schedules: Schedule[],
  editData: Schedule
): Schedule[] => {
  let newSchedules = [...schedules]
  const index: number = schedules.findIndex((e) => e.id === editData.id)

  if (index !== -1) {
    newSchedules[index] = editData
    return newSchedules
  }
  return newSchedules
}

const ContractManager: Reducer<modalInitStateType, actionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SUPPORTED_REDUX_FUNCTIONS.ADD_NEW_SCHEDULE:
      return {
        ...state,
        schedules: state.schedules.concat(newSchedule()),
      }
    case SUPPORTED_REDUX_FUNCTIONS.EDIT_SCHEDULE:
      return {
        ...state,
        schedules: editSchedule(state.schedules, action.data as Schedule),
      }
    case SUPPORTED_REDUX_FUNCTIONS.REMOVE_SCHEDULE:
      return {
        ...state,
        schedules: state.schedules.filter(
          (e) => e.id !== (action.data as string)
        ),
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_IS_SCHEDULE_EDIT:
      return {
        ...state,
        isEditMode: action.data as boolean,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_SCHEDULES:
      return {
        ...state,
        schedules: action.data as Schedule[],
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_JOB_CASE_STUDY:
      return {
        ...state,
        jobCaseStudy: action.data as CaseStudy,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_ADMIN_REQUEST:
      return {
        ...state,
        adminRequest: action.data as ADMIN_REQUEST | null,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_CONTRACT_STEP:
      return {
        ...state,
        stepHeader: action.data as CONTRACT_STEP,
      }
    default:
      return state
  }
}

export default ContractManager
