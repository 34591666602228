import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import CustomTable from "./NewTable"
import { Trans, useTranslation } from "react-i18next"
import { SUPPORTED_REDUX_FUNCTIONS } from "../Redux/SUPPORTED_REDUX_FUNCTION"
import {
  KOLCombineWithAvatar,
  KOLCombineWithAvatarDefault,
} from "../Model/KOLCombine"
import {
  chatGPTRecommendTableSchema,
  chatGPTRecommendTableSchemaDefault,
} from "../Utils/TableHelperFunction"
import { SUPPORTED_MODAL_TYPE } from "../Utils/ModalType"
import { Column, tableFunctions } from "../Model/Table"
import { useLocation } from "react-router-dom"
import classNames from "classnames"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "./NewTable.module.css"
import { TABLE_FUNCTIONS, WIDTH_SIZE } from "../Enum/TABLE_SYSTEM"
import {
  getFullKOLList,
  getNonRecomKOLList,
} from "../HelpingFunction/Assistant/assistantDBHelper"
import AssistantRecommendCard, {
  ASSISTANT_RECOMMEND_CARD_TYPE,
} from "./AssistantRecommendCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
  faSearch,
} from "@fortawesome/free-solid-svg-icons"
import DivContentLoader from "./DivContentLoader"

export const ChatGPTRecommendTable: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const showHelpText = useSelector((state: any) => {
    return state.ToolsManager.showHelpText
  })
  const savedKOLData = useSelector((state: any) => {
    return state.ChatGPTManager.recommendationUIDs
  })
  const isCardView = useSelector((state: any) => {
    return state.AssistantManager.isCardView
  })
  const expandedID = useSelector((state: any) => {
    return state.AssistantManager.expandedKOL
  })
  const selectedKOLs: KOLCombineWithAvatar[] = useSelector((state: any) => {
    return state.RecommendationManager.selectedKOL
  })

  const currentPage = useSelector((state: any) => {
    return state.RecommendationManager.searchKOLCondition.page
  })

  const searchKOLCondition = useSelector((state: any) => {
    return state.RecommendationManager.searchKOLCondition
  })

  const [data, setData] = useState<Column[][]>([
    chatGPTRecommendTableSchemaDefault(),
  ])
  const [kols, setKOLs] = useState<
    {
      kol: KOLCombineWithAvatar
      reason: string
      score: string
    }[]
  >([])

  const [hasMore, setHasMore] = useState<boolean>(false)
  const [isRefreshed, setIsRefreshed] = useState<boolean>(false)
  const [isTableLoaded, setIsTableLoaded] = useState<boolean>(false)
  const [isCombineLoaded, setIsCombineLoaded] = useState<boolean>(false)

  const defaultSelection = (kols: KOLCombineWithAvatar[]) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SELECT_KOL_ARRAY,
      data: { kols: kols.slice(0, 3), isDefaultSetup: true },
    })
  }
  const setViewKOLPercentageModal = (state: any) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.VIEW_KOL_PERCENTAGE_MODAL,
      returnFn: state.returnFn,
      metaData: state.metaData,
    })

  const onClickKOL = (kol: KOLCombineWithAvatar) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.ON_CLICK_KOL,
      data: kol,
    })
  }
  const onPreviewKOL = (kolID: string) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.KOL_INFO_PREVIEW_MODAL,
      returnFn: null,
      metaData: {
        kolID: kolID,
      },
    })
  }
  const onClickExpand = (kolID: string) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.EXPANDED_KOL,
      data: kolID,
    })
  }
  const setCurrentPage = (page: number) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_RECOM_SEARCH_KOL_QUERY,
      data: {
        key: "page",
        value: page,
      },
    })
  }
  const setCondition = (state: any) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_RECOM_SEARCH_KOL_QUERY,
      data: {
        key: state.key,
        value: state.value,
      },
    })

  const setKOLListReady = (isReady: boolean) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_KOLLIST_READY,
      data: isReady,
    })
  }

  useEffect(() => {
    if (
      !isRefreshed &&
      isCombineLoaded &&
      Array.isArray(kols) &&
      kols.length > 0 &&
      currentPage === 0
    ) {
      const kolIDs = kols.map((e) => e.kol)
      defaultSelection(kolIDs)
      onClickExpand(kolIDs[0].id)
      setIsRefreshed(true)
    }
  }, [isCombineLoaded])
  useEffect(() => {
    if (savedKOLData.length) {
      setKOLListReady(false)
      getSearchResult()
    }
  }, [savedKOLData, searchKOLCondition])

  useEffect(() => {
    if (Array.isArray(kols) && isCombineLoaded) {
      setKOLListReady(false)
      buildTable(kols)
    }
  }, [kols, showHelpText, selectedKOLs, expandedID, isCombineLoaded])

  const expandedComponent = (reason: string, score: string): JSX.Element => {
    return (
      <div className="h-40 p-4 w-full block 2 bg-base-100 border-2 border-base-600 rounded-2xl">
        <div className="flex gap-3">
          <span className=" font-thin text-lg">Score: </span>
          <span className=" font-medium text-lg">{score ? score : "--"}%</span>
          <progress
            className="progress progress-success w-56 my-auto"
            value={score ? score : 0}
            max="100"></progress>
        </div>
        <span className="block font-semibold text-gray-700 text-sm">
          Reason for promotion:
        </span>
        {reason ? (
          <span
            className={classNames(
              "h-20 inline-block text-gray-600 text-sm overflow-y-auto",
              classes.recommendScrollBar
            )}>
            {reason}
          </span>
        ) : (
          <span
            className={classNames(
              "h-20 inline-block text-gray-600 text-sm overflow-y-auto",
              classes.recommendScrollBar
            )}>
            <Trans>AssistantRecom.no-reason-warning</Trans>
          </span>
        )}
      </div>
    )
  }
  const buildTable = (
    kols: {
      kol: KOLCombineWithAvatar
      reason: string
      score: string
    }[]
  ) => {
    setKOLListReady(false)
    setIsTableLoaded(false)
    let tableSets: Column[][] = []
    kols.map((kol) => {
      let col: Column[] = [
        ...chatGPTRecommendTableSchema(
          selectedKOLs,
          kol,
          setViewKOLPercentageModal,
          showHelpText,
          onClickKOL,
          expandedComponent,
          expandedID,
          onClickExpand
        ),
      ]
      tableSets.push(col)
    })

    setData(tableSets)
    setIsTableLoaded(true)
    setKOLListReady(true)
  }

  const getSearchResult = async () => {
    setIsCombineLoaded(false)
    if (currentPage === 0) {
      const kolResult = await getFullKOLList(
        savedKOLData,
        searchKOLCondition,
        (hasMore) => {
          setHasMore(hasMore)
        }
      )
      setKOLs(kolResult.success ? kolResult.data : [])
      setIsCombineLoaded(true)
    } else {
      const kolResult = await getNonRecomKOLList(
        searchKOLCondition,
        (hasMore) => {
          setHasMore(hasMore)
        }
      )
      setKOLs(kolResult.success ? kolResult.data : [])
      setIsCombineLoaded(true)
    }
  }

  const tableFn: tableFunctions[] = [
    {
      title: t("RecomTable.kol-username"),
      data: {
        type: TABLE_FUNCTIONS.INPUT,
        placeHolder: t("RecomTable.search") + t("RecomTable.kol-username"),
        textValue: searchKOLCondition.searchStr,
        width: WIDTH_SIZE.XXL,
        onChange: (e) => {
          setCurrentPage(0)
          // setIsLoadedTable(false)
          setCondition({ key: "searchStr", value: e.target.value })
        },
      },
    },
  ]

  const selectedKOLIds = selectedKOLs.map((item) => item.id)
  return (
    <>
      {isCardView && (
        <div className="md:p-2">
          <section className="mt-2">
            <div
              className="grid md:border md:border-gray-300 shadow-lg md:shadow-none md:rounded-lg rounded-xl md:p-3 p-6 md:max-w-xs"
              style={{ gridTemplateColumns: "25px auto" }}>
              <div className="border-r">
                <FontAwesomeIcon
                  className="mx-auto text-gray-400"
                  icon={faSearch}
                />
              </div>
              <input
                type="text"
                placeholder={
                  t("RecomTable.search") + t("RecomTable.kol-username")
                }
                className={classNames("max-w-xs px-2")}
                value={searchKOLCondition.searchStr}
                onChange={(e) => {
                  setCurrentPage(0)
                  // setIsLoadedTable(false)
                  setCondition({ key: "searchStr", value: e.target.value })
                }}
              />
            </div>
          </section>
          <section>
            {isCombineLoaded ? (
              <div className="my-12 p-1">
                <div className="grid xl:grid-cols-3  sm:grid-cols-2 gap-4 md:px-0 px-1">
                  {kols &&
                    kols.map((k, index) => {
                      const cardType = k.reason
                        ? ASSISTANT_RECOMMEND_CARD_TYPE.GOLD
                        : ASSISTANT_RECOMMEND_CARD_TYPE.NORMAL
                      const selected = selectedKOLIds.includes(k.kol.id)
                      return (
                        <AssistantRecommendCard
                          kol={k.kol}
                          type={cardType}
                          reason={k.reason}
                          score={k.score}
                          isSelected={selected}
                          canSelectKOL={true}
                          onSelectKOL={onClickKOL}
                          onPreviewKOL={onPreviewKOL}
                          havePriceRange={true}
                        />
                      )
                    })}
                </div>
              </div>
            ) : (
              <div className="my-12 p-1">
                <div className="grid xl:grid-cols-3  sm:grid-cols-2 gap-4 md:px-0 px-1">
                  <DivContentLoader w={270} h={300} r={20} />
                  <DivContentLoader w={270} h={300} r={20} />
                  <DivContentLoader w={270} h={300} r={20} />
                  <DivContentLoader w={270} h={300} r={20} />
                  <DivContentLoader w={270} h={300} r={20} />
                  <DivContentLoader w={270} h={300} r={20} />
                </div>
              </div>
            )}
          </section>
          <section>
            <div className="w-full h-12">
              <div className="flex flex-row gap-2 justify-center w-40 text-center mx-auto mt-6">
                <button
                  className={classNames(
                    "w-10 h-10 rounded-full bg-gray-100",
                    currentPage === 0
                      ? "text-gray-300 cursor-not-allowed "
                      : "text-black"
                  )}
                  onClick={() => {
                    if (currentPage !== 0) {
                      let cp = currentPage
                      setCurrentPage(--cp)
                    }
                  }}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>

                <div className="w-10 h-10 rounded-full mx-auto bg-primary text-white flex items-center justify-center text-lg">
                  <p>{currentPage + 1}</p>
                </div>

                <button
                  className={classNames(
                    "w-10 h-10 rounded-full bg-gray-100",
                    !hasMore
                      ? "text-gray-300 cursor-not-allowed "
                      : "text-black"
                  )}
                  onClick={() => {
                    if (hasMore) {
                      let cp = currentPage
                      setCurrentPage(++cp)
                    }
                  }}>
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </div>
            </div>
          </section>
        </div>
      )}
      {!isCardView && (
        <div>
          {savedKOLData && isCombineLoaded ? (
            <CustomTable
              cols={data}
              tableFn={tableFn}
              isFirstColStatic={true}
              isLoading={!isTableLoaded}
              rowIsLoading={false}
              notFoundDes={t("SearchKOL.no-result")}
              clickAction={(index) => {
                onClickExpand(kols[index].kol.id)
              }}
              prevBtn={{
                disable: false,
                onClick: () => {
                  let cp = currentPage
                  setCurrentPage(--cp)
                },
              }}
              pageNum={currentPage}
              nextBtn={{
                disable: !hasMore,
                onClick: () => {
                  let cp = currentPage
                  setCurrentPage(++cp)
                },
              }}
              isLastRecord={!hasMore}
            />
          ) : (
            <CustomTable
              cols={data}
              tableFn={tableFn}
              isFirstColStatic={true}
              isLoading={true}
              rowIsLoading={false}
              notFoundDes={t("SearchKOL.no-result")}
              isLastRecord={true}
            />
          )}
        </div>
      )}
    </>
  )
}
