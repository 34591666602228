import React from "react"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import skipPaymentVector from "../../Assets/skip_payment_modal.jpg"
import CustomAlert from "../NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../Enum/ALERT_SYSYEM"
import { Trans } from "react-i18next"

const ContractSkipPaymentModalComponent = () => {
  return (
    <div>
      <img className="w-[450px] object-cover mx-auto" src={skipPaymentVector} />
      <p className="md:px-16 mt-4 md:mt-0 text-center font-medium">
        <Trans>ContractSkipPayment.modal-desc</Trans>
      </p>
      <p className="md:px-16 mt-4 md:mt-0 text-center text-red-400 font-medium">
        <Trans>ContractSkipPayment.modal-warning</Trans>
      </p>
      {/* <CustomAlert
        title="ContractSkipPayment.alert-title"
        alertType={ALERT_TYPE.WARNING}
        buttonType={{
          type: BUTTON_TYPE.NONE,
        }}
      /> */}
    </div>
  )
}

export default ContractSkipPaymentModalComponent
