import React from "react"
import { Link } from "react-router-dom"
import { Trans } from "react-i18next"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import facebookIcon from "../Assets/facebook-brands.svg"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import InstagramIcon from "../Assets/instagram-brands.svg"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LinkedinIcon from "../Assets/linkedin-brands.svg"
import { getUserLang } from "../Utils/utiltyHelper"

const Footer: React.FC = () => {
  return (
    <>
      <footer className="p-10 footer bg-base-200 text-base-content">
        <div>
          <span className="footer-title">
            <Trans>Footer.company</Trans>
          </span>
          <Link
            to={"/" + getUserLang() + "/about"}
            className="text-gray-400 hover:underline">
            <Trans>Footer.about-us</Trans>
          </Link>
        </div>
        <div>
          <span className="footer-title">
            <Trans>Footer.legal</Trans>
          </span>
          <Link
            to={"/" + getUserLang() + "/blog/terms"}
            className="text-gray-400 hover:underline">
            <Trans>Footer.terms</Trans>
          </Link>
          <Link
            to={"/" + getUserLang() + "/article/privacy-policy"}
            className="text-gray-400 hover:underline">
            <Trans>Footer.privacy-policy</Trans>
          </Link>
        </div>
      </footer>
      <footer className="px-10 py-4 border-t footer bg-base-200 text-base-content border-base-300">
        <div className="items-center grid-flow-col">
          <p>
            Copyright © {new Date().getFullYear()} HappyJai Limited. All
            contents © of their respective owners
          </p>
        </div>
        <div className="md:place-self-center md:justify-self-end">
          <div className="grid grid-flow-col gap-4">
            <a href="https://facebook.com/look4kol">
              <img
                src={facebookIcon}
                alt="facebook icon"
                className="mx-2 w-6 h-6 text-gray-400"
              />
            </a>
            <a href="https://instagram.com/look4kolhk">
              <img
                src={InstagramIcon}
                alt="Instagram icon"
                className="mx-2 w-6 h-6 text-gray-400"
              />
            </a>
            <a href="https://www.linkedin.com/company/look4kol">
              <img
                src={LinkedinIcon}
                alt="Linkedin icon"
                className="mx-2 w-6 h-6 text-gray-400"
              />
            </a>
          </div>
        </div>
      </footer>
    </>
  )
}
export default Footer
