import React, { useEffect, useState } from "react"
import { WalletHistoryModalData } from "../../Model/Modal"
import { Trans } from "react-i18next"
import { intToFloat, timeStampToDisplayTimeString } from "../../Utils/Converter"
import { getHKBankName } from "../../Utils/HKBankList"
import { PAYOUT_REQUEST_DATA } from "../../Model/AdminRequest"
import { Job, PAYMENT_METHOD, ReferralJob } from "../../Model/Job"
import { useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExternalLinkAlt,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons"
import { isChatroomExists } from "../../HelpingFunction/Chatroom/chatroomDBHelper"
import { getUserLang } from "../../Utils/utiltyHelper"
import classNames from "classnames"
import { ADMIN_REQUEST_STATUS_TYPE } from "../../Enum/APP_TYPE"
import { getCustomerCompanyName } from "../../HelpingFunction/Customer/customerDBHelper"
import { BankTransactionType } from "../../Model/BankTransaction"
import { getKOL } from "../../HelpingFunction/KOL/kolDBHelper"
import CustomAlert from "../NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../Enum/ALERT_SYSYEM"
import { t } from "i18next"

const WalletHistoryModalComponent: React.FC<WalletHistoryModalData> = ({
  metaData,
  closeModal,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const payout_data = metaData.data.data as PAYOUT_REQUEST_DATA
  const job_data = metaData.data as Job
  const referralJob: ReferralJob = metaData.data as ReferralJob
  const kolAward = parseInt(process.env.REACT_APP_KOL_AWARD as string)
  const history = useHistory()

  const [custName, setCustName] = useState<string>("")
  const [kolUserName, setkolUserName] = useState<string>("")

  useEffect(() => {
    if (metaData.type === BankTransactionType.JOB) {
      getCustomerName()
    } else if (metaData.type === BankTransactionType.REFERRAL) {
      getKOL(referralJob.KOLID).then((result) => {
        if (result.success) {
          setkolUserName(result.data.userName)
        }
      })
    }
  }, [metaData])

  const getCustomerName = async () => {
    const result = await getCustomerCompanyName(job_data.CustomerID)

    if (result.success) {
      setCustName(result.customerCompanyName)
    }
  }

  if (metaData.type === BankTransactionType.ADMIN_REQUEST) {
    return (
      <div>
        <div className={"text-center text-sm mb-8"}>
          <p>
            <Trans>WalletHistoryModal.payout-history</Trans>
          </p>
          <p className={"mb-2 opacity-60"}>
            {timeStampToDisplayTimeString(metaData.date)}
          </p>

          <span
            className={classNames("badge", {
              "bg-green-500":
                metaData.status === ADMIN_REQUEST_STATUS_TYPE.FINISHED,
              "bg-orange-400":
                metaData.status === ADMIN_REQUEST_STATUS_TYPE.PENDING,
              "bg-red-400":
                metaData.status === ADMIN_REQUEST_STATUS_TYPE.REJECT,
            })}>
            {metaData.status === ADMIN_REQUEST_STATUS_TYPE.FINISHED && (
              <Trans>MyPayoutRequest.finished</Trans>
            )}
            {metaData.status === ADMIN_REQUEST_STATUS_TYPE.PENDING && (
              <Trans>MyPayoutRequest.in-progress</Trans>
            )}
            {metaData.status === ADMIN_REQUEST_STATUS_TYPE.REJECT && (
              <Trans>MyPayoutRequest.rejected</Trans>
            )}
          </span>
        </div>

        <h3 className={"text-4xl text-md text-center mb-4"}>
          <span className={"text-red-500"}>
            {"-"}
            {payout_data.bankDetail.currency.toLocaleUpperCase()}{" "}
            {intToFloat((1 * metaData.amount) / 100, 2)}
          </span>
          <p className={"text-sm mt-4"}>
            <span>
              <Trans>WalletHistoryModal.handling-fee</Trans>:{" "}
              {intToFloat(payout_data.handlingFee / 100, 2)}
            </span>
          </p>
        </h3>
        <div className={"text-center mb-6"}>
          <p className={"text-xl"}>
            <Trans>WalletHistoryModal.payout</Trans>
          </p>
          <p className={"text-sm opacity-50"}>#{metaData.id}</p>
        </div>
        <hr />
        <div className={"mt-8 grid grid-cols-1 md:grid-cols-2 md:gap-4"}>
          <div>
            <h3 className={"mb-4"}>
              <span className={"opacity-50"}>
                <Trans>NewBankAccount.card-holder</Trans>:{" "}
              </span>
              {payout_data.bankDetail.cardHolder}
            </h3>
            <h3 className={"mb-4"}>
              <span className={"opacity-50"}>
                <Trans>NewBankAccount.account-number</Trans>:{" "}
              </span>
              {payout_data.bankDetail.accNum}
            </h3>
            <h3 className={"mb-4"}>
              <span className={"opacity-50"}>
                <Trans>WalletHistoryModal.apply-date</Trans>:{" "}
              </span>
              {timeStampToDisplayTimeString(metaData.date)}
            </h3>
          </div>
          <div>
            <h3 className={"mb-4"}>
              <span className={"opacity-50"}>
                <Trans>NewBankAccount.bank-location</Trans>:{" "}
              </span>
              {payout_data.bankDetail.bankLocation}
            </h3>
            <h3 className={"mb-4"}>
              <span className={"opacity-50"}>
                <Trans>NewBankAccount.receiving-bank</Trans>:{" "}
              </span>
              {getHKBankName(payout_data.bankDetail.bankID)}
            </h3>
            {payout_data.hasOwnProperty("isFive") && (
              <h3 className={"mb-4"}>
                <span className={"opacity-70 text-amber-500"}>
                  <Trans>BankWithdrawModal.extra-5%</Trans>:{" "}
                </span>
                {payout_data.isFive ? (
                  <Trans>BankWithdrawModal.applied</Trans>
                ) : (
                  <Trans>BankWithdrawModal.not-applied</Trans>
                )}
              </h3>
            )}
          </div>
        </div>
      </div>
    )
  } else if (metaData.type === BankTransactionType.JOB) {
    const jobData = metaData.data as Job

    return (
      <div>
        {jobData.paymentMethod === PAYMENT_METHOD.SKIP && (
          <div className="mb-8">
            <CustomAlert
              title={t("MyPayoutRequest.skip-payment")}
              alertType={ALERT_TYPE.WARNING}
              buttonType={{ type: BUTTON_TYPE.NONE }}
              icon={faTriangleExclamation}
            />
          </div>
        )}

        <div className={"text-center text-sm mb-8"}>
          <p>
            <Trans>WalletHistoryModal.job-history</Trans>
          </p>
          <p className={"mb-2 opacity-60"}>
            {timeStampToDisplayTimeString(metaData.date)}
          </p>
          <span className="badge bg-green-500">
            <Trans>MyPayoutRequest.finished</Trans>
          </span>
        </div>

        <h3 className={"text-4xl text-md text-center mb-4"}>
          <span className={"text-green-500"}>
            {"+"}
            {job_data.currency.toLocaleUpperCase()}{" "}
            {intToFloat((1 * metaData.amount) / 100, 2)}
          </span>
        </h3>
        <div className={"text-center mb-6"}>
          <p className={"text-xl"}>
            <Trans>MyPayoutRequest.job</Trans>
          </p>
          <p className={"text-sm opacity-50"}>
            #{metaData.id}{" "}
            <FontAwesomeIcon
              className="ml-2"
              onClick={async () => {
                const chatroom = await isChatroomExists(
                  jobData.CustomerID,
                  jobData.KOLID
                )
                if (chatroom.success) {
                  const chatroomID = chatroom.chatroomID
                  history.push({
                    pathname: "/" + getUserLang() + "/c",
                    search:
                      "?" +
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      new URLSearchParams({
                        chatroomID: chatroomID,
                        type: "contract",
                        metaPath: metaData.id,
                      }),
                  })
                }
                closeModal()
              }}
              icon={faExternalLinkAlt}
            />
          </p>
        </div>
        <hr />
        <section>
          <div className="my-2 flex flex-row gap-4 text-sm">
            <p className="text-gray-500">
              <Trans>WalletHistoryModal.job-name</Trans>
            </p>
            <p>{job_data.ServicePlanName}</p>
          </div>
          <div className="my-2 flex flex-row gap-4 text-sm">
            <p className="text-gray-500">
              <Trans>WalletHistoryModal.customer</Trans>
            </p>
            <p>{custName}</p>
          </div>
          {/* <div className="my-2 flex flex-row gap-4 text-sm">
            <p className="text-gray-500">Job Start:</p>
            <p>{timeStampToDisplayTimeString(jobData.startDate)}</p>
          </div> */}

          <hr />
          <div className="my-2 flex flex-row gap-4 text-sm">
            <p className="text-gray-500">
              <Trans>WalletHistoryModal.amount</Trans>
            </p>
            <p>{jobData.price} HKD</p>
          </div>
          <div className="my-2 flex flex-row gap-4 text-sm">
            <p className="text-gray-500">
              <Trans>WalletHistoryModal.transaction</Trans>
            </p>
            <p>0 HKD</p>
          </div>
          {/* eslint-disable */}
          {jobData.fee === kolAward &&
            jobData.paymentMethod !== PAYMENT_METHOD.SKIP && (
              <div className="my-2 flex flex-row gap-4 text-sm">
                <p className="text-amber-500">
                  <Trans>WalletHistoryModal.grace</Trans>
                </p>
                <p>
                  {jobData.price * Math.abs(kolAward / 100)} HKD (
                  <Trans>WalletHistoryModal.grace-desc</Trans>)
                </p>
              </div>
            )}
          {/* eslint-enable */}
          <hr />
          <div className="my-2 flex flex-row gap-4 text-sm">
            <p className="text-gray-500">
              <Trans>WalletHistoryModal.total-paid</Trans>
            </p>
            <p> {intToFloat((1 * metaData.amount) / 100, 2)} HKD</p>
          </div>
        </section>
      </div>
    )
  } else if (metaData.type === BankTransactionType.REFERRAL) {
    const jobData = metaData.data as ReferralJob

    return (
      <div>
        <div className={"text-center text-sm mb-8"}>
          <p>
            <Trans>WalletHistoryModal.job-history</Trans>
          </p>
          <p className={"mb-2 opacity-60"}>
            {timeStampToDisplayTimeString(metaData.date)}
          </p>
          <span className="badge bg-green-500">
            <Trans>MyPayoutRequest.finished</Trans>
          </span>
        </div>

        <h3 className={"text-4xl text-md text-center mb-4"}>
          <span className={"text-green-500"}>
            {"+"}

            {intToFloat((1 * metaData.amount) / 100, 2)}
          </span>
        </h3>
        <div className={"text-center mb-6"}>
          <p className={"text-xl"}>
            <Trans>WalletHistoryModal.referral-job</Trans>
          </p>
          <p className={"text-sm opacity-50"}>#{metaData.id} </p>
        </div>
        <hr />
        <section>
          <div className="my-2 flex flex-row gap-4 text-sm">
            <p className="text-gray-500">
              <Trans>WalletHistoryModal.customer</Trans>
            </p>
            <p>{kolUserName}</p>
          </div>
          <hr />
          <div className="my-2 flex flex-row gap-4 text-sm">
            <p className="text-gray-500">
              <Trans>WalletHistoryModal.amount</Trans>
            </p>
            <p>{jobData.price} HKD</p>
          </div>

          <hr />
          <div className="my-2 flex flex-row gap-4 text-sm">
            <p className="text-gray-500">
              <Trans>WalletHistoryModal.total-paid</Trans>
            </p>
            <p> {intToFloat((1 * metaData.amount) / 100, 2)} HKD</p>
          </div>
        </section>
      </div>
    )
  }
  return <div />
}

export default WalletHistoryModalComponent
