import React, { ReactChild, useEffect, useRef, useState } from "react"
import { i18n } from "i18next"
import { Trans } from "react-i18next"
import classNames from "classnames"
import { useSelector } from "react-redux"
import { AnimatePresence, motion, useCycle } from "framer-motion"

interface ModalButton {
  text: i18n
  color: "primary" | "secondary" | "error" | "success" | "info"
  onClick: (params?: any) => void
  size: "32" | "64" | "full"
  noAutoClose?: boolean
  loadingState?: boolean
}

interface Modal {
  isOpen: boolean
  closeModal: () => void
  buttons: ModalButton[]
  title: string
  modalSize: "md" | "2xl" | "3xl" | "4xl" | "7xl"
  modalHeight?: "1/4" | "1/2" | "3/4" | "full"
  returnFn?: any
  auth?: any
  ref?: any
  children: ReactChild
  component: any
  metaData: any
  noCancelButton?: boolean
  haveLoading?: boolean
  disableOutbounds?: boolean
  forceOpen?: boolean
}

const CustomModal: React.FC<Modal> = ({
  isOpen,
  closeModal,
  title,
  modalSize,
  modalHeight,
  buttons,
  component: Component,
  metaData,
  returnFn,
  noCancelButton,
  haveLoading,
  disableOutbounds,
  forceOpen,
}) => {
  const refButton = useRef<any>(null)

  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })

  const screenHeight = useSelector((state: any) => {
    return state.SystemManager.screenHeight
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)
  const [swiped, setSwiped] = useState<boolean>(false)

  const minSwipeDistance = window.innerHeight / 2 - 100

  const onTouchStart = (e: any) => {
    setTouchEnd(null)
    setTouchStart(e.targetTouches[0].clientY)
  }
  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientY)
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isDownSwipe = distance < -minSwipeDistance
    if (isDownSwipe) {
      setSwiped(true)
      onCycle()
      setTimeout(closeModalFunc, 200)
    }
  }

  const closeModalFunc = () => {
    // document.getElementsByTagName("body")[0].style.overflow = "scroll"
    window.removeEventListener("click", detectClickClose)
    closeModal()
  }
  const detectClickClose = (e: any) => {
    if (
      Component &&
      document.getElementById("modal-box")?.contains(e.target) === false &&
      !disableOutbounds
    ) {
      closeModalFunc()
    }
  }

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", detectClickClose)
    }
  }, [isOpen])
  const [isVisible, onCycle] = useCycle(true, false)

  const maxHeightConditions = screenHeight > 800 ? "580px" : "475px"
  const paddingConditions = screenHeight > 700 ? "0px" : "80px"

  return (
    <div>
      <input type="checkbox" id="my-modal-3" className="modal-toggle" />
      <AnimatePresence>
        {isVisible && (
          <div className="modal modal-open">
            <motion.div
              key="modal"
              initial={false}
              animate={{ opacity: 1 }}
              exit={{ opacity: 1, y: window.innerHeight }}
              // drag={screenWidth < 768 ? "y" : false}
              // dragDirectionLock={true}
              // dragMomentum={false}
              // dragConstraints={{ top: 0, bottom: swiped ? 100 : 0 }}
              // dragElastic={{ top: 0, bottom: 1 }}
              className={classNames(
                "w-full",
                `md:max-w-${modalSize}`,
                modalHeight && screenWidth > 768 ? `h-${modalHeight}` : "h-3/4"
              )}>
              <div
                onTouchStart={screenWidth < 768 ? onTouchStart : undefined}
                onTouchMove={screenWidth < 768 ? onTouchMove : undefined}
                onTouchEnd={screenWidth < 768 ? onTouchEnd : undefined}
                id={"modal-box"}
                style={{
                  maxHeight: "100%",
                  paddingBottom:
                    modalHeight === "full" ? "80px" : paddingConditions,
                  marginBottom: "calc(env(safe-area-inset-bottom) + 40px)",
                }}
                className={classNames(
                  "modal-box relative w-full overflow-hidden h-full animated animatedFadeInUp fadeInUp mx-auto",
                  `md:max-w-${modalSize}`
                )}>
                <div
                  className={
                    "md:invisible visible  h-2 rounded-xl mb-4 w-full"
                  }>
                  <div
                    id={"swipeDownButton"}
                    className="md:invisible visible bg-gray-300 w-12 absolute top-2 h-2 rounded-xl mb-4"
                    style={{ left: "45%" }}></div>
                </div>

                {!forceOpen && (
                  <motion.label whileHover={{ scale: 1.2 }}>
                    <label
                      onClick={() => closeModalFunc()}
                      htmlFor="my-modal-3"
                      className=" btn btn-sm btn-circle bg-gray-400 text-2xl text-gray-300 border-0 absolute right-2 top-2">
                      ✕
                    </label>
                  </motion.label>
                )}

                {/*Header*/}
                <div className="w-full h-auto justify-center items-center">
                  {title ? (
                    <div className="font-bold text-lg md:text-2xl md:mb-2 text-gray-800 md:text-left text-center pb-2">
                      <Trans>{title}</Trans>
                    </div>
                  ) : null}
                </div>
                {/*Header End*/}

                {/*Body Starts*/}
                <div
                  className="container overflow-scroll"
                  style={{
                    height: "100%",
                    minHeight: "200px",
                    maxHeight:
                      modalHeight === "full" ? "none" : maxHeightConditions,
                  }}>
                  <Component
                    closeModal={closeModalFunc}
                    metaData={metaData}
                    ref={refButton}
                    returnFn={returnFn}
                    isOpen={isOpen}
                  />
                </div>
                {/* Body Ends*/}

                {/*Error Starts*/}

                {/*Bottom Button Starts*/}
                {buttons.length ? (
                  <div className="absolute safe-are-detection-bottom-absolute left-0 w-full bg-white md:px-8 pt-2 px-2 mb-0">
                    {buttons.map((button) => {
                      return (
                        <button
                          disabled={button.loadingState ?? isLoading}
                          onClick={() => {
                            if (haveLoading) {
                              setIsLoading(true)
                              setTimeout(() => {
                                setIsLoading(false)
                              }, 10000)
                            }
                            if (refButton.current) {
                              button.onClick(refButton.current.returnData())
                            } else {
                              button.onClick()
                            }
                            if (!button.noAutoClose) {
                              closeModalFunc()
                            }
                          }}
                          className={classNames(
                            "btn float-right rounded-md mb-2",
                            `w-${button.size}`,
                            `w-${button.size}`,
                            {
                              "btn-primary": button.color === "primary",
                              "btn-secondary": button.color === "secondary",
                              "btn-success": button.color === "success",
                              "btn-error": button.color === "error",
                              "btn-info": button.color === "info",
                              //eslint-disable-next-line
                              loading: button.loadingState ?? isLoading,
                            }
                          )}>
                          <Trans>{button.text}</Trans>
                        </button>
                      )
                    })}

                    <button
                      className={
                        noCancelButton
                          ? "invisible"
                          : "w-16 md:w-28 h-12 pb-2 rounded-md mx-4 md:mx-4 float-right md:visible invisible"
                      }
                      onClick={() => {
                        closeModalFunc()
                      }}>
                      <Trans>ContactKOLModal.cancel</Trans>
                    </button>
                  </div>
                ) : (
                  <></>
                )}

                {/*Bottom Button Ends*/}
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default CustomModal
