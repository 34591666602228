import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Trans, useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBirthdayCake,
  faGlobeAsia,
  faUsersCog,
  faVenusMars,
} from "@fortawesome/free-solid-svg-icons"
import { isAuth, returnIdTokenResult } from "../../Utils/FirebaseUtils"
import {
  IG_USER_CRAWLER_DATA,
  IG_USER_DATA,
  igUserDataDefault,
} from "../../Enum/LOGIN_PAGE_TYPE"
import {
  getKOLDataFromCrawler,
  getKOLFollowerGroup,
  kolCrawlerFollowerData,
} from "../../HelpingFunction/KOL/kolDBHelper"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../Utils/ModalType"
import { useHistory } from "react-router-dom"
import { getFollowersData } from "../../HelpingFunction/FollowersData/FollowerDBHelper"
import { COMPONENT_DISPLAY } from "../../Enum/APP_TYPE"

interface PropsType {
  KOLID: string
  displayMethod?: COMPONENT_DISPLAY
}

const KOLFollowerGroup: React.FC<PropsType> = ({ KOLID, displayMethod }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  let history = useHistory()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const [isLoaded, setIsLoaded] = useState(false)
  const [followerGroup, setFollowerGroup] =
    useState<IG_USER_DATA>(igUserDataDefault)
  const [igKOLData, setIgKOLData] = useState<
    IG_USER_CRAWLER_DATA | IG_USER_DATA
  >({
    numOfPosts: 0,
    numOfFollowers: 0,
    numOfFollowing: 0,
    engagementRate: 0,
    imagesList: [],
    lastUpdate: new Date(),
    // eslint-disable-next-line camelcase
    audience_city: [],
    // eslint-disable-next-line camelcase
    audience_country: [],
    // eslint-disable-next-line camelcase
    audience_gender_age: [],
    // eslint-disable-next-line camelcase
    audience_locale: [],
    id: "",
    // eslint-disable-next-line camelcase
    ig_id: "",
    username: "",
    biography: "",
  })
  const [role, setRole] = useState("")
  const [updateDate, setUpdateDate] = useState<Date>(new Date())

  useEffect(() => {
    getKOLFollowerGroup(KOLID)
      .then((res) => {
        if (res.success) {
          setFollowerGroup(res.data)
          setIsLoaded(true)
        } else {
          setFollowerGroup(igUserDataDefault)
          setIsLoaded(true)
        }
      })
      .catch((err) => console.log(err))
  }, [KOLID])

  useEffect(() => {
    if (isAuth(auth)) {
      getRole()
    }
  }, [auth])

  useEffect(() => {
    getFollowersData(KOLID, "ig").then((res) => {
      if (res.success) {
        if (res.igData !== undefined) {
          setIgKOLData(res.igData)
        }
      }
    })
    let tempDate = new Date()
    tempDate.setDate(new Date(igKOLData.lastUpdate).getDate() + 7)

    setUpdateDate(tempDate)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      updateInfo()
    }, 2000)
  }, [])

  const updateInfo = async () => {
    let date = new Date()

    if (updateDate !== null || updateDate !== undefined) {
      if (date > updateDate) {
        const igUsername = igKOLData as IG_USER_DATA
        await getKOLDataFromCrawler(auth.uid, igUsername.username).then(
          (res) => {
            if (res.success) {
              const data = res.data as kolCrawlerFollowerData
              setFollowerGroup({ ...followerGroup, ...data })
              setIgKOLData({ ...igKOLData, ...data })
            }
          }
        )
      }
    }
  }

  const getRole = async () => {
    const getState = await returnIdTokenResult()
    if (getState.success && typeof getState.role !== "undefined") {
      setRole(getState.role)
    }
  }

  const openModal = (): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.VIEW_FOLLOWERS_DETAIL_MODAL,
      metaData: igKOLData,
    })
  }

  const returnMainCity = (audienceCityData: object): string => {
    if (
      typeof audienceCityData !== "undefined" &&
      !Array.isArray(audienceCityData) &&
      Object.keys(audienceCityData).length !== 0
    ) {
      let sortedData = Object.entries(audienceCityData).sort(
        (a: any, b: any) => {
          if (a[1] < b[1]) return 1
          if (a[1] > b[1]) return -1
          return 0
        }
      )
      return sortedData[0][0]
    }
    return t("FOLLOWERS_GENDER.NA")
  }

  const returnMainGender = (audienceGenderData: object): string => {
    let F = 0
    let M = 0
    let U = 0

    if (
      typeof audienceGenderData !== "undefined" &&
      !Array.isArray(audienceGenderData)
    ) {
      Object.entries(audienceGenderData).map((e) => {
        if (e[0].includes("F")) {
          F += e[1]
        } else if (e[0].includes("M")) {
          M += e[1]
        } else if (e[0].includes("U")) {
          U += e[1]
        }
      })
    }

    let max = Math.max(F, M, U)
    if (max === F && max !== 0) return t("FOLLOWERS_GENDER.F")
    else if (max === M && max !== 0) return t("FOLLOWERS_GENDER.M")
    else if (max === U && max !== 0) return t("FOLLOWERS_GENDER.U")
    return t("FOLLOWERS_GENDER.NA")
  }

  const returnMainAge = (audienceAgeData: object): string => {
    let age13T17 = 0
    let age18T24 = 0
    let age25T34 = 0
    let age35T44 = 0
    let age45T54 = 0
    let age55T64 = 0
    let age65 = 0

    if (
      typeof audienceAgeData !== "undefined" &&
      !Array.isArray(audienceAgeData)
    ) {
      Object.entries(audienceAgeData).map((e) => {
        if (e[0].includes("13-17")) {
          age13T17 += e[1]
        } else if (e[0].includes("18-24")) {
          age18T24 += e[1]
        } else if (e[0].includes("25-34")) {
          age25T34 += e[1]
        } else if (e[0].includes("35-44")) {
          age35T44 += e[1]
        } else if (e[0].includes("45-54")) {
          age45T54 += e[1]
        } else if (e[0].includes("55-64")) {
          age55T64 += e[1]
        } else {
          age65 += e[1]
        }
      })
    }
    let max = Math.max(
      age13T17,
      age18T24,
      age25T34,
      age35T44,
      age45T54,
      age55T64,
      age65
    )

    if (max === age13T17 && max !== 0) return "13 - 17"
    else if (max === age18T24 && max !== 0) return "18 - 24"
    else if (max === age25T34 && max !== 0) return "25 - 34"
    else if (max === age35T44 && max !== 0) return "35 - 44"
    else if (max === age45T54 && max !== 0) return "45 - 55"
    else if (max === age55T64 && max !== 0) return "55 - 64"
    else if (max === age65 && max !== 0) return "65+"
    return t("FOLLOWERS_GENDER.NA")
  }

  if (isLoaded) {
    if (displayMethod === COMPONENT_DISPLAY.LIST) {
      return (
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-1 text-gray-500 font-light my-4">
          {/* age*/}
          <li
            className="grid gap-4 px-1 py-2"
            style={{ gridTemplateColumns: "20px auto" }}>
            <div>
              <FontAwesomeIcon
                icon={faBirthdayCake}
                className="text-center text-xl mr-4"
              />
            </div>
            <div>{returnMainAge(followerGroup.audience_gender_age)}</div>
          </li>

          {/* gender*/}
          <li
            className="grid gap-4 px-1 py-2"
            style={{ gridTemplateColumns: "20px auto" }}>
            <div>
              <FontAwesomeIcon
                icon={faVenusMars}
                className="text-center text-xl mr-4"
              />
            </div>
            <div>{returnMainGender(followerGroup.audience_gender_age)}</div>
          </li>

          {/* city */}
          <li
            className="grid gap-4 px-1 py-2"
            style={{ gridTemplateColumns: "20px auto" }}>
            <div>
              <FontAwesomeIcon
                icon={faGlobeAsia}
                className="text-center text-xl mr-4"
              />
            </div>
            <div>{returnMainCity(followerGroup.audience_city)}</div>
          </li>
        </ul>
      )
    }
    return (
      <div className="px-2 ">
        <div className="block">
          <ul>
            <li className="my-8">
              <div
                className="grid"
                style={{ gridTemplateColumns: "50px auto 100px" }}>
                <div>
                  <FontAwesomeIcon
                    icon={faBirthdayCake}
                    className="text-center text-xl text-primary"
                  />
                </div>
                <div className="text-gray-700 text-md">
                  <Trans>KOLFollowerGroup.followers-age-group</Trans>
                </div>
                <div>{returnMainAge(followerGroup.audience_gender_age)}</div>
              </div>
            </li>

            <li className="my-8">
              <div
                className="grid"
                style={{ gridTemplateColumns: "50px auto 100px" }}>
                <div>
                  <FontAwesomeIcon
                    icon={faVenusMars}
                    className="text-center text-xl text-primary"
                  />
                </div>
                <div className="text-gray-700 text-md">
                  <Trans>KOLFollowerGroup.followers-gender</Trans>
                </div>
                <div>{returnMainGender(followerGroup.audience_gender_age)}</div>
              </div>
            </li>

            <li className="my-8">
              <div
                className="grid"
                style={{ gridTemplateColumns: "50px auto 100px" }}>
                <div>
                  <FontAwesomeIcon
                    icon={faGlobeAsia}
                    className="text-center text-xl text-primary"
                  />
                </div>
                <div className="text-gray-700 text-md">
                  <Trans>KOLFollowerGroup.followers-country</Trans>
                </div>
                <div>{returnMainCity(followerGroup.audience_city)}</div>
              </div>
            </li>
          </ul>
        </div>

        <div className="block">
          {isAuth(auth) && role !== "" ? (
            <button
              id={"KOLFollowerGroup_KOLMoreDetail"}
              className="w-full py-2 px-4 rounded-2xl border border-gray-500 text-gray-700 hover:bg-gray-100 my-2"
              onClick={() => {
                openModal()
              }}>
              <Trans>KOLFollowerGroup.more-detail</Trans>
            </button>
          ) : (
            <button
              className="w-full py-2 px-4 rounded-2xl border border-gray-500 text-gray-700 hover:bg-gray-100 my-2"
              onClick={() =>
                history.push(
                  "/" +
                    (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
                    "/login"
                )
              }>
              <Trans>KOLFollowerGroup.login-for-more-detail</Trans>
            </button>
          )}
        </div>
      </div>
    )
  }
  return <div>loading...</div>
}
export default KOLFollowerGroup
