import React, { useEffect, useState } from "react"
import { getFollowersData } from "../../HelpingFunction/FollowersData/FollowerDBHelper"
import { IG_USER_DATA, igUserDataDefault } from "../../Enum/LOGIN_PAGE_TYPE"
import { useDispatch, useSelector } from "react-redux"
import { isCDNImage, notErrorImage } from "../../Utils/HelpingFunction"
import { returnThumbnailUrl } from "../../Utils/utiltyHelper"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../Utils/ModalType"
import { Trans } from "react-i18next"
import { followersCount } from "../../Utils/Converter"
import classNames from "classnames"
import DivContentLoader from "../../Component/DivContentLoader"

interface PropsType {
  KOLID: string
  isChatGPTRecm?: boolean
}

const KOLNewestIGStatus: React.FC<PropsType> = ({ KOLID, isChatGPTRecm }) => {
  const dispatch = useDispatch()
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const [igUserData, setIGUserData] = useState<IG_USER_DATA>(igUserDataDefault)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const setViewImageModal = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.VIEW_IMAGE_MODAL,
      returnFn: state.returnFn,
      metaData: state.metaData,
    })
  }

  useEffect(() => {
    getKOLIGDetails()
  }, [auth, KOLID])

  const getKOLIGDetails = async () => {
    setIsLoaded(false)
    await getFollowersData(KOLID, "ig").then((res) => {
      if (res.success) {
        if (res.igData) {
          setIGUserData(res.igData as IG_USER_DATA)
          setIsLoaded(true)
        } else {
          setIGUserData(igUserDataDefault)
          setIsLoaded(true)
        }
      } else {
        setIGUserData(igUserDataDefault)
        setIsLoaded(true)
      }
    })
  }

  if (isLoaded) {
    return (
      <>
        <div
          className={classNames(
            "grid grid-cols-3 col-span-3 my-4 bg-gray-100 p-2 rounded-2xl border border-gray-100",
            {
              "mt-0": isChatGPTRecm,
            }
          )}>
          <div className={"text-center my-auto"}>
            <p className={"text-lg font-semibold"}>
              {igUserData.numOfPosts ? igUserData.numOfPosts : 0}
            </p>
            <h3 className="text-sm text-gray-500 font-normal">
              <Trans>SignUp.posts</Trans>
            </h3>
          </div>
          <div className={"text-center  my-auto"}>
            <p className={"text-lg font-semibold"}>
              {igUserData.numOfFollowers
                ? followersCount(igUserData.numOfFollowers)
                : 0}
            </p>
            <h3 className="text-sm text-gray-500 font-normal">
              <Trans>SignUp.followers</Trans>
            </h3>
          </div>
          <div className={"text-center  my-auto"}>
            <p className={"text-lg font-semibold"}>
              {igUserData.numOfFollowing ? igUserData.numOfFollowing : 0}
            </p>
            <h3 className="text-sm text-gray-500 font-normal">
              <Trans>SignUp.following</Trans>
            </h3>
          </div>
        </div>
        <div className="grid gap-2 grid-cols-2 lg:grid-cols-3">
          {Array.isArray(igUserData.imagesList) &&
            igUserData.imagesList.map((imageSrc, index) => {
              if (
                index < 6 &&
                !isCDNImage(imageSrc) &&
                notErrorImage(imageSrc)
              ) {
                return (
                  <div
                    className="cursor-pointer"
                    key={"img" + index}
                    onClick={() =>
                      setViewImageModal({
                        returnFn: null,
                        metaData: {
                          imageUrl: imageSrc,
                        },
                      })
                    }>
                    <img
                      src={returnThumbnailUrl(imageSrc)}
                      className="w-full h-40 md:h-32 lg:h-32 xl:h-36 object-cover rounded-md"
                      alt={igUserData.username + " cover image " + (index + 1)}
                    />
                  </div>
                )
              }
            })}
        </div>
      </>
    )
  }
  return (
    <div>
      <div
        className={classNames(
          "grid grid-cols-3 col-span-3 my-4 bg-gray-100 p-2 rounded-2xl border border-gray-100"
        )}>
        <div className={"text-center my-auto"}>
          <DivContentLoader w={20} h={20} r={5} />
          <h3 className="text-sm text-gray-500 font-normal">
            <Trans>SignUp.posts</Trans>
          </h3>
        </div>
        <div className={"text-center  my-auto"}>
          <DivContentLoader w={20} h={20} r={5} />
          <h3 className="text-sm text-gray-500 font-normal">
            <Trans>SignUp.followers</Trans>
          </h3>
        </div>
        <div className={"text-center  my-auto"}>
          <DivContentLoader w={20} h={20} r={5} />
          <h3 className="text-sm text-gray-500 font-normal">
            <Trans>SignUp.following</Trans>
          </h3>
        </div>
      </div>
      <div className="grid gap-2 grid-cols-2 lg:grid-cols-3">
        <DivContentLoader w={"100%"} h={150} r={10} />
        <DivContentLoader w={"100%"} h={150} r={10} />
        <DivContentLoader w={"100%"} h={150} r={10} />
        <DivContentLoader w={"100%"} h={150} r={10} />
        <DivContentLoader w={"100%"} h={150} r={10} />
        <DivContentLoader w={"100%"} h={150} r={10} />
      </div>
    </div>
  )
}
export default KOLNewestIGStatus
