import { Reducer } from "react"
import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"
import { setMemberBookMarkList } from "../../HelpingFunction/Member/memberDBHelper"

interface actionTypes {
  type: string
  data: any
}

export interface modalInitStateType {
  bookMarkedKOLs: string[]
  currentFbPageID: string
}

const initialState: modalInitStateType = {
  bookMarkedKOLs: [],
  currentFbPageID: "",
}

const setBookMarkList = async (kol: string, uid: string) => {
  if (initialState.bookMarkedKOLs.includes(kol)) {
    initialState.bookMarkedKOLs = initialState.bookMarkedKOLs.filter(
      (id) => id !== kol
    )
  } else {
    initialState.bookMarkedKOLs.push(kol)
  }

  await setMemberBookMarkList(uid, initialState.bookMarkedKOLs).then(
    (res) => null
  )

  return initialState.bookMarkedKOLs
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const MemberManager: Reducer<modalInitStateType, actionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SUPPORTED_REDUX_FUNCTIONS.SET_BOOKMARK_LIST:
      return {
        ...state,
        bookMarkedKOLs: setBookMarkList(
          action.data.kol as string,
          action.data.uid as string
        ),
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_ALL_BOOKMARK_LIST:
      return {
        ...state,
        bookMarkedKOLs: action.data as string[],
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_FACEBOOK_PAGE_ID:
      return {
        ...state,
        currentFbPageID: action.data as string,
      }
    default:
      return state
  }
}

export default MemberManager
