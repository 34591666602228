import React, { useEffect, useState } from "react"
import CaseStudyCard from "./CaseStudyCard"
import { Trans } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../Utils/ModalType"
import { getCaseStudies } from "../HelpingFunction/KOL/kolDBHelper"
import { useLocation } from "react-router-dom"
import classNames from "classnames"
import KOLPlanItem from "../page-components/home/KOLPlanItem"
import { CAROUSEL_TYPE } from "../Enum/CAROUSEL_SYSTEM"
import { CustomCarousel } from "./NewCarousel"

const CaseStudiesList = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const auth = useSelector((state) => {
    return state.firebase.auth
  })

  const [isLoaded, setIsLoaded] = useState(false)
  const [cases, setCases] = useState([])

  useEffect(() => {
    getCaseStudies(props.KOLID, false, false)
      .then((result) => {
        if (result.success) {
          setCases(result.data)
          setIsLoaded(true)
        } else {
          setCases([])
          setIsLoaded(true)
        }
      })
      .catch((err) => console.log(err))
  }, [auth, props.KOLID])

  const setCaseStudyModal = (state) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.CASE_STUDY_MODAL,
      returnFn: state.returnFn,
      metaData: state.metaData,
    })

  if (isLoaded) {
    return (
      <CustomCarousel
        dataSets={cases}
        isLoaded={isLoaded}
        displayComponent={(data, index) => (
          <CaseStudyCard
            key={"csc" + index}
            setCaseStudyModal={setCaseStudyModal}
            data={data}
            auth={auth}
            caseLength={cases.length}
          />
        )}
        indexName={CAROUSEL_TYPE.CASE_STUDY}
        emptyDataMessage={"KOLProfile.not-found"}
        forceMobileLayout={true}
      />
    )
  }
  return <div>loading...</div>
}
export default CaseStudiesList
