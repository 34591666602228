import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation, withRouter } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"
import { motion } from "framer-motion"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "../HeadingMessageManager/HeadingMessageManager.module.css"
import KOL, { KOLType } from "../../Model/KOL"
import DivContentLoader from "../DivContentLoader"
import {
  routerPageDesktop,
  routerTransitionDesktop,
} from "../../Utils/PageTransition"
import { CustomerType } from "../../Model/Customer"
import OwnedElement from "./OwnedElement"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"
import { getQuery, useQuery } from "../../Utils/QueryHelper"

interface PropsType {
  closeModal?: () => void
  metaData: {
    KOLList: KOLType[]
    CustomerData: CustomerType
  }
}

const OwnedKOLAccess: React.FC<PropsType> = ({ closeModal, metaData }) => {
  let { t } = useTranslation()

  const params = useSelector((state: any) => {
    return state.QueryManager.params
  })

  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })

  const KOLList = metaData.KOLList
  const CustomerData = metaData.CustomerData

  const [searchString, setSearchString] = useState<string>("")
  const [searchKOLList, setSearchKOLList] = useState<KOLType[]>([...KOLList])

  const viewID = getQuery(params, "viewAs")
  useEffect(() => {
    if (searchString !== "") {
      const newKOLList = KOLList.filter((kol) =>
        kol.userName.includes(searchString)
      )
      if (newKOLList) {
        setSearchKOLList(newKOLList)
      }
    } else {
      setSearchKOLList(KOLList)
    }
  }, [searchString])

  if (CustomerData) {
    return (
      <motion.div
        initial="out"
        exit="out"
        animate="in"
        variants={routerPageDesktop}
        transition={routerTransitionDesktop}
        className="h-full">
        <div
          className={classNames(
            {
              "menu menu-compact dropdown-content mt-3 p-2 gap-1":
                screenWidth < 768,
            },
            classes.HeadingMessageManager
          )}
          style={{ maxHeight: "auto", overflow: "visible" }}
          aria-labelledby="noti-menu">
          <h2 className="font-bold text-xl p-2">
            <Trans>OwnedKOL.title</Trans>
          </h2>
          <li className="my-auto mx-1 md:mx-2 text-md text-gray-700 font-semibold">
            <div className="form-control border border-gray-100 text-gray-800 rounded-full">
              <div className="input-group text-md ">
                <FontAwesomeIcon
                  icon={faSearch}
                  className="mx-1 px-2 my-auto "
                />
                <input
                  type="text"
                  placeholder={t("OwnedKOL.search-des") as string}
                  className="input w-full border border-gray-100 bg-gray-50 text-gray-800"
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                />
              </div>
            </div>
          </li>
          <div
            className="grid overflow-y-scroll h-96"
            style={{
              gridTemplateRows:
                searchString !== "" ? "50px auto" : "50px auto 50px auto",
            }}>
            {/* customer block will only appear when no search actions*/}
            {searchString === "" && (
              <h2 className="font-semibold p-2 mt-1">
                <Trans>OwnedKOL.group-customer</Trans>
              </h2>
            )}
            {searchString === "" && (
              <ul style={{ paddingLeft: "0" }} className="">
                <OwnedElement
                  owned={{
                    id: CustomerData.id,
                    avatarUrl: CustomerData.avatarUrl,
                    userName: CustomerData.companyName,
                    email: CustomerData.contactEmail,
                  }}
                  isSelected={viewID === null}
                  isCustomer={true}
                  closeModal={closeModal}
                />
              </ul>
            )}

            <h2 className="font-semibold p-2 mt-1">
              <Trans>OwnedKOL.group-kol</Trans>
            </h2>
            <ul style={{ paddingLeft: "0", height: "23rem" }}>
              {Array.isArray(searchKOLList) && searchKOLList.length > 0 ? (
                searchKOLList.map((ownedKOL: KOL, index: number) => {
                  if (index >= 0) {
                    return (
                      <OwnedElement
                        owned={{
                          id: ownedKOL.id,
                          avatarUrl: ownedKOL.avatarUrl,
                          userName: ownedKOL.userName,
                          email: ownedKOL.email,
                        }}
                        isSelected={ownedKOL.id === viewID}
                        isCustomer={false}
                        closeModal={closeModal}
                      />
                    )
                  }
                  return <div />
                })
              ) : (
                <div className="p-4 text-center text-gray-500 font-bold">
                  <Trans>OwnedKOL.no-result</Trans>
                </div>
              )}
            </ul>
          </div>
        </div>
      </motion.div>
    )
  }
  return (
    <div>
      <h2 className="font-bold text-lg mb-2 p-2">
        <Trans>OwnedKOL.title</Trans>
      </h2>
      <div>
        <DivContentLoader w={"98%"} h={60} r={8} />
      </div>
      <div>
        <DivContentLoader w={"98%"} h={60} r={8} />
      </div>
      <div>
        <DivContentLoader w={"98%"} h={60} r={8} />
      </div>
    </div>
  )
}

export default OwnedKOLAccess
