import { faCircleXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Trans } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { KOLCombineWithAvatar } from "../../Model/KOLCombine"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"

const PreviewSelectedKOLModalComponent = () => {
  const dispatch = useDispatch()

  const selectedKOLs: KOLCombineWithAvatar[] = useSelector((state: any) => {
    return state.ToolsManager.selectedKOLs
  })

  const isSearchPage = window.location.href.includes("/s")

  const removeFromSelectedList = (kolID: string) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.REMOVE_FROM_SELECTED_KOL,
      selectedKOLID: kolID,
    })
  }

  return (
    <div>
      <p className="text-sm text-gray-500 font-medium">
        <Trans>QuotationPage.modal-desc</Trans>
      </p>
      <div className="my-4">
        {selectedKOLs.map((k, index) => {
          return (
            <div className="flex flex-row gap-2 my-2 items-center">
              <img src={k.avatarUrl} className="w-6 h-6 rounded-full" />
              <p className="text-gray-700 font-semibold text-lg">
                {k.userName}
              </p>
              {isSearchPage && (
                <div>
                  <button
                    className="text-gray-700 hover:text-black hover:scale-105"
                    onClick={() => removeFromSelectedList(k.id)}>
                    <FontAwesomeIcon icon={faCircleXmark} />
                  </button>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PreviewSelectedKOLModalComponent
