import { faArrowLeft, faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { getUserLang } from "../../Utils/utiltyHelper"
import { Trans } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { RecomStep } from "../../Model/RecomTools"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"

const AssistantChatHeader = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const step: RecomStep = useSelector((state: any) => {
    return state.RecommendationManager.recommendationStep
  })

  const setStep = (state: RecomStep) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_RECOMMENDATION_STEP,
      data: state,
    })
  }

  return (
    <div className="shadow relative">
      <section className="px-4 py-4 flex flex-row items-center gap-2">
        <button
          className="text-gray-700 "
          onClick={() => {
            if (step === RecomStep.CONTRACT) {
              setStep(RecomStep.KOL)
            } else {
              history.push("/" + getUserLang() + "/assistant-chat")
            }
          }}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <h1 className="text-lg text-gray-700 font-medium">
          {step === RecomStep.KOL && (
            <Trans>AssistantRecom.kol-selection</Trans>
          )}
          {step === RecomStep.CONTRACT && (
            <Trans>AssistantRecom.quotation-step</Trans>
          )}
        </h1>
      </section>
    </div>
  )
}

export default AssistantChatHeader
