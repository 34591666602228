import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Trans } from "react-i18next"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "./HeadingMessageManager.module.css"
import "firebase/analytics"
import Spinner from "../Spinner"
import MessageItem from "./MessageItem/MessageItem"
import { motion } from "framer-motion"
import {
  routerPage,
  routerPageDesktop,
  routerTransition,
  routerTransitionDesktop,
} from "../../Utils/PageTransition"
import { isAuth } from "../../Utils/FirebaseUtils"
import { getMessage } from "../../HelpingFunction/Chatroom/chatroomDBHelper"
import { setMessageAsRead } from "../../HelpingFunction/Member/memberDBHelper"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import step1 from "../../Assets/message-popup-instruction-1.png"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import step2 from "../../Assets/message-popup-instruction-2.png"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import { SYSTEM_MESSAGE_TYPE, UNREAD_MESSAGE_TYPE } from "../../Enum/APP_TYPE"
import { NotiMessage } from "../../Model/Message"
import { useHistory, useLocation } from "react-router-dom"
import { GCMManager } from "../../page-components/setting/GCMManager"
import classNames from "classnames"
import { getAuthUID, getQuery, useQuery } from "../../Utils/QueryHelper"

interface PropsType {
  isPopup: boolean
  setIsMessageDropDownOpen: () => void
}

const HeadingMessageManager: React.FC<PropsType> = ({
  isPopup,
  setIsMessageDropDownOpen,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const messages = useSelector((state: any) => {
    return state.NotificationManager.msg
  })

  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })

  const params = useSelector((state: any) => {
    return state.QueryManager.params
  })

  interface msgCondition {
    isRead: boolean
    allMessage: boolean
  }

  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [isReadAll, setIsReadAll] = useState<boolean>(false)
  const [condition, setCondition] = useState<msgCondition>({
    isRead: false,
    allMessage: false,
  })

  const viewID = getQuery(params, "viewAs")
  useEffect(() => {
    if (isAuth(auth)) {
      getContractMessage()
    }
  }, [auth, condition, isReadAll, viewID])

  const resetNumOfUnreadContracts = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_NUM_OF_UNREAD_CONTRACT,
      numOfUnread: 0,
    })
  }

  const setMessages = (msg: any[]) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_MESSAGES,
      data: msg,
    })
  }

  const getContractMessage = () => {
    setIsLoaded(false)
    getMessage(
      condition,
      isReadAll,
      getAuthUID(params),
      UNREAD_MESSAGE_TYPE.JUST_CONTRACT
    ).then((results) => {
      if (isReadAll) {
        results.data.map((msg) => {
          if (msg.type === SYSTEM_MESSAGE_TYPE.CONTRACT) setIsRead(msg.id)
        })
        resetNumOfUnreadContracts()
      }
      if (viewID) {
        const filteredMessages = results.data.filter(
          (c) => c.url !== auth.uid && !c.id?.includes("chatgpt")
        )
        setMessages(filteredMessages)
      } else {
        setMessages(results.data)
      }
      setIsLoaded(true)
    })
  }

  const setIsRead = (msgID: string) => {
    setMessageAsRead(getAuthUID(params), msgID).then((result) => {
      if (result.success) {
        let allMsg = messages
        let newMsg = allMsg.find((e: { id: string }) => e.id === msgID)
        let index = allMsg.findIndex((e: { id: string }) => e.id === msgID)
        allMsg[index] = newMsg
        setMessages(allMsg)
      }
    })
  }

  const dateSort = (
    a: { issueDate: { seconds: number } },
    b: { issueDate: { seconds: number } }
  ) => {
    return b.issueDate.seconds - a.issueDate.seconds
  }

  const isAllMessagePage =
    history.location.pathname.includes("/message") && !isPopup

  const isNotificationPopUp =
    !history.location.pathname.includes("/message") || isPopup

  const popUpLayout = {
    maxHeight: "auto",
    overflow: "visible",
  }
  const messagePageStyle =
    screenWidth < 768 || isAllMessagePage
      ? { gridTemplateRows: " auto auto 50px", overflow: "visible" }
      : popUpLayout

  if (isLoaded) {
    return (
      <motion.div
        initial="out"
        exit="out"
        animate="in"
        variants={screenWidth < 768 ? routerPage : routerPageDesktop}
        transition={
          screenWidth < 768 ? routerTransition : routerTransitionDesktop
        }
        className="h-full">
        <div
          className={[
            classes.HeadingMessageManager,
            screenWidth < 768 || isNotificationPopUp
              ? "grid"
              : "grid grid-cols-12 gap-4 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8",
          ].join(" ")}
          style={messagePageStyle}
          aria-labelledby="noti-menu">
          <div
            className={[
              isAllMessagePage
                ? "md:pr-4 col-span-12 md:col-span-4 md:border-r  md:border-gray-200 sticky top-0 md:top-10 z-20 bg-white p-4 rounded-b-xl md:rounded-none shadow-md md:shadow-none"
                : "relative",
            ].join("")}>
            <div className="flex justify-between">
              <h2 className="font-bold text-lg mb-2 p-2">
                <Trans>RouterManager.MessageCenter</Trans>
              </h2>
              {messages.length > 0 && isPopup && (
                <div>
                  <button
                    className="btn btn-sm btn-primary block rounded-2xl mt-2 w-full mx-auto"
                    onClick={() => {
                      setIsReadAll(true)
                    }}>
                    <Trans>HeadingMessageManager.mark-as-read</Trans>
                  </button>
                </div>
              )}
            </div>

            <div
              className={classNames({
                "sticky h-12": !isAllMessagePage,
              })}>
              <GCMManager />
            </div>

            {isAllMessagePage && (
              <div className="mb-4 md:my-4 absolute md:static right-0 -top-3  md:top-0">
                <button
                  className={[
                    " my-6 mx-2 md:my-2 md:mx-0 py-2 px-4 rounded-2xl block",
                    condition.allMessage
                      ? "bg-yellow-200 text-yellow-900"
                      : "bg-gray-200 text-gray-900",
                  ].join(" ")}
                  onClick={() => {
                    setCondition({
                      ...condition,
                      allMessage: !condition.allMessage,
                    })
                  }}>
                  <Trans>HeadingMessageManager.all-message</Trans>
                </button>

                <hr className="hidden md:inline" />
              </div>
            )}
          </div>

          <div
            id="MessageItem"
            className={[
              isAllMessagePage
                ? "col-span-12 md:col-span-8"
                : "overflow-y-scroll h-76 mt-2",
            ].join(" ")}>
            {messages.length > 0 ? (
              <ul
                className="my-2 mr-2"
                style={
                  isAllMessagePage
                    ? { minHeight: "61vh", paddingLeft: 0 }
                    : { paddingLeft: 0 }
                }>
                {messages
                  .sort(dateSort)
                  .map((msg: NotiMessage, index: number) => {
                    if (msg.type === SYSTEM_MESSAGE_TYPE.CONTRACT && index >= 0)
                      return (
                        <MessageItem
                          key={msg.id + "index"}
                          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                          // @ts-ignore
                          setIsMessageDropDownOpen={setIsMessageDropDownOpen}
                          setIsRead={setIsRead}
                          msg={msg}
                        />
                      )
                    return <div />
                  })}
              </ul>
            ) : (
              <div className="p-4 text-center text-gray-500 font-bold">
                <Trans>HeadingMessageManager.no-message</Trans>
              </div>
            )}
          </div>

          {isNotificationPopUp && (
            <div className="mt-4">
              <button
                className=" btn btn-sm btn-primary block rounded-2xl  w-full mx-auto"
                onClick={() =>
                  history.push(
                    "/" +
                      (localStorage.getItem("i18n-lang") === "en"
                        ? "en"
                        : "hk") +
                      "/message"
                  )
                }>
                <Trans>HeadingMessageManager.all-message</Trans>
              </button>
            </div>
          )}
        </div>
      </motion.div>
    )
  }
  return (
    <>
      <motion.div
        initial="out"
        exit="out"
        animate="in"
        variants={screenWidth < 768 ? routerPage : routerPageDesktop}
        transition={
          screenWidth < 768 ? routerTransition : routerTransitionDesktop
        }>
        <div className="text-center ">
          <div className="h-64 justify-center">
            <Spinner />
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default HeadingMessageManager
