import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../../Utils/ModalType"
import { useDispatch } from "react-redux"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { headerHelpDocs } from "../../../Utils/HelpButtonHelperFunction"

interface PropsType {
  helpDocs: headerHelpDocs
  disabled?: boolean
}

export const HelpDocHeader: React.FC<PropsType> = ({ helpDocs, disabled }) => {
  const dispatch = useDispatch()
  const openHelpDocModal = () => {
    console.log(helpDocs)
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.MOBILE_HELP_DOC_MODAL,
      returnFn: () => {
        return null
      },
      metaData: helpDocs,
    })
  }

  return (
    <button
      disabled={disabled}
      className="btn btn-sm btn-circle btn-ghost my-auto">
      <FontAwesomeIcon
        icon={faQuestionCircle}
        onClick={() => {
          openHelpDocModal()
        }}
      />
    </button>
  )
}
