import {
  faCalendar,
  faCirclePlus,
  faCircleUser,
  faPen,
  faPlus,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { createProject } from "../../../HelpingFunction/ProjectManagement/ProjectDBHelper"
import { Job } from "../../../Model/Job"
import KOL from "../../../Model/KOL"
import {
  PageType,
  Project,
  projectDefault,
} from "../../../Model/ProjectManagement"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { isAuth } from "../../../Utils/FirebaseUtils"
import { DatePicker } from "react-responsive-datepicker"
import "react-responsive-datepicker/dist/index.css"
import { useHistory } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"
import { timeStampToDisplayTimeString } from "../../../Utils/Converter"
import { getUserLang } from "../../../Utils/utiltyHelper"

const ProjectForm = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })
  const project: Project = useSelector((state: any) => {
    return state.ProjectManager.currentProject
  })
  const kolList: KOL[] = useSelector((state: any) => {
    return state.ProjectManager.kolList
  })
  const setProject = (project: Project) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CURRENT_PROJECT,
      data: project,
    })
  }
  const setPage = (page: PageType) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_PROJECT_PAGE,
      data: page,
    })
  }
  const setJobList = (jobs: Job[]) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_JOB_LIST,
      data: jobs,
    })
  }
  const setKOLList = (kols: KOL[]) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_KOL_LIST,
      data: kols,
    })
  }
  const closeMobileModal = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.CLOSE_MODAL,
    })
  }

  useEffect(() => {
    setProject(projectDefault)
  }, [])

  useEffect(() => {
    if (isAuth(auth)) {
      setProject({ ...project, customerID: auth.uid })
    }
  }, [auth])

  const [isAdding, setIsAdding] = useState<boolean>(false)
  const [showError, setShowError] = useState<{ status: boolean; text: string }>(
    { status: false, text: "" }
  )
  const [isStartOpen, setIsStartOpen] = useState(false)
  const [isEndOpen, setIsEndOpen] = useState(false)

  const createLocalProject = async () => {
    setIsAdding(true)
    project.createDate = new Date()
    if (project.budget < 0 || isNaN(project.budget)) {
      project.budget = 0
    }
    if (
      project.title !== "" &&
      project.budget !== null &&
      project.startDate !== null &&
      project.customerID !== ""
    ) {
      await createProject(project).then((res) => {
        if (res.success) {
          setIsAdding(false)
          setJobList([])
          setKOLList([])
          project.id = res.projectID
          setProject(project)
          if (screenWidth < 768) {
            closeMobileModal()
          }

          history.push("/" + getUserLang() + "/project/" + res.projectID)
        } else {
          setIsAdding(false)
          setShowError({ status: true, text: "There is an issue" })
          setTimeout(() => {
            setShowError({ status: false, text: "" })
          }, 3000)
        }
      })
    } else {
      setIsAdding(false)
      setShowError({ status: true, text: "Please fill all the details" })
      setTimeout(() => {
        setShowError({ status: false, text: "" })
      }, 3000)
    }
  }

  return (
    <section className="p-2 xl:p-4 bg-white rounded-lg h-auto my-2 shadow-md md:pb-0 pb-10 ">
      <p className="font-medium mb-2 text-gray-500">
        <Trans>ProjectOptionBar.title</Trans>*
      </p>
      <input
        type="text"
        placeholder={t("ProjectOptionBar.title") as string}
        maxLength={25}
        className="input input-bordered w-full "
        value={project.title}
        onChange={(e) => setProject({ ...project, title: e.target.value })}
      />
      <p className="text-xs text-right mt-1 text-gray-500">
        {project.title.length}/25
      </p>
      <p className="font-medium my-2 text-gray-500">
        <Trans>ProjectOptionBar.description</Trans>
      </p>
      <textarea
        value={project.description}
        onChange={(e) =>
          setProject({ ...project, description: e.target.value })
        }
        className="textarea textarea-bordered w-full"
        placeholder={t("ProjectOptionBar.description") as string}
      />
      <p className="font-medium my-2 text-gray-500">
        <Trans>ProjectOptionBar.budget</Trans>
      </p>
      <div className="flex flex-row gap-2 items-center">
        <input
          type="number"
          min={0}
          placeholder={t("ProjectOptionBar.budget") as string}
          className="input input-bordered w-full"
          value={project.budget}
          onChange={(e) =>
            setProject({ ...project, budget: parseInt(e.target.value) })
          }
        />
        <p>HKD</p>
      </div>
      <div className="block lg:gap-4 my-2">
        <div className="flex-1">
          <p className="font-medium my-2 text-gray-500">
            <Trans>ProjectOptionBar.start-date</Trans>
          </p>
          <button
            className="btn btn-sm gap-2"
            onClick={() => {
              setIsStartOpen(true)
            }}>
            <FontAwesomeIcon icon={faCalendar} />
            {"toDateString" in project.startDate
              ? project.startDate.toDateString()
              : timeStampToDisplayTimeString(project.startDate)}
          </button>

          {!project.startDate.hasOwnProperty("seconds") && (
            <DatePicker
              showTitle={false}
              isOpen={isStartOpen}
              onClose={() => setIsStartOpen(false)}
              maxDate={project.endDate as Date}
              defaultValue={project.startDate as Date}
              onChange={(e: Date | null) => {
                if (e) {
                  setProject({ ...project, startDate: e })
                }
              }}
            />
          )}
        </div>
        <div className="flex-1">
          <p className="font-medium my-2 text-gray-500">
            <Trans>ProjectOptionBar.finish-date</Trans>
          </p>
          <button
            className="btn btn-sm gap-2"
            onClick={() => {
              setIsEndOpen(true)
            }}>
            <FontAwesomeIcon icon={faCalendar} />
            {"toDateString" in project.endDate
              ? project.endDate.toDateString()
              : timeStampToDisplayTimeString(project.endDate)}
          </button>
          {!project.endDate.hasOwnProperty("seconds") && (
            <DatePicker
              isOpen={isEndOpen}
              showTitle={false}
              onClose={() => setIsEndOpen(false)}
              minDate={project.startDate as Date}
              defaultValue={project.endDate as Date}
              onChange={(e: Date | null) => {
                if (e) {
                  setProject({ ...project, endDate: e })
                }
              }}
            />
          )}
        </div>
      </div>
      <div>
        <p className="font-medium my-2 text-gray-500">
          <Trans>ProjectOptionBar.select-kol</Trans>
        </p>
        <div className="grid grid-cols-2">
          <div>
            <button
              onClick={() => setPage(PageType.KOL)}
              className="btn btn-outline btn-primary mr-2 group">
              <Trans>ProjectOptionBar.add</Trans>
              <FontAwesomeIcon
                className="text-xl ml-4 text-primary group-hover:text-white"
                icon={faCirclePlus}
              />
            </button>
          </div>
          {project.kolList.length ? (
            <div className="avatar-group -space-x-6">
              {kolList.map((kol, index) => {
                if (index < 3) {
                  return (
                    <div
                      key={kol.id}
                      className="tooltip relative group"
                      data-tip={kolList[0].userName}>
                      <FontAwesomeIcon
                        className="absolute top-0 right-2 z-40 text-xs group-hover:visible invisible text-gray-600"
                        icon={faPen}
                      />
                      <div
                        onClick={() => setPage(PageType.SelectedKOLList)}
                        className="cursor-pointer">
                        <div>
                          {kol.avatarUrl ? (
                            <img
                              className="h-12 w-12 rounded-full"
                              title={kol.userName}
                              src={kol.avatarUrl}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="text-primary h-12 w-12 rounded-full bg-gray-200"
                              icon={faCircleUser}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )
                }
              })}
              {project.kolList.length > 3 && (
                <div className="avatar placeholder">
                  <div className="w-12 h-12 rounded-full bg-neutral-focus text-neutral-content">
                    <span>+{project.kolList.length - 3}</span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div>
        <div className="form-control">
          <label className="label cursor-pointer">
            <span className="font-medium my-2 text-gray-500">
              <Trans>ProjectOptionBar.is-project-finished</Trans>
            </span>
            <input
              type="checkbox"
              checked={project.isFinish}
              onChange={() =>
                setProject({
                  ...project,
                  isFinish: !project.isFinish,
                })
              }
              className="checkbox mr-8"
            />
          </label>
        </div>
      </div>
      {showError.status && (
        <div>
          <div className="alert alert-error shadow-lg mb-2">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current flex-shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span>{showError.text}!</span>
            </div>
          </div>
        </div>
      )}

      <button
        onClick={() => createLocalProject()}
        className={
          isAdding
            ? "btn btn-success loading rounded-xl w-full font-bold"
            : "btn btn-success rounded-xl w-full font-bold "
        }>
        {!isAdding && <FontAwesomeIcon icon={faPlus} className="mr-2" />}{" "}
        {isAdding ? "Loading" : t("ProjectOptionBar.add-project")}
      </button>
    </section>
  )
}

export default ProjectForm
