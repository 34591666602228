import React, { useEffect } from "react"
import { useHistory, withRouter } from "react-router-dom"
import { Trans } from "react-i18next"
import { getUserLang } from "../../Utils/utiltyHelper"

const RequireLogin: React.FC = (props: any) => {
  const history = useHistory()
  useEffect(() => {
    setTimeout(() => {
      history.push("/" + getUserLang() + "/login")
    }, 5000)
  }, [])

  return (
    <div className="min-h-screen py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 w-full">
        <div className="max-w-md mx-auto border rounded-xl shadow-lg p-8 mb-32">
          <div className="block font-semibold text-xl self-start text-gray-700">
            <h2 className="leading-relaxed text-center">
              <Trans>RegForm.require-login</Trans>
            </h2>
            <p className="text-sm text-gray-500 font-normal leading-relaxed text-center">
              <Trans>RegForm.auto-logout</Trans>
            </p>
            <div className="w-full flex flex-col justify-center">
              <button className="mt-8 btn btn-block btn-primary loading">
                loading
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(RequireLogin)
