import React, { useEffect, useState } from "react"
import { timeStampToDateTimeString } from "../../../Utils/Converter"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import {
  faCheckCircle,
  faEnvelopeCircleCheck,
  faExclamationCircle,
  faPenFancy,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ReactQuill from "react-quill"
import { CONTRACT_ROLE } from "../../../Enum/CONTRACT"
import { SUPPORTED_MODAL_TYPE } from "../../../Utils/ModalType"
import parse from "html-react-parser"
import TimeScheduleController from "./TimeScheduleController"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReactQuillEditorCss from "../../../Utils/ReactQuillEditor.module.css"
import { Schedule } from "../../../Model/Schedule"
import { Job } from "../../../Model/Job"
import { v4 as uuidv4 } from "uuid"
import { createNewProduct } from "../../../HelpingFunction/EditKOLProfile/EditKOLProfile"
import { returnIdTokenResult } from "../../../Utils/FirebaseUtils"
import { getAPIPath } from "../../../Utils/HelpingFunction"
import { ALERT_TYPE, BUTTON_TYPE } from "../../../Enum/ALERT_SYSYEM"
import CustomAlert from "../../../Component/NewAlert"
import { getAuthUID, getQuery } from "../../../Utils/QueryHelper"
import classNames from "classnames"
import { getUserLang } from "../../../Utils/utiltyHelper"

const ContractMain: React.FC = (any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const params = useSelector((state: any) => {
    return state.QueryManager.params
  })
  const job = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomJob
  })

  const schedules: Schedule[] = useSelector((state: any) => {
    return state.ContractManager.schedules
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSaved, setIsSaved] = useState(false)

  //to check whether the kol changed the price, need to create a new stripe Price object again
  const [oldPrice, setOldPrice] = useState<number>(0)

  //invalid input warnings
  const [isIncorrectReqInput, setIsIncorrectReqInput] = useState<boolean>(false)
  const [isIncorrectPriceInput, setIsIncorrectPriceInput] =
    useState<boolean>(false)

  const viewID = getQuery(params, "viewAs")

  useEffect(() => {
    if (job && job.price) {
      setOldPrice(job.price)
    }
  }, [])

  const updateJob = (state: any): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_JOB,
      data: state,
    })
  }

  const confirmContract = (role: CONTRACT_ROLE): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.CONTRACT_ACCEPT_MODAL,
      returnFn: (r: CONTRACT_ROLE) => {
        const updateVal = {
          [r === CONTRACT_ROLE.KOL ? "kolConfirm" : "cConfirm"]: true,
        }

        updateJob(updateVal)

        setTimeout(() => {
          updateContract(updateVal)
        }, 500)
      },
      metaData: {
        role: role,
      },
    })
  }

  const isInvalidPriceRange =
    job.price < 0 || (job.price >= 1 && job.price < 10) || job.price > 1000000

  const updateContract = async (val?: object): Promise<void> => {
    if (job.cRequirement === "") {
      setIsIncorrectReqInput(true)
      if (isInvalidPriceRange) {
        setIsIncorrectPriceInput(true)
      }
    } else if (isInvalidPriceRange) {
      setIsIncorrectPriceInput(true)
      if (job.cRequirement === "") {
        setIsIncorrectReqInput(true)
      }
    } else {
      //if kol changed the contract price

      let updateJobData: Job = {
        ...job,
        ...val,
        // init empty array for old contract
        schedules: Array.isArray(schedules) ? schedules : [],
      }

      if (job.price !== oldPrice) {
        let newProduct = {
          id: uuidv4(),
          title: "custom product [job: " + job.id + "]",
          isVisible: true,
          price: job.price,
        }
        const newPrice = (
          await createNewProduct(newProduct, getAuthUID(params), viewID)
        ).data.newPrice

        //update redux value
        updateJob({
          priceID: newPrice.id as string,
        })

        //update server value
        updateJobData = {
          ...updateJobData,
          priceID: newPrice.id as string,
        }
      }

      setIsSubmitting(true)
      returnIdTokenResult().then(async (res) => {
        let headerInfo: any = {
          "Content-Type": "application/json",
          idToken: res.token,
        }
        if (viewID) {
          headerInfo = {
            ...headerInfo,
            agencyID: viewID,
          }
        }
        await fetch(getAPIPath("/api/job/" + job.id + "/contract"), {
          method: "PATCH",
          headers: headerInfo,
          body: JSON.stringify({
            job: updateJobData,
          }),
        })
          .then((res) => res.json())
          .then((finalResponse) => {
            setIsSubmitting(false)
            setIsSaved(true)
            setTimeout(() => {
              setIsSaved(false)
            }, 1500)

            if (finalResponse.success) {
            } else {
            }
          })
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const modules = React.useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        //'image'
        ["link"],
        ["clean"],
        ["code-block"],
      ],
      // handlers: {
      //     'image': () => quillImageCallBack()
      // },
      clipboard: {
        matchVisual: false,
      },
    },
  }))

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    // 'image'
  ]

  const isKOLAndAgency = job.KOLID === getAuthUID(params)

  if (job.quotationDetail && job.isConfirmQuotation) {
    return (
      <div>
        {!job.isConfirmQuotation && (
          <div className="w-full shadow rounded border-t-8 border-red-300 my-4 p-4 pb-12">
            <h2 className="text-2xl font-bold text-red-600 my-4">
              <FontAwesomeIcon icon={faExclamationCircle} className={"mr-2"} />
              <Trans>Contract.wait-for-confirm</Trans>
            </h2>
            <p className="text-md text-red-500">
              <Trans>Contract.wait-for-quotation</Trans>
            </p>
          </div>
        )}

        <div className="my-4">
          {timeStampToDateTimeString(new Date().getTime() / 1000)}
        </div>

        <div className="rounded my-8  col-span-12 lg:col-span-4 ">
          <section>
            <label className="text-gray-700 mb-4 text-md">
              <Trans>Contract.title</Trans>
            </label>

            <div
              className={["my-4", ReactQuillEditorCss.ReactQuillEditor].join(
                " "
              )}
              style={{
                minHeight: "200px",
                maxHeight: "400px",
                overflow: "hidden",
              }}>
              {job.accepted ? (
                <div className="p-4 border h-64 border-gray-300 rounded-lg overflow-y-scroll">
                  {parse(job.cRequirement)}
                </div>
              ) : (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                <ReactQuill
                  placeholder={
                    t("Contract.kol-requirement-draft-message") as string
                  }
                  defaultValue={job.quotationDetail}
                  value={job.cRequirement}
                  onChange={(e) => {
                    if (e === "<p><br></p>") {
                      // console.log("invalid input")
                      e = ""
                    }
                    updateJob({
                      cRequirement: e,
                    })
                  }}
                  theme="snow"
                  modules={modules}
                  formats={formats}
                />
              )}
            </div>

            <div
              className={classNames("block h-14", {
                hidden: job.accepted,
              })}>
              {!job.accepted && (
                <button
                  className={[
                    "my-2 float-right btn",
                    isSubmitting ? "loading" : "",
                  ].join(" ")}
                  onClick={() => {
                    setIsIncorrectPriceInput(false)
                    setIsIncorrectReqInput(false)
                    if (job.cRequirement !== "") {
                      updateContract()
                    } else {
                      setIsIncorrectReqInput(true)
                    }
                  }}>
                  {isSaved ? (
                    <Trans>Contract.saved</Trans>
                  ) : (
                    <Trans>Contract.save</Trans>
                  )}
                </button>
              )}
            </div>

            {isIncorrectReqInput && (
              <CustomAlert
                title={"Contract.quotation-fill-warning"}
                alertType={ALERT_TYPE.ERROR}
                buttonType={{
                  type: BUTTON_TYPE.NONE,
                }}
                icon={faTriangleExclamation}
              />
            )}
          </section>

          <section>
            <label className="text-gray-700 mb-4 text-md">
              <Trans>Contract.time-schedule</Trans>
            </label>

            <TimeScheduleController isEditMode={!job.accepted} />
          </section>

          <section className="mb-8 bg-white rounded mb-2 ">
            <div className="block my-4">
              {isKOLAndAgency && !job.accepted ? (
                <>
                  <div className="form-control">
                    <label className="text-gray-700 mb-2 block">
                      <Trans>Contract.price</Trans>
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        disabled={
                          getAuthUID(params) === job.CustomerID || job.accepted
                        }
                        className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                        placeholder="price"
                        value={job.price}
                        min={0}
                        max={999999}
                        onChange={(e) => {
                          if (
                            parseInt(e.target.value) < 0 ||
                            parseInt(e.target.value) > 999999
                          ) {
                          } else {
                            if (e.target.value === "") e.target.value = "0"
                            updateJob({
                              price: parseInt(e.target.value),
                            })
                          }
                        }}
                      />
                      {!job.accepted && isKOLAndAgency && (
                        <button
                          className={[
                            "btn",
                            isSubmitting ? "loading" : "",
                          ].join(" ")}
                          onClick={() => {
                            setIsIncorrectPriceInput(false)
                            setIsIncorrectReqInput(false)
                            updateContract()
                          }}>
                          {isSaved ? (
                            <Trans>Contract.saved</Trans>
                          ) : (
                            <Trans>Contract.save</Trans>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <label className="text-gray-700 mb-2 block">
                    <Trans>Contract.price</Trans>
                  </label>
                  <input
                    type="number"
                    disabled={true}
                    className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                    placeholder="price"
                    value={job.price}
                    min={0}
                    max={999999}
                  />
                </>
              )}
            </div>
            {isIncorrectPriceInput && (
              <CustomAlert
                title={"Contract.price-warning"}
                alertType={ALERT_TYPE.ERROR}
                buttonType={{
                  type: BUTTON_TYPE.NONE,
                }}
                icon={faTriangleExclamation}
              />
            )}
          </section>

          <section className="my-4">
            <div className="text-sm text-gray-700">
              <Trans>Contract.confirm-warning</Trans>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <button
                  className={classNames("btn btn-primary my-4 w-full", {
                    "btn-success": job.cConfirm,
                    "btn-ghost":
                      isKOLAndAgency && !job.cConfirm && job.CustomerID,
                  })}
                  // disabled={auth.uid === job.KOLID || job.cConfirm}
                  onClick={() => {
                    if (!(isKOLAndAgency || job.cConfirm)) {
                      confirmContract(CONTRACT_ROLE.CUSTOMER)
                    } else if (isKOLAndAgency && job.CustomerID === "") {
                      const url = `https://look4kol.com/${getUserLang()}/job-preview/${
                        job.id
                      }`
                      navigator.clipboard
                        .writeText(url)
                        // eslint-disable-next-line no-alert
                        .then(() => alert("Copied link to invite customer!"))
                    }
                  }}>
                  {job.cConfirm ? (
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                  ) : job.CustomerID ? (
                    <FontAwesomeIcon icon={faPenFancy} className="mr-2" />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEnvelopeCircleCheck}
                      className="mr-2"
                    />
                  )}
                  {job.cConfirm ? (
                    <Trans>Contract.c-confirmed</Trans>
                  ) : getAuthUID(params) === job.CustomerID ? (
                    <Trans>Contract.confirm</Trans>
                  ) : job.CustomerID ? (
                    <Trans>Contract.wait-for-confirm</Trans>
                  ) : (
                    <p>
                      <Trans>DraftContractModal.invite-customer</Trans>
                    </p>
                  )}
                </button>
              </div>
              <div>
                <button
                  className={[
                    "btn btn-primary my-4 w-full",
                    job.kolConfirm || !job.CustomerID ? "btn-success" : "",
                    getAuthUID(params) === job.CustomerID && !job.kolConfirm
                      ? "btn-ghost"
                      : "",
                  ].join(" ")}
                  // disabled={auth.uid === job.CustomerID || job.kolConfirm}
                  onClick={() => {
                    if (
                      !(
                        getAuthUID(params) === job.CustomerID ||
                        job.kolConfirm ||
                        !job.CustomerID
                      )
                    ) {
                      confirmContract(CONTRACT_ROLE.KOL)
                    }
                  }}>
                  {job.kolConfirm || !job.CustomerID ? (
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                  ) : (
                    <FontAwesomeIcon icon={faPenFancy} className="mr-2" />
                  )}

                  {job.kolConfirm || !job.CustomerID ? (
                    <Trans>Contract.kol-confirmed</Trans>
                  ) : isKOLAndAgency ? (
                    <Trans>Contract.confirm</Trans>
                  ) : (
                    <Trans>Contract.wait-for-confirm</Trans>
                  )}
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
  return (
    <div>
      <CustomAlert
        title={"Contract.wait-for-quotation-confirm"}
        alertType={ALERT_TYPE.ERROR}
        buttonType={{
          type: BUTTON_TYPE.NONE,
        }}
        icon={faExclamationCircle}
      />
      <p className="text-gray-700 mb-4 text-md">
        <Trans>Contract.title</Trans>
      </p>
      <textarea
        className="textarea textarea-bordered w-full rounded-xl h-64"
        disabled={true}
        placeholder="Contract Details"
      />
      <p className="text-gray-700 my-4 text-md">
        <Trans>Contract.time-schedule</Trans>
      </p>

      <div className="text-gray-400 px-2">
        <div className="flex flex-row gap-4">
          <p>1</p>
          <p>dd-mm-yyyy</p>
        </div>
        <div className="flex flex-row gap-4">
          <p>2</p>
          <p>dd-mm-yyyy</p>
        </div>
      </div>

      <p className="text-gray-700 my-4 block">
        <Trans>Contract.price</Trans>
      </p>
      <input
        type="text"
        placeholder="Contract Price"
        disabled={true}
        className="input input-bordered w-full "
      />

      <div className="text-sm text-gray-700 my-4">
        <Trans>Contract.confirm-warning</Trans>
      </div>
      <div className="my-4 grid grid-cols-2 gap-6">
        <button className="btn" disabled={true}>
          <Trans>Contract.confirm</Trans>
        </button>
        <button className="btn" disabled={true}>
          <Trans>Contract.wait-for-confirm</Trans>
        </button>
      </div>
    </div>
  )
}
export default ContractMain
