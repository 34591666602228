import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"
import { getAuth, connectAuthEmulator } from "firebase/auth"
import { getStorage, connectStorageEmulator } from "firebase/storage"
import { getFunctions, connectFunctionsEmulator } from "firebase/functions"
import { getPerformance } from "firebase/performance"
import { getAnalytics } from "firebase/analytics"
import { initializeApp } from "firebase/app"
import { isLocalhost } from "../Utils/utiltyHelper"

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const firebaseApp = initializeApp(firebaseConfig)

const db = getFirestore()
const auth = getAuth()
const storage = getStorage()
const functions = getFunctions()

export const analytics = getAnalytics()

if (isLocalhost()) {
  connectFirestoreEmulator(db, "localhost", 8002)
  connectAuthEmulator(auth, "http://localhost:9099", {
    disableWarnings: true,
  })
  connectStorageEmulator(storage, "localhost", 9199)
  connectFunctionsEmulator(functions, "localhost", 5001)
} else {
  // only init performance while this is not a localhost
  const perf = getPerformance()
}
export default firebaseApp
