import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"
import { v4 as uuidv4 } from "uuid"
import { Reducer } from "react"
import { CONDITION_BADGES_TYPE } from "../../Model/ConditionBadge"
import { KOLCombine, KOLCombineWithAvatar } from "../../Model/KOLCombine"
import { DEFAULT_MAX_CONDITION } from "../../Enum/KOL_SEARCH_TYPE"

export interface CompareItem {
  data: string[]
  id: string
  title: string
  type: "ig"
}

export enum ORDER_BY {
  NOPE,
  FOL_DESC,
  FOL_ASC,
  USERNAME_DESC,
  USERNAME_ASC,
  ENGA_DESC,
  ENGA_ASC,
}

export interface OrderBySelectionType {
  value: string
  type: ORDER_BY
  label: string
}

export const OrderBySelection: OrderBySelectionType[] = [
  {
    value: "",
    type: ORDER_BY.NOPE,
    label: "SearchPage.default",
  },
  {
    value: "KOLCombine_noOfFollowers_desc",
    type: ORDER_BY.FOL_DESC,
    label: "SearchPage.follower-high-low",
  },
  {
    value: "KOLCombine_noOfFollowers_asc",
    type: ORDER_BY.FOL_ASC,
    label: "SearchPage.follower-low-high",
  },
  {
    value: "KOLCombine_username_desc",
    type: ORDER_BY.USERNAME_DESC,
    label: "SearchPage.username-high-low",
  },
  {
    value: "KOLCombine_username_asc",
    type: ORDER_BY.USERNAME_ASC,
    label: "SearchPage.username-low-high",
  },
  {
    value: "KOLCombine_basicScore_desc",
    type: ORDER_BY.ENGA_DESC,
    label: "SearchPage.enga-high-low",
  },
]

export const KOLSearchDefault: KOLSearchType = {
  uid: "",
  excludeId: "",
  searchStr: "",
  nationality: "",
  isLook4kolReg: true,
  userName: "",
  focusPercentage: "",
  hasData: "",
  isFace: "",
  gender: "",
  ageGroup: "",
  kolSize: "",
  type: "",
  genreCategory: "",
  genre: "",
  priceMin: 0,
  priceMax: 0,
  followerMin: 0,
  followerMax: 0,
  orderBy: "",
  page: 0,
  score: 0,
  isVerified: true,
  limit: 10,
  basicScoreMin: 0,
  basicScoreMax: 0,
  topCateID: "",
  topCateMin: 0,
  topCateMax: 100,
  isTextSearch: true,
}

export interface KOLSearchType {
  uid: string
  excludeId: string
  searchStr: string
  nationality: string
  isLook4kolReg: boolean | ""
  // typeID: string
  userName: string
  focusPercentage: string
  hasData: boolean | ""
  isFace: boolean | ""
  gender: "" | "1" | "2" | "3"
  ageGroup: AGE_GROUP | ""
  kolSize: KOL_SIZE | ""
  type: string
  genreCategory: string
  genre: string
  priceMin: number
  priceMax: number
  followerMin: number
  followerMax: number
  orderBy: string
  page: number
  score: number
  isVerified: boolean | ""
  limit: number | ""
  basicScoreMin: number
  basicScoreMax: number
  topCateID: string
  topCateMin: number
  topCateMax: number
  isTextSearch: boolean
}

export interface searchPageSupportedQuery {
  pageNum: string | null
  searchStr: string | null
  isLook4kolReg: string | null
  genreCategory: string | null
  engagementMin: string | null
  engagementMax: string | null
  verified: string | null
  ageGroup: AGE_GROUP | ""
  kolSize: KOL_SIZE | ""
}

export const sqliteField: string[] = [
  "genreCategory",
  "engagementMin",
  "engagementMax",
  "verified",
  "ageGroup",
  "kolSize",
]

enum KOL_SIZE {
  SMALL = "1",
  MEDIUM = "2",
  LARGE = "3",
  HUGE = "4",
}

enum AGE_GROUP {
  "13-17" = "13-17",
  "18-24" = "18-24",
  "25-34" = "25-34",
  "35-44" = "35-44",
  "45-54" = "45-54",
  "55-65" = "55-65",
}

export interface KOLNeo4jSearchType {
  categoryID?: string | ""
  genreID?: string | ""
  size?: KOL_SIZE | ""
  engagement?: number | ""
  age?: AGE_GROUP | ""
  kType?: string | ""
  username?: string | ""
  gender?: "female" | "male" | ""
  page?: number | ""
}

export enum QuoteStep {
  QUOTATION,
  PROGRESS,
}

interface actionTypes {
  type: string
  data: any
  selectedKOLID: string
}

export interface initStateType {
  step: CONDITION_BADGES_TYPE | ""
  customerToolsSideBar: boolean
  searchKOLCondition: KOLSearchType
  //add to compare group
  compareGroup: CompareItem[]
  isCompareGroupChange: boolean
  isLoadedCompareGroup: boolean
  showHelpText: boolean
  isConditionDropDown: boolean
  isLoaded: boolean
  isSearching: boolean
  hasMore: boolean
  searchResult: KOLCombine[]
  selectedKOLs: KOLCombineWithAvatar[]
  quoteStep: QuoteStep
}

const initialState: initStateType = {
  step: "",
  customerToolsSideBar: false,
  searchKOLCondition: KOLSearchDefault,
  //add to compare group
  compareGroup: [],
  isCompareGroupChange: false,
  isLoadedCompareGroup: false,
  showHelpText: false,
  isConditionDropDown: false,
  isLoaded: false,
  isSearching: false,
  hasMore: false,
  searchResult: [],
  selectedKOLs: [],
  quoteStep: QuoteStep.QUOTATION,
}

const addKOLToCompareGroup = (
  compareGroup: CompareItem[],
  groupID: string,
  newKOLID: string
): CompareItem[] => {
  // need to use dot operator, react redux rule
  let newCompareGroup: CompareItem[] = [...compareGroup]
  let index = newCompareGroup.findIndex((e) => e.id === groupID)
  if (index !== -1) {
    newCompareGroup[index].data = newCompareGroup[index].data.concat(newKOLID)
  }

  return newCompareGroup
}

const removeCompareGroup = (
  compareGroup: CompareItem[],
  selectedGroupID: string
): CompareItem[] => {
  // need to use dot operator, react redux rule
  let newCompareGroup = [...compareGroup]
  newCompareGroup = newCompareGroup.filter((e) => e.id !== selectedGroupID)

  return newCompareGroup
}

const removeKOLFromCompareGroup = (
  compareGroup: CompareItem[],
  selectedGroupID: string
) => {
  // need to use dot operator, react redux rule
  let newCompareGroup = [...compareGroup]
  newCompareGroup.map((e, index) => {
    e.data.map((el, indexEl) => {
      if (el === selectedGroupID) {
        newCompareGroup[index].data = newCompareGroup[index].data.filter(
          (k) => k !== selectedGroupID
        )
      }
    })
  })
  return newCompareGroup
}

const createNewCompareGroup = (
  compareGroup: CompareItem[],
  groupName: string,
  newKOLID: string
): CompareItem[] => {
  let newGroup: CompareItem = {
    data: [newKOLID],
    id: uuidv4(),
    title: groupName,
    type: "ig",
  }

  return [...compareGroup].concat(newGroup)
}

const ToolsManager: Reducer<initStateType, actionTypes> = (
  state = initialState,
  action
) => {
  if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_QUERY) {
    return {
      ...state,
      searchKOLCondition: {
        ...state.searchKOLCondition,
        [action.data.key]: action.data.value,
      },
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_QUERIES) {
    return {
      ...state,
      searchKOLCondition: {
        ...state.searchKOLCondition,
        ...action.data,
      },
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.RESET_SEARCH_KOL) {
    return {
      ...state,
      searchKOLCondition: {
        ...KOLSearchDefault,
        isTextSearch: state.searchKOLCondition.isTextSearch,
      },
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_KOL_STEP) {
    return {
      ...state,
      step: action.data,
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_COMPARE_GROUP) {
    return {
      ...state,
      compareGroup: action.data,
      isLoadedCompareGroup: true,
    }
  } else if (
    action.type === SUPPORTED_REDUX_FUNCTIONS.ADD_KOL_TO_COMPARE_GROUP
  ) {
    /**
     *  @param string  compareGroupID - selected group ID
     */
    return {
      ...state,
      compareGroup: addKOLToCompareGroup(
        state.compareGroup,
        action.data,
        action.selectedKOLID
      ),
    }
  } else if (
    action.type === SUPPORTED_REDUX_FUNCTIONS.CREATE_NEW_COMPARE_GROUP
  ) {
    /**
     *  @param string groupName - the title of the new group
     */

    return {
      ...state,
      compareGroup: createNewCompareGroup(
        state.compareGroup,
        action.data,
        action.selectedKOLID
      ),
    }
  } else if (
    action.type === SUPPORTED_REDUX_FUNCTIONS.REMOVE_KOL_FROM_COMPARE_GROUP
  ) {
    /**
     *  @param string  selectedGroupID - selected group ID
     */

    return {
      ...state,
      compareGroup: removeKOLFromCompareGroup(state.compareGroup, action.data),
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.REMOVE_COMPARE_GROUP) {
    /**
     *  @param string  compareGroupID - selected group ID
     */
    return {
      ...state,
      compareGroup: removeCompareGroup(state.compareGroup, action.data),
    }
  } else if (
    action.type === SUPPORTED_REDUX_FUNCTIONS.COMPARE_GROUP_NEED_UPDATE
  ) {
    return {
      ...state,
      //let AddToCompareGroup detect change
      isCompareGroupChange: !state.isCompareGroupChange,
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SHOW_HELP_TEXT) {
    return {
      ...state,
      //let AddToCompareGroup detect change
      showHelpText: action.data,
    }
  } else if (
    action.type === SUPPORTED_REDUX_FUNCTIONS.TOGGLE_CONDITION_DROPDOWN
  ) {
    return {
      ...state,
      //let AddToCompareGroup detect change
      isConditionDropDown: action.data,
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_RESULT) {
    return {
      ...state,
      isLoaded: true,
      isSearching: false,
      hasMore: action.data.hasMore,
      searchResult:
        action.data.kols.length > 0 ? action.data.kols : state.searchResult,
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_IS_SEARCHING) {
    return {
      ...state,
      isLoaded: false,
      isSearching: true,
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.ADD_TO_SELECTED_KOL) {
    return {
      ...state,
      selectedKOLs: state.selectedKOLs.concat(action.data),
    }
  } else if (
    action.type === SUPPORTED_REDUX_FUNCTIONS.REMOVE_FROM_SELECTED_KOL
  ) {
    return {
      ...state,
      selectedKOLs: state.selectedKOLs.filter(
        (kol) => kol.id !== action.selectedKOLID
      ),
    }
  } else if (
    action.type === SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_SELECTED_KOLS
  ) {
    return {
      ...state,
      selectedKOLs: action.data,
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_QUOTE_STEP) {
    return {
      ...state,
      quoteStep: action.data,
    }
  }
  return state
}

export default ToolsManager
