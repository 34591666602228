import React, { useEffect } from "react"
import ContractDraftKOL from "../../page-components/c/contract/ContractDraftKOL"
import Contract from "../../page-components/c/contract/Contract"
import { useDispatch } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import CustomAlert from "../NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../Enum/ALERT_SYSYEM"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"
import QuotationDraftForm from "../../page-components/c/contract/QuotationDraftForm"

const CreateDraftQuotationModal = (props: any) => {
  return (
    <div>
      <CustomAlert
        alertType={ALERT_TYPE.INFO}
        buttonType={{ type: BUTTON_TYPE.NONE }}
        title="DraftQuotationModal.alert"
        icon={faCircleExclamation}
      />
      <QuotationDraftForm
        tempTitle={props.metaData.title}
        tempDescription={props.metaData.content}
        kolID={props.metaData.kolID}
        closeModal={props.closeModal}
      />
    </div>
  )
}

export default CreateDraftQuotationModal
