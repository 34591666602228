import { Reducer } from "react"
import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"

interface actionTypes {
  type: string
  data: any
}

export interface assistantState {
  defaultMsg: string
  expandedKOL: string
  progress: number
  summaryQuote: string
  isDraftReady: boolean
  isKOLListReady: boolean
  isCardView: boolean
}

const initialState: assistantState = {
  defaultMsg: "",
  expandedKOL: "",
  progress: 0,
  summaryQuote: "",
  isDraftReady: false,
  isKOLListReady: false,
  isCardView: true,
}

const onSelectKOL = (selectedKOLID: string[], kolID: string) => {
  //if found
  if (selectedKOLID.find((e) => e === kolID)) {
    return [...selectedKOLID].filter((e) => e !== kolID)
  }
  //else add into the list
  return [...selectedKOLID].concat(kolID)
}
const assistantReducer: Reducer<assistantState, actionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SUPPORTED_REDUX_FUNCTIONS.EXPANDED_KOL:
      return {
        ...state,
        expandedKOL: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.ADD_ASSISTANT_MSG:
      return {
        ...state,
        defaultMsg: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.RESET_ASSISTANT_MSG:
      return {
        ...state,
        defaultMsg: "",
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_ASSISTANT_PROGRESS:
      return {
        ...state,
        progress: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_QUOTATION_SUMMARY:
      return {
        ...state,
        summaryQuote: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_QUOTATION_READY:
      return {
        ...state,
        isDraftReady: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_KOLLIST_READY:
      return {
        ...state,
        isKOLListReady: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_CARD_VIEW:
      return {
        ...state,
        isCardView: action.data,
      }

    default:
      return state
  }
}
export default assistantReducer
