import { combineReducers } from "redux"
import setModalController from "./ModalController"
import ServiceTagManager from "./ServiceTagManager"
import CaseStudyManager from "./CaseStudyManager"
import KOLPlanManager from "./KOLPlanManager"
import setChatRoomReducer from "./ChatRoomReducer"
import setKOLInfoReducer from "./KOLProfile"
import setDraftMsg from "./DraftMessage"
import MessagingSystem from "./MessagingSystem"
import SideBarManager from "./SideBarManager"
import SystemManager from "./SystemManager"
import ToolsManager from "./ToolsManager"
import ContractManager from "./ContractManager"
import AdminControlManager from "./AdminControlManager"
import LoginManager from "./LoginManager"
import HelpDocManager from "./HelpDocManager"
import NotificationManager from "./NotificationManager"
import ProjectManager from "./ProjectManager"
import SearchManager from "./SearchManager"
import RecommendationManager from "./RecommendationManager"
import MemberManager from "./MemberManager"
import ChatGPTManager from "./ChatGPTManager"
import ManagementManger from "./ManagementManger"
import QueryManager from "./QueryManager"
import AssistantManager from "./AssistantManager"

const rootReducer = combineReducers({
  firebase: LoginManager,
  setModalController: setModalController,
  ServiceTagManager: ServiceTagManager,
  CaseStudyManager: CaseStudyManager,
  KOLPlanManager: KOLPlanManager,
  ChatRoomInfo: setChatRoomReducer,
  KOLInfoManager: setKOLInfoReducer,
  FileExplorer: setDraftMsg,
  MessagingSystem: MessagingSystem,
  SideBarManager: SideBarManager,
  SystemManager: SystemManager,
  ContractManager: ContractManager,
  AdminControlManager: AdminControlManager,
  HelpDocManager: HelpDocManager,
  NotificationManager: NotificationManager,
  ProjectManager: ProjectManager,
  SearchManager: SearchManager,
  RecommendationManager: RecommendationManager,
  MemberManager: MemberManager,
  ToolsManager: ToolsManager,
  ChatGPTManager: ChatGPTManager,
  ManagementManger: ManagementManger,
  QueryManager: QueryManager,
  AssistantManager: AssistantManager,
})
export default rootReducer
