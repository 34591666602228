import { Reducer } from "react"
import { Job } from "../../Model/Job"
import KOL from "../../Model/KOL"
import {
  PageType,
  Project,
  ProjectGroupProgress,
  ProjectGroupQuotePage,
  projectDefault,
} from "../../Model/ProjectManagement"
import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"

interface actionTypes {
  type: string
  data: Project[] | Project | any
  pagePropsID?: string
}

interface ProjectInitStateType {
  currentProject: Project
  projects: Project[]
  kolList: KOL[]
  jobList: Job[]
  isLoadedSelectedProject: boolean
  isLoadedAll: boolean
  selectedKOL: string
  isLoadedKOL: boolean
  isLoadedJobs: boolean
  page: PageType
  pagePropsID: string
  isExpanded: boolean
  allKOLsTab: boolean
  selectedKOLName: string
  tableSelectedKOLs: string[]
  groupQuotationPage: ProjectGroupQuotePage
  groupContractDetails: {
    title: string
    description: string
  }
  groupProgress: ProjectGroupProgress
  newJobList: string[]

  reduxCheckBoxData: { checkElement: boolean[] }[]
}

const initialState: ProjectInitStateType = {
  currentProject: projectDefault,
  projects: [],
  kolList: [],
  jobList: [],
  isLoadedSelectedProject: false,
  isLoadedAll: false,
  selectedKOL: "",
  isLoadedKOL: false,
  isLoadedJobs: false,
  page: PageType.NewProject,
  pagePropsID: "",
  isExpanded: false,
  allKOLsTab: true,
  selectedKOLName: "",
  tableSelectedKOLs: [],
  newJobList: [],
  groupQuotationPage: ProjectGroupQuotePage.CONTRACT,
  groupProgress: ProjectGroupProgress.START,
  groupContractDetails: { title: "", description: "" },
  reduxCheckBoxData: [
    { checkElement: [] },
    { checkElement: [] },
    { checkElement: [] },
    { checkElement: [] },
  ],
}

const setProjectReducer: Reducer<ProjectInitStateType, actionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SUPPORTED_REDUX_FUNCTIONS.SET_PROJECTS:
      return {
        ...state,
        projects: action.data,
        isLoadedAll: true,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_KOL_LIST:
      return {
        ...state,
        kolList: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_TABLE_SELECTED_KOLS:
      return {
        ...state,
        tableSelectedKOLs: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_JOB_LIST:
      return {
        ...state,
        jobList: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_SELECTED_KOL:
      return {
        ...state,
        selectedKOL: action.data,
        isLoadedKOL: true,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_SELECTED_KOL_NAME:
      return {
        ...state,
        selectedKOLName: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_CURRENT_PROJECT:
      return {
        ...state,
        currentProject: action.data,
        isLoadedSelectedProject: true,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_PROJECT_PAGE:
      return {
        ...state,
        page: action.data,
        pagePropsID: action.pagePropsID ? action.pagePropsID : "",
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_PROJECT_PAGE_EXPAND:
      return {
        ...state,
        isExpanded: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_CHECKED:
      return {
        ...state,
        reduxCheckBoxData: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_ALL_KOLS_TAB:
      return {
        ...state,
        allKOLsTab: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_GROUP_QUOTATION_PAGE:
      return {
        ...state,
        groupQuotationPage: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_GROUP_QUOTATION_PROGRESS:
      return {
        ...state,
        groupProgress: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_GROUP_CONTRACT_DETAILS:
      return {
        ...state,
        groupContractDetails: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_PROJECT_LOADED:
      return {
        ...state,
        isLoadedSelectedProject: action.data,
      }

    default:
      return state
  }
}

export default setProjectReducer
