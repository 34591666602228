import { ADMIN_REQUEST_TYPE } from "../../Enum/APP_TYPE"
import AdminRequest, { ADMIN_REQUEST } from "../../Model/AdminRequest"
import { DateObj } from "../../Model/Utilities"
import { v4 as uuidv4 } from "uuid"
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore"
import firebaseApp from "../../Config/firebase"
import {
  getDirectionSign,
  ORDER_BY_DIRECTION,
  PAGINATION_DIRECTION,
  SORT_OPTION,
  sortDirectionFn,
} from "../../Utils/TableHelperFunction"
import { getAPIPath } from "../../Utils/HelpingFunction"

const db = getFirestore(firebaseApp)

const getJobAdminRequest = (
  jobID: string
): Promise<
  | {
      success: true
      data: ADMIN_REQUEST[]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let docRef = collection(db, "AdminRequest")
    const q1 = query(
      docRef,
      where("jobID", "==", jobID),
      orderBy("createDate", "desc"),
      limit(1)
    )

    await getDocs(q1)
      .then((docs) => {
        if (!docs.empty) {
          let arr: ADMIN_REQUEST[] = []
          docs.forEach((doc) => {
            arr.push({
              id: doc.id,
              ...doc.data(),
            } as ADMIN_REQUEST)
          })

          return resolve({
            success: true,
            data: arr,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const getBankCheckoutHistory = (
  jobID: string
): Promise<
  | {
      success: true
      data: AdminRequest[]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let docRef = collection(db, "AdminRequest")
    const q1 = query(docRef, where("jobID", "==", jobID))
    const q2 = query(
      q1,
      where("type", "==", ADMIN_REQUEST_TYPE.BANK_CHECKOUT),
      orderBy("createDate", "desc")
    )
    await getDocs(q2)
      .then((docs) => {
        if (!docs.empty) {
          let arr: AdminRequest[] = []
          docs.forEach((doc) => {
            arr.push({
              id: doc.id,
              ...doc.data(),
            } as AdminRequest)
          })

          return resolve({
            success: true,
            data: arr,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const callBankCheckoutAPI = (
  jobID: string
): Promise<{ success: true; data: AdminRequest[] } | { success: false }> => {
  return new Promise(async (resolve) => {
    await fetch(getAPIPath("/api/admin/bankCheckoutHistory/" + jobID), {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((finalResponse) => {
        return resolve(finalResponse)
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const getPayLaterHistory = (
  jobID: string
): Promise<
  | {
      success: true
      data: AdminRequest
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let docRef = collection(db, "AdminRequest")
    const q1 = query(docRef, where("jobID", "==", jobID))
    const q2 = query(
      q1,
      where("type", "==", ADMIN_REQUEST_TYPE.PAY_LATER_REQUEST),
      orderBy("createDate", "desc")
    )
    await getDocs(q2)
      .then((docs) => {
        if (!docs.empty) {
          return resolve({
            success: true,
            data: {
              id: docs.docs[0].id,
              ...docs.docs[0].data(),
            } as AdminRequest,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const getMyPayoutHistory = (
  condition: {
    startDate: Date
    endDate: Date
  },
  uid: string
): Promise<
  | {
      success: true
      hasMore: boolean
      data: any[]
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    let limitNum = 100
    let colRef = collection(db, "AdminRequest")

    let dbRef = query(
      colRef,
      where("type", "==", ADMIN_REQUEST_TYPE.PAYOUT_REQUEST),
      where("requestUID", "==", uid),
      where("lastUpdate", ">=", condition.startDate),
      where("lastUpdate", "<", condition.endDate)
    )

    getDocs(query(dbRef, limit(limitNum)))
      .then((docs) => {
        if (!docs.empty) {
          let newPayoutHis: any = []

          docs.forEach((doc) => {
            newPayoutHis.push({
              id: doc.id,
              ...doc.data(),
            })
          })

          return resolve({
            success: true,
            hasMore: docs.docs.length === limitNum,
            data: newPayoutHis,
          })
        }
        return resolve({
          success: true,
          data: [],
          hasMore: false,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({ success: false })
      })
  })
}

const createAdminRequest = (
  data: AdminRequest
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    const caseID = uuidv4()

    await setDoc(doc(db, "AdminRequest", caseID), data)
      .then((result) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => {
        console.log("Error", err)
        return resolve({
          success: false,
        })
      })
  })
}

export interface AdminRequestCondition {
  requestID?: string
  jobID?: string
  isFinish?: boolean | ""
  type?: ADMIN_REQUEST_TYPE | ""
  startAfter?: "" | string
}

const getAdminRequests = (
  condition: AdminRequestCondition
): Promise<{
  success: boolean
  hasMore: boolean
  data: ADMIN_REQUEST[]
}> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "AdminRequest")

    if (condition.requestID === "") {
      if (condition.isFinish !== "") {
        dbRef = query(dbRef, where("isFinish", "==", condition.isFinish))
      }

      if (condition.jobID !== "") {
        dbRef = query(dbRef, where("jobID", "==", condition.jobID))
      }

      if (condition.type !== "") {
        dbRef = query(dbRef, where("type", "==", condition.type))
      }

      if (condition.startAfter !== "") {
        dbRef = query(dbRef, where("createDate", "<", condition.startAfter))
      }

      await getDocs(query(dbRef, orderBy("createDate", "desc"), limit(10)))
        .then((docs: any) => {
          let allAdminRequests: ADMIN_REQUEST[] = []
          if (!docs.empty) {
            docs.forEach((doc: any) => {
              allAdminRequests.push({
                id: doc.id,
                ...doc.data(),
              })
            })

            return resolve({
              success: true,
              hasMore: docs.docs.length === 10,
              data: allAdminRequests,
            })
          }
          return resolve({
            success: true,
            hasMore: false,
            data: [],
          })
        })
        .catch((err: any) => console.log(err))
    } else {
      await getDoc(doc(dbRef, condition.requestID))
        .then((doc: any) => {
          if (doc.exists) {
            return resolve({
              success: true,
              hasMore: false,
              data: [
                {
                  id: doc.id,
                  ...doc.data(),
                },
              ],
            })
          }
        })
        .catch((err: any) => console.log(err))
    }
  })
}

const getAdminRequest = (
  id: string
): Promise<{
  success: boolean
  data: ADMIN_REQUEST
}> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "AdminRequest", id))
      .then((doc: any) => {
        if (doc.exists) {
          return resolve({
            success: true,
            data: {
              id: doc.id,
              ...doc.data(),
            },
          })
        }
      })
      .catch((err: any) => console.log(err))
  })
}

const setAdminRequest = (
  id: string,
  data: AdminRequest
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await setDoc(doc(db, "AdminRequest", id), data)
      .then((doc: any) => {
        if (doc.exists) {
          return resolve({
            success: true,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err: any) => console.log(err))
  })
}

/**
 * Only Admin can have the permission to update AdminRequest
 * @param id
 */
const markAdminRequestAsFinished = (
  id: string
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await updateDoc(doc(db, "AdminRequest", id), {
      isFinish: true,
      lastUpdate: new Date(),
    })
      .then((doc: any) => {
        return resolve({
          success: true,
        })
      })
      .catch((err: any) => console.log(err))
  })
}

const getKOLMissionHistory = (
  requestUID: string
): Promise<{ success: true; data: ADMIN_REQUEST } | { success: false }> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "AdminRequest")

    dbRef = query(dbRef, where("requestUID", "==", requestUID))
    dbRef = query(
      dbRef,
      where("type", "==", ADMIN_REQUEST_TYPE.KOL_REFERRAL_MISSION)
    )

    await getDocs(query(dbRef, orderBy("createDate", "desc"), limit(1)))
      .then((docs: any) => {
        let adminReq: ADMIN_REQUEST[] = []
        if (!docs.empty) {
          docs.forEach((doc: any) => {
            adminReq.push({
              ...doc.data(),
              id: doc.id,
            })
          })

          return resolve({
            success: true,
            data: adminReq[0],
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err: any) => {
        console.log(err)
      })
  })
}

export {
  getBankCheckoutHistory,
  createAdminRequest,
  getMyPayoutHistory,
  getPayLaterHistory,
  getAdminRequests,
  getAdminRequest,
  setAdminRequest,
  markAdminRequestAsFinished,
  getJobAdminRequest,
  getKOLMissionHistory,
  callBankCheckoutAPI,
}
