import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"
import { v4 as uuidv4 } from "uuid"
import { CSIgFinalMedia } from "../../Model/CaseStudy"

const initialState = {
  allCaseStudy: [],
}

const addCaseStudy = (caseStudy) => {
  let newAllCS = [...caseStudy]

  newAllCS.push({
    id: uuidv4(),
    ig_comment: 0,
    ig_like: 0,
    ig_save: 0,
    ig_share: 0,
    ig_total_action: 0,
    ig_total_engagement: 0,
    ig_reach: 0,
    ig_id: "",
    imageUrl: [],
    title: "",
    action: "add",
    verified: false,
    isPublish: false,
  })

  return newAllCS
}

const removeCaseStudy = (caseStudy, caseStudyID) => {
  let newAllCS = [...caseStudy]

  let index = newAllCS.findIndex((e) => e.id === caseStudyID)
  //if already exist in database
  if (newAllCS[index].action === "") {
    newAllCS[index].action = "delete"
  } else {
    newAllCS.splice(index, 1)
  }

  return newAllCS
}

const editCaseStudy = (caseStudy, caseStudyDetail) => {
  let newAllCS = [...caseStudy]
  let index = caseStudy.findIndex((e) => e.id === caseStudyDetail.id)
  //if already exist in database
  if (newAllCS[index].action === "") {
    newAllCS[index] = caseStudyDetail
    newAllCS[index].action = "update"
  } else {
    //if new item still keeping action="new"
    newAllCS[index] = caseStudyDetail
  }

  return newAllCS
}

const CaseStudyManager = (state = initialState, action) => {
  if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_CASE_STUDY) {
    return {
      ...state,
      allCaseStudy: action.allCaseStudy,
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.ADD_CASE_STUDY) {
    return {
      ...state,
      allCaseStudy: addCaseStudy(state.allCaseStudy),
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.REMOVE_CASE_STUDY) {
    return {
      ...state,
      allCaseStudy: removeCaseStudy(state.allCaseStudy, action.caseStudyID),
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.EDIT_CASE_STUDY) {
    return {
      ...state,
      allCaseStudy: editCaseStudy(state.allCaseStudy, action.caseStudy),
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_CASE_STUDY_MEDIA) {
    return {
      ...state,
      allCaseStudy: editCaseStudy(state.allCaseStudy, action.data),
    }
  }

  return state
}

export default CaseStudyManager
