/* eslint-disable max-statements */
import React, { useEffect, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { useHistory, withRouter } from "react-router-dom"
import * as List from "../../Utils/ModalType"
import { SUPPORTED_MODAL_TYPE } from "../../Utils/ModalType"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import { v4 as uuidv4 } from "uuid"
import { onUploadFile } from "../../HelpingFunction/FirebaseStorage"
import { t } from "i18next"

import CustomModal from "../CustomModal"
import ViewImageModalComponent from "../ModalComponents/ViewImageModalComponent"
import DeleteMessageModalComponent from "../ModalComponents/DeleteMessageModalComponent"
import { confirmDeleteMessage } from "../../HelpingFunction/Modal/ModalHelperFunction"
import {
  ADMIN_REQUEST_STATUS_TYPE,
  ADMIN_REQUEST_TYPE,
  CHATROOM_PAGE_TYPE,
} from "../../Enum/APP_TYPE"
import WalletHistoryModalComponent from "../ModalComponents/WalletHistoryModalComponent"
import ContractDisplayModalComponent from "../ModalComponents/ContractDisplayModalComponent"
import ContractAcceptModalComponent from "../ModalComponents/ContractAcceptModalComponent"
import SubmitJobFulfillmentModalComponent from "../ModalComponents/SubmitJobFulfillmentModalComponent"
import { sendPayoutRequest } from "../../HelpingFunction/Chatroom/Contract/JobStatusManager"
import ViewFollowersDetailModalComponent from "../ModalComponents/ViewFollowersDetailModalComponent"
import SignUpCoverImageModalComponent from "../ModalComponents/SignUpCoverImageModalComponent"
import BankWithdrawModalComponent from "../ModalComponents/BankWithdrawModalComponent"
import UploadFileModalComponent from "../ModalComponents/UploadFileModalComponent"
import CreateNewContractModalComponent from "../ModalComponents/CreateNewContractModalComponent"
import CreateCaseStudyModalComponent from "../ModalComponents/CreateCaseStudyModalComponent"
import ViewCaseStudyModalComponent from "../ModalComponents/ViewCaseStudyModalComponent"
import LoginModalComponent from "../ModalComponents/LoginModalComponent"
import {
  createChatGPTChatroom,
  createChatroom,
} from "../../HelpingFunction/Chatroom/chatroomDBHelper"
import { logout, returnIdTokenResult } from "../../Utils/FirebaseUtils"
import ContactKOLModalComponent from "../ModalComponents/ContactKOLModalComponent"
import CaseStudyModalComponent from "../ModalComponents/CaseStudyModalComponent"
import NewFolderModalComponent from "../ModalComponents/NewFolderModalComponent"
import { setAdminRequest } from "../../HelpingFunction/AdminRequest/AdminRequestDBHelper"
import ApplyJobAppealModalComponent from "../ModalComponents/ApplyJobAppealModalComponent"
import KOLPreviewModalComponent from "../ModalComponents/KOLPreviewModalComponent"
import { bankDefault } from "../../Model/Bank"
import {
  setPayoutRequest,
  setWalletBank,
} from "../../HelpingFunction/KOL/kolDBHelper"
import ProjectManagementModal from "./ProjectManagement/ProjectManagementModal"
import LoginRoleModalComponent from "../ModalComponents/LoginRoleModalComponent"
import { updateProject } from "../../HelpingFunction/ProjectManagement/ProjectDBHelper"
import AdminViewKOLPercentageModalComponent from "../../Component/ModalComponents/AdminViewKOLPercentageModalComponent"
import AddToCompareGroup from "../ModalComponents/AddToCompareGroup"
import SendAllContractsModalComponent from "../ModalComponents/SendAllContractsModalComponent"
import { ViewContractExampleComponent } from "../ModalComponents/ViewContractExampleComponent"
import MobileHelpDocModalComponent from "../ModalComponents/MobileHelpDocModalComponent"
import CaseStudyIGModalComponent from "../ModalComponents/CaseStudyIGModalComponent"
import AddChatgptChatroomComponent from "../ModalComponents/AddChatgptChatroomComponent"
import CreateNewCompareGroup from "../ModalComponents/CreateNewCompareGroup"
import { getRole, getUserLang } from "../../Utils/utiltyHelper"
import { KOLCompareTable } from "../../page-components/tools/KOLCompareTable"
import OwnedKOLAccess from "../OwnedKOLComponents/OwnedKOLAccess"
import DraftContractModal from "../ModalComponents/CreateDraftContractModal"
import ShowDraftContractModalComponent from "../ModalComponents/ShowDraftContractModalComponent"
import CreateDraftContractModal from "../ModalComponents/CreateDraftContractModal"
import AddProject from "../../page-components/project/ProjectRightSection/AddProject"
import ProjectPageOptionBar from "../../page-components/project/ProjectPageOptionBar"
import ContractSkipPaymentModalComponent from "../ModalComponents/ContractSkipPaymentModalComponent"
import { skipContractPayment } from "../../HelpingFunction/Job/JobDBHelper"
import RegisterCustomerModalComponent from "../ModalComponents/RegisterCustomerModalComponent"
import VerifyPhone from "../../pages/verify-phone"
import AcceptDraftContractModalComponent from "../ModalComponents/AcceptDraftContractModalComponent"
import CopyDraftContractModalComponent from "../ModalComponents/CopyDraftContractModalComponent"
import CreateDraftQuotationModal from "../ModalComponents/CreateDraftQuotationModal"
import ApplyAccountDelectionComponent from "../ModalComponents/ApplyAccountDelection"
import { applyAccountDeletion } from "../../HelpingFunction/Member/memberDBHelper"
import ShowAssistantKOLTableComponent from "../ModalComponents/ShowAssistantKOLTableComponent"
import GroupQuoteContract from "../../page-components/project/ProjectRightSection/GroupQuoteContract"
import GroupQuoteProject from "../../page-components/project/ProjectRightSection/GroupQuoteProject"
import SignupTutorialModalComponent from "../ModalComponents/SignupTutorialModalComponent"
import AssistantTutorialModalComponent from "../ModalComponents/AssistantTutorialModalComponent"
import InvoicePreviewModalComponent from "../ModalComponents/InvoicePreviewModalComponent"
import UpdateUsernameModalComponent from "../ModalComponents/UpdateUsernameModalComponent"
import KOLMissionTutorialModalComponent from "../ModalComponents/KOLMissionTutorialModalComponent"
import PreviewSelectedKOLModalComponent from "../ModalComponents/PreviewSelectedKOLModalComponent"
import { toast } from "react-hot-toast"
import KOLContractSelectionModalComponent from "../ModalComponents/KOLContractSelectionModalComponent"

const ModalController = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [isOpen, setIsOpen] = useState(false)
  const [type, setType] = useState("")
  const [bankWithdrawLoading, setBankWithdrawLoading] = useState(false)
  const auth = useSelector((state) => {
    return state.firebase.auth
  })
  const chatID = useSelector((state) => {
    return state.ChatRoomInfo.chatroomInfo.id
  })
  const step = useSelector((state) => {
    return state.RecommendationManager.recommendationStep
  })
  const quoteStep = useSelector((state) => {
    return state.ToolsManager.quoteStep
  })

  const job = useSelector((state) => {
    return state.ChatRoomInfo.chatroomJob
  })
  const groupQuotationPage = useSelector((state) => {
    return state.ProjectManager.groupQuotationPage
  })

  useEffect(() => {
    if (isOpen !== props.isModalOpen) {
      setIsOpen(props.isModalOpen)
    }

    if (type !== props.typeOfModal) {
      setType(props.typeOfModal)
    }
  }, [props.isModalOpen, props.typeOfModal])

  if (type === List.SUPPORTED_MODAL_TYPE.UPLOAD_FILE_MODAL) {
    const uploadFile = async (itemsRef) => {
      let allFile = []
      if (Array.isArray(itemsRef)) {
        itemsRef.forEach((ref) => {
          if (ref !== null) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            allFile.push(ref.returnFile())
          }
        })

        const uploadState = await onUploadFile(
          allFile,
          props.metaData.agencyID ? props.metaData.agencyID : props.auth.uid,
          props.uploadPath
        )

        if (uploadState.success) {
          props.returnFn(props.metaData, uploadState.data)
          props.closeModal()
        }
      }
    }
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "Upload",
            color: "info",
            size: "32",
            onClick: uploadFile,
          },
        ]}
        title={"UploadFileModal.title"}
        modalSize={"3xl"}
        component={UploadFileModalComponent}
        metaData={props.metaData}
        haveLoading={true}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.CONTACT_KOL_MODAL) {
    const setContractType = () => {
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_PAGE_TYPE,
        data: CHATROOM_PAGE_TYPE.CONTRACT,
      })
    }
    const getCustomerUserName = () => {
      return new Promise(async (resolve, reject) => {
        const getState = await returnIdTokenResult()
        if (getState.success) {
          return resolve(
            typeof getState.token.userName !== "undefined"
              ? getState.token.userName
              : ""
          )
        }
        return resolve("")
      })
    }

    const submitRequest = async () => {
      const customerName = await getCustomerUserName()
      createChatroom(
        auth.uid,
        props.metaData.KOLID,
        props.metaData.userName,
        customerName
      ).then((result) => {
        if (result.success) {
          history.push({
            pathname: "/" + getUserLang() + "/c",
            search:
              "?" + new URLSearchParams({ chatroomID: result.chatroomID }),
          })
          setContractType()
          props.closeModal()
        }
      })
    }

    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "ContactKOLModal.contact",
            color: "info",
            size: "64",
            onClick: submitRequest,
          },
        ]}
        title={"ContactKOLModal.title"}
        modalSize={"7xl"}
        component={ContactKOLModalComponent}
        metaData={props.metaData}
        haveLoading={true}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.CASE_STUDY_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={"CaseStudyModal.title"}
        modalSize={"4xl"}
        component={CaseStudyModalComponent}
        metaData={props.metaData}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.NEW_FOLDER_MODAL) {
    const buttonOnClickFunc = (folderName) => {
      const str = folderName.replace(" ", "")
      if (str.length > 0 && str.length <= 50) {
        props.returnFn(folderName)

        props.closeModal()
      }
    }
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "NewFolderModal.create",
            color: "success",
            size: "32",
            onClick: buttonOnClickFunc,
          },
        ]}
        title={"NewFolderModal.title"}
        modalSize={"3xl"}
        component={NewFolderModalComponent}
        metaData={props.metaData}
        haveLoading={true}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.APPLY_JOB_APPEAL_MODAL) {
    const submitRequest = async (selectedFileList, appealObj) => {
      let jobID = props.metaData.job.id
      let newChatroomID = uuidv4()
      if (Array.isArray(selectedFileList) && selectedFileList.length > 0) {
        let newSelectedFileList = selectedFileList.map((e) => e.file)
        // upload file and send a message in chatroom
        const uploadState = await onUploadFile(
          newSelectedFileList,
          props.auth.uid,
          "ChatRoom/" + newChatroomID
        )
        if (uploadState.success) {
          const id = uuidv4()
          setAdminRequest(id, {
            jobID: jobID,
            createDate: new Date(),
            lastUpdate: new Date(),
            status: ADMIN_REQUEST_STATUS_TYPE.PENDING,
            requestUID: props.auth.uid,
            type: ADMIN_REQUEST_TYPE.APPEAL,
            data: {
              ...appealObj,
            },
          }).then((result) => {
            if (result.success) {
              console.log("submitted")
            }
          })
        }
      }
    }
    const onClickFunc = (returnObj) => {
      submitRequest(returnObj.selectedFileList, returnObj.appealObj)
      props.returnFn()
      props.closeModal()
    }
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "ApplyJobAppealModal.submit",
            color: "success",
            size: "32",
            onClick: onClickFunc,
          },
        ]}
        title={"ApplyJobAppealModal.title"}
        modalSize={"3xl"}
        component={ApplyJobAppealModalComponent}
        metaData={props.metaData}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.SUBMIT_JOB_FULFILLMENT) {
    const submitFulfillment = async () => {
      const state = await sendPayoutRequest(
        props.metaData.job.id,
        props.metaData.job.CustomerID,
        props.auth.uid
      )
      if (state.success) {
        props.returnFn(state)
        props.closeModal()
      } else {
        console.log("error")
      }
    }
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "SubmitJobFulfillment.confirm",
            color: "success",
            size: "32",
            onClick: submitFulfillment,
          },
        ]}
        title={"SubmitJobFulfillment.title"}
        modalSize={"2xl"}
        component={SubmitJobFulfillmentModalComponent}
        metaData={props.metaData}
        haveLoading={true}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.VIEW_FOLLOWERS_DETAIL_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={"ViewFollowersDetailModal.title"}
        modalSize={"7xl"}
        component={ViewFollowersDetailModalComponent}
        metaData={props.metaData}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.KOL_INFO_PREVIEW_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={""}
        modalSize={"3xl"}
        component={KOLPreviewModalComponent}
        metaData={props.metaData}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.VIEW_IMAGE_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={"ViewImageModal.title"}
        modalSize={"3xl"}
        component={ViewImageModalComponent}
        metaData={props.metaData}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.DELETE_MESSAGE_MODAL) {
    const deleteMessage = () => {
      confirmDeleteMessage(props, dispatch)
    }
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "DeleteMessageModal.confirm",
            size: "32",
            color: "primary",
            onClick: deleteMessage,
          },
        ]}
        title={"DeleteMessageModal.title"}
        modalSize={"md"}
        component={DeleteMessageModalComponent}
        metaData={props.metaData}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.VIEW_KOL_PERCENTAGE_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={"AdminViewKOLPercentageModal.title"}
        modalSize={"3xl"}
        component={AdminViewKOLPercentageModalComponent}
        metaData={props.metaData}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.BANK_WITHDRAW_MODAL) {
    const submitRequest = (ref) => {
      setBankWithdrawLoading(true)
      let bankDetail = bankDefault

      let isSave = false
      //get bank detail from different source
      if (ref.type === "1") {
        bankDetail = ref.selectedBankAcc
      } else if (
        ref.type === "2" &&
        typeof ref.newBankAccountRef !== "undefined"
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        bankDetail = ref.newBankAccountRef.returnData().bankDetail
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isSave = ref.newBankAccountRef.returnData().isSave
      }

      //check bank detail
      if (
        bankDetail.accNum === "" &&
        bankDetail.cardHolder === "" &&
        ref.type === "1"
      ) {
        ref.setError("BankWithdrawModal.no-account-selected")
        setBankWithdrawLoading(false)
      } else if (
        (bankDetail.accNum === "" || bankDetail.cardHolder === "") &&
        ref.type === "2"
      ) {
        ref.setError("BankWithdrawModal.bank-account-not-correct")
        setBankWithdrawLoading(false)
      } else {
        // if pass
        // in cent
        setPayoutRequest(
          props.metaData.agencyID ? props.metaData.agencyID : auth.uid,
          ref.withdrawAmount * 100,
          bankDetail,
          props.metaData.agencyID ? props.metaData.agencyID : "",
          ref.isFive
        )
          .then((result) => {
            if (result.success) {
              //if the user want to save bank account
              if (isSave) {
                setWalletBank(
                  props.metaData.agencyID ? props.metaData.agencyID : auth.uid,
                  bankDetail,
                  props.metaData.agencyID ? props.metaData.agencyID : ""
                )
                  .then((result1) => {
                    if (result1.success) {
                      props.returnFn()
                      props.closeModal()
                      window.location.reload()
                    } else {
                      ref.setError(result.message)
                    }
                  })
                  .catch((err) => console.log(err))
              } else {
                props.returnFn()
                props.closeModal()
                window.location.reload()
              }
            } else {
              setBankWithdrawLoading(false)
              ref.setError(result.message)
            }
          })
          .catch((err) => console.log(err))
      }
    }
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "BankWithdrawModal.submit",
            color: "primary",
            size: "32",
            onClick: submitRequest,
            noAutoClose: true,
            loadingState: bankWithdrawLoading,
          },
        ]}
        title={"BankWithdrawModal.title"}
        modalSize={"4xl"}
        component={BankWithdrawModalComponent}
        metaData={props.metaData}
        disableOutbounds={true}
        haveLoading={true}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.WALLET_HISTORY_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={""}
        modalSize={"2xl"}
        component={WalletHistoryModalComponent}
        metaData={props.metaData}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.CONTRACT_DISPLAY_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={"ContractDisplayModal.title"}
        modalSize={"3xl"}
        component={ContractDisplayModalComponent}
        metaData={props.metaData}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.CONTRACT_ACCEPT_MODAL) {
    const buttonOnclickFunc = () => {
      props.returnFn(props.metaData.role)
      props.closeModal()
    }
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "ContractAcceptModal.confirm",
            color: "success",
            size: "32",
            onClick: buttonOnclickFunc,
          },
        ]}
        title={"ContractAcceptModal.title"}
        modalSize={"3xl"}
        component={ContractAcceptModalComponent}
        metaData={props.metaData}
        returnFn={props.returnFn}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.SIGNUP_COVER_IMAGE_MODAL) {
    const selectCoverImage = (url) => {
      props.returnFn(props.metaData.position, url)
      props.closeModal()
    }
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "IgCoverImageModal.select-button",
            color: "primary",
            size: "32",
            onClick: selectCoverImage,
          },
        ]}
        title={"IgCoverImageModal.instagram-feed"}
        modalSize={"3xl"}
        component={SignUpCoverImageModalComponent}
        metaData={props.metaData}
        haveLoading={true}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.CREATE_NEW_CONTRACT_MODAL) {
    const buttonOnClickFunc = () => {
      const query = new URLSearchParams({
        chatroomID: chatID,
        type: "contract",
        metaPath: "new",
      })
      history.push({
        pathname: "/" + getUserLang() + "/c",
        search: "?" + query,
      })
      props.closeModal()
    }
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "ContractCreateModal.confirm",
            color: "success",
            size: "32",
            onClick: buttonOnClickFunc,
          },
        ]}
        title={"ContractCreateModal.title"}
        modalSize={"md"}
        component={CreateNewContractModalComponent}
        metaData={props.metaData}
        haveLoading={true}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.CREATE_CASE_STUDY_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "CaseStudyModal.finish",
            color: "primary",
            size: "32",
            onClick: props.closeModal(),
          },
        ]}
        title={"CaseStudyModal.title"}
        modalSize={"3xl"}
        component={CreateCaseStudyModalComponent}
        metaData={props.metaData}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.VIEW_CASE_STUDY_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={"ViewCaseStudyModal.title"}
        modalSize={"4xl"}
        modalHeight={"full"}
        component={ViewCaseStudyModalComponent}
        metaData={props.metaData}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.LOGIN_LINKTREE_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={""}
        modalSize={"md"}
        component={LoginModalComponent}
        metaData={props.metaData}
        noCancelButton={true}
        haveLoading={true}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.PROJECT_MANAGEMENT_MODAL) {
    const setCurrentProject = (editProject) => {
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.SET_CURRENT_PROJECT,
        data: editProject,
      })
    }
    const updateEditProject = async (ref) => {
      if (ref.editProject.title === "" || ref.editProject.description === "") {
        ref.setError({
          show: true,
          message: "Please fill in everything.",
        })
      } else if (ref.editProject.title.length > 25) {
        ref.setError({
          show: true,
          message: "Make Title Shorter.",
        })
      } else if (ref.editProject.budget > 99999) {
        ref.setError({
          show: true,
          message: "Please set a lower Budget",
        })
      } else {
        await updateProject(ref.editProject).then((res) => {
          if (res.success) {
            setCurrentProject(ref.editProject)
            props.closeModal()
          }
        })
      }
    }
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "ProjectOptionBar.done",
            color: "",
            size: "32",
            onClick: updateEditProject,
          },
        ]}
        title={"ProjectModal.title"}
        modalSize={"md"}
        component={ProjectManagementModal}
        metaData={props.metaData}
        disableOutbounds={true}
        noCancelButton={true}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.MOBILE_HELP_DOC_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={
          props.metaData.label ? props.metaData.label : t("ZestSystem.help-doc")
        }
        modalSize={"4xl"}
        component={MobileHelpDocModalComponent}
        metaData={props.metaData.helpDocs}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.LOGIN_ROLE_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={"RegForm.sign-up-as"}
        modalSize={"2xl"}
        component={LoginRoleModalComponent}
        metaData={props.metaData}
        noCancelButton={true}
        haveLoading={false}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.ADD_TO_COMPARE_GROUP_MODAL) {
    const confirm = (ref) => {
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.COMPARE_GROUP_NEED_UPDATE,
      })

      props.closeModal()
    }

    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "Save",
            color: "",
            size: "32",
            onClick: confirm,
          },
        ]}
        title={"AddToCompareGroup.title"}
        modalSize={"2xl"}
        component={AddToCompareGroup}
        metaData={props.metaData}
        noCancelButton={false}
        haveLoading={false}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.CREATE_COMPARE_GROUP_MODAL) {
    const confirm = (ref) => {
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.CREATE_NEW_COMPARE_GROUP,
        data: ref.groupName,
        selectedKOLID: "",
      })

      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.COMPARE_GROUP_NEED_UPDATE,
      })

      props.closeModal()
    }

    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "Save",
            color: "",
            size: "32",
            onClick: confirm,
          },
        ]}
        title={"CreateNewCompareGroup.title"}
        modalSize={"2xl"}
        component={CreateNewCompareGroup}
        metaData={props.metaData}
        noCancelButton={false}
        haveLoading={false}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.SEND_RECOM_CONTRACT_MODAL) {
    const setStep = (state) => {
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.SET_RECOMMENDATION_STEP,
        data: state,
      })
    }

    const setQuoteStep = (state) => {
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.SET_QUOTE_STEP,
        data: state,
      })
    }
    const goToProgressStep = () => {
      if (window.location.href.includes("quote")) {
        setQuoteStep(quoteStep + 1)
      } else {
        setStep(step + 1)
      }
      props.closeModal()
    }
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "RecommendQuestion.submit",
            color: "success",
            size: "32",
            onClick: goToProgressStep,
          },
        ]}
        title={"SendAllContractsModal.title"}
        modalSize={"2xl"}
        component={SendAllContractsModalComponent}
        metaData={props.metaData}
        noCancelButton={false}
        haveLoading={false}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.VIEW_CONTRACT_EXAMPLE_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={"RecomContract.quotation-example"}
        modalSize={"3xl"}
        modalHeight={"3/4"}
        component={ViewContractExampleComponent}
        metaData={props.metaData}
        noCancelButton={true}
        haveLoading={false}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.CASE_STUDY_IG_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={""}
        modalSize={"4xl"}
        component={CaseStudyIGModalComponent}
        metaData={props.metaData}
        noCancelButton={true}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.COMPARE_GROUP_TABLE_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={""}
        modalSize={"7xl"}
        component={KOLCompareTable}
        metaData={props.metaData}
        noCancelButton={true}
        disableOutbounds={props.metaData.disableOutbounds}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.OWNED_KOL_SETTING_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={""}
        modalSize={"7xl"}
        component={OwnedKOLAccess}
        metaData={props.metaData}
        noCancelButton={true}
        disableOutbounds={props.metaData.disableOutbounds}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.ADD_CHATGPT_CHAT_MODAL) {
    const confirmCreate = () => {
      createChatGPTChatroom(auth.uid).then((result) => {
        if (result.success) {
          props.closeModal()
          history.push({
            pathname: "/" + getUserLang() + "/c",
            search:
              "?" + new URLSearchParams({ chatroomID: result.chatroomID }),
          })
        }
      })
    }

    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "AddChatgptChatModal.confirm",
            color: "success",
            size: "32",
            onClick: confirmCreate,
          },
        ]}
        title={""}
        modalSize={"4xl"}
        component={AddChatgptChatroomComponent}
        metaData={props.metaData}
        noCancelButton={true}
        haveLoading={true}
      />
    )
  } else if (
    type === List.SUPPORTED_MODAL_TYPE.SHOW_ASSISTANT_KOL_TABLE_MODAL
  ) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={""}
        modalSize={"7xl"}
        modalHeight={"3/4"}
        metaData={null}
        component={ShowAssistantKOLTableComponent}
        noCancelButton={false}
        disableOutbounds={true}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.CONTRACT_SKIP_PAYMENT_MODAL) {
    const setSkipPayment = async () => {
      const result = await skipContractPayment(job.id)
      if (result.success) {
        props.closeModal()
        console.log("Success")
      }
    }
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            text: "AddChatgptChatModal.confirm",
            color: "success",
            size: "32",
            onClick: setSkipPayment,
          },
        ]}
        title={"ContractSkipPayment.modal-title"}
        modalSize={"3xl"}
        component={ContractSkipPaymentModalComponent}
        metaData={props.metaData}
        noCancelButton={true}
        haveLoading={true}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.CREATE_DRAFT_CONTRACT_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title="DraftContractModal.title"
        modalSize={"2xl"}
        component={CreateDraftContractModal}
        metaData={props.metaData}
        noCancelButton={true}
        haveLoading={true}
        disableOutbounds={true}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.CREATE_DRAFT_QUOTATION_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title="DraftQuotationModal.title"
        modalSize={"2xl"}
        modalHeight="full"
        component={CreateDraftQuotationModal}
        metaData={props.metaData}
        noCancelButton={true}
        haveLoading={true}
        disableOutbounds={true}
      />
    )
  } else if (type === List.SUPPORTED_MODAL_TYPE.SHOW_DRAFT_CONTRACT_MODAL) {
    const inviteCustomer = async () => {
      const urlType = job.accepted ? "invoice" : "job-preview"
      const url = `https://look4kol.com/${getUserLang()}/${urlType}/${job.id}`

      await navigator.clipboard
        .writeText(url)
        // eslint-disable-next-line no-alert
        .then(() => {
          toast.success(t("DraftContractModal.link-copied"))
        })
    }
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            color: "primary",
            onClick: inviteCustomer,
            size: "full",
            text: "DraftContractModal.invite-customer",
          },
        ]}
        title={job.ServicePlanName}
        modalSize={"2xl"}
        modalHeight="3/4"
        component={ShowDraftContractModalComponent}
        metaData={props.metaData}
        noCancelButton={true}
        haveLoading={false}
      />
    )
  } else if (type === SUPPORTED_MODAL_TYPE.PROJECT_CREATE_MOBILE_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={""}
        modalSize={"4xl"}
        metaData={null}
        component={AddProject}
        noCancelButton={true}
        disableOutbounds={true}
      />
    )
  } else if (type === SUPPORTED_MODAL_TYPE.PROJECT_PAGE_MOBILE_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={""}
        modalSize={"4xl"}
        metaData={null}
        component={ProjectPageOptionBar}
        noCancelButton={false}
        disableOutbounds={true}
      />
    )
  } else if (type === SUPPORTED_MODAL_TYPE.REGISTER_CUSTOMER_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={""}
        modalSize={"2xl"}
        metaData={null}
        component={RegisterCustomerModalComponent}
        noCancelButton={false}
        disableOutbounds={true}
      />
    )
  } else if (type === SUPPORTED_MODAL_TYPE.VERIFY_PHONE_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={""}
        modalSize={"4xl"}
        metaData={null}
        forceOpen={true}
        component={VerifyPhone}
        noCancelButton={true}
        disableOutbounds={true}
      />
    )
  } else if (type === SUPPORTED_MODAL_TYPE.ACCEPT_DRAFT_CONTRACT_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={""}
        modalSize={"2xl"}
        metaData={null}
        forceOpen={false}
        component={AcceptDraftContractModalComponent}
        noCancelButton={true}
        disableOutbounds={false}
      />
    )
  } else if (type === SUPPORTED_MODAL_TYPE.COPY_DRAFT_CONTRACT_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={""}
        modalSize={"md"}
        modalHeight="1/4"
        metaData={props.metaData}
        forceOpen={false}
        component={CopyDraftContractModalComponent}
        noCancelButton={true}
        disableOutbounds={false}
      />
    )
  } else if (type === SUPPORTED_MODAL_TYPE.ACCOUNT_DELETION_MODAL) {
    const removeAccount = async () => {
      const result = await applyAccountDeletion(auth.uid)
      logout()
      props.closeModal()
    }

    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[
          {
            color: "primary",
            onClick: removeAccount,
            size: "full",
            text: "ApplyAccountDelectionComponent.title",
          },
        ]}
        title={"ApplyAccountDelectionComponent.title"}
        modalSize={"md"}
        modalHeight="1/4"
        metaData={props.metaData}
        forceOpen={false}
        component={ApplyAccountDelectionComponent}
        noCancelButton={true}
        disableOutbounds={false}
      />
    )
  } else if (type === SUPPORTED_MODAL_TYPE.GROUP_QUOTATION_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={"GroupQuoteProject.title"}
        modalSize={groupQuotationPage === 1 ? "2xl" : "4xl"}
        modalHeight={"3/4"}
        metaData={props.metaData}
        forceOpen={false}
        component={GroupQuoteProject}
        noCancelButton={true}
        disableOutbounds={true}
      />
    )
  } else if (type === SUPPORTED_MODAL_TYPE.SIGNUP_TUTORIAL_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={"SignUp.signup-tutorial"}
        modalSize={"3xl"}
        modalHeight={"3/4"}
        metaData={props.metaData}
        forceOpen={false}
        component={SignupTutorialModalComponent}
        noCancelButton={true}
        disableOutbounds={true}
      />
    )
  } else if (type === SUPPORTED_MODAL_TYPE.ASSISTANT_TUTORIAL_MOBILE_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={"AssistantTutorial.modal-title"}
        modalSize={"3xl"}
        modalHeight={"3/4"}
        metaData={props.metaData}
        forceOpen={false}
        component={AssistantTutorialModalComponent}
        noCancelButton={true}
        disableOutbounds={false}
      />
    )
  } else if (type === SUPPORTED_MODAL_TYPE.INVOICE_PREVIEW_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={""}
        modalSize="4xl"
        modalHeight="full"
        metaData={props.metaData}
        forceOpen={false}
        component={InvoicePreviewModalComponent}
        noCancelButton={true}
        disableOutbounds={false}
      />
    )
  } else if (type === SUPPORTED_MODAL_TYPE.UPDATE_KOL_USERNAME_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={"KOLProfile.change-ig-username"}
        modalSize={"3xl"}
        modalHeight={"3/4"}
        metaData={props.metaData}
        forceOpen={false}
        component={UpdateUsernameModalComponent}
        noCancelButton={true}
        disableOutbounds={false}
      />
    )
  } else if (type === SUPPORTED_MODAL_TYPE.KOL_MISSION_TUTORIAL_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={"KOLMissionTutorial.modal-title"}
        modalSize={"3xl"}
        modalHeight={"3/4"}
        metaData={props.metaData}
        forceOpen={false}
        component={KOLMissionTutorialModalComponent}
        noCancelButton={true}
        disableOutbounds={false}
      />
    )
  } else if (type === SUPPORTED_MODAL_TYPE.PREVIEW_SELECTED_KOL_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={"QuotationPage.modal-title"}
        modalSize={"3xl"}
        modalHeight={"3/4"}
        metaData={props.metaData}
        forceOpen={false}
        component={PreviewSelectedKOLModalComponent}
        noCancelButton={true}
        disableOutbounds={false}
      />
    )
  } else if (type === SUPPORTED_MODAL_TYPE.KOL_CONTRACT_SELECTION_MODAL) {
    return (
      <CustomModal
        isOpen={isOpen}
        closeModal={props.closeModal}
        buttons={[]}
        title={"KOLContractSelectionModal.title"}
        modalSize={"3xl"}
        modalHeight={"1/4"}
        metaData={props.metaData}
        forceOpen={false}
        component={KOLContractSelectionModalComponent}
        noCancelButton={true}
        disableOutbounds={false}
      />
    )
  }
  return <div></div>
}
const mapDispatchToProps = (dispatch) => {
  return {
    setContactKOLModal: (state) =>
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
        typeOfModal: SUPPORTED_MODAL_TYPE.CONTACT_KOL_MODAL,
        returnFn: state.returnFn,
        metaData: state.metaData,
      }),
    setModalController: (state) =>
      dispatch({
        type: state.type,
        typeOfModal: state.typeOfModal,
        isModalOpen: state.isModalOpen,
      }),
    closeModal: (state) =>
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.CLOSE_MODAL,
      }),
    setDeletedMessageID: (state) =>
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.SET_DELETED_MESSAGE_ID,
        deletedMessageID: state,
      }),
  }
}

const mapStateToProps = (state) => {
  return {
    typeOfModal: state.setModalController.Modal.name,
    isModalOpen: state.setModalController.Modal.isOpen,
    returnFn: state.setModalController.returnFn,
    metaData: state.setModalController.metaData,
    uploadPath: state.setModalController.uploadPath,
    typeLimit: state.setModalController.typeLimit,
    limit: state.setModalController.limit,

    auth: state.firebase.auth,
  }
}

const enhance = connect(mapStateToProps, mapDispatchToProps)

export default enhance(withRouter(ModalController))
