import React, { useEffect, useRef, useState } from "react"
import { isAuth } from "../../../Utils/FirebaseUtils"
import NewMessageContainer from "./NewMessageContainer"
import ToolBar from "./ToolBar"
import { useDispatch, useSelector } from "react-redux"
import { Trans } from "react-i18next"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { ChatMessage } from "../../../Model/ChatMessage"
import DivContentLoader from "../../../Component/DivContentLoader"
import {
  collection,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore"
import firebaseApp from "../../../Config/firebase"
import { ChatLastMessageWithId } from "../../../Model/Chatroom"
import ChatHelper from "./ChatHelper"
import {
  getOldChat,
  pushChatMessagesToStorage,
} from "../../../HelpingFunction/Chatroom/chatroomStorageHelper"
import {
  getKOL,
  getKOLUserName,
} from "../../../HelpingFunction/KOL/kolDBHelper"
import { getCustomerCompanyName } from "../../../HelpingFunction/Customer/customerDBHelper"
import { getChatroom } from "../../../HelpingFunction/Chatroom/chatroomDBHelper"

import ChatBubble, { BUBBLE_TYPE } from "./ChatBubble"
import LoadingChatBubble from "./LoadingChatBubble"
import KOL, { KOLDefault } from "../../../Model/KOL"
import { autoScrollHelper } from "../../../Utils/hlaScrollerHelper"
import { SCROLL_DIRECTION } from "../../../Enum/AUTO_SCROLLER_TYPE"
import { getAuthUID, getQuery } from "../../../Utils/QueryHelper"
import { ASSET_TYPE, ROLE } from "../../../Enum/APP_TYPE"
import { getRole } from "../../../Utils/utiltyHelper"
import classNames from "classnames"
import ContextMenu from "./ContextMenu"
import CustomAlert from "../../../Component/NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../../Enum/ALERT_SYSYEM"
import {
  faCircleExclamation,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons"
import { pastChatGPTMessages } from "../../../HelpingFunction/Chatroom/chatgptHelper"

const db = getFirestore(firebaseApp)

interface propsState {
  isAdmin: boolean
  id?: string
}

const LoadingPlaceholder: React.FC = () => {
  return (
    <div>
      <React.Fragment>
        <div className="col-start-4 md:col-start-2 lg:col-start-4 col-end-13 my-1 rounded-lg">
          <div className="flex items-center justify-start flex-row-reverse mt-4">
            <div className="flex items-center justify-center h-4 w-10 m-2 mr-4 rounded-full bg-red-default text-white flex-shrink-0">
              <DivContentLoader w={"100%"} h={42} r={100} />
            </div>
            <div className="flex items-center justify-center h-4 w-36 rounded-xl">
              <DivContentLoader w={"100%"} h={56} r={20} />
            </div>
          </div>
        </div>
        <div className="col-start-1 col-end-10 lg:col-end-15 my-1 rounded-lg">
          <div className="flex items-center justify-end flex-row-reverse mt-4">
            <div className="flex items-center justify-center h-4 w-36 rounded-xl">
              <DivContentLoader w={"100%"} h={56} r={20} />
            </div>
            <div className="flex items-center justify-center h-4 w-10 m-2 ml-4  rounded-full bg-red-default text-white flex-shrink-0">
              <DivContentLoader w={"100%"} h={42} r={100} />
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  )
}

// eslint-disable-next-line max-statements
const ChatText: React.FC<propsState> = ({ isAdmin, id }) => {
  const dispatch = useDispatch()
  const divRef = useRef(null)
  const timerRef = useRef()
  const longPressRef = useRef(false)

  const paramList = useSelector((state: any) => {
    return state.QueryManager.params
  })

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const chatroomInfo = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomInfo
  })

  const deletedMessageID = useSelector((state: any) => {
    return state.ChatRoomInfo.deletedMessageID
  })

  const isWaitingChatgpt = useSelector((state: any) => {
    return state.ChatRoomInfo.isWaitingChatgpt
  })
  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })
  const chatGPTPastMessages = useSelector((state: any) => {
    return state.ChatRoomInfo.pastChatGPTMessages
  })

  const [currentChatRoomID, setCurrentChatRoomID] = useState<string>("")

  const [isLoadedChat, setIsLoadedChat] = useState<boolean>(false)
  const [hasPrevMsg, setHasPrevMsg] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [allMessage, setAllMessage] = useState<ChatMessage[]>([])
  const [kol, setKOL] = useState<KOL>(KOLDefault)
  const [customerName, setCustomerName] = useState<string>("")
  const [role, setRole] = useState<ROLE | "">("")
  const [contextMenu, setContextMenu] = useState<boolean>(false)
  const [contextMenuPoints, setContextMenuPoints] = useState<{
    x: number
    y: number
  }>({ x: 0, y: 0 })
  const [currentItem, setCurrentItem] = useState<ChatMessage>()

  let params = {
    type: getQuery(paramList, "type"),
    chatroomID: getQuery(paramList, "chatroomID"),
    metaPath: getQuery(paramList, "metaPath"),
    viewAs: getQuery(paramList, "viewAs"),
  }

  let unsuscribe: any

  const onSelectMessage = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SELECT_MESSAGE,
      selectedMessage: state,
    })
  }

  const setChatRoomReducer = (state: any): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_INFO,
      chatroomInfo: state,
    })
  }

  const setIsWaitingChatGPT = (bool: boolean) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.IS_WAITING_CHATGPT,
      data: bool,
    })
  }
  const setPastChatGPTMessages = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_PAST_CHATGPT_MESSAGES,
      data: state,
    })
  }
  useEffect(() => {
    let cID = id

    if (params.chatroomID) {
      cID = params.chatroomID
    }

    // if (cID && chatroomInfo.id !== cID) {
    //   getChatroom(cID).then((result) => {
    //     if (result.success) {
    //       setChatRoomReducer(result.data)
    //     }
    //   })
    // }

    if (cID && cID !== "" && cID !== currentChatRoomID && isAuth(auth)) {
      setIsLoadedChat(false)
      setCurrentChatRoomID(cID)
      setAllMessage([])
    }
  }, [auth, chatroomInfo, params.chatroomID, id])

  useEffect(() => {
    const isChatGPTChatroom = currentChatRoomID.includes("chatgpt")
    if (currentChatRoomID) {
      if (unsuscribe !== null && typeof unsuscribe !== "undefined") {
        unsuscribe()
      } else {
        setAllMessage([])
        getChat(currentChatRoomID)
      }

      const handleMessageUpdate = (snapshot: any) => {
        const changes = snapshot.docChanges()
        let newList: ChatMessage[] = []

        changes.forEach((change: any) => {
          if (change.type === "added") {
            if (
              isChatGPTChatroom &&
              change.doc.data().createUserID === "chatgpt"
            ) {
              setIsWaitingChatGPT(false)
            }

            updateLastMessage({
              chatroomID: chatroomInfo.id,
              lastMessage: {
                id: change.doc.id,
                ...change.doc.data(),
              },
            })

            let currentTime: any = new Date().getTime()
            localStorage.setItem("look4kol-cr-" + chatroomInfo.id, currentTime)
            newList.push({
              id: change.doc.id,
              ...change.doc.data(),
            })

            setAllMessage((currentList) => {
              fetchPastChatGPTMessages(currentList.concat(newList))
              if (
                typeof currentList.find(
                  (e: ChatMessage) => e.id === newList[0].id
                ) === "undefined"
              ) {
                return currentList.concat(newList)
              }

              return currentList
            })
          }
        })

        // // Use the setState callback

        setTimeout(() => divRef.current && scrollToRecentChat(), 100)
      }

      const q = query(
        collection(db, "ChatRoom", currentChatRoomID, "Message"),
        orderBy("createDate", "desc"),
        limit(1)
      )

      setIsLoadedChat(true)
      // Create the DB listener
      unsuscribe = onSnapshot(q, handleMessageUpdate, (err) => console.log(err))
      return () => {
        unsuscribe()
      }
    }
  }, [currentChatRoomID])

  useEffect(() => {
    if (deletedMessageID !== "") {
      let newList = [...allMessage]
      const index = newList.findIndex((e) => e.id === deletedMessageID)
      if (index !== null && typeof index !== "undefined" && index !== -1) {
        newList.splice(index, 1)
        setAllMessage(newList)
      }
    }
  }, [deletedMessageID])

  useEffect(() => {
    if (document.getElementById("MessageContainerParent")) {
      //componentWillUnmount
      document
        .getElementById("MessageContainerParent")!
        .addEventListener("scroll", hlaScroll)
    }

    return () => {
      if (document.getElementById("MessageContainerParent")) {
        // componentDidMount
        document
          .getElementById("MessageContainerParent")!
          .removeEventListener("scroll", hlaScroll)
      }
    }
  }, [isLoadedChat, hasPrevMsg, allMessage])

  useEffect(() => {
    if (chatroomInfo.type === "chatgpt" && role === ROLE.KOL) {
      getKOLDetails()
    }
  }, [chatroomInfo, role])

  useEffect(() => {
    if (isAuth(auth)) {
      const getRoleFn = async () => {
        const role = await getRole(params.viewAs)
        setRole(role)
      }

      getRoleFn()
    }
  }, [auth, params.viewAs])

  useEffect(() => {
    if (chatGPTPastMessages.length < 1) {
      fetchPastChatGPTMessages(allMessage)
    }
  }, [allMessage])

  useEffect(() => {
    const handleClick = () => setContextMenu(false)
    window.addEventListener("click", handleClick)
    return () => window.removeEventListener("click", handleClick)
  }, [])

  const getChat = async (cID: string, isPrevMsg?: boolean) => {
    if (!isPrevMsg) {
      setAllMessage([])
      const result = await getOldChat(cID)
      if (result.success) {
        setAllMessage(result.data)
      }
    }

    setIsLoading(true)
    let dbRef = query(
      collection(
        db,
        "ChatRoom",
        params.chatroomID ? params.chatroomID : currentChatRoomID,
        "Message"
      ),
      orderBy("createDate", "desc")
    )

    if (isPrevMsg) {
      const prevDate = allMessage[0].createDate
      dbRef = query(dbRef, where("createDate", "<=", prevDate))
    }

    const docs = await getDocs(query(dbRef, limit(25)))
    setIsLoading(false)

    if (!docs.empty) {
      const resultMessage: ChatMessage[] = docs.docs.map(
        (doc) =>
          ({
            ...doc.data(),
            id: doc.id,
          } as ChatMessage)
      )

      let newAllMessage: ChatMessage[] = []
      let newAllMessageForLocalStorage: ChatMessage[] = []

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const localStorageMessage = await getOldChat(params.chatroomID)

      if (localStorageMessage.success) {
        resultMessage.forEach((msg) => {
          if (
            typeof localStorageMessage.data.find((e) => e.id === msg.id) ===
            "undefined"
          ) {
            newAllMessageForLocalStorage.push(msg)
          }
          newAllMessage.push(msg)
        })
      } else {
        newAllMessageForLocalStorage = resultMessage
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pushChatMessagesToStorage(params.chatroomID, newAllMessageForLocalStorage)
      setAllMessage([...allMessage, ...newAllMessage])
      setHasPrevMsg(docs.docs.length === 25)
    } else {
      setHasPrevMsg(false)
    }
  }

  const scrollToTop = (): void => {
    let sy = document.getElementById("MessageContainerParent")
    if (sy !== null) {
      sy.scrollTo(0, sy.offsetHeight)
    }
  }

  const scrollToRecentChat = (): void => {
    const latestChat = document.getElementById("finalMessage")
    if (latestChat !== null) {
      latestChat.scrollIntoView({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        behavior: screenWidth < 768 ? "smooth" : "instant",
      })
    }
  }

  const getMoreChatFn = () => {
    getChat(currentChatRoomID, true)
  }

  const fetchPastChatGPTMessages = (messages: ChatMessage[]) => {
    if (chatroomInfo.id.includes("chatgpt") && Array.isArray(messages)) {
      const pastMessages = pastChatGPTMessages(messages).slice(-10)
      setPastChatGPTMessages(pastMessages)
    }
  }

  const hlaScroll = () => {
    let ele = document.getElementById("MessageContainerParent")
    ele!.onscroll = async function () {
      if (ele) {
        let pathName =
          window.location.pathname +
          "/" +
          new URLSearchParams(window.location.search).get("chatroomID") +
          "/contract"
        new URLSearchParams(window.location.search).get("type")
        autoScrollHelper(
          ele,
          hasPrevMsg &&
            (pathName.includes("/c/" + params.chatroomID + "/contract") ||
              window.location.pathname.includes("/tools/project/")),
          isLoadedChat && allMessage.length > 23,
          getMoreChatFn,
          SCROLL_DIRECTION.TOP_DETECTION
        )
      }
    }.bind(this)
  }

  const updateLastMessage = (lastMessage: ChatLastMessageWithId) => {
    // this one will not trigger the reorder list chatroom
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.UPDATE_LAST_MESSAGE,
      data: lastMessage,
    })
  }

  const getKOLDetails = async () => {
    await getKOL(chatroomInfo.participateUID[1]).then((res) => {
      if (res.success) {
        setKOL(res.data)
      }
    })
  }

  const dateSort = (a: any, b: any) => {
    return a.createDate.seconds - b.createDate.seconds
  }

  const startPressTimer = (event: React.TouchEvent, message: ChatMessage) => {
    longPressRef.current = false
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    timerRef.current = setTimeout(() => {
      if (event.cancelable) {
        event.preventDefault()
        setCurrentItem(message)
        setContextMenu(true)
        setContextMenuPoints({
          x: event.changedTouches[0].pageX,
          y: event.changedTouches[0].pageY,
        })
        longPressRef.current = true
      }
    }, 700)
  }

  const fireTouchEvent = () => {
    if (!longPressRef.current) {
      clearTimeout(timerRef.current)
    }
  }

  const returnAllowedContextMenuType = (itemType: ASSET_TYPE): boolean => {
    let allowed = false

    if (
      itemType !== ASSET_TYPE.LINK_TEXT &&
      itemType !== ASSET_TYPE.FILE &&
      itemType !== ASSET_TYPE.FILE_REF &&
      itemType !== ASSET_TYPE.CLEAR_UP &&
      itemType !== ASSET_TYPE.IMAGE
    ) {
      allowed = true
    }

    return allowed
  }

  if (isAuth(auth)) {
    return (
      <div className="flex flex-col h-full safe-are-detection-chatroom-padding">
        <div className="flex-none hidden md:inline-block h-16 md:border-b-2 md:border-gray-200">
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*
             // @ts-ignore*/}
          <ToolBar />
        </div>

        <div className="grow overflow-x-auto pb-8" id="MessageContainerParent">
          {/* chat helper will show if there is no message*/}
          {isLoadedChat && allMessage.length === 0 && <ChatHelper />}
          {chatroomInfo.type === "admin" && (
            <div className="sticky top-0 z-20">
              <CustomAlert
                title={"ChatText.admin-chatroom"}
                alertType={ALERT_TYPE.INFO}
                buttonType={{
                  type: BUTTON_TYPE.NONE,
                }}
                icon={faCircleInfo}
              />
            </div>
          )}
          <div
            className={classNames(
              " overflow-x-hidden px-5 md:pb-0 mb-2 md:px-0",
              {
                "mt-12 md:mt-0": allMessage.length > 0,
              }
            )}
            id="chatRoomText">
            {/* Loading placeholder*/}
            {allMessage.length > 23 && hasPrevMsg ? (
              <div>{isLoading && <LoadingPlaceholder />}</div>
            ) : (
              <div className="w-36 text-center mx-auto rounded-2xl my-4 bg-red-100 text-red-700 py-2 px-4">
                <Trans>ChatText.end-of-msg</Trans>
              </div>
            )}
            <div
              className="gap-y-2 md:px-1 lg:px-4 pb-12 "
              id={"MessageContainer"}>
              {isLoadedChat &&
                Array.isArray(allMessage) &&
                allMessage.sort(dateSort).map((item: ChatMessage, index) => {
                  let bType: BUBBLE_TYPE = BUBBLE_TYPE.SYSTEM
                  if (item.createUserID !== "") {
                    bType =
                      item.createUserID === getAuthUID(paramList)
                        ? BUBBLE_TYPE.OWNER
                        : BUBBLE_TYPE.OPPOSITE
                  }

                  let shouldHrDate = true
                  let shouldSystemMessage = true
                  if (index !== 0) {
                    let currentCreateUserID = item.createUserID
                    let preCreateUserID = allMessage[index - 1].createUserID

                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    let currentDate = new Date(item.createDate.seconds * 1000)
                    let preDate = new Date(
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      //@ts-ignore
                      allMessage[index - 1].createDate.seconds * 1000
                    )
                    shouldHrDate =
                      currentDate.getDate() !== preDate.getDate() ||
                      currentDate.getMonth() !== preDate.getMonth()

                    // that mean this is the new section of system message
                    shouldSystemMessage =
                      currentCreateUserID !== preCreateUserID &&
                      currentCreateUserID === ""
                  }
                  return (
                    <div
                      id={index === allMessage.length - 1 ? "finalMessage" : ""}
                      onTouchStart={(e) => startPressTimer(e, item)}
                      onTouchEnd={(e) => fireTouchEvent()}
                      onContextMenu={(e) => {
                        if (returnAllowedContextMenuType(item.type)) {
                          e.preventDefault()
                          setCurrentItem(item)
                          setContextMenu(true)
                          setContextMenuPoints({ x: e.pageX, y: e.pageY })
                        }
                      }}>
                      <ChatBubble
                        index={index}
                        shouldHrDate={shouldHrDate}
                        shouldSystemMessage={shouldSystemMessage}
                        type={bType}
                        customerName={customerName}
                        kol={kol}
                        item={item}
                        onSelectMessage={onSelectMessage}
                        chatroomID={currentChatRoomID}
                      />
                    </div>
                  )
                })}

              {isWaitingChatgpt && <LoadingChatBubble />}

              <div
                ref={divRef}
                className="col-span-full"
                id="chattext-scroll-detection"
              />
            </div>
          </div>
        </div>
        {contextMenu && (
          <div className="z-50">
            <ContextMenu
              chatroomID={currentChatRoomID}
              rightClickItem={currentItem as ChatMessage}
              pointX={contextMenuPoints.x}
              pointY={contextMenuPoints.y}
            />
          </div>
        )}

        <div className="flex-none">
          <NewMessageContainer
            isAdmin={isAdmin}
            chatroomID={currentChatRoomID}
            scrollToTop={scrollToTop}
          />
        </div>
      </div>
    )
  }
  return <div>loading...</div>
}

export default ChatText
