import React, { useEffect, useState } from "react"

import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faUser } from "@fortawesome/free-solid-svg-icons"
import { isAuth } from "../../Utils/FirebaseUtils"
import { getKOL } from "../../HelpingFunction/KOL/kolDBHelper"
import { useDispatch, useSelector } from "react-redux"
import { getNumOfUnreadMessage } from "../../HelpingFunction/Member/memberDBHelper"
import { ROLE, UNREAD_MESSAGE_TYPE } from "../../Enum/APP_TYPE"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import { faBell } from "@fortawesome/free-regular-svg-icons"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "../../Layout/Heading/Heading.module.css"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from "../../Assets/icons-72.png"
import { getRole, getUserLang } from "../../Utils/utiltyHelper"
import { Trans } from "react-i18next"
import { getAuthUID, getQuery, useQuery } from "../../Utils/QueryHelper"

export const HomeHeader: React.FC = (props) => {
  const dispatch = useDispatch()
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const params = useSelector((state: any) => {
    return state.QueryManager.params
  })

  const [role, setRole] = useState<ROLE>(ROLE.DEFAULT)

  const setNumOfUnreadContract = (i: number): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_NUM_OF_UNREAD_CONTRACT,
      numOfUnread: i,
    })
  }

  const setNumOfUnreadChat = (i: number): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_NUM_OF_UNREAD_CHAT,
      numOfUnread: i,
    })
  }

  const numOfUnreadContract = useSelector((state: any) => {
    return state.MessagingSystem.numOfUnreadContracts
  })

  const [avatar, setAvatar] = useState<string>("")

  const viewID = getQuery(params, "viewAs")
  useEffect(() => {
    if (isAuth(auth)) {
      const getRoleFn = async () => {
        const role = await getRole(viewID)
        setRole(role)
      }
      hasUnReadMessage()
      getKOL(getAuthUID(params)).then((res) => {
        if (res.success) {
          setAvatar(res.data.avatarUrl)
        } else {
          setAvatar("")
        }
      })
      getRoleFn()
    }
  }, [auth, viewID])

  const hasUnReadMessage = () => {
    getNumOfUnreadMessage(
      getAuthUID(params),
      UNREAD_MESSAGE_TYPE.JUST_CONTRACT
    ).then((res) => {
      if (res.success) {
        setNumOfUnreadContract(res.numOfUnread)
      }
    })
    getNumOfUnreadMessage(
      getAuthUID(params),
      UNREAD_MESSAGE_TYPE.JUST_CHAT
    ).then((res) => {
      if (res.success) {
        setNumOfUnreadChat(res.numOfUnread)
      }
    })
  }

  const getPath = (role: ROLE) => {
    switch (role) {
      case ROLE.KOL:
        return "/kol/wallet"

      case ROLE.CUSTOMER:
        return "/customer/job"

      case ROLE.ADMIN:
        return "/admin"

      default:
        return "/login"
    }
  }

  return (
    <div
      className="h-18 w-full py-2 px-4 grid gap-2 md:gap-4"
      style={{
        gridTemplateColumns: "auto auto 45px 45px 45px",
      }}>
      <div className="my-auto">
        <Link to={"/" + getUserLang()}>
          <img
            id={"Heading_CompanyLogo"}
            className="h-10 w-auto sm:h-10"
            src={logo}
            alt="look4kol logo"
          />
        </Link>
      </div>

      <div className="my-auto ">
        {role !== ROLE.KOL && (
          <button
            id={"Heading_Marketing"}
            className="hidden md:inline btn-sm btn-primary rounded-full text-white my-auto text-md ">
            <a target={"_blank"} href="https://calendly.com/look4kol/look4kol">
              <span className="text-white">
                <Trans>Heading.marketing</Trans>
              </span>
            </a>
          </button>
        )}
      </div>

      <div>
        {isAuth(auth) && (
          <Link to={"/" + getUserLang() + "/message"}>
            <label
              id={"Heading_NotificationBell"}
              className="btn btn-ghost btn-circle"
              tabIndex={1}>
              <div className="indicator">
                <FontAwesomeIcon
                  icon={faBell}
                  className={[
                    numOfUnreadContract > 0
                      ? classes.NotiRequest
                      : classes.HeadingIconNavItem,
                    "text-lg text-gray-500",
                  ].join(" ")}
                />
                {numOfUnreadContract > 0 && (
                  <span
                    className="badge badge-xs indicator-item"
                    style={{
                      backgroundColor: "rgb(225 125 114)",
                      border: "solid 1px white",
                    }}></span>
                )}
              </div>
            </label>
          </Link>
        )}
      </div>

      <div>
        <Link to={"/" + getUserLang() + "/s"}>
          <label
            id={"Heading_NotificationBell"}
            className="btn btn-ghost btn-circle"
            tabIndex={1}>
            <div className="indicator">
              <FontAwesomeIcon
                icon={faSearch}
                className="text-lg text-gray-500"
              />
            </div>
          </label>
        </Link>
      </div>

      <div>
        <Link to={"/" + getUserLang() + getPath(role)}>
          <div
            id={"Heading_ProfileMenu"}
            className={
              "overflow-hidden h-12 w-12 bg-red-50 rounded-full flex flex-shrink-0 justify-center items-center text-red-900 text-2xl font-mono"
            }>
            {avatar ? (
              <img src={avatar} className="h-10 w-10 rounded-full" />
            ) : (
              <FontAwesomeIcon icon={faUser} className="text-red-200" />
            )}
          </div>
        </Link>
      </div>
    </div>
  )
}
