import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"

const initialState = {
  messageList: [],
  numOfUnreadContracts: 0,
  numOfUnreadChats: 0,
}

const MessagingSystem = (state = initialState, action) => {
  if (action.type === SUPPORTED_REDUX_FUNCTIONS.RECEIVED_MESSAGE) {
    return {
      ...state,
      messageList: state.messageList.concat(action.item),
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.REMOVE_MESSAGE) {
    return {
      ...state,
      messageList: state.messageList.filter((e) => e.name !== action.itemID),
    }
  } else if (
    action.type === SUPPORTED_REDUX_FUNCTIONS.SET_NUM_OF_UNREAD_CONTRACT
  ) {
    return {
      ...state,
      numOfUnreadContracts: action.numOfUnread,
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_NUM_OF_UNREAD_CHAT) {
    return {
      ...state,
      numOfUnreadChats: action.numOfUnread,
    }
  }

  return state
}

export default MessagingSystem
