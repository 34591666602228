import React, { useEffect, useState } from "react"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { Link, useLocation } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getUserLang } from "../Utils/utiltyHelper"
import classNames from "classnames"
import { useSelector } from "react-redux"
import { isAuth } from "../Utils/FirebaseUtils"
import KOL, { KOLDefault } from "../Model/KOL"
import { getKOL } from "../HelpingFunction/KOL/kolDBHelper"
import { getAuthUID, getQuery, useQuery } from "../Utils/QueryHelper"
import { faUser } from "@fortawesome/free-solid-svg-icons"

export interface NavButtonProps {
  visible: boolean
  icon: IconDefinition | string
  iconAfter?: IconDefinition
  noHighlight?: boolean
  iconUnread?: boolean
  i18Text: string
  path: string
  onClickFn?: () => void
  indicator?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
}

export const NavButton: React.FC<NavButtonProps> = ({
  visible,
  icon,
  iconAfter,
  noHighlight,
  iconUnread,
  i18Text,
  path,
  onClickFn,
  indicator,
}) => {
  const location = useLocation()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const params = useSelector((state: any) => {
    return state.QueryManager.params
  })

  const viewID = getQuery(params, "viewAs")

  const [kol, setKOL] = useState<KOL>(KOLDefault)

  useEffect(() => {
    if (isAuth(auth)) {
      getKOL(getAuthUID(params)).then((result) => {
        if (result.success) {
          setKOL(result.data)
        }
      })
    }
  }, [auth, viewID])

  const isAfter =
    location.pathname === "/" + getUserLang() + (path ? "/" : "") + path ||
    typeof path
      .replace(/\?.*/, "")
      .split("/")
      .find((e) => e === location.pathname.split("/")[2]) !== "undefined"

  const idP = location.pathname.split("/")[3]

  const isMyProfile =
    isAuth(auth) && path.includes("p/")
      ? idP === auth.uid || idP === viewID || idP === kol.userName
      : true
  return (
    <div
      className={classNames(" flex-1", {
        hidden: !visible,
      })}>
      <Link
        to={
          !path.includes("admin")
            ? "/" + getUserLang() + (path ? "/" : "") + path
            : ""
        }
        onClick={(e) => {
          if (onClickFn) {
            onClickFn()
          }
        }}>
        <button className="w-full  md:px-2 lg:px-4 h-16 md:h-18 text-center btn btn-circle btn-ghost bg-none active:bg-gray-200 grid grid-rows-5">
          {indicator && (
            <span className="indicator-item badge badge-secondary">
              {indicator === 10 ? "9+" : indicator}
            </span>
          )}
          <div
            className={classNames(
              "row-span-3 mx-auto rounded-full w-full md:w-16 px-4 py-1 md:py-2 text-gray-800",
              {
                "bg-red-200": isAfter && isMyProfile && !noHighlight,
              }
            )}>
            <div className="indicator">
              {typeof icon === "string" ? (
                <>
                  {icon !== "" ? (
                    <img src={icon} className="h-6 w-6 rounded-full " />
                  ) : (
                    <div className="h-8 w-8 rounded-full text-center bg-red-100 pt-2">
                      <FontAwesomeIcon
                        icon={faUser}
                        className="text-red-300 my-auto"
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={iconAfter ? (isAfter ? iconAfter : icon) : icon}
                    className={classNames("text-lg", {
                      "font-bold": iconAfter && isAfter,
                    })}
                  />
                  {iconUnread && (
                    <span
                      className="badge badge-xs indicator-item"
                      style={{
                        backgroundColor: "rgb(225 125 114)",
                        border: "solid 1px white",
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <div className="row-span-2 font-normal">{i18Text}</div>
        </button>
      </Link>
    </div>
  )
}
