import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"
import { Reducer } from "react"

interface actionTypes {
  type: string
  data: boolean
}

export interface initStateType {
  isDropdown: boolean
}

const initialState: initStateType = {
  isDropdown: false,
}

const SearchManager: Reducer<initStateType, actionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_DROPDOWN:
      return {
        ...state,
        isDropdown: !state.isDropdown,
      }
    default:
      return state
  }
}

export default SearchManager
