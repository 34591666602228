import { Timestamp } from "firebase/firestore"
import { t } from "i18next"

export function timeStampToString(ts) {
  const date = new Date(ts * 1000)
  return date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
}

export function dateObjToString(date) {
  return date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
}

export function timeStampToDateTimeString(ts) {
  const date = new Date(ts * 1000)
  return (
    date.getFullYear() +
    "/" +
    (date.getMonth() + 1) +
    "/" +
    date.getDate() +
    ", " +
    (date.getHours() > 12 ? date.getHours() - 12 : date.getHours()) +
    ":" +
    (date.getMinutes() === 0 ? "00" : date.getMinutes()) +
    (date.getHours() > 12 ? " PM" : " AM")
  )
}

export function timeStampToDateString(ts) {
  const date = new Date(ts * 1000)
  return date.getDate() + "/" + (date.getMonth() + 1)
}

export function timestraptoIOStime(st_second) {
  return new Date(st_second * 1000).toISOString()
}

export function timeStampToDisplayTimeString(d) {
  const date = timeConverter(d)
  try {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]

    return (
      date.getDate() +
      " " +
      monthNames[date.getMonth()] +
      ", " +
      date.getFullYear() +
      " | " +
      (date.getHours() > 12 ? date.getHours() - 12 : date.getHours()) +
      ":" +
      (date.getMinutes() === 0
        ? "00"
        : date.getMinutes() < 10
        ? "0" + date.getMinutes().toString()
        : date.getMinutes()) +
      (date.getHours() > 12 ? " PM" : " AM")
    )
  } catch (e) {
    console.log(e)
    return ""
  }
}

export function titleLangConverter(title, lang) {
  if (lang === "en" || title === "") {
    return title
      .replace(/<(pre)(?:(?!<\/\1).)*?<\/\1>/gsu, "")
      .replace(/<[^>]*>?/gm, "")
      .replace(/"/g, "'")
      .replace(/(([^\s]+\s\s*){80})(.*)/, "$1…")
  }
  return title
    .replace(/<(pre)(?:(?!<\/\1).)*?<\/\1>/gsu, "")
    .replace(/<[^>]*>?/gm, "")
    .replace(/"/g, "'")
    .replace(/(([^\s]+\s\s*){80})(.*)/, "$1…")
}

export function contentLangConverter(lang, content) {
  if (lang === "EN" || content === "") {
    return content
      .replace(/<(pre)(?:(?!<\/\1).)*?<\/\1>/gsu, "")
      .replace(/<[^>]*>?/gm, "")
      .replace(/"/g, "'")
      .substring(0, 500)
  }
  return content
    .replace(/<(pre)(?:(?!<\/\1).)*?<\/\1>/gsu, "")
    .replace(/<[^>]*>?/gm, "")
    .replace(/"/g, "'")
    .substring(0, 210)
}

export function timeAgo(date) {
  if (typeof date !== "undefined") {
    const dateAgo = (new Date().valueOf() / 1000 - date) / (60 * 60 * 24)
    let resStr = ""
    if (dateAgo > 30 && dateAgo <= 365) {
      resStr =
        Math.floor(parseInt(dateAgo / 30)).toString() +
        (Math.floor(parseInt(dateAgo / 30)) === 1 ? " month" : " months") +
        " ago"
    } else if (dateAgo > 365) {
      resStr =
        Math.floor(parseInt(dateAgo / 365)).toString() +
        (Math.floor(parseInt(dateAgo / 365)) === 1 ? " year" : " years") +
        " ago"
    } else if (dateAgo > 0 && dateAgo < 1) {
      if (new Date().valueOf() / 1000 - date < 3600) {
        resStr = Math.floor(
          (new Date().valueOf() / 1000 - date) / 60
        ).toString()
        resStr +=
          Math.floor((new Date().valueOf() / 1000 - date) / 60) === 1
            ? " minute"
            : " minutes" + " ago"
      } else {
        resStr =
          Math.floor((new Date().valueOf() / 1000 - date) / 3600).toString() +
          (Math.floor((new Date().valueOf() / 1000 - date) / 3600) === 1
            ? " hour"
            : " hours") +
          " ago"
      }
    } else {
      resStr =
        Math.floor(dateAgo).toString() +
        (Math.floor(parseInt(dateAgo)) === 1 ? " day" : " days") +
        " ago"
    }
    return resStr
  }
  return "Just Now"
}

export const getHourTime = (ts) => {
  if (typeof ts !== "undefined") {
    const date = new Date(
      ts.hasOwnProperty("seconds") ? date.seconds * 1000 : ts * 1000
    )

    return (
      (date.getHours() > 12 ? date.getHours() - 12 : date.getHours()) +
      ":" +
      (date.getMinutes() === 0
        ? "00"
        : date.getMinutes() < 10
          ? "0" + date.getMinutes().toString()
          : date.getMinutes()) +
      (date.getHours() > 12 ? " PM" : " AM")
    )
  }
  return "Just Now"
}

export function sizeConverter(sizeByte) {
  if (sizeByte > 1073741824) {
    return Math.round((sizeByte / 1024 / 1024 / 1024) * 100) / 100 + "GB"
  } else if (sizeByte > 1048576) {
    return Math.round((sizeByte / 1024 / 1024) * 100) / 100 + "MB"
  } else if (sizeByte > 1024) {
    return Math.round((sizeByte / 1024) * 100) / 100 + "KB"
  }
  return Math.round(sizeByte * 100) / 100 + "B"
}

export const returnListChatroomTime = (messageDate) => {
  const now = new Date()
  const msgDate = timeConverter(messageDate)
  const diffDate = now - msgDate
  const oneDayDiff = 24 * 60 * 60 * 1000

  if (diffDate < oneDayDiff) {
    return msgDate.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
  } else if (diffDate < 2 * oneDayDiff) {
    return t("Converter.yesterday")
  } else if (diffDate < 7 * oneDayDiff) {
    const dayNames = [
      t("Converter.sunday"),
      t("Converter.monday"),
      t("Converter.tuesday"),
      t("Converter.wednesday"),
      t("Converter.thursday"),
      t("Converter.friday"),
      t("Converter.saturday"),
    ]
    return dayNames[msgDate.getDay()]
  }
  const dd = String(msgDate.getDate()).padStart(2, "0")
  const mm = String(msgDate.getMonth() + 1).padStart(2, "0")
  const yy = String(msgDate.getFullYear()).slice(2)
  return `${dd}/${mm}/${yy}`
}

export function servicePlanToSelectObj(sp) {
  let newSp = []
  if (Array.isArray(sp)) {
    sp.map((e) => {
      newSp.push({
        label: e.title + "(~ HKD " + e.price + ")",
        value: e.id,
      })
    })
  }
  newSp.push({
    label: "[自定 Custom]",
    value: "custom",
  })
  return newSp
}

export function intToFloat(num, decPlaces) {
  const number = num.toFixed(decPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  return number
}

export function dataURLtoBlob(dataurl) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

export function jsxComponentToString(jsx) {
  if (typeof jsx !== "string") {
    if (Array.isArray(jsx)) {
      let el = ""
      jsx.map((e) => {
        el = el += jsxComponentToString(e)
      })
      return el
    } else if (
      jsx.props.children !== null &&
      (jsx.type === "h2" || jsx.type === "h3")
    ) {
      return jsxComponentToString(jsx.props.children)
    }
    return ""
  }
  return jsx
}

export const followersCount = (fCount) => {
  if (fCount > 9999 && fCount < 100000) {
    let num = fCount / 1000
    num = num.toFixed(2)
    num = num.toString()
    num = num.substring(0, num.length - 1)
    return num + "K"
  } else if (fCount >= 100000 && fCount < 1000000) {
    let num = fCount / 1000
    num = num.toFixed(2)
    num = num.toString()
    num = num.substring(0, num.length - 3)
    return num + "K"
  } else if (fCount >= 1000000 && fCount < 10000000) {
    let num = fCount / 1000000
    num = num.toFixed(2)
    num = num.toString()
    num = num.substring(0, num.length - 1)
    return num + "M"
  } else if (fCount >= 10000000) {
    let num = fCount / 1000000
    num = num.toFixed(2)
    num = num.toString()
    num = num.substring(0, num.length - 1)

    return num + "M"
  }
  return fCount
}

export const chatroomUrlConvertor = (url) => {
  if (!url.includes("/c?")) {
    if (url.slice(0, 2) === "c/") {
      url = "/" + url
    }
    const searchStr = url.split("/")
    return new URLSearchParams({
      chatroomID: searchStr[2],
      type: searchStr[3],
      metaPath: searchStr[4],
    })
  }
  return url.split("?")[1]
}

export const timeConverter = (obj) => {
  try {
    let date

    if (typeof obj === "string" || typeof obj === "number") {
      date = new Date(obj)
    }

    if (obj.hasOwnProperty("seconds")) {
      date = new Date(obj.seconds * 1000)
    }

    if (date instanceof Date && !isNaN(date.getTime())) {
      return date
    }

    return new Date(obj)
  } catch (e) {
    console.log(e)
    return new Date()
  }
}
