import React, { useEffect, useState } from "react"
import classes from "./SideBar.module.css"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

const SideBar = (props) => {
  const [xPosition, setX] = React.useState(-props.width)
  const [hasInit, setHasInit] = useState(false)

  useEffect(() => {
    setTimeout(function () {
      if (window.innerWidth < 768) {
        setX(props.position === "right" ? props.width : -props.width)
        setHasInit(true)
      } else {
        setX(0)
        setHasInit(true)
      }
    }, 1)
  }, [])

  useEffect(() => {
    if (hasInit) {
      if (props.isOpen) {
        document.getElementById(props.name).style.display = "inline"
        setTimeout(function () {
          setX(0)
        }, 100)
      } else {
        setX(props.position === "right" ? props.width : -props.width)
        setTimeout(function () {
          document.getElementById(props.name).style.display = "none"
        }, 500)
      }
    }
  }, [props.isOpen])

  return (
    <React.Fragment>
      <div
        className={[
          hasInit ? classes.SideBar : "hidden",
          "border-r border-gray-100 md:border-none shadow-xl min-h-screen md:h-auto",
        ].join(" ")}
        style={{
          transform: `translatex(${props.screenWidth < 768 ? xPosition : 0}px)`,
          width: window.innerWidth < 768 ? props.width : "100%",
          minHeight: props.height,
          float: props.position === "right" ? "right" : "left",
          // borderRight: "1px",
          // boxShadow: "164px 5px 5px rgb(0 0 0 / 20%)"
        }}>
        <div className="min-h-screen shadow-xl md:shadow-none pt-8 md:pt-0 px-2">
          {props.children}
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    screenWidth: state.SystemManager.screenWidth,
  }
}

const enhance = connect(
  mapStateToProps
  // mapDispatchToProps
)

export default enhance(withRouter(SideBar))
