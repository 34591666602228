import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { gptPrompts } from "../../Model/ChatGPT"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Chatgpt from "../../Assets/chatgpt-intro.jpg"

const AddChatgptChatroomComponent: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="container mx-auto overflow-hidden">
      <div className="text-3xl font-semibold">
        <Trans>AddChatgptChatModal.title</Trans>
      </div>

      <div className="my-4 grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="col-span-1">
          <img
            className="my-2 object-cover h-64 md:h-96 mx-auto"
            src={Chatgpt}
            alt={"chatgpt"}
          />
        </div>

        <div className="col-span-1 py-2 lg:py-0">
          <div className="my-2">
            <p className=" text-gray-500 font-medium px-2">
              <Trans>AddChatgptChatModal.des</Trans>
            </p>
          </div>
          <div className="  overflow-y-auto md:h-76">
            {gptPrompts.map((prompt, index) => {
              return (
                <div key={"prompt" + index} className="my-4 mx-2  ">
                  <div className="bg-white p-4 h-24  rounded-lg shadow">
                    <h3 className=" font-medium">{t(prompt.title)}</h3>
                    <p className="text-xs my-2">{t(prompt.desc)}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
export default AddChatgptChatroomComponent
