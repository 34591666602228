import React, { useEffect, useState } from "react"

import { useSelector } from "react-redux"
import { Trans } from "react-i18next"
import classNames from "classnames"

import TableToolBars from "./TableToolBars"
import DivContentLoader from "./DivContentLoader"
import Columns from "./Columns"
import { Column, tableFunctions } from "../Model/Table"
import { TABLE_TYPE } from "../Enum/TABLE_SYSTEM"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons"
import {
  expandWidthStyle,
  ORDER_BY_DIRECTION,
} from "../Utils/TableHelperFunction"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "./NewTable.module.css"

interface Table {
  defaultSchema?: Column[]
  cols: Column[][]
  tableFn?: tableFunctions[]
  hoverAction?: () => void
  clickAction?: (index: number) => void
  prevBtn?: {
    disable: boolean
    onClick: () => void
  }
  pageNum?: number | string
  nextBtn?: {
    disable: boolean
    onClick: () => void
  }
  isFirstColStatic: boolean
  isLoading: boolean
  rowIsLoading: boolean
  notFoundDes: string
  isLastRecord: boolean
  haveScrollRefresh?: boolean
}

const CustomTable: React.FC<Table> = ({
  defaultSchema,
  cols,
  tableFn,
  hoverAction,
  clickAction,
  nextBtn,
  pageNum,
  prevBtn,
  isFirstColStatic,
  isLoading,
  rowIsLoading,
  notFoundDes,
  isLastRecord,
  haveScrollRefresh,
}) => {
  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })

  const [isTableMoved, setIsTableMoved] = useState<boolean>(false)

  const scrollToMostRight = () => {
    const component = document.getElementById("customTable")
    if (component) {
      const scrollRight = component.scrollWidth
      const scrollLeft = component.scrollLeft
      component.scrollTo(scrollRight, 0)
      setIsTableMoved(scrollLeft > 5)
    }
  }

  const hlaScroll = () => {
    document.getElementById("customTable")!.onscroll = async function () {
      const scrollOffset = 5
      let scrollLeft = document.getElementById("customTable")!.scrollLeft
      setIsTableMoved(scrollLeft > scrollOffset)
    }.bind(this)
  }

  useEffect(() => {
    if (document.getElementById("customTable")) {
      //componentWillUnmount
      document
        .getElementById("customTable")!
        .addEventListener("scroll", hlaScroll)
    }

    return () => {
      if (document.getElementById("customTable")) {
        // componentDidMount
        document
          .getElementById("customTable")!
          .removeEventListener("scroll", hlaScroll)
      }
    }
  }, [cols])

  useEffect(() => {
    if (haveScrollRefresh) {
      scrollToMostRight()
    }
  }, [cols])

  const sortingIcon = (order: false | ORDER_BY_DIRECTION) => {
    if (order === false) {
      return faSort
    }
    return order === ORDER_BY_DIRECTION.ASC ? faSortUp : faSortDown
  }

  //basic sort function checks (non-header types)
  const nonHeadSortCheck = (col: Column) => {
    if ("canSort" in col && col.canSort && col.type !== TABLE_TYPE.HEAD) {
      return (
        <button
          className={classNames("btn btn-ghost", {
            "text-gray-200": col.canSort.order === false,
          })}
          onClick={col.canSort.sortFn}>
          <FontAwesomeIcon
            className="text-sm"
            icon={sortingIcon(col.canSort.order)}
          />
        </button>
      )
    }
  }

  //header types (with custom col / row component) sort function checks
  //needs to break down components to check which is the component used for sorting
  const headSortCheck = (headerComponent: Column) => {
    let result: Column | false = false
    if (
      headerComponent.type === TABLE_TYPE.HEAD &&
      headerComponent.canSort &&
      (headerComponent.component.type === TABLE_TYPE.ROW ||
        headerComponent.component.type === TABLE_TYPE.COL)
    ) {
      const sortTypeName = headerComponent.canSort
      const nestedComponent = headerComponent.component
      nestedComponent.component.map((item, index) => {
        const tmpResult = findNestedSort(item, sortTypeName)
        if (
          tmpResult &&
          "canSort" in tmpResult &&
          tmpResult.type !== TABLE_TYPE.HEAD
        ) {
          result = tmpResult
        }
      })
    }

    if (result) {
      return (
        <button
          className={classNames("btn btn-ghost", {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            "text-gray-200": result.canSort.order === false,
          })}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onClick={result.canSort.sortFn}>
          <FontAwesomeIcon
            className="text-sm"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            icon={sortingIcon(result.canSort.order)}
          />
        </button>
      )
    }
    return <div />
  }

  //search each individual component to see if one of them has the valid sort function type
  //exclusive for headSortCheck()
  const findNestedSort = (nestedComponent: Column, sortTypeName: string) => {
    if (
      nestedComponent.type === TABLE_TYPE.ROW ||
      nestedComponent.type === TABLE_TYPE.COL
    ) {
      const innerNestedComponent = nestedComponent.component
      innerNestedComponent.map((item, index) => {
        const result = findNestedSort(item, sortTypeName)
        if (result) return result
      })
    } else if (
      nestedComponent.title === sortTypeName &&
      "canSort" in nestedComponent
    ) {
      return nestedComponent
    }
    return false
  }

  if (!isLoading && cols.length) {
    const havePagination =
      typeof nextBtn !== "undefined" &&
      typeof prevBtn !== "undefined" &&
      typeof pageNum !== "undefined"
    return (
      <div className={classNames("mt-2", { " mb-8 lg:mb-4": havePagination })}>
        {tableFn && (
          <div className="sticky -top-1 z-[9] bg-white px-1 lg:px-0 rounded-b-xl md:rounded-xl shadow-md md:shadow-none ">
            <div
              className={classNames(
                "bg-white gap-4 lg:gap-3 items-center mx-2 flex overflow-x-scroll mb-1 pb-2 lg:pb-4",
                classes.tableToolScrollBar
              )}>
              {tableFn.map((fn: tableFunctions, index: number) => {
                return (
                  <div className="shrink-0">
                    <TableToolBars
                      title={fn.title}
                      data={fn.data}
                      key={"tableTool-" + index}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        )}
        <div
          id="customTable"
          className={classNames("overflow-x-auto m-2", classes.tableScrollBar)}>
          <table
            className={classNames(
              "table w-full",
              screenWidth < 768 ? " table-compact" : "",
              classes.customTable
            )}>
            <thead>
              <tr>
                {cols[0].map((col: Column, index: number) => {
                  if (index >= 0) {
                    return (
                      <th
                        key={"title-" + index}
                        className={
                          index === 0 && isTableMoved && classes.shadowElement
                        }>
                        <div className="flex">
                          <div className="truncate max-w-48 my-auto">
                            <Trans>{col.title}</Trans>
                          </div>
                          {col.type === TABLE_TYPE.HEAD
                            ? headSortCheck(col)
                            : nonHeadSortCheck(col)}
                        </div>
                      </th>
                    )
                  }
                  return <div />
                })}
              </tr>
            </thead>
            <tbody>
              {cols.map((rows: Column[], index: number) => {
                let rowIndex = index

                //condition for preventing loading default cols from being loaded during rowIsLoading
                if (
                  (!rowIsLoading && cols.length > 0) ||
                  (rowIsLoading && cols.length > 1)
                )
                  return (
                    <>
                      <tr
                        key={"row-" + rowIndex}
                        className={[
                          "hover:cursor-pointer group",
                          screenWidth < 768 ? " h-20" : "h-24",
                        ].join(" ")}
                        onClick={() => {
                          if (clickAction) {
                            clickAction(rowIndex)
                          }
                        }}>
                        {rows.map((data: Column, index: number) => {
                          if (index >= 0 && data.type !== TABLE_TYPE.DEFAULT) {
                            if (index === 0) {
                              return (
                                <th
                                  key={"row-" + rowIndex + "-columns-" + index}
                                  className={classNames(
                                    "group-hover:bg-gray-50 my-3 md:m-0",
                                    isTableMoved && classes.shadowElement
                                  )}>
                                  <Columns data={data} colIndex={rowIndex} />
                                </th>
                              )
                            }
                            return (
                              <td
                                key={"row-" + rowIndex + "-columns-" + index}
                                className="group-hover:bg-gray-50 my-3 md:m-0">
                                <Columns data={data} colIndex={rowIndex} />
                              </td>
                            )
                          }
                          return <div />
                        })}
                      </tr>
                      {rows
                        .filter((e) => e.type === TABLE_TYPE.EXPANDED)
                        .map((e) => {
                          if (e.type === TABLE_TYPE.EXPANDED && e.expanded) {
                            return (
                              <tr className="relative h-48 w-4/5">
                                <th
                                  className={classNames(
                                    isTableMoved && classes.shadowElement
                                  )}></th>
                                <div
                                  className={classNames(
                                    "absolute my-3 md:mb-0",
                                    expandWidthStyle(e)
                                  )}>
                                  {e.component}
                                </div>
                              </tr>
                            )
                          }
                        })}
                    </>
                  )
              })}
              {rowIsLoading && cols.length !== 0 && (
                <tr>
                  {cols[0].map((data: Column, index: number) => {
                    if (index >= 0) {
                      return (
                        <th key={"loader-" + index}>
                          <DivContentLoader w={"90%"} h={45} r={8} />
                        </th>
                      )
                    }
                    return <div />
                  })}
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {!rowIsLoading && isLastRecord && (
          <div className="w-full flex justify-center m-1 md:my-2 text-sm md:text-xs h-8">
            <Trans>CustomTable.isLastRecord</Trans>
          </div>
        )}
        {!rowIsLoading && havePagination && (
          <div className="w-full h-12">
            <div className="flex flex-row gap-2 justify-center w-64 text-center mx-auto mt-6">
              <button
                className={classNames(
                  "w-10 h-10 rounded-full bg-gray-100",
                  prevBtn.disable || pageNum === 0
                    ? "text-gray-300 cursor-not-allowed "
                    : "text-black"
                )}
                onClick={() => {
                  const isTrue =
                    typeof pageNum === "number" ? pageNum !== 0 : true
                  if (isTrue && prevBtn) {
                    prevBtn?.onClick()
                  }
                }}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>

              <div
                className={classNames(
                  { "w-10 ": typeof pageNum === "number" },
                  "h-10  px-2  rounded-full mx-auto bg-primary text-white flex items-center justify-center text-lg"
                )}>
                <p>{typeof pageNum === "number" ? pageNum + 1 : pageNum}</p>
              </div>

              <button
                className={classNames(
                  "w-10 h-10 rounded-full bg-gray-100",
                  nextBtn.disable
                    ? "text-gray-300 cursor-not-allowed "
                    : "text-black"
                )}
                onClick={() => {
                  if (!nextBtn.disable) {
                    nextBtn.onClick()
                  }
                }}>
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          </div>
        )}
      </div>
    )
  } else if (isLoading && cols.length !== 0) {
    return (
      <div>
        <div className="sticky lg:static top-0 z-10 bg-white px-1 lg:px-4 py-2 lg:py-4 lg:px-0 rounded-b-xl md:rounded-xl shadow-md md:shadow-none">
          {tableFn && (
            <div className="bg-white flex gap-2 lg:gap-3 items-center mx-2 overflow-x-scroll overflow-y-hidden">
              {tableFn.map((fn: tableFunctions, index: number) => {
                return (
                  <TableToolBars
                    title={fn.title}
                    data={fn.data}
                    key={"tableTool-" + index}
                  />
                )
              })}
            </div>
          )}
        </div>
        <div className="overflow-x-auto m-2">
          <table
            className={[
              "table w-full",
              screenWidth < 768 ? " table-compact" : "",
            ].join(" ")}>
            <thead>
              <tr>
                {cols[0].map((col: Column, index: number) => {
                  if (index >= 0) {
                    return (
                      <th key={"title-" + index}>
                        <Trans>{col.title}</Trans>
                      </th>
                    )
                  }
                  return <div />
                })}
              </tr>
            </thead>
            <tbody>
              {["", "", ""].map((el, index) => {
                return (
                  <tr className="hover:cursor-pointer hover:bg-gray-100">
                    {cols[0].map((data: Column, index: number) => {
                      if (index >= 0) {
                        return (
                          <th key={"loader-" + index}>
                            <DivContentLoader w={"90%"} h={45} r={8} />
                          </th>
                        )
                      }
                      return <div />
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
  const havePagination =
    typeof nextBtn !== "undefined" &&
    typeof prevBtn !== "undefined" &&
    typeof pageNum !== "undefined"

  return (
    <div
    // className="overflow-y-scroll"
    // style={{ maxHeight: " calc(100vh - 340px)" }}
    >
      <div
        className={classNames(
          "sticky lg:static top-0 z-10 bg-white px-1 lg:px-4 py-2 lg:py-4 rounded-b-xl md:rounded-xl shadow-md md:shadow-none",
          {
            hidden: !tableFn,
          }
        )}>
        {tableFn && (
          <div className="bg-white flex gap-2 lg:gap-3 items-center mx-2 overflow-x-scroll overflow-y-hidden">
            {tableFn.map((fn: tableFunctions, index: number) => {
              return (
                <TableToolBars
                  title={fn.title}
                  data={fn.data}
                  key={"tableTool-" + index}
                />
              )
            })}
          </div>
        )}
      </div>
      <div className="overflow-x-auto">
        <table
          className={[
            "table w-full",
            screenWidth < 768 ? " table-compact" : "",
          ].join(" ")}>
          <thead>
            <tr>
              {Array.isArray(defaultSchema) &&
                defaultSchema.map((col: Column, index: number) => {
                  if (index >= 0) {
                    return (
                      <th key={"title-" + index}>
                        <Trans>{col.title}</Trans>
                      </th>
                    )
                  }
                  return <div />
                })}
            </tr>
          </thead>
          <tbody>
            <tr className="">
              <td
                colSpan={
                  Array.isArray(defaultSchema) ? defaultSchema.length : 1
                }
                className=" p-4 text-center text-gray-500 font-bold">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {notFoundDes}
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {!rowIsLoading && havePagination && (
          <div className="w-full h-12">
            <div className="flex flex-row gap-2 justify-center w-64 text-center mx-auto mt-6">
              <button
                className={classNames(
                  "w-10 h-10 rounded-full bg-gray-100",
                  prevBtn.disable || pageNum === 0
                    ? "text-gray-300 cursor-not-allowed "
                    : "text-black"
                )}
                onClick={() => {
                  const isTrue =
                    typeof pageNum === "number" ? pageNum !== 0 : true
                  if (isTrue && prevBtn) {
                    prevBtn?.onClick()
                  }
                }}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>

              <div
                className={classNames(
                  { "w-10 ": typeof pageNum === "number" },
                  "h-10 px-2 rounded-full mx-auto bg-primary text-white flex items-center justify-center text-lg"
                )}>
                <p>{typeof pageNum === "number" ? pageNum + 1 : pageNum}</p>
              </div>

              <button
                className={classNames(
                  "w-10 h-10 rounded-full bg-gray-100",
                  nextBtn.disable
                    ? "text-gray-300 cursor-not-allowed "
                    : "text-black"
                )}
                onClick={() => {
                  if (!nextBtn.disable) {
                    nextBtn.onClick()
                  }
                }}>
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default CustomTable
