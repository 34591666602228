import { getAPIPath } from "../../../Utils/HelpingFunction"
import { returnIdTokenResult } from "../../../Utils/FirebaseUtils"

export async function getPriceObjectFromStripe(productID) {
  return new Promise(async (resolve, reject) => {
    await fetch(getAPIPath("/api/stripe/price/" + productID), {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((finalResponse) => {
        if (finalResponse.success) {
          console.log(finalResponse.data)
          resolve({
            success: true,
            data: finalResponse.data,
          })
        } else {
          console.log(finalResponse.message)
          resolve({
            success: false,
            message: finalResponse.message,
          })
        }
      })
  })
}

export async function purchasePlan(
  token,
  custID,
  jobID,
  servicePlanID,
  priceID,
  uid,
  email
) {
  return new Promise(async (resolve, reject) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/stripe/charge"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          token: token.id,
          custID: custID,
          jobID: jobID,
          priceID: priceID,
          email: email,
        }),
      })
        .then((res) => res.json())
        .then(async (response) => {
          console.log(response)
          return resolve(response)
        })
        .catch((err) => {
          console.log(err)
          return resolve({
            success: false,
            message: err,
          })
        })
    })
  })
}
