import { Timestamp } from "firebase/firestore"
import { ASSET_TYPE } from "../Enum/APP_TYPE"
import { DateObj } from "./Utilities"

export enum FORMAL_LEVEL {
  INFORMAL = "informal",
  NEUTRAL = "neutral",
  FORMAL = "formal",
}

export const formalLevel: { label: string; value: FORMAL_LEVEL }[] = [
  {
    label: "ChatGPTToolsBox.informal",
    value: FORMAL_LEVEL.INFORMAL,
  },
  {
    label: "ChatGPTToolsBox.neutral",
    value: FORMAL_LEVEL.NEUTRAL,
  },
  {
    label: "ChatGPTToolsBox.assertive",
    value: FORMAL_LEVEL.FORMAL,
  },
]

export enum FRIENDLY_LEVEL {
  FRIENDLY = "friendly",
  NEUTRAL = "neutral",
  ASSERTIVE = "assertive",
}

export const friendlyLevel: { label: string; value: FRIENDLY_LEVEL }[] = [
  {
    label: "ChatGPTToolsBox.friendly",
    value: FRIENDLY_LEVEL.FRIENDLY,
  },
  {
    label: "ChatGPTToolsBox.neutral",
    value: FRIENDLY_LEVEL.NEUTRAL,
  },
  {
    label: "ChatGPTToolsBox.assertive",
    value: FRIENDLY_LEVEL.ASSERTIVE,
  },
]

export enum WRITING_STYLE {
  NORMAL = "normal",
  ACADEMIC = "academic",
  ANALYTICAL = "analytical",
  ARGUMENTATIVE = "argumentative",
  CONVERSATIONAL = "conversational",
  CREATIVE = "creative",
  CRITICAL = "critical",
  DESCRIPTIVE = "descriptive",
  EPIGRAMMATIC = "epigrammatic",
  EPISTOLARY = "epistolary",
  EXPOSITORY = "expository",
  INFORMATIVE = "informative",
  INSTRUCTIVE = "instructive",
  JOURNALISTIC = "journalistic",
  METAPHORICAL = "metaphorical",
  NARRATIVE = "narrative",
  PERSUASIVE = "persuasive",
  POETIC = "poetic",
  SATIRICAL = "satirical",
  TECHNICAL = "technical",
}

export const writingStyle: { label: string; value: WRITING_STYLE }[] = [
  {
    label: "ChatGPTToolsBox.normal",
    value: WRITING_STYLE.NORMAL,
  },
  {
    label: "ChatGPTToolsBox.academic",
    value: WRITING_STYLE.ACADEMIC,
  },
  {
    label: "ChatGPTToolsBox.analytical",
    value: WRITING_STYLE.ANALYTICAL,
  },
  {
    label: "ChatGPTToolsBox.argumentative",
    value: WRITING_STYLE.ARGUMENTATIVE,
  },
  {
    label: "ChatGPTToolsBox.creative",
    value: WRITING_STYLE.CREATIVE,
  },
  {
    label: "ChatGPTToolsBox.conversational",
    value: WRITING_STYLE.CONVERSATIONAL,
  },
  {
    label: "ChatGPTToolsBox.creative",
    value: WRITING_STYLE.CRITICAL,
  },
  {
    label: "ChatGPTToolsBox.critical",
    value: WRITING_STYLE.CRITICAL,
  },
  {
    label: "ChatGPTToolsBox.descriptive",
    value: WRITING_STYLE.DESCRIPTIVE,
  },
  {
    label: "ChatGPTToolsBox.epigrammatic",
    value: WRITING_STYLE.EPIGRAMMATIC,
  },
  {
    label: "ChatGPTToolsBox.epistolary",
    value: WRITING_STYLE.EPISTOLARY,
  },
  {
    label: "ChatGPTToolsBox.expository",
    value: WRITING_STYLE.EXPOSITORY,
  },
  {
    label: "ChatGPTToolsBox.informative",
    value: WRITING_STYLE.INFORMATIVE,
  },
  {
    label: "ChatGPTToolsBox.instructive",
    value: WRITING_STYLE.INSTRUCTIVE,
  },
  {
    label: "ChatGPTToolsBox.journalistic",
    value: WRITING_STYLE.JOURNALISTIC,
  },
  {
    label: "ChatGPTToolsBox.metaphorical",
    value: WRITING_STYLE.METAPHORICAL,
  },
  {
    label: "ChatGPTToolsBox.narrative",
    value: WRITING_STYLE.NARRATIVE,
  },
  {
    label: "ChatGPTToolsBox.persuasive",
    value: WRITING_STYLE.PERSUASIVE,
  },
  {
    label: "ChatGPTToolsBox.poetic",
    value: WRITING_STYLE.POETIC,
  },
  {
    label: "ChatGPTToolsBox.satirical",
    value: WRITING_STYLE.SATIRICAL,
  },
  {
    label: "ChatGPTToolsBox.technical",
    value: WRITING_STYLE.TECHNICAL,
  },
]

export enum COMMEND_TYPE {
  TEXT = "/text",
  IMAGE = "/image",
  INSTAGRAM = "/instagram",
  RECOMMENDATION = "/recommendation",
}

export type CHATGPT_SETTING_TYPE = {
  commendType: COMMEND_TYPE
  // eslint-disable-next-line @typescript-eslint/ban-types
} & CHATGPT_SETTING_TYPE_COMBINE
// & (CHATGPT_SETTING_TYPE_TEXT | CHATGPT_SETTING_TYPE_IMAGE)

export type CHATGPT_SETTING_TYPE_COMBINE =
  | CHATGPT_SETTING_TYPE_TEXT
  | CHATGPT_SETTING_TYPE_IG

// CHATGPT_SETTING_TYPE_TEXT &
//   CHATGPT_SETTING_TYPE_IMAGE

export interface CHATGPT_SETTING_TYPE_TEXT {
  text: {
    formalLevel: FORMAL_LEVEL
    friendlyLevel: FRIENDLY_LEVEL
    tone: WRITING_STYLE
  }
}

export interface ASSISTANT_SETTING {
  pastHistory: { role: string; content: string }[]
}

export enum IG_POST_LENGTH {
  SHORT = "short",
  MEDIUM = "medium",
  LONG = "long",
}

export interface CHATGPT_SETTING_TYPE_IG {
  ig: {
    language: string
    location: string
    product_name: string
    product_desc: string
    product_brand?: string
    product_url?: string
    desc_length?: IG_POST_LENGTH
    keywords: string[]
    image_prompt?: string
  }
}

// export interface CHATGPT_SETTING_TYPE_IMAGE {}

export interface UserInputType {
  type: ASSET_TYPE.TEXT
  msg: string
  createUserID: string
  createDate: Date | DateObj
}

export interface ChatGPTQuestion {
  id?: string
  type: ASSET_TYPE.CHATGPT_TEXT_QUESTION
  setting: CHATGPT_SETTING_TYPE
  createDate: Date | DateObj
  msg: string
  createUserID: string
  pastHistory?: { role: string; content: string }[]
}

export interface ChatGPTReply {
  id?: string
  type: ASSET_TYPE.CHATGPT_TEXT_REPLY
  setting: CHATGPT_SETTING_TYPE
  createDate: Date | DateObj
  replyMsg: string
  msg: string
  createUserID: string
}

export const defaultUserInputType: UserInputType = {
  type: ASSET_TYPE.TEXT,
  msg: "",
  createUserID: "",
  createDate: new Date(),
}

interface ChatMessageText {
  id: string
  type: ASSET_TYPE.TEXT
  msg: string
  createDate: Date | DateObj
  createUserID: string
}

export interface ChatGPTMessage {
  id?: string
  type: ASSET_TYPE.CHATGPT_TEXT
  createDate: Date | DateObj
  msg: string
  createUserID: string
  //added
  tokenUsed: number
  followUpPrompts: { summary: string; question: string }[]
}

export interface ChatGPTIGPost {
  id?: string
  type: ASSET_TYPE.CHATGPT_IG
  tokenUsed: number
  thumbnailUrl: { url: string }
  ig_post_desc: string
  trending_hashtags: string[]
  createDate: Date | DateObj
  createUserID: string
}

export const ChatGPTMessageDefault: ChatGPTMessage = {
  type: ASSET_TYPE.CHATGPT_TEXT,
  tokenUsed: 0,
  followUpPrompts: [],
  createDate: new Date(),
  msg: "",
  createUserID: "chatgpt",
}

interface ChatMessageLinkText {
  id: string
  type: ASSET_TYPE.LINK_TEXT
  createDate: Timestamp
  msg: string
  path: string
  createUserID: string
}

interface ChatMessageImage {
  id: string
  type: ASSET_TYPE.IMAGE
  createDate: Timestamp
  thumbnailUrl: string
  url: string
  fileName: string
  fileSize: number
  createUserID: string
}

interface ChatMessageFile {
  id: string
  type: ASSET_TYPE.FILE
  createDate: Timestamp
  fileName: string
  fileSize: number
  createUserID: string

  url?: string
}

interface ChatMessageFileRef {
  id: string
  type: ASSET_TYPE.FILE_REF
  createDate: Timestamp
  path: string
  fileName: string
  fileSize: number
  createUserID: string
  url?: string
}

export const defaultChatGPTTextSettingType: CHATGPT_SETTING_TYPE = {
  commendType: COMMEND_TYPE.TEXT,
  text: {
    formalLevel: FORMAL_LEVEL.NEUTRAL,
    friendlyLevel: FRIENDLY_LEVEL.NEUTRAL,
    tone: WRITING_STYLE.NORMAL,
  },
}
export const defaultChatGPTIGSettingType: CHATGPT_SETTING_TYPE = {
  commendType: COMMEND_TYPE.INSTAGRAM,
  ig: {
    language: "",
    location: "",
    product_name: "",
    product_desc: "",
    product_brand: "",
    product_url: "",
    desc_length: IG_POST_LENGTH.SHORT,
    keywords: [],
    image_prompt: "",
  },
}

export type ChatMessage =
  | ChatMessageText
  | ChatMessageImage
  | ChatMessageFile
  | ChatMessageLinkText
  | ChatMessageFileRef
  | ChatGPTMessage
  | ChatGPTQuestion
  | ChatGPTIGPost
  | ChatGPTReply
