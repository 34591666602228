import React from "react"
import { Link, useLocation } from "react-router-dom"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { getUserLang } from "../../Utils/utiltyHelper"
import { queryChecker } from "../../Utils/QueryHelper"

interface TabLinkButtonProps {
  path: string
  isAfter: boolean
  i18Text: string
  color?: string
  icon?: IconDefinition
  iconAfter?: IconDefinition
  onClickFn?: () => void
}

export const TabLinkButton: React.FC<TabLinkButtonProps> = ({
  path,
  isAfter,
  i18Text,
  color,
  icon,
  iconAfter,
  onClickFn,
}) => {
  return (
    <Link
      className={classNames("tab rounded-2xl flex-1 h-10 font-bold", {
        "tab-active text-white bg-red-default ": isAfter,
        "text-gray-400 ": !isAfter,
      })}
      to={"/" + getUserLang() + "/" + path}
      onClick={(e) => {
        if (onClickFn) {
          onClickFn()
        }
      }}>
      <div className=" gap-2">
        {icon && (
          <FontAwesomeIcon
            icon={iconAfter ? (isAfter ? iconAfter : icon) : icon}
            className={classNames("text-lg mr-2")}
          />
        )}
        {i18Text}
      </div>
    </Link>
  )
}
