import React, { useState } from "react"
import { Trans } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"

import { returnIdTokenResult } from "../../../Utils/FirebaseUtils"
import { createChatroom } from "../../../HelpingFunction/Chatroom/chatroomDBHelper"
import { PageType } from "../../../Model/ProjectManagement"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import vectorImg from "../../../Assets/chatNotExist.png"

const ChatNotExist = () => {
  const dispatch = useDispatch()
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const kol = useSelector((state: any) => {
    return state.ChatRoomInfo.kol
  })

  const setPage = (page: PageType, pagePropsID?: string) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_PROJECT_PAGE,
      data: page,
      pagePropsID: pagePropsID,
    })
  }

  const [isCreatingChatroom, setIsCreatingChatroom] = useState<boolean>(false)
  const [failCreate, setFailCreate] = useState<boolean>(false)
  const getCustomerUserName = (): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      const getState = await returnIdTokenResult()
      if (getState.success) {
        return resolve(
          typeof getState.token.userName !== "undefined"
            ? getState.token.userName
            : ""
        )
      }
      return resolve("")
    })
  }

  const submitRequest = async () => {
    setIsCreatingChatroom(true)

    const customerName = await getCustomerUserName()
    createChatroom(auth.uid, kol.id, kol.userName, customerName).then(
      (result) => {
        if (result.success) {
          setIsCreatingChatroom(false)

          setPage(PageType.ChatPreview, result.chatroomID)
        } else {
          console.log("failed to create chatroom")
          setIsCreatingChatroom(false)
          setFailCreate(true)
        }
      }
    )
  }

  return (
    <div>
      <section>
        <img src={vectorImg} alt={"create a new chatroom"} />
      </section>
      <section>
        <div className="text-center">
          <h2 className="font-semibold text-xl mt-8">
            <Trans>ChatNotExist.title</Trans>
          </h2>
          <p className="text-gray-500 font-medium my-4">
            <Trans>ChatNotExist.description</Trans>
          </p>
          <button
            onClick={() => submitRequest()}
            className={classNames("btn btn-primary rounded-full w-32 my-4", {
              loading: isCreatingChatroom,
            })}>
            <Trans>ChatNotExist.start-chat</Trans>
          </button>
        </div>
      </section>
      <section>
        {failCreate && (
          <AnimatePresence>
            <motion.div
              initial={{ x: -300 }}
              animate={{ x: 0 }}
              transition={{ ease: "easeOut", duration: 0.5 }}
              className="p-2 shadow-lg rounded-lg my-6">
              <p className="text-gray-500 font-medium">
                <span>
                  <FontAwesomeIcon
                    className="text-red-500 mx-2"
                    icon={faCircleExclamation}
                  />
                </span>
                <Trans>
                  <Trans>ChatNotExist.fail</Trans>
                </Trans>
              </p>
            </motion.div>
          </AnimatePresence>
        )}
      </section>
    </div>
  )
}

export default ChatNotExist
