import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFileContract,
  faCreditCard as faCreditCardAfter,
  faHeart as faHeartAfter,
  faChevronLeft,
  faIdBadge as faIdBadgeAfter,
} from "@fortawesome/free-solid-svg-icons"
import {
  faHeart,
  faCreditCard,
  faIdBadge,
} from "@fortawesome/free-regular-svg-icons"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { useHistory } from "react-router-dom"
import { BadgeLinkButton } from "../BadgeLinkButton"
import { Trans, useTranslation } from "react-i18next"
import { getUserLang } from "../../Utils/utiltyHelper"

export const CustomerSettingHeader: React.FC = () => {
  const { t } = useTranslation()

  let history = useHistory()

  const KOLSettingLink: {
    icon: IconDefinition
    iconAfter: IconDefinition
    i18Text: string
    path: string
  }[] = [
    {
      icon: faIdBadge,
      iconAfter: faIdBadgeAfter,
      i18Text: t("CustomerSetting.title"),
      path: "customer/profile",
    },
    {
      icon: faCreditCard,
      iconAfter: faCreditCardAfter,
      i18Text: t("CustomerSetting.transaction-history"),
      path: "customer/transaction",
    },
    {
      icon: faFileContract,
      iconAfter: faFileContract,
      i18Text: t("CustomerSetting.job-history"),
      path: "customer/job",
    },
    {
      icon: faHeart,
      iconAfter: faHeartAfter,
      i18Text: t("CustomerSetting.saved-item"),
      path: "customer/saved",
    },
  ]

  return (
    <div className="shadow-md rounded-b-md">
      {/* KOL Header*/}
      <div
        className="h-18 my-auto w-full py-2 px-4 grid gap-4"
        style={{
          height: "60px",
          gridTemplateColumns: "40px  auto ",
        }}>
        <div>
          <button
            className="my-auto h-full text-left my-2 mx-2 text-gray-600 font-medium text-xl"
            onClick={() => {
              history.push("/" + getUserLang())
            }}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        </div>
        <div className="text-lg my-auto font-bold ">
          <Trans>CustomerSetting.title</Trans>
        </div>
      </div>

      {/* KOL Function*/}
      <div className="pt-1 pb-2 px-2 overflow-x-scroll whitespace-nowrap">
        {Array.isArray(KOLSettingLink) &&
          KOLSettingLink.map((item, key) => {
            return (
              <BadgeLinkButton
                key={"item" + key}
                path={item.path}
                i18Text={item.i18Text}
                icon={item.icon}
              />
            )
          })}
      </div>
    </div>
  )
}
