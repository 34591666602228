import React from "react"
import CustomerRegForm from "../../page-components/login/CustomerRegForm"

const RegisterCustomerModalComponent = (props: any) => {
  return (
    <div>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <CustomerRegForm closeModal={props.closeModal} />
    </div>
  )
}

export default RegisterCustomerModalComponent
