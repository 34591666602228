import React from "react"
import { timeStampToDateString } from "../../../Utils/Converter"
import { Trans } from "react-i18next"
import MessageManager from "./MessageManager"
import { ChatMessage } from "../../../Model/ChatMessage"
import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"
import { ASSET_TYPE } from "../../../Enum/APP_TYPE"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import chatgptIcon from "../../../Assets/openai.svg"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import l4kIcon from "../../../Assets/icons-72.png"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import KOL from "../../../Model/KOL"
import { getAuthUID } from "../../../Utils/QueryHelper"

export enum BUBBLE_TYPE {
  OWNER,
  OPPOSITE,
  SYSTEM,
  CLEAR,
}

interface PropsType {
  index: number
  shouldHrDate: boolean
  shouldSystemMessage: boolean
  type: BUBBLE_TYPE
  customerName: string
  item: ChatMessage
  onSelectMessage: (item: ChatMessage) => void
  kol: KOL
  chatroomID?: string
}

const ChatBubble: React.FC<PropsType> = ({
  index,
  shouldHrDate,
  shouldSystemMessage,
  type,
  customerName,
  item,
  onSelectMessage,
  kol,
  chatroomID,
}) => {
  let messageBoxColor = ""
  const dispatch = useDispatch()

  const chatroomInfo = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomInfo
  })

  const selectedMessage = useSelector((state: any) => {
    return state.ChatRoomInfo.selectedMessage
  })

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const params = useSelector((state: any) => {
    return state.QueryManager.params
  })
  const setChatGptPrompt = (data: string) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATGPT_PROMPT,
      data: data,
    })
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const createDate = new Date(item.createDate.seconds * 1000)

  // that mean the user is already read the message
  const hasReadDate = new Date(
    parseInt(localStorage.getItem("look4kol-cr-" + chatroomInfo.id) as string)
  )

  const readMsg = hasReadDate ? hasReadDate > createDate : false

  const isSelectedMessage = selectedMessage.id === item.id

  const ownerMessage =
    item.createUserID === getAuthUID(params) ? "bg-red-100" : "bg-gray-100"

  const chatGPTType =
    item.createUserID === "chatgpt" &&
    item.type !== ASSET_TYPE.KOLA_KOLS_RECOMMENDATION

  messageBoxColor = isSelectedMessage
    ? "bg-red-200 ease-in-out duration-200 transform scale-90"
    : ownerMessage

  if (type === BUBBLE_TYPE.OWNER || type === BUBBLE_TYPE.OPPOSITE) {
    return (
      <React.Fragment>
        {/* Hr line*/}
        {shouldHrDate && (
          <div
            id={"hr1" + index}
            className="col-span-12 text-center my-1 text-sm text-gray-400 my-2">
            {timeStampToDateString(createDate.getTime() / 1000)}
            <hr className="m-0" />
          </div>
        )}

        {/* Bubble*/}
        <div className="grid grid-cols-12">
          <div
            className={classNames("my-1 rounded-lg", {
              "col-start-4 md:col-start-2 lg:col-start-4 col-end-13":
                type === BUBBLE_TYPE.OWNER,
              "col-start-1 col-end-10 lg:col-end-15":
                type === BUBBLE_TYPE.OPPOSITE,
            })}
            key={item.id}>
            <div
              className={classNames("flex items-center ", {
                "justify-start flex-row-reverse": type === BUBBLE_TYPE.OWNER,
              })}>
              {item.createUserID === "chatgpt" && (
                <div
                  className={classNames(
                    "text-center hidden md:inline-block flex items-center justify-center h-10 w-10 rounded-full flex-shrink-0 uppercase",
                    {
                      "bg-red-default text-white py-2": !chatGPTType,
                    }
                  )}>
                  {/* Chat Name*/}
                  {/* eslint-disable */}
                  {item.createUserID === "chatgpt" && (
                    <div>
                      <img
                        src={chatGPTType ? chatgptIcon : l4kIcon}
                        alt={"chatgpt"}
                      />
                    </div>
                  )}
                  {/* eslint-enable */}
                </div>
              )}

              {/* Chat bubble main*/}
              <div
                className={classNames(
                  "overflow-hidden relative text-sm px-2 pt-2 pb-0.5 shadow rounded-xl ",
                  messageBoxColor,
                  {
                    "md:mr-3": type === BUBBLE_TYPE.OWNER,
                    "md:ml-3": type === BUBBLE_TYPE.OPPOSITE,
                  }
                )}>
                {/*Reply box*/}
                {item.type === ASSET_TYPE.CHATGPT_TEXT_REPLY && (
                  <div className="m-2 rounded-lg bg-gray-50 p-1 px-2 max-h-12 truncate ">
                    {item.replyMsg}
                  </div>
                )}
                <div>
                  <MessageManager
                    auth={auth}
                    customerName={customerName}
                    kol={kol}
                    item={item}
                    readMsg={readMsg}
                    chatroomID={chatroomID}
                  />
                  {/*Prompts recommendation*/}
                  {item.type === ASSET_TYPE.CHATGPT_TEXT && (
                    <div className="overflow-x-scroll ">
                      {Array.isArray(item.followUpPrompts) && (
                        <hr className="border-gray-200 my-2" />
                      )}
                      {Array.isArray(item.followUpPrompts) &&
                        item.followUpPrompts.map((p, index) => {
                          return (
                            <div
                              className="btn btn-sm btn-ghost font-normal lowercase bg-white hover:bg-gray-200 mx-1 my-1 "
                              key={"p" + index}
                              onClick={() => {
                                setChatGptPrompt(p.question)
                              }}>
                              {p.summary}
                            </div>
                          )
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  } else if (type === BUBBLE_TYPE.SYSTEM) {
    return (
      <React.Fragment>
        {shouldHrDate && (
          <div
            id={"hr2" + index}
            className="col-span-12 text-center my-1 text-sm text-gray-400 my-2">
            {timeStampToDateString(createDate.getTime() / 1000)}
            <hr className="m-0" />
          </div>
        )}

        {/*show system message */}
        {shouldSystemMessage && (
          <div
            id={"hr1" + index}
            className="col-span-12 text-center  text-sm text-gray-400 my-2">
            <Trans>ChatText.system-message</Trans>
            <hr className="m-0" />
          </div>
        )}

        <div className="col-span-full  rounded-lg">
          <div
            className={classNames(
              "overflow-hidden relative text-sm rounded-xl  "
            )}>
            <MessageManager
              item={item}
              readMsg={readMsg}
              chatroomID={chatroomID}
            />
          </div>
        </div>
      </React.Fragment>
    )
  } else if (type === BUBBLE_TYPE.CLEAR) {
    return (
      <div className="col-span-12 text-center  text-sm text-gray-400 my-2">
        User has cleared chat
      </div>
    )
  }
  return <div></div>
}
export default ChatBubble
