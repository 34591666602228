import React, { useEffect, useState } from "react"
import { KOLPreviewModalData } from "../../Model/Modal"
import KOL, { KOLType } from "../../Model/KOL"
import { getKOL } from "../../HelpingFunction/KOL/kolDBHelper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGlobeAsia,
  faHeart,
  faLayerGroup,
  faStar,
} from "@fortawesome/free-solid-svg-icons"
import { Trans } from "react-i18next"
import { followersCount } from "../../Utils/Converter"
import { getFollowersData } from "../../HelpingFunction/FollowersData/FollowerDBHelper"
import { IG_USER_DATA } from "../../Enum/LOGIN_PAGE_TYPE"
import { getCountry } from "../../Utils/Countries"
import KOLFollowerGroup from "../../page-components/p/KOLFollowerGroup"
import KOLPlanList from "../KOLPlanList"
import KOLFollowerInterest from "../../page-components/p/KOLFollowerInterest"
import { COMPONENT_DISPLAY } from "../../Enum/APP_TYPE"
import { isCDNImage, notErrorImage } from "../../Utils/HelpingFunction"
import { returnThumbnailUrl } from "../../Utils/utiltyHelper"

const KOLPreviewModalComponent: React.FC<KOLPreviewModalData> = ({
  metaData: { kolID },
}) => {
  const [kol, setKOL] = useState<KOL>()
  const [igKOLData, setIGUserData] = useState<IG_USER_DATA>()

  useEffect(() => {
    if (kolID) {
      getFullKOLProfile()
    }
  }, [kolID])

  const getFullKOLProfile = async () => {
    const state1 = await getKOL(kolID)

    if (state1.success) {
      setKOL(state1.data)
      const state2 = await getFollowersData(state1.data.id, "ig")
      if (state2.success) {
        setIGUserData(state2.igData as IG_USER_DATA)
      }
    }
  }

  if (kol && igKOLData) {
    return (
      <div className="container mx-auto  gap-6 overflow-y-auto">
        <section className="relative md:grid md:grid-cols-3 md:gap-2 w-full rounded-xl mb-4 ">
          <div className="md:col-span-1">
            <div className="h-full w-full mx-auto md:bg-gray-50 md:border md:border-gray-100 md:rounded-xl py-4 px-2">
              <div className="relative">
                <div className="w-24 h-24 xl:w-32 xl:h-32 rounded-full mx-auto text-center">
                  <img
                    className="object-cover border border-gray-50 w-24 h-24 xl:w-32 xl:h-32 rounded-full"
                    alt={kol.userName + "avatar image"}
                    src={
                      kol.avatarUrl
                        ? kol.avatarUrl
                        : "https://look4kol.com/logo.png"
                    }
                  />
                </div>
              </div>

              {/*  KOL username and score*/}
              <div className="text-center mx-auto">
                <h1 className="inline-block font-semibold text-lg p-0 my-4">
                  {kol.userName}
                </h1>
                <span
                  className="inline-block cursor-pointer"
                  onClick={() =>
                    window.open(`https://instagram.com/${kol.userName}`)
                  }>
                  <svg
                    className="svg-inline--fa fa-instagram  w-5 h-5 ml-1"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="instagram"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    data-fa-i2svg="">
                    <path
                      fill="currentColor"
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                  </svg>
                </span>
              </div>

              {/*  KOL country*/}
              <div className="py-2 px-4">
                <ul className="grid grid-rows-3 gap-1 text-gray-500 font-light my-4">
                  {/* kol score*/}
                  <li
                    className="grid gap-4 px-1 py-2"
                    style={{ gridTemplateColumns: "20px auto" }}>
                    <div>
                      <FontAwesomeIcon
                        icon={faStar}
                        className="text-center text-xl mr-4"
                      />
                    </div>
                    <div> {kol.score} / 5</div>
                  </li>

                  {/* kol cate*/}
                  <li
                    className="grid gap-4 px-1 py-2"
                    style={{ gridTemplateColumns: "20px auto" }}>
                    <div>
                      <FontAwesomeIcon
                        icon={faHeart}
                        className="text-center text-xl mr-4"
                      />
                    </div>
                    <div> {igKOLData.engagementRate} %</div>
                  </li>

                  {/* kol country*/}
                  <li
                    className="grid gap-4 px-1 py-2"
                    style={{ gridTemplateColumns: "20px auto" }}>
                    <div>
                      <FontAwesomeIcon
                        icon={faGlobeAsia}
                        className="text-center text-xl mr-4"
                      />
                    </div>
                    <div> {getCountry(kol.nationality).label}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="md:col-span-2">
            {/*  KOL follower count and num of post */}
            <div className=" bg-gray-50 border border-gray-100 rounded-xl py-4 px-2 grid grid-cols-3 col-span-3">
              <div className={"text-center my-auto"}>
                <p className={"text-lg font-semibold"}>
                  {igKOLData.numOfPosts ? igKOLData.numOfPosts : 0}
                </p>
                <span className="text-sm text-gray-500 font-normal">
                  <Trans>SignUp.posts</Trans>
                </span>
              </div>
              <div className={"text-center  my-auto"}>
                <p className={"text-lg font-semibold"}>
                  {igKOLData.numOfFollowers
                    ? followersCount(igKOLData.numOfFollowers)
                    : 0}
                </p>
                <span className="text-sm text-gray-500 font-normal">
                  <Trans>SignUp.followers</Trans>
                </span>
              </div>
              <div className={"text-center  my-auto"}>
                <p className={"text-lg font-semibold"}>
                  {igKOLData.numOfFollowing ? igKOLData.numOfFollowing : 0}
                </p>
                <span className="text-sm text-gray-500 font-normal">
                  <Trans>SignUp.following</Trans>
                </span>
              </div>
            </div>

            {/* KOL images*/}
            {Array.isArray(igKOLData.imagesList) && (
              <div className="grid gap-2 grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 my-2">
                {igKOLData.imagesList.map((imageSrc, index) => {
                  if (
                    index < 6 &&
                    !isCDNImage(imageSrc) &&
                    notErrorImage(imageSrc)
                  ) {
                    return (
                      <div className="cursor-pointer" key={"img" + index}>
                        <img
                          src={returnThumbnailUrl(imageSrc)}
                          className="w-full h-40 md:h-32 lg:h-32 xl:h-36 object-cover rounded-md"
                          alt={kol.userName + " cover image " + (index + 1)}
                        />
                      </div>
                    )
                  }
                })}
              </div>
            )}
          </div>
        </section>

        {/*<section className="w-full h-auto my-8">*/}
        {/*  <h2 className="inline-block text-md font-bold text-gray-800 ">*/}
        {/*    <Trans>KOLProfile.case-study</Trans>*/}
        {/*  </h2>*/}
        {/*  <CaseStudiesList KOLID={kolID} />*/}
        {/*</section>*/}

        <section className="w-full h-auto my-8">
          <h2 className="inline-block text-md font-bold text-gray-800 ">
            <Trans>ChatKOLInfo.follower-group</Trans>
          </h2>
          <KOLFollowerGroup
            KOLID={kolID}
            displayMethod={COMPONENT_DISPLAY.LIST}
          />
        </section>

        {/* KOL description*/}
        <section className="w-full h-auto my-8">
          <div>
            <h2 className="inline-block text-md font-bold text-gray-800 ">
              <Trans>KOLProfile.kol-description</Trans>
            </h2>
          </div>
          <p className="mt-4 font-light text-gray-500 max-h-40 h-auto overflow-x-hidden overflow-y-auto break-words	">
            {kol.description}
          </p>
        </section>

        <section className="w-full h-auto my-8">
          <h2 className="inline-block text-md font-bold text-gray-800 ">
            <Trans>KOLProfile.service-plan</Trans>
          </h2>
          <KOLPlanList
            KOLID={kolID}
            isKOL={false}
            displayMethod={COMPONENT_DISPLAY.LIST}
          />
        </section>

        <section className="w-full h-auto my-8">
          <h2 className="inline-block text-md font-bold text-gray-800 ">
            <Trans>ChatKOLInfo.follower-percentage</Trans>
          </h2>
          <KOLFollowerInterest userName={kol.userName} />
        </section>
      </div>
    )
  }

  return (
    <div>
      <p>Loading...</p>
    </div>
  )
}

export default KOLPreviewModalComponent
