import {
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore"
import firebaseApp from "../../Config/firebase"
import {
  Project,
  ProjectGroupProgress,
  ProjectListCondition,
} from "../../Model/ProjectManagement"
import { v4 as uuidv4 } from "uuid"
import { getAPIPath } from "../../Utils/HelpingFunction"
import { returnIdTokenResult } from "../../Utils/FirebaseUtils"
import { Job } from "../../Model/Job"
import KOL, { KOLType } from "../../Model/KOL"
import { getKOL } from "../KOL/kolDBHelper"
import { createBulkContractsAPI } from "../Job/JobDBHelper"
import { createBulkChatroomAPI } from "../Chatroom/chatroomDBHelper"

const db = getFirestore(firebaseApp)

const getProjectList = (
  condition: ProjectListCondition,
  uid: string,
  setLimit: number
): Promise<
  | {
      success: true
      data: Project[]
      hasMore: boolean
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "Project")

    if (uid !== "" && uid) {
      dbRef = query(dbRef, where("customerID", "==", uid))
    }
    if (condition.isFinish !== "") {
      dbRef = query(dbRef, where("isFinish", "==", condition.isFinish))
    }
    if (condition.budget !== "") {
      dbRef = query(dbRef, where("budget", "<=", condition.budget))
    }
    if (condition.startDate !== "") {
      dbRef = query(dbRef, where("startDate", ">=", condition.startDate))
    }
    if (condition.endDate !== "") {
      dbRef = query(dbRef, where("endDate", ">", condition.endDate))
    }

    await getDocs(
      query(dbRef, orderBy("createDate", "desc"), limit(setLimit))
    ).then((docs: any) => {
      if (!docs.empty) {
        let allProject: Project[] = []
        docs.forEach((doc: any) => {
          allProject.push({ id: doc.id, ...doc.data() })
        })
        return resolve({
          success: true,
          data: allProject,
          hasMore: allProject.length === setLimit,
        })
      }
      return resolve({
        success: false,
      })
    })
  })
}

const getProject = (
  projectID: string
): Promise<
  | {
      success: false
    }
  | {
      success: true
      data: Project
    }
> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "Project", projectID))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: {
              id: doc.id,
              ...doc.data(),
            } as Project,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => {
        return resolve({
          success: false,
        })
      })
  })
}

const createProject = (
  data: Project
): Promise<
  | {
      success: true
      projectID: string
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    const projectID = uuidv4()
    await setDoc(doc(db, "Project", projectID), data)
      .then(async (res) => {
        return resolve({
          success: true,
          projectID: projectID,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

export enum projectDBType {
  "CREATE",
  "UPDATE",
}

export const callAPIProjectJob = (
  id: string,
  jobs: string[],
  type: projectDBType
): Promise<{
  success: boolean
}> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/project/" + id + "/job"), {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          jobList: jobs,
          dbType: type,
        }),
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
            })
          }
          return resolve({
            success: false,
          })
        })
    })
  })
}

const updateProject = (
  project: Project
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    let projectID = project.id as string
    await updateDoc(doc(db, "Project", projectID), {
      title: project.title,
      description: project.description,
      budget: project.budget,
      kolList: project.kolList,
      jobList: project.jobList,
    })
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const deleteProjectDB = (projectID: string): Promise<{ success: boolean }> => {
  return new Promise(async (resolve) => {
    await deleteDoc(doc(db, "Project", projectID))
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const getUpdatedKOLAndJob = (
  jobs: Job[],
  selectedKOLID: string[],
  selectedJobID: string[],
  kolID: string
): {
  newSelectedKOLID: string[]
  newSelectedJobID: string[]
} => {
  let newSelectedKOLID: string[] = [...selectedKOLID]
  let newSelectedJobID: string[] = [...selectedJobID]

  if (newSelectedKOLID.includes(kolID)) {
    //if already include, then remove kol from the list
    newSelectedKOLID = newSelectedKOLID.filter((e) => e !== kolID)

    //then remove all job from the list
    // 1. keep the current job where the j.KOLID is not by this kol
    if (Array.isArray(jobs)) {
      newSelectedJobID = jobs
        .filter(
          (j) =>
            //keep the current job
            newSelectedJobID.includes(j.id) &&
            // where the j.KOLID is not by this kol
            j.KOLID !== kolID
        )
        .map((e) => e.id)
    }
    return {
      newSelectedKOLID: newSelectedKOLID,
      newSelectedJobID: newSelectedJobID,
    }
  }
  // else, add kol into list
  newSelectedKOLID.push(kolID)

  //then add all job related to this kol from the list
  if (Array.isArray(jobs)) {
    jobs
      .filter((j) => j.KOLID === kolID)
      .map((e) => e.id)
      .forEach((jobID) => {
        newSelectedJobID.push(jobID)
      })
  }
  return {
    newSelectedKOLID: newSelectedKOLID,
    newSelectedJobID: newSelectedJobID,
  }
}

const countTotalCost = (jobs: Job[]): number => {
  if (jobs.length) {
    return jobs.map((e) => e.price).reduce((acc, amount) => acc + amount)
  }
  return 0
}
/*Create Project Using Backend API*/

const createProjectAPI = (
  project: Project
): Promise<
  | {
      success: true
      projectID: string
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/project/create"), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          project: {
            ...project,
            createDate: new Date(),
            startDate: new Date(),
            endDate: new Date(),
          },
        }),
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
              projectID: finalResponse.projectID,
            })
          }
          return resolve({
            success: false,
          })
        })
    })
  })
}

const totalProjectCount = (
  uid: string
): Promise<
  { success: true; ongoing: number; finished: number } | { success: false }
> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "Project")

    if (uid !== "" && uid) {
      dbRef = query(dbRef, where("customerID", "==", uid))

      let ongoingRef = query(dbRef, where("isFinish", "==", false))
      let finishedRef = query(dbRef, where("isFinish", "==", true))

      const ongoingSnap = await getCountFromServer(ongoingRef)
      const finishedSnap = await getCountFromServer(finishedRef)

      if (ongoingSnap.data() && finishedSnap.data()) {
        return resolve({
          success: true,
          ongoing: ongoingSnap.data().count,
          finished: finishedSnap.data().count,
        })
      }
      return resolve({
        success: false,
      })
    }
    return resolve({
      success: false,
    })
  })
}

const getChatroomKolList = (
  uid: string
): Promise<{ success: true; kols: KOLType[] } | { success: false }> => {
  return new Promise((resolve) => {
    const kols: KOLType[] = []
    let dbRef = query(
      collection(db, "ChatRoom"),
      where("participateUID", "array-contains", uid)
    )

    getDocs(query(dbRef, limit(20), orderBy("lastUpdate", "desc")))
      .then(async (docs) => {
        if (!docs.empty) {
          for (const doc of docs.docs) {
            await getKOL(doc.data().participateUID[1]).then((res) => {
              if (res.success) {
                kols.push(res.data)
              }
            })
          }
        }
        return resolve({
          success: true,
          kols: kols,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const delayTime = (delayInms: number) => {
  return new Promise((resolve) => setTimeout(resolve, delayInms))
}

const createGroupQuotation = (
  projectID: string,
  selectedKOLs: string[],
  uid: string,
  contractDetails: { title: string; description: string },
  setProgress: (state: ProjectGroupProgress) => void,
  setProject: (state: Project) => void
): Promise<{ success: true; jobIDs: string[] } | { success: false }> => {
  return new Promise(async (resolve) => {
    let jobIDs: string[] = []

    setProgress(ProjectGroupProgress.CREATE_CHATROOM)
    await delayTime(2000)
    const result1 = await createBulkChatroomAPI(uid, selectedKOLs, "", "")
    if (!result1.success) return resolve({ success: false })

    setProgress(ProjectGroupProgress.CREATE_JOB)
    await delayTime(2000)
    const result2 = await createBulkContractsAPI(selectedKOLs, uid, {
      ServicePlanID: "CUSTOM",
      ServicePlanName: contractDetails.title,
      quotationDetail: contractDetails.description,
    })
    if (result2.success) {
      jobIDs = result2.jobIDList
    } else {
      return resolve({ success: false })
    }

    setProgress(ProjectGroupProgress.UPDATE_PROJECT)
    await delayTime(2000)
    const currentProject = await getProject(projectID)
    if (!currentProject.success) return resolve({ success: false })

    let updatedProject = {
      ...currentProject.data,
      jobList: currentProject.data.jobList.concat(jobIDs),
    }
    await delayTime(2000)
    const result5 = await updateProject(updatedProject)
    if (!result5.success) return resolve({ success: false })

    setProject(updatedProject)
    await delayTime(2000)
    setProgress(ProjectGroupProgress.FINISH)

    return resolve({
      success: true,
      jobIDs: jobIDs,
    })
  })
}

export {
  getUpdatedKOLAndJob,
  getProjectList,
  createProject,
  updateProject,
  getProject,
  countTotalCost,
  createProjectAPI,
  totalProjectCount,
  getChatroomKolList,
  deleteProjectDB,
  createGroupQuotation,
}
