import React, { useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { SUPPORTED_REDUX_FUNCTIONS } from "../Redux/SUPPORTED_REDUX_FUNCTION"
import { useDispatch } from "react-redux"
import classNames from "classnames"
import { TIP_DIRECTION } from "../Enum/HELP_BTN_SYSYEM"
import { helpDoc } from "../Utils/HelpButtonHelperFunction"
import { t } from "i18next"

interface PropsType {
  helpDoc: helpDoc
  tipDirection?: TIP_DIRECTION
  disabled?: boolean
}

const CustomHelpButton: React.FC<PropsType> = ({
  helpDoc,
  tipDirection,
  disabled,
}) => {
  const dispatch = useDispatch()

  const onHelpDocDirect = (path: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.DIRECT_TO_HELP_DOC,
      data: path,
    })
  }

  const helpBtnLayout = () => {
    return (
      <button
        disabled={disabled}
        className="hidden md:inline-flex btn btn-sm btn-circle btn-ghost"
        onClick={() => {
          onHelpDocDirect(helpDoc.url)
        }}>
        <FontAwesomeIcon icon={faQuestionCircle} />
      </button>
    )
  }

  const toopTipDirectionFn = (tipDirection: TIP_DIRECTION) => {
    switch (tipDirection) {
      case TIP_DIRECTION.BOTTOM:
        return "tooltip-bottom"
      case TIP_DIRECTION.TOP:
        return "tooltip-top"
      case TIP_DIRECTION.RIGHT:
        return "tooltip-right"
      case TIP_DIRECTION.LEFT:
        return "tooltip-left"
      default:
        return "tooltip-top"
    }
  }
  if (helpDoc.label) {
    return (
      <div
        className={classNames(
          "sticky z-0 tooltip hidden md:inline-flex text-gray-400 font-thin",
          tipDirection ? toopTipDirectionFn(tipDirection) : ""
        )}
        data-tip={helpDoc.label ? t(helpDoc.label) : "Click me for more tips!"}>
        {helpBtnLayout()}
      </div>
    )
  }
  return helpBtnLayout()
}
export default CustomHelpButton
