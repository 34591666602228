import { useSelector } from "react-redux"
import { ProjectGroupQuotePage } from "../../../Model/ProjectManagement"
import GroupQuoteContract from "./GroupQuoteContract"
import GroupQuoteProgress from "./GroupQuoteProgress"
import { Trans } from "react-i18next"

const GroupQuoteProject = () => {
  const pageType: ProjectGroupQuotePage = useSelector((state: any) => {
    return state.ProjectManager.groupQuotationPage
  })

  return (
    <div className="w-full relative">
      <section className="sticky h-16 top-0 px-1 w-full bg-white z-10 border-b">
        <p className="text-gray-500 font-medium text-sm my-2">
          <Trans>GroupQuoteProject.desc</Trans>
        </p>
      </section>

      {pageType === ProjectGroupQuotePage.CONTRACT && <GroupQuoteContract />}
      {pageType === ProjectGroupQuotePage.PROGRESS && <GroupQuoteProgress />}
    </div>
  )
}

export default GroupQuoteProject
