import { Reducer } from "react"
import { KOLType } from "../../Model/KOL"
import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"

interface actionTypes {
  type: string
  data: string[] | KOLType[] | any
}

interface ManagementInitStateType {
  isLoaded: boolean
  kolList: KOLType[]
}

const initialState: ManagementInitStateType = {
  isLoaded: false,
  kolList: [],
}

const setManagementReducer: Reducer<ManagementInitStateType, actionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SUPPORTED_REDUX_FUNCTIONS.SET_OWNED_KOL:
      return {
        ...state,
        kolList: action.data,
        isLoaded: true,
      }

    default:
      return state
  }
}

export default setManagementReducer
