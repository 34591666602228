import {
  faArrowLeft,
  faCircleUser,
  faGlobeAsia,
  faStar,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useDispatch, useSelector } from "react-redux"
import KOL from "../../../Model/KOL"
import { PageType, Project } from "../../../Model/ProjectManagement"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { getCountry } from "../../../Utils/Countries"
import { Trans } from "react-i18next"

const SelectedKOLList = () => {
  const dispatch = useDispatch()
  const kolList: KOL[] = useSelector((state: any) => {
    return state.ProjectManager.kolList
  })
  const project: Project = useSelector((state: any) => {
    return state.ProjectManager.currentProject
  })
  const setPage = (page: PageType) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_PROJECT_PAGE,
      data: page,
    })
  }
  const setProject = (project: Project) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CURRENT_PROJECT,
      data: project,
    })
  }
  const setKOLList = (kols: KOL[]) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_KOL_LIST,
      data: kols,
    })
  }

  const deleteKOL = (kolID: string) => {
    let newKOLList = kolList.filter((kol) => {
      return kol.id !== kolID
    })
    let newKOLs = project.kolList.filter((kol) => {
      return kol !== kolID
    })

    project.kolList = newKOLs
    setKOLList(newKOLList)
    setProject(project)
  }
  return (
    <div>
      <p className="font-medium my-2">
        <span
          onClick={() => setPage(PageType.Main)}
          className="text-lg cursor-pointer mr-2">
          <FontAwesomeIcon icon={faArrowLeft} />
        </span>
        <Trans>ProjectOptionBar.selected-kol-list</Trans>
      </p>
      <div className="mt-4">
        {kolList.map((kol) => {
          return (
            <div className="bg-white rounded-xl my-2">
              <div className="grid grid-cols-5 p-2">
                <div className="col-span-1">
                  <div className="avatar">
                    <div className="w-12 h-12 rounded-full">
                      {kol.avatarUrl ? (
                        <img src={kol.avatarUrl} />
                      ) : (
                        <FontAwesomeIcon
                          className="text-primary w-12 h-12 rounded-full"
                          icon={faCircleUser}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="ml-2 col-span-3">
                  <div className="grid grid-rows-2">
                    <div className="row-span-1">
                      <p className="font-medium text-lg">{kol.userName}</p>
                    </div>
                    <div className="row-span-1 text-xs text-gray-500 mt-2">
                      <div className="flex flex-row gap-4">
                        <p>
                          <FontAwesomeIcon
                            icon={faGlobeAsia}
                            className="mr-2"
                          />
                          {getCountry(kol.nationality)?.label as string}
                        </p>
                        <p>
                          <FontAwesomeIcon icon={faStar} className="mr-2" />
                          {typeof kol.score === "number"
                            ? Math.round(kol.score * 10) / 10
                            : ""}
                          <span>({kol.jobScored})</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-1">
                  <FontAwesomeIcon
                    onClick={() => deleteKOL(kol.id)}
                    className="mt-2 text-xl cursor-pointer"
                    icon={faTrash}
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SelectedKOLList
