import { multiSelectGroup } from "../Model/Table"
import { getUserLang } from "./utiltyHelper"

export interface tagTypeSelection {
  value: string
  label: string
}

export const ADMIN_REQUEST_TYPE = {
  APPEAL: "APPEAL",
  BANK_CHECKOUT: "BANK_CHECKOUT",
  RECOMMEND_REQUEST: "RECOMMEND_REQUEST",
  PAYOUT_REQUEST: "PAYOUT_REQUEST",
  PAY_LATER_REQUEST: "PAY_LATER_REQUEST",
}
export const genderType = [
  {
    value: "0",
    labelCHI: "男",
    label: "Male",
  },
  {
    value: "1",
    labelCHI: "女",
    label: "Female",
  },
  {
    value: "2",
    labelCHI: "未選擇性別",
    label: "Unisex",
  },
]
export const returnGenderName = (label: string) => {
  const match = genderType.find((e) => e.value === label)
  return match ? (getUserLang() === "en" ? match.label : match.labelCHI) : "-"
}
export const ageType = [
  {
    value: "0",
    min: "12",
    max: "15",
  },
  {
    value: "1",
    min: "16",
    max: "18",
  },
  {
    value: "2",
    min: "19",
    max: "22",
  },
  {
    value: "3",
    min: "23",
    max: "29",
  },
  {
    value: "4",
    min: "30",
    max: "35",
  },
  {
    value: "5",
    min: "36",
    max: "40",
  },
  {
    value: "6",
    min: "41",
    max: "60",
  },
]

export const ageTypeString: tagTypeSelection[] = [
  {
    value: "13-17",
    label: "13-17",
  },
  {
    value: "18-24",
    label: "18-24",
  },
  {
    value: "25-34",
    label: "25-34",
  },
  {
    value: "35-44",
    label: "35-44",
  },
  {
    value: "45-54",
    label: "45-54",
  },
  {
    value: "55-65",
    label: "55-65",
  },
]

export const KOLSizeTypeString: tagTypeSelection[] = [
  {
    value: "1",
    label: "<10,000",
  },
  {
    value: "2",
    label: "10,000 - 50,000",
  },
  {
    value: "3",
    label: "50,000 - 100,000",
  },
  {
    value: "4",
    label: ">100,000",
  },
]

export const returnAgeName = (label: string) => {
  const match = ageType.find((e) => e.value === label)
  return match ? { min: match.min, max: match.max } : { min: "-", max: "-" }
}
export const generalType = [
  {
    value: "1",
    labelCHI: "服裝及配飾",
    label: "Apparel and Accessories",
  },
  {
    value: "2",
    labelCHI: "美容和個人護理",
    label: "Beauty and Personal Care",
  },
  {
    value: "3",
    labelCHI: "家居和傢俱",
    label: "Home and Furniture",
  },
  {
    value: "4",
    labelCHI: "電子和娛樂",
    label: "Electronics and Entertainment",
  },
  {
    value: "5",
    labelCHI: "食品和飲料",
    label: "Food and Beverages",
  },
  {
    value: "6",
    labelCHI: "體育",
    label: "Sports",
  },
  {
    value: "7",
    labelCHI: "戶外和旅行",
    label: "Outdoor and Travel",
  },
  {
    value: "8",
    labelCHI: "家庭和育兒",
    label: "Family and Parenting",
  },
  {
    value: "9",
    labelCHI: "約會",
    label: "Dating",
  },
  {
    value: "10",
    labelCHI: "特殊愛好和興趣",
    label: "Special Hobbies and Interests",
  },
  {
    value: "11",
    labelCHI: "商業與投資",
    label: "Business and Investment",
  },
  {
    value: "12",
    labelCHI: "雜項",
    label: "Miscellaneous",
  },
]

export function getAllDetailType() {
  try {
    let arr: tagTypeSelection[] = []
    DETAIL_TYPE.map((item) => {
      arr.push({
        value: item.value,
        label: getUserLang() === "en" ? item.label : item.labelCHI,
      })
    })
    return arr
  } catch (err) {
    console.log(err)
  }
}

export function getAllCategoryType() {
  try {
    let arr: tagTypeSelection[] = []
    generalType.map((item) => {
      arr.push({
        value: item.value,
        label: getUserLang() === "en" ? item.label : item.labelCHI,
      })
    })
    return arr
  } catch (err) {
    console.log(err)
    return []
  }
}

export const genreGroupData = () => {
  let dataSet: multiSelectGroup[] = []
  const generalData = [...generalType]
  const categoryData = [...DETAIL_TYPE]
  if (Array.isArray(categoryData)) {
    generalData.forEach((group) => {
      const optionsData: { value: string; label: string }[] = []

      //filter category by Groups
      categoryData
        .filter((data) => data.subset === group.value)
        .map((item) => {
          optionsData.push({
            value: item.value,
            label: getUserLang() === "en" ? item.label : item.labelCHI,
          })
        })

      //add group data with category array as options
      dataSet.push({
        label: getUserLang() === "en" ? group.label : group.labelCHI,
        options: optionsData,
      })
    })
  }
  return dataSet
}

export const returnCatePercentage = (arr: any) => {
  let counts: { label: string; count: number }[] = []
  //init
  generalType.map((e) => counts.push({ label: e.value, count: 0 }))
  try {
    if (Array.isArray(arr)) {
      arr.map((e) => {
        const categoryID = e.item.split(".")[0]
        // if categoryID not empty
        if (categoryID) {
          const index = counts.findIndex((e) => e.label === categoryID)
          counts[index].count = counts[index].count + parseFloat(e.count)
        }
      })
    }

    return counts.sort((a, b) => b.count - a.count)
  } catch (e) {
    return counts
  }
}

export const isCentralizedGenre = (arr: any): boolean => {
  let counts: { label: string; count: number }[] = []
  //init
  generalType.map((e) => counts.push({ label: e.value, count: 0 }))
  let total = 0

  try {
    if (Array.isArray(arr)) {
      arr.map((e) => {
        const categoryID = e.item.split(".")[0]
        // if categoryID not empty
        if (categoryID) {
          const index = counts.findIndex((e) => e.label === categoryID)
          counts[index].count = counts[index].count + parseFloat(e.count)
        }
      })
    }

    return (
      typeof counts.find((item) => item.count / total > 0.4) !== "undefined"
    )
  } catch (e) {
    return false
  }
}

export function returnSelectedCategories(valueArr: string[]) {
  let items: tagTypeSelection[] = []

  try {
    if (Array.isArray(valueArr)) {
      valueArr.map((va) => {
        generalType.map((e) => {
          if (e.value === va) {
            items.push({
              value: e.value,
              label:
                localStorage.getItem("i18n-lang") === "en"
                  ? e.label
                  : e.labelCHI,
            })
          }
        })
      })
    }

    return items
  } catch (err) {
    console.log(err)
    return []
  }
}

export function returnSelectedItems(valueArr: string[]) {
  let items: tagTypeSelection[] = []

  try {
    if (Array.isArray(valueArr)) {
      valueArr.map((va) => {
        DETAIL_TYPE.map((e) => {
          if (e.value === va) {
            items.push({
              value: e.value,
              label: getUserLang() === "en" ? e.label : e.labelCHI,
            })
          }
        })
      })
    }

    return items
  } catch (err) {
    console.log(err)
    return []
  }
}

export const returnCategoryName = (label: string) => {
  const match = generalType.find((e) => e.value === label)
  return match ? (getUserLang() === "en" ? match.label : match.labelCHI) : "-"
}

export function returnSelectedItem(value: string) {
  let item = {
    value: "0",
    label: "-",
  }
  try {
    DETAIL_TYPE.map((e) => {
      if (e.value === value) {
        item = {
          value: e.value,
          label: getUserLang() === "en" ? e.label : e.labelCHI,
        }
      }
    })

    return item
  } catch (err) {
    console.log(err)
  }
}

export const stringToColour = (str: string): string => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = "#"
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff
    colour += ("00" + value.toString(16)).substr(-2)
  }
  return colour
}

export const DETAIL_TYPE = [
  {
    value: "",
    labelCHI: "－",
    label: "－",
  },
  {
    value: "1.1",
    subset: "1",
    labelCHI: "👗 服裝和服裝",
    label: "👗 Apparel and clothing",
  },
  {
    value: "1.2",
    subset: "1",
    labelCHI: "👟 鞋子和鞋類",
    label: "👟 Shoes and footwear",
  },
  {
    value: "1.3",
    subset: "1",
    labelCHI: "👒 配飾、包包、珠寶和帽子",
    label: "👒 Accessories, bags, jewelry, and hats",
  },
  {
    value: "1.4",
    subset: "1",
    labelCHI: "💍 奢侈時尚和奢侈包袋",
    label: "💍 Luxury fashion and luxury bags",
  },
  {
    value: "1.5",
    subset: "1",
    labelCHI: "👘 時尚復古",
    label: "👘 Trendy and vintage",
  },
  {
    value: "2.1",
    subset: "2",
    labelCHI: "💅 美容護膚品",
    label: "💅 Beauty and skincare products",
  },
  {
    value: "2.2",
    subset: "2",
    labelCHI: "🧴 個人衛生和美容產品",
    label: "🧴 Personal hygiene and grooming products",
  },
  {
    value: "2.3",
    subset: "2",
    labelCHI: "💊 健康和保健產品，如維生素和補充劑",
    label: "💊 Health and wellness products, such as vitamins and supplements",
  },
  {
    value: "3.1",
    subset: "3",
    labelCHI: "🛋️ 家居裝飾和傢俱",
    label: "🛋️ Home decor and furnishings",
  },
  {
    value: "3.2",
    subset: "3",
    labelCHI: "🛏️ 傢俱、家用電器和智慧家居設備",
    label: "🛏️ Furniture, home appliances and smart-home devices",
  },
  {
    value: "3.3",
    subset: "3",
    labelCHI: "🍳 廚具和烹飪工具",
    label: "🍳 Kitchenware and cooking tools",
  },
  {
    value: "4.1",
    subset: "4",
    labelCHI: "🎮 遊戲設備和配件",
    label: "🎮 Gaming equipment and accessories",
  },
  {
    value: "4.2",
    subset: "4",
    labelCHI: "💻 電子產品和小工具，如智慧手機和筆記型電腦",
    label: "💻 Electronics and gadgets, such as smartphones and laptops",
  },
  {
    value: "4.3",
    subset: "4",
    labelCHI: "🎧 音訊設備和揚聲器",
    label: "🎧 Audio equipment and speakers",
  },
  {
    value: "4.4",
    subset: "4",
    labelCHI: "💿 技術配件和元件",
    label: "💿 Tech accessories and components",
  },
  {
    value: "5.1",
    subset: "5",
    labelCHI: "🍽️ 食品和飲料",
    label: "🍽️ Food and beverages",
  },
  {
    value: "5.2",
    subset: "5",
    labelCHI: "🍷 酒精和烈酒",
    label: "🍷 Alcohol and spirits",
  },
  {
    value: "5.3",
    subset: "5",
    labelCHI: "☕ 咖啡和茶產品",
    label: "☕ Coffee and tea products",
  },
  {
    value: "6.1",
    subset: "6",
    labelCHI: "⛹ 運動和戶外裝備",
    label: "⛹ Sports and outdoor gear",
  },
  {
    value: "6.2",
    subset: "6",
    labelCHI: "🏟️ 體育迷",
    label: "🏟️ Sports fandom",
  },
  {
    value: "6.3",
    subset: "6",
    labelCHI: "🏃 運動訓練",
    label: "🏃 Sport training",
  },
  {
    value: "7.1",
    subset: "7",
    labelCHI: "✈ 旅行配件和行李箱",
    label: "✈ Travel accessories and luggage",
  },
  {
    value: "7.2",
    subset: "7",
    labelCHI: "🥾 戶外和露營裝備",
    label: "🥾 Outdoor and camping gear",
  },
  {
    value: "7.3",
    subset: "7",
    labelCHI: "🚲 個人交通工具，如自行車或電動滑板車",
    label:
      "🚲 Personal transportation devices, such as bicycles or electric scooters",
  },
  {
    value: "7.4",
    subset: "7",
    labelCHI: "🏡 花園和戶外設備",
    label: "🏡 Garden and outdoor equipment",
  },
  {
    value: "8.1",
    subset: "8",
    labelCHI: "🧒 兒童和青少年產品",
    label: "🧒 Children's and teens products",
  },
  {
    value: "8.2",
    subset: "8",
    labelCHI: "👶 嬰兒用品",
    label: "👶 Baby products",
  },
  {
    value: "8.3",
    subset: "8",
    labelCHI: "🏫 兒童教育",
    label: "🏫 Child education",
  },
  {
    value: "9.1",
    subset: "9",
    labelCHI: "💝 約會相關，如禮物，情侶故事，約會地點推薦",
    label:
      "💝 Dating related, such as gifts, couple story, recommendation of dating location",
  },
  {
    value: "9.2",
    subset: "9",
    labelCHI: "💋 性相關產品",
    label: "💋 Sex related product",
  },
  {
    value: "9.3",
    subset: "9",
    labelCHI: "💑 尋找交友物件，如交友APP",
    label: "💑 Seeking dating partner, such as dating APP",
  },
  {
    value: "10.1",
    subset: "10",
    labelCHI: "🎨 美術用品和材料",
    label: "🎨 Art supplies and materials",
  },
  {
    value: "10.2",
    subset: "10",
    labelCHI: "📚 書籍和文學",
    label: "📚 Books and literature",
  },
  {
    value: "10.3",
    subset: "10",
    labelCHI: "🎶 音樂和樂器",
    label: "🎶 Music and musical instruments",
  },
  {
    value: "10.4",
    subset: "10",
    labelCHI: "🎥 電影和電視節目",
    label: "🎥 Movies and TV shows",
  },
  {
    value: "10.5",
    subset: "10",
    labelCHI: "📸 相機和攝影設備",
    label: "📸 Cameras and photography equipment",
  },
  {
    value: "10.6",
    subset: "10",
    labelCHI: "🧩 棋盤遊戲和拼圖",
    label: "🧩 Board games and puzzles",
  },
  {
    value: "10.7",
    subset: "10",
    labelCHI: "🦸 動漫，漫畫，人物和角色扮演",
    label: "🦸 Anime, Comic, Figure and Cosplay",
  },
  {
    value: "10.8",
    subset: "10",
    labelCHI: "✂ 手工製品、手工藝品或手工製品",
    label: "✂ Handmade, Crafting or artisanal items",
  },
  {
    value: "10.9",
    subset: "10",
    labelCHI: "🔬 科學與知識",
    label: "🔬 Science and knowledge",
  },
  {
    value: "10.10",
    subset: "10",
    labelCHI: "⚜ 紋身",
    label: "⚜ Tattoo",
  },
  {
    value: "11.1",
    subset: "11",
    labelCHI: "📱 加密貨幣和 NFT",
    label: "📱 Crypto and NFT",
  },
  {
    value: "11.2",
    subset: "11",
    labelCHI: "📈 商業知識、未來科技、投資、商業新聞和股票市場",
    label:
      "📈 Business Knowledge, Future Tech, Investment, Business News and Stock Market",
  },
  {
    value: "11.3",
    subset: "11",
    labelCHI: "👔 商業、初創企業和專業服務，如網頁設計或法律服務。",
    label:
      "👔 Business, Start-up and professional services, such as web design or legal services.",
  },
  {
    value: "12.1",
    subset: "12",
    labelCHI: "🎓 學校、考試、文具和紙製品",
    label: "🎓 School, Exam, Stationery and paper products",
  },
  {
    value: "12.2",
    subset: "12",
    labelCHI: "🧵 量身定製、個人化和定製商品",
    label: "🧵 Tailor-mdde, personalized and custom items",
  },
  {
    value: "12.3",
    subset: "12",
    labelCHI: "🎄 季節性商品，如萬聖節服裝或聖誕裝飾品",
    label:
      "🎄 Seasonal items, such as Halloween costumes or Christmas decorations",
  },
  {
    value: "12.4",
    subset: "12",
    labelCHI: "🎁 個人化禮物（不適用於情侶）",
    label: "🎁 Personalized gifts (not for couple)",
  },
  {
    value: "12.5",
    subset: "12",
    labelCHI: "🐈 寵物用品和產品",
    label: "🐈 Pet supplies and products",
  },
  {
    value: "12.6",
    subset: "12",
    labelCHI: "💙 慈善事業",
    label: "💙 Philanthropy",
  },
  {
    value: "12.7",
    subset: "12",
    labelCHI: "💡 創意內容",
    label: "💡 Creative Content",
  },
  {
    value: "12.8",
    subset: "12",
    labelCHI: "🙋‍♀️ KOL",
    label: "🙋‍♀️ KOL",
  },
]
