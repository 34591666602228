import { Job, ReferralJob } from "./Job"
import AdminRequest, { PAYOUT_REQUEST_DATA } from "./AdminRequest"
import { ADMIN_REQUEST_STATUS_TYPE } from "../Enum/APP_TYPE"
import { timeConverter } from "../Utils/Converter"
import { BADGE_TYPE } from "../Enum/TABLE_SYSTEM"
import { t } from "i18next"

export enum BankTransactionType {
  ADMIN_REQUEST = "ADMIN_REQUEST",
  JOB = "JOB",
  REFERRAL = "REFERRAL",
}

export interface BankTransaction {
  id: string | undefined
  type: BankTransactionType
  title: string
  amount: number
  date: Date
  badge: {
    type: BADGE_TYPE
    value: string
  }
  status?: ADMIN_REQUEST_STATUS_TYPE
  data: AdminRequest | Job | ReferralJob
}

export const getBankTransaction = (
  payoutHis: AdminRequest[],
  jobHist: Job[],
  refJob: ReferralJob[]
): BankTransaction[] => {
  let tempTrans: BankTransaction[] = []

  if (Array.isArray(payoutHis)) {
    payoutHis.map((p: AdminRequest) => {
      const payoutRequest = p.data as PAYOUT_REQUEST_DATA
      tempTrans.push({
        id: p.id,
        type: BankTransactionType.ADMIN_REQUEST,
        title: t("MyTransaction.payout"),
        amount: payoutRequest.withdrawAmount,
        date: timeConverter(p.lastUpdate),
        badge: {
          type:
            p.status === ADMIN_REQUEST_STATUS_TYPE.FINISHED
              ? BADGE_TYPE.SUCCESS
              : BADGE_TYPE.PENDING,
          value:
            p.status === ADMIN_REQUEST_STATUS_TYPE.FINISHED
              ? "MyPayoutRequest.finished"
              : "MyPayoutRequest.in-progress",
        },
        status: p.status,
        data: p,
      })
    })
  }

  if (Array.isArray(jobHist)) {
    jobHist.map((j: Job) => {
      tempTrans.push({
        id: j.id,
        amount: j.payoutAmount,
        title: j.ServicePlanName,
        date: timeConverter(j.payDate),
        type: BankTransactionType.JOB,
        badge: {
          type: BADGE_TYPE.SUCCESS,
          value: "MyPayoutRequest.finished",
        },
        data: j,
      })
    })
  }

  if (Array.isArray(jobHist)) {
    refJob.map((j: ReferralJob) => {
      tempTrans.push({
        id: j.id,
        amount: j.amount,
        title: "KOL Referral",
        date: timeConverter(j.lastUpdate),
        type: BankTransactionType.REFERRAL,
        badge: {
          type: BADGE_TYPE.SUCCESS,
          value: "MyPayoutRequest.finished",
        },
        data: j,
      })
    })
  }

  const allTransactions: BankTransaction[] = tempTrans
  allTransactions.sort((a, b) => {
    const dateA = new Date(a.date).getTime()
    const dateB = new Date(b.date).getTime()
    return dateB - dateA
  })
  return allTransactions
}
