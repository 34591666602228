import React from "react"
import DivContentLoader from "../../Component/DivContentLoader"

const KOLPlanItemPlaceHolder = () => {
  return (
    <div className="p-6 pt-0  m-2  inline-block bg-white align-middle rounded-xl  shadow border border-gray-100 h-80 md:w-64 w-60">
      <h3 className="text-black font-semibold text-xl mt-2 overflow-ellipsis overflow-hidden">
        <DivContentLoader w={"100%"} h={32} r={2} />
      </h3>
      <p className="pt-2 tracking-wide">
        <span className="text-gray-400 align-top">
          <DivContentLoader w={"100%"} h={18} r={2} />
        </span>
      </p>

      <hr className="mt-4 border-1" />
      <div className="pt-2  h-auto max-h-56 overflow-y-auto">
        <DivContentLoader w={80} h={24} r={2} />
      </div>
    </div>
  )
}
export default KOLPlanItemPlaceHolder
