export const CompanySize = [
  {
    label: "<10",
    value: "1",
  },
  {
    label: "10-50",
    value: "2",
  },
  {
    label: "51-250",
    value: "3",
  },
  {
    label: ">250",
    value: "4",
  },
]
