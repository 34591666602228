import { IgMediaType } from "../HelpingFunction/FBLogin/FBLoginHelper"

interface CaseStudyIG {
  id: string
  jobID?: string
  ig_comment: number
  ig_like: number
  ig_save: number
  ig_share: number
  ig_total_action: number
  ig_total_engagement: number
  ig_reach: number
  ig_permalink: string
  ig_id: string
  ig_media_type: IgMediaType
  imageUrl: string[]
  title: string
  verified: boolean
  isPublish: boolean
  action?: "add" | "update" | "delete" | ""
}

export interface CSMediaListData {
  id: string
  caption: string
  comments_count: number
  like_count: number
  media_type: IgMediaType
  media_url: IgMediaType
  timestamp: Date
  permalink: string
  thumbnail_url?: string
}

export interface CSMediaInsight {
  engagement: number
  impressions: number
  reach: number
  saved: number
}

export interface CSIgFinalMedia {
  ig_comment: number
  ig_like: number
  ig_save: number
  ig_share: number
  ig_total_action: number
  ig_engagement: number
  ig_reach: number
  ig_permalink: string
  media_url: string
}

export const defaultCaseStudyIG: CaseStudy = {
  id: "-",
  ig_comment: -1,
  ig_like: -1,
  ig_save: -1,
  ig_share: -1,
  ig_total_action: -1,
  ig_total_engagement: -1,
  ig_reach: -1,
  ig_permalink: "-",
  ig_media_type: IgMediaType.IMAGE,
  ig_id: "-",
  imageUrl: [],
  title: "",
  verified: false,
  isPublish: false,
}

export type CaseStudy = CaseStudyIG
