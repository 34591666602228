import React from "react"
import { SubmitJobFulfillmentModalData } from "../../Model/Modal"
import { Trans } from "react-i18next"

const SubmitJobFulfillmentModalComponent = (
  props: SubmitJobFulfillmentModalData
) => {
  return (
    <div className="container mx-auto md:p-8  overflow-y-none my-8">
      <p>
        <Trans>SubmitJobFulfillment.confirm-job-fulfilled-des</Trans>
      </p>
    </div>
  )
}

export default SubmitJobFulfillmentModalComponent
