import classNames from "classnames"
import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { gptPrompts } from "../../../Model/ChatGPT"
import { CHATGPT_SETTING_TYPE, COMMEND_TYPE } from "../../../Model/ChatMessage"
import { CHATGPT_HELPER_TYPE } from "../../../Redux/Reducer/ChatGPTManager"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"

const ChatGPTPromptExample: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const chatgptSetting: CHATGPT_SETTING_TYPE = useSelector((state: any) => {
    return state.ChatGPTManager.setting
  })

  const setChatGptPrompt = (data: string) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATGPT_PROMPT,
      data: data,
    })
  }
  const onChangeChatGPTTabType = (type: CHATGPT_HELPER_TYPE) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATGPT_TAB,
      data: type,
    })
  }
  const updateGPTCommend = (data: COMMEND_TYPE | "") => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.UPDATE_COMMEND_TYPE,
      data: data,
    })
  }
  const updateChatGPTSetting = (data: CHATGPT_SETTING_TYPE) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.UPDATE_CHATGPT_SETTING,
      data: data,
    })
  }

  const handleClick = (prompt: any) => {
    if (prompt.type === COMMEND_TYPE.INSTAGRAM) {
      let newSetting = chatgptSetting
      newSetting = {
        ...newSetting,
        commendType: COMMEND_TYPE.INSTAGRAM,
        ig: prompt.setting.ig,
      }
      onChangeChatGPTTabType(CHATGPT_HELPER_TYPE.SETTING)
      updateChatGPTSetting(newSetting)
    } else {
      updateGPTCommend(prompt.type)
      setChatGptPrompt(t(prompt.desc))
    }
  }

  return (
    <div className="p-2 lg:py-0">
      <h2 className=" text-gray-500 font-medium  px-4 py-2">
        <Trans>AddChatgptChatModal.des</Trans>
      </h2>
      <div className="  overflow-y-auto">
        {gptPrompts.map((prompt, index) => {
          return (
            <div
              key={"prompt" + index}
              className="grid my-2 bg-white p-4 border border-gray-100 rounded-lg shadow gap-2"
              style={{ gridTemplateColumns: "auto 100px" }}>
              <div>
                <p
                  className={classNames("badge w-20 my-2 border-0", {
                    "bg-sky-400": prompt.type === COMMEND_TYPE.TEXT,
                    "bg-amber-400": prompt.type === COMMEND_TYPE.IMAGE,
                    "bg-violet-400": prompt.type === COMMEND_TYPE.INSTAGRAM,
                  })}>
                  {prompt.type === COMMEND_TYPE.TEXT && "Text"}
                  {prompt.type === COMMEND_TYPE.IMAGE && "Image"}
                  {prompt.type === COMMEND_TYPE.INSTAGRAM && "Instagram"}
                </p>
                <h3 className="text-lg font-bold ">{t(prompt.title)} </h3>
                <p className="text-sm text-gray-500 my-1">{t(prompt.desc)}</p>
              </div>
              <div>
                <button
                  className="btn btn-outline btn-primary my-2"
                  onClick={() => handleClick(prompt)}>
                  Try Now
                </button>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default ChatGPTPromptExample
