import React, { useEffect, useState } from "react"
import NewItemSelector from "./NewItemSelector"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCopy,
  faImage,
  faList,
  faPaperclip,
  faPaperPlane,
  faReply,
  faT,
  faTimes,
  faToolbox,
} from "@fortawesome/free-solid-svg-icons"

import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import MessageManager from "./MessageManager"
import {
  ASSET_TYPE,
  CHATROOM_PAGE_TYPE,
  CHATROOM_TYPE,
} from "../../../Enum/APP_TYPE"
import {
  CHATGPT_SETTING_TYPE,
  ChatGPTQuestion,
  ChatGPTReply,
  ChatMessage,
  COMMEND_TYPE,
  defaultChatGPTIGSettingType,
  defaultChatGPTTextSettingType,
  defaultUserInputType,
  UserInputType,
} from "../../../Model/ChatMessage"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "./NewMessageContainer.module.css"
import { isAuth } from "../../../Utils/FirebaseUtils"
import { ChatLastMessageWithId } from "../../../Model/Chatroom"
import classNames from "classnames"
import { Trans, useTranslation } from "react-i18next"
import { sendChatroomMessage } from "../../../HelpingFunction/Chatroom/chatroomDBHelper"
import { returnChatGPTSetting } from "../../../HelpingFunction/Chatroom/chatgptHelper"
import { CHATGPT_HELPER_TYPE } from "../../../Redux/Reducer/ChatGPTManager"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import igIcon from "../../../Assets/instagram-brands.svg"
import { isStringHaveSpacesAndNewLinesOnly } from "../../../Utils/HelpingFunction"
import { getAuthUID, getQuery } from "../../../Utils/QueryHelper"

interface PropsType {
  chatroomID: string
  scrollToTop: () => void
  isAdmin: boolean
}

const NewMessageContainer: React.FC<PropsType> = ({
  chatroomID,
  scrollToTop,
  isAdmin,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const params = useSelector((state: any) => {
    return state.QueryManager.params
  })
  const chatroomInfo = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomInfo
  })

  const draftReduxMsg = useSelector((state: any) => {
    return state.FileExplorer.draftMsg
  })

  const isContainDraftMsg = useSelector((state: any) => {
    return state.FileExplorer.isContainDraftMsg
  })

  const isWaitingChatgpt = useSelector((state: any) => {
    return state.ChatRoomInfo.isWaitingChatgpt
  })

  const gptPrompt: string = useSelector((state: any) => {
    return state.ChatRoomInfo.chatGptPrompt
  })

  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })

  const chatgptSetting: CHATGPT_SETTING_TYPE = useSelector((state: any) => {
    return state.ChatGPTManager.setting
  })

  const pageType = useSelector((state: any) => {
    return state.ChatRoomInfo.pageType
  })
  const chatGPTPastMessages = useSelector((state: any) => {
    return state.ChatRoomInfo.pastChatGPTMessages
  })

  const [isSending, setIsSending] = useState<boolean>(false)
  const [newMessage, setNewMessage] = useState<
    UserInputType | ChatGPTQuestion | ChatGPTReply
  >(defaultUserInputType)
  const [draftMsg, setDraftMsg] = useState<ChatMessage>()
  const [height, setHeight] = useState<string>("40px")
  const [gptMenu, setGPTMenu] = useState(false)

  const [textContainerWidth, setTextContainerWidth] = useState<number>(450)

  const viewID = getQuery(params, "viewAs")

  const isAdminDisplay = isAdmin && chatroomInfo.type !== CHATROOM_TYPE.ADMIN
  const isChatGPTChatroom =
    chatroomInfo.type === CHATROOM_TYPE.CHATGPT &&
    chatroomID.includes("chatgpt")

  const isChatGPTQuestionTypeOnly = isContainDraftMsg
    ? draftMsg?.type !== ASSET_TYPE.CHATGPT_TEXT_REPLY
    : false

  useEffect(() => {
    if (isContainDraftMsg) {
      setDraftMsg({
        // id: uuidv4(),
        ...draftReduxMsg,
        createUserID: getAuthUID(params),
      })
    }
  }, [isContainDraftMsg])

  useEffect(() => {
    if (isAuth(auth)) {
      setNewMessage({
        ...newMessage,
        msg: "",
        createUserID: getAuthUID(params),
      })
    }
  }, [auth])

  useEffect(() => {
    setNewMessage(defaultUserInputType)
  }, [chatroomID])

  useEffect(() => {
    if (isChatGPTChatroom) {
      //convert into CHATGPT_TEXT_QUESTION type, "setting" is required
      setNewMessage({
        ...newMessage,
        type: ASSET_TYPE.CHATGPT_TEXT_QUESTION,
        msg: gptPrompt ? gptPrompt : "",
        setting: returnChatGPTSetting(
          chatgptSetting.commendType as COMMEND_TYPE,
          chatgptSetting as CHATGPT_SETTING_TYPE
        ),
        createUserID: getAuthUID(params),
        pastHistory: chatGPTPastMessages,
      })
      setHeight("40px")
    }
  }, [isChatGPTChatroom, gptPrompt, chatgptSetting])

  let container = document.getElementById("MessageContainerParent")
  useEffect(() => {
    const textContainer = document.getElementById("text-container")
    if (
      container &&
      textContainer &&
      (pageType === CHATROOM_PAGE_TYPE.CHAT_TEXT || screenWidth >= 768)
    ) {
      setTextContainerWidth(container.offsetWidth)
    }
  }, [container, container?.offsetWidth, pageType])

  useEffect(() => {
    setGPTMenu(chatroomInfo.type === CHATROOM_TYPE.CHATGPT)
  }, [chatroomInfo])

  const cancelDraftMsg = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.RESET_DRAFT_MSG,
    })
  }

  const setIsWaitingChatGPT = (bool: boolean) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.IS_WAITING_CHATGPT,
      data: bool,
    })
  }
  const setChatGptPrompt = (data: string) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATGPT_PROMPT,
      data: data,
    })
  }
  const onChangeChatGPTTabType = (type: CHATGPT_HELPER_TYPE) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATGPT_TAB,
      data: type,
    })
  }

  const updateLastMessage = (lastMessage: ChatLastMessageWithId) => {
    // this will also trigger the chatroom reorder
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.UPDATE_LAST_MESSAGE_FROM_USER,
      data: lastMessage,
    })
  }
  const updateGPTCommend = (data: COMMEND_TYPE | "") => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.UPDATE_COMMEND_TYPE,
      data: data,
    })
  }

  const updateChatGPTSetting = (data: CHATGPT_SETTING_TYPE) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.UPDATE_CHATGPT_SETTING,
      data: data,
    })
  }

  const sendMessage = (msg: ChatMessage): void => {
    cancelDraftMsg()

    //reset

    setChatGptPrompt("")

    if (!isSending) {
      setIsSending(true)

      // if this is chatgpt chat, show loading chat let user waiting
      if (
        isChatGPTChatroom &&
        (msg.type === ASSET_TYPE.CHATGPT_TEXT_QUESTION ||
          msg.type === ASSET_TYPE.CHATGPT_TEXT_REPLY)
      ) {
        setIsWaitingChatGPT(true)
        setChatGptPrompt("")
        updateChatGPTSetting(
          chatgptSetting.commendType === COMMEND_TYPE.INSTAGRAM
            ? defaultChatGPTIGSettingType
            : defaultChatGPTTextSettingType
        )

        setNewMessage({
          ...defaultUserInputType,
          type: ASSET_TYPE.CHATGPT_TEXT_QUESTION,
          setting: returnChatGPTSetting(
            chatgptSetting.commendType as COMMEND_TYPE,
            chatgptSetting as CHATGPT_SETTING_TYPE
          ),
          createUserID: getAuthUID(params),
          pastHistory: chatGPTPastMessages,
        })
      } else {
        setNewMessage(defaultUserInputType)
      }

      sendChatroomMessage(chatroomID, msg, getAuthUID(params))
        .then((result) => {
          setIsSending(false)
          updateLastMessage({
            chatroomID: chatroomID,
            lastMessage: msg,
          })
        })
        .catch((err) => console.log(err))
    }
  }

  let textarea = document.querySelector("textarea")

  if (textarea !== null) {
    textarea.addEventListener("keydown", autosize)
  }

  function autosize(this: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let el = this
    setTimeout(function () {
      // for box-sizing other than "content-box" use:
      // el.style.cssText = '-moz-box-sizing:content-box';
      if (el.scrollHeight > 40) {
        setHeight(el.scrollHeight + "px")
      }
    }, 0)
  }

  const press = () => {
    document
      .getElementById("NewMessageContainer_ChatTextField")
      ?.addEventListener("keydown", (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
          event.preventDefault()
          document.getElementById("NewMessageContainer_SendChatButton")?.click()
        }
      })
  }

  return (
    <div className="relative">
      {isContainDraftMsg && draftMsg && (
        <div
          className="absolute bottom-12 h-24 overflow-hidden block  w-full bg-gray-100 rounded-t-xl p-4 mb-2 md:mb-4 shadow-md"
          style={{ marginTop: "-80px" }}>
          <button
            className="relative float-right "
            onClick={() => {
              cancelDraftMsg()
            }}>
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <div className="flex gap-3 items-center">
            <FontAwesomeIcon
              className="text-neutral text-lg"
              icon={
                draftMsg.type === ASSET_TYPE.CHATGPT_TEXT_REPLY
                  ? faReply
                  : faCopy
              }
            />
            <div className="max-w-full h-16 pr-8">
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/*@ts-ignore*/}
              <MessageManager item={draftMsg} readMsg={false} draftMsg={true} />
            </div>
          </div>
        </div>
      )}

      {gptMenu && (
        <div className="safe-are-detection-fixed-bottom absolute z-20 bottom-16 ">
          <ul className="menu border border-gray-200 bg-base-100 w-56 rounded-box">
            <li
              onClick={() => {
                setGPTMenu(!gptMenu)
                onChangeChatGPTTabType(CHATGPT_HELPER_TYPE.SETTING)
                updateGPTCommend(COMMEND_TYPE.TEXT)
                updateChatGPTSetting(defaultChatGPTTextSettingType)
              }}>
              <a>
                <Trans>ChatGPTSetting.menu-text</Trans>
              </a>
            </li>
            <li
              onClick={() => {
                setGPTMenu(!gptMenu)
                onChangeChatGPTTabType(CHATGPT_HELPER_TYPE.SETTING)
                updateGPTCommend(COMMEND_TYPE.IMAGE)
                updateChatGPTSetting({
                  ...defaultChatGPTTextSettingType,
                  commendType: COMMEND_TYPE.IMAGE,
                })
              }}>
              <a>
                <Trans>ChatGPTSetting.menu-image</Trans>
              </a>
            </li>
            <li
              onClick={() => {
                setGPTMenu(!gptMenu)
                onChangeChatGPTTabType(CHATGPT_HELPER_TYPE.SETTING)
                updateGPTCommend(COMMEND_TYPE.INSTAGRAM)
                updateChatGPTSetting(defaultChatGPTIGSettingType)
              }}>
              <a>
                <Trans>ChatGPTSetting.menu-ig</Trans>
              </a>
            </li>
          </ul>
        </div>
      )}

      <div
        id="text-container"
        className={classNames(
          "fixed z-20 safe-are-detection-fixed-bottom bottom-0 flex flex-row items-center min-h-16 md:h-12 md:rounded-xl bg-gray-100 w-full px-2"
        )}
        style={{
          width: textContainerWidth,
        }}>
        <div
          className={classNames("-mr-2", {
            hidden: screenWidth > 768 && isChatGPTChatroom,
          })}>
          <div className={classNames("dropdown dropdown-top ")}>
            <label tabIndex={0} className="btn btn-sm btn-circle btn-ghost m-1">
              <FontAwesomeIcon
                icon={isChatGPTChatroom ? faToolbox : faPaperclip}
              />
            </label>
            <ul
              tabIndex={0}
              className={classNames(
                "safe-are-detection-fixed-bottom dropdown-content menu p-2 mb-2 border border-gray-200 bg-base-100 rounded-box w-full md:w-96 h-96 lg:h-44",
                { "!fixed !bottom-14 !left-0 z-20": screenWidth < 768 }
              )}>
              <NewItemSelector agencyID={viewID} sendMessage={sendMessage} />
            </ul>
          </div>
        </div>

        <div
          className={classNames("-mr-2", {
            hidden: !isChatGPTChatroom,
          })}>
          <button
            className="btn btn-square btn-ghost btn-sm"
            onClick={() => {
              if (!isAdminDisplay) {
                setGPTMenu(!gptMenu)
              }
            }}>
            {gptMenu && <FontAwesomeIcon icon={faList} />}

            {!gptMenu && chatgptSetting.commendType === COMMEND_TYPE.TEXT && (
              <FontAwesomeIcon icon={faT} />
            )}
            {!gptMenu && chatgptSetting.commendType === COMMEND_TYPE.IMAGE && (
              <FontAwesomeIcon icon={faImage} />
            )}
            {chatgptSetting.commendType === COMMEND_TYPE.INSTAGRAM &&
              !gptMenu && <img className="w-4 h-4 object-cover" src={igIcon} />}
          </button>
        </div>

        <div className="flex-grow relative ml-4">
          <div className="relative w-full">
            <textarea
              id={"NewMessageContainer_ChatTextField"}
              style={{
                maxHeight: "120px",
                overflow: "auto",
                display: "block",
                height: height,
                padding: "7px auto",
              }}
              rows={1}
              className="rounded-3xl w-full pt-1.5 mt-3 border  focus:outline-none focus:border-indigo-300 pl-4 min-h-10 absolute -bottom-5 resize-none"
              value={newMessage.msg}
              maxLength={500}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              onKeyDown={press()}
              placeholder={
                isAdminDisplay
                  ? "ADMIN NOT ALLOW TO INPUT"
                    ? isWaitingChatgpt
                    : t("ChatText.waiting-for-chatgpt")
                  : ""
              }
              disabled={
                isChatGPTQuestionTypeOnly ||
                isAdminDisplay ||
                isWaitingChatgpt ||
                chatgptSetting.commendType === COMMEND_TYPE.INSTAGRAM
              }
              onChange={(e) => {
                if (e.target.value.length <= 500) {
                  if (
                    isContainDraftMsg &&
                    draftMsg?.type === ASSET_TYPE.CHATGPT_TEXT_REPLY
                  ) {
                    setNewMessage({
                      ...draftMsg,
                      msg: e.target.value,
                    })
                  } else {
                    setNewMessage({
                      ...newMessage,
                      msg: e.target.value,
                    })
                  }
                }
              }}
            />
          </div>
        </div>
        <div className="ml-2">
          <button
            id={"NewMessageContainer_SendChatButton"}
            disabled={isAdminDisplay || isWaitingChatgpt}
            className={classNames(
              "btn btn-primary btn-sm rounded-xl flex-shrink-0",
              {
                "text-grey-100 cursor-wait": isSending,
                "text-indigo cursor-pointer": !isSending,
                loading: isWaitingChatgpt,
              }
            )}
            onClick={() => {
              if (
                !isStringHaveSpacesAndNewLinesOnly(newMessage.msg) ||
                isContainDraftMsg
              ) {
                sendMessage(
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  isChatGPTQuestionTypeOnly ? draftMsg : newMessage
                )
                setHeight("40px")
              }
            }}>
            <span className="">
              {!isWaitingChatgpt && !isSending && (
                <FontAwesomeIcon icon={faPaperPlane} />
              )}
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default NewMessageContainer
