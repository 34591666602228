import React, { useEffect, useState } from "react"
import { isSupported } from "firebase/messaging"
import {
  isAuth,
  requestNotificationAccess,
  returnIdTokenResult,
} from "../../Utils/FirebaseUtils"
import { getAPIPath, isWeb } from "../../Utils/HelpingFunction"
import { useSelector } from "react-redux"
import { Trans } from "react-i18next"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "../../Component/HeadingMessageManager/HeadingMessageManager.module.css"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import step1 from "../../Assets/message-popup-instruction-1.png"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import step2 from "../../Assets/message-popup-instruction-2.png"
import classNames from "classnames"
import CustomAlert from "../../Component/NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../Enum/ALERT_SYSYEM"

const GCMSetup: React.FC = () => {
  return (
    <div className="card my-4 flex justify-center border border-gray-100 bg-base-100 shadow-md p-1">
      <div className="py-2">
        <h2 className="flex justify-center font-bold text-xl">
          <Trans>HeadingMessageManager.allow-notifications-how</Trans>
        </h2>
        <p className="flex justify-center text-gray-500 text-md ">
          <Trans>HeadingMessageManager.get-notifications</Trans>
        </p>

        <div className=" grid xs:grid-rows-2 sm:grid-cols-2 gap-1">
          <div className="row-span-1 sm:col-span-1">
            <p className="flex justify-center text-neutral text-md">
              <Trans>HeadingMessageManager.allow-notifications-step-1</Trans>
            </p>
            <img
              src={step1}
              className={["lazyload rounded-lg", classes.Img].join(" ")}
              alt={"setup web notification"}
            />
          </div>
          <div className="row-span-1 sm:col-span-1">
            <p className="flex justify-center text-neutral text-md">
              <Trans>HeadingMessageManager.allow-notifications-step-2</Trans>
            </p>
            <img
              src={step2}
              className={["lazyload rounded-lg", classes.Img].join(" ")}
              alt={"setup web notification"}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export const GCMManager: React.FC = () => {
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const [isSupportedMsg, setIsSupportedMsg] = useState<boolean>(false)

  const [helpMessage, setHelpMessage] = useState<boolean>(false)
  const [isRequested, setIsRequested] = useState<boolean>(false)
  const [isLocked, setIsLocked] = useState<boolean>(false)
  const [isDenied, setIsDenied] = useState<boolean>(false)

  const [isGettingToken, setIsGettingToken] = useState<boolean>(false)

  useEffect(() => {
    checkIsMsgSupport()
  }, [])

  const checkIsMsgSupport = async () => {
    const isSupportedMsg = await isSupported()
    setIsSupportedMsg(isSupportedMsg)
  }

  const isWebAndSupported = isWeb() && isSupportedMsg

  const isPermissionDenied = isWebAndSupported
    ? Notification.permission !== "granted"
    : false
  const isRejectedGCM = localStorage.getItem("look4kol-reject-gcm") === "true"

  const isRequireRequest = isAuth(auth)
    ? (localStorage.getItem("look4kol-gcm-id" + "-" + auth.uid) === null ||
        isSupportedMsg) &&
      localStorage.getItem("look4kol-reject-gcm") !== "true"
    : false

  if (isAuth(auth)) {
    return (
      <div>
        <div className="flex md:block justify-center">
          <button
            className={classNames(
              "btn btn-circle btn-outline btn-info w-full max-w-md mx-auto ",
              {
                loading: isGettingToken,
              }
            )}
            onClick={() => {
              setIsGettingToken(true)
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              requestNotificationAccess(auth).then((res) => {
                if (!res.success || isRejectedGCM || isPermissionDenied) {
                  setIsDenied(true)
                  setTimeout(() => {
                    setIsDenied(false)
                  }, 45000)
                } else {
                  setIsRequested(true)
                  setTimeout(() => {
                    setIsRequested(false)
                  }, 45000)
                }
                setIsGettingToken(false)
              })
            }}>
            {!isRequireRequest ? (
              <div>
                <Trans>HeadingMessageManager.request-noti</Trans>
              </div>
            ) : (
              <div>
                <Trans>AppSetting.test-now</Trans>
              </div>
            )}
          </button>
        </div>

        {isRequested && (
          <div className="py-2">
            <CustomAlert
              title={"AppSetting.message-sent"}
              alertType={ALERT_TYPE.INFO}
              buttonType={{
                type: BUTTON_TYPE.NONE,
              }}
            />
          </div>
        )}

        {isDenied && (
          <div className="mt-3">
            <CustomAlert
              title={"AppSetting.you-rejected-gcm"}
              alertType={ALERT_TYPE.ERROR}
              buttonType={{
                type: BUTTON_TYPE.NONE,
              }}
            />
            <GCMSetup />
          </div>
        )}
      </div>
    )
  } else if (isSupportedMsg && !isAuth(auth)) {
    return (
      <div className="">
        <div className="py-2 text-gray-500">
          <Trans>AppSetting.login-required</Trans>
        </div>
      </div>
    )
  }
  return <div></div>
}
