import { deleteDoc, doc, getFirestore } from "firebase/firestore"
import { DeleteMessageModalData } from "../../Model/Modal"
import { useDispatch } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import firebaseApp from "../../Config/firebase"

export const confirmDeleteMessage = async (
  props: DeleteMessageModalData,
  dispatch: any
) => {
  const setDeletedMessageID = (state: string) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_DELETED_MESSAGE_ID,
      deletedMessageID: state,
    })
  }
  console.log(props)
  const db = getFirestore(firebaseApp)

  await deleteDoc(
    doc(
      db,
      "ChatRoom",
      props.metaData.chatroomID,
      "Message",
      props.metaData.messageID
    )
  )
    .then((res) => {
      console.log("delete finished", props.metaData)
      setDeletedMessageID(props.metaData.messageID)
      props.closeModal()
    })
    .catch((error) => {
      console.error("Error removing document: ", error)
    })
}
