import React, { useEffect, useState } from "react"
import { Trans } from "react-i18next"
import { CHECKOUT_PAGE_TYPE } from "../../../Enum/CONTRACT"
import StripeCheckout from "./StripeCheckout"
import BankCheckout from "./BankCheckout"
import { PayLaterForm } from "./PayLaterForm"
import classNames from "classnames"
import { useDispatch, useSelector } from "react-redux"
import { Plan, PLAN_DEFAULT } from "../../../Model/Plan"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBank,
  faBusinessTime,
  faChevronRight,
  faCreditCard,
  faEllipsis,
  faUserClock,
  faWarning,
} from "@fortawesome/free-solid-svg-icons"
import { isAuth } from "../../../Utils/FirebaseUtils"
import { Job } from "../../../Model/Job"
import { ADMIN_REQUEST } from "../../../Model/AdminRequest"
import {
  ADMIN_REQUEST_STATUS_TYPE,
  JOB_STATUS_TYPE,
} from "../../../Enum/APP_TYPE"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../../Utils/ModalType"
import { getKOLUserName } from "../../../HelpingFunction/KOL/kolDBHelper"
import { getCustomerCompanyName } from "../../../HelpingFunction/Customer/customerDBHelper"
import CustomAlert from "../../../Component/NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../../Enum/ALERT_SYSYEM"

const CheckOutForm = () => {
  const dispatch = useDispatch()
  const job: Job = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomJob
  })
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const adminRequest: ADMIN_REQUEST | null = useSelector((state: any) => {
    return state.ContractManager.adminRequest
  })
  const isInvoicePage = window.location.pathname.includes("invoice")

  const [page, setPage] = useState<CHECKOUT_PAGE_TYPE>(CHECKOUT_PAGE_TYPE.BANK)
  const [kolName, setKOLName] = useState<string>("")
  const [customerCompanyName, setCustomerCompanyName] = useState<string>("")
  //For Invoice Purpose
  const [customerDetails, setCustomerDetails] = useState<{
    name: string
    email: string
  }>({
    name: "",
    email: "",
  })

  const confirmSkipPayment = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.CONTRACT_SKIP_PAYMENT_MODAL,
      metaData: null,
    })
  }

  useEffect(() => {
    if (isAuth(auth)) {
      getNames()
    } else {
      getKOLName()
    }
  }, [])

  const getNames = async () => {
    const kolName = await getKOLUserName(job.KOLID)
    const customerCompany = await getCustomerCompanyName(job.CustomerID)

    if (kolName.success) {
      setKOLName(kolName.userName)
    }
    if (customerCompany.success) {
      setCustomerCompanyName(customerCompany.customerCompanyName)
    }
  }

  const getKOLName = async () => {
    const kolName = await getKOLUserName(job.KOLID)

    if (kolName.success) {
      setKOLName(kolName.userName)
    }
  }

  if (
    adminRequest === null ||
    adminRequest.status === ADMIN_REQUEST_STATUS_TYPE.REJECT ||
    job.status === JOB_STATUS_TYPE.STATUS_4
  ) {
    return (
      <div>
        {/*
          Payment description
        */}
        {adminRequest?.status === ADMIN_REQUEST_STATUS_TYPE.REJECT && (
          <div className="px-2">
            <CustomAlert
              title="Contract.bank_checkout_reject"
              alertType={ALERT_TYPE.WARNING}
              buttonType={{
                type: BUTTON_TYPE.NONE,
              }}
              icon={faWarning}
            />
          </div>
        )}

        <section className="p-6 rounded-lg shadow bg-white">
          <h2 className="text-3xl font-medium">
            $
            {job.price.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h2>
          <div className="grid grid-cols-5  my-4 gap-y-2 text-sm">
            <div className="col-span-1 flex flex-col gap-2">
              <p className="text-gray-500 font-medium">
                <Trans>RecomQuestions.to</Trans>
              </p>
              <p className="text-gray-500 font-medium">
                <Trans>RecomQuestions.from</Trans>
              </p>
            </div>
            <div className="col-span-4 flex flex-col gap-2">
              <p className="font-light">{kolName}</p>

              <p className="font-light">
                {!isAuth(auth) && isInvoicePage
                  ? customerDetails.name
                  : customerCompanyName}
              </p>
              <hr />
              <a
                href="https://look4kol.com/hk/blog/terms"
                target="_blank"
                className="text-gray-500 font-medium cursor-pointer">
                <Trans>CheckoutForm.view-terms</Trans>{" "}
                <FontAwesomeIcon icon={faChevronRight} />
              </a>
            </div>
          </div>
        </section>

        <div className="rounded-lg bg-white p-6 my-4 shadow">
          {!isAuth(auth) && isInvoicePage && (
            <section className=" bg-white my-2">
              <h2 className="text-xl font-bold">
                <Trans>Customer Details</Trans>
              </h2>
              <div className="my-4 text-sm">
                <p className="text-gray-500 font-medium">
                  <Trans>InvoicePage.name</Trans>
                </p>
                <input
                  type="text "
                  className="input input-sm input-bordered w-full my-2"
                  onChange={(e) =>
                    setCustomerDetails({
                      ...customerDetails,
                      name: e.target.value,
                    })
                  }
                />
                <p className="text-gray-500 font-medium">
                  <Trans>InvoicePage.email</Trans>
                </p>
                <input
                  type="text "
                  className="input input-sm input-bordered w-full my-2"
                  onChange={(e) =>
                    setCustomerDetails({
                      ...customerDetails,
                      email: e.target.value,
                    })
                  }
                />
              </div>
            </section>
          )}
          <section className="my-2">
            <h2 className="text-xl font-bold">
              <Trans>CheckoutForm.select-payment</Trans>
            </h2>
            <div className="flex flex-row gap-2 justify-center">
              <div
                onClick={() => setPage(CHECKOUT_PAGE_TYPE.STRIPE)}
                className={classNames(
                  "p-2 border rounded-lg my-4  text-sm cursor-pointer",
                  page === CHECKOUT_PAGE_TYPE.STRIPE
                    ? "text-red-default border-red-default shadow"
                    : "text-gray-500",
                  isInvoicePage ? "w-full" : "w-[150px]"
                )}>
                <FontAwesomeIcon icon={faCreditCard} />
                <p>
                  <Trans>CheckoutForm.credit-card-checkout</Trans>
                </p>
              </div>
              <div
                onClick={() => setPage(CHECKOUT_PAGE_TYPE.BANK)}
                className={classNames(
                  "p-2 border rounded-lg my-4  text-sm cursor-pointer",
                  page === CHECKOUT_PAGE_TYPE.BANK
                    ? "text-red-default border-red-default shadow"
                    : "text-gray-500",
                  isInvoicePage ? "w-full" : "w-[150px]"
                )}>
                <FontAwesomeIcon icon={faBank} />
                <p>
                  <Trans>CheckoutForm.bank-checkout</Trans>
                </p>
              </div>
              <div
                tabIndex={0}
                className={classNames(
                  "p-2 border rounded-lg my-4 w-[75px] text-sm cursor-pointer dropdown dropdown-end ",
                  page === CHECKOUT_PAGE_TYPE.SKIP
                    ? "text-red-default border-red-default shadow"
                    : "text-gray-500",
                  isInvoicePage ? "hidden" : "block"
                )}>
                <label className=" m-1">
                  {" "}
                  <FontAwesomeIcon icon={faEllipsis} />
                </label>
                <p>
                  <Trans>CheckoutForm.others</Trans>
                </p>
                <ul
                  tabIndex={0}
                  className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                  <li onClick={() => confirmSkipPayment()}>
                    <a>
                      <FontAwesomeIcon className="mr-2" icon={faBusinessTime} />{" "}
                      <Trans>ContractSkipPayment.skip-payment</Trans>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section>
            <div>
              {page === CHECKOUT_PAGE_TYPE.BANK && (
                <BankCheckout customerDetails={customerDetails} />
              )}
              {page === CHECKOUT_PAGE_TYPE.STRIPE && (
                <StripeCheckout customerDetails={customerDetails} />
              )}
            </div>
          </section>
        </div>
      </div>
    )
  } else if (adminRequest.status === ADMIN_REQUEST_STATUS_TYPE.PENDING) {
    return (
      <div>
        <section className="px-2">
          <CustomAlert
            title="Contract.bank_checkout_pending"
            alertType={ALERT_TYPE.INFO}
            buttonType={{
              type: BUTTON_TYPE.NONE,
            }}
            icon={faUserClock}
          />
        </section>
        <section className="p-6 rounded-lg shadow bg-white ">
          <h2 className="text-3xl font-medium">${job.price.toFixed(2)}</h2>
          <div className="grid grid-cols-5  my-4 gap-y-2 text-sm">
            <div className="col-span-1 flex flex-col gap-2">
              <p className="text-gray-500">
                <Trans>RecomQuestions.to</Trans>
              </p>
              <p className="text-gray-500">
                <Trans>Contract.from</Trans>
              </p>
            </div>
            <div className="col-span-4 flex flex-col gap-2">
              <p className="font-light">{kolName}</p>

              <p className="font-light">{customerCompanyName}</p>
              <hr />
              <a
                href="https://look4kol.com/hk/blog/terms"
                target="_blank"
                className="text-gray-500 font-medium cursor-pointer">
                <Trans>CheckoutForm.view-terms</Trans>{" "}
                <FontAwesomeIcon icon={faChevronRight} />
              </a>
            </div>
          </div>
        </section>
      </div>
    )
  }
  return (
    <div>
      <div>Loading...</div>
    </div>
  )
}
export default CheckOutForm
