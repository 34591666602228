import React, { useEffect, useState } from "react"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logoFull from "../../Assets/logo_top_nobg.png"
import { InvoiceDetails } from "../../Model/Invoice"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReactQuillDisplayCss from "../../Utils/ReactQuillDisplay.module.css"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { isAuth } from "../../Utils/FirebaseUtils"
import { getKOL } from "../../HelpingFunction/KOL/kolDBHelper"
import { Job } from "../../Model/Job"
import { getJob } from "../../HelpingFunction/Job/JobDBHelper"
import { Trans } from "react-i18next"

const InvoiceView = ({
  invoiceDetails,
}: {
  invoiceDetails: InvoiceDetails
}) => {
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const job: Job = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomJob
  })
  const today = new Date()
  const isCreateInvoicePage =
    window.location.pathname.includes("create-invoice")

  const [kolDetails, setKOLDetails] = useState<{
    name: string
    email: string
  }>({
    name: "",
    email: "",
  })

  useEffect(() => {
    getKOLDetails()
  }, [])
  useEffect(() => {
    getKOLDetails()
  }, [auth])
  const getKOLDetails = async () => {
    if (isCreateInvoicePage) {
      if (isAuth(auth)) {
        const k = await getKOL(auth.uid)
        if (k.success) {
          console.log(k)
          setKOLDetails({
            ...kolDetails,
            name: k.data.userName,
            email: k.data.email,
          })
        }
      }
    } else {
      const j = await getJob(job.id)
      if (j.success) {
        await getKOL(j.job.KOLID).then((res) => {
          if (res.success) {
            setKOLDetails({
              ...kolDetails,
              name: res.data.userName,
              email: res.data.email,
            })
          }
        })
      }
    }
  }

  return (
    <div className="bg-white p-6 shadow-xl">
      <div className="flex flex-row justify-between mb-12">
        <section>
          <p className="lg:text-base text-sm font-bold">
            <Trans>InvoiceView.kol-detail</Trans>
          </p>

          <div className="text-sm font-light my-2">
            <p>{kolDetails.name}</p>
            <p>{kolDetails.email}</p>
          </div>
        </section>
        <section>
          <img className="lg:w-28 w-22" src={logoFull} />
        </section>
      </div>
      <div className="flex flex-row lg:gap-4 gap-8 justify-between">
        <section>
          <p className="lg:text-base text-sm font-bold">
            <Trans>InvoiceView.bill-to</Trans>
          </p>
          <div className="text-sm font-light my-2">
            <p>HappyJai Limited</p>
            <p>customer@look4kol.com</p>
          </div>
        </section>
        <section>
          <p className="lg:text-base text-sm font-bold">
            <Trans>InvoiceView.invoice</Trans> #{" "}
            <span className="text-sm font-light ml-4">{invoiceDetails.id}</span>
          </p>
          <p className="lg:text-base text-sm font-bold">
            <Trans>InvoiceView.invoice-date</Trans>
            <span className="text-sm font-light ml-4">
              {today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate()}
            </span>
          </p>
        </section>
      </div>
      <hr className="my-8 border-black " />
      <div className="flex flex-row justify-between">
        <p className="lg:text-4xl text-2xl font-semibold">
          <Trans>InvoiceView.invoice-total</Trans>
        </p>
        <p className="lg:text-4xl text-2xl font-semibold">
          HK${" "}
          <span className="font-normal">
            {invoiceDetails.price.toLocaleString()}
          </span>
        </p>
      </div>
      <hr className="my-8 border-black " />
      <section className="h-[450px] relative">
        <img
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-20 z-0"
          src={logoFull}
        />
        <p className="lg:text-base text-sm font-bold">
          <Trans>InvoiceView.desc</Trans>
        </p>
        <div className="my-8">
          <p
            dangerouslySetInnerHTML={{
              __html: invoiceDetails?.description as string,
            }}
            className={ReactQuillDisplayCss.ReactQuillDisplay}
          />
        </div>
      </section>
      <section>
        <p className="lg:text-base text-sm font-bold uppercase">
          <Trans>InvoiceView.terms</Trans>
        </p>
        <p className="lg:text-sm text-xs font-bold">
          Copyright © {new Date().getFullYear()} HappyJai Ltd. All Rights
          Reserved.
        </p>
        <p className="lg:text-sm text-xs ">
          <Trans>InvoiceView.payment-due</Trans>
        </p>
      </section>
    </div>
  )
}

export default InvoiceView
