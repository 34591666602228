import React, { useEffect, useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import {
  faArrowAltCircleDown,
  faArrowUpFromBracket,
  faBars,
  faBell as faBellAfter,
  faComment as faCommentAfter,
  faComments,
  faLanguage,
  faLink,
  faSearch,
  faTag,
  faUser,
  faWallet,
} from "@fortawesome/free-solid-svg-icons"
import {
  faAddressCard,
  faBell,
  faComment,
} from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import { isAuth, logout } from "../../Utils/FirebaseUtils"
import { Trans, useTranslation } from "react-i18next"
import { analytics } from "../../Config/firebase"
import HeadingMessageManager from "../../Component/HeadingMessageManager/HeadingMessageManager"
import QuickJobAccess from "./QuickJobAccess/QuickJobAccess"
import { getNumOfUnreadMessage } from "../../HelpingFunction/Member/memberDBHelper"
import { ROLE, UNREAD_MESSAGE_TYPE } from "../../Enum/APP_TYPE"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "./Heading.module.css"
import { logEvent } from "firebase/analytics"
import { getKOL } from "../../HelpingFunction/KOL/kolDBHelper"
import { RegisterPageType } from "../../pages/register"
import { getRole, getUserLang } from "../../Utils/utiltyHelper"
import KOL, { KOLDefault } from "../../Model/KOL"
import { OwnedKOLNavbar } from "../../Component/OwnedKOLComponents/OwnedKOLNavbar"
import { getAuthUID, getQuery, useQuery } from "../../Utils/QueryHelper"

export const HeaderText: React.FC = () => {
  const location = useLocation()

  const { t } = useTranslation()

  const [title, setTitle] = useState<string>("")

  useEffect(() => {
    const path = location.pathname.replace(/\?.*/, "").split("/")

    const res = routeText.find(
      (e) => e.path === (path.length > 2 ? path[2] : "")
    )

    if (res) {
      setTitle(res.i18Text)
    }
  }, [location.pathname])

  const routeText: { i18Text: string; path: string }[] = [
    { i18Text: t("BottomAppBar.homepage"), path: "" },
    { i18Text: t("BottomAppBar.chat"), path: "c" },
    { i18Text: t("BottomAppBar.job"), path: "j" },
    { i18Text: t("BottomAppBar.profile"), path: "p" },
    { i18Text: t("BottomAppBar.kol-pro"), path: "kol-pro" },
    { i18Text: t("BottomAppBar.blog"), path: "blog" },
    { i18Text: t("BottomAppBar.setting"), path: "setting" },
    { i18Text: t("BottomAppBar.search"), path: "s" },
    { i18Text: t("BottomAppBar.recommendation"), path: "recommendation" },
    { i18Text: t("BottomAppBar.tools"), path: "tools" },
    { i18Text: t("BottomAppBar.login"), path: "login" },
    { i18Text: t("BottomAppBar.project"), path: "project" },
    { i18Text: t("CustomerTools.profile"), path: "customer" },
    { i18Text: t("Onboarding.signup"), path: "signup" },
    { i18Text: t("Heading.register-as-kol"), path: "register" },
  ]

  return <div className="font-bold text-lg">{title}</div>
}

const NewHeading: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { i18n } = useTranslation()
  const location = useLocation()

  const params = useSelector((state: any) => {
    return state.QueryManager.params
  })

  const requireUpdate = useSelector((state: any) => {
    return state.QueryManager.forceUpdate
  })

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const HeadingSideBar = useSelector((state: any) => {
    return state.SideBarManager.HeadingSideBar
  })

  const numOfUnreadContract = useSelector((state: any) => {
    return state.MessagingSystem.numOfUnreadContracts
  })

  const numOfUnreadChat = useSelector((state: any) => {
    return state.MessagingSystem.numOfUnreadChats
  })

  const messages = useSelector((state: any) => {
    return state.NotificationManager.msg
  })

  const [role, setRole] = useState<ROLE | "">("")
  const [kol, setKOL] = useState<KOL>(KOLDefault)
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const [dropdownType, setDropdownType] = useState<DropdownType>()

  const viewID = getQuery(params, "viewAs")

  const setNumOfUnreadContract = (i: number): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_NUM_OF_UNREAD_CONTRACT,
      numOfUnread: i,
    })
  }

  const setNumOfUnreadChat = (i: number): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_NUM_OF_UNREAD_CHAT,
      numOfUnread: i,
    })
  }

  const onChangeSideBar = (isOpen: boolean): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SIDEBAR_ACTIVE,
      sideBarName: "HeadingSideBar",
      isOpen: isOpen,
    })
  }

  const returnUpdateStateDefault = () => {
    if (requireUpdate) {
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_FORCE_UPDATE,
        data: false,
      })
    }
  }

  enum DropdownType {
    CHAT,
    NOTI,
    OWNED,
    USER,
  }

  useEffect(() => {
    setTimeout(() => {
      if (isAuth(auth)) {
        const getRoleFn = async () => {
          const role = await getRole()
          setRole(role)
        }

        getRoleFn()
        hasUnReadMessage()
        getKOL(getAuthUID(params)).then((res) => {
          if (res.success) {
            setKOL(res.data)
          } else {
            setKOL(KOLDefault)
          }
          returnUpdateStateDefault()
        })
      }
    }, 500)
  }, [auth, viewID, requireUpdate])

  useEffect(() => {
    if (isAuth(auth)) {
      hasUnReadMessage()
    }
  }, [messages, viewID])

  const hasUnReadMessage = () => {
    getNumOfUnreadMessage(
      getAuthUID(params),
      UNREAD_MESSAGE_TYPE.JUST_CONTRACT
    ).then((res) => {
      if (res.success) {
        setNumOfUnreadContract(res.numOfUnread)
      }
    })
    getNumOfUnreadMessage(
      getAuthUID(params),
      UNREAD_MESSAGE_TYPE.JUST_CHAT
    ).then((res) => {
      if (res.success) {
        setNumOfUnreadChat(res.numOfUnread)
      }
    })
  }

  useEffect(() => {
    if (dropdownOpen) {
      window.document
        .getElementById("mainContainer")
        ?.addEventListener("click", () => {
          if (dropdownOpen) {
            setDropdownOpen(false)
            setDropdownType(undefined)
          }
        })
    }
  }, [dropdownOpen])

  const openDropdown = (type: DropdownType) => {
    if (dropdownType === type && dropdownOpen) {
      setDropdownOpen(false)
    } else if (dropdownType !== type && dropdownOpen) {
      setDropdownType(type)
    } else {
      setDropdownOpen(true)
      setDropdownType(type)
    }
  }

  const isSearchPage = location.pathname.includes("/s")

  const isSpecialPage =
    window.location.pathname.includes("/signup") ||
    window.location.pathname.includes("/verify-phone")
  const isKOLAndAgency = role === ROLE.KOL || viewID
  const customerAccountOnly = role === ROLE.CUSTOMER && !viewID

  return (
    <>
      <div className="navbar bg-base-100 border-b w-screen p-0">
        <div className="navbar-start h-16">
          {/*mobile dropdown*/}
          <button
            className="btn btn-ghost md:hidden"
            onClick={() => onChangeSideBar(!HeadingSideBar)}>
            <FontAwesomeIcon icon={faBars} />
          </button>

          <div className="mx-2 w-20"></div>

          {/*ownedKOL*/}

          {/*left quick access*/}
          <ul className="flex menu menu-horizontal p-0">
            {role !== ROLE.KOL && (
              <a
                target={"_blank"}
                href="https://calendly.com/look4kol/look4kol">
                <button
                  id={"Heading_Marketing"}
                  className="hidden lg:inline-block my-auto btn btn-sm btn-primary rounded-full h-12 mx-2 border-none gap-2">
                  <Trans>Heading.marketing</Trans>
                </button>
              </a>
            )}
          </ul>
        </div>

        <div className="navbar-center">
          <HeaderText />
        </div>

        <div className="navbar-end">
          {!isAuth(auth) && (
            <button
              id={"Heading_RegisterAsKOLNav"}
              className="btn btn-primary rounded-full normal-case w-40"
              onClick={() => {
                localStorage.setItem(
                  "redirectObj",
                  JSON.stringify(RegisterPageType.KOL)
                )
                history.push({
                  pathname:
                    "/" +
                    (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
                    "/register",
                })
                logEvent(analytics, "homepage_CTA", {
                  buttonName: "Header - Register as KOL",
                })
              }}>
              <Trans>Heading.register-as-kol</Trans>
            </button>
          )}

          <div className="mx-2 border-r-2 border-gray-200 inline-block h-10" />
          {/*job*/}
          {isAuth(auth) && !isSpecialPage && (
            <div className="dropdown dropdown-end mx-1 md:mx-2 h-12">
              <label
                onClick={() => {
                  openDropdown(DropdownType.CHAT)
                  HeadingSideBar && onChangeSideBar(!HeadingSideBar)
                }}
                id={"Heading_CurrentJob"}
                className="btn btn-ghost btn-circle"
                tabIndex={3}>
                <div className="indicator">
                  <FontAwesomeIcon
                    icon={
                      dropdownOpen && dropdownType === DropdownType.CHAT
                        ? faCommentAfter
                        : faComment
                    }
                    className={[
                      numOfUnreadChat > 0
                        ? classes.NotiRequest
                        : classes.HeadingIconNavItem,
                      "text-lg text-gray-500",
                    ].join(" ")}
                  />
                  {numOfUnreadChat > 0 && (
                    <span
                      className="badge badge-xs indicator-item"
                      style={{
                        backgroundColor: "rgb(225 125 114)",
                        border: "solid 1px white",
                      }}></span>
                  )}
                </div>
              </label>
              {dropdownType === DropdownType.CHAT && dropdownOpen && (
                <div
                  tabIndex={3}
                  className="menu menu-compact dropdown-content mt-3 p-2 gap-1 shadow bg-base-100 rounded-box w-80 h-116">
                  <QuickJobAccess />
                </div>
              )}
            </div>
          )}

          {/*noti*/}
          {isAuth(auth) && !isSpecialPage && (
            <div className="hidden md:inline-block dropdown dropdown-end mx-1 md:mx-2">
              <label
                onClick={() => openDropdown(DropdownType.NOTI)}
                id={"Heading_NotificationBell"}
                className="btn btn-ghost btn-circle"
                tabIndex={1}>
                <div className="indicator">
                  <FontAwesomeIcon
                    icon={
                      dropdownOpen && dropdownType === DropdownType.NOTI
                        ? faBellAfter
                        : faBell
                    }
                    className={[
                      numOfUnreadContract > 0
                        ? classes.NotiRequest
                        : classes.HeadingIconNavItem,
                      "text-lg text-gray-500",
                    ].join(" ")}
                  />
                  {numOfUnreadContract > 0 && (
                    <span
                      className="badge badge-xs indicator-item"
                      style={{
                        backgroundColor: "rgb(225 125 114)",
                        border: "solid 1px white",
                      }}></span>
                  )}
                </div>
              </label>
              {dropdownType === DropdownType.NOTI && dropdownOpen && (
                <div
                  tabIndex={1}
                  className="menu menu-compact dropdown-content mt-3 p-2 gap-1 shadow bg-base-100 rounded-box w-80 h-116">
                  <HeadingMessageManager
                    isPopup={true}
                    setIsMessageDropDownOpen={() => {
                      console.log("hi")
                    }}
                  />
                </div>
              )}
            </div>
          )}

          {/*user icon*/}
          <div className="dropdown dropdown-end mx-1 md:mx-2 h-12">
            <label
              onClick={() => {
                openDropdown(DropdownType.USER)
                HeadingSideBar && onChangeSideBar(!HeadingSideBar)
              }}
              tabIndex={2}
              className="overflow-hidden btn btn-ghost btn-circle avatar">
              <div
                id={"Heading_ProfileMenu"}
                className={
                  "overflow-hidden h-10 w-10 bg-red-50 rounded-full flex flex-shrink-0 justify-center items-center text-red-900 text-2xl font-mono pt-0.5"
                }>
                {kol.avatarUrl ? (
                  <img src={kol.avatarUrl} className="h-10 w-10 rounded-full" />
                ) : (
                  <FontAwesomeIcon icon={faUser} className="text-red-200" />
                )}
              </div>
            </label>
            {dropdownOpen && dropdownType === DropdownType.USER && (
              <ul
                tabIndex={2}
                className="menu menu-compact dropdown-content mt-3 p-2 gap-1 shadow bg-base-100 rounded-box w-72">
                <li>{auth ? auth.displayName : ""}</li>

                {/* customer search tools*/}
                {isAuth(auth) && (
                  <Link
                    to={
                      isKOLAndAgency
                        ? "/" +
                          getUserLang() +
                          "/p/" +
                          (kol.userName ? kol.userName : kol.id)
                        : "/" + getUserLang() + "/customer/job"
                    }>
                    <li
                      id={"Heading_Profile_Badge"}
                      className="grid focus:text-white text-gray-500 px-4 my-2 gap-1"
                      style={{ gridTemplateColumns: "50px auto" }}>
                      <div>
                        {kol.avatarUrl ? (
                          <img
                            src={kol.avatarUrl}
                            className="h-10 w-10 rounded-full "
                          />
                        ) : (
                          <div className="h-10 w-10 rounded-full text-center bg-red-50 py-1.5">
                            <FontAwesomeIcon
                              icon={faUser}
                              className="text-red-200 my-auto text-lg"
                            />
                          </div>
                        )}
                      </div>
                      <div>
                        <div className="font-bold text-gray-700">User</div>
                        <span className="block text-xs text-gray-500">
                          {viewID ? kol.email : auth.email}
                        </span>
                      </div>
                    </li>
                    <hr className="my-1" />
                  </Link>
                )}

                {isAuth(auth) && isKOLAndAgency && !isSpecialPage && (
                  <li
                    id={"Heading_KOLPro"}
                    className="focus:text-white text-gray-500">
                    <Link to={"/" + getUserLang() + "/kol-pro"}>
                      <FontAwesomeIcon
                        icon={faTag}
                        className="w-8  mr-4 text-lg text-left"
                      />
                      <div>
                        <span className="block badge badge-warning">
                          Look4KOL <Trans>BottomAppBar.kol-pro</Trans>
                        </span>
                      </div>
                    </Link>
                  </li>
                )}

                {/* customer search tools*/}
                {isAuth(auth) && customerAccountOnly && (
                  <li
                    id={"Heading_KOLProfile"}
                    className="focus:text-white text-gray-500">
                    <Link to={"/" + getUserLang() + "/customer/profile"}>
                      <FontAwesomeIcon
                        icon={faAddressCard}
                        className="w-8  mr-4 text-lg text-left"
                      />
                      <Trans>Heading.customer-info</Trans>
                    </Link>
                  </li>
                )}

                {isAuth(auth) && isKOLAndAgency && !isSpecialPage && (
                  <li
                    id={"Heading_KOLTools"}
                    className="focus:text-white text-gray-500">
                    <Link to={"/" + getUserLang() + "/link/" + kol.userName}>
                      <FontAwesomeIcon
                        icon={faLink}
                        className="w-8  mr-4 text-lg text-left"
                      />
                      <Trans>Heading.link-tree</Trans>
                    </Link>
                  </li>
                )}

                {isAuth(auth) && customerAccountOnly && (
                  <li
                    id={"Heading_marketing"}
                    className="focus:text-white text-gray-500">
                    <a
                      href={"https://calendly.com/look4kol/look4kol"}
                      target="_blank">
                      <FontAwesomeIcon
                        icon={faComments}
                        className="w-8  mr-4 text-lg text-left"
                      />
                      <Trans>AppSetting.marketing</Trans>
                    </a>
                  </li>
                )}

                {/* kol wallet */}
                {isAuth(auth) && isKOLAndAgency && !isSpecialPage && (
                  <li
                    id={"Heading_KOLTools"}
                    className="focus:text-white text-gray-500">
                    <Link to={"/" + getUserLang() + "/kol/wallet"}>
                      <FontAwesomeIcon
                        icon={faWallet}
                        className="w-8  mr-4 text-lg text-left"
                      />
                      <Trans>Heading.tools</Trans>
                    </Link>
                  </li>
                )}
                <li
                  id={"Heading_LangChange"}
                  className="focus:text-white text-gray-500">
                  {/*change lang button*/}
                  <a
                    onClick={(e: any) => {
                      if (localStorage.getItem("i18n-lang") === "en") {
                        i18n.changeLanguage("hk")
                        localStorage.setItem("i18n-lang", "hk")
                        if (
                          window.location.pathname !== "" &&
                          window.location.pathname.split("/en")[0] !==
                            window.location.pathname
                        ) {
                          history.push(
                            "/hk" + window.location.pathname.split("/en")[1]
                          )
                        }
                      } else {
                        i18n.changeLanguage("en")
                        localStorage.setItem("i18n-lang", "en")

                        if (
                          window.location.pathname !== "" &&
                          window.location.pathname.split("/hk")[0] !==
                            window.location.pathname
                        ) {
                          history.push(
                            "/en" + window.location.pathname.split("/hk")[1]
                          )
                        }
                      }
                    }}>
                    <FontAwesomeIcon
                      icon={faLanguage}
                      className=" w-8  mr-4 text-lg text-left"
                    />
                    {localStorage.getItem("i18n-lang") === "en" ? (
                      <Trans>Language.lang-hk</Trans>
                    ) : (
                      <Trans>Language.lang-en</Trans>
                    )}
                  </a>
                </li>

                <hr className="my-1" />

                <li
                  id={"Heading_Logout"}
                  className="focus:text-white text-gray-500 ">
                  {isAuth(auth) ? (
                    <a
                      onClick={() => {
                        logout()
                        dispatch({
                          type: SUPPORTED_REDUX_FUNCTIONS.SET_SIGN_UP_KOL,
                          forceSignUp: false,
                        })

                        let params = new URLSearchParams("")
                        if (location.search.includes("viewAs")) {
                          params.append("returnToCustomer", "true")
                        }
                        history.push({
                          pathname: "/" + getUserLang() + "/login",
                          search: params.toString(),
                        })
                      }}>
                      <FontAwesomeIcon
                        icon={faArrowUpFromBracket}
                        className=" w-8  mr-4 text-lg text-left"
                      />
                      <Trans>Heading.logout</Trans>
                    </a>
                  ) : (
                    <a
                      onClick={() => {
                        history.push(
                          "/" +
                            (localStorage.getItem("i18n-lang") === "en"
                              ? "en"
                              : "hk") +
                            "/login"
                        )
                      }}>
                      <FontAwesomeIcon
                        icon={faArrowAltCircleDown}
                        className=" w-8  mr-4 text-lg text-left"
                      />
                      <Trans>Heading.login</Trans>
                    </a>
                  )}
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default NewHeading
