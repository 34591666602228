import {
  Job,
  JOB_LIST_CONDITION_TYPE,
  JOB_TYPE,
  JobCustomerInfo,
  PAYMENT_METHOD,
  ReferralJob,
} from "../../Model/Job"
import { isChatroomExists } from "../Chatroom/chatroomDBHelper"
import {
  collection,
  doc,
  documentId,
  or,
  and,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
  getAggregateFromServer,
  sum,
  Timestamp,
} from "firebase/firestore"
import firebaseApp from "../../Config/firebase"
import { v4 as uuidv4 } from "uuid"
import { createNewJob } from "../../Utils/JobHelpingFunction"
import { returnIdTokenResult } from "../../Utils/FirebaseUtils"
import { getAPIPath } from "../../Utils/HelpingFunction"
import {
  getDirectionSign,
  PAGINATION_DIRECTION,
  SORT_OPTION,
  sortDirectionFn,
} from "../../Utils/TableHelperFunction"
import { KOLCombineWithAvatar } from "../../Model/KOLCombine"
import {
  getCustomerCompanyName,
  getCustomers,
} from "../Customer/customerDBHelper"
import { getKOLs, getKOLUserName } from "../KOL/kolDBHelper"
import { createNewProduct } from "../EditKOLProfile/EditKOLProfile"
import { Balance } from "../../Model/Bank"
import { ROLE } from "../../Enum/APP_TYPE"

const db = getFirestore(firebaseApp)
const refAward = parseInt(process.env.REACT_APP_REFERRAL_AWARD as string) / 100

/**
 * Get Job snapstop item by jobID
 * @param jobID - Job ID
 */

const getJobSnapshot = (
  jobID: string
): Promise<
  | {
      success: true
      job: Job
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    onSnapshot(doc(db, "Job", jobID), (doc) => {
      if (doc.exists()) {
        return resolve({
          success: true,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          job: {
            id: doc.id,
            ...doc.data(),
          } as Job,
        })
      }
      return resolve({
        success: false,
      })
    })
  })
}

/**
 * Get Job item by jobID
 * @param jobID - Job ID
 */

const getJob = (
  jobID: string
): Promise<
  | {
      success: true
      job: Job
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "Job", jobID))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            job: {
              id: doc.id,
              ...doc.data(),
            } as Job,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => {
        return resolve({
          success: false,
        })
      })
  })
}

const getReferredKOLJob = (
  condition: {
    startDate: Date
    endDate: Date
  },
  uid: string
): Promise<
  { success: true; data: ReferralJob[]; hasMore: boolean } | { success: false }
> => {
  return new Promise(async (resolve) => {
    let limitNum = 100

    let dbRef: any = collection(db, "Job")
    dbRef = query(
      dbRef,
      and(
        where("referralUser", "==", uid),
        where("isFinish", "==", true),
        where("lastUpdate", ">=", condition.startDate),
        where("lastUpdate", "<=", condition.endDate),
        or(
          where("paymentMethod", "==", PAYMENT_METHOD.BANK),
          where("paymentMethod", "==", PAYMENT_METHOD.CARD)
        )
      )
    )
    await getDocs(query(dbRef, limit(limitNum)))
      .then(async (docs: any) => {
        if (!docs.empty) {
          const allReferralJobs: ReferralJob[] = []
          docs.forEach((doc: any) => {
            const data = doc.data()
            allReferralJobs.push({
              id: doc.id,
              KOLID: data.KOLID,
              amount: data.payoutAmount * refAward,
              price: data.price * refAward,
              lastUpdate: data.lastUpdate,
              paymentMethod: data.paymentMethod
                ? data.paymentMethod
                : PAYMENT_METHOD.BANK,
            } as ReferralJob)
          })
          return resolve({
            success: true,
            data: allReferralJobs,
            hasMore: docs.length === limitNum,
          })
        }
        return resolve({ success: true, data: [], hasMore: false })
      })
      .catch((err) => {
        console.log(err)
        return resolve({ success: false })
      })
  })
}

const getReferredKOLJobServer = (
  condition: {
    startDate: Date
    endDate: Date
  },
  uid: string
): Promise<
  { success: true; data: ReferralJob[]; hasMore: boolean } | { success: false }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      //send payout request
      await fetch(getAPIPath("/api/kol/" + uid + "/referral-job"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          startDate: condition.startDate,
          endDate: condition.endDate,
        }),
      })
        .then((res) => res.json())
        .then(async (finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: finalResponse.success,
              data: finalResponse.data,
            })
          }
          return resolve({
            success: finalResponse.success,
          })
        })
    })
  })
}

/**
 * The pending job is the job which is signed a contract but not finish yet
 * @param condition
 * @param uid
 */
const getKOLWalletJob = (
  condition: {
    startDate: Date
    endDate: Date
  },
  uid: string
): Promise<
  { success: true; data: Job[]; hasMore: boolean } | { success: false }
> => {
  return new Promise(async (resolve) => {
    let limitNum = 100
    let dbRef: any = collection(db, "Job")

    dbRef = query(
      dbRef,
      where("KOLID", "==", uid),
      where("isFinish", "==", true),
      where("lastUpdate", ">=", condition.startDate),
      where("lastUpdate", "<", condition.endDate)
    )
    await getDocs(query(dbRef, limit(limitNum)))
      .then(async (docs: any) => {
        if (!docs.empty) {
          let allJobs: Job[] = []
          docs.forEach((doc: any) => {
            allJobs.push({
              id: doc.id,
              ...doc.data(),
            } as Job)
          })
          return resolve({
            success: true,
            data: allJobs,
            hasMore: docs.length === limitNum,
          })
        }
        return resolve({ success: true, data: [], hasMore: false })
      })
      .catch((err) => {
        console.log(err)
        return resolve({ success: false })
      })
  })
}

/**
 * The all finished job is the job by this kol
 * @param uid
 */
const getKOLJobSum = (uid: string): Promise<Balance> => {
  return new Promise(async (resolve) => {
    const currentDate = new Date()
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    )
    const firstDayOfMonthTimestamp = Timestamp.fromDate(firstDayOfMonth)
    const currentDateTimestamp = Timestamp.fromDate(currentDate)

    const coll: any = collection(db, "Job")
    const q = query(
      coll,
      and(
        where("KOLID", "==", uid),
        where("isFinish", "==", true),
        where("payoutDate", ">=", firstDayOfMonthTimestamp),
        where("payoutDate", "<=", currentDateTimestamp)
      )
    )
    const snapshot = await getAggregateFromServer(q, {
      totalAmount: sum("payoutAmount"),
    })
    return resolve({ currency: "hkd", amount: snapshot.data().totalAmount })
  })
}

/**
 * The pending job is the job which is signed a contract but not finish yet
 * @param uid
 */
const getKOLPendingJobSum = (uid: string): Promise<Balance> => {
  return new Promise(async (resolve) => {
    const coll: any = collection(db, "Job")
    const q = query(
      coll,
      and(
        where("KOLID", "==", uid),
        where("isFinish", "==", false),
        or(
          where("paymentMethod", "==", PAYMENT_METHOD.BANK),
          where("paymentMethod", "==", PAYMENT_METHOD.CARD)
        )
      )
    )
    const snapshot = await getAggregateFromServer(q, {
      totalAmount: sum("price"),
    })
    return resolve({ currency: "hkd", amount: snapshot.data().totalAmount })
  })
}

/**
 * The pending job is the job which is signed a contract but not finish yet
 * @param uid
 */
const getKOLReferralJobSum = (uid: string): Promise<Balance> => {
  return new Promise(async (resolve) => {
    const currentDate = new Date()
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    )
    const firstDayOfMonthTimestamp = Timestamp.fromDate(firstDayOfMonth)
    const currentDateTimestamp = Timestamp.fromDate(currentDate)
    const coll: any = collection(db, "Job")
    const q = query(
      coll,
      and(
        where("referralUser", "==", uid),
        where("isFinish", "==", true),
        where("payoutDate", ">=", firstDayOfMonthTimestamp),
        where("payoutDate", "<=", currentDateTimestamp),
        or(
          where("paymentMethod", "==", PAYMENT_METHOD.BANK),
          where("paymentMethod", "==", PAYMENT_METHOD.CARD)
        )
      )
    )
    const snapshot = await getAggregateFromServer(q, {
      totalAmount: sum("payoutAmount"),
    })

    return resolve({
      currency: "hkd",
      amount: snapshot.data().totalAmount * refAward,
    })
  })
}

/**
 * The pending job is the job which is signed a contract but not finish yet
 * @param uid
 */
const getKOLReferralLifetimeSum = (uid: string): Promise<Balance> => {
  return new Promise(async (resolve) => {
    const coll: any = collection(db, "Job")
    const q = query(
      coll,
      and(
        where("referralUser", "==", uid),
        where("isFinish", "==", true),

        or(
          where("paymentMethod", "==", PAYMENT_METHOD.BANK),
          where("paymentMethod", "==", PAYMENT_METHOD.CARD)
        )
      )
    )
    const snapshot = await getAggregateFromServer(q, {
      totalAmount: sum("payoutAmount"),
    })

    return resolve({
      currency: "hkd",
      amount: snapshot.data().totalAmount * refAward,
    })
  })
}

export interface jobListCondition {
  type?: JOB_LIST_CONDITION_TYPE
  uid: string | ""
  customerID: string | ""
  kolID: string | ""
  completePayout: boolean | ""
  accepted: boolean | ""
  paid: boolean | ""
  quotationDetail: boolean | string | ""
  paginationData?: any | ""
  paginationDirection: PAGINATION_DIRECTION | ""
  jobID?: string
  projectID?: string
  draft?: boolean
  priceID: boolean | ""
  isConfirmQuotation: boolean | ""
}

const getJobList = (
  condition: jobListCondition,
  uid: string,
  orderByOption: SORT_OPTION,
  setLimit: number
): Promise<{
  success: true
  data: Job[]
  hasMore: boolean
}> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "Job")
    if (condition.kolID !== "") {
      dbRef = query(
        dbRef,
        where("participateUID", "in", [
          [condition.customerID, condition.kolID],
        ]),
        where("KOLID", "==", condition.kolID)
      )
    } else if (uid !== "" && uid) {
      if (condition.projectID) {
        dbRef = query(dbRef, where("CustomerID", "==", uid))
        dbRef = query(
          dbRef,
          where("projectID", "array-contains", condition.projectID)
        )
      } else {
        dbRef = query(dbRef, where("participateUID", "array-contains", uid))
        if (condition.draft) {
          dbRef = query(dbRef, where("CustomerID", "==", ""))
        }

        // arr.push(["participateUID", "array-contains", uid])
      }
    }

    if (condition.completePayout !== "") {
      dbRef = query(
        dbRef,
        where("completePayout", "==", condition.completePayout)
      )
    }

    if (condition.accepted !== "") {
      dbRef = query(dbRef, where("accepted", "==", condition.accepted))
    }

    if (condition.paid !== "") {
      dbRef = query(dbRef, where("paid", "==", condition.paid))
    }

    if (condition.isConfirmQuotation !== "") {
      dbRef = query(
        dbRef,
        where("isConfirmQuotation", "==", condition.isConfirmQuotation)
      )
    }

    if (condition.paginationData !== "" && orderByOption.direction !== false) {
      dbRef = query(
        dbRef,
        where(
          orderByOption.fieldName,
          getDirectionSign(
            orderByOption.direction,
            condition.paginationDirection
          ),
          condition.paginationData
        )
      )
    }
    //For reversing the order of data depending on the direction
    // (so that we can get the closest datasets)
    const reverseDirection = sortDirectionFn(orderByOption.direction)

    const orderByDirection =
      condition.paginationDirection === PAGINATION_DIRECTION.PREV
        ? reverseDirection
        : orderByOption.direction

    if (condition.quotationDetail && orderByDirection) {
      dbRef = query(dbRef, orderBy(orderByOption.fieldName, orderByDirection))

      // arr.push([orderByOption.fieldName, orderByDirection])
    } else if (condition.quotationDetail === false && orderByDirection) {
      dbRef = query(
        dbRef,
        where("quotationDetail", "==", ""),
        //orderBy orderByOption.fieldName will be done first before quotationDetail to avoid order error
        orderBy(orderByOption.fieldName, orderByDirection)
      )

      // arr.push([orderByOption.fieldName, orderByDirection])
    } else if (condition.priceID && orderByDirection) {
      dbRef = query(
        dbRef,
        where("priceID", "!=", ""),
        orderBy("priceID", "desc"),
        orderBy(orderByOption.fieldName, orderByDirection)
      )
    } else if (condition.priceID === false && orderByDirection) {
      dbRef = query(
        dbRef,
        where("priceID", "==", ""),
        orderBy(orderByOption.fieldName, orderByDirection)
      )
    } else if (orderByDirection) {
      dbRef = query(dbRef, orderBy(orderByOption.fieldName, orderByDirection))

      // arr.push([orderByOption.fieldName, orderByDirection])
    }
    // console.log(arr)
    await getDocs(query(dbRef, limit(setLimit)))
      .then(async (docs: any) => {
        let allPromise: Promise<any>[] = []

        if (!docs.empty) {
          let filteredLimit = 0
          if (condition.kolID !== "") {
            //if getting same Chatroom Contract
            await isChatroomExists(
              docs.docs[0].data().CustomerID[0],
              docs.docs[0].data().KOLID[0]
            ).then((result) => {
              docs.forEach((doc: any) => {
                const quotationFilterCheck =
                  (condition.quotationDetail &&
                    doc.data().quotationDetail !== "") ||
                  !condition.quotationDetail

                if (filteredLimit < 10 && quotationFilterCheck) {
                  allPromise.push(
                    new Promise(async (resolve1) => {
                      //find job chatroom
                      if (result.success) {
                        resolve1({
                          id: doc.id,
                          chatroomID: result.chatroomID,
                          ...doc.data(),
                        })
                      } else {
                        resolve1({
                          id: doc.id,
                          ...doc.data(),
                        })
                      }
                    })
                  )
                  filteredLimit += 1
                }
              })
            })

            Promise.all(allPromise).then((res) => {
              //docs.docs.length is not the same as filteredLimit when quotationDetail filter is on

              const hasMoreConvertor = condition.quotationDetail
                ? filteredLimit === setLimit - 1
                : docs.docs.length === setLimit

              return resolve({
                success: true,
                data: res,
                hasMore: hasMoreConvertor,
              })
            })
          } else {
            //Get all Contracts
            docs.forEach((doc: any) => {
              const quotationFilterCheck =
                (condition.quotationDetail &&
                  doc.data().quotationDetail !== "") ||
                !condition.quotationDetail

              if (filteredLimit < 10 && quotationFilterCheck) {
                allPromise.push(
                  new Promise(async (resolve1) => {
                    resolve1({
                      id: doc.id,
                      ...doc.data(),
                    })
                  })
                )
                filteredLimit += 1
              }
            })
            Promise.all(allPromise).then((res) => {
              //docs.docs.length is not the same as filteredLimit when quotationDetail filter is on
              const hasMoreConvertor = condition.quotationDetail
                ? filteredLimit === setLimit - 1
                : docs.docs.length === setLimit

              return resolve({
                success: true,
                data: res,
                hasMore: hasMoreConvertor,
              })
            })
          }
        } else {
          return resolve({
            success: true,
            data: [],
            hasMore: false,
          })
        }
      })
      .catch((err: any) => console.log(err))
  })
}

const getProjectJobList = (
  jobIds: string[]
): Promise<{ success: true; data: Job[] } | { success: false }> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "Job")

    dbRef = query(dbRef, where(documentId(), "in", jobIds))

    await getDocs(query(dbRef))
      .then((docs: any) => {
        if (!docs.empty) {
          let projectJobs: any = []
          docs.forEach((doc: any) => {
            projectJobs.push({ id: doc.id, ...doc.data() })
          })

          return resolve({
            success: true,
            data: projectJobs,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

export interface QuotationObj {
  ServicePlanID: string
  //will be the job name
  ServicePlanName: string
  quotationDetail: string
  price?: number
  isConfirmQuotation?: boolean
  accepted?: boolean
}

export const QuotationObjDefault = {
  ServicePlanID: "",
  //will be the job name
  ServicePlanName: "",
  quotationDetail: "",
}

const createNewContract = (
  kolID: string,
  uid: string,
  quotationObj?: QuotationObj
): Promise<
  | {
      success: true
      jobID: string
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    // job id
    const projectID = uuidv4()

    const JobObject = {
      CustomerID: uid,
      KOLID: kolID,
      endDate: new Date(),
      isFinish: false,
      // the order cannot be changed !!
      participateUID: [uid, kolID],
      price: 0,
      startDate: new Date(),
      createDate: new Date(),
      priceID: "",
      customTitle: [],
      isSubmitQuotation: true,
      ...(quotationObj ? quotationObj : QuotationObjDefault),
      //    more detail will be added by cloud functions
    }

    await createNewJob(projectID, JobObject).then((result) => {
      if (result.success) {
        return resolve({
          success: true,
          jobID: projectID,
        })
      }
      return resolve({
        success: false,
      })
    })
  })
}

const createDraftContract = (
  // using for checking isAgency
  uid: string,
  kolUID: string,
  quotationObj: QuotationObj
): Promise<{ success: true; jobID: string } | { success: false }> => {
  return new Promise(async (resolve) => {
    const newJobID = uuidv4()
    const agencyID = uid !== kolUID ? kolUID : ""

    // create product
    let newProduct = {
      id: uuidv4(),
      title: "custom product [job: " + newJobID + "]",
      isVisible: true,
      price: quotationObj.price,
    }

    //get price ID from stripe
    const getState = await createNewProduct(newProduct, kolUID, agencyID)

    if (getState.success) {
      const JobObject = {
        accepted: quotationObj.accepted ? quotationObj.accepted : false,
        CustomerID: "",
        KOLID: kolUID,
        endDate: new Date(),
        isFinish: false,
        // the order cannot be changed !!
        participateUID: ["", kolUID],
        price: quotationObj.price,
        startDate: new Date(),
        priceID: getState.data.newPrice.id,
        customTitle: [],
        isSubmitQuotation: true,
        type: quotationObj.accepted ? JOB_TYPE.INVOICE : JOB_TYPE.CONTRACT,
        ...(quotationObj ? quotationObj : QuotationObjDefault),
        //    more detail will be added by cloud functions
      }

      await createNewJob(newJobID, JobObject).then((result) => {
        if (result.success) {
          return resolve({
            success: true,
            jobID: newJobID,
          })
        }
        return resolve({
          success: false,
        })
      })
    } else {
      return resolve({
        success: false,
      })
    }
  })
}

/* Create Bulk Contracts with different KOLs */

const createBulkContractsAPI = (
  kolIDs: string[],
  uid: string,
  quotationObj?: QuotationObj
): Promise<{ success: true; jobIDList: string[] } | { success: false }> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/job/bulk"), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          kolIDList: kolIDs,
          uid: uid,
          quotationObj: quotationObj,
        }),
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
              jobIDList: finalResponse.data,
            })
          }
          return resolve({
            success: false,
          })
        })
    })
  })
}

const setJobCaseStudyID = (
  jobID: string,
  csID: string
): Promise<{ success: boolean }> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/job/" + jobID + "/case/" + csID), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          csID: csID,
        }),
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({ success: true })
          }
          return resolve({ success: false })
        })
    })
  })
}

const getJobTitle = async (data: Job, uid: string): Promise<string> => {
  if (data.CustomerID !== data.KOLID) {
    switch (uid) {
      case data.KOLID:
        const customerResult = await getCustomerCompanyName(data.CustomerID)
        return customerResult.success ? customerResult.customerCompanyName : ""
      case data.CustomerID:
        const kolResult = await getKOLUserName(data.KOLID)
        return kolResult.success ? kolResult.userName : ""
      default:
        return ""
    }
  }
  return "UNKNOWN"
}

const getOngoingJobCount = (
  uid: string
): Promise<{ success: true; count: number } | { success: false }> => {
  return new Promise(async (resolve) => {
    let dbRef: any = collection(db, "Job")

    if (uid !== "") {
      dbRef = query(dbRef, where("participateUID", "array-contains", uid))
    }

    dbRef = query(dbRef, where("completePayout", "==", false))

    const snapshot = await getCountFromServer(dbRef)

    if (snapshot.data()) {
      return resolve({
        success: true,
        count: snapshot.data().count,
      })
    }
    return resolve({ success: false })
  })
}

const skipContractPayment = (id: string): Promise<{ success: boolean }> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/job/" + id + "/skip"), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({ success: true })
          }
          return resolve({ success: false })
        })
    })
  })
}

const assignCustomerToContract = (
  uid: string,
  jobID: string
): Promise<{ success: boolean }> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/job/" + jobID + "/connect"), {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          customerID: uid,
        }),
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: true,
            })
          }
          return resolve({
            success: false,
          })
        })
    })
  })
}

const addJobCustomerInfo = (
  jobID: string,
  customerInfo: JobCustomerInfo
): Promise<{ success: boolean }> => {
  return new Promise(async (resolve) => {
    await fetch(getAPIPath("/api/job/" + jobID + "/addCustomerInfo"), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: customerInfo.name,
        email: customerInfo.email,
      }),
    })
      .then((res) => res.json())
      .then((finalResponse) => {
        if (finalResponse.success) {
          return resolve({
            success: true,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const returnJobsWithTableName = (
  jobs: Job[],
  role: ROLE
): Promise<{ success: true; data: Job[] } | { success: false }> => {
  return new Promise(async (resolve) => {
    if (Array.isArray(jobs)) {
      let finalJobs: Job[] = []
      if (role === ROLE.KOL) {
        const customerArr = jobs.map((j) => j.CustomerID).filter(Boolean)
        const customerData = await getCustomers(customerArr)
        if (customerData.success) {
          jobs.map((j) => {
            const cust = customerData.data.find(
              (custs) => custs.id === j.CustomerID
            )
            if (cust) {
              finalJobs.push({
                ...j,
                title: cust.companyName,
              })
            } else {
              finalJobs.push({
                ...j,
                title: "",
              })
            }
          })
          return resolve({
            success: true,
            data: finalJobs,
          })
        }
        return resolve({
          success: true,
          data: jobs,
        })
      } else if (role === ROLE.CUSTOMER) {
        const kolArr = jobs.map((j) => j.KOLID)
        const kolData = await getKOLs(kolArr)
        if (kolData.success) {
          jobs.map((j) => {
            const kol = kolData.data.find((kols) => kols.id === j.KOLID)
            if (kol) {
              finalJobs.push({
                ...j,
                title: kol.userName,
              })
            } else {
              finalJobs.push({
                ...j,
                title: "",
              })
            }
          })
          return resolve({
            success: true,
            data: finalJobs,
          })
        }
        return resolve({
          success: true,
          data: jobs,
        })
      }
    }
    return resolve({
      success: false,
    })
  })
}

export {
  getJob,
  getReferredKOLJob,
  getKOLWalletJob,
  getKOLJobSum,
  getKOLPendingJobSum,
  getKOLReferralJobSum,
  getJobSnapshot,
  getJobList,
  createNewContract,
  getProjectJobList,
  createBulkContractsAPI,
  getJobTitle,
  getOngoingJobCount,
  setJobCaseStudyID,
  createDraftContract,
  skipContractPayment,
  assignCustomerToContract,
  addJobCustomerInfo,
  getKOLReferralLifetimeSum,
  returnJobsWithTableName,
}
