export const SUPPORTED_MODAL_TYPE = {
  UPLOAD_FILE_MODAL: "UPLOAD_FILE_MODAL",
  CONTACT_KOL_MODAL: "CONTACT_KOL_MODAL",
  CASE_STUDY_MODAL: "CASE_STUDY_MODAL",

  CHATROOM_SETTING_MODAL: "CHATROOM_SETTING_MODAL",

  NEW_FOLDER_MODAL: "NEW_FOLDER_MODAL",

  APPLY_JOB_APPEAL_MODAL: "APPLY_JOB_APPEAL_MODAL",
  SUBMIT_JOB_FULFILLMENT: "SUBMIT_JOB_FULFILLMENT",

  CONTRACT_DISPLAY_MODAL: "CONTRACT_DISPLAY_MODAL",
  CONTRACT_ACCEPT_MODAL: "CONTRACT_ACCEPT_MODAL",
  CONTRACT_SKIP_PAYMENT_MODAL: "CONTRACT_SKIP_PAYMENT_MODAL",

  VIEW_FOLLOWERS_DETAIL_MODAL: "VIEW_FOLLOWERS_DETAIL_MODAL",
  VIEW_CASE_STUDY_MODAL: "VIEW_CASE_STUDY_MODAL",

  ADMIN_CREATE_CHATROOM_MODAL: "ADMIN_CREATE_CHATROOM_MODAL",
  ADMIN_EDIT_REQUEST_MODAL: "ADMIN_EDIT_REQUEST_MODAL",

  KOL_INFO_PREVIEW_MODAL: "KOL_INFO_PREVIEW_MODAL",

  VIEW_IMAGE_MODAL: "VIEW_IMAGE_MODAL",

  DELETE_MESSAGE_MODAL: "DELETE_MESSAGE_MODAL",

  VIEW_KOL_PERCENTAGE_MODAL: "VIEW_KOL_PERCENTAGE_MODAL",
  LOGIN_MODAL: "LOGIN_MODAL",

  BANK_WITHDRAW_MODAL: "BANK_WITHDRAW_MODAL",
  WALLET_HISTORY_MODAL: "WALLET_HISTORY_MODAL",

  SHARE_MODAL: "SHARE_MODAL",

  SIGNUP_COVER_IMAGE_MODAL: "SIGNUP_COVER_IMAGE_MODAL",

  CREATE_NEW_CONTRACT_MODAL: "CREATE_NEW_CONTRACT_MODAL",
  CREATE_CASE_STUDY_MODAL: "CREATE_CASE_STUDY_MODAL",
  CREATE_DRAFT_CONTRACT_MODAL: "CREATE_DRAFT_CONTRACT_MODAL",
  SHOW_DRAFT_CONTRACT_MODAL: "SHOW_DRAFT_CONTRACT_MODAL",
  ACCEPT_DRAFT_CONTRACT_MODAL: "ACCEPT_DRAFT_CONTRACT_MODAL",
  COPY_DRAFT_CONTRACT_MODAL: "COPY_DRAFT_CONTRACT_MODAL",

  CREATE_DRAFT_QUOTATION_MODAL: "CREATE_DRAFT_QUOTATION_MODAL",

  LOGIN_LINKTREE_MODAL: "LOGIN_LINKTREE_MODAL",
  PROJECT_MANAGEMENT_MODAL: "PROJECT_MANAGEMENT_MODAL",
  PROJECT_CREATE_MOBILE_MODAL: "PROJECT_CREATE_MOBILE_MODAL",
  PROJECT_PAGE_MOBILE_MODAL: "PROJECT_PAGE_MOBILE_MODAL",
  GROUP_QUOTATION_MODAL: "GROUP_QUOTATION_MODAL",
  LOGIN_ROLE_MODAL: "LOGIN_ROLE_MODAL",

  ADD_TO_COMPARE_GROUP_MODAL: "ADD_TO_COMPARE_GROUP_MODAL",
  CREATE_COMPARE_GROUP_MODAL: "CREATE_COMPARE_GROUP_MODAL",
  COMPARE_GROUP_TABLE_MODAL: "COMPARE_GROUP_TABLE_MODAL",

  MOBILE_HELP_DOC_MODAL: "MOBILE_HELP_DOC_MODAL",

  SEND_RECOM_CONTRACT_MODAL: "SEND_RECOM_CONTRACT_MODAL",
  VIEW_CONTRACT_EXAMPLE_MODAL: "VIEW_CONTRACT_EXAMPLE_MODAL",

  CASE_STUDY_IG_MODAL: "CASE_STUDY_IG_MODAL",

  SHOW_ASSISTANT_KOL_TABLE_MODAL: "SHOW_ASSISTANT_KOL_TABLE_MODAL",
  ADD_CHATGPT_CHAT_MODAL: "ADD_CHATGPT_CHAT_MODAL",
  CHATGPT_SETTING_MODAL: "CHATGPT_SETTING_MODAL",
  ASSISTANT_TUTORIAL_MOBILE_MODAL: "ASSISTANT_TUTORIAL_MOBILE_MODAL",

  OWNED_KOL_SETTING_MODAL: "OWNED_KOL_SETTING_MODAL",

  REGISTER_CUSTOMER_MODAL: "REGISTER_CUSTOMER_MODAL",

  VERIFY_PHONE_MODAL: "VERIFY_PHONE_MODAL",

  ACCOUNT_DELETION_MODAL: "ACCOUNT_DELETION_MODAL",

  SIGNUP_TUTORIAL_MODAL: "SIGNUP_TUTORIAL_MODAL",

  INVOICE_PREVIEW_MODAL: "INVOICE_PREVIEW_MODAL",

  UPDATE_KOL_USERNAME_MODAL: "UPDATE_KOL_USERNAME_MODAL",

  KOL_MISSION_TUTORIAL_MODAL: "KOL_MISSION_TUTORIAL_MODAL",

  PREVIEW_SELECTED_KOL_MODAL: "PREVIEW_SELECTED_KOL_MODAL",

  KOL_CONTRACT_SELECTION_MODAL: "KOL_CONTRACT_SELECTION_MODAL",
}
