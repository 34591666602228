export const routerPage = {
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0.5,
    x: "-100vw",
  },
}

export const routerPageDesktop = {
  in: {
    opacity: 1,
    y: 0,
    x: 0,
  },
  out: {
    opacity: 0.8,
    y: "2vw",
    x: 0,
  },
}

export const routerTransition = {
  type: "tween",
  ease: "easeIn",
  duration: 0.2,
}

export const routerTransitionDesktop = {
  type: "tween",
  ease: "easeIn",
  duration: 0.2,
}
