import React, { useEffect, useImperativeHandle, useState } from "react"
import { ApplyJobAppealModalData } from "../../Model/Modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { Trans, useTranslation } from "react-i18next"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "../ModalController/ContactKOLModal/ContactKOLModal.module.css"
import {
  getFileTypeImage,
  SupportingImageType,
} from "../../Utils/FileTypeImageConverter"
import { FileType } from "../../Model/File"
import { getAdminRequests } from "../../HelpingFunction/AdminRequest/AdminRequestDBHelper"
import { ADMIN_REQUEST_TYPE } from "../../Enum/APP_TYPE"
import { rebuildFileArr } from "../../Utils/FileUploadUtils"

interface file {
  id: string
  img: string
  fileType: string
  file: FileType
}

const ApplyJobAppealModalComponent = (
  props: ApplyJobAppealModalData,
  ref: any
) => {
  const { t, i18n } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [selectedFileList, setSelectedFileList] = useState<file[]>([])
  const [appealObj, setAppealObj] = useState<{
    content: string
    reasonablePrice: number
    attachment: string[]
  }>({
    content: "",
    reasonablePrice: 0,
    attachment: [],
  })
  const [orgPrice, setOrgPrice] = useState<number>(0)
  useEffect(() => {
    if (typeof props.metaData.job !== "undefined") {
      checkAppealExist(props.metaData.job.id)
      setOrgPrice(props.metaData.job.price)
    }
  }, [props.metaData.job])

  const checkAppealExist = (jobID: string) => {
    getAdminRequests({
      jobID: jobID,
      type: ADMIN_REQUEST_TYPE.APPEAL,
    }).then((result) => {
      if (result.success && Array.isArray(result.data)) {
        setIsSubmitted(result.data.length !== 0)
      }
    })
  }

  const selectFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFileList([])
    const state = await rebuildFileArr(e)
    if (state.success) {
      setSelectedFileList(state.data)
    }
  }

  const removeFile = (id: string) => {
    let newArr = selectedFileList
    newArr = newArr.filter((e: file) => e.id !== id)
    setSelectedFileList(newArr)
  }
  useImperativeHandle(ref, () => ({
    returnData() {
      return {
        selectedFileList: selectedFileList,
        appealObj: appealObj,
      }
    },
  }))
  return (
    <div className="container mx-auto overflow-y-auto">
      {isSubmitted ? (
        <div className="container mx-auto md:p-8 max-h-screen ">
          <div className="w-full text-center my-4">
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="w-16 h-16 text-5xl text-green-700"
            />
          </div>

          <div className="w-full text-center mt-4 text-gray-800 text-2xl font-bold">
            <Trans>ApplyJobAppealModal.submit-successful</Trans>
          </div>

          <p className="w-full text-center text-sm text-gray-400 my-2">
            <Trans>ApplyJobAppealModal.submit-successful-des</Trans>
          </p>
        </div>
      ) : (
        <div className="container mx-auto md:p-8 max-h-screen ">
          <div className="w-full mt-4 text-gray-800 text-lg font-bold">
            <Trans>ApplyJobAppealModal.description</Trans>
          </div>

          <p className="text-sm text-gray-400 mb-2">
            <Trans>ApplyJobAppealModal.description-des</Trans>
          </p>

          <textarea
            className="block w-full rounded bg-gray-100 mb-4 h-48 p-4"
            value={appealObj.content}
            onChange={(e) => {
              setAppealObj({
                ...appealObj,
                content: e.target.value,
              })
            }}
          />

          <div className="w-full my-8">
            <div className="col-span-2 mt-4 text-gray-800 text-lg font-bold">
              <Trans>ApplyJobAppealModal.reasonable-price</Trans>
            </div>
            <p className="text-sm text-gray-400 mb-2">
              <Trans>ApplyJobAppealModal.reasonable-price-des</Trans>
            </p>
            <input
              type={"number"}
              placeholder={t("ApplyJobAppealModal.original-price") + orgPrice}
              className="block w-full rounded bg-gray-100 mb-4 h-12 p-4"
              onChange={async (e) => {
                setAppealObj({
                  ...appealObj,
                  reasonablePrice: parseInt(e.target.value),
                })
              }}
            />
          </div>

          <div className="w-full my-8 mb-20">
            <div className="col-span-2 my-4 text-gray-800 text-lg font-bold">
              <Trans>ContactKOLModal.attachment</Trans>
            </div>
            <div className={classes.UploadImageContainer}>
              <input
                type={"file"}
                disabled={isSubmitting}
                placeholder={"上載圖片"}
                id={"uploader"}
                accept="*"
                onChange={async (e) => selectFiles(e)}
                multiple
              />
            </div>
          </div>

          <div>
            {selectedFileList.map((file, index) => {
              return (
                <div key={file.id} className={classes.ImageContainer}>
                  <button
                    className={classes.DeleteImageBtn}
                    onClick={() => removeFile(file.id)}>
                    X
                  </button>
                  {SupportingImageType.includes(file.fileType) ? (
                    <img className={classes.ImageItem} src={file.img} />
                  ) : (
                    <img
                      className={classes.ImageItem}
                      src={getFileTypeImage(file.fileType)}
                    />
                  )}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default React.forwardRef(ApplyJobAppealModalComponent)
