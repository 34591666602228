export const SUPPORTED_FILE_TYPE = {
  UPLOAD_PROFILE_COVER_IMAGE: "UPLOAD_PROFILE_COVER_IMAGE",
  UPLOAD_SERVICE_FEATURE_IMAGE: "UPLOAD_SERVICE_FEATURE_IMAGE",
  UPLOAD_CHAT_ATTACHMENT: "UPLOAD_CHAT_ATTACHMENT",
  UPLOAD_USER_SIGNATURE: "UPLOAD_USER_SIGNATURE",
}

const PATH = {
  UPLOAD_PROFILE_COVER_IMAGE: "Profile",
  UPLOAD_USER_SIGNATURE: "Profile",
  UPLOAD_SERVICE_FEATURE_IMAGE: "ServicePlan",
  UPLOAD_CHAT_ATTACHMENT: "Project",
}

export function getFilePath(type, uid, path, path2) {
  if (type === SUPPORTED_FILE_TYPE.UPLOAD_PROFILE_COVER_IMAGE) {
    return "Member/" + uid + "/" + PATH[type]
  } else if (type === SUPPORTED_FILE_TYPE.UPLOAD_USER_SIGNATURE) {
    return "Member/" + uid + "/" + PATH[type]
  } else if (type === SUPPORTED_FILE_TYPE.UPLOAD_SERVICE_FEATURE_IMAGE) {
    return "Member/" + uid + "/" + PATH[type] + "/" + path
  } else if (type === SUPPORTED_FILE_TYPE.UPLOAD_CHAT_ATTACHMENT) {
    return "ChatRoom" + "/" + path
  }
}
