import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"
import "firebase/compat/firestore"
import { getFirestore, doc, updateDoc } from "firebase/firestore"
import firebaseApp from "../../Config/firebase"
import firebaseRef from "firebase/compat/app"
import { KOLDefault } from "../../Model/KOL"
import { igUserDataDefault } from "../../Enum/LOGIN_PAGE_TYPE"

const db = getFirestore(firebaseApp)

const initialState = {
  kolInfo: { ...KOLDefault, isBookmarked: false },
  igKOLData: igUserDataDefault,
  kolProfileJobs: [],
  fromSearchPage: false,
}

const setMemberBookmark = async (kolInfo, uid, kolID) => {
  let docRef = doc(db, "Member", uid)

  await updateDoc(docRef, {
    bookmark: kolInfo.isBookmarked
      ? firebaseRef.firestore.FieldValue.arrayRemove(kolID)
      : firebaseRef.firestore.FieldValue.arrayUnion(kolID),
  })
    .then((res) => {
      console.log(!kolInfo.isBookmarked)
    })
    .catch((err) => console.log(err))

  return !kolInfo.isBookmarked
}

const setKOLInfoReducer = (state = initialState, action) => {
  if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_KOL_INFO) {
    return {
      ...state,
      kolInfo: {
        ...state.kolInfo,
        ...action.data,
      },
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.BOOK_MARK_KOL) {
    return {
      ...state,
      kolInfo: {
        ...state.kolInfo,
        isBookmarked: setMemberBookmark(
          state.kolInfo,
          action.uid,
          action.kolID
        ),
      },
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_IG_USER_DATA) {
    return {
      ...state,
      igKOLData: action.data,
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_KOL_PROFILE_JOBS) {
    return {
      ...state,
      kolProfileJobs: action.data,
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_PAGE_ROUTE) {
    return {
      ...state,
      fromSearchPage: action.data,
    }
  }
  return state
}

export default setKOLInfoReducer
