import { DateObj } from "./Utilities"

export interface Schedule {
  id: string
  title: string
  // description: string
  date: Date | DateObj
  isFinish: boolean
}

export const SCHEDULER_DEFAULT: Schedule = {
  id: "",
  title: "",
  // description: "",
  date: new Date(),
  isFinish: false,
}
