import {
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
} from "firebase/firestore"
import firebaseApp from "../../Config/firebase"
import {
  KOLCombineWithAvatar,
  KOLCombineWithAvatarDefault,
} from "../../Model/KOLCombine"
import { getKOL, searchKOLAlgolia } from "../KOL/kolDBHelper"
import {
  KOLSearchDefault,
  KOLSearchType,
} from "../../Redux/Reducer/ToolsManager"
import { returnIdTokenResult } from "../../Utils/FirebaseUtils"
import { getAPIPath } from "../../Utils/HelpingFunction"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import l4kIcon from "../../Assets/icons-72.png"
import { KOLAMessage, RecommendedKOLWithReason } from "../../Model/KOLAMessage"

const db = getFirestore(firebaseApp)

/**
 * get assistant Chatroom by chatroom ID
 * @param {string} id - chatroom ID
 */

export const getAssistantChat = async (
  chatroomID: string
): Promise<
  | {
      success: true
      data: KOLAMessage[]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    let dbRef = query(
      collection(db, "ChatRoom", chatroomID, "Message"),
      orderBy("createDate", "desc")
    )

    await getDocs(query(dbRef, limit(25)))
      .then((docs) => {
        if (docs && !docs.empty) {
          const resultMessage: KOLAMessage[] = docs.docs.map(
            (doc) =>
              ({
                ...doc.data(),
                id: doc.id,
              } as KOLAMessage)
          )
          let newAllMessage: KOLAMessage[] = []

          resultMessage.forEach((msg) => {
            newAllMessage.push(msg)
          })

          newAllMessage = newAllMessage.slice().reverse()

          resolve({ success: true, data: [...newAllMessage] })
        } else {
          resolve({ success: false })
        }
      })
      .catch((e) => {
        console.log("error", e)
      })
  })
}

export const getFullKOLList = async (
  savedKOLData: RecommendedKOLWithReason[],
  searchKOLCondition: KOLSearchType,
  setHasMore: (hasMore: boolean) => void
): Promise<
  | {
      data: {
        kol: KOLCombineWithAvatar
        reason: string
        score: string
      }[]
      success: true
    }
  | {
      success: false
    }
> => {
  return new Promise(async (finalResolve) => {
    let allPromise: Promise<{
      kol: KOLCombineWithAvatar
      reason: string
      score: string
    }>[] = []
    let extraPromise: Promise<{
      kol: KOLCombineWithAvatar
      reason: string
      score: string
    }>[] = []
    savedKOLData.forEach((kol: RecommendedKOLWithReason) => {
      allPromise.push(
        new Promise(async (resolve1) => {
          searchKOLAlgolia({
            ...KOLSearchDefault,
            ...searchKOLCondition,
            id: kol.uid,
          })
            .then(async (response) => {
              if (response.success) {
                const kolAvatar = await getKOL(response.data[0].userName)
                resolve1({
                  kol: {
                    ...response.data[0],
                    avatarUrl: kolAvatar.success
                      ? kolAvatar.data.avatarUrl
                      : l4kIcon,
                    coverImages: kolAvatar.success
                      ? kolAvatar.data.coverImages
                      : [],
                  } as KOLCombineWithAvatar,
                  reason: kol.explainMsg,
                  score: kol.score,
                })
                // setHasMore(response.hasMore)
              }
              resolve1({
                kol: KOLCombineWithAvatarDefault,
                reason: "",
                score: "",
              })
            })
            .catch((err) => {
              console.log(err)
              finalResolve({ success: false })
            })
        })
      )
    })

    Promise.all(allPromise).then((result) => {
      searchKOLAlgolia({
        ...KOLSearchDefault,
        ...searchKOLCondition,
        excludeId: savedKOLData.map((e: { uid: any }) => e.uid).join("&"),
      })
        .then((response) => {
          if (response.success) {
            response.data.forEach((kol) => {
              extraPromise.push(
                new Promise(async (resolve2) => {
                  const kolAvatar = await getKOL(kol.userName)
                  resolve2({
                    kol: {
                      ...kol,
                      avatarUrl: kolAvatar.success
                        ? kolAvatar.data.avatarUrl
                        : l4kIcon,
                      coverImages: kolAvatar.success
                        ? kolAvatar.data.coverImages
                        : [],
                    } as KOLCombineWithAvatar,
                    reason: "",
                    score: "",
                  })
                })
              )
            })
            setHasMore(response.hasMore)
          }
          Promise.all(extraPromise).then((fullResult) => {
            finalResolve({
              success: true,
              data: result.filter((e) => e.kol && e.kol.id).concat(fullResult),
            })
          })
        })
        .catch((err) => {
          console.log(err)
          finalResolve({ success: false })
        })
    })
  })
}

export const getNonRecomKOLList = async (
  searchKOLCondition: KOLSearchType,
  setHasMore: (hasMore: boolean) => void
): Promise<
  | {
      data: {
        kol: KOLCombineWithAvatar
        reason: string
        score: string
      }[]
      success: true
    }
  | {
      success: false
    }
> => {
  return new Promise(async (finalResolve) => {
    let extraPromise: Promise<{
      kol: KOLCombineWithAvatar
      reason: string
      score: string
    }>[] = []
    searchKOLAlgolia({
      ...KOLSearchDefault,
      ...searchKOLCondition,
    })
      .then((response) => {
        if (response.success) {
          response.data.forEach((kol) => {
            extraPromise.push(
              new Promise(async (resolve) => {
                const kolAvatar = await getKOL(kol.userName)
                resolve({
                  kol: {
                    ...kol,
                    avatarUrl: kolAvatar.success
                      ? kolAvatar.data.avatarUrl
                      : l4kIcon,
                    coverImages: kolAvatar.success
                      ? kolAvatar.data.coverImages
                      : [],
                  } as KOLCombineWithAvatar,
                  reason: "",
                  score: "",
                })
              })
            )
          })
          setHasMore(response.hasMore)
        }
        Promise.all(extraPromise).then((fullResult) => {
          finalResolve({ data: fullResult, success: true })
        })
      })
      .catch((err) => {
        console.log(err)
        finalResolve({ success: false })
      })
  })
}

export const getContractDraftDetails = async (
  backUpSummary: string
): Promise<
  | {
      data: {
        title: string
        content: string
      }
      success: true
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      //send payout request
      await fetch(getAPIPath("/api/openai/quotationDraft"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          summary: backUpSummary,
        }),
      })
        .then((res) => res.json())
        .then(async (finalResponse) => {
          resolve({ data: finalResponse.data, success: true })
        })
        .catch((err) => {
          console.log(err)
          resolve({ success: false })
        })
    })
  })
}

export const warmChatgpt = (): Promise<{ success: boolean }> => {
  return new Promise(async (resolve) => {
    await fetch(getAPIPath("/api/openai/warm-up"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(async (finalResponse) => {
        return resolve({ success: true })
      })
      .catch((err) => {
        console.log(err)
        return resolve({ success: false })
      })
  })
}
