import React, { useEffect, useState } from "react"
import { getCaseStudy } from "../../../HelpingFunction/KOL/kolDBHelper"
import { useDispatch, useSelector } from "react-redux"
import { CaseStudy } from "../../../Model/CaseStudy"
import CaseStudyCard from "../../../Component/CaseStudyCard"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../../Utils/ModalType"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons"
import { Trans, useTranslation } from "react-i18next"
import { ASSET_TYPE, CHATROOM_PAGE_TYPE, ROLE } from "../../../Enum/APP_TYPE"
import { Job } from "../../../Model/Job"

interface PropsType {
  role: ROLE
  kolID: string
  caseID?: string
  jobID: string
}

const LinkCaseStudy: React.FC<PropsType> = ({ role, kolID, caseID, jobID }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const jobCaseStudy = useSelector((state: any) => {
    return state.ContractManager.jobCaseStudy
  })

  const chatroom = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomInfo
  })

  const job: Job = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomJob
  })

  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (caseID) {
      getCaseStudy(kolID, caseID)
        .then((result) => {
          if (result.success) {
            setJobCaseStudy(result.data)
            setIsLoaded(true)
          }
        })
        .catch((err) => console.log(err))
    }
  }, [auth, caseID])

  const setCaseStudyModal = (state: any) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.CASE_STUDY_MODAL,
      returnFn: state.returnFn,
      metaData: state.metaData,
    })

  const toggleFBModal = (cs: CaseStudy) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.CASE_STUDY_IG_MODAL,
      returnFn: null,
      metaData: { caseStudy: cs, jobID: jobID },
    })
  }
  const setJobCaseStudy = (data: CaseStudy) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_JOB_CASE_STUDY,
      data: data,
    })
  }

  const setChatText = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_PAGE_TYPE,
      data: CHATROOM_PAGE_TYPE.CHAT_TEXT,
    })
  }

  const addDraftMsgToChat = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SHARE_DRAFT_MSG,
      draftMsg: state,
    })
  }

  const inviteKOLToAttachIGPost = () => {
    setChatText()
    addDraftMsgToChat({
      type: ASSET_TYPE.LINK_TEXT,
      msg: t("LinkCaseStudy.invite-kol-link-ig"),
      path: "/c/" + chatroom.id + "/contract/" + job.id,
    })
  }

  if (isLoaded && caseID) {
    return (
      <div className="w-full shadow rounded border-t-8 border-green-300 my-4 p-4 pb-12 bg-white">
        <h2 className="text-2xl font-bold text-green-600 my-4">
          <FontAwesomeIcon icon={faCheckCircle} className={"mr-2"} />
          <Trans>LinkCaseStudy.title</Trans>
        </h2>
        <p className=" text-gray-400 text-sm mb-4">
          <Trans>LinkCaseStudy.des</Trans>
        </p>
        <div className="mx-auto">
          <CaseStudyCard
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            setCaseStudyModal={setCaseStudyModal}
            data={jobCaseStudy}
            auth={auth}
          />
        </div>

        {/* only role as kol can reconnect post*/}
        {role === ROLE.KOL && !job.isFinish && (
          <button
            className="btn btn-primary w-full mt-4"
            onClick={() => toggleFBModal(jobCaseStudy as CaseStudy)}>
            <Trans>LinkCaseStudy.re-connect</Trans>
          </button>
        )}
      </div>
    )
  } else if (role === ROLE.KOL) {
    return (
      <div className="relative w-full shadow rounded border-t-8 border-yellow-300 my-4 p-4 pb-12 bg-white">
        <h2 className="text-2xl font-bold text-yellow-600 my-4">
          <FontAwesomeIcon icon={faQuestionCircle} className={"mr-2"} />
          <Trans>LinkCaseStudy.no-connected-post</Trans>
        </h2>
        <p className=" text-gray-400 text-sm mb-4">
          <Trans>LinkCaseStudy.why-connect</Trans>
        </p>

        <button
          className="absolute bottom-2 right-2 btn float-right w-32 btn-warning"
          onClick={() => toggleFBModal(jobCaseStudy as CaseStudy)}>
          <Trans>LinkCaseStudy.connect</Trans>
        </button>
      </div>
    )
  } else if (role === ROLE.CUSTOMER) {
    return (
      <div className="relative w-full shadow rounded border-t-8 border-yellow-300 my-4 p-4 pb-12 bg-white">
        <h2 className="text-2xl font-bold text-yellow-600 my-4">
          <FontAwesomeIcon icon={faQuestionCircle} className={"mr-2"} />
          <Trans>LinkCaseStudy.no-connected-post</Trans>
        </h2>
        <p className=" text-gray-400 text-sm mb-6">
          <Trans>LinkCaseStudy.cta</Trans>
        </p>

        <div className="my-2">
          <button
            className="absolute bottom-4 right-4 btn float-right w-32 btn-warning"
            onClick={(e) => inviteKOLToAttachIGPost()}>
            <Trans>LinkCaseStudy.invite</Trans>
          </button>
        </div>
      </div>
    )
  }
  return <div>Admin</div>
}
export default LinkCaseStudy
