import React from "react"
import { CreateCaseStudyModalData } from "../../Model/Modal"
import EditCaseStudyItem from "../../page-components/kol/EditCaseStudyItem"
import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../Utils/ModalType"

const CreateCaseStudyModalComponent = (props: CreateCaseStudyModalData) => {
  const dispatch = useDispatch()
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const allCaseStudy = useSelector((state: any) => {
    return state.CaseStudyManager.allCaseStudy
  })

  const removeCaseStudy = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.REMOVE_CASE_STUDY,
      caseStudyID: state,
    })
  }
  const updateCaseStudy = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.EDIT_CASE_STUDY,
      caseStudy: state,
    })
  }
  const setUploadFileModal = (state: any): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.UPLOAD_FILE_MODAL,
      returnFn: state.returnFn,
      metaData: state.metaData,
      uploadPath: state.uploadPath,
      typeLimit: state.typeLimit,
      limit: state.limit,
    })
  }
  return (
    <div className="my-4">
      {allCaseStudy.map((e: any, index: any) => {
        if (e.action !== "delete") {
          return (
            <EditCaseStudyItem
              auth={auth}
              key={e.id}
              index={index}
              data={e}
              currentCase={props.metaData.caseId}
              updateCaseStudy={updateCaseStudy}
              removeCaseStudy={removeCaseStudy}
              setUploadFileModal={setUploadFileModal}
            />
          )
        }
      })}
    </div>
  )
}
export default CreateCaseStudyModalComponent
