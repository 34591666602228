import React, { useState } from "react"
import { HelpDocModalData } from "../../Model/Modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"
import { useSelector } from "react-redux"
import { t } from "i18next"

const MobileHelpDocModalComponent: React.FC<HelpDocModalData> = ({
  metaData,
}) => {
  const screenHeight = useSelector((state: any) => {
    return state.SystemManager.screenHeight
  })

  const [docPath, setDocPath] = useState<string>("")
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const onHelpDocDirect = (path: string) => {
    setIsLoaded(false)
    setDocPath(
      (localStorage.getItem("i18n-lang") === "en" ? "/eng" : "/zh") + "/" + path
    )

    setTimeout(() => {
      setIsLoaded(true)
    }, 100)
  }

  const helpDocs = metaData

  return (
    <div className="relative">
      <button
        className={classNames(
          "fixed btn btn-ghost btn-circle btn-xs justify-self-start ",
          { hidden: !isLoaded }
        )}
        style={{
          top: 50,
          left: 35,
        }}
        onClick={() => {
          setIsLoaded(false)
        }}>
        <FontAwesomeIcon icon={faArrowLeftLong} className="text-lg" />
      </button>
      {Array.isArray(helpDocs) &&
        helpDocs.map((helpDoc, key) => {
          return (
            <div key={key}>
              {key !== 0 && !isLoaded && <div className="divider my-1" />}
              <button
                key={"helpDocMobile" + key}
                onClick={() => {
                  onHelpDocDirect(helpDoc.url)
                }}
                className={classNames("btn btn-block btn-primary", {
                  hidden: isLoaded,
                })}>
                {t(helpDoc.label)}
              </button>
            </div>
          )
        })}
      {isLoaded && (
        <div className="fixed sm:static w-screen sm:w-auto left-0 overflow-hidden">
          <div className="relative left-0 -top-14 z-0">
            {/*when click this button, show gitbook embeded by using <iframe>*/}
            <iframe
              src={"https://docs.look4kol.com" + docPath}
              className="w-full "
              style={{
                height: "70vh",
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default MobileHelpDocModalComponent
