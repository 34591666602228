import { returnIdTokenResult } from "./FirebaseUtils"
import { getAPIPath } from "./HelpingFunction"
import { Capacitor } from "@capacitor/core"
import { ROLE } from "../Enum/APP_TYPE"
import { t } from "i18next"
import { DateObj } from "../Model/Utilities"
import { Timestamp } from "firebase/firestore"

const checkViewAsQuery = (
  prevLocation: string,
  path: any
): { pathname: string; search: string } => {
  if (prevLocation.includes("viewAs=")) {
    // previous URL contains viewAs query

    const oldParams = new URLSearchParams(prevLocation)

    const viewQuery = oldParams.get("viewAs")

    //  do not remove typeof condition, otherwise might crash when perform string actions
    if (typeof path.search === "string") {
      const newParams = new URLSearchParams(path.search)

      // check if returnToCustomer exist
      const returnQuery = newParams.get("returnToCustomer")

      if (returnQuery && returnQuery === "true") {
        // confirmed returnToCustomer exist, returning to customer...
        // reset all params
        return {
          pathname: path.pathname,
          search: "",
        }
      } else if (viewQuery && !newParams.get("viewAs")) {
        // old viewAs exist, and new param doesn't, update accordingly
        newParams.set("viewAs", viewQuery)
      }
      // new viewAs exists and replaces old viewAs
      return {
        pathname: path.pathname,
        search: newParams.toString(),
      }
    }

    // previous search query is empty, and so new viewAs is added to history.push
    return {
      pathname: path.pathname ? path.pathname : path,
      search: "?viewAs=" + viewQuery,
    }
  }
  // normal query
  return {
    pathname: path.pathname ? path.pathname : path,
    search: typeof path.search === "string" ? path.search : "",
  }
}

const getUserLang = (): "hk" | "en" => {
  return localStorage.getItem("i18n-lang") === "en" ? "en" : "hk"
}

const getAlterationsUserLang = (): "hk" | "en" => {
  return localStorage.getItem("i18n-lang") === "hk" ? "en" : "hk"
}

const isWebView = (): boolean => {
  try {
    let is_uiwebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(
      navigator.userAgent
    )

    let userAgent = window.navigator.userAgent

    if (userAgent.includes("wv") || is_uiwebview) {
      return true
    }
    return false
  } catch (err) {
    return false
  }
}

const isTestServer = (): boolean => {
  try {
    return window.location.hostname === "look4kol-test-server.web.app"
  } catch (e) {
    return false
  }
}

const isLocalhost = (): boolean => {
  try {
    return (
      (window.location.hostname === "localhost" ||
        window.location.hostname.includes("16.163.76.52") ||
        window.location.hostname.includes("192.168.")) &&
      !(
        Capacitor.getPlatform() === "android" ||
        Capacitor.getPlatform() === "ios"
      )
    )
  } catch (e) {
    return false
  }
}

const isIOS = (): boolean => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  )
}

const isPhoneDevice = (): boolean => {
  return (
    Capacitor.getPlatform() === "android" || Capacitor.getPlatform() === "ios"
  )
}

const isMobileDevice = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}

export enum IMGUR_THUMBNAIL_TYPE {
  s = "s",
  b = "b",
  t = "t",
  m = "m",
  l = "l",
  h = "h",
}

const returnThumbnailUrl = (
  url: string | undefined,
  type?: IMGUR_THUMBNAIL_TYPE
): string => {
  // doc: https://api.imgur.com/models/image
  try {
    if (typeof url === "string" && url.includes("i.imgur.com")) {
      return url.replace(
        "." + url.split(".")[url.split.length + 1],
        `${type ? type : "m"}.` + url.split(".")[url.split.length + 1]
      )
    }
    return typeof url === "string" ? url : ""
  } catch (e) {
    return ""
  }
}

const toggleShare = (data: {
  title: string
  text: string
  url: string
}): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    if (isMobileDevice()) {
      let shareData = {
        title: data.title,
        text: data.text,
        url: data.url,
      }
      let res = navigator.canShare(shareData)
      if (res) {
        try {
          await navigator.share(shareData).then(() => {
            return resolve({ success: true })
          })
        } catch (err) {
          return resolve({ success: false })
        }
      } else {
        await navigator.clipboard.writeText(window.location.href).then(() => {
          return resolve({ success: true })
        })
      }
    } else {
      await navigator.clipboard.writeText(window.location.href).then(() => {
        return resolve({ success: true })
      })
    }
  })
}

const uploadImageFileToImgur = (
  //image file
  file: File
): Promise<
  | {
      success: true
      data: string[]
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      const formData = new FormData()
      formData.append("file", file, "file")

      await fetch(getAPIPath("/api/utility/" + res.uid + "/upload"), {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          idToken: res.token,
        },
        body: file,
      })
        .then((res) => res.json())
        .then(async (finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: finalResponse.success,
              data: finalResponse.data,
            })
          }
          return resolve({
            success: finalResponse.success,
          })
        })
    })
  })
}

const uploadImagesToImgur = (
  uid: string,
  //image url
  imagesList: string[]
): Promise<
  | {
      success: true
      data: string[]
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      //send payout request
      await fetch(getAPIPath("/api/utility/" + uid + "/upload"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          imagesList: imagesList,
        }),
      })
        .then((res) => res.json())
        .then(async (finalResponse) => {
          if (finalResponse.success) {
            return resolve({
              success: finalResponse.success,
              data: finalResponse.data,
            })
          }
          return resolve({
            success: finalResponse.success,
          })
        })
    })
  })
}

const getRole = (viewAs?: string | null): Promise<ROLE> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then((res) => {
      if (res.success) {
        if (res.role === "administrator") {
          return resolve(ROLE.ADMIN)
        } else if (res.role === "kol" || viewAs) {
          return resolve(ROLE.KOL)
        } else if (res.role === "customer") {
          return resolve(ROLE.CUSTOMER)
        }
        return resolve(ROLE.DEFAULT)
      }
      return resolve(ROLE.DEFAULT)
    })
  })
}

const getDistinctValue = (array: any[], key: string): any[] => {
  try {
    // check whether the array include this key
    if (array[0].hasOwnProperty(key)) {
      return array
        .map((item) => item[key])
        .filter((value, index, self) => self.indexOf(value) === index)
    }
    return []
  } catch (e) {
    console.log(e)
    return []
  }
}

const getTimeOfDay = (): string => {
  const hour = new Date().getHours()
  if (hour < 12) {
    return t("Dashboard.morning")
  } else if (hour < 18) {
    return t("Dashboard.afternoon")
  }
  return t("Dashboard.evening")
}

const getTimeFromAnyDateType = (date: Date | DateObj | Timestamp) => {
  return "seconds" in date
    ? date.seconds
    : Math.round(new Date(date).getTime() / 1000)
}

export {
  isIOS,
  checkViewAsQuery,
  getUserLang,
  getAlterationsUserLang,
  isWebView,
  isTestServer,
  isLocalhost,
  uploadImageFileToImgur,
  uploadImagesToImgur,
  isMobileDevice,
  returnThumbnailUrl,
  isPhoneDevice,
  toggleShare,
  getRole,
  getDistinctValue,
  getTimeOfDay,
  getTimeFromAnyDateType,
}
