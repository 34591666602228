import React from "react"
import { ChatMessage } from "../../../Model/ChatMessage"
import { ASSET_TYPE } from "../../../Enum/APP_TYPE"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy, faTrashCan } from "@fortawesome/free-regular-svg-icons"
import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../../Utils/ModalType"
import { Trans } from "react-i18next"

interface Props {
  chatroomID: string
  rightClickItem: ChatMessage
  pointX: number
  pointY: number
}
const ContextMenu = ({ chatroomID, rightClickItem, pointX, pointY }: Props) => {
  const dispatch = useDispatch()
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const deleteMessageModal = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.DELETE_MESSAGE_MODAL,
      returnFn: state.returnFn,
      metaData: state.metaData,
    })
  }

  const copyText = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await navigator.clipboard.writeText(rightClickItem.msg)
      console.log("copied text")
    } catch (err) {
      console.log(err)
    }
  }

  const deleteTextCheck = () => {
    return (
      Math.floor(
        (new Date().valueOf() / 1000 -
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          new Date(rightClickItem.createDate.seconds).valueOf()) /
          60
      ) > 10
    )
  }
  return (
    <div
      style={{
        position: "absolute",
        top: pointY - 30 + "px",
        left:
          rightClickItem.createUserID === auth.uid
            ? pointX - 100 + "px"
            : pointX + "px",
      }}>
      <ul className="menu bg-base-200 w-24 rounded-box text-xs ">
        <li onClick={() => copyText()}>
          <a className="group">
            <FontAwesomeIcon
              className="group-hover:animate-bounce mr-2"
              icon={faCopy}
            />{" "}
            <Trans>ToolBar.copy</Trans>
          </a>
        </li>
        {rightClickItem.createUserID === auth.uid && !deleteTextCheck() && (
          <li
            onClick={() => {
              deleteMessageModal({
                returnFn: () => {
                  console.log("done")
                },
                metaData: {
                  inTenMins:
                    Math.floor(
                      (new Date().valueOf() / 1000 -
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        new Date(rightClickItem.createDate.seconds).valueOf()) /
                        60
                    ) <= 10,
                  chatroomID: chatroomID,
                  messageID: rightClickItem.id,
                },
              })
            }}>
            <a className="group">
              {" "}
              <FontAwesomeIcon
                className="group-hover:animate-bounce mr-2"
                icon={faTrashCan}
              />{" "}
              <Trans>ToolBar.delete</Trans>
            </a>
          </li>
        )}
      </ul>
    </div>
  )
}

export default ContextMenu
