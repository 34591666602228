import React from "react"
import classes from "./DivContentLoader.module.css"

const DivContentLoader = (props) => {
  return (
    <div
      className={classes.animatedBackground}
      style={{
        borderRadius: typeof props.r !== "undefined" ? props.r : 0,
        width: typeof props.w !== "undefined" ? props.w : 100,
        height: typeof props.h !== "undefined" ? props.h : 100,
      }}></div>
  )
}
export default DivContentLoader
