import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"

const initialState = {
  isConfirmEmail: false,
  screenWidth: 0,
  screenHeight: 0,
  forceSignUp: false,
  forcePhoneVerify: false,
}

const SystemManager = (state = initialState, action) => {
  if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_SCREEN_WIDTH) {
    return {
      ...state,
      screenWidth: action.screenWidth,
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_SCREEN_HEIGHT) {
    return {
      ...state,
      screenHeight: action.screenHeight,
    }
  } else if (
    action.type === SUPPORTED_REDUX_FUNCTIONS.SET_USER_EMAIL_CONFIRMATION
  ) {
    return {
      ...state,
      isConfirmEmail: action.isConfirmEmail,
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_SIGN_UP_KOL) {
    return {
      ...state,
      forceSignUp: action.forceSignUp,
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_SIGN_UP_CUSTOMER) {
    return {
      ...state,
      forcePhoneVerify: action.forcePhoneVerify,
    }
  }

  return state
}

export default SystemManager
