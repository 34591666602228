import React, { useEffect, useImperativeHandle, useRef, useState } from "react"
import { UploadFileModalData } from "../../Model/Modal"
import FileItem from "../ModalController/UploadFileModal/FileItem/FileItem"
import { useSelector } from "react-redux"

const UploadFileModalComponent = (props: UploadFileModalData, ref: any) => {
  const typeLimit = useSelector((state: any) => {
    return state.setModalController.typeLimit
  })
  const limit = useSelector((state: any) => {
    return state.setModalController.limit
  })
  const itemsRef = useRef<string[]>([])
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [selectedFileList, setSelectedFileList] = useState<File[]>([])
  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, selectedFileList.length)
  }, [selectedFileList])

  useEffect(() => {
    setIsUploading(false)
    setSelectedFileList([])
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.getElementById("uploader").value = ""
  }, [props.isOpen])

  const selectFiles = (e: any) => {
    setSelectedFileList([])

    let files = e.target.files

    if (files !== null) {
      let newFileList: Promise<any>[] = []
      let allNewFile: File[] = []
      Array.from(files).forEach((file: any) => {
        newFileList.push(
          new Promise<void>(async (resolve, reject) => {
            allNewFile.push(file)
            resolve()
          })
        )
      })

      Promise.all(newFileList).then((res) => {
        setSelectedFileList(allNewFile)
      })
    }
  }
  const removeFileFromList = (fileID: string) => {
    let newFileList = selectedFileList
    newFileList = newFileList.filter((e) => e.name !== fileID)
    setSelectedFileList(newFileList)
  }
  useImperativeHandle(ref, () => ({
    returnData() {
      return itemsRef.current
    },
  }))

  return (
    <div className="container mx-auto overflow-hidden">
      <div className="flex flex-row flex-wrap">
        <label></label>

        <input
          type={"file"}
          className="p-4 w-full h-16 mb-16 bg-gray-200 rounded"
          disabled={isUploading}
          id={"uploader"}
          accept={typeLimit === "image" ? ".png,.jpg,.svg" : "*"}
          onChange={async (e) => selectFiles(e)}
          multiple={limit > 1}
        />

        <div className="block w-full py-2 ">
          {selectedFileList.map((file, index) => {
            return (
              <div key={file.name + index}>
                <FileItem
                  index={index}
                  removeFileFromList={removeFileFromList}
                  /* eslint-disable @typescript-eslint/ban-ts-comment */
                  /* eslint-disable no-return-assign */
                  // @ts-ignore

                  ref={(el: any) => (itemsRef.current[index] = el)}
                  data={file}
                  /* eslint-enable no-return-assign */
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default React.forwardRef(UploadFileModalComponent)
