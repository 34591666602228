export interface Bank {
  cardHolder: string
  bankID: string
  accNum: string
  bankLocation: "hk"
  currency: "hkd"
}

export interface Balance {
  amount: number
  currency: "hkd"
}

export const bankDefault: Bank = {
  cardHolder: "",
  bankID: "",
  accNum: "",
  bankLocation: "hk",
  currency: "hkd",
}

export const balanceDefault: Balance = {
  amount: 0,
  currency: "hkd",
}
