import React from "react"
import { useDispatch } from "react-redux"
import classNames from "classnames"
import { Plan } from "../../Model/Plan"
import { COMPONENT_DISPLAY } from "../../Enum/APP_TYPE"
import { Carousel } from "react-responsive-carousel"
import { returnThumbnailUrl } from "../../Utils/utiltyHelper"

interface PropsType {
  data: Plan
  displayMethod?: COMPONENT_DISPLAY
}

const KOLPlanItem: React.FC<PropsType> = ({ data, displayMethod }) => {
  if (displayMethod === COMPONENT_DISPLAY.LIST) {
    return (
      <div className="inline-block m-2 w-64 p-3 bg-white align-middle rounded-xl  shadow border border-gray-100">
        <h3 className="font-bold">{data.title}</h3>
        <p className=" tracking-wide">
          <span className="text-gray-500 align-top text-xs">
            HKD
            <span className="text-lg ml-2 font-semibold text-black">
              {data.price}
            </span>
          </span>
        </p>
        <ul className="pt-2  h-auto max-h-56 overflow-y-auto">
          {data.features.map((e, index) => {
            return (
              <li
                className="font-light text-sm text-gray-400 text-left whitespace-normal break-words "
                key={e + index}>
                - {e}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
  return (
    <div
      className={classNames(
        "inline-block mx-2 rounded-lg bg-white border border-gray-50 shadow px-3 pt-5 pb-2 text-gray-800 w-72 relative h-116"
      )}>
      <h3 className="text-xl font-medium px-2 overflow-hidden overflow-ellipsis">
        {data.title}
      </h3>
      {data.exampleImages.length > 0 && (
        <Carousel
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
          className="h-60 my-3 w-full rounded-xl"
          showStatus={false}
          showThumbs={false}>
          {data.exampleImages.map((e: string, index: number) => {
            if (e !== "") {
              return (
                <div key={index} className="w-full rounded-xl">
                  <img
                    alt="find kol"
                    className="rounded-xl object-cover lazyload h-60"
                    src={returnThumbnailUrl(e as string)}
                  />
                </div>
              )
            }
            return <div></div>
          })}
        </Carousel>
      )}
      <p className=" tracking-wide">
        <span className="text-gray-500 align-top text-xs">
          HKD
          <span className="text-lg ml-2 font-semibold text-black">
            {data.price}
          </span>
        </span>
      </p>
      <hr className="mt-4 border-1" />
      <ul className="my-2 h-auto max-h-28 overflow-y-auto">
        {data.features.map((e, index) => {
          return (
            <li
              className="font-semibold text-gray-400 text-left whitespace-normal break-words "
              key={e + index}>
              - {e}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
export default KOLPlanItem
