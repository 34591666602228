import { Carousel } from "react-responsive-carousel"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import img1 from "../../Assets/contract-chat-page-min.png"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import img2 from "../../Assets/chatMockup.png"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import img3 from "../../Assets/walletMockup.png"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import bannerBg from "../../Assets/t8mxq-q5u25.png"
import { Trans } from "react-i18next"
import Look4kolLogo from "../../../src/Component/Look4kolLogo"

const LoginInfo = () => {
  return (
    <div className="h-full relative">
      <div
        className="shadow-xl md:block hidden h-full fixed top-15"
        style={{
          backgroundPosition: "0px -200px",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${bannerBg})`,
          backgroundSize: "1100px",
          maxWidth: "1920px",
        }}>
        <div className="py-2 px-12">
          <Look4kolLogo />
          <div className="mx-auto text-dark-red-default text-xl font-medium w-full my-16 pb-8">
            <h2 className="text-4xl">
              <Trans>LoginInfo.title</Trans>
            </h2>
            <p className="text-primary font-normal text-base mt-8">
              <Trans>LoginInfo.desc</Trans>
            </p>
          </div>
          <Carousel
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            className="w-full rounded-xl mb-4"
            showStatus={false}
            showThumbs={false}
            autoPlay
            infiniteLoop>
            <img src={img1} />
            <img src={img2} />
            <img src={img3} />
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default LoginInfo
