import {
  faAdd,
  faArrowLeft,
  faCross,
  faExclamationCircle,
  faX,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import CustomAlert from "../../../Component/NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../../Enum/ALERT_SYSYEM"
import {
  CHATGPT_SETTING_TYPE,
  COMMEND_TYPE,
  IG_POST_LENGTH,
} from "../../../Model/ChatMessage"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"

const ChatGPTIGSetting = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const chatgptSetting: CHATGPT_SETTING_TYPE = useSelector((state: any) => {
    return state.ChatGPTManager.setting
  })

  const [currentKeyword, setCurrentKeyword] = useState<string>("")
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const updateChatGPTSetting = (data: CHATGPT_SETTING_TYPE) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.UPDATE_CHATGPT_SETTING,
      data: data,
    })
  }
  const setChatGptPrompt = (data: string) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATGPT_PROMPT,
      data: data,
    })
  }
  const updateSettingType = (key: string, value: any) => {
    let newSetting = chatgptSetting
    newSetting = {
      ...newSetting,
      ig: {
        ...newSetting.ig,
        [key]: value,
      },
    }
    updateChatGPTSetting(newSetting)
  }

  const sendPrompt = () => {
    if (
      setting.language !== "" &&
      setting.location !== "" &&
      setting.product_name !== "" &&
      setting.product_desc !== ""
    ) {
      const prompt = `language: ${setting.language}
      location: ${setting.location}
      product name: ${setting.product_name}
      product description: ${setting.product_desc}
      product brand: ${setting.product_brand}
      product url: ${setting.product_url}
      keywords: ${setting.keywords?.join(",")}
      length: ${setting.desc_length}`

      setChatGptPrompt(prompt)
    } else {
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2000)
    }
  }

  const setting = chatgptSetting.ig

  const handleClick = () => {
    if (currentKeyword !== "") {
      if (Array.isArray(setting.keywords)) {
        setting.keywords?.push(currentKeyword)
        updateSettingType("keywords", setting.keywords)
        setCurrentKeyword("")
      }
    }
  }
  const removeKeyword = (keyword: string) => {
    let newKeywords = setting.keywords?.filter((k: string) => k !== keyword)

    updateSettingType("keywords", newKeywords)
  }

  return (
    <div className="relative">
      <div className="mt-4 mb-8">
        <section className="bg-white p-4 rounded-xl my-2 border border-gray-200">
          <h2 className="font-bold text-xl mt-2 mb-6">
            <Trans>ChatGPTSetting.ig-section-1</Trans>
          </h2>
          <p className="text-sm font-medium  mx-1">
            <Trans>ChatGPTSetting.ig-q-1</Trans>
            <span className="text-red-400">*</span>
          </p>
          <input
            onChange={(e) => {
              updateSettingType("language", e.target.value)
            }}
            value={setting.language}
            type="text"
            placeholder="English"
            className="input input-bordered w-full  my-3 "
          />
          <p className="text-sm font-medium  mx-1">
            <Trans>ChatGPTSetting.ig-q-2</Trans>
            <span className="text-red-400">*</span>
          </p>
          <input
            onChange={(e) => {
              updateSettingType("location", e.target.value)
            }}
            value={setting.location}
            type="text"
            placeholder="Hong Kong"
            className="input input-bordered w-full  my-3"
          />
        </section>

        <section className="bg-white p-4 rounded-xl my-2 border border-gray-200">
          <h2 className="font-bold text-xl my-6">
            <Trans>ChatGPTSetting.ig-section-2</Trans>
          </h2>
          <p className="text-sm font-medium  mx-1">
            <Trans>ChatGPTSetting.ig-q-4</Trans>
            <span className="text-red-400">*</span>
          </p>
          <input
            onChange={(e) => {
              updateSettingType("product_name", e.target.value)
            }}
            value={setting.product_name}
            type="text"
            placeholder="Air Jordan"
            className="input input-bordered w-full  my-3"
          />
          <p className="text-sm font-medium mx-1">
            <Trans>ChatGPTSetting.ig-q-5</Trans>
            <span className="text-red-400">*</span>
          </p>
          <textarea
            onChange={(e) => {
              updateSettingType("product_desc", e.target.value)
            }}
            value={setting.product_desc}
            placeholder="This is a sneaker for young adults and is very colourful."
            className="input input-bordered w-full  resize-none h-[90px] my-3"
          />
          <p className="text-sm font-medium mx-1">
            <Trans>ChatGPTSetting.ig-q-6</Trans>
          </p>
          <input
            onChange={(e) => {
              updateSettingType("product_brand", e.target.value)
            }}
            value={setting.product_brand}
            type="text"
            placeholder="Nike"
            className="input input-bordered w-full  my-3"
          />
          <p className="text-sm font-medium mx-1">
            <Trans>ChatGPTSetting.ig-q-7</Trans>
          </p>

          <input
            onChange={(e) => {
              updateSettingType("product_url", e.target.value)
            }}
            value={setting.product_url}
            type="text"
            placeholder="www.nike.com"
            className="input input-bordered w-full  my-2"
          />
        </section>

        <section className="bg-white p-4 rounded-xl my-2 border border-gray-200">
          <h2 className="font-bold text-xl my-6">
            <Trans>ChatGPTSetting.ig-section-3</Trans>
          </h2>
          <p className="text-sm font-medium mx-1">
            <Trans>ChatGPTSetting.ig-q-9</Trans>
          </p>
          <select
            value={setting.desc_length}
            className="select select-bordered w-full max-w-xs my-3"
            onChange={(e) => updateSettingType("desc_length", e.target.value)}>
            <option selected disabled={true}>
              {t("ChatGPTSetting.length")}
            </option>
            <option value={IG_POST_LENGTH.SHORT}>
              {t("ChatGPTSetting.short")}
            </option>
            <option value={IG_POST_LENGTH.MEDIUM}>
              {t("ChatGPTSetting.medium")}
            </option>
            <option value={IG_POST_LENGTH.LONG}>
              {t("ChatGPTSetting.long")}
            </option>
          </select>

          <p className="text-sm font-medium mx-1">
            <Trans>ChatGPTSetting.ig-q-10</Trans>
          </p>
          <div className="form-control my-3">
            <div className="input-group">
              <input
                onChange={(e) => {
                  setCurrentKeyword(e.target.value)
                }}
                value={currentKeyword}
                type="text"
                placeholder={t("ChatGPTSetting.keyword-to-target") as string}
                className="input input-bordered w-full "
              />
              <button
                onClick={() => handleClick()}
                className="btn btn-square btn-primary">
                <FontAwesomeIcon icon={faAdd} />
              </button>
            </div>
          </div>
          <div className="flex flex-row flex-wrap gap-2">
            {setting.keywords?.map((k: string, index: number) => {
              return (
                <div key={index}>
                  <div className="rounded-full w-auto p-2 bg-primary text-white">
                    <p>
                      {k}{" "}
                      <FontAwesomeIcon
                        className="cursor-pointer"
                        onClick={() => removeKeyword(k)}
                        icon={faX}
                      />
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </section>

        <section className="bg-white p-4 rounded-xl my-2 border border-gray-200">
          <h2 className="font-bold text-xl my-6">
            <Trans>ChatGPTSetting.ig-section-4</Trans>
          </h2>
          <p className="text-sm font-medium mx-1">
            <Trans>ChatGPTSetting.ig-section-4</Trans>
          </p>
          <textarea
            onChange={(e) => {
              updateSettingType("image_prompt", e.target.value)
            }}
            value={setting.image_prompt}
            placeholder={t("ChatGPTSetting.ig-section-4") as string}
            className="input input-bordered w-full  resize-none h-[90px] my-3"
          />
          {showAlert && (
            <div className="my-4">
              <CustomAlert
                title={"Please fill in all the fields"}
                alertType={ALERT_TYPE.ERROR}
                icon={faExclamationCircle}
                buttonType={{
                  type: BUTTON_TYPE.NONE,
                  buttonText: undefined,
                  buttonDisabledText: undefined,
                  buttonIcon: undefined,
                }}
              />
            </div>
          )}
        </section>
      </div>

      <div className="px-2 mt-4 mb-10">
        <button
          onClick={() => sendPrompt()}
          className="btn btn-primary normal-case w-full">
          Send
        </button>
      </div>
    </div>
  )
}
export default ChatGPTIGSetting
