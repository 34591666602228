import { ChatMessage } from "../../Model/ChatMessage"

const pushChatMessagesToStorage = (
  chatroomId: string,
  chatMessages: ChatMessage[]
): Promise<{ success: boolean }> => {
  return new Promise(async (resolve) => {
    if (Array.isArray(chatMessages)) {
      // step one, check the existing record
      getOldChat(chatroomId).then((result) => {
        if (result.success) {
          // if chat record < 20, direct concat
          if (result.data.length < 50) {
            const newMessages = result.data.concat(chatMessages)
            localStorage.removeItem("look4kol-cm-" + chatroomId)
            localStorage.setItem(
              "look4kol-cm-" + chatroomId,
              JSON.stringify(newMessages)
            )
          } else {
            // else, replace the whole array
            localStorage.setItem(
              "look4kol-cm-" + chatroomId,
              JSON.stringify(chatMessages)
            )
          }
          return resolve({
            success: true,
          })
        }
        // if record not found, create a new item
        localStorage.setItem(
          "look4kol-cm-" + chatroomId,
          JSON.stringify(chatMessages)
        )
      })
    } else {
      return resolve({ success: false })
    }
  })
}

const insertChatMessageToStorage = (
  chatroomId: string,
  chatMessages: ChatMessage
): Promise<{ success: boolean }> => {
  return new Promise(async (resolve) => {
    try {
      const storageItem = localStorage.getItem("look4kol-cm-" + chatroomId)
      if (storageItem && chatMessages) {
        let newStorgeItem = [...JSON.parse(storageItem)]
        newStorgeItem.unshift(chatMessages)

        localStorage.setItem(
          "look4kol-cm-" + chatroomId,
          JSON.stringify(chatMessages)
        )
        return resolve({ success: true })
      }
      return resolve({ success: true })
    } catch (e) {
      console.log(e)
    }
  })
}

const clearUpOldChat = (): void => {
  try {
    const allChatroomMessage = Object.entries(localStorage).filter(([key]) =>
      key.startsWith("look4kol-cm-")
    )

    if (
      Array.isArray(allChatroomMessage) &&
      Array.isArray(allChatroomMessage[1])
    ) {
      allChatroomMessage[1].map((chatroom) => {
        const lastUpdate: Date = new Date(
          Math.max(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...chatroom.map(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (e) => new Date(e.createDate.seconds * 1000)
            )
          )
        )

        const now = new Date()
        const sevenDay = 604800000
        if (now.valueOf() - lastUpdate.valueOf() > sevenDay) {
          // remove the whole object
          localStorage.removeItem(allChatroomMessage[0] as unknown as string)
        }
      })
    }
  } catch (e) {
    console.log(e)
  }
}

const getOldChat = (
  chatroomId: string
): Promise<
  | {
      success: true
      data: ChatMessage[]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    try {
      const storageItem = localStorage.getItem("look4kol-cm-" + chatroomId)
      if (storageItem) {
        return resolve({
          success: true,
          data: JSON.parse(storageItem),
        })
      }
      return resolve({
        success: true,
        data: [],
      })
    } catch (e) {
      return resolve({
        success: false,
      })
    }
  })
}
export {
  pushChatMessagesToStorage,
  insertChatMessageToStorage,
  clearUpOldChat,
  getOldChat,
}
