import React, { useState, useEffect } from "react"
import { getHKBankName } from "../../../src/Utils/HKBankList"

const BankAccountCard = (props) => {
  return (
    <div
      className={[
        "my-2 p-4 rounded-md bg-red-50 cursor-pointer",
        props.selectedAccNum === props.bank.accNum
          ? "border-2 border-red-300"
          : "border-2 border-red-50",
      ].join(" ")}
      onClick={() => {
        if (!props.displayOnly) {
          if (props.selectedAccNum === props.bank.accNum) {
            //    unselect the item
            props.unselectBank()
          } else {
            props.setSelectedBankAcc(props.bank)
          }
        }
      }}>
      {/*bank name*/}
      <div className="block text-red-700 font-bold">
        {getHKBankName(props.bank.bankID)}
      </div>

      {/*Card holder*/}
      <div className="w-full block uppercase text-red-700 font-bold">
        {props.bank.cardHolder}
      </div>

      {/*Card number*/}
      <div className="w-full block text-md text-red-700 font-bold">
        {props.bank.accNum}
      </div>
    </div>
  )
}
export default BankAccountCard
