import { ViewImageModalData } from "../../Model/Modal"
import React, { useEffect, useImperativeHandle, useState } from "react"

const ViewImageModalComponent = (props: ViewImageModalData) => {
  const [selectedImage, setSelectedImage] = useState<string>("")

  useEffect(() => {
    if (
      Array.isArray(props.metaData.imageUrl) &&
      props.metaData.imageUrl.length > 0
    ) {
      setSelectedImage(props.metaData.imageUrl[0])
    } else {
      setSelectedImage(props.metaData.imageUrl as string)
    }
  }, [props.metaData])

  return (
    <div className="container mx-auto overflow-y-auto overflow-x-hidden">
      <div className="w-full h-fit grid md:gap-8 md:grid-rows-1 grid-rows-2 md:grid-cols-5">
        <div className="row-span-2 md:row-span-1 md:col-span-4">
          {selectedImage !== "" && (
            <img
              className="md:h-96 h-64 mx-auto object-contain "
              src={selectedImage}
            />
          )}

          {/*<img src={selectedImage}*/}
          {/*     className="items-center m-4 max-w-96 h-auto  object-contain inline-block lazyload  h-full object-cover my-1 rounded"*/}
          {/*/>*/}
        </div>
        <div className="md:col-span-1 grid grid-cols-4 md:block">
          {Array.isArray(props.metaData.imageUrl) &&
            props.metaData.imageUrl.map((url, index) => {
              if (url !== "") {
                return (
                  <img
                    src={url}
                    key={"img-pre" + index}
                    className={
                      selectedImage === url
                        ? "cursor-pointer md:items-center md:m-4 border-2 w-24 object-contain border-rose-300 inline-block lazyload object-cover md:my-0.5 my-1   rounded-lg"
                        : "cursor-pointer md:items-center md:m-4 border-2 border-slate-200  w-24 object-contain inline-block lazyload object-cover md:my-0.5 my-1 rounded-lg"
                    }
                    onClick={(e) => setSelectedImage(url)}
                  />
                )
              }
            })}
        </div>
      </div>
    </div>
  )
}

export default ViewImageModalComponent
