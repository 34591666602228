import React from "react"
import { ContractAcceptModalData } from "../../Model/Modal"
import { Trans } from "react-i18next"

const ContractAcceptModalComponent = (props: ContractAcceptModalData) => {
  const tempArr: string[] = []
  return (
    <div className="container mx-auto overflow-hidden">
      <div>
        <Trans>ContractAcceptModal.title</Trans>
      </div>

      <p>
        <Trans>ContractAcceptModal.des</Trans>
      </p>
    </div>
  )
}

export default ContractAcceptModalComponent
