import React from "react"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from "../Assets/icons-72.png"

const Look4kolLogo: React.FC = () => {
  return (
    <div
      className="mx-auto grid grid-cols-2 py-4"
      style={{ gridTemplateColumns: "50px auto" }}>
      <img
        className="h-10 w-auto sm:h-10 inline-block"
        src={logo}
        alt="look4kol logo"
      />
      <div
        style={{
          fontFamily: "Ubuntu, sans-serif",
        }}
        className="  font-bold inline-block text-2xl text-red-default my-auto ml-2">
        Look4KOL
      </div>
    </div>
  )
}
export default Look4kolLogo
