import React, { useState } from "react"
import { Trans } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBirthdayCake,
  faBookmark,
  faChevronDown,
  faComment,
  faGlobeAsia,
  faHeart,
  faMouse,
  faPaperPlane,
  faUsersCog,
  faVenusMars,
} from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"

const CaseStudyResultIG = (props) => {
  const { t, i18n } = useTranslation()

  return (
    <div className="rounded-2xl px-6 py-4 shadow-md border border-gray-100">
      <div
        className="grid my-8"
        style={{ gridTemplateColumns: "25% 25% 25% 25%" }}>
        <div>
          <div className="text-center text-gray-900 text-lg">
            {props.data.ig_like}
          </div>
          <div className="text-center text-gray-700 text-md">
            <FontAwesomeIcon icon={faHeart} className="" />
          </div>
        </div>
        <div>
          <div className="text-center text-gray-900 text-lg">
            {props.data.ig_comment}
          </div>
          <div className="text-center text-gray-700 text-md">
            <FontAwesomeIcon icon={faComment} className="" />
          </div>
        </div>
        <div>
          <div className="text-center text-gray-900 text-lg">
            {props.data.ig_share}
          </div>
          <div className="text-center text-gray-700 text-md">
            <FontAwesomeIcon icon={faPaperPlane} className="" />
          </div>
        </div>
        <div>
          <div className="text-center text-gray-900 text-lg">
            {props.data.ig_save}
          </div>
          <div className="text-center text-gray-700 text-md">
            <FontAwesomeIcon icon={faBookmark} className="" />
          </div>
        </div>
      </div>
      <hr />
      <div className="block">
        <ul>
          <li className="my-4">
            <div
              className="grid gap-2"
              style={{ gridTemplateColumns: "50px auto 100px" }}>
              <div>
                <FontAwesomeIcon
                  icon={faUsersCog}
                  className="text-center text-xl text-red-700"
                />
              </div>
              <div className="text-gray-700 text-md">
                <Trans>EditCaseStudy.ig-total-engagement</Trans>
              </div>
              <div>{props.data.ig_total_engagement}</div>
            </div>
          </li>

          <li className="my-4">
            <div
              className="grid gap-2"
              style={{ gridTemplateColumns: "50px auto 100px" }}>
              <div>
                <FontAwesomeIcon
                  icon={faMouse}
                  className="text-center text-xl text-red-700"
                />
              </div>
              <div className="text-gray-700 text-md">
                <Trans>EditCaseStudy.ig-total-action</Trans>
              </div>
              <div>{props.data.ig_total_action}</div>
            </div>
          </li>

          {/*<li className="my-4">*/}
          {/*    <div className='grid'*/}
          {/*         style={{gridTemplateColumns: "50px auto 100px"}}*/}
          {/*    >*/}
          {/*        <div>*/}
          {/*            <FontAwesomeIcon icon={faBirthdayCake} className="text-center text-xl text-red-700"/>*/}
          {/*        </div>*/}
          {/*        <div className="text-gray-700 text-md">*/}
          {/*            <Trans>KOLFollowerGroup.followers-age-group</Trans>*/}
          {/*        </div>*/}
          {/*        <div>*/}
          {/*            {(FOLLOWERS_AGE_GROUP.find((e) => e.value === props.data.followersAgeGroup)).label}*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</li>*/}

          {/*<li className="my-4">*/}
          {/*    <div className='grid'*/}
          {/*         style={{gridTemplateColumns: "50px auto 100px"}}*/}
          {/*    >*/}
          {/*        <div>*/}
          {/*            <FontAwesomeIcon icon={faVenusMars} className="text-center text-xl text-red-700"/>*/}
          {/*        </div>*/}
          {/*        <div className="text-gray-700 text-md">*/}
          {/*            <Trans>KOLFollowerGroup.followers-gender</Trans>*/}
          {/*        </div>*/}
          {/*        <div>*/}
          {/*            {t((FOLLOWERS_GENDER.find(e => e.value === props.data.followersGender)).label)}*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</li>*/}

          {/*<li className="my-4">*/}
          {/*    <div className='grid'*/}
          {/*         style={{gridTemplateColumns: "50px auto 100px"}}*/}
          {/*    >*/}
          {/*        <div>*/}
          {/*            <FontAwesomeIcon icon={faGlobeAsia} className="text-center text-xl text-red-700"/>*/}
          {/*        </div>*/}
          {/*        <div className="text-gray-700 text-md">*/}
          {/*            <Trans>KOLFollowerGroup.followers-country</Trans>*/}
          {/*        </div>*/}
          {/*        <div>*/}
          {/*            {getCountry(props.data.followersCountry).label}*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</li>*/}
        </ul>
      </div>
    </div>
  )
}

export default CaseStudyResultIG
