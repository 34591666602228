import { Reducer } from "react"
import * as List from "../../Utils/ModalType"
import Modal from "../../Model/Modal"
import { MODAL_TYPE } from "../../Enum/MODAL_TYPE"
import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"
import { APP_TYPE } from "../../Enum/APP_TYPE"

interface actionTypes {
  type: string
  Modal: Modal
  typeOfModal: MODAL_TYPE
  returnFn: () => void | null
  metaData: null
  typeLimit?: APP_TYPE
  uploadPath?: string
  limit?: number
}

export interface modalInitStateType {
  Modal: Modal
  returnFn: (() => void) | null
  metaData: null
  typeLimit?: APP_TYPE
  uploadPath?: string
  limit?: number
}

const initialState: modalInitStateType = {
  Modal: new Modal("", false),
  returnFn: null,
  metaData: null,
  typeLimit: APP_TYPE.NONE,
  uploadPath: "",
  limit: 1,
}

const ModalController: Reducer<modalInitStateType, actionTypes> = (
  state = initialState,
  action
) => {
  const modalTypes = Object.values(List.SUPPORTED_MODAL_TYPE)
  if (action.type === SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL) {
    document.getElementsByTagName("body")[0].style.overflowY = "hidden"
    if (action.typeOfModal === List.SUPPORTED_MODAL_TYPE.UPLOAD_FILE_MODAL) {
      return {
        ...state,
        Modal: new Modal(action.typeOfModal, true),
        returnFn: action.returnFn,
        metaData: action.metaData,
        uploadPath: action.uploadPath,
        typeLimit: action.typeLimit,
        limit: action.limit,
      }
    } else if (modalTypes.find((type) => type === action.typeOfModal)) {
      return {
        ...state,
        Modal: new Modal(action.typeOfModal, true),
        returnFn: action.returnFn,
        metaData: action.metaData,
      }
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.CLOSE_MODAL) {
    document.getElementsByTagName("body")[0].style.overflowY = "scroll"
    return {
      ...state,
      Modal: new Modal("", false),
    }
  }

  return state
}

export default ModalController
