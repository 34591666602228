import { useDispatch, useSelector } from "react-redux"
import { PageType } from "../../Model/ProjectManagement"
import ChatKOLInfo from "../c/ChatKOLInfo"
import ChatText from "../c/chat/ChatText"
import Contract from "../c/contract/Contract"
import JobListProject from "./ProjectRightSection/JobListProject"
import KOLListProject from "./ProjectRightSection/KOLListProject"
import ChatNotExist from "./ProjectRightSection/ChatNotExist"
import React from "react"
import { getUserLang } from "../../Utils/utiltyHelper"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ProjectLinks from "./ProjectRightSection/ProjectLinks"

const ProjectPageOptionBar = () => {
  const dispatch = useDispatch()
  const page: PageType = useSelector((state: any) => {
    return state.ProjectManager.page
  })
  const pagePropsID: string = useSelector((state: any) => {
    return state.ProjectManager.pagePropsID
  })
  const isExpanded: boolean = useSelector((state: any) => {
    return state.ProjectManager.isExpanded
  })

  const setIsExpanded = (expand: boolean) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_PROJECT_PAGE_EXPAND,
      data: expand,
    })
  }
  const setPage = (page: PageType) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_PROJECT_PAGE,
      data: page,
    })
  }

  const isSpecialProjectPage = () => {
    if (
      page === PageType.KOL ||
      page === PageType.Job ||
      page === PageType.ProjectLinks
    ) {
      return true
    }
    return false
  }

  if (isExpanded) {
    return (
      <div
        className="overflow-y-scroll"
        style={{
          height: "calc( 100vh - 75px)",
        }}>
        {page === PageType.Main && (
          <div className={"h-full"}>
            <iframe
              src={`https://docs.look4kol.com/${
                getUserLang() === "hk" ? "zh" : "eng"
              }/project-tools`}
              className="w-full h-full"
            />
          </div>
        )}
        {!isSpecialProjectPage() && (
          <div className="sticky h-16 top-0 px-1 w-full bg-white z-10 border-b ">
            <button
              onClick={() => {
                setIsExpanded(true)
                setPage(PageType.ProjectLinks)
              }}
              className="font-medium text-lg my-2 btn btn-ghost">
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          </div>
        )}

        {page === PageType.KOL && <KOLListProject />}
        {page === PageType.Job && <JobListProject />}
        {page === PageType.JobPreview && <Contract id={pagePropsID} />}
        {page === PageType.KOLPreview && <ChatKOLInfo />}
        {page === PageType.ChatPreview && (
          <ChatText isAdmin={false} id={pagePropsID} />
        )}
        {page === PageType.ChatNotExist && <ChatNotExist />}
        {page === PageType.ProjectLinks && <ProjectLinks />}
      </div>
    )
  }
  return <div></div>
}

export default ProjectPageOptionBar
