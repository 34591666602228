import { DateObj } from "./Utilities"

export interface CustomerType {
  id: string
  avatarUrl: string
  companyName: string
  companySize: string
  companyType: string
  contactEmail: string
  contactNumber: string
  createDate: DateObj | Date
  description: string
  firstName: string
  isPublish: boolean
  isVerified: boolean
  kolCompareGroup: string[]
  lastModified: DateObj | Date
  lastName: string
  roleInCompany: string
  website: string
}

export const customerDefault: CustomerType = {
  id: "",
  avatarUrl: "",
  companyName: "",
  companySize: "",
  companyType: "",
  contactEmail: "",
  contactNumber: "",
  createDate: new Date(),
  description: "",
  firstName: "",
  isPublish: false,
  isVerified: false,
  kolCompareGroup: [],
  lastModified: new Date(),
  lastName: "",
  roleInCompany: "",
  website: "",
}

export interface CustomerUpdateType {
  avatarUrl: string
  companyName: string
  companySize: string
  companyType: string
  contactEmail: string
  contactNumber: string
  description: string
  firstName: string
  lastName: string
  roleInCompany: string
  website: string
}

export const customerUpdateDefault: CustomerUpdateType = {
  avatarUrl: "",
  companyName: "",
  companySize: "",
  companyType: "",
  contactEmail: "",
  contactNumber: "",
  description: "",
  firstName: "",
  lastName: "",
  roleInCompany: "",
  website: "",
}
