import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "./Assets/i18n/i18n"
import "lazysizes"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

// Sentry.init({
//   dsn: "https://98730257f0d3461481516b1b5fd966c3@o1337607.ingest.sentry.io/6607615",
//   integrations: [new BrowserTracing()],
//   // debug: process.env.NODE_ENV !== "production",
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// })

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

serviceWorkerRegistration.unregister()
