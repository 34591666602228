import React from "react"
import ZestSystem from "./ZestSystem/ZestSystem"

const OverlaySystem: React.FC = (props) => {
  return (
    <>
      <ZestSystem />
    </>
  )
}
export default OverlaySystem
