import React from "react"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "./ChatText.module.css"
import {
  chatroomUrlConvertor,
  sizeConverter,
  getHourTime,
  timeAgo,
} from "../../../Utils/Converter"
import parse from "html-react-parser"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircle,
  faDownload,
  faEllipsis,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons"
import {
  faBookmark,
  faComment,
  faHeart,
  faPaperPlane,
} from "@fortawesome/free-regular-svg-icons"
import { getFileTypeImage } from "../../../Utils/FileTypeImageConverter"
import { Trans } from "react-i18next"
import { Link, useHistory, useLocation, useParams } from "react-router-dom"
import { ASSET_TYPE } from "../../../Enum/APP_TYPE"
import {
  CHATGPT_SETTING_TYPE,
  ChatMessage,
  COMMEND_TYPE,
  defaultChatGPTTextSettingType,
} from "../../../Model/ChatMessage"
import KOL from "../../../Model/KOL"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import l4kLogo from "../../../Assets/icons-72.png"
import classNames from "classnames"
import { getUserLang } from "../../../Utils/utiltyHelper"
import { getQuery, useQuery } from "../../../Utils/QueryHelper"
import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"

interface propsState {
  item: ChatMessage
  //whether the user is read this message already
  readMsg: boolean
  //whether the msg is in a chatBubble or draftContainer
  draftMsg?: boolean
  kol?: KOL
  customerName?: string
  auth?: any
  //applicable when chatroom is in project page
  chatroomID?: string
}

const MessageManager: React.FC<propsState> = ({
  item,
  readMsg,
  draftMsg,
  kol,
  customerName,
  auth,
  chatroomID,
}) => {
  try {
    let history = useHistory()

    const paramList = useSelector((state: any) => {
      return state.QueryManager.params
    })

    let params = {
      type: getQuery(paramList, "type"),
      chatroomID: getQuery(paramList, "chatroomID"),
      metaPath: getQuery(paramList, "metaPath"),
      viewAs: getQuery(paramList, "viewAs"),
    }

    //check null
    if (item) {
      if (
        item.type === ASSET_TYPE.CHATGPT_TEXT_QUESTION ||
        item.type === ASSET_TYPE.CHATGPT_TEXT_REPLY
      ) {
        return (
          <div
            className={[
              classes.messageText,
              item.createUserID !== "" ? classes.messageTextWithMaxWidth : "",
            ].join(" ")}
            id={"text" + item.id}>
            <div className={classNames({ truncate: draftMsg })}>
              <span className="badge mb-1 block">
                {item.setting.commendType}
              </span>
              {item.msg}
            </div>
            {/* Chatgpt setting*/}
            {item.setting.commendType === COMMEND_TYPE.TEXT && (
              <div className="my-2"></div>
            )}

            <div className={classes.Time}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
              {/* @ts-ignore*/}
              {getHourTime(item.createDate.seconds)}
            </div>
          </div>
        )
      } else if (item.type === ASSET_TYPE.CHATGPT_IG) {
        return (
          <div>
            <div className="flex flex-row gap-2 items-center mb-2 h-10 p-1">
              <img
                src={auth.role === "kol" && kol ? kol.avatarUrl : l4kLogo}
                className="object-cover w-8 h-8 rounded-full"
              />
              <p className="text-sm font-bold my-2">
                {" "}
                {auth.role === "kol" && kol ? kol.userName : customerName}
              </p>
              <p className="text-xl h-full py-auto text-gray-500">.</p>
              <p className="text-gray-500 text-sm">
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                {/* @ts-ignore*/}
                {getHourTime(item.createDate.seconds)}
              </p>
            </div>

            <div className="mb-2 ">
              <img className="rounded" src={item.thumbnailUrl.url} />
            </div>
            <div className="flex flex-row items-start md:text-[22px] text-[20px] gap-4 mx-2 md:mb-4 mb-2">
              <p>
                <FontAwesomeIcon icon={faHeart} />
              </p>
              <p>
                <FontAwesomeIcon icon={faComment} />
              </p>
              <p>
                {" "}
                <FontAwesomeIcon icon={faPaperPlane} />
              </p>
              <p className="ml-auto">
                <FontAwesomeIcon icon={faBookmark} />
              </p>
            </div>
            <p className="text-sm font-bold mx-2">20,127 likes</p>
            <p className="text-sm mx-2 md:my-2 my-1">
              <span className="font-bold mr-2">
                {auth.role === "kol" && kol ? kol.userName : customerName}
              </span>
              {item.ig_post_desc}
            </p>
            <p className="text-gray-400 mx-2">View all 263 comments</p>
          </div>
        )
      } else if (
        item.type === ASSET_TYPE.TEXT ||
        item.type === ASSET_TYPE.CHATGPT_TEXT
      ) {
        return (
          <div
            className={classNames(
              classes.messageText,
              item.createUserID !== "" ? classes.messageTextWithMaxWidth : ""
            )}
            id={"text" + item.id}>
            <div className={classNames({ "truncate mt-4": draftMsg })}>
              <span
                dangerouslySetInnerHTML={{
                  __html: item.msg.replace(
                    /(https?:\/\/[^\s]+)/g,
                    "<a target='_blank' class='underline text-red-800' href='$&'>$&</a>"
                  ),
                }}></span>
            </div>
            <div className={classes.Time}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
              {/* @ts-ignore*/}
              {getHourTime(item.createDate.seconds)}
            </div>
          </div>
        )
      } else if (item.type === ASSET_TYPE.LINK_TEXT) {
        let systemString: string
        if (localStorage.getItem("i18n-lang") === "en") {
          systemString = item.msg.split("]")[0] + "]" + item.msg.split("\n")[1]
        } else {
          systemString = item.msg.split("\n")[0]
        }
        return (
          <div
            className={[
              "cursor-pointer md:px-0 px-4",
              classes.messageText,
              item.createUserID !== "" ? classes.messageTextWithMaxWidth : "",
            ].join(" ")}
            id={"link-text" + item.id}>
            <div
              className={[
                classes.LinkContainer,
                "bg-blue-100 text-gray-700",
              ].join(" ")}
              onClick={() => {
                if ("path" in item) {
                  let searchParams = chatroomUrlConvertor(item.path)

                  if (params.viewAs) {
                    searchParams += "&viewAs=" + params.viewAs
                  }
                  history.push({
                    pathname: "/" + getUserLang() + "/c",
                    search: "?" + searchParams,
                  })
                }
              }}>
              <FontAwesomeIcon icon={faExternalLinkAlt} />
              {parse(systemString)}
            </div>

            <div className={classes.Time}>
              {getHourTime(item.createDate.seconds)}
            </div>
          </div>
        )
      } else if (item.type === ASSET_TYPE.IMAGE) {
        return (
          <div
            className={[
              classes.messageText,
              item.createUserID !== "" ? classes.messageTextWithMaxWidth : "",
            ].join(" ")}
            id={"image" + item.id}>
            <figure
              className="cursor-pointer max-h-42 lg:my-4 w-full"
              onClick={() => {
                if ("url" in item) {
                  window.open(item.url, "_blank")
                }
              }}>
              <img
                src={item.thumbnailUrl}
                className="rounded-xl w-full h-52 object-cover"
              />
            </figure>
            <div className={classes.ImageDetail}>
              {item.fileName + " | " + sizeConverter(item.fileSize)}
            </div>
            <div className={classes.Time}>
              {getHourTime(item.createDate.seconds)}
            </div>
          </div>
        )
      } else if (item.type === ASSET_TYPE.FILE) {
        return (
          <div
            className={[
              classes.messageText,
              item.createUserID !== "" ? classes.messageTextWithMaxWidth : "",
            ].join(" ")}
            id={"image" + item.id}>
            <img
              onClick={() => {
                if (item.url) {
                  window.open(item.url as string, "_blank")
                }
              }}
              src={getFileTypeImage(
                item.fileName.split(".")[item.fileName.split(".").length - 1]
              )}
              className="cursor-pointer max-h-42 rounded-xl w-full h-52 object-cover"
            />
            <hr className="border-gray-200 my-2" />
            <div className={classes.ImageDetail}>
              {item.fileName + " | " + sizeConverter(item.fileSize)}
            </div>
            <div className={classes.Time}>
              {getHourTime(item.createDate.seconds)}
            </div>
          </div>
        )
      } else if (item.type === ASSET_TYPE.FILE_REF) {
        /*eslint-disable */
        const split = item.path.split("/").splice(1, 1).join("/")
        return (
          <div
            className={[
              classes.messageText,
              item.createUserID !== "" ? classes.messageTextWithMaxWidth : "",
              "grid w-full",
            ].join(" ")}
            id={"file-ref" + item.id}
            style={{ gridTemplateColumns: "50px auto 50px" }}>
            <div
              onClick={() =>
                history.push({
                  pathname: "/" + getUserLang() + "/c",
                  search:
                    "?" +
                    // @ts-ignore
                    new URLSearchParams({
                      chatroomID: chatroomID ? chatroomID : params.chatroomID,
                      type: "file",
                      metaPath: item.path.replace(/\//g, "+"),
                    }),
                })
              }>
              <img
                src={getFileTypeImage(
                  item.fileName.split(".")[item.fileName.split(".").length - 1]
                )}
              />
            </div>
            <div
              className={classes.ImageDetail}
              onClick={() =>
                history.push({
                  pathname: "/" + getUserLang() + "/c",
                  search:
                    "?" +
                    // @ts-ignore
                    new URLSearchParams({
                      chatroomID: chatroomID ? chatroomID : params.chatroomID,
                      type: "file",
                      metaPath: item.path.replace(/\//g, "+"),
                    }),
                })
              }>
              {item.fileName + " | " + sizeConverter(item.fileSize)}
              <div className="block">
                <Trans>MessageManager.file-location</Trans>
                {item.path.split("/").length <= 2
                  ? item.path.split("/")[0]
                  : split}
              </div>
            </div>
            <button
              className="px-2 rounded text-lg"
              onClick={() => {
                if (item.url) {
                  window.open(item.url, "_blank")
                }
              }}>
              <FontAwesomeIcon icon={faDownload} />
            </button>
          </div>
        )
      } else {
        return <div></div>
      }
    }

    return <div></div>
  } catch (e) {
    return <div></div>
  }
}
export default MessageManager
