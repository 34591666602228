import {
  FBAccount,
  FBAccountPageResult,
  FBAccountsResult,
  IGInsightResult,
} from "../../Model/FacebookAPI"
import {
  IG_USER_CRAWLER_DATA,
  IG_USER_INSIGHTS_DATA,
  IG_USER_NORMAL_DATA,
} from "../../Enum/LOGIN_PAGE_TYPE"
import { CSMediaInsight, CSMediaListData } from "../../Model/CaseStudy"
import { isLocalhost, isWebView } from "../../Utils/utiltyHelper"
import { returnIdTokenResult } from "../../Utils/FirebaseUtils"
import { getAPIPath, isAndroid, isIOS } from "../../Utils/HelpingFunction"
import { igTab } from "../../Component/ModalComponents/CaseStudyIGModal/CaseStudySelectMedia"

const loadFbLoginAPI = (): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  window.fbAsyncInit = function () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.FB.init({
      appId: process.env.REACT_APP_FACEBOOK_API_APP_ID,
      cookie: true,
      xfbml: true,
      version: "v19.0",
    })
  }

  console.log("Loading fb api")

  // Load the SDK asynchronously
  ;(function (d, s, id) {
    let js,
      fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) return
    js = d.createElement(s)
    js.id = id
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    js.src = "//connect.facebook.net/en_US/all.js"
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    fjs.parentNode.insertBefore(js, fjs)
  })(document, "script", "facebook-jssdk")
}

interface IgData {
  id: string
  ig_id: string
  username: string
  biography: string
  media_count: number
  followers_count: number
  follows_count: number
  profile_picture_url: string
}

interface IgMedia {
  data: [IgMediaObject]
}

export enum IgMediaType {
  IMAGE = "IMAGE",
  CAROUSEL = "CAROUSEL_ALBUM",
  REEL = "VIDEO",
  STORY = "STORY",
}

interface IgMediaObject {
  media_type: IgMediaType
  media_url: string
  thumbnail_url?: string
  like_count: number
  comments_count: number
}

const fbLogout = (): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  window.FB.logout(function (response) {
    console.log(response)
  })
}

const getFBLoginToken = (path: string): string => {
  const redirectUrl = isLocalhost()
    ? "https://localhost:3000/fb_handler"
    : process.env.REACT_APP_HOST_NAME + "/fb_handler"

  return `https://www.facebook.com/v19.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_API_APP_ID}&redirect_uri=${redirectUrl}&state=${path}&scope=pages_show_list,business_management,instagram_manage_insights,public_profile,instagram_basic,pages_read_engagement`
}

const getFBToken = (
  uid: string,
  code: string
): Promise<
  | {
      success: true
      data: {
        access_token: string
        expires_in: number
        token_type: string
      }
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve, reject) => {
    const redirectUrl = isLocalhost()
      ? "https://localhost:3000/fb_handler"
      : process.env.REACT_APP_HOST_NAME + "/fb_handler"

    returnIdTokenResult().then(async (res) => {
      await fetch(
        getAPIPath(
          "/api/kol/" +
            uid +
            "/fbToken?" +
            new URLSearchParams({
              code: code,
              redirect_uri: redirectUrl,
            })
        ),
        {
          method: "GET",
          headers: { "Content-Type": "application/json", idToken: res.token },
        }
      )
        .then((res) => res.json())
        .then((finalResponse) => {
          return resolve(finalResponse)
        })
        .catch((err) => {
          console.log(err)
        })
    })
  })
}

const returnApprovedPageWithToken = (
  token: string
): Promise<{
  success: boolean
  approvedPages: FBAccount[]
}> => {
  return new Promise((resolve) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.FB.getLoginStatus(function (response) {
      console.log(response)
    })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.FB.api(
      "/me/accounts",
      "GET",
      { fields: "name,id", access_token: token },
      function (response1: FBAccountsResult) {
        if (Array.isArray(response1.data)) {
          return resolve({
            success: true,
            approvedPages: response1.data,
          })
        }
        return resolve({
          success: false,
          approvedPages: [],
        })
      }
    )
  })
}

const returnApprovedPageWithoutToken = (): Promise<{
  success: boolean
  approvedPages: FBAccount[]
}> => {
  return new Promise((resolve) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.FB.getLoginStatus(function (response) {
      console.log(response)
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.FB.login(
      function (response: any) {
        console.log(response)
        if (response.authResponse && response.status === "connected") {
          console.log("approved")
          //call api to get all pages connected to facebook account

          //permissions
          /**
           * instagram_basic
           * pages_show_list
           * instagram_manage_insights
           * public_profile
           * pages_read_engagement
           */

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          window.FB.api(
            "/me/accounts",
            "GET",
            { fields: "name,id" },
            function (response1: FBAccountsResult) {
              if (Array.isArray(response1.data)) {
                return resolve({
                  success: true,
                  approvedPages: response1.data,
                })
              }
              return resolve({
                success: false,
                approvedPages: [],
              })
            }
          )
        } else {
          return resolve({
            success: false,
            approvedPages: [],
          })
        }
      },
      {
        scope:
          "pages_show_list,business_management,instagram_manage_insights,public_profile,instagram_basic,pages_read_engagement",
      }
    )
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const returnApprovedPage = async (): Promise<{
  success: boolean
  approvedPages: FBAccount[]
}> => {
  const fbToken = localStorage.getItem("look4kol-fb-code")
  const fbTokenExpiry = localStorage.getItem("look4kol-fb-code-expiry")

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let expiry = !isNaN(parseInt(fbTokenExpiry)) ? parseInt(fbTokenExpiry) : 0

  if (fbToken && new Date(expiry) > new Date()) {
    return await returnApprovedPageWithToken(fbToken)
  } else if (isAndroid() || isIOS() || isWebView()) {
    window.location.replace(getFBLoginToken(window.location.pathname))
  } else {
    return await returnApprovedPageWithoutToken()
  }
}

const returnIGUsername = (
  fbPageID: string
): Promise<{ success: true; username: string } | { success: false }> => {
  return new Promise((resolve) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.FB.login(
      function (response: any) {
        if (response.authResponse && response.status === "connected") {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          window.FB.api(
            `/${fbPageID}`,
            "GET",
            { fields: "name,id,username" },
            function (response: any) {
              if (response) {
                return resolve({
                  success: true,
                  username: response.username,
                })
              }
              return resolve({
                success: false,
              })
            }
          )
        } else {
          return resolve({
            success: false,
          })
        }
      },
      {
        scope:
          "pages_show_list,business_management,instagram_manage_insights,public_profile,instagram_basic,pages_read_engagement",
      }
    )
  })
}
const returnPageDataAndInsights = (
  fbPageID: string
): Promise<
  | {
      success: true
      igState: IG_USER_NORMAL_DATA
      igInsights?: IG_USER_INSIGHTS_DATA
      igMedia: IG_USER_CRAWLER_DATA
      fbID?: string
      nextMediaPage?: string
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    const fbToken = localStorage.getItem("look4kol-fb-code")
    const baseParam = { fields: "instagram_business_account" }

    const tokenParam = {
      ...baseParam,
      access_token: fbToken,
    }

    const params = fbToken ? tokenParam : baseParam

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.FB.api(
      "/" + fbPageID,
      "GET",
      params,
      async function (response: FBAccountPageResult) {
        // if this page connected a ig account
        if (response.instagram_business_account) {
          const igState = await handleIGData(
            response.instagram_business_account.id
          )
          const igInsights = await handleIGInsightsReport(
            response.instagram_business_account.id
          )
          let numFollowers = 0
          if (igState.success) {
            numFollowers = igState.data.numOfFollowers
          } else {
            numFollowers = 0
          }

          const igMedia = await handleIGMediaData(
            response.instagram_business_account.id,
            numFollowers
          )

          // if both ig data and ig insight is fulfilled
          if (igState.success && igInsights.success && igMedia.success) {
            return resolve({
              success: true,
              igState: igState.data,
              igInsights: igInsights.data,
              igMedia: igMedia.data,
              fbID: response.instagram_business_account.id,
              nextMediaPage: igMedia.nextPage,
            })
          } else if (igState.success && igMedia.success) {
            return resolve({
              success: true,
              igState: igState.data,
              igMedia: igMedia.data,
              fbID: response.instagram_business_account.id,
              nextMediaPage: igMedia.nextPage,
            })
          }
        } else {
          return resolve({
            success: false,
          })
        }
      }
    )
  })
}

const handleIGInsightsReport = (
  id: string
): Promise<
  | {
      success: true
      data: IG_USER_INSIGHTS_DATA
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    const igCity = await handleIGInsightsCity(id)
    const igCountry = await handleIGInsightsCountry(id)
    const igGenderAge = await handleIGInsightsGenderAge(id)

    if (igCity.success && igCountry.success && igGenderAge.success) {
      return resolve({
        success: true,
        data: {
          lastUpdate: new Date(),
          audience_city: igCity.data,
          audience_country: igCountry.data,
          audience_gender_age: igGenderAge.data,
          audience_locale: [],
        },
      })
    }
    return resolve({
      success: false,
    })
  })
}

const handleIGInsightsCity = (
  id: string
): Promise<{ success: true; data: object } | { success: false }> => {
  return new Promise((resolve) => {
    const fbToken = localStorage.getItem("look4kol-fb-code")
    const baseParam = {
      metric: "follower_demographics",
      period: "lifetime",
      metric_type: "total_value",
      breakdown: "city",
    }

    const tokenParam = {
      ...baseParam,
      access_token: fbToken,
    }

    const params = fbToken ? tokenParam : baseParam

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.FB.api(
      "/" + id + "/insights",
      "GET",
      params,
      function (response: any) {
        try {
          const data = response.data[0].total_value.breakdowns[0].results
          const cityData: { [key: string]: number } = {}

          data.forEach((d: any) => {
            const city_name = d.dimension_values[0]
            const value = d.value
            cityData[city_name] = value
          })
          return resolve({
            success: true,
            data: cityData,
          })
        } catch (e) {
          console.log(e)
          return resolve({
            success: false,
          })
        }
      }
    )
  })
}
const handleIGInsightsCountry = (
  id: string
): Promise<{ success: true; data: object } | { success: false }> => {
  return new Promise((resolve) => {
    const fbToken = localStorage.getItem("look4kol-fb-code")
    const baseParam = {
      metric: "follower_demographics",
      period: "lifetime",
      metric_type: "total_value",
      breakdown: "country",
    }

    const tokenParam = {
      ...baseParam,
      access_token: fbToken,
    }

    const params = fbToken ? tokenParam : baseParam

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.FB.api(
      "/" + id + "/insights",
      "GET",
      params,
      function (response: any) {
        try {
          const data = response.data[0].total_value.breakdowns[0].results
          const countryData: { [key: string]: number } = {}

          data.forEach((d: any) => {
            const country = d.dimension_values[0]
            const value = d.value
            countryData[country] = value
          })

          return resolve({
            success: true,
            data: countryData,
          })
        } catch (e) {
          console.log(e)
          return resolve({
            success: false,
          })
        }
      }
    )
  })
}

const handleIGInsightsGenderAge = (
  id: string
): Promise<{ success: true; data: object } | { success: false }> => {
  return new Promise((resolve) => {
    const fbToken = localStorage.getItem("look4kol-fb-code")
    const baseParam = {
      metric: "follower_demographics",
      period: "lifetime",
      metric_type: "total_value",
      breakdown: "gender,age",
    }

    const tokenParam = {
      ...baseParam,
      access_token: fbToken,
    }

    const params = fbToken ? tokenParam : baseParam

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.FB.api(
      "/" + id + "/insights",
      "GET",
      params,
      function (response: any) {
        try {
          const data = response.data[0].total_value.breakdowns[0].results
          const genderAgeData: { [key: string]: number } = {}

          data.forEach((d: any) => {
            const gender = d.dimension_values[0]
            const ageRange = d.dimension_values[1]
            const value = d.value

            const key = `${gender}.${ageRange}`

            genderAgeData[key] = value
          })

          return resolve({
            success: true,
            data: genderAgeData,
          })
        } catch (e) {
          console.log(e)
          return resolve({
            success: false,
          })
        }
      }
    )
  })
}

const handleIGData = (
  id: string
): Promise<
  | {
      success: true
      data: IG_USER_NORMAL_DATA
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    const fbToken = localStorage.getItem("look4kol-fb-code")
    const baseParam = {
      fields:
        "id, ig_id, username,biography,media_count,followers_count,follows_count,profile_picture_url",
    }

    const tokenParam = {
      ...baseParam,
      access_token: fbToken,
    }

    const params = fbToken ? tokenParam : baseParam

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.FB.api("/" + id, "GET", params, function (response: IgData) {
      try {
        return resolve({
          success: true,
          data: {
            id: response.id,
            ig_id: response.ig_id,
            username: response.username,
            biography: response.biography,
            numOfPosts: response.media_count,
            numOfFollowers: response.followers_count,
            numOfFollowing: response.follows_count,
            avatar_url: response.profile_picture_url,
          },
        })
      } catch (e) {
        console.log(e)
        return resolve({
          success: false,
        })
      }
    })
  })
}

export const handleIGMediaData = (
  id: string,
  followersCount: number,
  afterPage?: string
): Promise<
  | {
      success: true
      data: IG_USER_CRAWLER_DATA
      nextPage?: string
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    const fbToken = localStorage.getItem("look4kol-fb-code")
    const baseParam = {
      fields:
        "id,caption,comments_count,like_count,media_type,media_url,thumbnail_url,timestamp,permalink",
      limit: 18,
      after: afterPage ? afterPage : "",
    }

    const tokenParam = {
      ...baseParam,
      access_token: fbToken,
    }

    const params = fbToken ? tokenParam : baseParam

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.FB.api("/" + id + "/media", "GET", params, function (response: any) {
      try {
        let afterPage = ""
        let mediaUrl: string[] = []
        let engagementCountList: number[] = []
        let engagementCount = 0
        let engagementRate = 0

        if (response.data.length > 0) {
          if (response.data.length === 18) {
            afterPage = response.paging?.cursors?.after
          }

          response.data.forEach((post: IgMediaObject) => {
            mediaUrl.push(
              post.media_type === "VIDEO"
                ? (post.thumbnail_url as string)
                : post.media_url
            )
            engagementCountList.push(post.like_count + post.comments_count)
          })

          if (engagementCountList.length > 0) {
            engagementCountList.forEach((post_engage) => {
              engagementCount += post_engage
            })

            engagementRate = (engagementCount / 9 / followersCount) * 100
          }
        } else {
          mediaUrl = []
          engagementRate = 0
        }

        return resolve({
          success: true,
          data: {
            engagementRate: engagementRate,
            imagesList: mediaUrl,
            lastUpdate: new Date(),
          },
          nextPage: afterPage,
        })
      } catch (e) {
        console.log(e)
        return resolve({
          success: false,
        })
      }
    })
  })
}

const listCSMedia = (
  fbPageID: string,
  type: igTab,
  nextPage?: string
): Promise<
  | {
      success: true
      igMediaList: CSMediaListData[]
      nextPage?: string
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    const fbToken = localStorage.getItem("look4kol-fb-code")
    const baseParam = { fields: "instagram_business_account" }

    const tokenParam = {
      ...baseParam,
      access_token: fbToken,
    }

    const params = fbToken ? tokenParam : baseParam

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.FB.api(
      "/" + fbPageID,
      "GET",
      params,
      async function (response: FBAccountPageResult) {
        if (response.instagram_business_account && type === igTab.POSTS) {
          const mediaData = await getCSMediaData(
            response.instagram_business_account.id,
            nextPage
          )

          if (mediaData.success) {
            return resolve({
              success: true,
              igMediaList: mediaData.data,
              nextPage: mediaData.nextPage,
            })
          }
          return resolve({
            success: false,
          })
        } else if (
          response.instagram_business_account &&
          type === igTab.STORIES
        ) {
          const mediaData = await getCSMediaStoryData(
            response.instagram_business_account.id
          )
          if (mediaData.success) {
            return resolve({
              success: true,
              igMediaList: mediaData.data,
            })
          }
          return resolve({
            success: false,
          })
        }
      }
    )
  })
}

const getCSMediaData = (
  id: string,
  afterPage?: string
): Promise<
  | {
      success: true
      data: CSMediaListData[]
      nextPage?: string
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    const fbToken = localStorage.getItem("look4kol-fb-code")
    let baseParam = {
      fields:
        "id,caption,comments_count,like_count,media_type,media_url,thumbnail_url,timestamp,permalink",
      limit: 18,
      after: afterPage ? afterPage : "",
    }

    const tokenParam = {
      ...baseParam,
      access_token: fbToken,
    }

    const params = fbToken ? tokenParam : baseParam

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.FB.api("/" + id + "/media", "GET", params, function (response: any) {
      let afterPage = ""

      if (response.data.length === 18) {
        afterPage = response.paging?.cursors?.after
      }

      if (response.data.length) {
        return resolve({
          success: true,
          data: response.data,
          nextPage: afterPage,
        })
      }
      return resolve({
        success: false,
      })
    })
  })
}

const getCSMediaStoryData = (
  id: string
): Promise<
  | {
      success: true
      data: CSMediaListData[]
    }
  | {
      success: false
    }
> => {
  return new Promise((resolve) => {
    const fbToken = localStorage.getItem("look4kol-fb-code")
    const baseParam = {
      fields:
        "id,caption,comments_count,like_count,media_type,media_url,thumbnail_url,timestamp,permalink",
    }

    const tokenParam = {
      ...baseParam,
      access_token: fbToken,
    }

    const params = fbToken ? tokenParam : baseParam

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.FB.api(
      "/" + id + "/stories",
      "GET",
      params,
      function (response: any) {
        if (response.data.length) {
          return resolve({ success: true, data: response.data })
        }
        return resolve({
          success: false,
        })
      }
    )
  })
}

const getCSMediaInsight = (
  imageID: string,
  mediaType: IgMediaType
): Promise<{ success: true; insight: CSMediaInsight } | { success: false }> => {
  return new Promise((resolve) => {
    const fbToken = localStorage.getItem("look4kol-fb-code")
    let baseParam = {}

    if (mediaType === IgMediaType.IMAGE) {
      baseParam = {
        metric: "total_interactions,impressions,reach,saved",
      }
    } else if (mediaType === IgMediaType.CAROUSEL) {
      baseParam = {
        metric: "total_interactions,impressions,reach,saved",
      }
    } else if (mediaType === IgMediaType.REEL) {
      baseParam = {
        metric: "total_interactions,plays,reach,saved",
      }
    } else if (mediaType === IgMediaType.STORY) {
      baseParam = {
        metric: "total_interactions,impressions,reach,follows",
      }
    }

    const tokenParam = {
      ...baseParam,
      access_token: fbToken,
    }

    const params = fbToken ? tokenParam : baseParam

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.FB.api(
      "/" + imageID + "/insights",
      "GET",
      params,
      function (response: any) {
        if (response.data.length) {
          let InsightObj: CSMediaInsight = {
            engagement: 0,
            impressions: 0,
            reach: 0,
            saved: 0,
          }

          InsightObj.engagement = response.data[0].values[0].value
          InsightObj.impressions = response.data[1].values[0].value
          InsightObj.reach = response.data[2].values[0].value
          InsightObj.saved = response.data[3].values[0].value

          return resolve({
            success: true,
            insight: InsightObj,
          })
        }
        return response({
          success: false,
        })
      }
    )
  })
}

export {
  fbLogout,
  getFBToken,
  loadFbLoginAPI,
  getFBLoginToken,
  returnApprovedPage,
  getCSMediaStoryData,
  returnPageDataAndInsights,
  listCSMedia,
  getCSMediaInsight,
  returnIGUsername,
}
