import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleInfo,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons"
import { Trans } from "react-i18next"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import igIcon from "../../../Assets/instagram-brands.svg"
import {
  loadFbLoginAPI,
  returnApprovedPage,
} from "../../../HelpingFunction/FBLogin/FBLoginHelper"
import CustomAlert from "../../NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../../Enum/ALERT_SYSYEM"

const CaseStudyConnectIG = ({
  setStep,
  setApprovedPages,
}: {
  setStep: any
  setApprovedPages: any
}) => {
  const [isFBLoggedIn, setIsFBLoggedIn] = useState(false)
  const [noIGAccount, setNoIGAccount] = useState<boolean>(false)

  useEffect(() => {
    loadFbLoginAPI()
  }, [])
  const handleFBLogin = (): void => {
    returnApprovedPage()
      .then((result) => {
        if (result.success && Array.isArray(result.approvedPages)) {
          setIsFBLoggedIn(true)
          setApprovedPages(result.approvedPages)
          //default selection
        }
      })
      .catch((err) => console.log(err))
  }
  return (
    <div>
      <section className="mt-12">
        <h1 className="text-2xl font-semibold text-center">
          <Trans>CaseStudyIGModal.title-1</Trans>
        </h1>
      </section>
      <section className="text-center my-8">
        <p className="text-sm text-gray-500 md:mx-40">
          <Trans>CaseStudyIGModal.desc-1</Trans>
        </p>
        <button
          onClick={() => handleFBLogin()}
          className="btn btn-outline  rounded-xl w-56 p-2 normal-case text-sm my-12 hover:bg-gray-200 hover:text-black">
          <span className="mx-2">
            <img className="w-6 h-6" src={igIcon} />{" "}
          </span>
          <Trans>CaseStudyIGModal.button-1</Trans>
        </button>
      </section>
      <section>
        <div className="md:px-14 px-4">
          <CustomAlert
            title={"CaseStudyIGModal.home-alert"}
            alertType={ALERT_TYPE.WARNING}
            buttonType={{ type: BUTTON_TYPE.NONE }}
            icon={faTriangleExclamation}
          />
        </div>
      </section>
    </div>
  )
}

export default CaseStudyConnectIG
