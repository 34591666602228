import { Job } from "../Model/Job"
import { Column, ExpansionType } from "../Model/Table"
import {
  BADGE_TYPE,
  BUTTON_SIZE,
  BUTTON_TYPE,
  HEADER_STYLE,
  ICON_TYPE,
  TABLE_TYPE,
  WIDTH_SIZE,
} from "../Enum/TABLE_SYSTEM"
import {
  faBriefcase,
  faChartPie,
  faCircleCheck,
  faCircleXmark,
  faCommentDots,
  faComments,
  faExternalLinkAlt,
  faFileContract,
  faHeart,
  faUser,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons"
import { BankTransaction } from "../Model/BankTransaction"
import { intToFloat } from "./Converter"
import KOL, { KOLType } from "../Model/KOL"
import { returnJobBadgeSetting } from "../HelpingFunction/JobUtility"
import { KOLCombine, KOLCombineWithAvatar } from "../Model/KOLCombine"
import { returnKOLRecomText } from "./SearchKOLUtils"
import { t } from "i18next"
import {
  returnAudienceAgeData,
  returnAudienceCityData,
  returnAudienceGenderData,
} from "../HelpingFunction/FollowersData/FollowerDBHelper"
import { CaseStudy } from "../Model/CaseStudy"
import {
  returnCategoryName,
  returnSelectedItem,
  tagTypeSelection,
} from "./TypeList"
import { ADMIN_REQUEST_STATUS_TYPE } from "../Enum/APP_TYPE"
import { getUserLang } from "./utiltyHelper"
import { NotiMessage } from "../Model/Message"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"

export enum ORDER_BY_DIRECTION {
  DESC = "desc",
  ASC = "asc",
}

export enum PAGINATION_DIRECTION {
  PREV,
  NEXT,
}

export interface SORT_OPTION {
  isDefault?: boolean
  fieldName: string
  direction: ORDER_BY_DIRECTION | false
}

export const getMySort = (arr: SORT_OPTION[]): SORT_OPTION => {
  if (Array.isArray(arr)) {
    const result = arr.find((e) => e.direction !== false)
    if (result) {
      return result
    }

    const defaultOption = arr.find((e) => e.isDefault === true)
    if (defaultOption) {
      return {
        ...defaultOption,
        direction: ORDER_BY_DIRECTION.DESC,
      }
    }

    return {
      fieldName: "",
      direction: false,
    }
  }
  return {
    fieldName: "",
    direction: false,
  }
}

export const getPaginationData = (arr: SORT_OPTION[], data: any): any => {
  let fieldName = ""
  if (Array.isArray(arr)) {
    const result = arr.find((e) => e.direction !== false)
    if (result) {
      fieldName = result.fieldName
    }

    const defaultOption = arr.find((e) => e.isDefault === true)
    if (defaultOption) {
      fieldName = defaultOption.fieldName
    }
  }
  return data[fieldName] ? data[fieldName] : {}
}

export const getNewSortDirection = (
  arr: SORT_OPTION[],
  fieldName: string
): ORDER_BY_DIRECTION | false => {
  const val = arr.find((e) => e.fieldName === fieldName)
  if (val) {
    return sortDirectionFn(val.direction)
  }
  return false
}

const getSortDirection = (
  arr: SORT_OPTION[],
  fieldName: string
): ORDER_BY_DIRECTION | false => {
  const val = arr.find((e) => e.fieldName === fieldName)
  if (val) {
    return val.direction
  }
  return false
}

export const onSort = (
  sortOption: SORT_OPTION[],
  fieldName: string
): SORT_OPTION[] => {
  const newDir = getNewSortDirection(sortOption, fieldName)

  let newSortOption: SORT_OPTION[] = []
  sortOption.forEach((e) => {
    if (e.fieldName === fieldName) {
      newSortOption.push({
        ...e,
        direction: newDir,
      })
    } else {
      newSortOption.push({
        ...e,
        direction: false,
      })
    }
  })
  return newSortOption
}

//add default sorting direction to the soon be sorted type
// and reverse direction if sort again
export const sortDirectionFn = (
  order: ORDER_BY_DIRECTION | false
): ORDER_BY_DIRECTION => {
  if (order === false) {
    return ORDER_BY_DIRECTION.DESC
  }
  return order === ORDER_BY_DIRECTION.DESC
    ? ORDER_BY_DIRECTION.ASC
    : ORDER_BY_DIRECTION.DESC
}

export const getDirectionSign = (
  o: ORDER_BY_DIRECTION,
  p: PAGINATION_DIRECTION | ""
): "<" | ">" => {
  if (o === ORDER_BY_DIRECTION.DESC && p === PAGINATION_DIRECTION.NEXT) {
    // if order by desc and pagination is next, the pagination data should get the `last` item on the list and `smaller` than
    return "<"
  } else if (o === ORDER_BY_DIRECTION.DESC && p === PAGINATION_DIRECTION.PREV) {
    // if order by desc and pagination is prev, the pagination data should get the `first` item on the list and `larger` than
    return ">"
  } else if (o === ORDER_BY_DIRECTION.ASC && p === PAGINATION_DIRECTION.NEXT) {
    // if order by asc and pagination is next, the pagination data should get the `last` item on the list and `larger` than
    return ">"
  } else if (o === ORDER_BY_DIRECTION.ASC && p === PAGINATION_DIRECTION.PREV) {
    // if order by asc and pagination is prev, the pagination data should get the `first` item on the list and `smaller` than
    return "<"
  }
  return "<"
}

//for getting a sorting direction from the correct sorting type list
export const orderGetter = (orderType: string, orderList: any) => {
  const type = orderList.find((element: any) => {
    return element.type === orderType
  })

  if (type) {
    return type.order
  }
  return false
}

// for displaying empty data results
export const nullResultChecker = (res: string) => {
  if (res && res !== "-") {
    return res
  }
  return getUserLang() === "en" ? "No results" : "沒有結果"
}

/**
 Contract Table
 */

export const defaultContractTableSort: SORT_OPTION[] = [
  {
    isDefault: true,
    direction: false,
    fieldName: "lastUpdate",
  },
]
export const expandWidthStyle = (data: ExpansionType) => {
  switch (data.fixedWidth) {
    case WIDTH_SIZE.SM:
      return "w-auto"
    case WIDTH_SIZE.MD:
      return "w-3/4"
    case WIDTH_SIZE.LG:
      return "w-4/5"
    case WIDTH_SIZE.XL:
      return "w-5/6"
    case WIDTH_SIZE.XXL:
      return "w-11/12"
    case WIDTH_SIZE.XXXL:
      return "w-full"
    default:
      break
  }
}
export const defaultContractTableSchema = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ListContract.job-name",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ListContract.price",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ListContract.job-create-day",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ListContract.paid",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ListContract.status",
    },
  ]
}

export const defaultContractTableWithNameSchema = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ListContract.job-name",
    },

    {
      type: TABLE_TYPE.DEFAULT,
      title: "ListContract.name",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ListContract.price",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ListContract.job-create-day",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ListContract.paid",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ListContract.status",
    },
  ]
}

export const contractTableWithNameSchema = (
  job: Job,
  name: string,
  orderList: any,
  sortFn: (type: string) => void
): Column[] => {
  const badgeElement = returnJobBadgeSetting(job)

  return [
    {
      type: TABLE_TYPE.HEAD,
      title: "ListContract.job-name",
      component: {
        type: TABLE_TYPE.COL,
        title: "",
        fixedWidth: WIDTH_SIZE.LG,
        component: [
          {
            type: TABLE_TYPE.STRING,
            title: "jobName",
            value: job.ServicePlanName,
          },
          {
            type: TABLE_TYPE.BADGE,
            title: "",
            badgeType: badgeElement.type,
            value: badgeElement.label,
          },
        ],
      },
      canSort: "jobName",
    },
    {
      type: TABLE_TYPE.STRING,
      title: "ListContract.name",
      value: name,
    },
    {
      type: TABLE_TYPE.PRICE,
      title: "ListContract.price",
      currency: job.currency,
      value: job.price,
    },
    {
      type: TABLE_TYPE.DATE,
      title: "ListContract.job-create-day",
      value: job.startDate,
      canSort: {
        order: getSortDirection(orderList, "createDate"),
        sortFn: () => sortFn("createDate"),
      },
    },
    {
      type: TABLE_TYPE.ICON,
      iconType: job.paid ? ICON_TYPE.SUCCESS : ICON_TYPE.FAIL,
      title: "ListContract.paid",
      value: job.paid ? faCircleCheck : faCircleXmark,
    },
    {
      type: TABLE_TYPE.ICON,
      iconType: job.isFinish ? ICON_TYPE.SUCCESS : ICON_TYPE.FAIL,
      title: "ListContract.status",
      value: job.isFinish ? faCircleCheck : faCircleXmark,
    },
  ]
}

export const contractTableSchema = (
  job: Job,
  orderList: any,
  sortFn: (type: string) => void
): Column[] => {
  const badgeElement = returnJobBadgeSetting(job)

  return [
    {
      type: TABLE_TYPE.HEAD,
      title: "ListContract.job-name",
      component: {
        type: TABLE_TYPE.COL,
        title: "",
        fixedWidth: WIDTH_SIZE.LG,
        component: [
          {
            type: TABLE_TYPE.STRING,
            title: "jobName",
            value: job.ServicePlanName,
          },
          {
            type: TABLE_TYPE.BADGE,
            title: "",
            badgeType: badgeElement.type,
            value: badgeElement.label,
          },
        ],
      },
      canSort: "jobName",
    },
    {
      type: TABLE_TYPE.PRICE,
      title: "ListContract.price",
      currency: job.currency,
      value: job.price,
    },
    {
      type: TABLE_TYPE.DATE,
      title: "ListContract.job-create-day",
      value: job.createDate,
      canSort: {
        order: getSortDirection(orderList, "createDate"),
        sortFn: () => sortFn("createDate"),
      },
    },
    {
      type: TABLE_TYPE.ICON,
      iconType: job.paid ? ICON_TYPE.SUCCESS : ICON_TYPE.FAIL,
      title: "ListContract.paid",
      value: job.paid ? faCircleCheck : faCircleXmark,
    },
    {
      type: TABLE_TYPE.ICON,
      iconType: job.isFinish ? ICON_TYPE.SUCCESS : ICON_TYPE.FAIL,
      title: "ListContract.status",
      value: job.isFinish ? faCircleCheck : faCircleXmark,
    },
  ]
}

/**
 * KOL Job Table
 */

export const defaultKOLJobTableSort: SORT_OPTION[] = [
  {
    isDefault: true,
    direction: false,
    fieldName: "lastUpdate",
  },
]

export const defaultKOLJobTableSchema = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.job-name",
    },

    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.job-coop-name",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.job-price",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.job-score",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.job-status",
    },
  ]
}

/**
 * KOL Job Table
 */

export const kolJobTableSchema = (
  job: Job,
  name: string,
  orderList: any,
  sortFn: (type: string) => void
): Column[] => {
  const badgeElement = returnJobBadgeSetting(job)

  return [
    {
      type: TABLE_TYPE.STRING,
      title: "MyTransaction.job-name",
      value: job.ServicePlanName,
    },

    {
      type: TABLE_TYPE.STRING,
      title: "MyTransaction.job-coop-name",
      value: name,
    },
    {
      type: TABLE_TYPE.PRICE,
      title: "MyTransaction.job-price",
      currency: job.currency,
      value: job.price,
    },
    {
      type: TABLE_TYPE.SCORE,
      title: "MyTransaction.job-score",
      value: job.score,
    },
    {
      type: TABLE_TYPE.BADGE,
      badgeType: badgeElement.type,
      title: "MyTransaction.job-status",
      value: badgeElement.label,
    },
  ]
}

/**
 KOL Transaction
 */

export const defaultTransactionTableSort: SORT_OPTION[] = [
  {
    direction: false,
    fieldName: "payDate",
  },
  {
    isDefault: true,
    direction: false,
    fieldName: "lastUpdate",
  },
]

export const defaultKOLTranscationTableSchema = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.job-name",
    },

    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.date",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.amount",
    },
  ]
}

export const kolTranscationTableSchema = (
  bankTrans: BankTransaction
): Column[] => {
  return [
    {
      type: TABLE_TYPE.STRING,
      title: "MyTransaction.job-name",
      value: bankTrans.title,
    },
    {
      type: TABLE_TYPE.DATE,
      title: "MyTransaction.date",
      value: bankTrans.date,
    },
    {
      type: TABLE_TYPE.COL,
      title: "MyTransaction.amount",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "",
          value:
            (bankTrans.type === "ADMIN_REQUEST" ? "- " : "+ ") +
            intToFloat((1 * bankTrans.amount) / 100, 2),
        },
        {
          type: TABLE_TYPE.BADGE,
          badgeType: bankTrans.badge.type,
          title: "MyTransaction.job-status",
          value: bankTrans.badge.value,
        },
      ],
    },
  ]
}
export const transactionTableSchemaDefault = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.job-name",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.job-payout-date",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.job-status",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "MyTransaction.functions",
    },
  ]
}

export const transactionTableSchema = (
  job: Job,
  onClickTransaction: (job: Job) => void,
  orderList: SORT_OPTION[],
  sortFn: (type: string) => void
): Column[] => {
  return [
    {
      type: TABLE_TYPE.STRING,
      title: "MyTransaction.job-name",
      value: job.ServicePlanName,
    },
    {
      type: TABLE_TYPE.PRICE,
      title: "MyTransaction.job-price",
      currency: job.currency,
      value: job.price,
    },
    {
      type: TABLE_TYPE.DATE,
      title: "MyTransaction.job-payout-date",
      value: job.payoutDate,
      canSort: {
        order: getSortDirection(orderList, "payoutDate"),
        sortFn: () => sortFn("payoutDate"),
      },
    },
    {
      type: TABLE_TYPE.BADGE,
      title: "MyTransaction.job-status",
      badgeType: job.completePayout ? BADGE_TYPE.JOB_DONE : BADGE_TYPE.WORKING,
      value: job.completePayout
        ? "MyTransaction.fulfilled"
        : "MyTransaction.working",
    },
    {
      type: TABLE_TYPE.BUTTON,
      buttonType: BUTTON_TYPE.INFO,
      title: "MyTransaction.functions",
      icon: faExternalLinkAlt,
      onClick: () => onClickTransaction(job),
      value: "MyTransaction.open",
      fixedWidth: WIDTH_SIZE.MD,
    },
  ]
}

export const defaultProjectKOLTableSchema = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ProjectTable.kol-name",
    },
  ]
}

export interface ProjectKOLType {
  kol: KOL
  handleClickKOL: () => void
  chatRoomPreviewFn: (chatRoomID: string) => void
  userPreviewFn: () => void
  jobsPreviewFn: () => void
  selectedKOLID: string[]
}

export const listProjectKOLTableSchema = (data: ProjectKOLType): Column[] => {
  const kol = data.kol
  const handleClickKOL = data.handleClickKOL
  const chatRoomPreviewFn = data.chatRoomPreviewFn
  const userPreviewFn = data.userPreviewFn
  const jobsPreviewFn = data.jobsPreviewFn
  const selectedKOLID = data.selectedKOLID

  const firstRowPositionToggleType = (): Column => {
    return {
      type: TABLE_TYPE.CHECK_BOX,
      title: "",
      checked: selectedKOLID.includes(kol.id),
      onChange: () => {
        handleClickKOL()
      },
    }
  }
  const firstRowPositionUserType = (): Column => {
    return {
      type: TABLE_TYPE.ROW,
      title: "ProjectTable.kol-name",
      fixedWidth: WIDTH_SIZE.LG,
      component: [
        {
          type: TABLE_TYPE.AVATAR,
          title: "",
          value: kol.avatarUrl,
        },
        {
          type: TABLE_TYPE.COL,
          title: "",
          component: [
            {
              type: TABLE_TYPE.STRING,
              title: "",
              fixedWidth: WIDTH_SIZE.MD,
              value: kol.userName,
            },
            {
              type: TABLE_TYPE.ROW,
              title: "",
              component: [
                {
                  type: TABLE_TYPE.BUTTON,
                  buttonType: BUTTON_TYPE.GHOST,
                  title: "",
                  icon: faComments,
                  onClick: () => {
                    chatRoomPreviewFn("123")
                  },
                  hoverActive: true,
                  value: "",
                },
                {
                  type: TABLE_TYPE.BUTTON,
                  buttonType: BUTTON_TYPE.GHOST,
                  title: "",
                  icon: faUser,
                  onClick: () => {
                    userPreviewFn()
                  },
                  hoverActive: true,
                  value: "",
                },
                {
                  type: TABLE_TYPE.BUTTON,
                  buttonType: BUTTON_TYPE.GHOST,
                  title: "",
                  icon: faBriefcase,
                  onClick: () => {
                    jobsPreviewFn()
                  },
                  hoverActive: true,
                  value: "",
                },
              ],
            },
          ],
        },
      ],
    }
  }

  return [firstRowPositionToggleType(), firstRowPositionUserType()]
}

export const emptyProjectKOLTableSchema = (): Column[] => {
  let newTableRow: Column[] = [
    {
      type: TABLE_TYPE.EMPTY,
      title: "",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.kol-name",
    },
  ]
  return newTableRow
}
export const emptyProjectJOBTableSchema = (): Column[] => {
  let newTableRow: Column[] = [
    {
      type: TABLE_TYPE.EMPTY,
      title: "",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.job-name",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.job-status",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.job-price",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.job-create-date",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.job-paid",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.job-finish",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.case-likes",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.case-shares",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.case-comments",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.case-bookmarks",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.case-engagements",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.case-actions",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.case-reaches",
    },
  ]
  return newTableRow
}

export const defaultProjectJobTableSchema = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ProjectTable.job-name",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ProjectTable.job-status",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ProjectTable.job-price",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ProjectTable.job-create-date",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ProjectTable.job-paid",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ProjectTable.job-finish",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.case-likes",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.case-shares",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.case-comments",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.case-bookmarks",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.case-engagements",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.case-actions",
    },
    {
      type: TABLE_TYPE.EMPTY,
      title: "ProjectTable.case-reaches",
    },
  ]
}

export interface ProjectJobType {
  job: Job
  handleClickJob: (jobID: string) => void
  jobPreviewFn: (chatRoomID: string, jobID: string) => void
  selectedJobID: string[]
  caseData: CaseStudy
}

export const listProjectJobTableSchema = ({
  job,
  handleClickJob,
  jobPreviewFn,
  selectedJobID,
  caseData,
}: ProjectJobType): Column[] => {
  let chatID = ""

  const badgeElement = returnJobBadgeSetting(job)
  return [
    {
      type: TABLE_TYPE.CHECK_BOX,
      title: "",
      checked: selectedJobID.includes(job.id),
      onChange: () => {
        handleClickJob(job.id)
      },
    },
    {
      type: TABLE_TYPE.COL,
      title: "ProjectTable.job-name",
      fixedWidth: WIDTH_SIZE.LG,
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "",
          value: job.ServicePlanName,
        },
        {
          type: TABLE_TYPE.BUTTON,
          buttonType: BUTTON_TYPE.GHOST,
          title: "",
          icon: faBriefcase,
          onClick: () => {
            jobPreviewFn(chatID, job.id)
          },
          hoverActive: true,
          value: "ProjectTable.view-job",
        },
      ],
    },
    {
      type: TABLE_TYPE.BADGE,
      title: "ProjectTable.job-status",
      badgeType: badgeElement.type,
      value: badgeElement.label,
    },
    {
      type: TABLE_TYPE.PRICE,
      title: "ProjectTable.job-price",
      currency: job.currency,
      value: job.price,
    },
    {
      type: TABLE_TYPE.DATE,
      title: "ProjectTable.job-create-date",
      value: job.createDate,
    },
    {
      type: TABLE_TYPE.ICON,
      iconType: job.paid ? ICON_TYPE.SUCCESS : ICON_TYPE.FAIL,
      title: "ProjectTable.job-paid",
      value: job.paid ? faCircleCheck : faCircleXmark,
    },
    {
      type: TABLE_TYPE.ICON,
      iconType: job.isFinish ? ICON_TYPE.SUCCESS : ICON_TYPE.FAIL,
      title: "ProjectTable.job-finish",
      value: job.isFinish ? faCircleCheck : faCircleXmark,
    },

    {
      type: TABLE_TYPE.STRING,
      title: "ProjectTable.case-likes",
      value: caseData.ig_like >= 0 ? caseData.ig_like.toString() : "/",
    },
    {
      type: TABLE_TYPE.STRING,
      title: "ProjectTable.case-shares",
      value: caseData.ig_share >= 0 ? caseData.ig_share.toString() : "/",
    },
    {
      type: TABLE_TYPE.STRING,
      title: "ProjectTable.case-comments",
      value: caseData.ig_comment >= 0 ? caseData.ig_comment.toString() : "/",
    },
    {
      type: TABLE_TYPE.STRING,
      title: "ProjectTable.case-bookmarks",
      value: caseData.ig_save >= 0 ? caseData.ig_save.toString() : "/",
    },
    {
      type: TABLE_TYPE.STRING,
      title: "ProjectTable.case-engagements",
      value:
        caseData.ig_total_engagement >= 0
          ? caseData.ig_total_engagement.toString()
          : "/",
    },
    {
      type: TABLE_TYPE.STRING,
      title: "ProjectTable.case-actions",
      value:
        caseData.ig_total_action >= 0
          ? caseData.ig_total_action.toString()
          : "/",
    },
    {
      type: TABLE_TYPE.STRING,
      title: "ProjectTable.case-reaches",
      value: caseData.ig_reach >= 0 ? caseData.ig_reach.toString() : "/",
    },
  ]
}

export const defaultProjectJobItemTableSchema = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ProjectTable.job-name",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "ProjectTable.job-create-date",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "",
    },
  ]
}

export interface ProjectJobItemsType {
  job: Job
  handleClickJob: (jobID: string) => void
  selectedJobID: string[]
}

export const listProjectJobItemTableSchema = ({
  job,
  handleClickJob,
  selectedJobID,
}: ProjectJobItemsType): Column[] => {
  const badgeElement = returnJobBadgeSetting(job)

  return [
    {
      type: TABLE_TYPE.COL,
      title: "ProjectTable.job-name",
      fixedWidth: WIDTH_SIZE.MD,
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "",
          value: job.ServicePlanName,
        },
        {
          type: TABLE_TYPE.BADGE,
          title: "ProjectTable.job-status",
          badgeType: badgeElement.type,
          value: badgeElement.label,
        },
      ],
    },
    {
      type: TABLE_TYPE.DATE,
      title: "ProjectTable.job-create-date",
      value: job.createDate,
    },
    {
      type: TABLE_TYPE.CHECK_BOX,
      title: "",
      checked: selectedJobID.includes(job.id),
      onChange: () => {
        handleClickJob(job.id)
      },
    },
  ]
}

export const recommendationTableSchemaDefault = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "RecommendationTable.username",
      fixedWidth: WIDTH_SIZE.LG,
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "RecommendationTable.followers",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "RecommendationTable.genre",
    },
    // {
    //   type: TABLE_TYPE.DEFAULT,
    //   title: "RecommendationTable.location",
    // },
    // {
    //   type: TABLE_TYPE.DEFAULT,
    //   title: "RecommendationTable.score",
    // },
  ]
}

export const recommendationTableSchema = (
  selectedKOLs: KOLCombineWithAvatar[],
  kol: KOLCombineWithAvatar,
  avatarUrl: string,
  handleClickSelectedKOL: (kol: KOLCombineWithAvatar) => void,
  onClickRow: (kolID: string) => void
): Column[] => {
  const largeAgeItem = returnAudienceAgeData(
    kol.audience_gender_age,
    false
  ).sort((a, b) => b[1] - a[1])

  const largeGenderItem = returnAudienceGenderData(
    kol.audience_gender_age,
    false
  ).sort((a, b) => b[1] - a[1])

  let largestItemName = ""

  const gender = largeGenderItem[0][1] > 0 ? largeGenderItem[0][0] : ""
  const age = largeAgeItem[0][1] > 0 ? largeAgeItem[0][0] : ""

  if (kol.percentages.length > 0) {
    largestItemName = kol.percentages.reduce((a, b) =>
      a.percentages > b.percentages ? a : b
    ).item
  }

  return [
    {
      type: TABLE_TYPE.CHECK_BOX,
      title: "",
      checked: selectedKOLs.some((e) => e.id === kol.id),
      onChange: () => {
        handleClickSelectedKOL(kol)
      },
    },
    {
      type: TABLE_TYPE.AVATAR,
      title: "",
      value: avatarUrl,
    },
    {
      type: TABLE_TYPE.COL,
      title: "",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "RecommendationTable.username",
          value: kol.userName,
          fixedWidth: WIDTH_SIZE.LG,
        },
        {
          type: TABLE_TYPE.ROW,
          title: "",
          component: [
            {
              type: TABLE_TYPE.BUTTON,
              buttonType: BUTTON_TYPE.GHOST,
              title: "",
              icon: faUserCircle,
              onClick: () => {
                onClickRow(kol.id)
              },
              hoverActive: false,
              value: t("RecommendationTable.view-profile"),
            },
          ],
        },
      ],
    },

    {
      type: TABLE_TYPE.STRING,
      title: "RecommendationTable.followers",
      value: kol.noOfFollowers.toString(),
    },
    // {
    //   type: TABLE_TYPE.STRING,
    //   title: "RecommendationTable.genre",
    //   value: largestItemName,
    // },
    {
      type: TABLE_TYPE.STRING,
      title: "RecommendationTable.age",
      value: age,
    },
    {
      type: TABLE_TYPE.STRING,
      title: "RecommendationTable.gender",
      value: gender,
    },
    // {
    //   type: TABLE_TYPE.STRING,
    //   title: "RecommendationTable.score",
    //   value: kol.kolScore ? kol.kolScore.toString() : "0",
    // },
  ]
}

export const searchKOLTableSchemaDefault = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.userID",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.followers",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.top-cate",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.top-genre",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.followers-age-group",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.followers-gender",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.followers-country",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "",
    },
  ]
}

export const searchKOLTableSchema = (
  kol: KOLCombine,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  setViewKOLPercentageModal: (metaData: { metaData: KOLCombine }) => void,
  // whether user toggle to show recommendation
  showHelpText: boolean,
  setAddToCompareGroup: (metaData: { metaData: string }) => void
): Column[] => {
  const topCateStat = kol.topCatePercentage
    ? (kol.topCatePercentage * 100).toFixed(2)
    : "-"
  const topGenreStat = kol.topGenrePercentage
    ? (kol.topGenrePercentage * 100).toFixed(2)
    : "-"
  const largeAgeItem = returnAudienceAgeData(
    kol.audience_gender_age,
    true
  ).sort((a, b) => b[1] - a[1])

  const largeGenderItem = returnAudienceGenderData(
    kol.audience_gender_age,
    true
  ).sort((a, b) => b[1] - a[1])
  const largeCountryItem = returnAudienceCityData(
    kol.audience_country,
    20
  ).sort((a, b) => b[1] - a[1])

  const gender = largeGenderItem[0][1] > 0 ? largeGenderItem[0] : ""
  const age = largeAgeItem[0][1] > 0 ? largeAgeItem[0] : ""

  const totalCountryCount = largeCountryItem.length
    ? largeCountryItem.map((e) => e[1]).reduce((a, b) => a + b)
    : 0
  const country: { name: string; percentage: number } = {
    name: largeCountryItem[0] ? largeCountryItem[0][0] : "",
    percentage: largeCountryItem[0]
      ? (largeCountryItem[0][1] / totalCountryCount) * 100
      : 0,
  }
  return [
    {
      type: TABLE_TYPE.COL,
      title: "",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "SearchKOL.userID",
          value: kol.userName,
        },
        {
          type: TABLE_TYPE.BUTTON,
          buttonType: BUTTON_TYPE.GHOST,
          title: "",
          icon: faChartPie,
          onClick: () => {
            setViewKOLPercentageModal({
              metaData: kol,
            })
          },
          hoverActive: false,
          value: t("SearchKOL.data-analysis"),
          hidden: kol.percentages.length === 0,
        },
        {
          type: TABLE_TYPE.BADGE,
          title: "",
          badgeType: BADGE_TYPE.SUCCESS,
          value: returnKOLRecomText(kol.percentages),
          hidden:
            !showHelpText ||
            kol.percentages.length === 0 ||
            returnKOLRecomText(kol.percentages) === "",
        },
      ],
    },
    {
      type: TABLE_TYPE.STRING,
      title: "SearchKOL.followers",
      value: kol.noOfFollowers.toString(),
    },
    {
      type: TABLE_TYPE.COL,
      title: "SearchKOL.top-cate",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "",
          value: topCateStat + "%",
        },
        {
          type: TABLE_TYPE.BADGE,
          title: "",
          value: nullResultChecker(returnCategoryName(kol.topCateID)),
          badgeType:
            returnCategoryName(kol.topCateID) !== "-"
              ? BADGE_TYPE.TAG
              : BADGE_TYPE.FAIL,
        },
      ],
    },
    {
      type: TABLE_TYPE.COL,
      title: "SearchKOL.top-genre",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "",
          value: topGenreStat + "%",
        },
        {
          type: TABLE_TYPE.BADGE,
          title: "",
          value: nullResultChecker(returnSelectedItem(kol.topGenreID)!.label),
          badgeType:
            returnSelectedItem(kol.topGenreID)!.label !== "-"
              ? BADGE_TYPE.TAG
              : BADGE_TYPE.FAIL,
        },
      ],
    },
    {
      type: TABLE_TYPE.COL,
      title: "SearchKOL.followers-age-group",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "",
          value: age ? age[1].toFixed(2) + "%" : "-%",
        },
        {
          type: TABLE_TYPE.BADGE,
          title: "",
          value: nullResultChecker(age[0]),
          badgeType: age ? BADGE_TYPE.TAG : BADGE_TYPE.FAIL,
        },
      ],
    },
    {
      type: TABLE_TYPE.COL,
      title: "SearchKOL.followers-gender",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "",
          value: gender ? gender[1].toFixed(2) + "%" : "-%",
        },
        {
          type: TABLE_TYPE.BADGE,
          title: "",
          value: nullResultChecker(gender[0]),
          badgeType: gender ? BADGE_TYPE.TAG : BADGE_TYPE.FAIL,
        },
      ],
    },
    {
      type: TABLE_TYPE.COL,
      title: "SearchKOL.followers-country",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "",
          value: country.percentage
            ? country.percentage.toFixed(2) + "%"
            : "-%",
        },
        {
          type: TABLE_TYPE.BADGE,
          title: "",
          value: nullResultChecker(country.name),
          badgeType: country.name ? BADGE_TYPE.TAG : BADGE_TYPE.FAIL,
        },
      ],
    },
    {
      type: TABLE_TYPE.BUTTON,
      buttonType: BUTTON_TYPE.INFO,
      title: "MyTransaction.functions",
      icon: faHeart,
      onClick: () =>
        setAddToCompareGroup({
          metaData: kol.userName,
        }),
      value: "SearchKOL.save",
      fixedWidth: WIDTH_SIZE.MD,
    },
  ]
}

export const compareKOLTableSchemaDefault = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "CompareKOL.stats-name",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "CompareKOL.stats-followers",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "CompareKOL.stats-top-cate",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "CompareKOL.stats-top-genre",
    },
  ]
}

export const compareKOLTableSchema = (
  kol: KOLCombine,
  filterData: tagTypeSelection[],
  orderList: SORT_OPTION[],
  sortFn: (type: string) => void
): Column[] => {
  const topCateStat = kol.topCatePercentage
    ? (kol.topCatePercentage * 100).toFixed(2)
    : "-"
  const topGenreStat = kol.topGenrePercentage
    ? (kol.topGenrePercentage * 100).toFixed(2)
    : "-"
  let groupColumns: Column[] = []
  filterData.forEach((data) => {
    const itemCount = kol.percentages.find((k) => {
      return k.item === data.value
    })
    groupColumns.push({
      type: TABLE_TYPE.STRING,
      title: data.label + "(%)",
      value: itemCount ? (itemCount.percentages * 100).toFixed(2) + "%" : "-%",
      canSort: {
        order: getSortDirection(orderList, data.value),
        sortFn: () => sortFn(data.value),
      },
    })
  })
  return [
    {
      type: TABLE_TYPE.STRING,
      title: "CompareKOL.stats-name",
      value: kol.userName,
      canSort: {
        order: getSortDirection(orderList, "userName"),
        sortFn: () => sortFn("userName"),
      },
    },
    {
      type: TABLE_TYPE.STRING,
      title: "CompareKOL.stats-followers",
      value: kol.noOfFollowers.toString(),
      canSort: {
        order: getSortDirection(orderList, "noOfFollowers"),
        sortFn: () => sortFn("noOfFollowers"),
      },
    },
    {
      type: TABLE_TYPE.HEAD,
      title: "SearchKOL.top-cate",
      component: {
        type: TABLE_TYPE.COL,
        title: "",
        component: [
          {
            type: TABLE_TYPE.STRING,
            title: "topCatePercentage",
            value: topCateStat + "%",
            canSort: {
              order: getSortDirection(orderList, "topCatePercentage"),
              sortFn: () => sortFn("topCatePercentage"),
            },
          },
          {
            type: TABLE_TYPE.BADGE,
            title: "",
            value: nullResultChecker(returnCategoryName(kol.topCateID)),
            badgeType:
              returnCategoryName(kol.topCateID) !== "-"
                ? BADGE_TYPE.TAG
                : BADGE_TYPE.FAIL,
          },
        ],
      },
      canSort: "topCatePercentage",
    },
    {
      type: TABLE_TYPE.HEAD,
      title: "SearchKOL.top-genre",
      component: {
        type: TABLE_TYPE.COL,
        title: "",
        component: [
          {
            type: TABLE_TYPE.STRING,
            title: "topGenrePercentage",
            value: topGenreStat + "%",
            canSort: {
              order: getSortDirection(orderList, "topGenrePercentage"),
              sortFn: () => sortFn("topGenrePercentage"),
            },
          },
          {
            type: TABLE_TYPE.BADGE,
            title: "",
            value: nullResultChecker(returnSelectedItem(kol.topGenreID)!.label),
            badgeType:
              returnSelectedItem(kol.topGenreID)!.label !== "-"
                ? BADGE_TYPE.TAG
                : BADGE_TYPE.FAIL,
          },
        ],
      },
      canSort: "topGenrePercentage",
    },

    ...groupColumns,
  ]
}
export const dashboardJobTableSchemaDefault = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.userID",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.followers",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.location",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.genre-category",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "",
    },
  ]
}

export const dashboardJobTableSchema = (job: Job): Column[] => {
  const badgeElement = returnJobBadgeSetting(job)

  return [
    {
      type: TABLE_TYPE.STRING,
      title: "ProjectTable.job-name",
      fixedWidth: WIDTH_SIZE.LG,
      value: job.ServicePlanName,
    },
    {
      type: TABLE_TYPE.BADGE,
      title: "ProjectTable.job-status",
      badgeType: badgeElement.type,
      value: badgeElement.label,
    },
    {
      type: TABLE_TYPE.DATE,
      title: "ProjectTable.job-create-date",
      value: job.createDate,
    },
    // {
    //   type: TABLE_TYPE.BUTTON,
    //   buttonType: BUTTON_TYPE.INFO,
    //   title: "MyTransaction.functions",
    //   icon: faExternalLinkAlt,
    //   onClick: () => handleClickJob(job),
    //   value: "MyTransaction.open",
    //   fixedWidth: WIDTH_SIZE.MD,
    // },
  ]
}

export const managementTableSchemaDefault = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "KOLManagement.username",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "KOLManagement.latest-chat",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "KOLManagement.latest-contract",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "",
    },
  ]
}

const localeSelector = (noti: NotiMessage) => {
  return getUserLang() === "en" ? noti.msg : noti.msgCHI
}

const unreadMsgColumns = (
  noti: NotiMessage[],
  icon: IconDefinition,
  title: string
): Column => {
  if (noti.length) {
    const col: Column[] = [
      {
        type: TABLE_TYPE.STRING,
        title: "",
        value: localeSelector(noti[0]),
        fixedWidth: WIDTH_SIZE.XL,
      },
      {
        type: TABLE_TYPE.DATE,
        title: "",
        value: noti[0].issueDate,
      },
    ]
    if (noti.length > 1) {
      col.push({
        type: TABLE_TYPE.BADGE,
        title: "",
        badgeType: BADGE_TYPE.CONTRACT,
        value:
          t("KOLManagement.more-messages-1") +
          (noti.length - 1) +
          t("KOLManagement.more-messages-2"),
      })
    }
    return {
      type: TABLE_TYPE.ROW,
      title: title,
      component: [
        {
          type: TABLE_TYPE.ICON,
          title: "",
          iconType: ICON_TYPE.GHOST,
          value: icon,
        },
        {
          type: TABLE_TYPE.COL,
          title: "",
          component: [...col],
        },
      ],
    }
  }
  return {
    type: TABLE_TYPE.BADGE,
    title: title,
    badgeType: BADGE_TYPE.FAIL,
    value: "KOLManagement.no-message",
  }
}

export const managementTableSchema = (
  kol: KOLType,
  unreadChat: NotiMessage[],
  unreadContract: NotiMessage[],
  ownUID: string,
  handleClickKOL: (kolID: string) => void
): Column[] => {
  //filter out msg which are from own chat (customer who owned the KOL)
  const latestChat = unreadChat.length
    ? unreadChat.filter((c) => !c.url.includes(ownUID))
    : []
  const latestContract = unreadContract.length
    ? unreadContract.filter((c) => !c.url.includes(ownUID))
    : []

  let chatColumn: Column = unreadMsgColumns(
    latestChat,
    faCommentDots,
    "KOLManagement.latest-chat"
  )
  let contractColumn: Column = unreadMsgColumns(
    latestContract,
    faFileContract,
    "KOLManagement.latest-contract"
  )

  return [
    {
      type: TABLE_TYPE.ROW,
      title: "KOLManagement.username",
      component: [
        {
          type: TABLE_TYPE.AVATAR,
          title: "",
          value: kol.avatarUrl,
        },
        {
          type: TABLE_TYPE.COL,
          title: "",
          component: [
            {
              type: TABLE_TYPE.STRING,
              title: "",
              fixedWidth: WIDTH_SIZE.LG,
              value: kol.userName,
            },
            {
              type: TABLE_TYPE.STRING,
              title: "",
              fixedWidth: WIDTH_SIZE.LG,
              headingStyle: HEADER_STYLE.H2,
              value: kol.email,
            },
          ],
        },
      ],
    },
    { ...chatColumn },
    { ...contractColumn },
    {
      type: TABLE_TYPE.BUTTON,
      buttonType: BUTTON_TYPE.INFO,
      title: "",
      icon: faExternalLinkAlt,
      onClick: () => handleClickKOL(kol.id),
      value: "KOLManagement.chat-open",
      fixedWidth: WIDTH_SIZE.XL,
    },
  ]
}

export const defaultIGPercentageTableSchema = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      fixedWidth: WIDTH_SIZE.LG,
      title: "AdminViewKOLPercentageModal.item",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      fixedWidth: WIDTH_SIZE.MD,
      title: "AdminViewKOLPercentageModal.percentage",
    },
  ]
}

export const getIGPercentageTableSchema = (
  total: number,
  data: {
    count: number
    item: string
    percentages: number
  }
): Column[] => {
  return [
    {
      type: TABLE_TYPE.STRING,
      title: "AdminViewKOLPercentageModal.item",
      fixedWidth: WIDTH_SIZE.XL,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value: returnSelectedItem(data.item).label as string,
    },
    {
      type: TABLE_TYPE.STRING,
      fixedWidth: WIDTH_SIZE.MD,
      title: "AdminViewKOLPercentageModal.percentage",
      value: ((data.count / total) * 100).toFixed(2).toString() + "%",
    },
  ]
}

export const getIGFollowerPercentageTableSchema = (data: {
  item: string
  percentage: number
}): Column[] => {
  return [
    {
      type: TABLE_TYPE.STRING,
      title: "AdminViewKOLPercentageModal.item",
      fixedWidth: WIDTH_SIZE.XL,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value: returnSelectedItem(data.item).label as string,
    },
    {
      type: TABLE_TYPE.STRING,
      fixedWidth: WIDTH_SIZE.MD,
      title: "AdminViewKOLPercentageModal.percentage",
      value: (data.percentage * 100).toFixed(2).toString() + "%",
    },
  ]
}
export const chatGPTRecommendTableSchemaDefault = (): Column[] => {
  return [
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.userID",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.followers",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "AssistantRecom.top-cate",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "AssistantRecom.top-genre",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.followers-age-group",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.followers-gender",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "SearchKOL.followers-country",
    },
    {
      type: TABLE_TYPE.DEFAULT,
      title: "",
    },
  ]
}

export const chatGPTRecommendTableSchema = (
  selectedKOLs: KOLCombineWithAvatar[],
  kol: { kol: KOLCombineWithAvatar; reason: string; score: string },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  setViewKOLPercentageModal: (metaData: { metaData: KOLCombine }) => void,
  // whether user toggle to show recommendation
  showHelpText: boolean,
  handleClickSelectedKOL: (kol: KOLCombineWithAvatar) => void,
  expandedComponent: (reason: string, score: string) => JSX.Element,
  expandedID: string,
  onClickExpand: (kolID: string) => void
  // eslint-disable-next-line max-params
): Column[] => {
  const topCateStat = kol.kol.topCatePercentage
    ? (kol.kol.topCatePercentage * 100).toFixed(2)
    : "-"
  const topGenreStat = kol.kol.topGenrePercentage
    ? (kol.kol.topGenrePercentage * 100).toFixed(2)
    : "-"
  const largeAgeItem = returnAudienceAgeData(
    kol.kol.audience_gender_age,
    true
  ).sort((a, b) => b[1] - a[1])

  const largeGenderItem = returnAudienceGenderData(
    kol.kol.audience_gender_age,
    true
  ).sort((a, b) => b[1] - a[1])
  const largeCountryItem = returnAudienceCityData(
    kol.kol.audience_country,
    20
  ).sort((a, b) => b[1] - a[1])

  const gender = largeGenderItem[0][1] > 0 ? largeGenderItem[0] : ""
  const age = largeAgeItem[0][1] > 0 ? largeAgeItem[0] : ""

  let nameColumnSetUp: Column[] = [
    {
      type: TABLE_TYPE.STRING,
      title: "SearchKOL.userID",
      value: kol.kol.userName,
    },
    {
      type: TABLE_TYPE.BUTTON,
      buttonType: BUTTON_TYPE.INFO,
      title: "",
      icon: faChartPie,
      onClick: () => {
        setViewKOLPercentageModal({
          metaData: kol.kol,
        })
      },
      buttonSize: BUTTON_SIZE.XS,
      fixedWidth: WIDTH_SIZE.SM,
      hoverActive: false,
      value: "",
      hidden: kol.kol.percentages.length === 0,
    },
  ]
  if (kol.reason !== "" && kol.score !== "") {
    nameColumnSetUp.unshift({
      type: TABLE_TYPE.BADGE,
      title: "",
      value: "AssistantRecom.recm-badge-title",
      badgeType: BADGE_TYPE.SUCCESS,
    })
  }

  const expandComponent = (): Column => {
    return {
      type: TABLE_TYPE.EXPANDED,
      title: "",
      expanded: expandedID === kol.kol.id,
      onChange: () => {
        onClickExpand(kol.kol.id)
      },
      component: expandedComponent(kol.reason, kol.score),
      fixedWidth: WIDTH_SIZE.LG,
    }
  }

  const totalCountryCount = largeCountryItem.length
    ? largeCountryItem.map((e) => e[1]).reduce((a, b) => a + b)
    : 0
  const country: { name: string; percentage: number } = {
    name: largeCountryItem[0] ? largeCountryItem[0][0] : "",
    percentage: largeCountryItem[0]
      ? (largeCountryItem[0][1] / totalCountryCount) * 100
      : 0,
  }
  return [
    {
      type: TABLE_TYPE.ROW,
      title: "",
      component: [
        {
          type: TABLE_TYPE.CHECK_BOX,
          title: "",
          checked: selectedKOLs.some((e: { id: string }) => {
            return e.id === kol.kol.id
          }),
          onChange: () => {
            handleClickSelectedKOL(kol.kol)
          },
        },
        {
          type: TABLE_TYPE.COL,
          title: "",
          component: nameColumnSetUp,
          fixedWidth: WIDTH_SIZE.MD,
        },
      ],
    },
    {
      type: TABLE_TYPE.STRING,
      title: "SearchKOL.followers",
      value: kol.kol.noOfFollowers.toString(),
    },
    {
      type: TABLE_TYPE.COL,
      title: "AssistantRecom.top-cate",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "",
          value: nullResultChecker(returnCategoryName(kol.kol.topCateID)),
          showExtraTip: true,
          fixedWidth: WIDTH_SIZE.MD,
        },
        {
          type: TABLE_TYPE.BADGE,
          title: "",
          value: topCateStat + "%",
          badgeType:
            returnCategoryName(kol.kol.topCateID) !== "-"
              ? BADGE_TYPE.TAG
              : BADGE_TYPE.FAIL,
        },
      ],
    },
    {
      type: TABLE_TYPE.COL,
      title: "AssistantRecom.top-genre",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "",
          value: nullResultChecker(
            returnSelectedItem(kol.kol.topGenreID)!.label
          ),
          showExtraTip: true,
          fixedWidth: WIDTH_SIZE.MD,
        },
        {
          type: TABLE_TYPE.BADGE,
          title: "",
          value: topGenreStat + "%",
          badgeType:
            returnSelectedItem(kol.kol.topGenreID)!.label !== "-"
              ? BADGE_TYPE.TAG
              : BADGE_TYPE.FAIL,
        },
      ],
    },
    {
      type: TABLE_TYPE.COL,
      title: "SearchKOL.followers-age-group",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "",
          value: age ? age[1].toFixed(2) + "%" : "-%",
        },
        {
          type: TABLE_TYPE.BADGE,
          title: "",
          value: nullResultChecker(age[0]),
          badgeType: age ? BADGE_TYPE.TAG : BADGE_TYPE.FAIL,
        },
      ],
    },
    {
      type: TABLE_TYPE.COL,
      title: "SearchKOL.followers-gender",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "",
          value: gender ? gender[1].toFixed(2) + "%" : "-%",
        },
        {
          type: TABLE_TYPE.BADGE,
          title: "",
          value: nullResultChecker(gender[0]),
          badgeType: gender ? BADGE_TYPE.TAG : BADGE_TYPE.FAIL,
        },
      ],
    },
    {
      type: TABLE_TYPE.COL,
      title: "SearchKOL.followers-country",
      component: [
        {
          type: TABLE_TYPE.STRING,
          title: "",
          value: country.percentage
            ? country.percentage.toFixed(2) + "%"
            : "-%",
        },
        {
          type: TABLE_TYPE.BADGE,
          title: "",
          value: nullResultChecker(country.name),
          badgeType: country.name ? BADGE_TYPE.TAG : BADGE_TYPE.FAIL,
        },
      ],
    },
    expandComponent(),
  ]
}
