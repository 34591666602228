import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faSearch,
  faExchangeAlt,
  faBrain,
} from "@fortawesome/free-solid-svg-icons"
import { faFolder, faHeart } from "@fortawesome/free-regular-svg-icons"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { useHistory } from "react-router-dom"

import { BadgeLinkButton } from "../BadgeLinkButton"
import { Trans, useTranslation } from "react-i18next"
import { getUserLang } from "../../Utils/utiltyHelper"
import { TOOLS_HEADER } from "../../Utils/HelpButtonHelperFunction"
import { HelpDocHeader } from "./HelpDocHeader/HelpDocHeader"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from "../../Assets/icons-72.png"

export const ToolsHeader: React.FC = () => {
  const { t } = useTranslation()

  let history = useHistory()

  const ToolsSetting: {
    icon: IconDefinition
    iconAfter: IconDefinition
    i18Text: string
    path: string
  }[] = [
    {
      icon: faSearch,
      iconAfter: faSearch,
      i18Text: t("CustomerTools.search-kol"),
      path: "tools?type=search",
    },
    {
      icon: faExchangeAlt,
      iconAfter: faExchangeAlt,
      i18Text: t("CustomerTools.compare-kol"),
      path: "tools?type=compare",
    },
    {
      icon: faFolder,
      iconAfter: faFolder,
      i18Text: t("CustomerTools.project"),
      path: "project",
    },
    {
      icon: faBrain,
      iconAfter: faBrain,
      i18Text: t("BottomAppBar.recommendation"),
      path: "recommendation",
    },
  ]

  return (
    <div className="shadow-md rounded-b-md">
      {/* KOL Header*/}
      <div
        className="h-18 my-auto w-full py-2 px-4 grid gap-4"
        style={{
          height: "60px",
          gridTemplateColumns: "40px  auto auto",
        }}>
        <div>
          <button
            className="my-auto h-full text-left my-2 mx-2 text-gray-600 font-medium text-xl"
            onClick={() => {
              history.push("/" + getUserLang())
            }}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        </div>
        <div className="text-lg my-auto font-bold ">
          <Trans>CustomerTools.title</Trans>
        </div>
        <div className="flex justify-end">
          <HelpDocHeader helpDocs={TOOLS_HEADER} />
        </div>
      </div>

      {/* KOL Function*/}
      <div className="pt-1 pb-2 px-2 overflow-x-scroll whitespace-nowrap">
        {Array.isArray(ToolsSetting) &&
          ToolsSetting.map((item, key) => {
            return (
              <BadgeLinkButton
                key={"item" + key}
                path={item.path}
                i18Text={item.i18Text}
                icon={item.icon}
              />
            )
          })}
      </div>
    </div>
  )
}
