import { faArrowLeft, faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useTranslation, Trans } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
  CHATGPT_SETTING_TYPE,
  COMMEND_TYPE,
  FORMAL_LEVEL,
  formalLevel,
  FRIENDLY_LEVEL,
  friendlyLevel,
  writingStyle,
} from "../../../Model/ChatMessage"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"

const ChatGPTTextSetting = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const chatgptSetting: CHATGPT_SETTING_TYPE = useSelector((state: any) => {
    return state.ChatGPTManager.setting
  })
  const formalLevelData: FORMAL_LEVEL = useSelector((state: any) => {
    return state.ChatGPTManager.formalLevel
  })

  const friendlyLevelData: FRIENDLY_LEVEL = useSelector((state: any) => {
    return state.ChatGPTManager.friendlyLevel
  })

  const updateChatGPTSetting = (data: CHATGPT_SETTING_TYPE) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.UPDATE_CHATGPT_SETTING,
      data: data,
    })
  }

  const updateSettingType = (key: string, value: any) => {
    let newSetting = chatgptSetting
    newSetting = {
      ...newSetting,
      text: {
        ...newSetting.text,
        [key]: value,
      },
    }

    updateChatGPTSetting(newSetting)
  }

  const updateGPTCommend = (data: COMMEND_TYPE | "") => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.UPDATE_COMMEND_TYPE,
      data: data,
    })
  }

  return (
    <div>
      {/* <p className="mt-2 mx-2">
        <FontAwesomeIcon
          className="cursor-pointer"
          onClick={() => updateGPTCommend("")}
          icon={faArrowLeft}
        />
      </p> */}
      <div className="alert shadow-lg my-4 mx-4">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="stroke-info flex-shrink-0 w-6 h-6">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
          <span>
            <Trans>ChatGPTSetting.fill-in</Trans>
          </span>
        </div>
      </div>
      <h2 className=" text-gray-500 font-medium  px-4 py-2">
        <Trans>ChatGPTToolsBox.writing-style</Trans>
      </h2>

      <section className="my-4">
        <select
          className="select select-bordered w-full max-w-xs"
          onChange={(e) => {
            updateSettingType(
              "tone",
              writingStyle[e.target.selectedIndex].value
            )
          }}>
          {writingStyle.map((e, index) => {
            return <option key={index}>{t(e.label)}</option>
          })}
        </select>
      </section>

      <h2 className=" text-gray-500 font-medium  px-4 py-2">
        <Trans>ChatGPTToolsBox.writing-tones</Trans>
      </h2>

      <section className="my-4">
        <input
          type="range"
          min="1"
          max="3"
          value={formalLevelData}
          className="range"
          step="1"
          onChange={(e) => {
            updateSettingType(
              "formalLevel",
              formalLevel[parseInt(e.target.value) - 1].value
            )
          }}
        />
        <div className="w-full flex justify-between text-xs px-2">
          <div>
            <Trans>ChatGPTToolsBox.informal</Trans>
          </div>
          <div>
            <Trans>ChatGPTToolsBox.neutral</Trans>
          </div>
          <div>
            <Trans>ChatGPTToolsBox.formal</Trans>
          </div>
        </div>
      </section>

      <section className="my-4">
        <input
          type="range"
          min="1"
          max="3"
          value={friendlyLevelData}
          className="range"
          step="1"
          onChange={(e) => {
            updateSettingType(
              "friendlyLevel",
              friendlyLevel[parseInt(e.target.value) - 1].value
            )
          }}
        />
        <div className="w-full flex justify-between text-xs px-2">
          <div>
            <Trans>ChatGPTToolsBox.friendly</Trans>
          </div>
          <div>
            <Trans>ChatGPTToolsBox.neutral</Trans>
          </div>
          <div>
            <Trans>ChatGPTToolsBox.assertive</Trans>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ChatGPTTextSetting
