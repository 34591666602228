import { KOLCombine } from "../Model/KOLCombine"
import { SORT_OPTION } from "./TableHelperFunction"

export const sortInfo = (
  a: KOLCombine,
  b: KOLCombine,
  sortEnabled: SORT_OPTION
): { sortDataA: any; sortDataB: any } => {
  let dataA
  let dataB
  switch (sortEnabled.fieldName) {
    case "userName":
      dataA = a.userName
      dataB = b.userName
      break
    case "noOfFollowers":
      dataA = a.noOfFollowers
      dataB = b.noOfFollowers
      break
    case "topGenrePercentage":
      dataA = a.topGenrePercentage ? a.topGenrePercentage : 0
      dataB = b.topGenrePercentage ? b.topGenrePercentage : 0
      break
    case "topCatePercentage":
      dataA = a.topCatePercentage ? a.topCatePercentage : 0
      dataB = b.topCatePercentage ? b.topCatePercentage : 0
      break
    default:
      //genre percentage
      const percentA = a.percentages.find(
        (e) => e.item === sortEnabled.fieldName
      )
      const percentB = b.percentages.find(
        (e) => e.item === sortEnabled.fieldName
      )
      dataA = percentA ? percentA.percentages : 0
      dataB = percentB ? percentB.percentages : 0
      break
  }
  return { sortDataA: dataA, sortDataB: dataB }
}
