import React from "react"
import "./App.css"
import { Provider } from "react-redux"
import { ReactReduxFirebaseProvider } from "react-redux-firebase"
import { getReduxStore, getRrfProp } from "./Config/firebase-redux"
import { BrowserRouter, Switch } from "react-router-dom"
import RouterManager from "./Layout/RouterManager/_app"
import { isWeb } from "./Utils/HelpingFunction"
import ScrollToTop from "./Utils/ScrollToTop"

function App() {
  return (
    <div className={["App", !isWeb() ? "select-auto" : ""].join(" ")}>
      <Provider store={getReduxStore()}>
        <ReactReduxFirebaseProvider {...getRrfProp()}>
          <BrowserRouter>
            <Switch>
              <ScrollToTop>
                <RouterManager />
              </ScrollToTop>
            </Switch>
          </BrowserRouter>
        </ReactReduxFirebaseProvider>
      </Provider>
    </div>
  )
}

export default App
