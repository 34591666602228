import React, { useEffect, useState } from "react"
import { CONTRACT_STEP } from "../../../Enum/CONTRACT"
import ContractToolBar from "./ContractToolBar"
import { Trans } from "react-i18next"
import {
  QuotationObj,
  createDraftContract,
} from "../../../HelpingFunction/Job/JobDBHelper"
import { Plan } from "../../../Model/Plan"
import { useDispatch, useSelector } from "react-redux"
import { ROLE } from "../../../Enum/APP_TYPE"
import {
  getKOL,
  setKOL,
  getKOLPlans,
} from "../../../HelpingFunction/KOL/kolDBHelper"
import { KOLType, KOLDefault } from "../../../Model/KOL"
import { t } from "i18next"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReactQuillEditorCss from "../../../Utils/ReactQuillEditor.module.css"

import ReactQuill from "react-quill"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import CustomAlert from "../../../Component/NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../../Enum/ALERT_SYSYEM"
import { useLocation } from "react-router-dom"
import classNames from "classnames"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../../Utils/ModalType"
import { getAuthUID } from "../../../Utils/QueryHelper"
import { DRAFT_CONTRACT_TYPE } from "../../../Component/ModalComponents/CopyDraftContractModalComponent"

const ContractDraftKOL = (props: any) => {
  const dispatch = useDispatch()

  const paramList = useSelector((state: any) => {
    return state.QueryManager.params
  })

  function useQuery() {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let queryParam = useQuery()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const chatroom = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomInfo
  })

  const openCopyDraftContractModal = (id: string) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.COPY_DRAFT_CONTRACT_MODAL,
      metaData: {
        id: id,
        type: DRAFT_CONTRACT_TYPE.CONTRACT,
      },
    })
  }

  const [quotationObj, setQuotationObj] = useState<QuotationObj>({
    ServicePlanID: "",
    //will be the job name
    ServicePlanName: "",
    quotationDetail: "",
    price: 0,
  })
  const [kol, setKOL] = useState<KOLType>(KOLDefault)
  const [isLoadedKOL, setIsLoadedKOL] = useState<boolean>(false)
  const [alert, setAlert] = useState<string>("")
  const [KOLPlan, setKOLPlan] = useState<Plan[]>([])
  const [isLoadedKOLPlan, setIsLoadedKOLPlan] = useState<boolean>(false)
  const [isCreatingContract, setIsCreatingContract] = useState<boolean>(false)

  const [role, setRole] = useState<ROLE>(ROLE.DEFAULT)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const modules = React.useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        //'image'
        ["link"],
        ["clean"],
        ["code-block"],
      ],
      // handlers: {
      //     'image': () => quillImageCallBack()
      // },
      clipboard: {
        matchVisual: false,
      },
    },
  }))

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    // 'image'
  ]

  const createContract = async () => {
    setIsCreatingContract(true)
    await createDraftContract(
      auth.uid,
      getAuthUID(paramList),
      quotationObj
    ).then((res) => {
      if (res.success) {
        setIsCreatingContract(false)
        props.closeModal()
        openCopyDraftContractModal(res.jobID)
      }
    })
  }

  useEffect(() => {
    if (chatroom && chatroom.id !== "") {
      let KOLID = ""

      // create contract person could be KOL itself or customer
      if (role === ROLE.KOL) {
        KOLID = auth.uid
      } else {
        KOLID = chatroom.participateUID.find((e: string) => e !== auth.uid)
      }

      if (KOLID) {
        getKOL(KOLID).then((result) => {
          if (result.success) {
            setKOL(result.data)
            setIsLoadedKOL(true)
          }
        })

        getKOLPlans(KOLID, false).then((result) => {
          if (result.success) {
            setKOLPlan(result.data)
            setIsLoadedKOLPlan(true)
          } else {
            setIsLoadedKOLPlan(true)
          }
        })
      }
    }
  }, [chatroom])

  return (
    <div className="bg-white overflow-y-scroll px-2 pt-2 pb-20">
      <div className="block my-4">
        <label className="text-gray-700 mb-2 block">
          <Trans>Contract.job-title</Trans>
        </label>
        <input
          id={"QuotaionPage_JobTitle"}
          type="text"
          className="px-4 py-3 bg-white border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
          value={quotationObj.ServicePlanName}
          onChange={(e) => {
            setQuotationObj({
              ...quotationObj,
              ServicePlanName: e.target.value,
            })
          }}
        />
      </div>
      <div className="block my-4">
        <label className="text-gray-700 mb-2 block">
          <Trans>Contract.plan-select</Trans>
        </label>
        {isLoadedKOLPlan && (
          <select
            id={"QuotaionPage_PlanSelect"}
            name="plan"
            className="w-full select select-bordered mt-2"
            onChange={(event) => {
              const ets: number = event.target.selectedIndex
              // check whether is custom plan or not
              setQuotationObj({
                ...quotationObj,
                // ServicePlanName: KOLPlan[ets - 1].title,
                ServicePlanID: KOLPlan[ets - 1]
                  ? KOLPlan[ets - 1].id
                  : "Custom",
              })
            }}>
            <option disabled selected>
              Choose Type
            </option>
            {KOLPlan.map((o) => {
              return (
                <option
                  key={o.id}
                  selected={quotationObj.ServicePlanID === o.id}>
                  {o.title + " ~HKD " + o.price}
                </option>
              )
            })}
            <option>Custom 自定</option>
          </select>
        )}
      </div>
      <div className="block mt-4 mb-6">
        <label className="text-gray-700 mb-2">
          <Trans>Contract.quotation-detail</Trans>
        </label>
        <div
          className={["my-4", ReactQuillEditorCss.ReactQuillEditor].join(" ")}
          style={{
            minHeight: "200px",
            maxHeight: "400px",
            overflow: "hidden",
          }}>
          <ReactQuill
            placeholder={t("Contract.kol-requirement-draft-message") as string}
            value={quotationObj.quotationDetail}
            onChange={(e) => {
              if (e === "<p><br></p>") {
                e = ""
              }
              setQuotationObj({
                ...quotationObj,
                quotationDetail: e,
              })
            }}
            theme="snow"
            modules={modules}
            formats={formats}
          />
        </div>

        <div
          className={[
            "block float-right text-sm",
            quotationObj.quotationDetail.length >= 3000
              ? "text-red-500"
              : "text-gray-500",
          ].join(" ")}>
          {quotationObj.quotationDetail.length + " / 3000"}
        </div>
      </div>
      <div className="block my-4">
        <label className="text-gray-700 mb-2 block">
          <Trans>Contract.price</Trans>
        </label>
        <input
          type="text"
          id={"Contract_QuotationPriceKOL"}
          className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
          placeholder="price"
          value={quotationObj.price}
          min={0}
          max={999999}
          onChange={(e) => {
            if (
              parseInt(e.target.value) < 0 ||
              parseInt(e.target.value) > 999999
            ) {
            } else {
              if (
                parseInt(e.target.value) >= 1 &&
                parseInt(e.target.value) < 10
              )
                setAlert(t("Contract.price-warning") as string)
              else setAlert("")
              if (e.target.value === "") e.target.value = "0"
              setQuotationObj({
                ...quotationObj,
                price: parseInt(e.target.value),
              })
            }
          }}
        />
        <button
          onClick={() => createContract()}
          id={"Contract_SendQuotationKOL"}
          className={classNames("btn btn-outline btn-info mt-8", {
            loading: isCreatingContract,
          })}>
          <Trans>DraftContractModal.create-contract</Trans>
        </button>
        {alert !== "" && (
          <div className="py-4">
            <CustomAlert
              title={alert}
              alertType={ALERT_TYPE.ERROR}
              buttonType={{
                type: BUTTON_TYPE.NONE,
              }}
              icon={faExclamationCircle}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ContractDraftKOL
