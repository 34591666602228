import React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons"

import { getUserLang } from "../../Utils/utiltyHelper"
import { Trans } from "react-i18next"
import classNames from "classnames"
import { isAuth, logout } from "../../Utils/FirebaseUtils"

export const VerifyPhoneHeader: React.FC = ({}) => {
  let history = useHistory()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  return (
    <div
      className="h-18 my-auto w-full py-2 px-4 gap-4"
      style={{
        height: "60px",
      }}>
      <div className="float-right">
        {isAuth(auth) && (
          <button
            className={classNames("btn btn-circle btn-ghost", {})}
            onClick={() => {
              logout()
              history.push("/" + getUserLang() + "/login")
            }}>
            <FontAwesomeIcon icon={faRightFromBracket} />
          </button>
        )}
      </div>
    </div>
  )
}
