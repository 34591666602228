export enum CONTRACT_STEP {
  QUOTATION,
  CONTRACT,
  PAYMENT,
}

export enum CHECKOUT_PAGE_TYPE {
  ERROR,
  STRIPE,
  BANK,
  PAY_LATER,
  SKIP,
}

export enum CONTRACT_ROLE {
  KOL,
  CUSTOMER,
}
