import { ChatroomJob } from "./Chatroom"
import { CHATROOM_TYPE } from "../Enum/APP_TYPE"
import {
  CHATGPT_SETTING_TYPE_IG,
  COMMEND_TYPE,
  IG_POST_LENGTH,
} from "./ChatMessage"

export const ChatGPTChatItem: ChatroomJob = {
  //do not add id filed in here
  adminUID: "",
  createDate: new Date(),
  customTitle: [],
  lastUpdate: new Date(),
  participateUID: [],
  title: "ChatGPT",
  avatarUrl: "",
  type: CHATROOM_TYPE.CHATGPT,
}
export const AssistantChatItem: ChatroomJob = {
  //do not add id filed in here
  adminUID: "",
  createDate: new Date(),
  customTitle: [],
  lastUpdate: new Date(),
  participateUID: [],
  title: "Assistant",
  avatarUrl: "",
  type: CHATROOM_TYPE.ASSISTANT,
}

export const gptPrompts: {
  title: string
  desc: string
  type: COMMEND_TYPE
  setting?: CHATGPT_SETTING_TYPE_IG
}[] = [
  {
    title: "AddChatgptChatModal.prompt-t-6",
    desc: "AddChatgptChatModal.prompt-d-6",
    type: COMMEND_TYPE.INSTAGRAM,
    setting: {
      ig: {
        language: "English",
        location: "Hong Kong",
        product_name: "Air Jordan",
        product_desc:
          "This is a vibrant and colourful high-top sneakers that is trending in young adults.",
        product_brand: "Nike",
        product_url: "www.nike.com",
        keywords: ["Nike", "Fashion", "Sneakers"],
        desc_length: IG_POST_LENGTH.MEDIUM,
        image_prompt: "Create an image with Hong Kong busy traffic",
      },
    },
  },
  {
    title: "AddChatgptChatModal.prompt-t-7",
    desc: "AddChatgptChatModal.prompt-d-7",
    type: COMMEND_TYPE.IMAGE,
  },
  {
    title: "AddChatgptChatModal.prompt-t-8",
    desc: "AddChatgptChatModal.prompt-d-8",
    type: COMMEND_TYPE.IMAGE,
  },
  {
    title: "AddChatgptChatModal.prompt-t-1",
    desc: "AddChatgptChatModal.prompt-d-1",
    type: COMMEND_TYPE.TEXT,
  },
  {
    title: "AddChatgptChatModal.prompt-t-2",
    desc: "AddChatgptChatModal.prompt-d-2",
    type: COMMEND_TYPE.TEXT,
  },
  {
    title: "AddChatgptChatModal.prompt-t-3",
    desc: "AddChatgptChatModal.prompt-d-3",
    type: COMMEND_TYPE.TEXT,
  },
  {
    title: "AddChatgptChatModal.prompt-t-4",
    desc: "AddChatgptChatModal.prompt-d-4",
    type: COMMEND_TYPE.TEXT,
  },
  {
    title: "AddChatgptChatModal.prompt-t-5",
    desc: "AddChatgptChatModal.prompt-d-5",
    type: COMMEND_TYPE.TEXT,
  },
]
