import React, { useState } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle, faWarning } from "@fortawesome/free-solid-svg-icons"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import chatgptIcon from "../../../Assets/openai.svg"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import l4kIcon from "../../../Assets/icons-72.png"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "./ChatText.module.css"
import { Trans } from "react-i18next"
import { useSelector } from "react-redux"

const LoadingChatBubble: React.FC = () => {
  const [timeOut, setTimeOut] = useState<boolean>(false)
  setTimeout(() => {
    setTimeOut(true)
  }, 18000)
  return (
    <React.Fragment>
      <div
        className={classNames("my-1 rounded-lg", {
          "col-start-1 col-end-10 lg:col-end-15": true,
        })}>
        <div className={classNames("flex items-center ")}>
          <div className="text-center hidden md:inline-block flex items-center justify-center h-10 w-10 rounded-full flex-shrink-0 uppercase">
            <div>
              <img src={chatgptIcon} alt={"chatgpt"} />
            </div>
          </div>
          <div
            className={classNames(
              "overflow-hidden relative text-sm p-2 shadow rounded-xl cursor-pointer",
              {
                "md:ml-3": true,
              }
            )}>
            <div className={classes.messageText}>
              <FontAwesomeIcon
                className="mx-0.5 text-gray-400  animate-bounce text-xs "
                icon={faCircle}
              />

              <FontAwesomeIcon
                className="mx-0.5 text-gray-400  animate-bounce text-xs "
                icon={faCircle}
              />

              <FontAwesomeIcon
                className="mx-0.5 text-gray-400  animate-bounce text-xs di"
                icon={faCircle}
              />
            </div>
          </div>
        </div>
        {timeOut && (
          <div className="mx-4 p-2 shadow rounded-xl text-xs text-red-400 my-2 flex items-center gap-2 w-auto">
            <FontAwesomeIcon className="mx-1" icon={faWarning} />
            <Trans>ChatText.chatGPT-timeout</Trans>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
export default LoadingChatBubble
