import React, { useEffect, useState } from "react"
import { isAuth, returnIdTokenResult } from "../../../Utils/FirebaseUtils"
import { getAPIPath } from "../../../Utils/HelpingFunction"
import { Trans, useTranslation } from "react-i18next"
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { returnErrorMessageFromCode } from "../../../Utils/StripeErrorCodes"
import { purchasePlan } from "../../../HelpingFunction/Chatroom/Contract/Checkout"
import { useDispatch, useSelector } from "react-redux"
import { logEvent } from "firebase/analytics"
import { analytics } from "../../../Config/firebase"
import { Job, JOB_TYPE } from "../../../Model/Job"
import classNames from "classnames"
import CustomAlert from "../../../Component/NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../../Enum/ALERT_SYSYEM"
import toast from "react-hot-toast"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { JOB_STATUS_TYPE } from "../../../Enum/APP_TYPE"
import { addJobCustomerInfo } from "../../../HelpingFunction/Job/JobDBHelper"

interface Props {
  customerDetails?: {
    name: string
    email: string
  }
}

const StripeCheckout = (props: Props) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const stripe = useStripe()
  const elements = useElements()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const job: Job = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomJob
  })

  const setJob = (j: Job) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_JOB,
      data: j,
    })
  }

  const [custID, setCustID] = useState<string>()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState<any>("")
  const [hasError, setHasError] = useState<boolean>(false)

  useEffect(() => {
    if (isAuth(auth)) {
      //check whether the customer is registered stripe account
      returnIdTokenResult().then(async (idTokenResult) => {
        if (idTokenResult.custID) {
          setCustID(idTokenResult.custID)
        }
      })
    }
  }, [auth])

  const handleChange = ({ error }: any) => {
    if (error) {
      setErrorMessage(
        returnErrorMessageFromCode(
          localStorage.getItem("i18n-lang") === "en" ? "en" : "hk",
          error.code
        )
      )
      setHasError(true)
    } else {
      setErrorMessage("")
      setHasError(false)
    }
  }

  const createStripeCustomer = (): Promise<
    { success: true; cID: string } | { success: false }
  > => {
    return new Promise(async (resolve) => {
      await fetch(getAPIPath("/api/stripe/customer"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: props.customerDetails?.name,
          email: props.customerDetails?.email,
        }),
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          return resolve({
            success: true,
            cID: finalResponse.customerID,
          })
        })
        .catch((err) => {
          console.log(err)
          return resolve({
            success: false,
          })
        })
    })
  }

  const submit = async (ev: any) => {
    ev.preventDefault()
    if (!isLoading) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const cardElement = elements.getElement(CardNumberElement)
      const uid = auth.uid

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      stripe.createToken(cardElement).then(async ({ token, error }) => {
        if (error) {
          console.log(error)
          setIsLoading(false)
          setHasError(true)
          setErrorMessage(error)
        } else {
          console.log(token, job)
          const createPurchase = await purchasePlan(
            token,
            custID,
            job.id,
            job.ServicePlanID,
            job.priceID,
            uid,
            auth.email
          )
          if (createPurchase.success) {
            if (job.type && job.type === JOB_TYPE.INVOICE) {
              setJob({
                ...job,
                status: JOB_STATUS_TYPE.STATUS_2,
              })
            }
            console.log("everything done!", createPurchase)
            logEvent(analytics, "contract_conversion", {
              value: job.price,
            })
          } else {
            setIsLoading(false)
            setHasError(true)
            setErrorMessage(t("CheckoutForm.error-1"))
          }
        }
      })
    }
  }

  //Function For Invoice and to submit payment without login
  const submitWithoutLogin = async (ev: any) => {
    ev.preventDefault()
    if (!isLoading && props.customerDetails) {
      if (
        props.customerDetails.name !== "" &&
        props.customerDetails.email !== ""
      ) {
        //Create Stripe customer and also add customer details in Job DB
        const customer = await createStripeCustomer()
        const jobUpdateState = await addJobCustomerInfo(job.id, {
          name: props.customerDetails.name,
          email: props.customerDetails.email,
        })
        if (customer.success && jobUpdateState.success) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const cardElement = elements.getElement(CardNumberElement)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          stripe.createToken(cardElement).then(async ({ token, error }) => {
            if (error) {
              console.log(error)
              setIsLoading(false)
              setHasError(true)
              setErrorMessage(error)
            } else {
              console.log(token, job)
              const createPurchase = await purchasePlan(
                token,
                customer.cID,
                job.id,
                job.ServicePlanID,
                job.priceID,
                "",
                props.customerDetails?.email
              )
              if (createPurchase.success) {
                setJob({
                  ...job,
                  status: JOB_STATUS_TYPE.STATUS_2,
                })
                console.log("everything done!", createPurchase)
                logEvent(analytics, "contract_conversion", {
                  value: job.price,
                })
              } else {
                setIsLoading(false)
                setHasError(true)
                setErrorMessage(t("CheckoutForm.error-1"))
              }
            }
          })
        } else {
          setIsLoading(false)
          toast.error("Something went wrong, try again")
        }
      } else {
        setIsLoading(false)
        toast.error(t("InvoicePage.fill-customer-details"))
      }
    }
  }

  return (
    <>
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-12">
          <form id="payment-form">
            <div className="split-form">
              <div className="block">
                <div className="block">
                  <label className="block bg-white text-sm">
                    <p className="text-sm font-medium  text-gray-500 mb-2">
                      <Trans>CheckoutForm.card-number</Trans>
                    </p>

                    <div className="rounded-lg border">
                      <div className="p-3 border-b">
                        <CardNumberElement
                          options={{
                            showIcon: true,
                            iconStyle: "default",
                            style: {
                              base: {
                                fontSize: "16px",
                                color: "#424770",
                                letterSpacing: "0.025em",
                                "::placeholder": {
                                  color: "#aab7c4",
                                },
                              },
                              invalid: {
                                color: "#c23d4b",
                              },
                            },
                          }}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="grid grid-cols-2">
                        <div className="border-r border-gray-200 rounded p-3">
                          <CardExpiryElement
                            options={{
                              style: {
                                base: {
                                  fontSize: "16px",
                                  color: "#424770",
                                  letterSpacing: "0.025em",
                                  "::placeholder": {
                                    color: "#aab7c4",
                                  },
                                },
                                invalid: {
                                  color: "#c23d4b",
                                },
                              },
                            }}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="rounded p-3">
                          <CardCvcElement
                            options={{
                              style: {
                                base: {
                                  fontSize: "16px",
                                  color: "#424770",
                                  letterSpacing: "0.025em",
                                  "::placeholder": {
                                    color: "#aab7c4",
                                  },
                                },
                                invalid: {
                                  color: "#c23d4b",
                                },
                              },
                            }}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </form>

          {hasError && (
            <div className="mt-4">
              <CustomAlert
                title={
                  typeof errorMessage === "object"
                    ? errorMessage.message
                    : errorMessage
                }
                buttonType={{
                  type: BUTTON_TYPE.NONE,
                }}
                alertType={ALERT_TYPE.ERROR}
                icon={faExclamationCircle}
              />
            </div>
          )}

          <div className="float-right">
            {/*<img src={pbs} alt="powered by stripe"/>*/}
          </div>
        </div>
      </div>
      <div className="error mb-12" role="alert">
        <button
          disabled={isLoading}
          className={classNames(
            "btn bg-emerald-500 hover:bg-emerald-700 border-0 w-full mt-4 ",
            {
              loading: isLoading,
            }
          )}
          onClick={(e) => {
            setIsLoading(true)
            isAuth(auth) ? submit(e) : submitWithoutLogin(e)
          }}>
          <p className="normal-case font-light text-base">
            <Trans>CheckoutForm.confirm-payment</Trans> $
            {job.price.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
        </button>
        <div className="text-red-400 text-sm mt-4 ">
          <Trans>PayoutRegister.we-use-stripe</Trans>
        </div>
      </div>
    </>
  )
}
export default StripeCheckout
