import React, { useState, useImperativeHandle } from "react"
import { Trans } from "react-i18next"
import { returnBankOptions } from "../../Utils/HKBankList"
import classes from "../../Utils/ToogleSwitch.module.css"
import { t } from "i18next"

const NewBankAccount = (props, ref) => {
  const [newBankObj, setNewBankObj] = useState({
    cardHolder: "",
    bankID: "",
    accNum: "",
    bankLocation: "hk",
    currency: "hkd",
  })

  const [isSave, setIsSave] = useState(false)

  useImperativeHandle(ref, () => ({
    returnData() {
      return {
        bankDetail: newBankObj,
        isSave: isSave,
      }
    },
  }))

  return (
    <div className="my-4 border border-gray-100 bg-gray-50 rounded-md p-4">
      <div>
        <Trans>BankWithdrawModal.new-account</Trans>
      </div>

      {/* Receiving bank*/}
      <div className="my-8">
        <div className="text-md text-gray-700 mb-1">
          <Trans>NewBankAccount.receiving-bank</Trans>
        </div>
        <div className="w-full">
          <select
            name="plan"
            className="select select-bordered w-full max-w-xs mt-6"
            onChange={(event) => {
              const ets = event.target.selectedIndex
              setNewBankObj({
                ...newBankObj,
                bankID: returnBankOptions()[ets - 1].value,
              })
            }}>
            <option disabled selected>
              {t("NewBankAccount.please-select-bank")}
            </option>
            {returnBankOptions().map((o) => {
              return (
                <option selected={newBankObj.bankID === o.value}>
                  {o.label + " - " + o.value}
                </option>
              )
            })}
          </select>
        </div>
      </div>

      {/* account number*/}
      <div className="my-8">
        <div className="text-md text-gray-700 mb-1">
          <Trans>NewBankAccount.account-number</Trans>
        </div>
        <div>
          <input
            type="number"
            className="w-64 border-gray-300 rounded p-2 border w-full"
            value={newBankObj.accNum}
            onChange={(e) => {
              setNewBankObj({
                ...newBankObj,
                accNum: e.target.value.replace(/[^0-9]+/g, ""),
              })
            }}
          />
          <div className="text-gray-500 text-sm my-2">
            <Trans>NewBankAccount.account-number-des</Trans>
          </div>
        </div>
      </div>

      {/* account number*/}
      <div className="my-8">
        <div className="text-md text-gray-700 mb-1">
          <Trans>NewBankAccount.card-holder</Trans>
        </div>
        <div>
          <input
            type="text"
            className="w-64 border-gray-300 rounded p-2 border w-full"
            value={newBankObj.cardHolder}
            max={60}
            onChange={(e) => {
              if (e.target.value.length <= 60) {
                setNewBankObj({
                  ...newBankObj,
                  cardHolder: e.target.value,
                })
              }
            }}
          />
        </div>
      </div>

      {/*save as account*/}
      <div className="my-8">
        <div className="text-md text-gray-700 mb-1">
          <Trans>NewBankAccount.save-detail</Trans>
        </div>
        <div>
          <label className={classes.switch}>
            <input
              type="checkbox"
              checked={isSave}
              onChange={(e) => {
                setIsSave(e.target.checked)
              }}
            />
            <span className={[classes.slider, classes.round].join(" ")}></span>
          </label>
        </div>
      </div>
    </div>
  )
}
export default React.forwardRef(NewBankAccount)
