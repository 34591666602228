import React, { useEffect, useState } from "react"
import { Trans } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
  faExclamationCircle,
  faWarning,
} from "@fortawesome/free-solid-svg-icons"
import {
  getFileTypeImage,
  SupportingImageType,
} from "../../../Utils/FileTypeImageConverter"
import { timeStampToDisplayTimeString } from "../../../Utils/Converter"
import { rebuildFileArr } from "../../../Utils/FileUploadUtils"
import { v4 as uuidv4 } from "uuid"
import { onUploadFile } from "../../../HelpingFunction/FirebaseStorage"
import {
  ADMIN_REQUEST_STATUS_TYPE,
  ADMIN_REQUEST_TYPE,
} from "../../../Enum/APP_TYPE"
import {
  callBankCheckoutAPI,
  createAdminRequest,
  getBankCheckoutHistory,
} from "../../../HelpingFunction/AdminRequest/AdminRequestDBHelper"
import CustomAlert from "../../../Component/NewAlert"
import Spinner from "../../../Component/Spinner"
import AdminRequest, { ADMIN_REQUEST } from "../../../Model/AdminRequest"
import { ALERT_TYPE, BUTTON_TYPE } from "../../../Enum/ALERT_SYSYEM"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "../../../Component/ModalController/ContactKOLModal/ContactKOLModal.module.css"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { Job, JobCustomerInfo, JOB_TYPE } from "../../../Model/Job"
import toast from "react-hot-toast"
import { isAuth } from "../../../Utils/FirebaseUtils"
import { getAPIPath } from "../../../Utils/HelpingFunction"
import { addJobCustomerInfo } from "../../../HelpingFunction/Job/JobDBHelper"
import { t } from "i18next"

interface Props {
  customerDetails?: {
    name: string
    email: string
  }
}

const BankCheckout = (props: Props) => {
  const dispatch = useDispatch()
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const adminRequest: ADMIN_REQUEST | null = useSelector((state: any) => {
    return state.ContractManager.adminRequest
  })
  const job: Job = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomJob
  })
  const isInvoicePage = window.location.pathname.includes("invoice")

  const setAdminRequest = (request: ADMIN_REQUEST | null) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_ADMIN_REQUEST,
      data: request,
    })
  }

  const [selectedFileList, setSelectedFileList] = useState([])
  const [isUploadingBankReceipt, setIsUploadingBankReceipt] =
    useState<boolean>(false)
  const [isUploadBankReceipt, setIsUploadBankReceipt] = useState<boolean>(false)

  const [isLoadedSubmitHistory, setIsLoadedSubmitHistory] =
    useState<boolean>(false)
  const [noResult, setNoResult] = useState<boolean>(false)
  const [submitHistory, setSubmitHistory] = useState<AdminRequest[]>([])
  const [invoiceRejectAlert, setInvoiceRejectAlert] = useState<boolean>(false)

  useEffect(() => {
    if (adminRequest === null) {
      getSubmitHistory()
    }
  }, [job, adminRequest])

  const getSubmitHistory = async () => {
    await callBankCheckoutAPI(job.id).then((res) => {
      if (res.success) {
        if (
          res.data[0].status === ADMIN_REQUEST_STATUS_TYPE.REJECT &&
          job.type === JOB_TYPE.INVOICE
        ) {
          setSubmitHistory([])
          setInvoiceRejectAlert(true)
        } else {
          setSubmitHistory(res.data)
        }
        setIsLoadedSubmitHistory(true)
      } else {
        setNoResult(true)
        setIsLoadedSubmitHistory(true)
      }
    })
  }

  const selectFiles = async (e: any) => {
    setSelectedFileList([])
    const state = await rebuildFileArr(e)
    if (state.success) {
      setSelectedFileList(state.data)
    }
  }

  const removeFile = (id: string) => {
    let newArr = selectedFileList
    newArr = newArr.filter((e: any) => e.id !== id)
    setSelectedFileList(newArr)
  }

  //Function to add customerInfo to Job in DB
  const addCustomerInfoJob = async () => {
    if (
      job.CustomerID === "" &&
      job.customerInfo?.email === "" &&
      props.customerDetails
    ) {
      const customerDetails: JobCustomerInfo = {
        name: props.customerDetails?.name,
        email: props.customerDetails?.email,
      }
      const state = await addJobCustomerInfo(job.id, customerDetails)

      if (state.success) {
        console.log("Added Customer Info")
      }
    }
  }

  const uploadBankReceipt = async () => {
    setIsUploadingBankReceipt(true)
    const caseID = uuidv4()
    if (
      isInvoicePage &&
      !isAuth(auth) &&
      (props.customerDetails?.name === "" ||
        props.customerDetails?.email === "")
    ) {
      toast.error(t("InvoicePage.fill-customer-details"))
      setIsUploadingBankReceipt(false)
    } else if (Array.isArray(selectedFileList) && selectedFileList.length > 0) {
      addCustomerInfoJob()
      let newSelectedFileList = selectedFileList.map((e: any) => e.file)
      // upload file and send a message in chatroom
      const uploadState = await onUploadFile(
        newSelectedFileList,
        auth ? auth.uid : "",
        "AdminRequest/" + caseID
      )
      if (uploadState.success) {
        const req = {
          type: ADMIN_REQUEST_TYPE.BANK_CHECKOUT,
          createDate: new Date(),
          status: ADMIN_REQUEST_STATUS_TYPE.PENDING,
          jobID: job.id,
          requestUID: auth ? auth.uid : "",
          data: uploadState.data,
          lastUpdate: new Date(),
          remark: props.customerDetails
            ? `Name: ${props.customerDetails.name} , Email: ${props.customerDetails.email}`
            : "",
        } as AdminRequest

        await fetch(getAPIPath("/api/admin/adminRequest"), {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            req: req,
          }),
        })
          .then((res) => res.json())
          .then((finalResponse) => {
            console.log(finalResponse)
            if (finalResponse.success) {
              setSelectedFileList([])
              setIsUploadingBankReceipt(false)
              setIsUploadBankReceipt(true)

              setSubmitHistory(submitHistory.concat(req))
              setNoResult(false)
              setAdminRequest(req)
            } else {
              //TODO: This is just quick fix
              setSelectedFileList([])
              setIsUploadingBankReceipt(false)
              setIsUploadBankReceipt(true)

              setSubmitHistory(submitHistory.concat(req))
              setNoResult(false)
              setAdminRequest(req)
            }
          })
          .catch((err) => {
            console.log(err)
          })

        // await createAdminRequest(req).then((result) => {
        //   if (result.success) {
        //     setSelectedFileList([])
        //     setIsUploadingBankReceipt(false)
        //     setIsUploadBankReceipt(true)

        //     setSubmitHistory(submitHistory.concat(req))
        //     setNoResult(false)
        //     setAdminRequest(req)
        //   } else {
        //     //TODO: This is just quick fix
        //     setSelectedFileList([])
        //     setIsUploadingBankReceipt(false)
        //     setIsUploadBankReceipt(true)

        //     setSubmitHistory(submitHistory.concat(req))
        //     setNoResult(false)
        //     setAdminRequest(req)
        //   }
        // })
      } else {
        setIsUploadingBankReceipt(false)
      }
    } else {
      setIsUploadingBankReceipt(false)
    }
  }

  return (
    <div>
      {invoiceRejectAlert && (
        <div className="px-2">
          <CustomAlert
            title="Contract.bank_checkout_reject"
            alertType={ALERT_TYPE.WARNING}
            buttonType={{
              type: BUTTON_TYPE.NONE,
            }}
            icon={faWarning}
          />
        </div>
      )}
      <div className="grid grid-cols-12 gap-2">
        <section className="col-span-12">
          <h2 className="text-lg font-medium uppercase text-gray-500">
            <Trans>CheckoutForm.pay</Trans> $
            {job.price.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            <Trans>CheckoutForm.with-bank</Trans>
          </h2>
          <p className="text-sm text-gray-500 my-2">
            <Trans>CheckoutForm.send-invoice</Trans>
          </p>
          <div className="rounded-lg bg-gray-100 p-4 border mt-4">
            <div className="grid grid-cols-2 text-sm">
              <p className="text-gray-500">
                <Trans>CheckoutForm.bank-acc-number</Trans>
              </p>
              <p className="text-gray-700 font-medium">038522256838</p>
              <p className="text-gray-500">
                <Trans>CheckoutForm.bank</Trans>
              </p>
              <p className="text-gray-700 font-medium">HSBC {"(匯豐銀行)"}</p>
              <p className="text-gray-500">
                <Trans>CheckoutForm.company-name</Trans>
              </p>
              <p className="text-gray-700 font-medium">Happyjai Limited</p>
            </div>
          </div>

          <div className="my-4">
            <input
              className="block w-full text-sm text-slate-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-sky-50 file:text-sky-700
              hover:file:bg-sky-100 file:cursor-pointer"
              id={"CheckOutForm_ChooseFilesForBankRecipt"}
              type={"file"}
              disabled={isUploadingBankReceipt}
              placeholder={"上載圖片"}
              accept=".png,.jpg,.pdf"
              onChange={async (e) => selectFiles(e)}
              multiple
            />
          </div>
          <div className="text-gray-400 text-sm mt-1 mb-4">
            <Trans>CheckoutForm.accept-file-type</Trans>
          </div>

          {submitHistory.length !== 0 && (
            <CustomAlert
              title={"CheckoutForm.wait-for-admin-approve"}
              alertType={ALERT_TYPE.PAYMENT}
              buttonType={{
                type: BUTTON_TYPE.NONE,
              }}
              icon={faExclamationCircle}
            />
          )}

          <div>
            {selectedFileList.map((file: any, index) => {
              return (
                <div key={file.id} className={classes.ImageContainer}>
                  {!isUploadingBankReceipt && (
                    <button
                      className={classes.DeleteImageBtn}
                      onClick={() => removeFile(file.id)}>
                      X
                    </button>
                  )}

                  {SupportingImageType.includes(file.fileType) ? (
                    <img className={classes.ImageItem} src={file.img} />
                  ) : (
                    <img
                      className={classes.ImageItem}
                      src={getFileTypeImage(file.fileType)}
                    />
                  )}
                </div>
              )
            })}
          </div>

          <div>
            {isLoadedSubmitHistory ? (
              !noResult ? (
                submitHistory.map((his, index) => {
                  return (
                    <div
                      key={"his" + index}
                      className="w-full p-2 rounded-lg my-4 border border-gray-100">
                      <div className="block mb-2 text-gray-400">
                        {timeStampToDisplayTimeString(his.createDate)}
                      </div>
                      <div>
                        {Array.isArray(his.data) &&
                          his.data.map((file) => {
                            return (
                              <div
                                key={file.fileKey}
                                className={classes.ImageContainer}>
                                <img
                                  className={classes.ImageItem}
                                  src={file.thumbnailUrl}
                                />
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className="text-center text-gray-400 py-2 px-4">
                  <Trans>CheckoutForm.no-history-record-was-found</Trans>
                </div>
              )
            ) : (
              ""
            )}
          </div>
          <div className="mt-4">
            <button
              id={"CheckOutForm_UploadBankTransfer"}
              className="w-full btn btn-primary"
              disabled={selectedFileList.length === 0 || isUploadBankReceipt}
              onClick={() => uploadBankReceipt()}>
              {isUploadingBankReceipt ? (
                <Spinner />
              ) : (
                <div>
                  <Trans>CheckoutForm.upload</Trans>
                </div>
              )}
            </button>
          </div>
        </section>
      </div>
    </div>
  )
}
export default BankCheckout
