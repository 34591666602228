import React, { useEffect, useState } from "react"
import { Plan } from "../Model/Plan"
import { getKOLPlans } from "../HelpingFunction/KOL/kolDBHelper"
import KOLPlanItem from "../page-components/home/KOLPlanItem"
import { Trans } from "react-i18next"
import KOLPlanItemPlacehoder from "../page-components/home/KOLPlanItemPlacehoder"
import { useLocation } from "react-router-dom"
import { Carousel } from "react-responsive-carousel"
import { COMPONENT_DISPLAY } from "../Enum/APP_TYPE"

interface PropsType {
  KOLID: string
  isKOL: boolean
  displayMethod?: COMPONENT_DISPLAY
}

const KOLPlanList: React.FC<PropsType> = ({ KOLID, isKOL, displayMethod }) => {
  let location = useLocation()
  const [KOLPlan, setKOLPlan] = useState<Plan[]>([])
  const [isLoadedKOLPlan, setIsLoadedKOLPlan] = useState<boolean>(false)

  const useCarousel =
    (location.pathname.includes("/c") && location.search) ||
    location.pathname.includes("/s")

  useEffect(() => {
    getKOLPlan(KOLID)
  }, [KOLID])

  const getKOLPlan = (kolID: string) => {
    getKOLPlans(kolID, isKOL).then((result) => {
      if (result.success) {
        setKOLPlan(result.data)
        setIsLoadedKOLPlan(true)
      } else {
        setIsLoadedKOLPlan(true)
      }
    })
  }

  if (isLoadedKOLPlan) {
    if (displayMethod === COMPONENT_DISPLAY.LIST) {
      return (
        <div className="w-full my-4 overflow-y-hidden overflow-x-scroll whitespace-nowrap">
          {KOLPlan.map((e, index) => {
            return (
              <div key={e.id}>
                <KOLPlanItem data={e} displayMethod={COMPONENT_DISPLAY.LIST} />
              </div>
            )
          })}
        </div>
      )
    }
    return (
      <div>
        {KOLPlan.length > 0 ? (
          useCarousel ? (
            <Carousel
              swipeable={true}
              showArrows={true}
              emulateTouch={true}
              showStatus={false}>
              {KOLPlan.map((e, index) => {
                return (
                  <div key={e.id}>
                    <KOLPlanItem data={e} />
                  </div>
                )
              })}
            </Carousel>
          ) : (
            <div className="rounded-xl bg-gray-200 p-2 w-72 my-4 overflow-y-hidden overflow-x-scroll whitespace-nowrap">
              {KOLPlan.map((e, index) => {
                return <KOLPlanItem key={e.id} data={e} />
              })}
            </div>
          )
        ) : (
          <div className="my-4 py-4 px-4 bg-blue-200 text-blue-900 rounded-xl text-center">
            <Trans>ContactKOLModal.no-plan-created</Trans>
          </div>
        )}
      </div>
    )
  }
  return (
    <div className="w-64 my-4 overflow-y-hidden overflow-x-scroll whitespace-nowrap">
      <KOLPlanItemPlacehoder />
      <KOLPlanItemPlacehoder />
      <KOLPlanItemPlacehoder />
    </div>
  )
}
export default KOLPlanList
