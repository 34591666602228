import React, { useEffect, useImperativeHandle, useRef, useState } from "react"
import { BankWithdrawModalData } from "../../Model/Modal"
import { Trans, useTranslation } from "react-i18next"
import { intToFloat } from "../../Utils/Converter"
import BankAccountCard from "../../page-components/kol/BankAccountCard"
import NewBankAccount from "../../page-components/kol/NewBankAccount"
import { Balance, balanceDefault, Bank, bankDefault } from "../../Model/Bank"
import { isAuth } from "../../Utils/FirebaseUtils"
import {
  getKOL,
  getKOLWallet,
  getWalletBank,
} from "../../HelpingFunction/KOL/kolDBHelper"
import { useSelector } from "react-redux"
import NewAlert from "../NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../Enum/ALERT_SYSYEM"
import { getAuthUID, getQuery } from "../../Utils/QueryHelper"
import { Bonus, BONUS_TYPE } from "../../Model/Bonus"
import classNames from "classnames"
import { useHistory } from "react-router-dom"
import { getUserLang } from "../../Utils/utiltyHelper"

enum ACCOUNT_TYPE {
  NEW = "1",
  EXISTING = "2",
}

const BankWithdrawModalComponent = (props: BankWithdrawModalData, ref: any) => {
  const { t, i18n } = useTranslation()
  let history = useHistory()
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const params = useSelector((state: any) => {
    return state.QueryManager.params
  })

  let newBankAccountRef = useRef()
  const [isLoadedBankObj, setIsLoadedBankObj] = useState<boolean>(false)
  const [bankObj, setBankObj] = useState([])

  const [balance, setBalance] = useState<Balance>(balanceDefault)

  const [withdrawAmount, setWithdrawAmount] = useState<number>(0)
  const [selectedBankAcc, setSelectedBankAcc] = useState<Bank>(bankDefault)
  const [isGoldenWeek, setIsGoldenWeek] = useState<boolean>(false)
  const [isFive, setIsFive] = useState<boolean>(false)

  const [isCompletedKOLProMission, setIsCompletedKOLProMission] =
    useState<boolean>(false)

  const [type, setType] = useState<ACCOUNT_TYPE>(ACCOUNT_TYPE.NEW)
  const [error, setError] = useState("")

  const ownID = getQuery(params, "viewAs")
  useEffect(() => {
    if (isAuth(auth)) {
      getWalletConnectedBank(getAuthUID(params))

      getKOLWallet(getAuthUID(params), ownID ? ownID : "").then(
        (result: any) => {
          if (result.success) {
            setBalance(result.data)
          } else {
            setBalance(balanceDefault)
          }
        }
      )

      getKOL(getAuthUID(params)).then((result) => {
        if (result.success) {
          const bonus: Bonus[] = Array.isArray(result.data.bonus)
            ? result.data.bonus
            : []

          const basicBonus = bonus.find((e) => e.type === BONUS_TYPE.BASIC)
          setIsCompletedKOLProMission(basicBonus ? true : false)
        }
      })
    }
  }, [auth])

  useEffect(() => {
    const currDate = new Date()
    const dateOfMonth = currDate.getDate()

    if (dateOfMonth >= 1 && dateOfMonth <= 5) {
      setIsGoldenWeek(true)
    } else {
      setIsGoldenWeek(false)
    }
  }, [])

  useEffect(() => {
    if (error) {
      document
        .getElementById("bank-withdraw-modal-error")
        ?.scrollIntoView({ behavior: "smooth" })
    }
  }, [error])

  const unselectBank = (): void => {
    setSelectedBankAcc(bankDefault)
  }

  const getWalletConnectedBank = (uid: string): void => {
    getWalletBank(uid).then((result: any) => {
      if (result.success) {
        setBankObj(result.data)
      } else {
      }
      setIsLoadedBankObj(true)
    })
  }

  useImperativeHandle(ref, () => ({
    returnData() {
      return {
        selectedBankAcc: selectedBankAcc,
        newBankAccountRef: newBankAccountRef.current,
        setError: setError,
        withdrawAmount: withdrawAmount,
        type: type,
        isFive: isFive,
      }
    },
  }))

  return (
    <div className="container mx-auto overflow-y-auto mt-10 mb-20">
      <div
        className="w-full md:grid py-8"
        style={{ gridTemplateColumns: "120px auto" }}>
        <div className=" mt-4 text-gray-800 text-lg font-bold">
          <Trans>BankWithdrawModal.from-bank</Trans>
        </div>
        <div className="rounded-md border border-gray-300 py-2 px-4">
          <div className="my-2">
            <Trans>MyWallet.balance</Trans>
          </div>

          <div className="my-2 text-2xl text-red-default">
            {intToFloat((1 * balance.amount) / 100, 2)}
          </div>

          <div className="my-2 uppercase">{balance.currency}</div>
        </div>
      </div>

      <div
        className="w-full md:grid my-8"
        style={{ gridTemplateColumns: "120px auto" }}>
        <div className=" text-gray-800 text-lg font-bold">
          <Trans>BankWithdrawModal.to-account</Trans>
        </div>
        <div>
          {/*  button group*/}
          <div className="mb-4">
            <button
              id={"BankWithdrawModal_MyAccountButton"}
              className={[
                "inline-block mr-2 text-gray-700 border p-2 rounded-md",
                type === ACCOUNT_TYPE.NEW
                  ? "border-red-400 bg-red-50"
                  : "border-gray-300 bg-white",
              ].join(" ")}
              onClick={() => setType(ACCOUNT_TYPE.NEW)}>
              <Trans>BankWithdrawModal.my-account</Trans>
            </button>

            <button
              id={"BankWithdrawModal_NewAccountButton"}
              className={[
                "inline-block mr-2 text-gray-700 border p-2 rounded-md",
                type === ACCOUNT_TYPE.EXISTING
                  ? "border-red-400 bg-red-50"
                  : "border-gray-300 bg-white",
              ].join(" ")}
              onClick={() => setType(ACCOUNT_TYPE.EXISTING)}>
              <Trans>BankWithdrawModal.new-account</Trans>
            </button>
          </div>
          <div>
            {type === ACCOUNT_TYPE.NEW && isLoadedBankObj && (
              <div>
                {bankObj.length !== 0 &&
                  bankObj.map((b, index) => {
                    return (
                      <BankAccountCard
                        key={"bk" + index}
                        bank={b}
                        setSelectedBankAcc={setSelectedBankAcc}
                        selectedAccNum={selectedBankAcc.accNum}
                        unselectBank={unselectBank}
                      />
                    )
                  })}

                {bankObj.length === 0 && (
                  <div className="text-sm text-gray-500">
                    <Trans>BankWithdrawModal.no-bank-acc</Trans>
                  </div>
                )}
              </div>
            )}

            {type === ACCOUNT_TYPE.EXISTING && isLoadedBankObj && (
              <NewBankAccount ref={newBankAccountRef} />
            )}
          </div>
        </div>
      </div>

      <div
        className="w-full md:grid my-8"
        style={{ gridTemplateColumns: "120px auto" }}>
        <div className=" text-gray-800 text-lg font-bold">
          <Trans>BankWithdrawModal.amount</Trans>
        </div>
        <div>
          <input
            id={"BankWithdrawModal_InputWithdrawAmount"}
            type="number"
            className="w-64 border-gray-300 rounded p-2 border "
            value={withdrawAmount}
            min={0}
            max={balance.currency}
            onChange={(e) => {
              setWithdrawAmount(parseInt(e.target.value))
            }}
            onWheel={(e) => e.currentTarget.blur()}
          />
          <div className="text-gray-500 text-sm">
            <Trans>BankWithdrawModal.available-amount</Trans>{" "}
            {intToFloat((1 * balance.amount) / 100, 2) +
              " / " +
              balance.currency}
          </div>
        </div>
      </div>

      <div
        className="w-full md:grid my-8"
        style={{ gridTemplateColumns: "120px auto" }}>
        <div className="text-lg text-amber-500 font-bold ">
          <Trans>BankWithdrawModal.extra-5%</Trans>
        </div>
        <div className="rounded-md border border-gray-300 p-4 grid gap-2">
          <h2 className="mb-2 text-lg">
            <Trans>BankWithdrawModal.5-condition</Trans>
          </h2>
          {/*item 1*/}
          <div className="flex gap-2">
            <input
              type="checkbox"
              disabled={!isGoldenWeek}
              checked={isGoldenWeek}
              className="checkbox checkbox-primary  border border-amber-400"
            />
            <p className="text-gray-500 text-center text-sm">
              <Trans>BankWithdrawModal.5%-desc</Trans>
            </p>
          </div>

          {/*item 2*/}
          <div className="flex gap-2">
            <input
              type="checkbox"
              disabled={!isCompletedKOLProMission}
              checked={isCompletedKOLProMission}
              className="checkbox checkbox-primary  border border-amber-400"
            />
            <p className="text-gray-500 text-center text-sm">
              <Trans>BankWithdrawModal.complete-mission</Trans>
              {!isCompletedKOLProMission && (
                <button
                  className="ml-2 btn btn-xs btn-primary"
                  onClick={() => {
                    history.push("/" + getUserLang() + "/kol-pro")
                    props.closeModal()
                  }}>
                  <Trans>BankWithdrawModal.go-to-mission</Trans>
                </button>
              )}
            </p>
          </div>

          {/*  Apply button*/}
          <button
            className={classNames("btn btn-sm btn-warning", {
              "btn-outline": !isFive,
            })}
            onClick={() => setIsFive(!isFive)}
            disabled={!(isGoldenWeek && isCompletedKOLProMission)}>
            {isFive ? (
              <Trans>BankWithdrawModal.applied</Trans>
            ) : (
              <Trans>BankWithdrawModal.apply</Trans>
            )}
          </button>
        </div>
      </div>

      <div id="bank-withdraw-modal-error" className="w-full my-8">
        <div className="text-lg text-bold my-4">
          <Trans>BankWithdrawModal.des</Trans>
        </div>
        {error !== "" && (
          <NewAlert
            title={t(error)}
            alertType={ALERT_TYPE.ERROR}
            buttonType={{
              type: BUTTON_TYPE.NONE,
            }}
            visible={true}
          />
        )}
        <NewAlert
          title={t("BankWithdrawModal.des-1")}
          alertType={ALERT_TYPE.INFO}
          buttonType={{
            type: BUTTON_TYPE.NONE,
          }}
          visible={true}
        />
        <NewAlert
          title={t("BankWithdrawModal.des-2")}
          alertType={ALERT_TYPE.INFO}
          buttonType={{
            type: BUTTON_TYPE.NONE,
          }}
          visible={true}
        />
      </div>
    </div>
  )
}

export default React.forwardRef(BankWithdrawModalComponent)
