import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useDispatch, useSelector } from "react-redux"
import { PageType } from "../../../Model/ProjectManagement"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import JobListProject from "./JobListProject"
import KOLListProject from "./KOLListProject"

import ProjectForm from "./ProjectForm"
import SelectedKOLList from "./SelectedKOLList"
import { Trans } from "react-i18next"
import { useEffect } from "react"
import ProjectLinks from "./ProjectLinks"

const AddProject = () => {
  const dispatch = useDispatch()
  const page: PageType = useSelector((state: any) => {
    return state.ProjectManager.page
  })

  const setPageType = (type: PageType): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_PROJECT_PAGE,
      data: type,
    })
  }

  useEffect(() => {
    setPageType(PageType.NewProject)
  }, [])

  if (page === PageType.NewProject) {
    return (
      <div>
        {/*  heading*/}
        <h2 className="text-xl font-semibold mb-8">
          <Trans>ProjectOptionBar.project-tools-title</Trans>
        </h2>

        <button
          onClick={() => {
            setPageType(PageType.Main)
          }}
          className={"btn btn-success rounded-xl w-full font-bold "}>
          <FontAwesomeIcon icon={faPlus} className="mr-2" />{" "}
          <Trans>ProjectOptionBar.add-project</Trans>
        </button>
      </div>
    )
  } else if (page === PageType.Main) {
    return (
      <div>
        <h2 className="text-2xl font-semibold mb-4">
          {" "}
          <FontAwesomeIcon
            className="mr-2 cursor-pointer text-xl"
            onClick={() => setPageType(PageType.NewProject)}
            icon={faChevronLeft}
          />
          <Trans>ProjectOptionBar.create-project</Trans>
        </h2>
        <p className="text-gray-600 text-sm my-2 italic">
          <Trans>ProjectOptionBar.create-project-desc</Trans>
          <span className="not-italic"> 😁</span>
        </p>
        <ProjectForm />
      </div>
    )
  } else if (page === PageType.KOL) {
    return <KOLListProject />
  } else if (page === PageType.Job) {
    return <JobListProject />
  } else if (page === PageType.SelectedKOLList) {
    return <SelectedKOLList />
  }
  return <div></div>
}

export default AddProject
