import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"
import {
  ChatLastMessageWithId,
  ChatroomJob,
  ChatroomWithLastMessage,
} from "../../Model/Chatroom"
import { Reducer } from "react"
import { Job } from "../../Model/Job"
import { CHATROOM_PAGE_TYPE, CHATROOM_TYPE } from "../../Enum/APP_TYPE"
import AdminRequest from "../../Model/AdminRequest"
import { ChatMessage } from "../../Model/ChatMessage"
import KOL, { KOLDefault } from "../../Model/KOL"

interface actionTypes {
  type: string
  //ChatMessage
  selectedMessage: any
  deletedMessageID: string
  chatroomInfo: object
  chatroomInfoLocal: object
  data:
    | Job
    | AdminRequest[]
    | ChatroomWithLastMessage[]
    | ChatMessage[]
    | KOL
    | any
}

export interface ChatRoomInitStateType {
  kol: KOL
  isLoadedJobKOL: boolean
  chatroomInfo: ChatroomJob
  chatroomInfoLocal: ChatroomJob
  allMessage: ChatMessage[]
  isLoadedAllMessage: boolean
  chatroomJob: Job | any
  hasSelectedMessage: boolean
  //ChatMessage
  selectedMessage: any
  deletedMessageID: string
  isLoadedChatrooms: boolean
  chatrooms: ChatroomJob[]
  jobPaymentHistory: AdminRequest[]
  pageType: CHATROOM_PAGE_TYPE
  lastMessages: ChatLastMessageWithId[]
  isLoadedLastMessages: boolean
  isWaitingChatgpt: boolean
  isWaitingAssistant: boolean
  chatGptPrompt: string
  ownedKOLPageID: string
  pastChatGPTMessages: any
  selectedChatroom: {
    id: string
    title: string
    avatar?: string
  }
}

const initialState: ChatRoomInitStateType = {
  kol: KOLDefault,
  isLoadedJobKOL: false,
  chatroomInfo: {
    id: "",
    adminUID: "",
    createDate: new Date(),
    customTitle: [],
    lastUpdate: new Date(),
    participateUID: [],
    title: "",
    type: CHATROOM_TYPE.DEFAULT,
  },
  chatroomInfoLocal: {
    id: "",
    adminUID: "",
    createDate: new Date(),
    customTitle: [],
    lastUpdate: new Date(),
    participateUID: [],
    title: "",
    type: CHATROOM_TYPE.DEFAULT,
  },
  allMessage: [],
  isLoadedAllMessage: false,
  chatroomJob: {},
  hasSelectedMessage: false,
  selectedMessage: {},
  deletedMessageID: "",
  isLoadedChatrooms: false,
  chatrooms: [],
  jobPaymentHistory: [],
  pageType: CHATROOM_PAGE_TYPE.LIST_CHAT,
  lastMessages: [],
  isLoadedLastMessages: false,
  isWaitingChatgpt: false,
  isWaitingAssistant: false,
  chatGptPrompt: "",
  ownedKOLPageID: "",
  pastChatGPTMessages: [],
  selectedChatroom: {
    id: "",
    title: "",
    avatar: "",
  },
}

const lastMessageManager = (
  allLastMessages: ChatLastMessageWithId[],
  lastMessage: ChatLastMessageWithId
): ChatLastMessageWithId[] => {
  if (allLastMessages.length > 0) {
    allLastMessages.forEach((message) => {
      if (message.chatroomID === lastMessage.chatroomID) {
        message.lastMessage = lastMessage.lastMessage
      }
    })
  }

  return allLastMessages
}

const updateAvatarAndTitle = (
  chatrooms: ChatroomJob[],
  state: {
    id: string
    avatarUrl: string
    title: string
  }
): ChatroomJob[] => {
  const index = chatrooms.findIndex((e) => e.id === state.id)
  let newArr = [...chatrooms]
  if (index !== -1) {
    newArr[index] = {
      ...newArr[index],
      avatarUrl: state.avatarUrl,
      title: state.title,
      isLoadedAvatarAndTitle: true,
    }
  }
  return newArr
}

const reorderChatroom = (
  chatrooms: ChatroomJob[],
  lastMessage: ChatLastMessageWithId
) => {
  const index = chatrooms.findIndex((e) => e.id === lastMessage.chatroomID)
  if (index !== -1) {
    let newArr = [...chatrooms]
    newArr.splice(0, 0, newArr.splice(index, 1)[0])

    return newArr
  }
  return chatrooms
}

const setChatRoomReducer: Reducer<ChatRoomInitStateType, actionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_INFO:
      return {
        ...state,
        chatroomInfo: {
          ...state.chatroomInfo,
          ...action.chatroomInfo,
        },
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_INFO_LOCAL:
      return {
        ...state,
        chatroomInfoLocal: {
          ...action.chatroomInfoLocal,
        },
      }
    case SUPPORTED_REDUX_FUNCTIONS.SELECT_MESSAGE:
      if (action.selectedMessage.id === state.selectedMessage.id) {
        return {
          ...state,
          selectedMessage: {},
          hasSelectedMessage: false,
        }
      }
      return {
        ...state,
        selectedMessage: {
          ...action.selectedMessage,
        },
        hasSelectedMessage: true,
      }
    case SUPPORTED_REDUX_FUNCTIONS.UNSELECT_MESSAGE:
      return {
        ...state,
        selectedMessage: {},
        hasSelectedMessage: false,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_DELETED_MESSAGE_ID:
      return {
        ...state,
        deletedMessageID: action.deletedMessageID,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_JOB:
      return {
        ...state,
        chatroomJob: {
          ...state.chatroomJob,
          ...action.data,
        },
      }
    case SUPPORTED_REDUX_FUNCTIONS.EMPTY_CHATROOM_JOB:
      return {
        ...state,
        chatroomJob: {},
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_JOB_PAYMENT_HISTORY:
      return {
        ...state,
        jobPaymentHistory: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_PAGE_TYPE:
      return {
        ...state,
        pageType: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOMS:
      return {
        ...state,
        chatrooms: action.data,
        isLoadedChatrooms: true,
      }
    case SUPPORTED_REDUX_FUNCTIONS.RESET_CHATROOMS:
      return {
        ...state,
        chatrooms: [],
        isLoadedChatrooms: false,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_ALL_CHAT:
      return {
        ...state,
        allMessage: action.data,
        isLoadedAllMessage: true,
      }
    case SUPPORTED_REDUX_FUNCTIONS.RESET_ALL_CHAT:
      return {
        ...state,
        allMessage: [],
        isLoadedAllMessage: false,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_JOB_KOL:
      return {
        ...state,
        kol: action.data,
        isLoadedJobKOL: true,
      }
    case SUPPORTED_REDUX_FUNCTIONS.UPDATE_LAST_MESSAGE:
      return {
        ...state,
        chatrooms: state.chatrooms,
        lastMessages: lastMessageManager(state.lastMessages, action.data),
        isLoadedLastMessages: !state.isLoadedLastMessages,
      }
    case SUPPORTED_REDUX_FUNCTIONS.UPDATE_LAST_MESSAGE_FROM_USER:
      return {
        ...state,
        chatrooms: reorderChatroom(state.chatrooms, action.data),
        lastMessages: lastMessageManager(state.lastMessages, action.data),
        isLoadedLastMessages: !state.isLoadedLastMessages,
      }
    case SUPPORTED_REDUX_FUNCTIONS.UPDATE_CHATROOM_AVATAR_AND_TITLE:
      return {
        ...state,
        chatrooms: updateAvatarAndTitle(state.chatrooms, action.data),
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_LAST_MESSAGES:
      return {
        ...state,
        lastMessages: action.data,
        isLoadedLastMessages: true,
      }
    case SUPPORTED_REDUX_FUNCTIONS.IS_WAITING_CHATGPT:
      return {
        ...state,
        isWaitingChatgpt: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.IS_WAITING_ASSISTANT:
      return {
        ...state,
        isWaitingAssistant: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_CHATGPT_PROMPT:
      return {
        ...state,
        chatGptPrompt: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_OWNED_KOL_PAGE_ID:
      return {
        ...state,
        ownedKOLPageID: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_PAST_CHATGPT_MESSAGES:
      return {
        ...state,
        pastChatGPTMessages: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_SELECTED_CHATROOM:
      return {
        ...state,
        selectedChatroom: action.data,
      }

    default:
      return state
  }
}

export default setChatRoomReducer
