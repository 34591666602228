import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { getUserLang } from "../../Utils/utiltyHelper"
import { t } from "i18next"
import { Trans } from "react-i18next"

export enum DRAFT_CONTRACT_TYPE {
  CONTRACT = "job-preview",
  INVOICE = "invoice",
}

interface Props {
  metaData: {
    id: string
    type: DRAFT_CONTRACT_TYPE
  }
}

const CopyDraftContractModalComponent = (props: Props) => {
  const [copied, setCopied] = useState<boolean>(false)
  const copyUrl = () => {
    const url = `https://look4kol.com/${getUserLang()}/${props.metaData.type}/${
      props.metaData.id
    }`

    navigator.clipboard.writeText(url).then(() => console.log("copied"))
    setCopied(true)
  }

  return (
    <div className="p-2">
      <h1 className="font-medium ">
        {props.metaData.type === DRAFT_CONTRACT_TYPE.CONTRACT ? (
          <Trans>CopyDraftContractModal.link</Trans>
        ) : (
          <Trans>CopyDraftContractModal.link-invoice</Trans>
        )}
      </h1>

      <p className="my-4 text-sm text-gray-500 ">
        <Trans>CopyDraftContractModal.desc</Trans>
      </p>
      <div className="form-control">
        <div className="input-group">
          <input
            type="text"
            className="w-full input input-bordered"
            value={`https://look4kol.com/${getUserLang()}/${
              props.metaData.type
            }/${props.metaData.id}`}
          />
          <button className="btn btn-square" onClick={() => copyUrl()}>
            <FontAwesomeIcon
              className="cursor-pointer ml-2 text-base "
              icon={copied ? faCheck : faCopy}
            />
          </button>
        </div>
      </div>
    </div>
  )
}

export default CopyDraftContractModalComponent
