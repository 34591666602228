import React, { useEffect, useState } from "react"
import { ViewFollowersDetailModalData } from "../../Model/Modal"
import { Trans, useTranslation } from "react-i18next"
import { followersCount } from "../../Utils/Converter"
import { Chart } from "react-google-charts"
import {
  IG_USER_CRAWLER_DATA,
  IG_USER_DATA,
  igUserDataDefault,
} from "../../Enum/LOGIN_PAGE_TYPE"
import {
  returnAudienceAgeData,
  returnAudienceCityData,
  returnAudienceGenderData,
} from "../../HelpingFunction/FollowersData/FollowerDBHelper"

const ViewFollowersDetailModalComponent = (
  props: ViewFollowersDetailModalData
) => {
  const { t, i18n } = useTranslation()

  const [igKOLData, setIgKOLData] = useState<
    IG_USER_CRAWLER_DATA & IG_USER_DATA
  >(igUserDataDefault)
  const [isLoaded, setIsLoaded] = useState(true)
  const [isPercentage, setIsPercentage] = useState<boolean>(false)
  useEffect(() => {
    setIgKOLData(props.metaData)
  }, [props.metaData])

  const isChartShow =
    typeof igKOLData.audience_country === "object" &&
    Object.keys(igKOLData.audience_country).length

  return (
    <div className="container mx-auto overflow-y-auto ">
      {isLoaded && (
        <>
          <div className="grid grid-cols-2 gap-2">
            <section className="rounded-2xl p-4 shadow-md my-4 col-span-2 lg:col-span-1 border-2 border-gray-200">
              <h1 className="my-2 font-bold text-gray-700 text-lg">
                {/*<Trans>FollowersData.num-of-follower</Trans>*/}
                {igKOLData.username}
              </h1>
              <div
                className="grid my-4"
                style={{
                  gridTemplateColumns: "60px auto",
                  gridColumnGap: "5px",
                }}>
                <div>
                  <svg
                    className="svg-inline--fa fa-instagram text-lg w-12 h-12"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="instagram"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    data-fa-i2svg="">
                    <path
                      fill="currentColor"
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                  </svg>
                </div>
                <div className={"grid grid-cols-3"}>
                  <div className={"text-center"}>
                    <p className={"text-lg font-semibold"}>
                      {igKOLData.numOfPosts ? igKOLData.numOfPosts : 0}
                    </p>
                    <h3>
                      <Trans>SignUp.posts</Trans>
                    </h3>
                  </div>
                  <div className={"text-center"}>
                    <p className={"text-lg font-semibold"}>
                      {igKOLData.numOfFollowers
                        ? followersCount(igKOLData.numOfFollowers)
                        : 0}
                    </p>
                    <h3>
                      <Trans>SignUp.followers</Trans>
                    </h3>
                  </div>
                  <div className={"text-center"}>
                    <p className={"text-lg font-semibold"}>
                      {igKOLData.numOfFollowing ? igKOLData.numOfFollowing : 0}
                    </p>
                    <h3>
                      <Trans>SignUp.following</Trans>
                    </h3>
                  </div>
                </div>
                {/*  <Trans>FollowersData.last-update</Trans>{" "}*/}
                {/*  /!*{timestraptoIOStime(igKOLData.lastUpdate)}*!/*/}
                {/*</div>*/}
              </div>
              {Array.isArray(igKOLData.imagesList) && (
                <div className="grid md:h-72 h-full grid-cols-3 md:grid-cols-4 lg:grid-cols-3  gap-4 overflow-y-scroll">
                  {igKOLData.imagesList.map((img, index) => {
                    return <img key={index} src={img} />
                  })}
                </div>
              )}
            </section>

            <section className="rounded-2xl p-4 shadow-md my-4 col-span-2 lg:col-span-1 border-2 border-gray-200">
              <div
                className="grid gap-4"
                style={{ gridTemplateColumns: "auto 70px 70px" }}>
                <div className="">
                  <h1 className="my-2 font-bold text-gray-700 text-lg">
                    <Trans>LocationEditRow.title</Trans>
                  </h1>
                </div>
              </div>
              {isChartShow ? (
                <div className="relative">
                  <Chart
                    width={"100%"}
                    height={400}
                    chartType="GeoChart"
                    loader={<div>Loading Chart</div>}
                    data={[
                      ["Country", "Popularity"],
                      ...returnAudienceCityData(
                        igKOLData.audience_country,
                        100
                      ),
                    ]}
                    // For tests
                    rootProps={{ "data-testid": "2" }}
                  />
                  <div
                    className=" absolute right-0 bottom-0 border-2 rounded-md"
                    style={{
                      width: "200px",
                      height: "130px",
                    }}>
                    <div
                      className="absolute font-bold  text-gray-700 p-2"
                      style={{ zIndex: 5 }}>
                      <Trans>ViewFollowersDetailModal.hong-kong</Trans>
                    </div>
                    <Chart
                      chartType="GeoChart"
                      loader={<div>Loading Chart</div>}
                      data={[
                        ["Country", "Popularity"],
                        ...returnAudienceCityData(
                          igKOLData.audience_country,
                          100
                        ),
                      ]}
                      options={{ region: "HK" }}
                      // For tests
                      rootProps={{ "data-testid": "2" }}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <h2>
                    <Trans>ViewFollowersDetailModal.data-not-found</Trans>
                  </h2>
                </div>
              )}
            </section>
          </div>

          <div className="grid grid-cols-2 gap-2 mb-16">
            <section className="rounded-2xl p-4 shadow-md my-4 col-span-2 lg:col-span-1 border-2 border-gray-200">
              <div
                className="grid gap-2"
                style={{ gridTemplateColumns: "auto 100px" }}>
                <div>
                  <h1 className="my-2 font-bold text-gray-700 text-lg">
                    <Trans>GenderEditRow.title</Trans>
                  </h1>
                </div>
              </div>
              {isChartShow ? (
                <div>
                  <Chart
                    width={"100%"}
                    height={"100%"}
                    chartType="PieChart"
                    loader={<div>Loading Chart</div>}
                    data={[
                      [
                        t("GenderEditRow.gender"),
                        t("IGFollowDataEditor.percentage"),
                      ],
                      ...returnAudienceGenderData(
                        igKOLData.audience_gender_age,
                        isPercentage
                      ),
                    ]}
                    options={{
                      sliceVisibilityThreshold: 0,
                      colors: ["#0ea5e9", "#e17d72", "#22c55e"],
                    }}
                    // For tests
                    rootProps={{ "data-testid": "2" }}
                  />
                </div>
              ) : (
                <div>
                  <h2>
                    <Trans>ViewFollowersDetailModal.data-not-found</Trans>
                  </h2>
                </div>
              )}
            </section>

            <section className="rounded-2xl p-4 shadow-md my-4 col-span-2 lg:col-span-1 border-2 border-gray-200">
              <div
                className="grid gap-4"
                style={{ gridTemplateColumns: "auto 100px" }}>
                <div className="">
                  <h1 className="my-2 font-bold text-gray-700 text-lg">
                    <Trans>AgeGroupEditRow.title</Trans>
                  </h1>
                </div>
              </div>
              {isChartShow ? (
                <div>
                  <Chart
                    width={"100%"}
                    height={"100%"}
                    chartType="BarChart"
                    loader={<div>Loading Chart</div>}
                    data={[
                      [
                        t("AgeGroupEditRow.age"),
                        isPercentage
                          ? t("IGFollowDataEditor.percentage")
                          : t("IGFollowDataEditor.count"),
                      ],
                      ...returnAudienceAgeData(
                        props.metaData.audience_gender_age,
                        isPercentage
                      ),
                    ]}
                    options={{
                      // Material design options
                      chart: {
                        title: t("AgeGroupEditRow.title"),
                        subtitle: t("AgeGroupEditRow.description"),
                      },
                      colors: ["#e17d72"],
                    }}
                    // For tests
                    rootProps={{ "data-testid": "2" }}
                  />
                </div>
              ) : (
                <h2>
                  <Trans>ViewFollowersDetailModal.data-not-found</Trans>
                </h2>
              )}
            </section>
          </div>
        </>
      )}
    </div>
  )
}
export default ViewFollowersDetailModalComponent
