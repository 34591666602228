import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"

const initialState = {
  allKOLPlan: [],
}

const KOLPlanManager = (state = initialState, action) => {
  if (action.type === SUPPORTED_REDUX_FUNCTIONS.SET_KOL_PLAN) {
    return {
      ...state,
      allKOLPlan: action.allKOLPlan,
    }
  }

  return state
}

export default KOLPlanManager
