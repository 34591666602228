import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Job } from "../../../Model/Job"
import { Project } from "../../../Model/ProjectManagement"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { Trans, useTranslation } from "react-i18next"
import { Column } from "../../../Model/Table"
import {
  defaultProjectJobItemTableSchema,
  listProjectJobItemTableSchema,
} from "../../../Utils/TableHelperFunction"
import CustomTable from "../../../Component/NewTable"

interface ProjectJobItemProps {
  data: Job[]
  isGettingMoreJob: boolean
  hasMore: boolean
}

const ListKOLJobTable: React.FC<ProjectJobItemProps> = ({
  data,
  isGettingMoreJob,
  hasMore,
}) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const selectedKOL = useSelector((state: any) => {
    return state.ProjectManager.selectedKOL
  })

  const project: Project = useSelector((state: any) => {
    return state.ProjectManager.currentProject
  })
  const jobs: Job[] = useSelector((state: any) => {
    return state.ProjectManager.jobList
  })

  const [projectList, setProjectList] = useState<Column[][]>([
    defaultProjectJobItemTableSchema(),
  ])

  const [selectedJobID, setSelectedJobID] = useState<string[]>(
    jobs.map((e) => e.id)
  )

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const setProject = (project: Project) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CURRENT_PROJECT,
      data: project,
    })
  }
  const setJobList = (jobs: Job[]) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_JOB_LIST,
      data: jobs,
    })
  }

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [data])

  useEffect(() => {
    let projectTableSchema: Column[][] = []
    //  generate table schema
    data.map((j, index) => {
      // [job]
      let cols: Column[] = [
        ...listProjectJobItemTableSchema({
          job: j,
          handleClickJob: () => handleClickJob(j.id),
          selectedJobID: selectedJobID,
        }),
      ]
      projectTableSchema.push(cols)
    })
    setProjectList(projectTableSchema)
  }, [data, selectedJobID])

  const handleClickJob = (jobID: string) => {
    let newSelectedJobID = [...selectedJobID]
    const jobIncluded = selectedJobID.includes(jobID)

    newSelectedJobID = jobIncluded
      ? newSelectedJobID.filter((e) => e !== jobID)
      : [...newSelectedJobID, jobID]

    setSelectedJobID(newSelectedJobID)
    setProject({ ...project, jobList: newSelectedJobID })
    setJobList(
      jobIncluded
        ? jobs.filter((job) => job.id !== jobID)
        : [...jobs, ...data.filter((j) => j.id === jobID)]
    )
  }

  if (selectedKOL && !isLoading) {
    return (
      <div>
        <CustomTable
          cols={projectList}
          isFirstColStatic={false}
          rowIsLoading={isGettingMoreJob}
          notFoundDes={t("ProjectOptionBar.no-jobs-kol")}
          isLoading={false}
          isLastRecord={!hasMore}
        />
      </div>
    )
  } else if (!isLoading) {
    return (
      <div className="my-4">
        <Trans>ProjectOptionBar.no-jobs-kol</Trans>
      </div>
    )
  }
  return <div className="my-4"></div>
}

export default ListKOLJobTable
