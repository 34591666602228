export const SUPPORTED_REDUX_FUNCTIONS = {
  TOGGLE_MODAL: "TOGGLE_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",

  ADD_SERVICE_TAG: "ADD_SERVICE_TAG",
  REMOVE_SERVICE_TAG: "REMOVE_SERVICE_TAG",

  SET_CHATROOM_INFO: "SET_CHATROOM_INFO",
  SET_CHATROOM_INFO_LOCAL: "SET_CHATROOM_INFO_LOCAL",
  SET_CHATROOM_JOB: "SET_CHATROOM_JOB",
  EMPTY_CHATROOM_JOB: "EMPTY_CHATROOM_JOB",
  SET_JOB_PAYMENT_HISTORY: "SET_JOB_PAYMENT_HISTORY",
  SET_CHATROOM_PAGE_TYPE: "SET_CHATROOM_PAGE_TYPE",
  SET_CHATROOMS: "SET_CHATROOMS",
  RESET_CHATROOMS: "RESET_CHATROOMS",
  SET_ALL_CHAT: "SET_ALL_CHAT",
  RESET_ALL_CHAT: "RESET_ALL_CHAT",
  SET_JOB_KOL: "SET_JOB_KOL",
  SET_LAST_CHAT_TO_CHATROOM: "SET_LAST_CHAT_TO_CHATROOM",
  UPDATE_LAST_MESSAGE: "UPDATE_LAST_MESSAGE",
  UPDATE_LAST_MESSAGE_FROM_USER: "UPDATE_LAST_MESSAGE_FROM_USER",
  SET_LAST_MESSAGES: "SET_LAST_MESSAGES",
  UPDATE_CHATROOM_AVATAR_AND_TITLE: "UPDATE_CHATROOM_AVATAR_AND_TITLE",
  SET_SELECTED_CHATROOM: "SET_SELECTED_CHATROOM",

  SET_KOL_INFO: "SET_KOL_INFO",
  SET_IG_USER_DATA: "SET_IG_USER_DATA",
  SET_KOL_PROFILE_JOBS: "SET_KOL_PROFILE_JOBS",
  BOOK_MARK_KOL: "BOOK_MARK_KOL",
  SET_SEARCH_PAGE_ROUTE: "SET_SEARCH_PAGE_ROUTE",
  SET_FACEBOOK_PAGE_ID: "SET_FACEBOOK_PAGE_ID",

  SELECT_MESSAGE: "SELECT_MESSAGE",
  UNSELECT_MESSAGE: "UNSELECT_MESSAGE",
  SET_DELETED_MESSAGE_ID: "SET_DELETED_MESSAGE_ID",

  SHARE_DRAFT_MSG: "SHARE_DRAFT_MSG",
  RESET_DRAFT_MSG: "RESET_DRAFT_MSG",
  IS_WAITING_CHATGPT: "IS_WAITING_CHATGPT",
  IS_WAITING_ASSISTANT: "IS_WAITING_ASSISTANT",
  SET_CHATGPT_PROMPT: "SET_CHATGPT_PROMPT",
  SET_OWNED_KOL_PAGE_ID: "SET_OWNED_KOL_PAGE_ID",
  SET_PAST_CHATGPT_MESSAGES: "SET_PAST_CHATGPT_MESSAGES",

  RECEIVED_MESSAGE: "RECEIVED_MESSAGE",
  REMOVE_MESSAGE: "REMOVE_MESSAGE",
  MARK_AS_READ: "MARK_AS_READ",
  SET_NUM_OF_UNREAD_CONTRACT: "SET_NUM_OF_UNREAD_CONTRACT",
  SET_NUM_OF_UNREAD_CHAT: "SET_NUM_OF_UNREAD_CHAT",
  SET_MESSAGES: "SET_MESSAGES",

  SET_CASE_STUDY: "SET_CASE_STUDY",
  ADD_CASE_STUDY: "ADD_CASE_STUDY",
  EDIT_CASE_STUDY: "EDIT_CASE_STUDY",
  REMOVE_CASE_STUDY: "REMOVE_CASE_STUDY",
  SET_CASE_STUDY_MEDIA: "SET_CASE_STUDY_MEDIA",

  SET_KOL_PLAN: "SET_KOL_PLAN",

  SET_SIDEBAR_ACTIVE: "SET_SIDEBAR_ACTIVE",

  //    SystemManager
  SET_SCREEN_WIDTH: "SET_SCREEN_WIDTH",
  SET_SCREEN_HEIGHT: "SET_SCREEN_HEIGHT",
  SET_USER_EMAIL_CONFIRMATION: "SET_USER_EMAIL_CONFIRMATION",
  SET_SIGN_UP_KOL: "SET_SIGN_UP_KOL",
  SET_SIGN_UP_CUSTOMER: "SET_SIGN_UP_CUSTOMER",

  // ToolsManager
  SET_SEARCH_KOL_QUERY: "SET_SEARCH_KOL_QUERY",
  SET_SEARCH_KOL_QUERIES: "SET_SEARCH_KOL_QUERIES",
  RESET_SEARCH_KOL: "RESET_SEARCH_KOL",
  SET_SEARCH_KOL_STEP: "SET_SEARCH_KOL_STEP",
  SET_QUOTE_STEP: "SET_QUOTE_STEP",

  // ToolsManager　> compare group
  SET_COMPARE_GROUP: "SET_COMPARE_GROUP",
  ADD_KOL_TO_COMPARE_GROUP: "ADD_KOL_TO_COMPARE_GROUP",
  REMOVE_KOL_FROM_COMPARE_GROUP: "REMOVE_KOL_FROM_COMPARE_GROUP",
  CREATE_NEW_COMPARE_GROUP: "CREATE_NEW_COMPARE_GROUP",
  COMPARE_GROUP_NEED_UPDATE: "COMPARE_GROUP_NEED_UPDATE",
  REMOVE_COMPARE_GROUP: "REMOVE_COMPARE_GROUP",
  SHOW_HELP_TEXT: "SHOW_HELP_TEXT",
  TOGGLE_CONDITION_DROPDOWN: "TOGGLE_CONDITION_DROPDOWN",
  SET_SEARCH_RESULT: "SET_SEARCH_RESULT",
  SET_IS_SEARCHING: "SET_IS_SEARCHING",
  ADD_TO_SELECTED_KOL: "ADD_TO_SELECTED_KOL",
  REMOVE_FROM_SELECTED_KOL: "REMOVE_FROM_SELECTED_KOL",
  SET_SEARCH_SELECTED_KOLS: "SET_SEARCH_SELECTED_KOLS",

  //  ErrorManager
  SET_ERROR_MSG: "SET_ERROR_MSG",
  CLOSE_ERROR_MSG: "CLOSE_ERROR_MSG",

  //ContractManager
  SET_SCHEDULES: "SET_SCHEDULES",
  ADD_NEW_SCHEDULE: "ADD_NEW_SCHEDULE",
  EDIT_SCHEDULE: "EDIT_SCHEDULE",
  REMOVE_SCHEDULE: "REMOVE_SCHEDULE",
  SET_IS_SCHEDULE_EDIT: "SET_IS_SCHEDULE_EDIT",
  SET_JOB_CASE_STUDY: "SET_JOB_CASE_STUDY",
  SET_ADMIN_REQUEST: "SET_ADMIN_REQUEST",
  SET_CONTRACT_STEP: "SET_CONTRACT_STEP",

  // AdminControlManger
  SET_PROMOTED_KOL_LIST: "SET_PROMOTED_KOL_LIST",
  ADD_TO_PROMOTED_KOL_LIST: "ADD_TO_PROMOTED_KOL_LIST",
  REMOVE_FROM_PROMOTED_KOL_LIST: "REMOVE_FROM_PROMOTED_KOL_LIST",

  // LoginManager
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",

  // HelpDocManager
  DIRECT_TO_HELP_DOC: "DIRECT_TO_HELP_DOC",
  HELP_DOC_RESET: "HELP_DOC_RESET",

  //ProjectManager
  SET_PROJECTS: "SET_PROJECTS",
  SET_KOL_LIST: "SET_KOL_LIST",
  SET_TABLE_SELECTED_KOLS: "SET_TABLE_SELECTED_KOLS",
  SET_SELECTED_KOL: "SET_SELECTED_KOL",
  SET_SELECTED_KOL_NAME: "SET_SELECTED_KOL_NAME",
  SET_JOB_LIST: "SET_JOB_LIST",
  SET_CURRENT_PROJECT: "SET_CURRENT_PROJECT",
  SET_PROJECT_PAGE: "SET_PROJECT_PAGE",
  SET_PROJECT_PAGE_EXPAND: "SET_PROJECT_PAGE_EXPAND",
  SET_CHECKED: "SET_CHECKED",
  SET_ALL_KOLS_TAB: "SET_ALL_KOLS_TAB",
  SET_GROUP_QUOTATION_PAGE: "SET_GROUP_QUOTATION_PAGE",
  SET_GROUP_QUOTATION_PROGRESS: "SET_GROUP_QUOTATION_PROGRESS",
  SET_GROUP_CONTRACT_DETAILS: "SET_GROUP_CONTRACT_DETAILS",
  SET_PROJECT_LOADED: "SET_PROJECT_LOADED",
  SET_KOL_GROUP_QUOTATION: "SET_KOL_GROUP_QUOTATION",

  // SearchManager
  SET_SEARCH_DROPDOWN: "SET_SEARCH_DROPDOWN",

  //MemberManager
  SET_BOOKMARK_LIST: "SET_BOOKMARK_LIST",
  SET_ALL_BOOKMARK_LIST: "SET_ALL_BOOKMARK_LIST",

  //RecommendationManager
  SET_CURRENT_RECOMMENDATION: "SET_CURRENT_RECOMMENDATION",
  SET_RECOMMENDATION_STEP: "SET_RECOMMENDATION_STEP",
  ON_CLICK_KOL: "ON_CLICK_KOL",
  SELECT_KOL_ARRAY: "SELECT_KOL_ARRAY",
  SET_RECOMMENDATION_PROGRESS: "SET_RECOMMENDATION_PROGRESS",
  SET_PROJECT_ID: "SET_PROJECT_ID",
  SET_RECOM_SEARCH_KOL_QUERY: "SET_RECOM_SEARCH_KOL_QUERY",
  SET_SUGGESTED_KOL: "SET_SUGGESTED_KOL",

  //ChatGPTManager
  SET_CHATGPT_TAB: "SET_CHATGPT_TAB",
  UPDATE_CHATGPT_SETTING: "UPDATE_CHATGPT_SETTING",
  UPDATE_COMMEND_TYPE: "UPDATE_COMMEND_TYPE",
  SET_PAST_CHATBOT_MESSAGES: "SET_PAST_CHATBOT_MESSAGES",
  UPDATE_RECOMMEND_KOLS: "UPDATE_RECOMMEND_KOLS",
  RESET_RECOMMEND_KOLS: "RESET_RECOMMEND_KOLS",

  //AssistantManager
  ADD_ASSISTANT_MSG: "ADD_ASSISTANT_MSG",
  RESET_ASSISTANT_MSG: "RESET_ASSISTANT_MSG",
  SET_ASSISTANT_PROGRESS: "SET_ASSISTANT_PROGRESS",
  EXPANDED_KOL: "EXPANDED_KOL",
  SET_QUOTATION_SUMMARY: "SET_QUOTATION_SUMMARY",
  SET_QUOTATION_READY: "SET_QUOTATION_READY",
  SET_KOLLIST_READY: "SET_KOLLIST_READY",
  SET_CARD_VIEW: "SET_CARD_VIEW",

  //ManagementManager
  SET_OWNED_KOL: "SET_OWNED_KOL",
  SET_OWNED_KOL_PROFILE: "SET_OWNED_KOL_PROFILE",

  // SearchManager
  SET_VIEW_ID: "SET_VIEW_ID",
  TOGGLE_FORCE_UPDATE: "TOGGLE_FORCE_UPDATE",
}
