export enum ObjectiveType {
  REACH,
  ENGAGEMENT,
}

export enum RecomStep {
  FEATURE,
  ABOUT,
  QUESTION,
  KOL,
  CONTRACT,
  PROGRESS,
}

export enum RecomGender {
  ALL = "All",
  MALE = "Male",
  FEMALE = "Female",
  UNKNOWN = "Unknown",
}

export enum RecomProgress {
  START,
  CREATE_PROJECT,
  CREATE_CHATROOM,
  CREATE_JOB,
  UPDATE_PROJECT,
  FINISH,
}

export interface RecomData {
  objectiveType: ObjectiveType | null
  campaignName: string
  campaignDescription: string
  kolNum: { toggle: boolean; value: number }
  budget: number
  minAge: number
  maxAge: number
  preference: {
    genre: string[]
    category: string
    gender: string
    country: string[]
    ages: string[] | boolean
  }
  contractName: string
  contractDescription: string
  countryAlpha: string[]
  genreLabel: string[]
}

export const RecommendationDefault: RecomData = {
  objectiveType: null,
  campaignName: "",
  campaignDescription: "",
  kolNum: { toggle: false, value: 0 },
  budget: 0,
  minAge: 0,
  maxAge: 0,
  preference: {
    genre: [],
    category: "",
    gender: "",
    country: [],
    ages: [],
  },
  contractName: "",
  contractDescription: "",
  countryAlpha: [],
  genreLabel: [],
}
