import React from "react"
import { ContactKOLModalData } from "../../Model/Modal"
import KOLPlanList from "../KOLPlanList"

const ContactKOLModalComponent = (props: ContactKOLModalData) => {
  return (
    <div>
      <div className="container mx-auto md:overflow-y-hidden overflow-x-hidden md:mb-0 p-2 mb-12">
        <KOLPlanList KOLID={props.metaData.KOLID} isKOL={false} />
      </div>
    </div>
  )
}

export default ContactKOLModalComponent
