import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  faArrowLeftLong,
  faBookmark,
  faComments,
  faRecordVinyl,
  faVideoCamera,
} from "@fortawesome/free-solid-svg-icons"
import { Capacitor } from "@capacitor/core"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { useDispatch, useSelector } from "react-redux"
import { ZEST_MENU_TYPE } from "../../../Enum/ZEST_SYSTEM"

import { Trans } from "react-i18next"

const ZestSystem: React.FC = (props) => {
  const isBrowser =
    Capacitor.getPlatform() !== "android" && Capacitor.getPlatform() !== "ios"

  const [menuType, setMenuType] = useState(ZEST_MENU_TYPE.MAIN)

  const dispatch = useDispatch()

  const docData = useSelector((state: any) => {
    return state.HelpDocManager.doc
  })

  useEffect(() => {
    if (docData.isOpened) {
      setMenuType(ZEST_MENU_TYPE.GITBOOK)
    }
  }, [docData])

  const onHelpDocDirect = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.DIRECT_TO_HELP_DOC,
      data: state,
    })
  }

  // Button sample
  // <button
  // className="btn mx-10"
  // onClick={() => {
  //   onHelpDocDirect("/kol/page-2")
  // }}>
  // help doc test 2
  // </button>

  switch (menuType) {
    case ZEST_MENU_TYPE.MAIN:
      return (
        <div
          className="hidden md:inline fixed bottom-16 md:bottom-20 right-2 z-20"
          id="zest-chat">
          <div className="dropdown dropdown-top dropdown-end">
            <label
              tabIndex={0}
              className="btn btn-lg btn-primary btn-circle shadow-xl m-1">
              <FontAwesomeIcon icon={faComments} />
            </label>
            <div
              tabIndex={0}
              className="dropdown-content menu p-4 border border-gray-200 bg-white shadow-lg rounded-box w-108 grid justify-items-stretch">
              <button
                className="btn btn-primary mb-4 w-full no-animation"
                onClick={() => {
                  setMenuType(ZEST_MENU_TYPE.GITBOOK)
                }}>
                <div>
                  <FontAwesomeIcon icon={faBookmark} className="text-xl mx-4" />
                  <Trans>ZestSystem.help-doc</Trans>
                </div>
              </button>

              <button
                className="btn btn-primary mb-4 w-full no-animation"
                onClick={() => {
                  setMenuType(ZEST_MENU_TYPE.RECORDING)
                }}>
                <div>
                  <FontAwesomeIcon
                    icon={faVideoCamera}
                    className="text-xl mx-4"
                  />
                  <Trans>ZestSystem.record-problem</Trans>
                </div>
              </button>
            </div>
          </div>
        </div>
      )
    case ZEST_MENU_TYPE.GITBOOK:
      return (
        <div
          className="hidden md:inline fixed bottom-16 md:bottom-2 right-2 z-50"
          id="zest-chat">
          <div className="dropdown dropdown-top dropdown-end dropdown-open">
            <label
              tabIndex={0}
              className="btn btn-lg btn-primary btn-circle shadow-xl m-1">
              <FontAwesomeIcon icon={faComments} />
            </label>
            <div
              tabIndex={0}
              className="dropdown-content menu p-4 border border-gray-200 bg-white shadow-lg rounded-box w-128 grid justify-items-stretch">
              <button
                className="btn btn-ghost btn-circle btn-xs justify-self-start no-animation"
                onClick={() => {
                  setMenuType(ZEST_MENU_TYPE.MAIN)
                }}>
                <FontAwesomeIcon icon={faArrowLeftLong} className="text-lg" />
              </button>
              <div className="my-4">
                {/*when click this button, show gitbook embeded by using <iframe>*/}
                {/*<iframe src="https://www.example.com/"></iframe>*/}
                <iframe
                  src={"https://docs.look4kol.com" + docData.currentPath}
                  className="h-96 w-full"></iframe>
              </div>
            </div>
          </div>
        </div>
      )
    case ZEST_MENU_TYPE.RECORDING:
      return (
        <div
          className="hidden md:inline fixed bottom-16 md:bottom-20 right-2 z-20"
          id="zest-chat">
          <div className="dropdown dropdown-top dropdown-end dropdown-open">
            <label
              tabIndex={0}
              className="btn btn-lg btn-primary btn-circle shadow-xl m-1">
              <FontAwesomeIcon icon={faComments} />
            </label>
            <div
              tabIndex={0}
              className="dropdown-content menu p-4 border border-gray-200 bg-white shadow-lg rounded-box w-128 grid justify-items-stretch">
              <button
                className="btn btn-ghost btn-circle btn-xs justify-self-start no-animation"
                onClick={() => {
                  setMenuType(ZEST_MENU_TYPE.MAIN)
                }}>
                <FontAwesomeIcon icon={faArrowLeftLong} className="text-lg" />
              </button>
              <div>
                <div>
                  <div className="text-lg font-bold">
                    <Trans>ZestSystem.title</Trans>
                  </div>
                  <div className="text-md text-gray-500">
                    <Trans>ZestSystem.description</Trans>
                  </div>
                </div>
                {isBrowser ? (
                  <div>
                    <a href="#" className="zstw_open"></a>
                    <button className="btn btn-primary mt-4 w-full zstw_open">
                      <FontAwesomeIcon
                        icon={faRecordVinyl}
                        className="text-lg mr-2"
                      />
                      <Trans>ZestSystem.start-record</Trans>
                    </button>
                  </div>
                ) : (
                  <div className="alert alert-error text-red-default">
                    <Trans>ZestSystem.browser-only</Trans>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    default:
      return <div></div>
  }
}
export default ZestSystem
