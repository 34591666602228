import {
  faCircleUser,
  faFire,
  faUsers,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import KOL, { KOLType } from "../../../Model/KOL"
import { PageType, Project } from "../../../Model/ProjectManagement"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import {
  getKOL,
  getKOLFollowerGroup,
} from "../../../HelpingFunction/KOL/kolDBHelper"
import { returnSelectedCategories } from "../../../Utils/TypeList"
import { followersCount } from "../../../Utils/Converter"

interface Props {
  data: KOLType
}

const ProjectKOLItem: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch()

  const project: Project = useSelector((state: any) => {
    return state.ProjectManager.currentProject
  })
  const kols: KOL[] = useSelector((state: any) => {
    return state.ProjectManager.kolList
  })

  const setPage = (page: PageType, pagePropsID?: string) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_PROJECT_PAGE,
      data: page,
      pagePropsID: pagePropsID,
    })
  }
  const setSelectedKOL = (kolID: string) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SELECTED_KOL,
      data: kolID,
    })
  }
  const setSelectedKOLName = (username: string) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SELECTED_KOL_NAME,
      data: username,
    })
  }

  const setProject = (project: Project) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CURRENT_PROJECT,
      data: project,
    })
  }
  const setKOLList = (kols: KOL[]) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_KOL_LIST,
      data: kols,
    })
  }
  const includes = project.kolList.includes(data.id as string)
  const [checked, setChecked] = useState(false)
  const [kol, setKOL] = useState<KOLType>(data)
  const [followersNum, setFollowersNum] = useState<number>(0)

  useEffect(() => {
    if (data.objectID) {
      getKOLInfo()
    }

    getFollowersCount()
  }, [])

  const getKOLInfo = async () => {
    const res = await getKOL(data.id)

    if (res.success) {
      let newKOL: KOLType = {
        ...data,
        avatarUrl: res.data.avatarUrl,
      }

      setKOL(newKOL)
    }
  }

  const getFollowersCount = async () => {
    const res = await getKOLFollowerGroup(data.id)
    if (res.success) {
      setFollowersNum(res.data.numOfFollowers)
    }
  }

  const updateProject = (p: Project, kols: KOL[]) => {
    setProject(p)
    setKOLList(kols)
    setChecked(!checked)
  }
  const handleClick = (kol: KOLType) => {
    // if kol is not include in project, add kol to the list
    if (!includes) {
      //add to project doc, only store the kolID
      project.kolList.push(kol.id)

      //stone on redux store the whole kol object
      let newKOls = [...kols, data]
      updateProject(project, newKOls)
    } else {
      //remove to project doc, only store the kolID
      project.kolList = project.kolList.filter((e) => e !== kol.id)

      //stone on redux store the whole kol object
      let newKOls = [...kols].filter((e) => e.id !== kol.id)
      updateProject(project, newKOls)
    }
  }

  const handleJobClick = () => {
    if (data.id) {
      setSelectedKOL(data.id as string)
      setSelectedKOLName(data.userName)
    } else {
      setSelectedKOL(data.objectID as string)
      setSelectedKOLName(kol.userName)
    }
    if (project.id) {
      setPage(PageType.ProjectLinks)
    } else {
      setPage(PageType.Job)
    }
  }

  if (data.userName) {
    return (
      <div className="bg-white shadow-sm hover:bg-gray-200 rounded-xl p-2 lg:p-4 relative cursor-pointer">
        <div className="grid grid-cols-5  items-center">
          <div
            onClick={() => {
              handleJobClick()
            }}
            className="col-span-4 grid w-full border-r-2 border-gray-300"
            style={{ gridTemplateColumns: "50px auto" }}>
            <div className="self-center ">
              {kol.avatarUrl ? (
                <img className="w-10 h-10 rounded-full" src={kol.avatarUrl} />
              ) : (
                <FontAwesomeIcon
                  className="text-primary text-4xl"
                  icon={faCircleUser}
                />
              )}
            </div>

            <div className="font-normal text-lg  grid grid-rows-2">
              <div>{kol.userName}</div>

              <div className="flex flex-row items-center gap-2">
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                {data.topCateID &&
                  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                  //@ts-ignore
                  returnSelectedCategories([data.topCateID]).map((t) => {
                    return (
                      <div
                        key={t.value}
                        className=" flex items-center w-auto md:max-w-[170px] max-w-[120px] border p-1 bg-white  text-xs   text-red-default rounded-xl">
                        <FontAwesomeIcon
                          icon={faFire}
                          className="mr-2 text-red-default my-auto"
                        />
                        <p className="truncate">{t.label}</p>
                      </div>
                    )
                  })}

                <div className="flex text-xs text-gray-500 items-center">
                  <FontAwesomeIcon icon={faUsers} className="mr-2" />
                  {followersCount(followersNum)}
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 justify-self-center pt-1">
            <input
              type="checkbox"
              onChange={() => handleClick(kol)}
              checked={includes}
              className="checkbox"
            />
          </div>
        </div>
      </div>
    )
  }
  return <div></div>
}

export default ProjectKOLItem
