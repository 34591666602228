export enum BADGE_TYPE {
  SUCCESS,
  WARNING,
  FAIL,
  TAG,
  WORKING,
  JOB_DONE,
  JOB_ACCEPTED,
  WAIT_QUOTE_ACCEPT,
  QUOTATION,
  CONTRACT,
  PENDING,
  INVOICE,
}

export enum BUTTON_TYPE {
  SUCCESS,
  WARNING,
  FAIL,
  INFO,
  GHOST,
}

export enum ICON_TYPE {
  SUCCESS,
  WARNING,
  FAIL,
  GHOST,
}

export enum TABLE_TYPE {
  BUTTON,
  STRING,
  BADGE,
  PRICE,
  DATE,
  CHECK_BOX,
  TOGGLE,
  ICON,
  SCORE,
  AVATAR,
  CUSTOM,
  DEFAULT,
  EMPTY,
  COL,
  ROW,
  HEAD,
  EXPANDED,
}

export enum TABLE_FUNCTIONS {
  SELECT,
  MULTISELECT,
  BUTTON,
  DROPDOWN,
  DATE,
  INPUT,
  CUSTOM,
}

export enum MULTISELECT_TYPE {
  GENRE,
}

export enum WIDTH_SIZE {
  SM = "SM",
  MD = "MD",
  LG = "LG",
  XL = "XL",
  XXL = "XXL",
  XXXL = "XXXL",
  FULL = "FULL",
}

export enum BUTTON_SIZE {
  XS = "XS",
  SM = "SM",
  MD = "MD",
  LG = "LG",
  XL = "XL",
}

export enum HEADER_STYLE {
  H1 = "H1",
  H2 = "H2",
  H3 = "H3",
}
