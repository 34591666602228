import React from "react"
import { Link, useLocation } from "react-router-dom"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { getUserLang } from "../Utils/utiltyHelper"
import { queryChecker } from "../Utils/QueryHelper"

interface BadgeLinkButtonProps {
  visible?: boolean
  path: string
  i18Text: string
  color?: string
  icon?: IconDefinition
  onClickFn?: () => void
}

export const BadgeLinkButton: React.FC<BadgeLinkButtonProps> = ({
  visible,
  path,
  i18Text,
  color,
  icon,
  onClickFn,
}) => {
  const location = useLocation()

  const isAfter = () => {
    if (path.includes("?")) {
      return (
        location.pathname.includes(
          "/" + getUserLang() + "/" + path.split("?")[0]
        ) && queryChecker(location.search, path)
      )
    }
    return (
      location.pathname.includes("/" + getUserLang() + "/" + path) &&
      queryChecker(location.search, path)
    )
  }
  const colorCode = color ? `btn-${color}` : ""

  return (
    <Link
      className={classNames({
        hidden: visible === false,
      })}
      to={"/" + getUserLang() + "/" + path}
      onClick={(e) => {
        if (onClickFn) {
          onClickFn()
        }
      }}>
      <button
        className={classNames(
          "btn mx-1 py-1 px-4 font-normal border border-gray-200 hover:border-gray-200 rounded-full gap-2 normal-case",
          {
            "bg-red-default hover:bg-red-default text-white": isAfter(),
            "btn-ghost": !color,
          },
          colorCode
        )}>
        {icon && (
          <FontAwesomeIcon icon={icon} className={classNames("text-lg")} />
        )}
        {i18Text}
      </button>
    </Link>
  )
}
