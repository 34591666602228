import React from "react"
import { DeleteMessageModalData } from "../../Model/Modal"
import { Trans } from "react-i18next"
import { deleteDoc, doc, getFirestore } from "firebase/firestore"
import firebaseApp from "../../Config/firebase"
import { useDispatch } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"

const db = getFirestore(firebaseApp)
const DeleteMessageModalComponent = (props: DeleteMessageModalData) => {
  return (
    <div className="container mx-auto  overflow-y-auto ">
      <label>
        <Trans>DeleteMessageModal.confirm-delete</Trans>
      </label>

      <p>
        <Trans>DeleteMessageModal.confirm-delete-warning</Trans>
      </p>
    </div>
  )
}

export default DeleteMessageModalComponent
