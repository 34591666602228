import { getAPIPath } from "../../Utils/HelpingFunction"
import { returnIdTokenResult } from "../../Utils/FirebaseUtils"

export async function createNewProduct(e, kolID, agencyID) {
  return new Promise(async (resolve, reject) => {
    returnIdTokenResult().then(async (res) => {
      let headerInfo = {
        "Content-Type": "application/json",
        idToken: res.token,
      }
      if (agencyID) {
        headerInfo = {
          ...headerInfo,
          agencyID: agencyID,
        }
      }
      await fetch(getAPIPath("/api/kol/" + kolID + "/product"), {
        method: "PUT",
        headers: headerInfo,
        body: JSON.stringify({
          productID: e.id,
          name: e.title ? e.title : "",
          active: e.isVisible,
          price: e.price,
          // currency coming soon
        }),
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            resolve({
              success: true,
              data: finalResponse.data,
            })
          } else {
            console.log(finalResponse.message)
            resolve({
              success: false,
              message: finalResponse.message,
            })
          }
        })
    })
  })
}

export async function updateProduct(e, kolID, isPriceChange, agencyID) {
  return new Promise(async (resolve, reject) => {
    returnIdTokenResult().then(async (res) => {
      let headerInfo = {
        "Content-Type": "application/json",
        idToken: res.token,
      }
      if (agencyID) {
        headerInfo = {
          ...headerInfo,
          agencyID: agencyID,
        }
      }
      await fetch(getAPIPath("/api/kol/" + kolID + "/product/" + e.id), {
        method: "PATCH",
        headers: headerInfo,
        body: JSON.stringify({
          isPriceChange: isPriceChange,
          name: e.title,
          active: e.isVisible,
          price: e.price,
          // currency coming soon
        }),
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            resolve({
              success: true,
              data: finalResponse.data,
            })
          } else {
            console.log(finalResponse.message)
            resolve({
              success: false,
              message: finalResponse.message,
            })
          }
        })
    })
  })
}
