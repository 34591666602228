import React, { useState } from "react"
import { DatePicker } from "react-responsive-datepicker"
import "react-responsive-datepicker/dist/index.css"
import { timeStampToDisplayTimeString } from "../../../Utils/Converter"
import { Schedule } from "../../../Model/Schedule"
import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { faCalendar, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans, useTranslation } from "react-i18next"

interface PropsState {
  data: Schedule
  index: number
}

const TimeScheduleItem: React.FC<PropsState> = (props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const isEditMode: boolean = useSelector((state: any) => {
    return state.ContractManager.isEditMode
  })

  const editScheduler = (editData: Schedule) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.EDIT_SCHEDULE,
      data: editData,
    })
  }

  const removeScheduler = (id: string) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.REMOVE_SCHEDULE,
      data: id,
    })
  }
  const [isOpen, setIsOpen] = useState(false)

  if (isEditMode) {
    return (
      <div
        className={[
          "grid py-2 px-2 border-b",
          props.index % 2 === 0 ? "bg-gray-100" : "",
        ].join(" ")}
        style={{ gridTemplateColumns: "50px auto 50px" }}>
        <div className="text-center font-bold">{props.index + 1}</div>
        <div className="grid grid-rows-2">
          <div>
            <label className="text-xs text-gray-400">
              <Trans>TimeScheduleController.date</Trans>
            </label>
            <div className="text-xs text-base">
              <button
                className="btn btn-sm gap-2 mt-2"
                onClick={() => {
                  setIsOpen(true)
                }}>
                <FontAwesomeIcon icon={faCalendar} />
                {typeof props.data.date !== "string"
                  ? "toDateString" in props.data.date
                    ? props.data.date.toDateString()
                    : timeStampToDisplayTimeString(props.data.date)
                  : new Date(props.data.date).toDateString()}
              </button>
              <DatePicker
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                defaultValue={
                  typeof props.data.date !== "string"
                    ? (props.data.date as Date)
                    : new Date(props.data.date)
                }
                onChange={(e: Date | null) => {
                  if (e) {
                    editScheduler({
                      ...props.data,
                      date: e,
                    })
                  }
                }}
                headerFormat="DD, MM dd"
              />
            </div>
          </div>
          <div>
            <label className=" text-xs text-gray-400">
              <Trans>TimeScheduleController.title</Trans>
            </label>
            <div className="text-md text-gray-500">
              <input
                type="text"
                placeholder={t("TimeScheduleController.title-example")}
                value={props.data.title}
                onChange={(e) => {
                  editScheduler({
                    ...props.data,
                    title: e.target.value,
                  })
                }}
                className="input input-bordered w-full max-w-xs"
              />
            </div>
          </div>
        </div>
        <div>
          <button
            className="btn btn-ghost btn-square"
            onClick={() => {
              removeScheduler(props.data.id)
            }}>
            <FontAwesomeIcon
              icon={faTrash}
              className="text-red-700 cursor-pointer"
            />
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="grid" style={{ gridTemplateColumns: "50px auto 50px" }}>
      <div>{props.index + 1}</div>
      <div className="grid grid-rows-3">
        <div className="text-lg text-base">
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*// @ts-ignore*/}
          {timeStampToDisplayTimeString(new Date(props.data.date) / 1000)}
        </div>
        <div className="text-md text-gray-500">{props.data.title}</div>
      </div>
      <div>
        {/*<input*/}
        {/*  type="checkbox"*/}
        {/*  checked={props.data.isFinish}*/}
        {/*  className="checkbox"*/}
        {/*  onChange={(e) => {*/}
        {/*    editScheduler({*/}
        {/*      ...props.data,*/}
        {/*      isFinish: e.target.checked,*/}
        {/*    })*/}
        {/*  }}*/}
        {/*/>*/}
      </div>
    </div>
  )
}
export default TimeScheduleItem
