import React, { useEffect, useState } from "react"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CsvDownload from "react-json-to-csv"
import { KOLCombine } from "../../Model/KOLCombine"
import CustomTable from "../../Component/NewTable"
import { t } from "i18next"
import { Column, multiSelectGroup, tableFunctions } from "../../Model/Table"
import {
  compareKOLTableSchema,
  compareKOLTableSchemaDefault,
  onSort,
  ORDER_BY_DIRECTION,
  SORT_OPTION,
} from "../../Utils/TableHelperFunction"
import {
  BUTTON_TYPE,
  MULTISELECT_TYPE,
  TABLE_FUNCTIONS,
} from "../../Enum/TABLE_SYSTEM"
import {
  genreGroupData,
  returnSelectedItems,
  tagTypeSelection,
} from "../../Utils/TypeList"
import { sortInfo } from "../../Utils/CompareKOLUtils"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import classNames from "classnames"
import { Trans } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  categoryPercentageToCSV,
  genrePercentageToCSV,
  headerMaker,
} from "../../Utils/KOLDefaultData"
import { EXCEL_TYPE } from "../../Enum/EXCEL_TYPE"
import { faFilePdf, faUser } from "@fortawesome/free-solid-svg-icons"

interface PropsType {
  metaData: {
    kolData: {
      success: boolean
      userName: string
      data: KOLCombine
    }[]
    dataLoaded: boolean
  }
}

export const KOLCompareTable: React.FC<PropsType> = ({ metaData }) => {
  const [kolCompare, setKOLCompare] = useState<Column[][]>([
    compareKOLTableSchemaDefault(),
  ])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [genreFilterData, setGenreFilterData] = useState<tagTypeSelection[]>([])

  const [isExcelLoading, setIsExcelLoading] = useState<boolean>(false)
  const [downloadableGenreData, setDownloadableGenreData] = useState([])
  const [downloadableCategoryData, setDownloadableCategoryData] = useState([])

  const [sortOptions, setSortOptions] = useState<SORT_OPTION[]>([])

  const genreHeader: string[] = headerMaker(EXCEL_TYPE.KOL_GENRE)
  const cateHeader: string[] = headerMaker(EXCEL_TYPE.KOL_CATEGORY)
  const formatOutputExcel = (
    rawData: {
      success: boolean
      userName: string
      data: KOLCombine
    }[]
  ) => {
    let formattedGenreData: any = []
    let formattedCategoryData: any = []

    if (Array.isArray(rawData)) {
      rawData.map((e) => {
        formattedGenreData.push({
          ...genrePercentageToCSV(
            e.userName,
            e.data.percentages,
            e.data.noOfFollowers,
            e.data.topGenreID
          ),
        })
        formattedCategoryData.push({
          ...categoryPercentageToCSV(
            e.userName,
            e.data.percentages,
            e.data.noOfFollowers,
            e.data.topCateID
          ),
        })
      })
    }

    setDownloadableGenreData(formattedGenreData)
    setDownloadableCategoryData(formattedCategoryData)
  }

  const buildTable = (kols: KOLCombine[]) => {
    setIsLoaded(false)
    let tableSets: Column[][] = []

    const sortEnabled = sortOptions.find((e) => e.direction !== false)
    if (sortEnabled) {
      kols.sort((a, b) => {
        const { sortDataA, sortDataB } = sortInfo(a, b, sortEnabled)
        if (
          (sortDataA < sortDataB &&
            sortEnabled.direction === ORDER_BY_DIRECTION.ASC) ||
          (sortDataA > sortDataB &&
            sortEnabled.direction === ORDER_BY_DIRECTION.DESC)
        ) {
          return -1
        }
        if (
          (sortDataA > sortDataB &&
            sortEnabled.direction === ORDER_BY_DIRECTION.ASC) ||
          (sortDataA < sortDataB &&
            sortEnabled.direction === ORDER_BY_DIRECTION.DESC)
        ) {
          return 1
        }

        // names must be equal
        return 0
      })
    }
    kols.map((kol) => {
      let col: Column[] = [
        ...compareKOLTableSchema(kol, genreFilterData, sortOptions, sortFn),
      ]
      tableSets.push(col)
    })

    setKOLCompare(tableSets)
    setIsLoaded(true)
  }

  useEffect(() => {
    if (metaData.kolData.every((kC) => kC.success)) {
      buildTable(metaData.kolData.map((kC) => kC.data))
      formatOutputExcel(metaData.kolData)
    }
  }, [metaData.kolData, sortOptions])

  const sortFn = (fieldName: string) => {
    const isExistedSortOption = sortOptions.find(
      (e) => e.fieldName === fieldName
    )
    let newSortOption: SORT_OPTION[]
    if (isExistedSortOption) {
      newSortOption = onSort(sortOptions, fieldName)
    } else {
      newSortOption = onSort(
        [
          ...sortOptions,
          {
            direction: false,
            fieldName: fieldName,
          },
        ],
        fieldName
      )
    }
    setSortOptions(newSortOption)
  }

  const formatGroupLabel = (data: multiSelectGroup) => {
    return (
      <div className="flex justify-between m-3">
        <span>{data.label}</span>
        <span className="badge badge-primary p-2 my-auto">
          {data.options.length}
        </span>
      </div>
    )
  }

  const tableFn: tableFunctions[] = [
    {
      title: t("CompareKOL.details-download"),
      data: {
        type: TABLE_FUNCTIONS.CUSTOM,
        title: "",
        component: (
          <div className=" dropdown shrink-0 dropdown-bottom">
            <label
              tabIndex={0}
              className={classNames(" btn btn-md gap-2 py-1", {
                loading: isExcelLoading,
                disable: isExcelLoading,
              })}>
              <span className="hidden md:inline">
                <Trans>{"CompareKOL.details-download"}</Trans>
              </span>
              <FontAwesomeIcon icon={faFilePdf} className="inline md:hidden" />
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content menu mt-1 p-2 shadow-lg bg-base-100 border-gray-200	border-2  rounded-box w-52">
              <li
                id={"dropdown-1"}
                onClick={() => {
                  setIsExcelLoading(true)
                  setTimeout(() => {
                    setIsExcelLoading(false)
                  }, 500)
                }}>
                <a className="font-medium">
                  <CsvDownload
                    filename="look4kol_category.csv"
                    data={downloadableCategoryData}
                    headers={cateHeader}
                    delimiter={","}>
                    <Trans>CompareKOL.download-category</Trans>
                  </CsvDownload>
                </a>
              </li>
              <li
                id={"dropdown-2"}
                onClick={() => {
                  setIsExcelLoading(true)
                  setTimeout(() => {
                    setIsExcelLoading(false)
                  }, 500)
                }}>
                <a className="font-medium">
                  <CsvDownload
                    filename="look4kol_genre.csv"
                    data={downloadableGenreData}
                    headers={genreHeader}
                    delimiter={","}>
                    <Trans>CompareKOL.download-genre</Trans>
                  </CsvDownload>
                </a>
              </li>
            </ul>
          </div>
        ),
      },
    },
    {
      title: t("CompareKOL.filter-genre"),
      data: {
        type: TABLE_FUNCTIONS.MULTISELECT,
        dataType: MULTISELECT_TYPE.GENRE,
        buttonType: BUTTON_TYPE.INFO,
        selectData: genreGroupData(),
        labeledValue: genreFilterData,
        onClick: (data) => {
          if (Array.isArray(genreFilterData) && genreFilterData.length <= 5) {
            let allIDs: string[] = [...genreFilterData.map((e) => e.value)]
            let sortIDs: SORT_OPTION[] = [...sortOptions]
            const existgenreID = genreFilterData.findIndex(
              (e) => e.value === data.value
            )
            const existSortID = sortOptions.findIndex(
              (e) => e.fieldName === data.value
            )
            if (!(existgenreID > -1) && !(existSortID > -1)) {
              if (genreFilterData.length < 5) {
                allIDs.push(data.value)
                sortIDs.push({
                  direction: false,
                  fieldName: data.value,
                })
              }
            } else {
              allIDs.splice(existgenreID, 1)
              sortIDs.splice(existSortID, 1)
            }
            setGenreFilterData(returnSelectedItems(allIDs))
            setSortOptions(sortIDs)
          } else if (data === null) {
            setGenreFilterData([])
            setSortOptions([])
          }
        },
        groupFormat: formatGroupLabel,
        disableButton: genreFilterData.length >= 5,
      },
    },
  ]

  return (
    <CustomTable
      cols={kolCompare}
      tableFn={tableFn}
      isFirstColStatic={false}
      isLoading={!metaData.dataLoaded || !isLoaded}
      rowIsLoading={false}
      notFoundDes={"No Record"}
      isLastRecord={true}
      haveScrollRefresh={true}
    />
  )
}
