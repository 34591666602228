import { MODAL_TYPE } from "../Enum/MODAL_TYPE"
import { Job } from "./Job"
import { IG_USER_CRAWLER_DATA, IG_USER_DATA } from "../Enum/LOGIN_PAGE_TYPE"
import { KOLType } from "./KOL"
import { BankTransaction } from "./BankTransaction"
import { CONTRACT_ROLE } from "../Enum/CONTRACT"
import { kolCrawlerFollowerData } from "../HelpingFunction/KOL/kolDBHelper"

export interface UploadFileModalData {
  metaData: {
    position: number
  }
  returnFn: (
    metaData:
      | {
          position: number
        }
      | undefined,
    data: any
  ) => void
  isOpen: boolean
}

export interface ContactKOLModalData {
  metaData: {
    KOLID: string
    userName: string
    role: string
  }
  returnFn: null
}

export interface CaseStudyModalData {
  metaData: {
    jobData: {
      KOLID: string
      ServicePlanID: string
      imageUrl: string[]
    }
  }
  setContactKOLModal: any
  jobData: Job
}

export interface NewFolderModalData {
  metaData: null
  returnFn: (newFolderName: string) => void
  closeModal: () => void
}

export interface ApplyJobAppealModalData {
  returnFn: () => void
  metaData: {
    job: {
      id: string
      CustomerID: string
      KOLID: string
      cRequirement: string
      kolRequirement: string
      cConfirm: boolean
      kolConfirm: boolean
      state: string
      isSubmitQuotation: boolean
      price: number
    }
  }
}

export interface SubmitJobFulfillmentModalData {
  returnFn: (state: any) => void
  metaData: {
    job: {
      id: string
      CustomerID: string
      KOLID: string
      cRequirement: string
      kolRequirement: string
      cConfirm: boolean
      kolConfirm: boolean
      state: string
      isSubmitQuotation: boolean
    }
  }
}

export interface ViewFollowersDetailModalData {
  metaData: IG_USER_CRAWLER_DATA & IG_USER_DATA
}

export interface AdminCreateChatroomModalData {
  metaData: {
    userID: string
  }
}

export interface KOLPreviewModalData {
  metaData: {
    kolID: string
  }
  closeModal: () => void
}

export interface ViewImageModalData {
  metaData: {
    imageUrl: string[] | string
  }
}

export interface DeleteMessageModalData {
  metaData: {
    inTenMins: number
    chatroomID: string
    messageID: string
  }
  closeModal: () => void
}

export interface AdminViewKOLPercentageModalData {
  metaData: {
    userID: string
    percentages: {
      count: number
      item: string
      percentages: number
    }[]
  }
}

export interface AdminEditRequestModalData {
  metaData: {
    request: {
      type: string
    }
  }
  closeModal: () => void
  returnFn: () => void
}

export interface BankWithdrawModalData {
  returnFn: () => void
  closeModal: () => void
}

export interface WalletHistoryModalData {
  closeModal: () => void
  metaData: BankTransaction
}

export interface ContractDisplayModalData {
  metaData: {
    userID: string
  }
  closeModal: () => void
}

export interface ContractAcceptModalData {
  closeModal: () => void
  metaData: {
    role: CONTRACT_ROLE
  }
  returnFn: (role: CONTRACT_ROLE) => void
}

export interface SignUpCoverImageModalData {
  metaData: {
    data: kolCrawlerFollowerData
    position: number
    nextPage?: string
  }
  returnFn: (metaData: number, postUrl: string) => void

  closeModal(): void
}

export interface CreateNewContractModalData {
  metaData: {
    kolProfile: KOLType
    agencyID: string
  }
}

export interface CreateCaseStudyModalData {
  metaData: {
    caseId: string
  }

  closeModal(): void
}

export interface ViewCaseStudyModalData {
  metaData: {
    KOLID: string
  }
}

export interface LoginModalData {
  metaData: {
    kolUsername: string
    jobID?: string
  }

  closeModal(): void
}

export interface LoginRoleModalData {
  closeModal(): void
}

class Modal {
  name: MODAL_TYPE | string

  isOpen: boolean

  constructor(name: MODAL_TYPE | string, isOpen: boolean) {
    this.name = name
    this.isOpen = isOpen
  }
}

export default Modal
