const online_payment_error_codes_en = {
  account_already_exists:
    "The email address provided for the creation of a deferred account already has an account associated with it. Use the OAuth flow to connect the existing account to your platform.",
  account_country_invalid_address:
    "The country of the business address provided does not match the country of the account. Businesses must be located in the same country as the account.",
  account_invalid:
    "The account ID provided as a value for the Stripe-Account header is invalid. Check that your requests are specifying a valid account ID.",
  account_number_invalid:
    "The bank account number provided is invalid (e.g., missing digits). Bank account information varies from country to country. We recommend creating validations in your entry forms based on the bank account formats we provide.",
  alipay_upgrade_required:
    "This method for creating Alipay payments is not supported anymore. Please upgrade your integration to use Sources instead.",
  amount_too_large:
    "The specified amount is greater than the maximum amount allowed. Use a lower amount and try again.",
  amount_too_small:
    "The specified amount is less than the minimum amount allowed. Use a higher amount and try again.",
  api_key_expired:
    "The API key provided has expired. Obtain your current API keys from the Dashboard and update your integration to use them.",
  balance_insufficient:
    "The transfer or payout could not be completed because the associated account does not have a sufficient balance available. Create a new transfer or payout using an amount less than or equal to the account’s available balance.",
  bank_account_exists:
    "The bank account provided already exists on the specified Customer object. If the bank account should also be attached to a different customer, include the correct customer ID when making the request again.",
  bank_account_unusable:
    "The bank account provided cannot be used for payouts. A different bank account must be used.",
  bank_account_unverified:
    "Your Connect platform is attempting to share an unverified bank account with a connected account.",
  bitcoin_upgrade_required:
    "This method for creating Bitcoin payments is not supported anymore. Please upgrade your integration to use Sources instead.",
  card_declined:
    "The card has been declined. When a card is declined, the error returned also includes the decline_code attribute with the reason why the card was declined. Refer to our decline codes documentation to learn more.",
  charge_already_captured:
    "The charge you’re attempting to capture has already been captured. Update the request with an uncaptured charge ID.",
  charge_already_refunded:
    "The charge you’re attempting to refund has already been refunded. Update the request to use the ID of a charge that has not been refunded.",
  charge_disputed:
    "The charge you’re attempting to refund has been charged back. Check the disputes documentation to learn how to respond to the dispute.",
  charge_exceeds_source_limit:
    "This charge would cause you to exceed your rolling-window processing limit for this source type. Please retry the charge later, or contact us to request a higher processing limit.",
  charge_expired_for_capture:
    "The charge cannot be captured as the authorization has expired. Auth and capture charges must be captured within seven days.",
  country_unsupported:
    "Your platform attempted to create a custom account in a country that is not yet supported. Make sure that users can only sign up in countries supported by custom accounts.",
  coupon_expired:
    "The coupon provided for a subscription or order has expired. Either create a new coupon, or use an existing one that is valid.",
  customer_max_subscriptions:
    "The maximum number of subscriptions for a customer has been reached. Contact us if you are receiving this error.",
  email_invalid:
    "The email address is invalid (e.g., not properly formatted). Check that the email address is properly formatted and only includes allowed characters.",
  expired_card:
    "The card has expired. Check the expiration date or use a different card.",
  idempotency_key_in_use:
    "The idempotency key provided is currently being used in another request. This occurs if your integration is making duplicate requests simultaneously.",
  incorrect_address:
    "The card’s address is incorrect. Check the card’s address or use a different card.",
  incorrect_cvc:
    "The card’s security code is incorrect. Check the card’s security code or use a different card.",
  incorrect_number:
    "The card number is incorrect. Check the card’s number or use a different card.",
  incorrect_zip:
    "The card’s postal code is incorrect. Check the card’s postal code or use a different card.",
  instant_payouts_unsupported:
    "The debit card provided as an external account does not support instant payouts. Provide another debit card or use a bank account instead.",
  invalid_card_type:
    "The card provided as an external account is not a debit card. Provide a debit card or use a bank account instead.",
  invalid_charge_amount:
    "The specified amount is invalid. The charge amount must be a positive integer in the smallest currency unit, and not exceed the minimum or maximum amount.",
  invalid_cvc:
    "The card’s security code is invalid. Check the card’s security code or use a different card.",
  invalid_expiry_month:
    "The card’s expiration month is incorrect. Check the expiration date or use a different card.",
  invalid_expiry_year:
    "The card’s expiration year is incorrect. Check the expiration date or use a different card.",
  invalid_number:
    "The card number is invalid. Check the card details or use a different card.",
  invalid_source_usage:
    "The source cannot be used because it is not in the correct state (e.g., a charge request is trying to use a source with a pending, failed, or consumed source). Check the status of the source you are attempting to use.",
  invoice_no_customer_line_items:
    "An invoice cannot be generated for the specified customer as there are no pending invoice items. Check that the correct customer is being specified or create any necessary invoice items first.",
  invoice_no_subscription_line_items:
    "An invoice cannot be generated for the specified subscription as there are no pending invoice items. Check that the correct subscription is being specified or create any necessary invoice items first.",
  invoice_not_editable:
    "The specified invoice can no longer be edited. Instead, consider creating additional invoice items that will be applied to the next invoice. You can either manually generate the next invoice or wait for it to be automatically generated at the end of the billing cycle.",
  invoice_upcoming_none:
    "There is no upcoming invoice on the specified customer to preview. Only customers with active subscriptions or pending invoice items have invoices that can be previewed.",
  livemode_mismatch:
    "Test and live mode API keys, requests, and objects are only available within the mode they are in.",
  missing:
    "Both a customer and source ID have been provided, but the source has not been saved to the customer. To create a charge for a customer with a specified source, you must first save the card details.",
  not_allowed_on_standard_account:
    "Transfers and payouts on behalf of a Standard connected account are not allowed.",
  order_creation_failed:
    "The order could not be created. Check the order details and then try again.",
  order_required_settings:
    "The order could not be processed as it is missing required information. Check the information provided and try again.",
  order_status_invalid:
    "The order cannot be updated because the status provided is either invalid or does not follow the order lifecycle (e.g., an order cannot transition from created to fulfilled without first transitioning to paid).",
  order_upstream_timeout: "The request timed out. Try again later.",
  out_of_inventory:
    "The SKU is out of stock. If more stock is available, update the SKU’s inventory quantity and try again.",
  parameter_invalid_empty:
    "One or more required values were not provided. Make sure requests include all required parameters.",
  parameter_invalid_integer:
    "One or more of the parameters requires an integer, but the values provided were a different type. Make sure that only supported values are provided for each attribute. Refer to our API documentation to look up the type of data each attribute supports.",
  parameter_invalid_string_blank:
    "One or more values provided only included whitespace. Check the values in your request and update any that contain only whitespace.",
  parameter_invalid_string_empty:
    "One or more required string values is empty. Make sure that string values contain at least one character.",
  parameter_missing:
    "One or more required values are missing. Check our API documentation to see which values are required to create or modify the specified resource.",
  parameter_unknown:
    "The request contains one or more unexpected parameters. Remove these and try again.",
  parameters_exclusive:
    "Two or more mutually exclusive parameters were provided. Check our API documentation or the returned error message to see which values are permitted when creating or modifying the specified resource.",
  payment_intent_authentication_failure:
    "The provided source has failed authentication. Provide source_data or a new source to attempt to fulfill this PaymentIntent again.",
  payment_intent_incompatible_payment_method:
    "The PaymentIntent expected a payment method with different properties than what was provided.",
  payment_intent_invalid_parameter:
    "One or more provided parameters was not allowed for the given operation on the PaymentIntent. Check our API reference or the returned error message to see which values were not correct for that PaymentIntent.",
  payment_intent_payment_attempt_failed:
    "The latest payment attempt for the PaymentIntent has failed. Check the last_payment_error property on the PaymentIntent for more details, and provide source_data or a new source to attempt to fulfill this PaymentIntent again.",
  payment_intent_unexpected_state:
    "The PaymentIntent’s state was incompatible with the operation you were trying to perform.",
  payment_method_unactivated:
    "The charge cannot be created as the payment method used has not been activated. Activate the payment method in the Dashboard, then try again.",
  payment_method_unexpected_state:
    "The provided payment method’s state was incompatible with the operation you were trying to perform. Confirm that the payment method is in an allowed state for the given operation before attempting to perform it.",
  payouts_not_allowed:
    "Payouts have been disabled on the connected account. Check the connected account’s status to see if any additional information needs to be provided, or if payouts have been disabled for another reason.",
  platform_api_key_expired:
    "The API key provided by your Connect platform has expired. This occurs if your platform has either generated a new key or the connected account has been disconnected from the platform. Obtain your current API keys from the Dashboard and update your integration, or reach out to the user and reconnect the account.",
  postal_code_invalid: "The postal code provided was incorrect.",
  processing_error:
    "An error occurred while processing the card. Check the card details are correct or use a different card.",
  product_inactive:
    "The product this SKU belongs to is no longer available for purchase.",
  rate_limit:
    "Too many requests hit the API too quickly. We recommend an exponential backoff of your requests.",
  resource_already_exists:
    "A resource with a user-specified ID (e.g., plan or coupon) already exists. Use a different, unique value for id and try again.",
  resource_missing:
    "The ID provided is not valid. Either the resource does not exist, or an ID for a different resource has been provided.",
  routing_number_invalid: "The bank routing number provided is invalid.",
  secret_key_required:
    "The API key provided is a publishable key, but a secret key is required. Obtain your current API keys from the Dashboard and update your integration to use them.",
  sepa_unsupported_account: "Your account does not support SEPA payments.",
  shipping_calculation_failed:
    "Shipping calculation failed as the information provided was either incorrect or could not be verified.",
  sku_inactive:
    "The SKU is inactive and no longer available for purchase. Use a different SKU, or make the current SKU active again.",
  state_unsupported:
    "Occurs when providing the legal_entity information for a U.S. custom account, if the provided state is not supported. (This is mostly associated states and territories.)",
  tax_id_invalid:
    "The tax ID number provided is invalid (e.g., missing digits). Tax ID information varies from country to country, but must be at least nine digits.",
  taxes_calculation_failed: "Tax calculation for the order failed.",
  testmode_charges_only:
    "Your account has not been activated and can only make test charges. Activate your account in the Dashboard to begin processing live charges.",
  tls_version_unsupported:
    "Your integration is using an older version of TLS that is unsupported. You must be using TLS 1.2 or above.",
  token_already_used:
    "The token provided has already been used. You must create a new token before you can retry this request.",
  token_in_use:
    "The token provided is currently being used in another request. This occurs if your integration is making duplicate requests simultaneously.",
  transfers_not_allowed:
    "The requested transfer cannot be created. Contact us if you are receiving this error.",
  upstream_order_creation_failed:
    "The order could not be created. Check the order details and then try again.",
  url_invalid: "The URL provided is invalid.",
  incomplete_number: "The card number is incomplete",
  incomplete_cvc: "The card’s security code is incomplete",
  incomplete_expiry: "The card’s expiration date is incomplete",
  invalid_expiry_year_past: "The card’s expiration year is in the past",
}

const online_payment_error_codes_hk = {
  account_already_exists:
    "提供的用於創建遞延帳戶的電子郵件地址已經有一個與之關聯的帳戶。 使用OAuth流將現有帳戶連接到您的平台。",
  account_country_invalid_address:
    "提供的公司地址所在的國家/地區與帳戶的國家/地區不匹配。 公司必須與帳戶位於同一國家/地區。",
  account_invalid:
    "作為Stripe-Account標頭的值提供的帳戶ID無效。 檢查您的請求是否指定了有效的帳戶ID。",
  account_number_invalid:
    "提供的銀行帳號無效（例如，缺少數字）。 銀行帳戶信息因國家/地區而異。 我們建議您根據我們提供的銀行帳戶格式在您的輸入表單中創建驗證。",
  alipay_upgrade_required:
    "不再支持這種創建支付寶付款的方法。 請升級您的集成以改用Sources。",
  amount_too_large: "指定的數量大於允許的最大數量。 使用少量，然後重試。",
  amount_too_small: "指定的數量小於允許的最小數量。 使用更高的用量，然後重試。",
  api_key_expired:
    "提供的API密鑰已過期。 從儀表板獲取當前的API密鑰，並更新集成以使用它們。",
  balance_insufficient:
    "由於關聯帳戶的餘額不足，因此無法完成轉帳或付款。 使用小於或等於帳戶可用餘額的金額創建新的轉帳或付款。",
  bank_account_exists:
    "提供的銀行帳戶已經存在於指定的“客戶”對像上。 如果銀行帳戶也應附加到其他客戶，則在再次提出請求時，請包括正確的客戶ID。",
  bank_account_unusable: "提供的銀行帳戶不能用於付款。 必須使用其他銀行帳戶。",
  bank_account_unverified:
    "您的Connect平台正在嘗試與連接的帳戶未經驗證的銀行帳戶。",
  bitcoin_upgrade_required:
    "不再支持這種創建比特幣付款的方法。請升級您的集成以使用Sources。",
  card_declined:
    "該卡已被拒絕。 拒絕卡片時，返回的錯誤還包括帶有拒絕卡片原因的拒絕代碼屬性。 請參閱我們的拒絕代碼文檔以了解更多信息。",
  charge_already_captured:
    "您嘗試收取的費用已被收取。 使用未捕獲的費用ID更新請求。",
  charge_already_refunded:
    "您嘗試退款的費用已經退款。 更新請求以使用尚未退款的費用ID。",
  charge_disputed:
    "您嘗試退款的費用已被退款。 查看爭議文檔以了解如何應對爭議。",
  charge_exceeds_source_limit:
    "此費用將導致您超出此來源類型的滾動窗口處理限制。請稍後重試該費用，或與我們聯繫以請求更高的處理限制。",
  charge_expired_for_capture:
    "由於授權已過期，因此無法收取費用。 驗證和捕獲費用必須在7天內捕獲。",
  country_unsupported:
    "您的平台嘗試在尚不支持的國家/地區創建自定義帳戶。 確保用戶只能在自定義帳戶支持的國家/地區註冊。",
  coupon_expired:
    "為訂閱或訂單提供的優惠券已過期。 可以創建一個新的優惠券，或使用現有的一個是有效的。",
  customer_max_subscriptions:
    "已達到客戶的最大訂閱數。 如果收到此錯誤，請與我們聯繫。",
  email_invalid:
    "電子郵件地址無效（例如，格式不正確）。 檢查電子郵件地址的格式正確，並且僅包含允許的字符。",
  expired_card: "卡已過期。 檢查到期日期或使用其他卡。",
  idempotency_key_in_use:
    "當前提供的冪等性密鑰正在另一個請求中使用。 如果您的集成同時發出重複的請求，則會發生這種情況。",
  incorrect_address: "卡的地址不正確。 檢查卡的地址或使用其他卡。",
  incorrect_cvc: "卡的安全碼不正確。 檢查卡的安全碼或使用其他卡。",
  incorrect_number: "卡號不正確。 檢查卡號或使用其他卡。",
  incorrect_zip: "卡的郵政編碼不正確。 檢查卡的郵政編碼或使用其他卡。",
  instant_payouts_unsupported:
    "作為外部帳戶提供的借記卡不支持即時付款。 提供另一張借記卡或使用銀行帳戶代替。",
  invalid_card_type:
    "作為外部帳戶提供的卡不是藉記卡。 提供借記卡或使用銀行帳戶代替。",
  invalid_charge_amount:
    "指定的數量無效。 費用金額必須是最小貨幣單位的正整數，並且不得超過最小或最大金額。",
  invalid_cvc: "卡的安全碼無效。 檢查卡的安全碼或使用其他卡。",
  invalid_expiry_month: "卡的有效期限不正確。 檢查到期日期或使用其他卡。",
  invalid_expiry_year: "卡的有效期不正確。 檢查到期日期或使用其他卡。",
  invalid_number: "卡號無效。 檢查卡的詳細信息或使用其他卡。",
  invalid_source_usage:
    "無法使用該來源，因為它的狀態不正確（例如，收費請求正在嘗試將來源與待處理，失敗或消耗的來源一起使用）。 檢查您嘗試使用的源的狀態。",
  invoice_no_customer_line_items:
    "由於沒有待處理的發票項目，因此無法為指定的客戶生成發票。 檢查是否指定了正確的客戶，或者首先創建任何必要的發票項目。",
  invoice_no_subscription_line_items:
    "由於沒有待處理的發票項目，因此無法為指定的訂閱生成發票。 檢查是否指定了正確的訂閱，或者首先創建任何必要的發票項目。",
  invoice_not_editable:
    "指定的發票無法再編輯。 相反，請考慮創建將應用於下一個發票的其他發票項目。 您可以手動生成下一張發票，也可以等待在結算週期結束時自動生成下一張發票。",
  invoice_upcoming_none:
    "指定的客戶沒有即將預覽的發票。 僅具有有效訂閱或待處理髮票項目的客戶具有可以預覽的發票。",
  livemode_mismatch:
    "測試和實時模式API密鑰，請求和對象僅在其所處的模式下可用。",
  missing:
    "提供了客戶ID和源ID，但尚未將源保存到客戶。 要為具有指定來源的客戶收取費用，必須首先保存卡的詳細信息。",
  not_allowed_on_standard_account: "不允許代表標準關聯帳戶進行轉帳和付款。",
  order_creation_failed: "無法創建訂單。 檢查訂單詳細信息，然後重試。",
  order_required_settings:
    "訂單缺少必填信息，因此無法處理。 檢查提供的信息，然後重試。",
  order_status_invalid:
    "由於提供的狀態無效或未遵循訂單生命週期，因此無法更新訂單（例如，在未先轉換為已付款的情況下，訂單無法從創建的狀態轉換為已完成的狀態）。",
  order_upstream_timeout: "請求超時。 稍後再試。",
  out_of_inventory: "SKU缺貨。 如果有更多庫存，請更新SKU的庫存數量，然後重試。",
  parameter_invalid_empty:
    "未提供一個或多個所需值。 確保請求包括所有必需的參數。",
  parameter_invalid_integer:
    "一個或多個參數需要一個整數，但是提供的值是另一種類型。 確保僅為每個屬性提供受支持的值。 請參閱我們的API文檔，以查找每個屬性支持的數據類型。",
  parameter_invalid_string_blank:
    "提供的一個或多個值僅包含空格。 檢查您的請求中的值，並更新任何僅包含空格的值。",
  parameter_invalid_string_empty:
    "一個或多個必需的字符串值是空的。 確保字符串值至少包含一個字符。",
  parameter_missing:
    "缺少一個或多個必需值。 請查看我們的API文檔，以了解創建或修改指定資源所需的值。",
  parameter_unknown: "該請求包含一個或多個意外參數。 刪除這些，然後重試。",
  parameters_exclusive:
    "提供了兩個或更多互斥參數。 查看我們的API文檔或返回的錯誤消息，以查看在創建或修改指定資源時允許使用哪些值。",
  payment_intent_authentication_failure:
    "提供的源認證失敗。 提供source_data或新的來源以嘗試再次滿足此PaymentIntent。",
  payment_intent_incompatible_payment_method:
    "PaymentIntent期望使用與提供的屬性不同的付款方式。",
  payment_intent_invalid_parameter:
    "PaymentIntent上的給定操作不允許使用一個或多個提供的參數。 查看我們的API參考或返回的錯誤消息，以查看哪個值對於該PaymentIntent不正確。",
  payment_intent_payment_attempt_failed:
    "PaymentIntent的最新付款嘗試失敗。 檢查PaymentIntent上的last_payment_error屬性以獲取更多詳細信息，並提供source_data或新的來源以嘗試再次滿足此PaymentIntent。",
  payment_intent_unexpected_state:
    "PaymentIntent的狀態與您要執行的操作不兼容。",
  payment_method_unactivated:
    "由於尚未激活所使用的付款方式，因此無法創建費用。 在控制台中激活付款方式，然後重試。",
  payment_method_unexpected_state:
    "提供的付款方式的狀態與您要執行的操作不兼容。 在嘗試執行給定操作之前，請確認該支付方式處於允許狀態。",
  payouts_not_allowed:
    "所關聯帳戶的付款已被禁用。 檢查關聯帳戶的狀態，以查看是否需要提供任何其他信息，或者是否由於其他原因而禁用了付款。",
  platform_api_key_expired:
    "您的Connect平台提供的API密鑰已過期。 如果您的平台已生成新密鑰或所連接的帳戶已從平台斷開連接，則會發生這種情況。 從儀表板獲取當前的API密鑰並更新集成，或者與用戶聯繫並重新連接該帳戶。",
  postal_code_invalid: "提供的郵政編碼不正確。",
  processing_error: "處理卡時發生錯誤。 檢查卡的詳細信息是否正確或使用其他卡。",
  product_inactive: "該SKU所屬的產品不再可用。",
  rate_limit: "太多請求無法很快到達API。 我們建議您以指數方式退回您的請求。",
  resource_already_exists:
    "具有用戶指定ID（例如計劃或優惠券）的資源已經存在。 為ID使用不同的唯一值，然後重試。",
  resource_missing: "提供的ID無效。 資源不存在，或者提供了其他資源的ID。",
  routing_number_invalid: "提供的銀行轉帳號碼無效。",
  secret_key_required:
    "提供的API密鑰是可發布的密鑰，但是需要一個秘密密鑰。 從儀表板獲取當前的API密鑰，並更新集成以使用它們。",
  sepa_unsupported_account: "您的帳戶不支持SEPA付款。",
  shipping_calculation_failed: "運送計算失敗，因為提供的信息不正確或無法驗證。",
  sku_inactive:
    "該SKU處於無效狀態，無法再購買。 使用其他SKU，或再次激活當前SKU。",
  state_unsupported:
    "如果不支持提供的狀態，則在為美國自定義帳戶提供Legal_entity信息時發生。 （這主要是相關的州和領地。）",
  tax_id_invalid:
    "提供的稅號ID無效（例如，缺少數字）。 稅號信息因國家/地區而異，但必須至少為9位數字。",
  taxes_calculation_failed: "訂單的稅金計算失敗。",
  testmode_charges_only:
    "您的帳戶尚未激活，只能收取測試費用。 在控制台中激活您的帳戶以開始處理實時費用。",
  tls_version_unsupported:
    "您的集成使用的是不支持的舊版TLS。 您必須使用TLS 1.2或更高版本。",
  token_already_used:
    "提供的令牌已被使用。 您必須先創建一個新令牌，然後才能重試此請求。",
  token_in_use:
    "提供的令牌當前正在另一個請求中使用。 如果您的集成同時發出重複的請求，則會發生這種情況。",
  transfers_not_allowed: "無法創建請求的傳輸。 如果收到此錯誤，請與我們聯繫。",
  upstream_order_creation_failed: "無法創建訂單。 檢查訂單詳細信息，然後重試。",
  url_invalid: "提供的網址無效。",
  incomplete_number: "卡號不完整。",
  incomplete_cvc: "卡的安全碼不完整。",
  incomplete_expiry: "卡的有效期不完整。",
  invalid_expiry_year_past: "卡的有效期限是過去的年份。",
}

export function returnErrorMessageFromCode(lang, code) {
  if (lang === "en") {
    return typeof online_payment_error_codes_en[code] !== "undefined" &&
      online_payment_error_codes_en[code] !== null
      ? online_payment_error_codes_en[code]
      : "unexpected error"
  } else if (lang === "hk") {
    return typeof online_payment_error_codes_hk[code] !== "undefined" &&
      online_payment_error_codes_hk[code] !== null
      ? online_payment_error_codes_hk[code]
      : "意外的錯誤"
  }
}
