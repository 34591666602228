import React, { useState } from "react"
import { KOLCombineWithAvatar } from "../Model/KOLCombine"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleMinus,
  faCirclePlus,
  faHeart,
  faUsers,
} from "@fortawesome/free-solid-svg-icons"
import { followersCount } from "../Utils/Converter"
import { motion } from "framer-motion"
import { Trans } from "react-i18next"
import classNames from "classnames"
import { Carousel } from "react-responsive-carousel"
import { IMGUR_THUMBNAIL_TYPE, returnThumbnailUrl } from "../Utils/utiltyHelper"

export enum ASSISTANT_RECOMMEND_CARD_TYPE {
  NORMAL = "NORMAL",
  GOLD = "GOLD",
}

interface Props {
  kol: KOLCombineWithAvatar
  type: ASSISTANT_RECOMMEND_CARD_TYPE
  reason?: string
  score?: string
  badgeTitle?: string
  isSelected?: boolean
  canSelectKOL: boolean
  onPreviewKOL: (kolID: string) => void
  onSelectKOL?: (kol: KOLCombineWithAvatar) => void
  havePriceRange: boolean
}

const AssistantRecommendCard = (props: Props) => {
  const [showRecom, setShowRecom] = useState<string[]>([])

  const handleKOLAReason = (id: string) => {
    let newList = [...showRecom]

    if (showRecom.length && showRecom.includes(id)) {
      newList = showRecom.filter((e) => e !== id)
      setShowRecom(newList)
    } else {
      newList.push(id)
      setShowRecom(newList)
    }
  }

  const returnKOLPricing = (followersNum: number): string => {
    let price = "$"
    if (followersNum <= 10000) {
      price = "$"
    } else if (followersNum > 10000 && followersNum <= 50000) {
      price = "$$"
    } else {
      price = "$$$"
    }
    return price
  }

  return (
    <div
      className={classNames(
        "md:h-96 h-96  w-full border shadow rounded-xl relative",
        {
          "ring-4 ring-amber-400":
            props.type === ASSISTANT_RECOMMEND_CARD_TYPE.GOLD,
        }
      )}>
      <section className="absolute top-2 z-20 grid grid-cols-3  w-full  px-2">
        <div className="flex flex-row   items-center col-span-2">
          {props.type === ASSISTANT_RECOMMEND_CARD_TYPE.GOLD && (
            <div className="bg-amber-200  rounded-full w-auto px-2 text-sm text-amber-500 text-center mr-2">
              <Trans>AssistantRecom.recm-badge-title</Trans>
            </div>
          )}
          {props.badgeTitle && (
            <div className="bg-sky-100 rounded-full w-auto px-2 text-sm text-sky-600 text-center mr-2">
              <Trans>{props.badgeTitle}</Trans>
            </div>
          )}
          {props.havePriceRange && (
            <div className="px-1 text-center bg-gray-200 rounded-full w-10 text-gray-700 font-bold text-sm ">
              {returnKOLPricing(props.kol.noOfFollowers)}
            </div>
          )}
        </div>
      </section>

      {props.kol.coverImages && (
        <>
          <Carousel
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            className="h-56 md:h-48 w-full rounded-t-xl"
            showStatus={false}
            showThumbs={false}>
            {props.kol.coverImages.map((e, index) => {
              if (e !== "") {
                return (
                  <div
                    key={"lk" + props.kol.id + index}
                    className="w-full rounded-xl">
                    <img
                      alt="find kol"
                      className="rounded-t-xl object-cover lazyload md:h-48 h-56"
                      src={returnThumbnailUrl(e as string)}
                    />
                  </div>
                )
              }
              return <div></div>
            })}
          </Carousel>
        </>
      )}

      {props.canSelectKOL && (
        <div className="absolute md:top-44 top-52 right-4">
          <motion.button
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => props.onSelectKOL && props.onSelectKOL(props.kol)}
            className="w-8 h-8 bg-white rounded-full flex items-center shadow-lg">
            <FontAwesomeIcon
              className={classNames("text-4xl", {
                "text-green-500": !props.isSelected,
                "text-red-400": props.isSelected,
              })}
              icon={props.isSelected ? faCircleMinus : faCirclePlus}
            />
          </motion.button>
        </div>
      )}

      <section className="px-2">
        <div className="flex flex-row items-center gap-4 mt-4">
          <img
            className="w-8 h-8 rounded-full object-cover"
            src={returnThumbnailUrl(
              props.kol.avatarUrl,
              IMGUR_THUMBNAIL_TYPE.s
            )}
          />
          <div>
            <p
              onClick={() => props.onPreviewKOL(props.kol.id)}
              className={classNames(
                "font-semibold text-base cursor-pointer",
                props.reason ? "text-amber-500" : "text-gray-700"
              )}>
              {props.kol.userName}
            </p>
            <div className="flex flex-row items-center gap-2 text-xs text-gray-500 font-medium">
              <p>
                <FontAwesomeIcon icon={faUsers} />{" "}
                {followersCount(props.kol.noOfFollowers)}
              </p>
              <p>
                <FontAwesomeIcon icon={faHeart} /> {props.kol.basicScore}%
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-2 h-24  overflow-y-auto">
        {props.reason && showRecom.includes(props.kol.id) && (
          <div className="flex flex-row px-2.5 items-center">
            <p className="text-xs font-medium">
              <Trans>Contract.score</Trans>:{" "}
            </p>
            <p className="text-amber-400 text-sm ml-2 font-semibold">
              {props.score}%
            </p>
          </div>
        )}
        <p className="px-2.5 text-xs text-gray-700 mt-1 text-left">
          {showRecom.includes(props.kol.id)
            ? props.reason
            : props.kol.description}
        </p>
      </section>
      {props.reason && (
        <section
          onClick={() => handleKOLAReason(props.kol.id)}
          className="absolute bottom-1 px-2 w-full">
          <button className="w-full btn btn-sm bg-amber-400 hover:bg-amber-500 text-amber-800 hover:text-white border-0">
            {showRecom.includes(props.kol.id) ? (
              <Trans>RecomTable.see-profile</Trans>
            ) : (
              <Trans>RecomTable.see-why</Trans>
            )}
          </button>
        </section>
      )}
    </div>
  )
}

export default AssistantRecommendCard
