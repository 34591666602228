import React from "react"
import { useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons"

import { getUserLang } from "../../Utils/utiltyHelper"
import { Trans } from "react-i18next"
import classNames from "classnames"
import { isAuth, logout } from "../../Utils/FirebaseUtils"
import { HelpDocHeader } from "./HelpDocHeader/HelpDocHeader"
import {
  COMPARE_KOL,
  PROJECT_HOW_TO,
  RECOMMENDATION_HOW_TO,
  REGISTER_HOW_TO_REGISTER_IG,
  REGISTER_KOL_FACEBOOK,
  REGISTER_KOL_HOW_TO_BLUE_TICK,
  REGISTER_KOL_HOW_TO_FACEBOOK,
  SEARCH_KOL,
  SIGNUP_HEADER,
} from "../../Utils/HelpButtonHelperFunction"

export const SignupHeader: React.FC = ({}) => {
  let history = useHistory()
  const location = useLocation()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  return (
    <div
      className="h-18 my-auto w-full py-2 px-4 grid gap-4"
      style={{
        height: "60px",
        gridTemplateColumns: " auto auto 40px ",
      }}>
      <div className="ml-8 my-auto overflow-ellipsis w-full text-lg font-medium uppercase">
        <Trans>SignUp.title</Trans>
      </div>
      <div className="flex justify-end">
        <HelpDocHeader helpDocs={SIGNUP_HEADER} />
      </div>
      <div>
        {isAuth(auth) && (
          <button
            className={classNames("btn btn-circle btn-ghost", {})}
            onClick={() => {
              logout()

              let params = new URLSearchParams("")
              if (location.search.includes("viewAs")) {
                params.append("returnToCustomer", "true")
              }
              history.push({
                pathname: "/" + getUserLang() + "/login",
                search: params.toString(),
              })
            }}>
            <FontAwesomeIcon icon={faRightFromBracket} />
          </button>
        )}
      </div>
    </div>
  )
}
