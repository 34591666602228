import {
  RecomData,
  RecommendationDefault,
  RecomProgress,
  RecomStep,
} from "../../Model/RecomTools"
import { Reducer } from "react"
import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"
import { KOLSearchDefault, KOLSearchType } from "./ToolsManager"
import { KOLCombineWithAvatar } from "../../Model/KOLCombine"
import { DEFAULT_MAX_CONDITION } from "../../Enum/KOL_SEARCH_TYPE"

interface actionTypes {
  type: string
  data: any
}

interface RecommendationInitStateType {
  currentRecommendation: RecomData
  recommendationStep: RecomStep
  preferenceChange: boolean
  selectedKOL: KOLCombineWithAvatar[]
  suggestedKOL: KOLCombineWithAvatar[]
  loadedKOLs: boolean
  recommendationProgress: RecomProgress
  projectID: string
  searchKOLCondition: KOLSearchType
}

const initialState: RecommendationInitStateType = {
  currentRecommendation: RecommendationDefault,
  recommendationStep: RecomStep.FEATURE,
  preferenceChange: false,
  selectedKOL: [],
  suggestedKOL: [],
  loadedKOLs: false,
  recommendationProgress: RecomProgress.START,
  projectID: "",
  searchKOLCondition: {
    ...KOLSearchDefault,
    followerMax: DEFAULT_MAX_CONDITION.FOLLOWER,
    basicScoreMax: DEFAULT_MAX_CONDITION.BASIC_SCORE,
    isLook4kolReg: true,
  },
}

const onSelectKOL = (
  selectedKOL: KOLCombineWithAvatar[],
  kol: KOLCombineWithAvatar
) => {
  //if found
  if (selectedKOL.find((e) => e.id === kol.id)) {
    return [...selectedKOL].filter((e) => e.id !== kol.id)
  }
  //else add into the list
  return [...selectedKOL].concat(kol)
}
const onSelectKOLArray = (
  selectedKOL: KOLCombineWithAvatar[],
  data: {
    kols: KOLCombineWithAvatar[]
    isDefaultSetup: boolean
  }
) => {
  let tmpSelections = [...selectedKOL]
  data.kols.forEach((kol) => {
    //if found
    if (selectedKOL.find((e) => e.id === kol.id)) {
      // ignore reverse selection if this is under default selection
      if (!data.isDefaultSetup) {
        tmpSelections.filter((e) => e.id !== kol.id)
      }
    } else {
      //else add into the list
      tmpSelections.push(kol)
    }
  })
  return tmpSelections
}

const setRecommendationReducer: Reducer<
  RecommendationInitStateType,
  actionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case SUPPORTED_REDUX_FUNCTIONS.SET_CURRENT_RECOMMENDATION:
      return {
        ...state,
        currentRecommendation: action.data,
        preferenceChange: !state.preferenceChange,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_RECOMMENDATION_STEP:
      return {
        ...state,
        recommendationStep: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.ON_CLICK_KOL:
      return {
        ...state,
        selectedKOL: onSelectKOL(
          state.selectedKOL,
          action.data as KOLCombineWithAvatar
        ),
        loadedKOLs: !state.loadedKOLs,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SELECT_KOL_ARRAY:
      return {
        ...state,
        selectedKOL: onSelectKOLArray(
          state.selectedKOL,
          action.data as {
            kols: KOLCombineWithAvatar[]
            isDefaultSetup: boolean
          }
        ),
        loadedKOLs: !state.loadedKOLs,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_RECOMMENDATION_PROGRESS:
      return {
        ...state,
        recommendationProgress: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_PROJECT_ID:
      return {
        ...state,
        projectID: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_SUGGESTED_KOL:
      return {
        ...state,
        suggestedKOL: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_RECOM_SEARCH_KOL_QUERY:
      // if any condition changed
      if (action.data.key !== "page") {
        return {
          ...state,
          searchKOLCondition: {
            ...state.searchKOLCondition,
            page: 0,
            // recommendation should find the kol who register in look4kol
            isLook4kolReg: true,
            [action.data.key]: action.data.value,
          },
        }
      }
      // only "page" change
      return {
        ...state,
        searchKOLCondition: {
          ...state.searchKOLCondition,
          // recommendation should find the kol who register in look4kol
          isLook4kolReg: true,
          [action.data.key]: action.data.value,
        },
      }
    default:
      return state
  }
}
export default setRecommendationReducer
