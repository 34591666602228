import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import facebookIcon from "../../Assets/facebookLogo.png"
import { IG_USER_DATA } from "../../Enum/LOGIN_PAGE_TYPE"
import { isAuth, logout, returnIdTokenResult } from "../../Utils/FirebaseUtils"
import { getAPIPath } from "../../Utils/HelpingFunction"
import { getAuthUID } from "../../Utils/QueryHelper"
import RequireLogin from "../../page-components/login/RequireLogin"
import {
  faArrowDown,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ALERT_TYPE, BUTTON_TYPE } from "../../Enum/ALERT_SYSYEM"
import CustomAlert from "../NewAlert"
import { Trans } from "react-i18next"
import { getUserLang } from "../../Utils/utiltyHelper"
import { useHistory } from "react-router-dom"
import {
  loadFbLoginAPI,
  returnIGUsername,
} from "../../HelpingFunction/FBLogin/FBLoginHelper"
import { getFollowersData } from "../../HelpingFunction/FollowersData/FollowerDBHelper"

const UpdateUsernameModalComponent: React.FC = (props: any) => {
  let history = useHistory()
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const paramList = useSelector((state: any) => {
    return state.QueryManager.params
  })

  const [newUserName, setNewUserName] = useState<string>("")
  const [isFinished, setIsFinished] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [followersData, setFollowersData] = useState<IG_USER_DATA>()

  useEffect(() => {
    loadFbLoginAPI()
    if (isAuth(auth)) {
      callFollowersData()
    }
  }, [])

  const callFollowersData = async () => {
    const state = await getFollowersData(auth.uid, "ig")

    if (state.success) {
      setFollowersData(state.igData as IG_USER_DATA)
    }
  }

  const onSubmit = (): void => {
    setIsLoading(true)
    returnIdTokenResult().then(async (res) => {
      await fetch(
        getAPIPath("/api/kol/" + getAuthUID(paramList) + "/username"),
        {
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            idToken: res.token,
          },
          body: JSON.stringify({
            oldUsername: followersData?.username,
            newUsername: newUserName,
            igUserData: null,
          }),
        }
      ).then((res) =>
        res.json().then((r) => {
          setIsLoading(false)
          if (r.success) {
            setIsFinished(true)
            setTimeout(() => {
              logout()
              props.closeModal()
              history.push("/" + getUserLang() + "/login")
            }, 2800)
          }
        })
      )
    })
  }

  const handleFBLogin = async () => {
    await returnIGUsername(followersData?.id as string).then((res) => {
      if (res.success) {
        setNewUserName(res.username)
      }
    })
  }

  if (followersData?.username !== "" && !isFinished) {
    //if loaded kol profile, not connect fb yet
    return (
      <div className="container mx-auto overflow-y-auto mt-10 mb-20">
        <CustomAlert
          title={"UpdateUsernameModalComponent.warning"}
          alertType={ALERT_TYPE.WARNING}
          buttonType={{
            type: BUTTON_TYPE.NONE,
          }}
          icon={faTriangleExclamation}
        />
        {newUserName === "" && (
          <div className="text-center">
            <button
              className="btn gap-2 border-none  text-white rounded-md p-2 text-xl w-auto mx-auto normal-case my-4 "
              style={{ backgroundColor: "#2473F2" }}
              id="btn-social-login"
              onClick={() => handleFBLogin()}>
              <div className="flex flex-row items-center">
                <img className="w-8" src={facebookIcon} />
                <p className="mx-4">
                  <Trans>RegForm.sign-up-with-facebook</Trans>
                </p>
              </div>
            </button>
          </div>
        )}

        {newUserName !== "" && (
          <div className="my-10">
            <div className="font-bold">
              <Trans>UpdateUsernameModalComponent.confirm-change</Trans>
            </div>

            <div className="grid grid-rows-3 gap-2 p-4 my-6 text-center rounded-2xl border-2 border-gray-200">
              {/* Item 1*/}
              <div className="flex mx-auto">
                <label className="mx-2 text-xl">
                  {followersData?.username}
                </label>
              </div>

              {/*  Item 2*/}
              <div>
                <FontAwesomeIcon
                  icon={faArrowDown}
                  className="text-gray-400 text-2xl"
                />
              </div>

              {/*  Item 3*/}
              <div className="flex mx-auto">
                <label className="mx-2 text-xl text-primary">
                  {newUserName}
                </label>
              </div>
            </div>

            <button
              className="btn btn-primary w-full"
              disabled={
                isLoading ||
                followersData?.username === newUserName ||
                newUserName === ""
              }
              onClick={onSubmit}>
              {followersData?.username === newUserName ? (
                <Trans>UpdateUsernameModalComponent.same-username</Trans>
              ) : (
                <Trans>UpdateUsernameModalComponent.confirm</Trans>
              )}
            </button>
            <p className="text-gray-500 text-sm mt-2">
              <Trans>UpdateUsernameModalComponent.confirm-warning</Trans>
            </p>
          </div>
        )}
      </div>
    )
  } else if (followersData?.username !== "" && isFinished) {
    //if everything done, wait and relogin
    return (
      <div className="container mx-auto overflow-y-auto my-auto">
        <RequireLogin />
      </div>
    )
  }
  return <div>loading</div>
}

export default UpdateUsernameModalComponent
