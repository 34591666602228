import React from "react"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import invoiceImg from "../../Assets/invoice-contract.png"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import draftImg from "../../Assets/draft-contract.png"
import { useHistory } from "react-router-dom"
import { getUserLang } from "../../Utils/utiltyHelper"
import { useDispatch } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../Utils/ModalType"
import { Trans } from "react-i18next"

export interface KOLContractSelectionModalProps {
  closeModal(): void
}

const KOLContractSelectionModalComponent = (
  props: KOLContractSelectionModalProps
) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const createDraftContractModal = (): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.CREATE_DRAFT_CONTRACT_MODAL,
      metaData: null,
    })
  }
  return (
    <div className="md:p-2">
      <div className="grid grid-cols-2  my-4 divide-x ">
        <div
          onClick={() => {
            history.push("/" + getUserLang() + "/create-draft")
            props.closeModal()
          }}
          className="hover:bg-gray-100 hover:opacity-80 cursor-pointer p-4 rounded-xl md:px-8">
          <p className="text-lg font-medium">
            <Trans>KOLContractSelectionModal.draft-title</Trans>
          </p>
          <p className="text-gray-500 text-sm font-medium">
            <Trans>KOLContractSelectionModal.draft-desc</Trans>
          </p>
          <img className="w-56 my-4 mx-auto" src={draftImg} />
        </div>
        <div
          onClick={() => {
            history.push("/" + getUserLang() + "/create-invoice")
            props.closeModal()
          }}
          className="hover:bg-gray-100 hover:opacity-80 cursor-pointer p-4 rounded-xl md:px-8">
          <p className="text-lg font-medium">
            <Trans>KOLContractSelectionModal.invoice-title</Trans>
          </p>
          <p className="text-gray-500 text-sm font-medium">
            <Trans>KOLContractSelectionModal.invoice-desc</Trans>
          </p>
          <img className="w-56 my-4 mx-auto" src={invoiceImg} />
        </div>
      </div>
    </div>
  )
}

export default KOLContractSelectionModalComponent
