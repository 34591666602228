import { Capacitor } from "@capacitor/core"
import { isLocalhost } from "./utiltyHelper"

export function getAPIPath(path: string) {
  const isMobileDevice =
    Capacitor.getPlatform() === "android" || Capacitor.getPlatform() === "ios"

  const dnsUrl = "https://look4kol.com" + path
  const testUrl = "http://localhost:" + process.env.REACT_APP_TEST_PORT + path

  return isMobileDevice ? dnsUrl : isLocalhost() ? testUrl : path
}

export const notErrorImage = (url: string): boolean => {
  // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
  let pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  )
  return !!pattern.test(url)
}
export const isCDNImage = (url: string): boolean => {
  return (
    url.includes("cdninstagram.com") ||
    url.includes("privatephotoviewer.com") ||
    url.includes("scontent") ||
    url.includes("greatfon.com")
  )
}

export function getNeo4jServer(path: string) {
  return "https://neo4j.look4kol.com" + path
}

export function isWeb() {
  return (
    Capacitor.getPlatform() !== "android" && Capacitor.getPlatform() !== "ios"
  )
}

export function isAndroid() {
  return Capacitor.getPlatform() === "android"
}

export function isIOS() {
  return Capacitor.getPlatform() === "ios"
}

export function getCumulativeOffset(element: any) {
  let top = 0,
    left = 0
  do {
    top += element.offsetTop || 0
    left += element.offsetLeft || 0

    element = element.offsetParent
  } while (element)

  return {
    top: top,
    left: left,
  }
}

export function isStringHaveSpacesAndNewLinesOnly(str: string) {
  let regex = /^[ \r\n]*$/
  return regex.test(str)
}
