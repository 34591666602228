import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleUser,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons"
import { Trans } from "react-i18next"

import {
  AvatarType,
  BadgeType,
  ButtonType,
  CheckBoxType,
  ColType,
  CustomType,
  DateType,
  EmptyType,
  ExpansionType,
  HeaderType,
  IconType,
  PriceType,
  RowType,
  ScoreType,
  StringType,
  TogglerType,
} from "../Model/Table"
import {
  BADGE_TYPE,
  BUTTON_SIZE,
  BUTTON_TYPE,
  HEADER_STYLE,
  ICON_TYPE,
  TABLE_TYPE,
  WIDTH_SIZE,
} from "../Enum/TABLE_SYSTEM"
import { timeStampToDisplayTimeString } from "../Utils/Converter"
import { Rating } from "./Rating"
import classNames from "classnames"
import { useSelector } from "react-redux"

interface Column {
  data:
    | IconType
    | ScoreType
    | CheckBoxType
    | TogglerType
    | BadgeType
    | DateType
    | StringType
    | ButtonType
    | PriceType
    | AvatarType
    | CustomType
    | EmptyType
    | ColType
    | RowType
    | HeaderType
    | ExpansionType
  colIndex: number
}

const Columns: React.FC<Column> = ({ data, colIndex }) => {
  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })

  const stringHeadingStyle = (data: StringType) => {
    switch (data.headingStyle) {
      case HEADER_STYLE.H1:
        return "font-semibold"
      case HEADER_STYLE.H2:
        return "font-normal text-sm text-gray-500"
      case HEADER_STYLE.H3:
        return "font-light text-xs text-gray-400"
      default:
        break
    }
  }

  const widthStyle = (data: RowType | ColType | StringType | ButtonType) => {
    switch (data.fixedWidth) {
      case WIDTH_SIZE.SM:
        return "w-12"
      case WIDTH_SIZE.MD:
        return "w-24"
      case WIDTH_SIZE.LG:
        return "w-36"
      case WIDTH_SIZE.XL:
        return "w-48"
      case WIDTH_SIZE.XXL:
        return "w-64"
      case WIDTH_SIZE.XXXL:
        return "w-96"
      default:
        break
    }
  }
  const buttonSizeStyle = (data: ButtonType) => {
    switch (data.buttonSize) {
      case BUTTON_SIZE.XS:
        return "btn-xs"
      case BUTTON_SIZE.SM:
        return "btn-sm"
      case BUTTON_SIZE.MD:
        return ""
      case BUTTON_SIZE.LG:
        return "btn-lg"
      default:
        break
    }
  }
  const iconTypeStyle = (data: IconType) => {
    switch (data.iconType) {
      case ICON_TYPE.FAIL:
        return "text-red-600"
      case ICON_TYPE.WARNING:
        return "text-yellow-600"
      case ICON_TYPE.SUCCESS:
        return "text-green-600"
      case ICON_TYPE.GHOST:
        return "text-gray-500"
      default:
        break
    }
  }

  const btnIconTypeStyle = (data: ButtonType) => {
    switch (data.buttonType) {
      case BUTTON_TYPE.FAIL:
        return "text-red-600"
      case BUTTON_TYPE.WARNING:
        return "text-yellow-600"
      case BUTTON_TYPE.SUCCESS:
        return "text-green-600"
      case BUTTON_TYPE.INFO:
        return "text-gray-100"
      case BUTTON_TYPE.GHOST:
        return "text-gray-500"
      default:
        break
    }
  }

  const btnTypeStyle = (data: ButtonType) => {
    switch (data.buttonType) {
      case BUTTON_TYPE.FAIL:
        return "btn-error"
      case BUTTON_TYPE.WARNING:
        return "btn-warning"
      case BUTTON_TYPE.SUCCESS:
        return "btn-success"
      case BUTTON_TYPE.INFO:
        return ""
      case BUTTON_TYPE.GHOST:
        return "btn-ghost text-gray-500"
      default:
        break
    }
  }

  const btnIconExisit = (data: ButtonType) => {
    if (data.icon) {
      return (
        <FontAwesomeIcon
          className={classNames("text-sm", btnIconTypeStyle(data))}
          icon={data.icon}
        />
      )
    }
  }

  switch (data.type) {
    case TABLE_TYPE.STRING:
      return (
        <div className="font-medium text-stone-800 text-sm">
          <div
            className={classNames({ tooltip: data.showExtraTip })}
            data-tip={data.value}>
            <div
              className={classNames(
                "text-ellipsis overflow-hidden",
                widthStyle(data),
                stringHeadingStyle(data)
              )}>
              {data.value}
            </div>
          </div>
        </div>
      )
    case TABLE_TYPE.PRICE:
      return (
        <div
          className={classNames("font-medium text-stone-800 text-sm", {
            "text-gray-400": data.value === 0,
          })}>
          {data.value === 0
            ? "NA"
            : data.value + " " + data.currency.toUpperCase()}
        </div>
      )
    case TABLE_TYPE.ICON:
      return (
        <div
          className={classNames("my-auto ml-2 text-lg", iconTypeStyle(data))}>
          <FontAwesomeIcon icon={data.value} />
        </div>
      )
    case TABLE_TYPE.DATE:
      return (
        <div className="font-medium text-stone-800 text-sm">
          {typeof data.value !== "string" ? (
            timeStampToDisplayTimeString(data.value)
          ) : (
            <div className="text-base-400">------</div>
          )}
        </div>
      )
    case TABLE_TYPE.BADGE:
      return (
        <div
          className={classNames("h-auto grid grid-rows-1 gap-2", {
            hidden: data.hidden,
          })}>
          <div className="row-span-3">
            <span
              className={classNames(
                "relative inline-block px-2 py-1  text-2xs font-semibold leading-tight",
                {
                  "text-fuchsia-900": data.badgeType === BADGE_TYPE.WORKING,
                  "text-green-900":
                    data.badgeType === BADGE_TYPE.JOB_DONE ||
                    data.badgeType === BADGE_TYPE.SUCCESS,
                  "text-yellow-900": data.badgeType === BADGE_TYPE.JOB_ACCEPTED,
                  "text-blue-900": data.badgeType === BADGE_TYPE.CONTRACT,
                  "text-indigo-900": data.badgeType === BADGE_TYPE.QUOTATION,
                  "text-pink-900":
                    data.badgeType === BADGE_TYPE.WAIT_QUOTE_ACCEPT,
                  "text-orange-900":
                    data.badgeType === BADGE_TYPE.PENDING ||
                    data.badgeType === BADGE_TYPE.FAIL,
                  "text-gray-900": data.badgeType === BADGE_TYPE.TAG,
                  "text-amber-700": data.badgeType === BADGE_TYPE.INVOICE,
                }
              )}>
              <span
                aria-hidden
                className={classNames(
                  "absolute inset-0 opacity-50 rounded-full",
                  {
                    "bg-fuchsia-200": data.badgeType === BADGE_TYPE.WORKING,
                    "bg-green-200 ": data.badgeType === BADGE_TYPE.JOB_DONE,
                    "bg-yellow-200 ":
                      data.badgeType === BADGE_TYPE.JOB_ACCEPTED,
                    "bg-blue-200": data.badgeType === BADGE_TYPE.CONTRACT,
                    "bg-indigo-200": data.badgeType === BADGE_TYPE.QUOTATION,
                    "bg-pink-200":
                      data.badgeType === BADGE_TYPE.WAIT_QUOTE_ACCEPT,
                    "bg-orange-200": data.badgeType === BADGE_TYPE.PENDING,
                    "bg-gray-200": data.badgeType === BADGE_TYPE.TAG,
                    "bg-green-100": data.badgeType === BADGE_TYPE.SUCCESS,
                    "bg-red-100": data.badgeType === BADGE_TYPE.FAIL,
                    "bg-amber-200": data.badgeType === BADGE_TYPE.INVOICE,
                  }
                )}
              />
              <span className="relative">
                <Trans>{data.value}</Trans>
              </span>
            </span>
          </div>
        </div>
      )
    case TABLE_TYPE.BUTTON:
      return (
        <div>
          <button
            className={classNames(
              "btn flex p-1 gap-2 normal-case",
              buttonSizeStyle(data),
              btnTypeStyle(data),
              data.fixedWidth ? widthStyle(data) : "w-auto",
              data.hoverActive &&
                screenWidth > 768 &&
                "group-hover:visible invisible",
              {
                hidden: data.hidden,
              }
            )}
            disabled={data.disable}
            onClick={(e) => {
              e.stopPropagation()
              data.onClick()
            }}>
            {btnIconExisit(data)}
            <Trans>{data.value}</Trans>
          </button>
        </div>
      )
    case TABLE_TYPE.CHECK_BOX:
      return (
        <div className="my-auto h-full">
          <input
            type="checkbox"
            className="checkbox"
            onChange={data.onChange}
            checked={data.checked}
          />
        </div>
      )
    case TABLE_TYPE.TOGGLE:
      return (
        <div>
          <input
            type="checkbox"
            className="toggle"
            onChange={data.onChange}
            checked={data.checked}
          />
        </div>
      )
    case TABLE_TYPE.SCORE:
      return (
        <div>
          <Rating score={data.value} size={"sm"} index={colIndex} />
        </div>
      )
    case TABLE_TYPE.AVATAR:
      return (
        <div className="avatar">
          <div className="w-10 h-10 rounded-full">
            {data.value !== "" ? (
              <img src={data.value} />
            ) : (
              <FontAwesomeIcon
                className="text-primary w-10 h-10"
                icon={faCircleUser}
              />
            )}
          </div>
        </div>
      )
    case TABLE_TYPE.CUSTOM:
      return <div>{data.component}</div>
    case TABLE_TYPE.ROW:
      return (
        <div
          className={classNames(
            "flex gap-2 lg:gap-4 ",
            data.fixedWidth ? widthStyle(data) : ""
          )}>
          {data.component.map((item, index) => {
            if (
              item.type !== TABLE_TYPE.DEFAULT &&
              item.type !== TABLE_TYPE.EXPANDED
            ) {
              return <Columns data={item} colIndex={index} />
            }
            return <div />
          })}
        </div>
      )
    case TABLE_TYPE.COL:
      return (
        <div
          className={classNames(
            "flex-1 gap-2 w-full",
            data.fixedWidth ? widthStyle(data) : ""
          )}>
          {data.component.map((item, index) => {
            if (
              item.type !== TABLE_TYPE.DEFAULT &&
              item.type !== TABLE_TYPE.EXPANDED
            ) {
              return <Columns data={item} colIndex={index} />
            }
            return <div />
          })}
        </div>
      )
    case TABLE_TYPE.HEAD:
      if (
        data.component.type !== TABLE_TYPE.DEFAULT &&
        data.component.type !== TABLE_TYPE.EXPANDED
      ) {
        return <Columns data={data.component} colIndex={colIndex} />
      }
      return <div />
    case TABLE_TYPE.EXPANDED:
      return (
        <div>
          <FontAwesomeIcon
            className="text-gray-600 w-6 h-6"
            onClick={data.onChange}
            icon={data.expanded ? faSortUp : faSortDown}
          />
        </div>
      )

    default:
      return <div />
  }
}
export default Columns
