import { DETAIL_TYPE, generalType } from "./TypeList"
import { EXCEL_TYPE } from "../Enum/EXCEL_TYPE"

export function returnKOLGender(value: string) {
  const res = KOL_GENDER.find((e) => e.value === value)
  return res !== null && typeof res !== "undefined" ? res.label : "/"
}

export const KOL_GENDER = [
  {
    label: "SELECT",
    value: "",
  },
  {
    label: "Male",
    value: "1",
  },
  {
    label: "Female",
    value: "2",
  },
  {
    label: "Uni sex",
    value: "3",
  },
]

export function returnKOLJobNatureName(value: string) {
  const res = KOL_JOB_NATURE.find((e) => e.value === value)
  return res !== null && typeof res !== "undefined" ? res.label : "/"
}

export const KOL_JOB_NATURE = [
  {
    label: "SELECT",
    value: "",
  },
  {
    label: "Unknown /other",
    value: "0",
  },
  {
    label: "Youtuber",
    value: "1",
  },
  {
    label: "Artist",
    value: "2",
  },
  {
    label: "Modal",
    value: "3",
  },
  {
    label: "Content Creator",
    value: "4",
  },
  {
    label: "Illustrator",
    value: "5",
  },
  {
    label: "Fan Page",
    value: "6",
  },
  {
    label: "Public Figure",
    value: "7",
  },
  {
    label: "Shop",
    value: "8",
  },
  {
    label: "Student",
    value: "9",
  },
]

export function returnKOLAccType(value: string) {
  const res = KOL_ACC_TYPE.find((e) => e.value === value)
  return res !== null && typeof res !== "undefined" ? res.label : "/"
}

export const KOL_ACC_TYPE = [
  {
    label: "SELECT",
    value: "",
  },
  {
    label: "Single",
    value: "1",
  },
  {
    label: "Page",
    value: "2",
  },
  {
    label: "Company",
    value: "3",
  },
]

export function headerMaker(type: EXCEL_TYPE) {
  const header = ["User Name", "Number of Followers"]

  switch (type) {
    case EXCEL_TYPE.KOL_GENRE:
      header.push("Top Genre")
      DETAIL_TYPE.map((c) => {
        //skip the first useless item
        if (c.label !== "－") {
          header.push(c.label.split(",").join("") + "(percentage)")
        }
      })
      return header
    case EXCEL_TYPE.KOL_CATEGORY:
      header.push("Top Category")
      generalType.map((c) => {
        //skip the first useless item
        if (c.label !== "－") {
          header.push(c.label.split(",").join("") + "(percentage)")
        }
      })
      return header
    default:
      return header
  }
}

export function genrePercentageToCSV(
  userID: string,
  percentagesArr: any[],
  noOfFollow: number,
  topGenre: string
) {
  let topGenreName = DETAIL_TYPE.find((d) => d.value === topGenre)
  let resultArr = [
    userID,
    noOfFollow,
    topGenreName ? topGenreName.label : "None",
  ]
  let counts: { label: string; percentages: any }[] = []

  if (Array.isArray(percentagesArr)) {
    DETAIL_TYPE.map((c) => {
      const itemInfo = percentagesArr.find((e) => e.item === c.value)
      //skip the first useless item
      if (c.label !== "－") {
        counts.push({
          label: c.label.split(",").join(""),
          percentages: itemInfo ? itemInfo.percentages : 0,
        })
      }
    })
  }

  counts.map((i) => {
    resultArr.push((i.percentages * 100).toFixed(5) + "%")
  })

  return resultArr
}

export function categoryPercentageToCSV(
  userID: string,
  percentagesArr: any[],
  noOfFollow: number,
  topCate: string
) {
  let topCateName = generalType.find((d) => d.value === topCate)
  let resultArr = [userID, noOfFollow, topCateName ? topCateName.label : "None"]

  let counts: { label: string; percentages: number }[] = []

  if (Array.isArray(percentagesArr)) {
    generalType.map((c) => {
      const itemInfo = percentagesArr.filter(
        (e) => e.item.split(".")[0] === c.value
      )
      if (c.label !== "") {
        if (itemInfo) {
          let cateTotalPercent = 0
          itemInfo.forEach((item) => {
            cateTotalPercent += item.percentages
          })
          counts.push({
            label: c.label,
            percentages: cateTotalPercent,
          })
        } else {
          counts.push({ label: c.label, percentages: 0 })
        }
      }
    })
  }

  counts.map((i) => {
    resultArr.push((i.percentages * 100).toFixed(5) + "%")
  })

  return resultArr
}
