import React, { useEffect, useState } from "react"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { CHATROOM_PAGE_TYPE, SYSTEM_MESSAGE_TYPE } from "../../../Enum/APP_TYPE"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { ChatroomJob } from "../../../Model/Chatroom"
import JobMsgItem from "./JobMsgItem"
import { timeStampToDisplayTimeString } from "../../../Utils/Converter"
import { NotiMessage } from "../../../Model/Message"
import { Trans } from "react-i18next"
import { getUserLang } from "../../../Utils/utiltyHelper"

interface Props {
  chatroom: ChatroomJob
  messages: NotiMessage[]
  setAllMsgIsRead: (chatroomID: string) => void
}

const JobMsg = (props: Props) => {
  let history = useHistory()
  const dispatch = useDispatch()

  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })

  const [isJustRead, setIsJustRead] = useState<boolean>(false)
  // const [avatarURL, setAvatarUrl] = useState<string>("")
  const [newMsg, setNewMsg] = useState<NotiMessage[]>([])
  const [isMsgLoaded, setIsMsgLoaded] = useState<boolean>(true)

  const setPageType = (): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_PAGE_TYPE,
      data: CHATROOM_PAGE_TYPE.CHAT_TEXT,
    })
  }

  const dateSort = (
    a: { issueDate: { seconds: number } },
    b: { issueDate: { seconds: number } }
  ) => {
    return b.issueDate.seconds - a.issueDate.seconds
  }

  useEffect(() => {
    let newMsg: NotiMessage[] = []
    props.messages.map((msg: NotiMessage, index: number) => {
      if (
        msg.type !== SYSTEM_MESSAGE_TYPE.CONTRACT &&
        msg.type !== SYSTEM_MESSAGE_TYPE.SYSTEM &&
        msg.url.includes(props.chatroom.id as string) &&
        index >= 0
      )
        newMsg.push(msg)
    })
    setNewMsg(newMsg)
  }, [props.messages.length])

  const goToContractPage = () => {
    if ("seconds" in props.chatroom.lastUpdate) {
      localStorage.setItem(
        "look4kol-cr-" + props.chatroom.id,
        new Date(props.chatroom.lastUpdate.seconds * 1000).toISOString()
      )
    }

    if (screenWidth < 768) {
      history.push({
        pathname: "/" + getUserLang() + "/c",
        search:
          "?" +
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          new URLSearchParams({
            chatroomID: props.chatroom.id,
          }),
      })
    } else {
      history.push({
        pathname: "/" + getUserLang() + "/c",
        search:
          "?" +
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          new URLSearchParams({
            chatroomID: props.chatroom.id,
            type: "contract",
          }),
      })
    }

    setPageType()
  }

  const displayRecentMsgDate = newMsg
    .sort(dateSort)
    .map((msg: NotiMessage, index: number) => {
      if (index < 1) {
        return (
          <div className="mb-2">
            <span className="w-full text-xs text-gray-500 my-1 text-right">
              {timeStampToDisplayTimeString(msg.issueDate.seconds)}
            </span>
          </div>
        )
      }
      return <div />
    })

  const chatMsgLayout = [
    "p-3 py-2 mb-2 mr-2 md:my-2",
    isJustRead ? "opacity-50" : " ",
    "shadow md:shadow-none cursor-pointer hover:bg-gray-100 rounded-md border border-gray-100",
  ].join(" ")

  if (isMsgLoaded) {
    return (
      <li
        className={chatMsgLayout}
        onClick={() => {
          setIsJustRead(true)
          if (props.chatroom.id) {
            props.setAllMsgIsRead(props.chatroom.id)
          }
          goToContractPage()
        }}>
        <div>
          <div className="flex gap-3">
            <div className="flex h-14 w-14 items-center justify-center rounded-full overflow-hidden">
              <img
                className="h-10 w-10 rounded-full"
                src={
                  props.chatroom.avatarUrl
                    ? props.chatroom.avatarUrl
                    : "https://look4kol.com/circle-user.png"
                }
                loading="lazy"
              />
            </div>
            <div className="w-full">
              <div className="flex gap-2">
                <div
                  className={
                    newMsg.length <= 0 || isJustRead
                      ? "font-normal text-gray-500"
                      : "font-semibold text-gray-800"
                  }>
                  {props.chatroom.title}
                </div>
                {newMsg.length > 0 && !isJustRead && (
                  //red dot indicator
                  <div className="rounded-full mt-2 mr-4 bg-red-400 w-2 h-2" />
                )}
              </div>
              <div>
                {newMsg.length > 0 ? (
                  <div>{displayRecentMsgDate}</div>
                ) : (
                  <div className="mb-2">
                    <span className="w-full text-xs text-gray-500 my-1 text-right">
                      {timeStampToDisplayTimeString(props.chatroom.lastUpdate)}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            {newMsg.sort(dateSort).map((msg: NotiMessage, index: number) => {
              if (index < 3) {
                return <JobMsgItem key={msg.id + "index"} msg={msg} />
              }
              return <div />
            })}
          </div>
          <div>
            {newMsg.length > 3 && newMsg.length <= 9 && (
              <div className="text-neutral text-xs m-2">
                <Trans>JobMsg.message-extended-1</Trans>
                {newMsg.length - 3}
                <Trans>JobMsg.message-extended-2</Trans>
              </div>
            )}
            {newMsg.length > 9 && (
              <div className="text-neutral text-xs m-2">
                <Trans>JobMsg.message-extended-1</Trans>
                9+
                <Trans>JobMsg.message-extended-2</Trans>
              </div>
            )}
          </div>
        </div>
      </li>
    )
  }
  return <div />
}
export default JobMsg
