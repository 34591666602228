import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Trans, useTranslation } from "react-i18next"
import Spinner from "../../../Component/Spinner"
import { Plan } from "../../../Model/Plan"
import { getKOLPlans } from "../../../HelpingFunction/KOL/kolDBHelper"
import ReactQuill from "react-quill"
import { isAuth, returnIdTokenResult } from "../../../Utils/FirebaseUtils"
import { getAPIPath } from "../../../Utils/HelpingFunction"
import { v4 as uuidv4 } from "uuid"
import { createNewProduct } from "../../../HelpingFunction/EditKOLProfile/EditKOLProfile"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import parse from "html-react-parser"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReactQuillEditorCss from "../../../Utils/ReactQuillEditor.module.css"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReactQuillDisplayCss from "../../../Utils/ReactQuillDisplay.module.css"
import { ASSET_TYPE, CHATROOM_PAGE_TYPE } from "../../../Enum/APP_TYPE"
import { ALERT_TYPE, BUTTON_TYPE } from "../../../Enum/ALERT_SYSYEM"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import CustomAlert from "../../../Component/NewAlert"
import { getAuthUID, getQuery, useQuery } from "../../../Utils/QueryHelper"

const QuotationPage: React.FC = (any) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const paramList = useSelector((state: any) => {
    return state.QueryManager.params
  })

  const job = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomJob
  })

  const chatroom = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomInfo
  })

  let params = {
    type: getQuery(paramList, "type"),
    chatroomID: getQuery(paramList, "chatroomID"),
    metaPath: getQuery(paramList, "metaPath"),
    viewAs: getQuery(paramList, "viewAs"),
  }

  const addDraftMsgToChat = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SHARE_DRAFT_MSG,
      draftMsg: state,
    })
  }

  const setChatText = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_PAGE_TYPE,
      data: CHATROOM_PAGE_TYPE.CHAT_TEXT,
    })
  }

  const isInviteCustomer = isAuth(auth)
    ? getAuthUID(paramList) === job.KOLID &&
      !job.isSubmitQuotation &&
      job.priceID === ""
    : ""
  const isWaitingQuotation = isAuth(auth)
    ? getAuthUID(paramList) === job.CustomerID &&
      job.isSubmitQuotation &&
      job.priceID === ""
    : ""
  const isWaitForQuotationConfirmAlert = isAuth(auth)
    ? getAuthUID(paramList) === job.KOLID &&
      !job.isConfirmQuotation &&
      job.priceID !== ""
    : ""

  const inviteAdvertisers = () => {
    setChatText()
    addDraftMsgToChat({
      type: ASSET_TYPE.LINK_TEXT,
      msg: t("DefaultMessage.invite-customer-quotation"),
      path:
        "/c?chatroomID=" + chatroom.id + "&type=contract&metaPath=" + job.id,
    })
  }

  const [KOLPlan, setKOLPlan] = useState<Plan[]>([])
  const [isLoadedKOLPlan, setIsLoadedKOLPlan] = useState<boolean>(false)

  const [isSubmittingQuotation, setIsSubmittingQuotation] =
    useState<boolean>(false)
  const [isConfirmQG, setIsConfirmQG] = useState<boolean>(false)

  const [quotationObj, setQuotationObj] = useState<{
    ServicePlanID: string
    ServicePlanName: string
    quotationDetail: string
    price: number
    priceID: string
  }>({
    ServicePlanID: "",
    //will be the job name
    ServicePlanName: "",
    quotationDetail: "",
    price: 0,
    priceID: "",
  })
  const [alert, setAlert] = useState<string>("")
  const [emailType, setEmailType] = useState("")

  const updateJob = (state: any): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_JOB,
      data: state,
    })
  }

  useEffect(() => {
    setQuotationObj({
      ServicePlanID: job.ServicePlanID ? job.ServicePlanID : "",
      ServicePlanName: job.ServicePlanName ? job.ServicePlanName : "",
      quotationDetail: job.quotationDetail ? job.quotationDetail : "",
      price: job.price ? job.price : 0,
      priceID: job.priceID ? job.priceID : "",
    })

    if (job && job.id !== "") {
      getKOLPlans(job.KOLID, false).then((result) => {
        if (result.success) {
          setKOLPlan(result.data)
          setIsLoadedKOLPlan(true)
        } else {
          setIsLoadedKOLPlan(true)
        }
      })
    }
  }, [job])

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const modules = React.useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        //'image'
        ["link"],
        ["clean"],
        ["code-block"],
      ],
      // handlers: {
      //     'image': () => quillImageCallBack()
      // },
      clipboard: {
        matchVisual: false,
      },
    },
  }))

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    // 'image'
  ]

  const shouldDisplayQuotationPrice = job
    ? job.isSubmitQuotation && job.priceID !== "" && !job.accepted
    : false

  const shouldCustomerConfirm =
    job && auth
      ? getAuthUID(paramList) === job.CustomerID &&
        !job.accepted &&
        job.isSubmitQuotation &&
        job.priceID !== ""
      : false

  const shouldCustomerRequestQuotation =
    job && auth
      ? getAuthUID(paramList) === job.CustomerID &&
        !job.accepted &&
        !job.isSubmitQuotation &&
        job.priceID === ""
      : false

  const shouldKOLFillPrice =
    job && auth
      ? (getAuthUID(paramList) === job.KOLID &&
          job.isSubmitQuotation &&
          job.priceID === "") ||
        job.accepted
      : false

  const withdrawQuotation = async () => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/job/" + job.id + "/quotation/withdraw"), {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          setEmailType("quotation_withdraw")
          updateJob({
            isSubmitQuotation: false,
            price: 0,
            priceID: "",
          })
        })
    })
  }

  const isInvalidPriceRange =
    quotationObj.price < 0 ||
    (quotationObj.price >= 1 && quotationObj.price < 10) ||
    quotationObj.price > 1000000

  const submitQuotation = async () => {
    setIsSubmittingQuotation(true)
    let newJob = { ...job }
    delete newJob.id
    // if custom product && is kol
    if (
      getAuthUID(paramList) === job.KOLID &&
      !isInvalidPriceRange &&
      quotationObj.ServicePlanName
    ) {
      let newProduct = {
        id: uuidv4(),
        title: "custom product [job: " + job.id + "]",
        isVisible: true,
        price: quotationObj.price,
      }
      const createProductState = await createNewProduct(
        newProduct,
        getAuthUID(paramList),
        params.viewAs
      )

      returnIdTokenResult().then(async (res) => {
        let headerInfo: any = {
          "Content-Type": "application/json",
          idToken: res.token,
        }
        if (params.viewAs) {
          headerInfo = {
            ...headerInfo,
            agencyID: params.viewAs,
          }
        }
        await fetch(getAPIPath("/api/job/" + job.id + "/quotation"), {
          method: "PATCH",
          headers: headerInfo,
          body: JSON.stringify({
            quotationObj: {
              ...quotationObj,
              priceID: createProductState.data.newPrice.id,
            },
          }),
        })
          .then((res) => res.json())
          .then((finalResponse) => {
            setIsSubmittingQuotation(false)
            if (finalResponse.success) {
              setEmailType("quotation_kol")
              updateJob({
                price: quotationObj.price,
                priceID: createProductState.data.newPrice.id,
              })
            } else {
              console.log("error")
            }
          })
      })
    } else if (
      quotationObj.quotationDetail !== "" &&
      quotationObj.ServicePlanName !== "" &&
      quotationObj.ServicePlanID !== ""
    ) {
      returnIdTokenResult().then(async (res) => {
        await fetch(getAPIPath("/api/job/" + job.id + "/quotation"), {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            idToken: res.token,
          },
          body: JSON.stringify({
            quotationObj: {
              ...quotationObj,
              ServicePlanID:
                quotationObj.ServicePlanID === "Custom"
                  ? ""
                  : quotationObj.ServicePlanID,
            },
          }),
        })
          .then((res) => res.json())
          .then((finalResponse) => {
            setIsSubmittingQuotation(false)
            if (finalResponse.success) {
              setEmailType("quotation_customer")
              updateJob({
                isSubmitQuotation: true,
              })
            } else {
            }
          })
      })
      setAlert("")
    } else {
      setIsSubmittingQuotation(false)
      //empty quotationObj.quotationDetail
      // eslint-disable-next-line no-alert
      setAlert(t("Contract.quotation-fill-warning") as string)
    }
  }

  const confirmQuotation = async () => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/job/" + job.id + "/quotation"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          setEmailType("quotation_accept")
          updateJob({
            isConfirmQuotation: true,
          })
        })
    })
  }

  if (job.id && typeof quotationObj.quotationDetail !== "undefined") {
    return (
      <div>
        {/*{warningMsg !== "" && <WarningMsgAlert warningMsg={warningMsg} />}*/}

        {isWaitingQuotation && (
          <CustomAlert
            title={"Contract.wait-for-quotation"}
            alertType={ALERT_TYPE.WARNING}
            buttonType={{
              type: BUTTON_TYPE.NONE,
            }}
            icon={faExclamationCircle}
          />
        )}

        {isInviteCustomer && (
          <CustomAlert
            title={"Contract.invite-customer"}
            alertType={ALERT_TYPE.WARNING}
            buttonType={{
              type: BUTTON_TYPE.TEXT_ONLY,
              buttonText: "Contract.invite-customer-btn",
            }}
            icon={faExclamationCircle}
            buttonFn={inviteAdvertisers}
          />
        )}

        {isWaitForQuotationConfirmAlert && (
          <CustomAlert
            title={"Contract.wait-for-quotation-confirm"}
            alertType={ALERT_TYPE.WARNING}
            buttonType={{
              type: BUTTON_TYPE.NONE,
            }}
            icon={faExclamationCircle}
          />
        )}

        <div className="block my-4">
          <label className="text-gray-700 mb-2 block">
            <Trans>Contract.job-title</Trans>
          </label>
          <input
            id={"QuotaionPage_JobTitle"}
            type="text"
            className="px-4 py-3 bg-white border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            value={quotationObj.ServicePlanName}
            defaultValue={job.ServicePlanName}
            disabled={job.isSubmitQuotation}
            onChange={(e) => {
              setQuotationObj({
                ...quotationObj,
                ServicePlanName: e.target.value,
              })
            }}
          />
        </div>

        <div className="block my-4">
          <label className="text-gray-700 mb-2 block">
            <Trans>Contract.plan-select</Trans>
          </label>
          {isLoadedKOLPlan && (
            <select
              id={"QuotaionPage_PlanSelect"}
              name="plan"
              className="w-full select select-bordered mt-2"
              disabled={job.isSubmitQuotation}
              onChange={(event) => {
                const ets: number = event.target.selectedIndex
                // check whether is custom plan or not
                setQuotationObj({
                  ...quotationObj,
                  // ServicePlanName: KOLPlan[ets - 1].title,
                  ServicePlanID: KOLPlan[ets - 1]
                    ? KOLPlan[ets - 1].id
                    : "Custom",
                })
              }}>
              <option disabled selected>
                Choose Type
              </option>
              {KOLPlan.map((o) => {
                return (
                  <option
                    key={o.id}
                    selected={quotationObj.ServicePlanID === o.id}>
                    {o.title + " ~HKD " + o.price}
                  </option>
                )
              })}
              <option>Custom 自定</option>
            </select>
          )}
        </div>

        <div className="block mt-4 mb-6">
          <label className="text-gray-700 mb-2">
            <Trans>Contract.quotation-detail</Trans>
          </label>
          <div
            className={["my-4", ReactQuillEditorCss.ReactQuillEditor].join(" ")}
            style={{
              minHeight: "200px",
              maxHeight: "400px",
              overflow: "hidden",
            }}>
            {job.isSubmitQuotation || getAuthUID(paramList) === job.KOLID ? (
              <div
                className={[
                  "p-4 border h-64 border-gray-300 rounded-lg overflow-auto",
                  ReactQuillDisplayCss.ReactQuillDisplay,
                ].join(" ")}>
                {parse(job.quotationDetail)}
              </div>
            ) : (
              <ReactQuill
                placeholder={
                  t("Contract.kol-requirement-draft-message") as string
                }
                defaultValue={job.quotationDetail}
                value={quotationObj.quotationDetail}
                onChange={(e) => {
                  if (e === "<p><br></p>") {
                    // console.log("invalid input")
                    e = ""
                  }
                  setQuotationObj({
                    ...quotationObj,
                    quotationDetail: e,
                  })
                }}
                theme="snow"
                modules={modules}
                formats={formats}
              />
            )}
          </div>

          <div
            className={[
              "block float-right text-sm",
              quotationObj.quotationDetail.length >= 3000
                ? "text-red-500"
                : "text-gray-500",
            ].join(" ")}>
            {quotationObj.quotationDetail.length + " / 3000"}
          </div>
        </div>

        {shouldDisplayQuotationPrice && !job.accepted && (
          <div className="block my-4">
            <label className="text-gray-700 mb-2">
              <Trans>Contract.price</Trans>
            </label>
            <input
              type="number"
              className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
              value={job.price}
              disabled={true}
            />
          </div>
        )}

        {shouldCustomerConfirm && !job.isConfirmQuotation && (
          <div className={"md:pb-8 grid grid-cols-2 gap-4 pt-4"}>
            <button
              id={"Contract_WithdrawQuotationButton"}
              className="btn bg-red-default hover:bg-red-600 border-none"
              onClick={() => {
                withdrawQuotation()
              }}>
              <Trans>Contract.withdraw-quotation</Trans>
            </button>
            <button
              id={"Contract_AcceptQuotationButton"}
              className="btn bg-green-600	hover:bg-green-700 border-none"
              onClick={() => {
                confirmQuotation()
              }}>
              <Trans>Contract.confirm-quotation</Trans>
            </button>
          </div>
        )}

        {shouldCustomerRequestQuotation && !job.isConfirmQuotation && (
          <div>
            <button
              id={"Contract_GetQuotationButton"}
              className="btn btn-outline btn-info "
              disabled={
                isSubmittingQuotation || getAuthUID(paramList) === job.KOLID
              }
              onClick={() => {
                submitQuotation()
              }}>
              {getAuthUID(paramList) === job.KOLID ? (
                <Trans>Contract.quotation-Customer-Only</Trans>
              ) : isSubmittingQuotation ? (
                <Spinner />
              ) : (
                <Trans>Contract.get-quotation</Trans>
              )}
            </button>
            {alert !== "" && (
              <div className="py-4">
                <CustomAlert
                  title={alert}
                  alertType={ALERT_TYPE.ERROR}
                  buttonType={{
                    type: BUTTON_TYPE.NONE,
                  }}
                  icon={faExclamationCircle}
                />
              </div>
            )}
          </div>
        )}

        {shouldKOLFillPrice && !job.isConfirmQuotation && (
          <>
            <div className="block my-4">
              <label className="text-gray-700 mb-2 block">
                <Trans>Contract.price</Trans>
              </label>
              <input
                type="number"
                disabled={
                  !job.isSubmitQuotation ||
                  getAuthUID(paramList) === job.CustomerID ||
                  job.accepted
                }
                id={"Contract_QuotationPriceKOL"}
                className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                placeholder="price"
                value={quotationObj.price}
                min={0}
                max={999999}
                onChange={(e) => {
                  if (
                    parseInt(e.target.value) < 0 ||
                    parseInt(e.target.value) > 999999
                  ) {
                  } else {
                    if (
                      parseInt(e.target.value) >= 1 &&
                      parseInt(e.target.value) < 10
                    )
                      setAlert(t("Contract.price-warning") as string)
                    else setAlert("")
                    if (e.target.value === "") e.target.value = "0"
                    setQuotationObj({
                      ...quotationObj,
                      price: parseInt(e.target.value),
                    })
                  }
                }}
              />
            </div>

            <div className="block my-4">
              <input
                type="checkbox"
                disabled={
                  !job.isSubmitQuotation ||
                  getAuthUID(paramList) === job.CustomerID ||
                  job.accepted
                }
                id={"Contract_CheckboxAcceptTerms"}
                className="inline-block px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-8 sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                placeholder="price"
                checked={isConfirmQG}
                onChange={(e) => {
                  if (!job.accepted) {
                    setIsConfirmQG(e.target.checked)
                  }
                }}
              />
              <label className="inline-block text-gray-700 mb-2">
                <Trans>Contract.confirm-qg</Trans>
              </label>
            </div>

            <button
              id={"Contract_SendQuotationKOL"}
              className={[
                "btn btn-outline btn-info",
                isConfirmQG
                  ? "bg-indigo-100 text-indigo-800"
                  : "bg-gray-100 text-gray-400",
              ].join(" ")}
              disabled={!isConfirmQG || job.accepted}
              onClick={() => {
                submitQuotation()
              }}>
              {isSubmittingQuotation ? (
                <Spinner />
              ) : (
                <Trans>Contract.compete-quotation</Trans>
              )}
            </button>
            {alert !== "" && (
              <div className="py-4">
                <CustomAlert
                  title={alert}
                  alertType={ALERT_TYPE.ERROR}
                  buttonType={{
                    type: BUTTON_TYPE.NONE,
                  }}
                  icon={faExclamationCircle}
                />
              </div>
            )}
          </>
        )}
      </div>
    )
  }
  if (params.metaPath === "none") {
    return <></>
  }
  return <div>Loading...</div>
}
export default QuotationPage
