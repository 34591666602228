import {
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import { Trans } from "react-i18next"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { KOLCombineWithAvatar } from "../../Model/KOLCombine"
import { getUserLang } from "../../Utils/utiltyHelper"

const QuotationHeader = () => {
  const history = useHistory()

  return (
    <div className="shadow relative">
      <section className="px-4 py-4 flex flex-row items-center gap-2">
        <button
          className="text-gray-700 "
          onClick={() => history.push("/" + getUserLang() + "/s")}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <h1 className="text-lg text-gray-700 font-medium">
          <Trans>QuotationPage.mobile-header</Trans>
        </h1>
      </section>
    </div>
  )
}

export default QuotationHeader
