import {
  faCircle,
  faCircleCheck,
  faXmarkCircle,
} from "@fortawesome/free-regular-svg-icons"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { t } from "i18next"
import React, { useEffect, useState } from "react"
import { Trans } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import NewAlert from "../../../Component/NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../../Enum/ALERT_SYSYEM"
import { createGroupQuotation } from "../../../HelpingFunction/ProjectManagement/ProjectDBHelper"
import { Project, ProjectGroupProgress } from "../../../Model/ProjectManagement"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"

const GroupQuoteProgress = () => {
  const dispatch = useDispatch()

  const project: Project = useSelector((state: any) => {
    return state.ProjectManager.currentProject
  })
  const groupProgress = useSelector((state: any) => {
    return state.ProjectManager.groupProgress
  })
  const tableKOLs: string[] = useSelector((state: any) => {
    return state.ProjectManager.tableSelectedKOLs
  })
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const contractDetails: { title: string; description: string } = useSelector(
    (state: any) => {
      return state.ProjectManager.groupContractDetails
    }
  )

  const setGroupProgress = (progress: ProjectGroupProgress) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_GROUP_QUOTATION_PROGRESS,
      data: progress,
    })
  }

  const setProjectLoaded = (loaded: boolean) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_PROJECT_LOADED,
      data: loaded,
    })
  }
  const closeModal = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.CLOSE_MODAL,
    })
  }

  const setProject = (project: Project) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CURRENT_PROJECT,
      data: project,
    })
  }

  const [alert, setAlert] = useState<{ show: boolean; message: string }>({
    show: false,
    message: "",
  })

  useEffect(() => {
    callGroupQuotation()
  }, [])

  const callGroupQuotation = async () => {
    setProjectLoaded(false)
    await createGroupQuotation(
      project.id as string,
      tableKOLs,
      auth.uid,
      contractDetails,
      setGroupProgress,
      setProject
    ).then((res) => {
      if (res.success) {
        console.log("project updated")
      } else {
        setProjectLoaded(true)
        setAlert({
          show: true,
          message: t("RecomProgress.failed-to-create"),
        })
      }
    })
  }

  return (
    <div className="mt-8 w-full">
      <div className="text-center block">
        <h2 className="font-medium text-lg text-gray-700">
          <Trans>GroupQuoteProject.progress-title</Trans>
        </h2>
        <div className="px-4 my-6 mx-auto w-80">
          <div className="flex flex-row my-4">
            <p className="text-primary ml-2 mr-4 text-3xl">
              {groupProgress < 1 && <FontAwesomeIcon icon={faCircle} />}
              {groupProgress === 1 && (
                <FontAwesomeIcon className="animate-spin" icon={faSpinner} />
              )}
              {groupProgress > 1 && <FontAwesomeIcon icon={faCircleCheck} />}
            </p>
            <p
              className={classNames("my-auto text-lg", {
                "text-gray-500": groupProgress >= 1,
                "text-black": groupProgress < 1,
                "font-bold": groupProgress === 1,
              })}>
              <Trans>RecomProgress.chatroom-create</Trans>
            </p>
          </div>
          <div className="flex flex-row my-4">
            <p className="text-primary ml-2 mr-4 text-3xl">
              {groupProgress < 2 && <FontAwesomeIcon icon={faCircle} />}
              {groupProgress === 2 && (
                <FontAwesomeIcon className="animate-spin" icon={faSpinner} />
              )}
              {groupProgress > 2 && <FontAwesomeIcon icon={faCircleCheck} />}
            </p>
            <p
              className={classNames("my-auto text-lg", {
                "text-gray-500": groupProgress >= 2,
                "text-black": groupProgress < 2,
                "font-bold": groupProgress === 2,
              })}>
              <Trans>RecomProgress.jobs-create</Trans>
            </p>
          </div>
          <div className="flex flex-row my-4">
            <p className="text-primary ml-2 mr-4 text-3xl">
              {groupProgress < 3 && <FontAwesomeIcon icon={faCircle} />}
              {groupProgress === 3 && (
                <FontAwesomeIcon className="animate-spin" icon={faSpinner} />
              )}
              {groupProgress > 3 && <FontAwesomeIcon icon={faCircleCheck} />}
            </p>
            <p
              className={classNames("my-auto text-lg", {
                "text-gray-500": groupProgress >= 3,
                "text-black": groupProgress < 3,
                "font-bold": groupProgress === 3,
              })}>
              <Trans>RecomProgress.project-update</Trans>
            </p>
          </div>
        </div>

        <div className="text-center mt-16">
          <button
            onClick={() => closeModal()}
            className={classNames("btn normal-case w-48", {
              "btn-disabled": groupProgress < 4,
              "btn-primary": groupProgress === 4,
            })}>
            <Trans>GroupQuoteProject.done</Trans>
          </button>
        </div>
        {alert.show && (
          <div id="recom-alert">
            <NewAlert
              title={t(alert.message)}
              alertType={ALERT_TYPE.ERROR}
              buttonType={{
                type: BUTTON_TYPE.NONE,
              }}
              icon={faXmarkCircle}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default GroupQuoteProgress
