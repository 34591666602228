import React, { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBookmark,
  faBriefcase,
  faCircleCheck,
  faComment,
  faHeart,
  faPaperPlane,
  faUpload,
  faXmarkSquare,
} from "@fortawesome/free-solid-svg-icons"
import { onUploadFile } from "../../HelpingFunction/FirebaseStorage"
import classNames from "classnames"
import igIcon from "../../Assets/instagramIcon.png"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../Utils/ModalType"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { getUserLang } from "../../Utils/utiltyHelper"
import { getAuthUID } from "../../Utils/QueryHelper"

const EditCaseStudyItem = (props) => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const params = useSelector((state) => {
    return state.QueryManager.params
  })

  const toggleFBModal = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.CASE_STUDY_IG_MODAL,
      returnFn: null,
      metaData: { caseStudy: props.data },
    })
  }
  const [isLoaded, setIsLoaded] = useState(true)
  const [isImgUploading, setIsImgUploading] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [isPublish, setIsPublish] = useState(false)

  useEffect(() => {
    checkVerified()
    checkPublished()
  }, [])

  useEffect(() => {
    checkVerified()
    checkPublished()
  }, [props])

  const uploadImage = async (e) => {
    setIsImgUploading(true)
    let allFile = []
    Array.from(e.target.files).forEach((file) => allFile.push(file))
    const uploadState = await onUploadFile(
      allFile,
      getAuthUID(params),
      "Member/" + getAuthUID(params) + "/CaseStudy/" + props.data.id,
      {
        width: 1024,
        height: 1024,
      }
    )
    if (uploadState.success) {
      let newImages = props.data.imageUrl.concat(
        uploadState.data.map((e) => e.url)
      )
      props.updateCaseStudy({
        ...props.data,
        imageUrl: newImages,
      })
      setIsImgUploading(false)
    }
  }

  const removeImg = async (imgUrl) => {
    let newImages = props.data.imageUrl
    let imgIndex = newImages.findIndex((item) => item === imgUrl)
    newImages.splice(imgIndex, 1)
    props.updateCaseStudy({
      ...props.data,
      imageUrl: newImages,
    })
    setIsLoaded(false)
    setTimeout(() => {
      setIsLoaded(true)
    }, 100)
  }

  const checkVerified = () => {
    let haveField = props.data.hasOwnProperty("verified")
    if (haveField) {
      if (props.data.verified) {
        setIsVerified(true)
      } else {
        setIsVerified(false)
      }
    } else {
      setIsVerified(false)
    }
  }
  const checkPublished = () => {
    let haveField = props.data.hasOwnProperty("isPublish")
    if (haveField) {
      if (props.data.isPublish) {
        setIsPublish(true)
      } else {
        setIsPublish(false)
      }
    } else {
      setIsPublish(false)
    }
  }

  const handlePublish = () => {
    let haveField = props.data.hasOwnProperty("isPublish")
    if (haveField) {
      setIsPublish(!isPublish)
      props.updateCaseStudy({
        ...props.data,
        isPublish: !isPublish,
      })
    } else {
      setIsPublish(true)
      props.updateCaseStudy({
        ...props.data,
        isPublish: true,
      })
    }
  }

  return (
    <div
      className={classNames(
        "mx-auto relative rounded-2xl shadow-lg border border-gray-200 my-2 max-w-xs",
        { "invisible hidden": props.currentCase !== props.data.id }
      )}>
      <div className="p-3 bg-gray-100 border-b-2 border-gray-200 rounded-tl-xl rounded-tr-xl grid grid-cols-7">
        <div className={props.data.jobID ? "col-span-5" : "col-span-6"}>
          <span>#{props.index + 1}</span>
          {" - "}
          <span className="pl-2 text-gray-600 break-words">
            {props.data.title}
          </span>
        </div>
        {props.data.jobID && (
          <div
            className="col-span-1 text-center text-amber-900 tooltip tooltip-bottom"
            data-tip={t("EditCaseStudy.connected-job")}>
            <button
              onClick={() =>
                history.push({
                  pathname: "/" + getUserLang() + "/c",
                  search:
                    "?" +
                    new URLSearchParams({
                      chatroomID: "undefined",
                      type: "contract",
                      metaPath: props.data.jobID,
                    }),
                })
              }>
              <FontAwesomeIcon icon={faBriefcase} />
            </button>
          </div>
        )}

        <div
          className="col-span-1 text-center tooltip tooltip-bottom"
          data-tip={
            isVerified
              ? t("EditCaseStudy.verified-post")
              : t("EditCaseStudy.connect-to-instagram")
          }>
          {isVerified ? (
            <FontAwesomeIcon
              className="text-green-600 text-xl"
              icon={faCircleCheck}
            />
          ) : (
            <button onClick={() => toggleFBModal()}>
              <img className="w-8 h-8" src={igIcon} />
            </button>
          )}
        </div>
      </div>

      <div className="p-3">
        <div className="my-2 grid grid-cols-2">
          <p className="text-sm my-0.5">
            <Trans>EditCaseStudy.publish</Trans>
          </p>
          <div className="justify-self-end">
            <input
              type="checkbox"
              className="toggle"
              checked={isPublish}
              onChange={() => handlePublish()}
            />
          </div>
        </div>
        <hr />
        <div className="block my-2">
          <div className="text-sm my-0.5">
            <Trans>EditCaseStudy.case-study-title</Trans>
          </div>

          <input
            id={"EditCaseStudyItem_CSName"}
            type="text"
            className={[
              "p-2 w-full text-sm rounded border focus:ring-gray-500 focus:border-gray-900",
              props.data.title === ""
                ? "border-2 border-red-400"
                : "border border-gray-400",
            ].join(" ")}
            value={props.data.title}
            placeholder={t("EditCaseStudy.title")}
            onChange={(e) => {
              if (e.target.value.length <= 50) {
                props.updateCaseStudy({
                  ...props.data,
                  title: e.target.value,
                })
              }
            }}
          />

          {props.data.title === "" && (
            <div className="text-sm text-red-400 my-2">
              <Trans>ErrorList.case-study-title-should-not-be-empty</Trans>
            </div>
          )}
        </div>

        <div className="relative block my-6 mb-2">
          <div className="text-sm mt-4 mb-0.5">
            <Trans>EditCaseStudy.case-study-image</Trans>
          </div>

          <button
            id={"EditCaseStudyItem_CSImage"}
            disabled={isVerified}
            className={[
              isImgUploading
                ? "loading btn btn-primary btn-sm absolute right-2 -top-2 w-24 pl-4 my-2 py-2 rounded font-normal"
                : "btn btn-primary btn-sm absolute right-2 -top-2 w-42 my-2 py-2 rounded font-normal",
            ].join()}>
            {isImgUploading ? (
              <div />
            ) : (
              <>
                <label
                  htmlFor={"file-input-case-study" + props.index}
                  className="cursor-pointer px-6">
                  <FontAwesomeIcon icon={faUpload} className="mr-2" />
                  <Trans>IGInsightUploader.upload</Trans>
                </label>
                <input
                  id={"file-input-case-study" + props.index}
                  type="file"
                  className="hidden "
                  disabled={isImgUploading}
                  accept=".png,.jpg,.svg"
                  multiple={true}
                  onChange={(e) => uploadImage(e)}
                />
              </>
            )}
          </button>
        </div>

        <div className="mt-4 mb-8 w-full whitespace-nowrap overflow-x-scroll">
          {isLoaded &&
            props.data.imageUrl.map((img, index) => {
              return (
                <div
                  key={"img" + index}
                  className="inline-grid border rounded-xl m-0.5 w-32"
                  style={{
                    gridTemplateRows: "auto 25px",
                    maxHeight: "360px",
                    maxWidth: "100%",
                  }}>
                  <img
                    className="object-contain inline-block  object-cover rounded-tl-xl rounded-tr-xl "
                    style={{ maxHeight: "300px", maxWidth: "100%" }}
                    src={img}
                  />
                  <button
                    className="btn btn-sm btn-primary w-full p-1 rounded-none	rounded-bl-xl rounded-br-xl"
                    disabled={isVerified}
                    onClick={() => removeImg(img)}>
                    <Trans>IGInsightUploader.delete</Trans>
                  </button>
                </div>
              )
            })}
        </div>

        <hr className="my-4" />

        <div className="text-sm my-4">
          <Trans>EditCaseStudy.case-study-result</Trans>
        </div>

        <div className=" grid grid-cols-4 gap-1 text-center">
          {/*ig like count*/}
          <div className="col-span-1">
            <div className="block">
              <FontAwesomeIcon icon={faHeart} className="" />
            </div>
            <input
              id={"EditCaseStudyItem_CSLikes"}
              type="tel"
              className="p-2 w-full text-sm rounded border focus:ring-gray-500 focus:border-gray-900"
              disabled={isVerified}
              value={props.data.ig_like}
              min={0}
              max={5000000}
              onChange={(e) => {
                if (parseInt(e.target.value) >= 0) {
                  props.updateCaseStudy({
                    ...props.data,
                    ig_like: parseInt(e.target.value.replace(".", "")),
                  })
                } else {
                  props.updateCaseStudy({
                    ...props.data,
                    ig_like: "",
                  })
                }
              }}
            />
          </div>
          {/*ig comment count*/}
          <div className="col-span-1">
            <div className="block">
              <FontAwesomeIcon icon={faComment} className="" />
            </div>
            <input
              id={"EditCaseStudyItem_CSComment"}
              type="tel"
              className="p-2 w-full text-sm rounded border focus:ring-gray-500 focus:border-gray-900"
              disabled={isVerified}
              value={props.data.ig_comment}
              min={0}
              max={5000000}
              onChange={(e) => {
                if (parseInt(e.target.value) >= 0) {
                  props.updateCaseStudy({
                    ...props.data,
                    ig_comment: parseInt(e.target.value.replace(".", "")),
                  })
                } else {
                  props.updateCaseStudy({
                    ...props.data,
                    ig_comment: "",
                  })
                }
              }}
            />
          </div>

          {/*ig share count*/}
          <div className="col-span-1">
            <div className="block">
              <FontAwesomeIcon icon={faPaperPlane} className="" />
            </div>
            <input
              id={"EditCaseStudyItem_CSShare"}
              type="tel"
              className="p-2 w-full text-sm rounded border focus:ring-gray-500 focus:border-gray-900"
              disabled={isVerified}
              value={props.data.ig_share}
              min={0}
              max={5000000}
              onChange={(e) => {
                if (parseInt(e.target.value) >= 0) {
                  props.updateCaseStudy({
                    ...props.data,
                    ig_share: parseInt(e.target.value.replace(".", "")),
                  })
                } else {
                  props.updateCaseStudy({
                    ...props.data,
                    ig_share: "",
                  })
                }
              }}
            />
          </div>

          {/*ig save count*/}
          <div className="col-span-1">
            <div className="block">
              <FontAwesomeIcon icon={faBookmark} className="" />
            </div>
            <input
              id={"EditCaseStudyItem_CSSave"}
              type="tel"
              className="p-2 w-full text-sm rounded border focus:ring-gray-500 focus:border-gray-900"
              disabled={isVerified}
              value={props.data.ig_save}
              min={0}
              max={5000000}
              onChange={(e) => {
                if (parseInt(e.target.value) >= 0) {
                  props.updateCaseStudy({
                    ...props.data,
                    ig_save: parseInt(e.target.value.replace(".", "")),
                  })
                } else {
                  props.updateCaseStudy({
                    ...props.data,
                    ig_save: "",
                  })
                }
              }}
            />
          </div>
        </div>

        <div className="grid grid-cols-3 mb-8 gap-2">
          <div className="block mt-2">
            <input
              id={"EditCaseStudyItem_CSEngagement"}
              type="tel"
              className="p-2 w-full rounded text-sm font-bold border focus:ring-gray-500 focus:border-gray-900"
              disabled={isVerified}
              value={props.data.ig_total_engagement}
              min={0}
              max={5000000}
              onChange={(e) => {
                if (parseInt(e.target.value) >= 0) {
                  props.updateCaseStudy({
                    ...props.data,
                    ig_total_engagement: parseInt(
                      e.target.value.replace(".", "")
                    ),
                  })
                } else {
                  props.updateCaseStudy({
                    ...props.data,
                    ig_total_engagement: "",
                  })
                }
              }}
            />

            <div className="block w-full text-center font-light text-sm text-gray-400">
              <Trans>EditCaseStudy.ig-total-engagement</Trans>
            </div>
          </div>
          <div className="block mt-2 ">
            <input
              id={"EditCaseStudyItem_CSAction"}
              type="tel"
              className="p-2 w-full rounded text-sm font-bold border focus:ring-gray-500 focus:border-gray-900"
              disabled={isVerified}
              value={props.data.ig_total_action}
              min={0}
              max={5000000}
              onChange={(e) => {
                if (parseInt(e.target.value) >= 0) {
                  props.updateCaseStudy({
                    ...props.data,
                    ig_total_action: parseInt(e.target.value.replace(".", "")),
                  })
                } else {
                  props.updateCaseStudy({
                    ...props.data,
                    ig_total_action: "",
                  })
                }
              }}
            />

            <div className="block w-full text-center font-light text-sm text-gray-400">
              <Trans>EditCaseStudy.ig-total-action</Trans>
            </div>
          </div>
          <div className="block mt-2">
            <input
              id={"EditCaseStudyItem_CSReach"}
              type="tel"
              className="p-2 w-full rounded text-sm font-bold border focus:ring-gray-500 focus:border-gray-900"
              disabled={isVerified}
              value={props.data.ig_reach}
              min={0}
              max={5000000}
              onChange={(e) => {
                if (parseInt(e.target.value) >= 0) {
                  props.updateCaseStudy({
                    ...props.data,
                    ig_reach: parseInt(e.target.value.replace(".", "")),
                  })
                } else {
                  props.updateCaseStudy({
                    ...props.data,
                    ig_total_engagement: "",
                  })
                }
              }}
            />

            <div className="block w-full text-center font-light text-sm text-gray-400">
              <Trans>EditCaseStudy.ig-total-reach</Trans>
            </div>
          </div>
        </div>
      </div>
      {!props.data.jobID && (
        <button
          id={"EditCaseStudyItem_RemoveCaseStudy"}
          className="btn btn-link absolute right-2 bottom-0 text-sm  text-red-900"
          onClick={() => props.removeCaseStudy(props.data.id)}>
          <FontAwesomeIcon icon={faXmarkSquare} className="mr-2" />
          <Trans>EditCaseStudy.remove</Trans>
        </button>
      )}
    </div>
  )
}
export default EditCaseStudyItem
