import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation, withRouter } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"
import { isAuth } from "../../Utils/FirebaseUtils"
import { CompanySize } from "../../Utils/RegFormData"
import { validateEmail } from "../../Utils/FormValidate"
import { NEW_CUSTOMER } from "../../Enum/LOGIN_PAGE_TYPE"
import {
  createCustomer,
  setCustomer,
} from "../../HelpingFunction/Customer/customerDBHelper"
import { checkEmailExist } from "../../HelpingFunction/Member/memberDBHelper"
import { RegisterPageType } from "../../pages/register"
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons"
import CustomAlert from "../../Component/NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../Enum/ALERT_SYSYEM"
import classNames from "classnames"
import { getUserLang } from "../../Utils/utiltyHelper"
import { REGISTER_CUSTOMER_HOW_TO } from "../../Utils/HelpButtonHelperFunction"
import { TIP_DIRECTION } from "../../Enum/HELP_BTN_SYSYEM"
import CustomHelpButton from "../../Component/NewHelpButton"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../Utils/ModalType"
import { logEvent } from "firebase/analytics"
import { analytics } from "../../Config/firebase"

const CustomerRegForm = (props: any) => {
  const { t } = useTranslation()
  let history = useHistory()
  const dispatch = useDispatch()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const openLoginModal = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.LOGIN_LINKTREE_MODAL,
    })
  }
  const [newCustomer, setNewCustomer] = useState<NEW_CUSTOMER>({
    contactEmail: "",
    avatarUrl: "",
    companyName: "",
    companySize: "1",
    roleInCompany: "",
    companyType: "",
    firstName: "",
    lastName: "",
    contactNumber: "",
  })
  const [password, setPassword] = useState<string>("")
  const [reTypePassword, setReTypePassword] = useState<string>("")
  const [error, setError] = useState<{
    show: boolean
    value: string
  }>({
    show: false,
    value: "",
  })

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  useEffect(() => {
    if (isAuth(auth)) {
      history.push("/" + getUserLang())
    }
  }, [auth])

  useEffect(() => {
    if (isAuth(auth)) {
      setNewCustomer({
        ...newCustomer,
        contactEmail: auth.email,
        uid: auth.uid,
      })
    }
  }, [auth])

  const submitCustomer = async (): Promise<void> => {
    setIsSubmitting(true)
    if (!newCustomer.companySize) {
      setNewCustomer({
        ...newCustomer,
        companySize: "1",
      })
    }
    if (isAuth(auth)) {
      //logged in through social login
      await setCustomer(auth.uid, newCustomer)
        .then((r) => {
          if (r.success) {
            setIsSubmitting(false)
            props.setPageType(RegisterPageType.REQUIRE_LOGIN)
            localStorage.setItem("look4kol-require-logout", "true")
            localStorage.setItem("look4kol-require-login", "true")

            if (isJobPreviewPage) {
              props.closeModal()
            } else {
              setTimeout(() => {
                history.push({
                  pathname:
                    "/" +
                    (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
                    "/login",
                })
              }, 5000)
            }
          }
        })
        .catch((err) => console.log(err))
    } else {
      const checkEmailState = await checkEmailExist(newCustomer.contactEmail)
      if (
        validateEmail(newCustomer.contactEmail) &&
        !checkEmailState.exists &&
        password.length >= 8 &&
        password === reTypePassword &&
        newCustomer.contactEmail !== "" &&
        newCustomer.companyName !== ""
      ) {
        await createCustomer(newCustomer, password)
          .then((r) => {
            if (r.success) {
              localStorage.setItem("look4kol-require-logout", "true")
              localStorage.setItem("look4kol-require-login", "true")

              logEvent(analytics, "registerCustomer", {})

              if (isJobPreviewPage) {
                setTimeout(() => {
                  setIsSubmitting(false)
                  props.closeModal()
                  openLoginModal()
                }, 3000)
              } else {
                setIsSubmitting(false)
                props.setPageType(RegisterPageType.REQUIRE_LOGIN)
                setTimeout(() => {
                  history.push({
                    pathname:
                      "/" +
                      (localStorage.getItem("i18n-lang") === "en"
                        ? "en"
                        : "hk") +
                      "/login",
                  })
                }, 5000)
              }
            } else {
              // console.log(finalResponse.message)
            }
          })
          .catch((err) => console.log(err))
      } else if (newCustomer.contactEmail === "") {
        setIsSubmitting(false)
        setError({ show: true, value: t("ErrorList.email-is-required") })
      } else if (newCustomer.companyName === "") {
        setIsSubmitting(false)
        setError({ show: true, value: t("ErrorList.company-name-required") })
      } else if (!validateEmail(newCustomer.contactEmail)) {
        setIsSubmitting(false)
        setError({ show: true, value: t("ErrorList.reg-kol-email-not-valid") })
      } else if (checkEmailState.exists) {
        setIsSubmitting(false)
        setError({ show: true, value: t("ErrorList.email-already-exist") })
      } else if (password.length < 8) {
        setIsSubmitting(false)
        setError({
          show: true,
          value: t("ErrorList.password-must-at-least-eight-char"),
        })
      } else if (password !== reTypePassword) {
        setIsSubmitting(false)
        setError({ show: true, value: t("ErrorList.password-is-not-the-same") })
      }
    }
  }

  const isJobPreviewPage = location.pathname.includes("/job-preview")

  return (
    <div className="w-full min-h-screen py-6 flex flex-col justify-center ">
      <div className="relative  w-full">
        <div>
          <div className="mx-auto border rounded-lg p-3">
            <div className="block pl-2 font-semibold text-xl self-start text-gray-700">
              <h2 className="leading-relaxed">
                <Trans>RegForm.register-as-customer</Trans>
                <CustomHelpButton
                  helpDoc={REGISTER_CUSTOMER_HOW_TO}
                  tipDirection={TIP_DIRECTION.RIGHT}
                />
              </h2>
              <p className="text-sm text-gray-500 font-normal leading-relaxed">
                <Trans>RegForm.register-as-customer-des</Trans>
              </p>
            </div>
          </div>
          <div>
            <div className="pb-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7 pt-4">
              {!isAuth(auth) && (
                <>
                  <div className="flex flex-col">
                    <label className="leading-loose">
                      <Trans>RegForm.email</Trans>
                    </label>
                    <input
                      id={"CustomerRegForm_Email"}
                      type="text"
                      className="input input-bordered w-full"
                      placeholder={t("RegForm.email")}
                      value={newCustomer.contactEmail}
                      onChange={(e) => {
                        setNewCustomer({
                          ...newCustomer,
                          contactEmail: e.target.value,
                        })
                      }}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="leading-loose">
                      <Trans>RegForm.password</Trans>
                      <span className="block text-xs text-gray-500 mb-2">
                        <Trans>RegForm.password-des</Trans>
                      </span>
                    </label>
                    <input
                      id={"CustomerRegForm_Password"}
                      type="password"
                      className="input input-bordered w-full"
                      placeholder={t("RegForm.password")}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="leading-loose">
                      <Trans>RegForm.re-type-password</Trans>
                    </label>
                    <input
                      id={"CustomerRegForm_RetypePassword"}
                      type="password"
                      className="input input-bordered w-full"
                      placeholder={t("RegForm.re-type-password")}
                      value={reTypePassword}
                      onChange={(e) => setReTypePassword(e.target.value)}
                    />
                  </div>
                </>
              )}

              <div className="grid grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label className="leading-loose">
                    <Trans>RegForm.last-name</Trans>
                  </label>
                  <input
                    id={"CustomerRegForm_LastName"}
                    type="text"
                    className="input input-bordered w-full max-w-sm"
                    placeholder={t("RegForm.last-name")}
                    value={newCustomer.lastName}
                    onChange={(e) =>
                      setNewCustomer({
                        ...newCustomer,
                        lastName: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="flex flex-col">
                  <label className="leading-loose">
                    <Trans>RegForm.first-name</Trans>
                  </label>
                  <input
                    id={"CustomerRegForm_FirstName"}
                    type="text"
                    className="input input-bordered w-full max-w-sm"
                    placeholder={t("RegForm.first-name")}
                    value={newCustomer.firstName}
                    onChange={(e) =>
                      setNewCustomer({
                        ...newCustomer,
                        firstName: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label className="leading-loose">
                  <Trans>RegForm.contact-number</Trans>
                </label>
                <input
                  id={"RegForm_ContactNumber"}
                  type="number"
                  className="input input-bordered w-full"
                  placeholder={t("RegForm.contact-number")}
                  value={newCustomer.contactNumber}
                  onChange={(e) => {
                    if (e.target.value.length <= 15) {
                      setNewCustomer({
                        ...newCustomer,
                        contactNumber: e.target.value,
                      })
                    }
                  }}
                />
              </div>

              <div className="flex flex-col">
                <label className="leading-loose">
                  <Trans>RegForm.company-name</Trans> *
                </label>
                <input
                  id={"CustomerRegForm_CompanyName"}
                  type="text"
                  className="input input-bordered w-full"
                  placeholder={t("RegForm.company-name")}
                  value={newCustomer.companyName}
                  onChange={(e) =>
                    setNewCustomer({
                      ...newCustomer,
                      companyName: e.target.value,
                    })
                  }
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label className="leading-loose">
                    <Trans>RegForm.role-in-company</Trans>
                  </label>
                  <input
                    id={"CustomerRegForm_RoleInCompany"}
                    type="text"
                    className="input input-bordered w-full"
                    placeholder={t("RegForm.role-in-company")}
                    value={newCustomer.roleInCompany}
                    onChange={(e) => {
                      if (e.target.value.length <= 50) {
                        setNewCustomer({
                          ...newCustomer,
                          roleInCompany: e.target.value,
                        })
                      }
                    }}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="leading-loose">
                    <Trans>RegForm.company-size</Trans>
                  </label>
                  <select
                    value={newCustomer.companySize}
                    onChange={(e: any) => {
                      setNewCustomer({
                        ...newCustomer,
                        companySize: CompanySize[e.target.selectedIndex].value,
                      })
                    }}
                    className="select select-bordered w-full">
                    {CompanySize.map((option) => {
                      return (
                        <option value={option.value}>{option.label}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>
            {error.show && (
              //ErrorAlerts
              <CustomAlert
                title={error.value}
                alertType={ALERT_TYPE.ERROR}
                buttonType={{
                  type: BUTTON_TYPE.NONE,
                }}
                icon={faCircleXmark}
              />
            )}
            <div className="pt-4 flex items-center space-x-4">
              <button
                id={"CustomerRegForm_CreateUser"}
                className={classNames("btn w-40 btn-primary mb-4", {
                  loading: isSubmitting,
                })}
                disabled={isSubmitting}
                onClick={() => {
                  submitCustomer()
                }}>
                {isSubmitting ? <div /> : <Trans>RegForm.create-profile</Trans>}
              </button>
            </div>
            <div className="text-sm flex flex-row gap-1">
              <p>
                <Trans>RegisterPage.already-account</Trans>
              </p>
              <span
                onClick={() => {
                  if (isJobPreviewPage) {
                    props.closeModal()
                    openLoginModal()
                  } else {
                    history.push(
                      "/" +
                        (localStorage.getItem("i18n-lang") === "en"
                          ? "en"
                          : "hk") +
                        "/login"
                    )
                  }
                }}
                className="text-primary cursor-pointer font-medium">
                <Trans>RegForm.login</Trans>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(CustomerRegForm)
