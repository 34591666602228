import React, { useEffect, useState } from "react"
import ContractToolBar from "./ContractToolBar"
import { Trans, useTranslation } from "react-i18next"
import ReactQuill from "react-quill"
import Spinner from "../../../Component/Spinner"
import { useDispatch, useSelector } from "react-redux"
import { CONTRACT_STEP } from "../../../Enum/CONTRACT"
import { Plan } from "../../../Model/Plan"
import { getKOL, getKOLPlans } from "../../../HelpingFunction/KOL/kolDBHelper"
import { KOLDefault, KOLType } from "../../../Model/KOL"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReactQuillEditorCss from "../../../Utils/ReactQuillEditor.module.css"

import {
  createNewContract,
  QuotationObj,
} from "../../../HelpingFunction/Job/JobDBHelper"
import { useHistory } from "react-router-dom"
import { isAuth, returnIdTokenResult } from "../../../Utils/FirebaseUtils"
import { ROLE } from "../../../Enum/APP_TYPE"
import { getUserLang } from "../../../Utils/utiltyHelper"
import { getAuthUID, getQuery } from "../../../Utils/QueryHelper"
import { v4 as uuidv4 } from "uuid"
import { createNewProduct } from "../../../HelpingFunction/EditKOLProfile/EditKOLProfile"
import { getAPIPath } from "../../../Utils/HelpingFunction"
import {
  createChatroom,
  getChatroom,
} from "../../../HelpingFunction/Chatroom/chatroomDBHelper"
import { PageType } from "../../../Model/ProjectManagement"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { Chatroom, ChatroomJobDefault } from "../../../Model/Chatroom"

interface placeholderProps {
  tempTitle?: string
  tempDescription?: string
  kolID?: string
  closeModal?: () => void
}

const QuotationDraftForm: React.FC<placeholderProps> = ({
  tempTitle,
  tempDescription,
  kolID,
  closeModal,
}) => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const paramList = useSelector((state: any) => {
    return state.QueryManager.params
  })

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })

  const chatroom = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomInfo
  })

  const [quotationObj, setQuotationObj] = useState<QuotationObj>({
    ServicePlanID: "",
    //will be the job name
    ServicePlanName: tempTitle ? tempTitle : "",
    quotationDetail: tempDescription ? tempDescription : "",
  })

  const [kol, setKOL] = useState<KOLType>(KOLDefault)
  const [isLoadedKOL, setIsLoadedKOL] = useState<boolean>(false)

  const [KOLPlan, setKOLPlan] = useState<Plan[]>([])
  const [isLoadedKOLPlan, setIsLoadedKOLPlan] = useState<boolean>(false)
  const [isSubmittingQuotation, setIsSubmittingQuotation] =
    useState<boolean>(false)
  const [isConfirmQG, setIsConfirmQG] = useState<boolean>(false)

  const [alert, setAlert] = useState<string>("")
  const [role, setRole] = useState<ROLE>(ROLE.DEFAULT)

  const [draftChatroom, setDraftChatroom] =
    useState<Chatroom>(ChatroomJobDefault)

  let params = {
    type: getQuery(paramList, "type"),
    chatroomID: getQuery(paramList, "chatroomID"),
    metaPath: getQuery(paramList, "metaPath"),
    viewAs: getQuery(paramList, "viewAs"),
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const modules = React.useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        //'image'
        ["link"],
        ["clean"],
        ["code-block"],
      ],
      // handlers: {
      //     'image': () => quillImageCallBack()
      // },
      clipboard: {
        matchVisual: false,
      },
    },
  }))

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    // 'image'
  ]

  const setChatRoomReducer = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_INFO,
      chatroomInfo: state,
    })
  }

  useEffect(() => {
    if (isAuth(auth)) {
      returnIdTokenResult().then((res) => {
        if (res.success) {
          if (params.viewAs) {
            setRole(ROLE.KOL)
          } else {
            setRole(res.role)
          }
        }
      })
    }
  }, [auth])
  const createNewChat = async (kolID: string) => {
    const customerName = await getCustomerUserName()
    createChatroom(auth.uid, kolID, kol.userName, customerName).then(
      (result) => {
        if (result.success) {
          getChatroom(result.chatroomID).then((result) => {
            if (result.success) {
              setDraftChatroom(result.data)
              setChatRoomReducer(result.data)
            }
          })
        }
      }
    )
  }

  useEffect(() => {
    if (isAuth(auth) && kolID) {
      createNewChat(kolID)
    }
  }, [kolID])

  useEffect(() => {
    if (chatroom && chatroom.id !== "") {
      let KOLID = ""

      // create contract person could be KOL itself or customer
      if (role === ROLE.KOL || params.viewAs) {
        KOLID = getAuthUID(paramList)
      } else {
        KOLID = chatroom.participateUID.find((e: string) => e !== auth.uid)
      }

      if (KOLID) {
        getKOL(KOLID).then((result) => {
          if (result.success) {
            setKOL(result.data)
            setIsLoadedKOL(true)
          }
        })

        getKOLPlans(KOLID, false).then((result) => {
          if (result.success) {
            setKOLPlan(result.data)
            setIsLoadedKOLPlan(true)
          } else {
            setIsLoadedKOLPlan(true)
          }
        })
      }
    }
  }, [chatroom, role])

  const getCustomerUserName = (): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      const getState = await returnIdTokenResult()
      if (getState.success) {
        return resolve(
          typeof getState.token.userName !== "undefined"
            ? getState.token.userName
            : ""
        )
      }
      return resolve("")
    })
  }
  const submitQuotation = async () => {
    setIsSubmittingQuotation(true)

    if (
      kol.id &&
      auth.uid &&
      quotationObj.quotationDetail !== "" &&
      quotationObj.ServicePlanName !== "" &&
      quotationObj.ServicePlanID !== "" &&
      !isInvalidPriceRange
    ) {
      const customerID = chatroom.participateUID.find((e: string) =>
        role === ROLE.CUSTOMER ? e === auth.uid : e !== getAuthUID(paramList)
      )
      const createJobState = await createNewContract(
        kol.id,
        customerID,
        role === ROLE.KOL
          ? { ...quotationObj, isConfirmQuotation: true }
          : quotationObj
      )
      if (createJobState.success && (params.chatroomID || draftChatroom.id)) {
        if (role === ROLE.CUSTOMER) {
          setIsSubmittingQuotation(false)
          history.push({
            pathname: "/" + getUserLang() + "/c",
            search:
              "?" +
              new URLSearchParams({
                chatroomID: draftChatroom.id
                  ? draftChatroom.id
                  : (params.chatroomID as string),
                type: "contract",
                metaPath: createJobState.jobID,
              }),
          })
          if (closeModal) {
            closeModal()
          }
        } else if (role === ROLE.KOL) {
          // if kol make a quotation, skip straight to contract sign up
          let newProduct = {
            id: uuidv4(),
            title: "custom product [job: " + createJobState.jobID + "]",
            isVisible: true,
            price: quotationObj.price,
          }
          const createProductState = await createNewProduct(
            newProduct,
            getAuthUID(paramList),
            params.viewAs
          )
          returnIdTokenResult().then(async (res) => {
            let headerInfo: any = {
              "Content-Type": "application/json",
              idToken: res.token,
            }
            if (params.viewAs) {
              headerInfo = {
                ...headerInfo,
                agencyID: params.viewAs,
              }
            }
            await fetch(
              getAPIPath("/api/job/" + createJobState.jobID + "/quotation"),
              {
                method: "PATCH",
                headers: headerInfo,
                body: JSON.stringify({
                  quotationObj: {
                    ...quotationObj,
                    priceID: createProductState.data.newPrice.id,
                    ServicePlanID:
                      quotationObj.ServicePlanID === "Custom"
                        ? ""
                        : quotationObj.ServicePlanID,
                  },
                }),
              }
            )
              .then((res) => res.json())
              .then((response) => {
                if (response.success) {
                  // accept quotation by default
                  returnIdTokenResult().then(async (res) => {
                    await fetch(
                      getAPIPath(
                        "/api/job/" + createJobState.jobID + "/quotation"
                      ),
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          idToken: res.token,
                          kolRequest: customerID,
                        },
                      }
                    )
                      .then((res) => res.json())
                      .then((finalResponse) => {
                        if (finalResponse.success && params.chatroomID) {
                          setIsSubmittingQuotation(false)
                          history.push({
                            pathname: "/" + getUserLang() + "/c",
                            search:
                              "?" +
                              new URLSearchParams({
                                chatroomID: params.chatroomID,
                                type: "contract",
                                metaPath: createJobState.jobID,
                              }),
                          })
                        }
                      })
                  })
                } else {
                  console.log("error")
                }
              })
          })
        }
      }
    } else {
      setIsSubmittingQuotation(false)
      //empty quotationObj.quotationDetail
      // eslint-disable-next-line no-alert,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      setAlert(t("Contract.quotation-fill-warning"))
    }
  }

  const isInvalidPriceRange = quotationObj.price
    ? quotationObj.price < 0 ||
      (quotationObj.price >= 1 && quotationObj.price < 10) ||
      quotationObj.price > 1000000
    : role !== ROLE.CUSTOMER

  return (
    <div>
      {!tempTitle && !tempDescription && (
        <div className="sticky top-0 z-10 hidden md:inline-block w-full bg-white shadow rounded-lg p-2">
          <ContractToolBar />
        </div>
      )}

      <div className="p-4 rounded-lg  bg-white">
        <div className="block my-4">
          <label className="text-gray-700 mb-2 block">
            <Trans>Contract.job-title</Trans>
          </label>
          <input
            id={"QuotaionPage_JobTitle"}
            type="text"
            className="px-4 py-3 bg-white border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            value={quotationObj.ServicePlanName}
            onChange={(e) => {
              setQuotationObj({
                ...quotationObj,
                ServicePlanName: e.target.value,
              })
            }}
          />
        </div>

        <div className="block my-4">
          <label className="text-gray-700 mb-2 block">
            <Trans>Contract.plan-select</Trans>
          </label>
          {isLoadedKOLPlan && (
            <select
              id={"QuotaionPage_PlanSelect"}
              name="plan"
              className="w-full select select-bordered mt-2"
              onChange={(event) => {
                const ets: number = event.target.selectedIndex
                // check whether is custom plan or not
                setQuotationObj({
                  ...quotationObj,
                  // ServicePlanName: KOLPlan[ets - 1].title,
                  ServicePlanID: KOLPlan[ets - 1]
                    ? KOLPlan[ets - 1].id
                    : "Custom",
                })
              }}>
              <option disabled selected>
                Choose Type
              </option>
              {KOLPlan.map((o) => {
                return (
                  <option
                    key={o.id}
                    selected={quotationObj.ServicePlanID === o.id}>
                    {o.title + " ~HKD " + o.price}
                  </option>
                )
              })}
              <option>Custom 自定</option>
            </select>
          )}
        </div>

        <div className="block mt-4 mb-6">
          <label className="text-gray-700 mb-2">
            <Trans>Contract.quotation-detail</Trans>
          </label>
          <div
            className={["my-4", ReactQuillEditorCss.ReactQuillEditor].join(" ")}
            style={{
              minHeight: "400px",
              maxHeight: "600px",
              overflow: "hidden",
            }}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore*/}
            <ReactQuill
              placeholder={
                t("Contract.kol-requirement-draft-message") as string
              }
              value={quotationObj.quotationDetail}
              onChange={(e) => {
                if (e === "<p><br></p>") {
                  e = ""
                }
                setQuotationObj({
                  ...quotationObj,
                  quotationDetail: e,
                })
              }}
              theme="snow"
              modules={modules}
              formats={formats}
            />
          </div>

          <div
            className={[
              "block float-right text-sm",
              quotationObj.quotationDetail.length >= 3000
                ? "text-red-500"
                : "text-gray-500",
            ].join(" ")}>
            {quotationObj.quotationDetail.length + " / 3000"}
          </div>
          {role === ROLE.KOL && (
            <>
              <div className="block my-4">
                <label className="text-gray-700 mb-2 block">
                  <Trans>Contract.price</Trans>
                </label>
                <input
                  type="number"
                  id={"Contract_QuotationPriceKOL"}
                  className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                  placeholder="price"
                  value={quotationObj.price ? quotationObj.price : 0}
                  min={0}
                  max={999999}
                  onChange={(e) => {
                    if (
                      parseInt(e.target.value) < 0 ||
                      parseInt(e.target.value) > 999999
                    ) {
                    } else {
                      if (
                        parseInt(e.target.value) >= 1 &&
                        parseInt(e.target.value) < 10
                      )
                        setAlert(t("Contract.price-warning") as string)
                      else setAlert("")
                      if (e.target.value === "") e.target.value = "0"
                      setQuotationObj({
                        ...quotationObj,
                        price: parseInt(e.target.value),
                      })
                    }
                  }}
                />
              </div>

              <div className="block my-4">
                <input
                  type="checkbox"
                  id={"Contract_CheckboxAcceptTerms"}
                  className="inline-block px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-8 sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                  placeholder="price"
                  checked={isConfirmQG}
                  onChange={(e) => {
                    setIsConfirmQG(e.target.checked)
                  }}
                />
                <label className="inline-block text-gray-700 mb-2">
                  <Trans>Contract.confirm-qg</Trans>
                </label>
              </div>
            </>
          )}
        </div>

        <div>
          <button
            id={"Contract_GetQuotationButton"}
            className="btn btn-outline btn-info "
            disabled={
              isSubmittingQuotation ||
              !isLoadedKOL ||
              (role === ROLE.KOL && !isConfirmQG)
            }
            onClick={() => {
              submitQuotation()
            }}>
            {isSubmittingQuotation ? (
              <Spinner />
            ) : role === ROLE.KOL ? (
              <Trans>Contract.kol-get-quotation</Trans>
            ) : (
              <Trans>Contract.get-quotation</Trans>
            )}
          </button>
          <div
            className={
              alert !== "" ? "alert alert-error shadow-lg mt-2" : "hidden"
            }>
            <div>
              <span>{alert}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default QuotationDraftForm
