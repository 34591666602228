import { Timestamp } from "firebase/firestore"
import { DateObj } from "./Utilities"

export interface Project {
  id?: string
  title: string
  startDate: Date | DateObj
  endDate: Timestamp | Date | DateObj
  budget: number
  totalCost: number
  description: string
  kolList: string[]
  jobList: string[]
  isFinish: boolean
  customerID: string
  createDate: Date | DateObj
}

export enum ProjectGroupProgress {
  START,
  CREATE_CHATROOM,
  CREATE_JOB,
  UPDATE_PROJECT,
  FINISH,
}

export enum ProjectGroupQuotePage {
  "CONTRACT",
  "PROGRESS",
}

export enum PageType {
  "NewProject",
  "Main",
  "KOL",
  "Job",
  "SelectedKOLList",
  "KOLPreview",
  "JobPreview",
  "ChatPreview",
  "ChatNotExist",
  "ProjectLinks",
}

export interface ProjectListCondition {
  isFinish: boolean | ""
  startDate: Date | DateObj | ""
  endDate: Date | DateObj | ""
  budget: number | ""
  kolID: string | ""
}

export const projectDefault: Project = {
  title: "",
  description: "",
  startDate: new Date(),
  endDate: new Date(),
  budget: 0,
  totalCost: 0,
  kolList: [],
  jobList: [],
  isFinish: false,
  customerID: "",
  createDate: new Date(),
}

export enum deleteTypeProjectItem {
  "KOL",
  "JOB",
}
