import React, { useEffect, useState } from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe, Stripe } from "@stripe/stripe-js"
import CheckOutForm from "./CheckOutFormNew"
import LinkCaseStudy from "./LinkCaseStudy"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, withRouter } from "react-router-dom"
import { isAuth, returnIdTokenResult } from "../../../Utils/FirebaseUtils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBank,
  faBusinessTime,
  faChevronRight,
  faCircleExclamation,
  faCreditCard,
  faEllipsis,
  faExclamationCircle,
  faMoneyBill,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons"
import { Trans } from "react-i18next"
import {
  ADMIN_REQUEST_STATUS_TYPE,
  ADMIN_REQUEST_TYPE,
  JOB_STATUS_TYPE,
  ROLE,
} from "../../../Enum/APP_TYPE"
import { intToFloat, timestraptoIOStime } from "../../../Utils/Converter"
import CustomAlert from "../../../Component/NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../../Enum/ALERT_SYSYEM"
import { getRole, isLocalhost } from "../../../Utils/utiltyHelper"
import CheckoutRefCode from "./CheckoutRefCode"
import ConfirmJobFinish from "./ConfirmJobFinish"
import ContractFeedbackEdit from "./ContractFeedbackEdit"
import { getAuthUID, getQuery, useQuery } from "../../../Utils/QueryHelper"
import { Job } from "../../../Model/Job"
import { DateObj } from "../../../Model/Utilities"
import { getJobAdminRequest } from "../../../HelpingFunction/AdminRequest/AdminRequestDBHelper"
import { ADMIN_REQUEST } from "../../../Model/AdminRequest"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import classNames from "classnames"
import { CHECKOUT_PAGE_TYPE } from "../../../Enum/CONTRACT"
import BankCheckout from "./BankCheckout"
import StripeCheckout from "./StripeCheckout"
import PendingPaymentHelp from "./PendingPaymentHelp"
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const testKey = process.env.REACT_APP_STRIPE_TEST_PUB_KEY
const prodKey = process.env.REACT_APP_STRIPE_PROD_PUB_KEY

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const stripePromise: Stripe | null = loadStripe(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  isLocalhost() ? testKey : prodKey
)

const CheckoutPage: React.FC = (props: any) => {
  let history = useHistory()
  const dispatch = useDispatch()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const job: Job = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomJob
  })

  const params = useSelector((state: any) => {
    return state.QueryManager.params
  })

  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [role, setRole] = useState<ROLE>(ROLE.KOL)
  const [isLoaded, setIsLoaded] = useState(false)

  const viewID = getQuery(params, "viewAs")

  useEffect(() => {
    const getRoleFn = async () => {
      const role = await getRole(viewID)
      setRole(role)
    }

    getRoleFn()
  }, [auth])

  useEffect(() => {
    if (isAuth(auth)) {
      returnIdTokenResult().then((res) => {
        if (res.success) {
          setIsAdmin(res.token.type === "administrator")
        }
      })
    }
  }, [auth])

  useEffect(() => {
    if (isAuth(auth) && job.id !== "") {
      getJobAdminRequest(job.id).then((res) => {
        if (res.success) {
          setAdminRequest(res.data[0])
          setIsLoaded(true)
        } else {
          setAdminRequest(null)
          setIsLoaded(true)
        }
      })
    }
  }, [job])

  const setAdminRequest = (request: ADMIN_REQUEST | null) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_ADMIN_REQUEST,
      data: request,
    })
  }

  const returnAlertSystem = (request: ADMIN_REQUEST | null) => {
    if (request === null) {
      return (
        <CustomAlert
          title={"Contract.call-for-pay"}
          alertType={ALERT_TYPE.PAYMENT}
          buttonType={{
            type: BUTTON_TYPE.NONE,
          }}
          icon={faMoneyBill}
        />
      )
    }
    if (request.type === ADMIN_REQUEST_TYPE.BANK_CHECKOUT) {
      if (request.status === ADMIN_REQUEST_STATUS_TYPE.PENDING) {
        return (
          <CustomAlert
            title={"Contract.bank_checkout_pending"}
            alertType={ALERT_TYPE.INFO}
            buttonType={{
              type: BUTTON_TYPE.NONE,
            }}
            icon={faCircleExclamation}
          />
        )
      } else if (request.status === ADMIN_REQUEST_STATUS_TYPE.REJECT) {
        return (
          <CustomAlert
            title={"Contract.bank_checkout_reject"}
            alertType={ALERT_TYPE.ERROR}
            buttonType={{
              type: BUTTON_TYPE.NONE,
            }}
            icon={faTriangleExclamation}
          />
        )
      }
    } else if (request.type === ADMIN_REQUEST_TYPE.PAY_LATER_REQUEST) {
      if (request.status === ADMIN_REQUEST_STATUS_TYPE.PENDING) {
        return (
          <CustomAlert
            title={"Contract.pay_later_pending"}
            alertType={ALERT_TYPE.INFO}
            buttonType={{
              type: BUTTON_TYPE.NONE,
            }}
            icon={faCircleExclamation}
          />
        )
      } else if (request.status === ADMIN_REQUEST_STATUS_TYPE.REJECT) {
        return (
          <CustomAlert
            title={"Contract.pay_later_reject"}
            alertType={ALERT_TYPE.ERROR}
            buttonType={{
              type: BUTTON_TYPE.NONE,
            }}
            icon={faTriangleExclamation}
          />
        )
      }
    }
  }

  const isKOLAndAgency = job.KOLID === getAuthUID(params)

  if (!isAuth(auth)) {
    return <div>Please login</div>
  } else if (job.id && (job.CustomerID === getAuthUID(params) || isAdmin)) {
    //if this is customer, payment
    if (job.status === JOB_STATUS_TYPE.STATUS_0) {
      return (
        <div className="p-2">
          <CustomAlert
            title={"Contract.confirm-contract-and-payment"}
            alertType={ALERT_TYPE.ERROR}
            buttonType={{
              type: BUTTON_TYPE.NONE,
            }}
            icon={faExclamationCircle}
          />
          <section>
            <div className="w-full shadow rounded-xl p-6">
              <p className="text-3xl font-semibold">$ XXX</p>
              <div className="grid grid-cols-5 my-4 gap-y-2 text-sm">
                <div className="col-span-1 flex flex-col gap-2">
                  <p className="text-gray-500">
                    <Trans>RecomQuestions.to</Trans>
                  </p>
                  <p className="text-gray-500">
                    <Trans>RecomQuestions.from</Trans>
                  </p>
                </div>
                <div className="col-span-4 flex flex-col gap-2">
                  <p className="font-light">KOL Name</p>

                  <p className="font-light">Customer Name</p>
                  <hr />
                  <a
                    href="https://look4kol.com/hk/blog/terms"
                    target="_blank"
                    className="text-gray-500 font-medium cursor-pointer">
                    <Trans>CheckoutForm.view-terms</Trans>{" "}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="rounded-lg bg-white p-6 my-4 shadow">
              <section className="my-2">
                <h2 className="text-xl font-bold">
                  <Trans>CheckoutForm.select-payment</Trans>
                </h2>
                <div className="flex flex-row gap-2 justify-center">
                  <div
                    className={classNames(
                      "p-2 border rounded-lg my-4 w-[150px] text-sm cursor-not-allowed"
                    )}>
                    <FontAwesomeIcon icon={faCreditCard} />
                    <p>
                      <Trans>CheckoutForm.credit-card-checkout</Trans>
                    </p>
                  </div>
                  <div
                    className={classNames(
                      "p-2 border rounded-lg my-4 w-[150px] text-sm cursor-not-allowed"
                    )}>
                    <FontAwesomeIcon icon={faBank} />
                    <p>
                      <Trans>CheckoutForm.bank-checkout</Trans>
                    </p>
                  </div>
                  <div
                    tabIndex={0}
                    className={classNames(
                      "p-2 border rounded-lg my-4 w-[75px] text-sm cursor-not-allowed  "
                    )}>
                    <label className=" m-1">
                      {" "}
                      <FontAwesomeIcon icon={faEllipsis} />
                    </label>
                    <p>
                      <Trans>CheckoutForm.others</Trans>
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </div>
      )
    } else if (
      job.status === JOB_STATUS_TYPE.STATUS_1 ||
      job.status === JOB_STATUS_TYPE.STATUS_4
    ) {
      // if status is "1", call for payment, even the user applied pay later
      return (
        <div>
          {isLoaded && (
            <Elements stripe={stripePromise}>
              <CheckOutForm />
            </Elements>
          )}
        </div>
      )
    } else if (job.status === JOB_STATUS_TYPE.STATUS_2) {
      // if status is "1", call for payment
      return (
        <div>
          <ConfirmJobFinish isAdmin={isAdmin} job={job} />
          <CheckoutRefCode
            payRefID={job.payRefID}
            payDate={job.payDate as DateObj}
          />
          <LinkCaseStudy
            role={role}
            kolID={job.KOLID}
            caseID={job.caseID}
            jobID={job.id}
          />
        </div>
      )
    } else if (job.status === JOB_STATUS_TYPE.STATUS_3) {
      return (
        <>
          <ContractFeedbackEdit
            isAdmin={isAdmin}
            id={job.id}
            score={job.score}
            finalComment={job.finalComment}
          />
          <LinkCaseStudy
            role={role}
            kolID={job.KOLID}
            caseID={job.caseID}
            jobID={job.id}
          />
        </>
      )
    } else if (job.status === JOB_STATUS_TYPE.STATUS_5) {
      return (
        <div>
          <ConfirmJobFinish skipPayment={true} isAdmin={isAdmin} job={job} />
          <CheckoutRefCode
            skipPayment={true}
            payRefID={job.payRefID}
            payDate={job.payDate as DateObj}
          />
          <LinkCaseStudy
            role={role}
            kolID={job.KOLID}
            caseID={job.caseID}
            jobID={job.id}
          />
        </div>
      )
    }
  } else if (job.id && isKOLAndAgency) {
    //if kol, show pending
    if (job.status === JOB_STATUS_TYPE.STATUS_0) {
      return (
        <div className="w-full shadow rounded border-t-8 border-red-300 my-4 p-4 pb-12">
          <h2 className="text-2xl font-bold text-red-600 my-4">
            <FontAwesomeIcon icon={faExclamationCircle} className={"mr-2"} />
            <Trans>Contract.confirm-contract-and-payment</Trans>
          </h2>
          <p className="text-md text-red-500">
            <Trans>Contract.confirm-contract-and-payment-des</Trans>
          </p>
        </div>
      )
    } else if (job.status === JOB_STATUS_TYPE.STATUS_1) {
      return (
        <>
          {/* <CustomAlert
            title={"Contract.status-1"}
            alertType={ALERT_TYPE.WARNING}
            buttonType={{
              type: BUTTON_TYPE.NONE,
            }}
            icon={faExclamationCircle}
          /> */}
          <PendingPaymentHelp />
        </>
      )
    } else if (
      job.status === JOB_STATUS_TYPE.STATUS_2 ||
      job.status === JOB_STATUS_TYPE.STATUS_4 ||
      job.status === JOB_STATUS_TYPE.STATUS_5
    ) {
      // if status is "1", call for payment
      return (
        <div>
          <CustomAlert
            title={
              job.status === JOB_STATUS_TYPE.STATUS_5
                ? "ContractSkipPayment.kol-deal"
                : "Contract.status-2"
            }
            alertType={ALERT_TYPE.WARNING}
            buttonType={{
              type: BUTTON_TYPE.NONE,
            }}
            icon={faExclamationCircle}
          />

          <LinkCaseStudy
            role={role}
            kolID={job.KOLID}
            caseID={job.caseID ? job.caseID : ""}
            jobID={job.id}
          />
        </div>
      )
    } else if (job.status === JOB_STATUS_TYPE.STATUS_3) {
      return (
        <div>
          <div className="w-full shadow rounded border-t-8 border-green-300 p-4 pb-12">
            <h2 className="text-2xl font-bold text-green-600 my-4">
              <Trans>Contract.payout-finish</Trans>
            </h2>

            <hr className="my-4" />

            <div className="text-lg mt-6">
              <p className="my-2">
                <Trans>Contract.payout-amount</Trans>
                <div className="inline-block ml-4 p-1 rounded bg-gray-100">
                  HKD
                  <span className="ml-2">
                    {intToFloat((1 * job.payoutAmount) / 100, 2)}
                  </span>
                </div>
              </p>
              <p className="my-2">
                <Trans>Contract.payout-time</Trans>
                <span className="ml-4 p-1 rounded bg-gray-100">
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    timestraptoIOStime(job.payoutDate.seconds)
                  }
                </span>
              </p>
              <p className="my-2">
                <Trans>Contract.payout-ref-code</Trans>
                <span className="ml-4 p-1 rounded bg-gray-100">
                  # {job.payoutRefID}
                </span>
              </p>
            </div>
            <div className="w-full mb-4">
              <button
                className="btn btn-success bg-green-900 hover:bg-green-800 w-48 float-right"
                disabled={isAdmin}
                onClick={(e) => {
                  history.push(
                    "/" +
                      (localStorage.getItem("i18n-lang") === "en"
                        ? "en"
                        : "hk") +
                      "/kol/wallet"
                  )
                }}>
                <Trans>Contract.payout-setting</Trans>
              </button>
            </div>
          </div>
          <LinkCaseStudy
            role={role}
            kolID={job.KOLID}
            caseID={job.caseID}
            jobID={job.id}
          />
        </div>
      )
    }
  }
  return <div></div>
}

export default withRouter(CheckoutPage)
