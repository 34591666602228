import { getAPIPath } from "../../Utils/HelpingFunction"
import { Member } from "../../Model/Member"
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  limit,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore"
import firebaseApp from "../../Config/firebase"
import { UNREAD_MESSAGE_TYPE } from "../../Enum/APP_TYPE"
import { returnIdTokenResult } from "../../Utils/FirebaseUtils"

const db = getFirestore(firebaseApp)

/**
 * Check whether this email is registered
 * @param email
 */

const checkEmailExist = (
  email: string
): Promise<{
  success: boolean
  exists: boolean
}> => {
  return new Promise(async (resolve, reject) => {
    await fetch(getAPIPath("/api/user/emailExists"), {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
      }),
    })
      .then((res) => res.json())
      .then((finalResponse) => {
        return resolve(finalResponse)
      })
  })
}

/**
 * get number of unread message for the member
 * @param {string} uid - firebase uid
 */

const getNumOfUnreadMessage = (
  uid: string,
  msgType: UNREAD_MESSAGE_TYPE
): Promise<{
  success: boolean
  numOfUnread: number
}> => {
  return new Promise(async (resolve) => {
    let dbRef = query(
      collection(db, "Member", uid, "Message"),
      where("isRead", "==", false),
      limit(10)
    )

    switch (msgType) {
      case UNREAD_MESSAGE_TYPE.JUST_CHAT:
        dbRef = query(dbRef, where("type", "not-in", ["contract", "system"]))
        break
      case UNREAD_MESSAGE_TYPE.JUST_CONTRACT:
        dbRef = query(dbRef, where("type", "==", "contract"))
        break
      default:
        break
    }
    await onSnapshot(query(dbRef), (colSnapshot) => {
      return resolve({
        success: true,
        numOfUnread: colSnapshot.docs.length,
      })
    })
  })
}

/**
 * get member object from firebase
 * @param {string} uid - firebase uid
 */

const getMember = (
  uid: string
): Promise<
  | {
      success: true
      data: Member
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "Member", uid))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: {
              id: doc.id,
              ...doc.data(),
            } as Member,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

/**
 * update admin compare group, since customer should be the collection "Customer", only admin use "Member" collection
 * @param {string} uid - firebase uid
 * @param {object} compareGroup
 */

const updateAdminCompareGroup = (
  uid: string,
  compareGroup: [
    {
      data: string[]
      id: string
      title: string
      type: string
    }
  ]
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await updateDoc(doc(db, "Member", uid), {
      kolCompareGroup: compareGroup,
    })
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => console.log(err))
  })
}

const setMessageAsRead = (
  uid: string,
  messageID: string
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await updateDoc(doc(db, "Member", uid, "Message", messageID), {
      isRead: true,
    })
      .then((doc) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

/**
 * check whether this KOL is bookmarked
 * @param {string} id - firebase uid
 * @param { string } kolID - kol id
 */
const isKOLBookmarked = (
  id: string,
  kolID: string
): Promise<{
  success: boolean
  bookmarked: boolean
}> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "Member", id))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            bookmarked: doc.data()?.bookmark.includes(kolID),
          })
        }
        return resolve({
          success: false,
          bookmarked: false,
        })
      })
      .catch((err) => console.log(err))
  })
}

/**
 * set bookmark list of a member
 * @param {string} uid - firebase uid
 * @param { string } bookmarkedKOLs - list of kolIDs
 */
const setMemberBookMarkList = (
  uid: string,
  bookmarkedKOLs: string[]
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await updateDoc(doc(db, "Member", uid), {
      bookmark: bookmarkedKOLs,
    })
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

/**
 * createVerifyRequest
 * @param {string} uid - firebase uid
 * @param {string} email
 */
const createVerifyRequest = (
  uid: string,
  email: string
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/user/" + uid + "/requestVerify"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          email: email,
        }),
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            return resolve({ success: true })
          }
        })
    })
  })
}

/**
 * Apply account deletion
 * @param uid
 */

const applyAccountDeletion = (
  uid: string
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve, reject) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath(`/api/user/${uid}`), {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          return resolve(finalResponse)
        })
    })
  })
}

export {
  checkEmailExist,
  getNumOfUnreadMessage,
  getMember,
  updateAdminCompareGroup,
  setMessageAsRead,
  isKOLBookmarked,
  setMemberBookMarkList,
  createVerifyRequest,
  applyAccountDeletion,
}
