import { doc, getDoc, getFirestore } from "firebase/firestore"
import firebaseApp from "../../Config/firebase"
import { IG_USER_CRAWLER_DATA, IG_USER_DATA } from "../../Enum/LOGIN_PAGE_TYPE"
import { t } from "i18next"

const db = getFirestore(firebaseApp)
const getFollowersData = (
  kolID: string,
  platform: string
): Promise<{
  success: boolean
  igData?: IG_USER_CRAWLER_DATA | IG_USER_DATA
}> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "FollowersGroup", kolID, "FollowersIG", "all")).then(
      (doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            igData: { ...doc.data() } as IG_USER_DATA,
          })
        }
        return resolve({
          success: false,
        })
      }
    )
  })
}

const returnAudienceCityData = (
  audienceCityData: object,
  limit: number
): [string, number][] => {
  if (typeof audienceCityData !== "undefined") {
    let sortedData = Object.entries(audienceCityData).sort((a: any, b: any) => {
      if (a[1] < b[1]) return 1
      if (a[1] > b[1]) return -1
      return 0
    })

    let newLimit =
      Object.entries(audienceCityData).length > limit
        ? limit
        : Object.entries(audienceCityData).length

    return sortedData.slice(0, newLimit)
  }
  return []
}

const returnAudienceAgeData = (
  audienceAgeData: object,
  isPercentage: boolean
): [string, number][] => {
  let age13T17 = 0
  let age18T24 = 0
  let age25T34 = 0
  let age35T44 = 0
  let age45T54 = 0
  let age55T64 = 0
  let age65 = 0
  let totalCount = 0

  if (typeof audienceAgeData !== "undefined") {
    Object.entries(audienceAgeData).map((e) => {
      if (e[0].includes("13-17")) {
        age13T17 += e[1]
        totalCount += e[1]
      } else if (e[0].includes("18-24")) {
        age18T24 += e[1]
        totalCount += e[1]
      } else if (e[0].includes("25-34")) {
        age25T34 += e[1]
        totalCount += e[1]
      } else if (e[0].includes("35-44")) {
        age35T44 += e[1]
        totalCount += e[1]
      } else if (e[0].includes("45-54")) {
        age45T54 += e[1]
        totalCount += e[1]
      } else if (e[0].includes("55-64")) {
        age55T64 += e[1]
        totalCount += e[1]
      } else {
        age65 += e[1]
        totalCount += e[1]
      }
    })

    return [
      ["13 - 17", isPercentage ? (age13T17 / totalCount) * 100 : age13T17],
      ["18 - 24", isPercentage ? (age18T24 / totalCount) * 100 : age18T24],
      ["25 - 34", isPercentage ? (age25T34 / totalCount) * 100 : age25T34],
      ["35 - 44", isPercentage ? (age35T44 / totalCount) * 100 : age35T44],
      ["45 - 55", isPercentage ? (age45T54 / totalCount) * 100 : age45T54],
      ["55 - 64", isPercentage ? (age55T64 / totalCount) * 100 : age55T64],
      ["65+", isPercentage ? (age65 / totalCount) * 100 : age65],
    ]
  }
  return [
    ["13 - 17", 0],
    ["18 - 24", 0],
    ["25 - 34", 0],
    ["35 - 44", 0],
    ["45 - 55", 0],
    ["55 - 64", 0],
    ["65+", 0],
  ]
}

const returnAudienceGenderData = (
  audienceGenderData: object,
  isPercentage: boolean
): [string, number][] => {
  let F = 0
  let M = 0
  let U = 0
  let totalCount = 0
  if (typeof audienceGenderData !== "undefined") {
    Object.entries(audienceGenderData).map((e) => {
      if (e[0].includes("F")) {
        F += e[1]
        totalCount += e[1]
      } else if (e[0].includes("M")) {
        M += e[1]
        totalCount += e[1]
      }
      // else if (e[0].includes("U")) {
      //   U += e[1]
      //   totalCount += e[1]
      // }
    })

    return [
      [t("GenderEditRow.male"), isPercentage ? (M / totalCount) * 100 : M],
      [t("GenderEditRow.female"), isPercentage ? (F / totalCount) * 100 : F],
      // [t("GenderEditRow.other"), isPercentage ? (U / totalCount) * 100 : U],
    ]
  }
  return [
    [t("GenderEditRow.male"), 0],
    [t("GenderEditRow.female"), 0],
    // [t("GenderEditRow.other"), 0],
  ]
}

export {
  getFollowersData,
  returnAudienceCityData,
  returnAudienceAgeData,
  returnAudienceGenderData,
}
