import React, { useEffect, useState } from "react"
import { NavButton, NavButtonProps } from "./NavButton"
import {
  faBrain,
  faTag,
  faComment as faCommentAfter,
  faCrown,
  faFile as faFileAfter,
  faGear,
  faHouse,
  faIdBadge as faIdBadgeAfter,
  faLock,
  faLockOpen,
  faNewspaper as faNewspaperAfter,
  faSearch,
  faToolbox,
  faDatabase,
  faPeopleGroup,
  faUsers,
} from "@fortawesome/free-solid-svg-icons"
import {
  faComment,
  faFile,
  faFolder,
  faIdBadge,
  faNewspaper,
} from "@fortawesome/free-regular-svg-icons"
import classNames from "classnames"
import { useDispatch, useSelector } from "react-redux"
import { isAuth } from "../Utils/FirebaseUtils"
import { CHATROOM_PAGE_TYPE, ROLE } from "../Enum/APP_TYPE"
import { useTranslation } from "react-i18next"
import { SUPPORTED_REDUX_FUNCTIONS } from "../Redux/SUPPORTED_REDUX_FUNCTION"
import { getRole } from "../Utils/utiltyHelper"
import { getKOL, getKOLs } from "../HelpingFunction/KOL/kolDBHelper"
import KOL, { KOLDefault, KOLType } from "../Model/KOL"
import { Link } from "react-router-dom"
import { getAuthUID, getQuery, useQuery } from "../Utils/QueryHelper"
import { customerDefault, CustomerType } from "../Model/Customer"
import {
  getCustomer,
  getOwnedKOL,
} from "../HelpingFunction/Customer/customerDBHelper"
import { SUPPORTED_MODAL_TYPE } from "../Utils/ModalType"

export enum NavButtonGroupDir {
  ROW,
  COL,
}

interface NavButtonGroupProps {
  dir: NavButtonGroupDir
}

export const NavButtonGroup: React.FC<NavButtonGroupProps> = ({ dir }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })
  const numOfUnreadChat = useSelector((state: any) => {
    return state.MessagingSystem.numOfUnreadChats
  })
  const params = useSelector((state: any) => {
    return state.QueryManager.params
  })
  const requireUpdate = useSelector((state: any) => {
    return state.QueryManager.forceUpdate
  })

  const [role, setRole] = useState<ROLE>(ROLE.DEFAULT)
  const [kol, setKOL] = useState<KOL>(KOLDefault)
  const [customerData, setCustomerData] =
    useState<CustomerType>(customerDefault)
  const [ownedKOLData, setOwnedKOLData] = useState<KOLType[]>([])

  const viewID = getQuery(params, "viewAs")

  const returnUpdateStateDefault = () => {
    if (requireUpdate) {
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_FORCE_UPDATE,
        data: false,
      })
    }
  }

  useEffect(() => {
    if (isAuth(auth)) {
      getCustomer(auth.uid).then((resultCustomer) => {
        if (resultCustomer.success) {
          setCustomerData(resultCustomer.data)
        }
      })
      getOwnedKOL(auth.uid).then((result) => {
        if (result.success) {
          getKOLs(result.data).then((resultKOL) => {
            if (resultKOL.success) {
              setOwnedKOLData(resultKOL.data)
              returnUpdateStateDefault()
            }
          })
        }
      })
    }
  }, [auth, role, requireUpdate])
  useEffect(() => {
    const getRoleFn = async () => {
      const role = await getRole(viewID)
      if (role === ROLE.KOL) {
        getKOLDetails()
      }
      setRole(role)
    }

    getRoleFn()
  }, [auth, viewID, requireUpdate])

  const setPageType = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_PAGE_TYPE,
      data: CHATROOM_PAGE_TYPE.LIST_CHAT,
    })
  }

  const resetPageByRefresh = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.RESET_SEARCH_KOL,
      data: null,
    })
  }
  const setSwitchAccountModal = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.OWNED_KOL_SETTING_MODAL,
      returnFn: () => {
        return null
      },
      metaData: state.metaData,
    })
  }

  const uid = isAuth(auth) ? auth.uid : ""

  const isSpecialPage =
    window.location.pathname.includes("/signup") ||
    window.location.pathname.includes("/verify-phone")

  const getKOLDetails = async () => {
    await getKOL(getAuthUID(params)).then((res) => {
      if (res.success) {
        setKOL(res.data)
      }
    })
  }

  const kolNavButtons: NavButtonProps[] = [
    {
      visible: true,
      icon: faHouse,
      i18Text: t("BottomAppBar.homepage"),
      path: "",
    },
    {
      visible: screenWidth >= 768,
      icon: faTag,
      iconAfter: faTag,
      i18Text: t("BottomAppBar.kol-pro"),
      path: "kol-pro",
    },
    {
      visible: true,
      icon: faComment,
      iconAfter: faCommentAfter,
      i18Text: t("BottomAppBar.chat"),
      path: "c",
      iconUnread: numOfUnreadChat > 0,
      onClickFn: setPageType,
    },
    {
      visible: true,
      icon: faFile,
      iconAfter: faFileAfter,
      i18Text: t("BottomAppBar.job"),
      path: "j",
    },

    {
      visible: true,
      icon: faIdBadge,
      iconAfter: faIdBadgeAfter,
      i18Text: t("BottomAppBar.profile"),
      path: "p/" + kol.userName,
    },

    {
      visible: screenWidth >= 768,
      icon: faNewspaper,
      iconAfter: faNewspaperAfter,
      i18Text: t("BottomAppBar.blog"),
      path: "blog",
    },
    {
      visible: true,
      icon: faGear,
      i18Text: t("BottomAppBar.setting"),
      path: "setting",
    },
    {
      visible: viewID !== null && screenWidth < 768,
      icon: kol.avatarUrl,
      i18Text: t("OwnedKOL.title"),
      noHighlight: true,
      path: "",
      onClickFn: () => {
        setSwitchAccountModal({
          metaData: {
            KOLList: ownedKOLData,
            CustomerData: customerData,
            disableOutbounds: true,
          },
        })
      },
    },
  ]

  const customerNavButtons: NavButtonProps[] = [
    {
      visible: screenWidth < 768,
      icon: faHouse,
      i18Text: t("BottomAppBar.homepage"),
      path: "",
    },
    {
      visible: screenWidth >= 768,
      icon: faSearch,
      iconAfter: faSearch,
      i18Text: t("BottomAppBar.search"),
      path: "s",
    },
    {
      visible: true,
      icon: faComment,
      iconAfter: faCommentAfter,
      iconUnread: numOfUnreadChat > 0,
      i18Text: t("BottomAppBar.chat"),
      path: "c",
      onClickFn: setPageType,
    },
    {
      visible: true,
      icon: faFile,
      iconAfter: faFileAfter,
      i18Text: t("BottomAppBar.job"),
      path: "j",
    },
    {
      visible: screenWidth >= 768,
      icon: faBrain,
      iconAfter: faBrain,
      i18Text: t("BottomAppBar.recommendation"),
      path: "assistant-chat",
    },
    {
      visible: screenWidth >= 768,
      icon: faDatabase,
      i18Text: t("BottomAppBar.tools"),
      path: "tools?type=search",
      onClickFn: resetPageByRefresh,
    },
    {
      visible: screenWidth >= 768,
      icon: faFolder,
      iconAfter: faFolder,
      i18Text: t("BottomAppBar.project"),
      path: "project",
    },
    {
      visible: screenWidth >= 768,
      icon: faPeopleGroup,
      iconAfter: faPeopleGroup,
      i18Text: t("BottomAppBar.management"),
      path: "management",
    },
    {
      visible: screenWidth >= 768,
      icon: faNewspaper,
      iconAfter: faNewspaperAfter,
      i18Text: t("BottomAppBar.blog"),
      path: "blog",
    },
    // {
    //   visible: screenWidth >= 768,
    //   icon: faIdBadge,
    //   iconAfter: faIdBadge,
    //   i18Text: t("CustomerTools.profile"),
    //   path: "customer/profile",
    // },
    {
      visible: true,
      icon: faGear,
      i18Text: t("BottomAppBar.setting"),
      path: "setting",
    },
    {
      visible: screenWidth < 768,
      icon: customerData.avatarUrl,
      i18Text: t("OwnedKOL.title"),
      noHighlight: true,
      path: "",
      onClickFn: () => {
        setSwitchAccountModal({
          metaData: {
            KOLList: ownedKOLData,
            CustomerData: customerData,
            disableOutbounds: true,
          },
        })
      },
    },
  ]

  const adminNavButtons: NavButtonProps[] = [
    {
      visible: true,
      icon: faHouse,
      i18Text: t("BottomAppBar.homepage"),
      path: "",
    },
    {
      visible: true,
      icon: faComment,
      iconAfter: faCommentAfter,
      iconUnread: numOfUnreadChat > 0,
      i18Text: t("BottomAppBar.chat"),
      path: "c",
      onClickFn: setPageType,
    },
    {
      visible: true,
      icon: faCrown,
      iconAfter: faCrown,
      i18Text: t("BottomAppBar.admin"),
      path: "admin",
      onClickFn: () => {
        window.open("https://admin.look4kol.com/hk")
      },
    },
    {
      visible: true,
      icon: faGear,
      i18Text: t("BottomAppBar.setting"),
      path: "setting",
    },
  ]

  const defaultNavButtons: NavButtonProps[] = [
    {
      visible: true,
      icon: faHouse,
      i18Text: t("BottomAppBar.homepage"),
      path: "",
    },
    {
      visible: true,
      icon: faLock,
      iconAfter: faLockOpen,
      i18Text: t("BottomAppBar.login"),
      path: "login",
    },
    {
      visible: true,
      icon: faSearch,
      iconAfter: faSearch,
      i18Text: t("BottomAppBar.search"),
      path: "s",
    },
    {
      visible: screenWidth >= 768,
      icon: faTag,
      iconAfter: faTag,
      i18Text: t("BottomAppBar.kol-pro"),
      path: "kol-pro",
    },
    {
      visible: screenWidth >= 768,
      icon: faNewspaper,
      iconAfter: faNewspaperAfter,
      i18Text: t("BottomAppBar.blog"),
      path: "blog",
    },
    {
      visible: true,
      icon: faGear,
      i18Text: t("BottomAppBar.setting"),
      path: "setting",
    },
  ]

  const returnNavButton = (): NavButtonProps[] => {
    if (isAuth(auth)) {
      switch (role) {
        case ROLE.KOL:
          return kolNavButtons

        case ROLE.CUSTOMER:
          return customerNavButtons

        case ROLE.ADMIN:
          return adminNavButtons

        default:
          return defaultNavButtons
      }
    }
    return defaultNavButtons
  }

  if (!isSpecialPage) {
    return (
      <div className="bg-[#fdf8f8] md:bg-white border-r border-gary-200 lg:bg-white h-full safe-are-detection-bottom">
        {/* icon*/}
        <div className="hidden md:inline mb-10">
          {!isSpecialPage ? (
            <Link
              to={
                "/" + (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk")
              }>
              <img
                id={"Heading_CompanyLogo"}
                className="fixed left-6 top-2 w-12 h-12 mx-auto"
                src={"https://look4kol.com/logo96.png"}
                alt="look4kol logo"
              />
            </Link>
          ) : (
            <img
              id={"Heading_CompanyLogo"}
              className="fixed left-6 top-2 w-12 h-12 mx-auto"
              src={"https://look4kol.com/logo96.png"}
              alt="look4kol logo"
            />
          )}
        </div>
        <div
          className={classNames(
            "md:max-h-screen md:overflow-y-scroll bg-[#fdf8f8] md:bg-white flex flex-auto gap-0 md:gap-2  w-full",
            {
              "flex-row px-2": dir === NavButtonGroupDir.ROW,
              "flex-col py-2": dir === NavButtonGroupDir.COL,
            }
          )}>
          {returnNavButton().map((e, index) => {
            return (
              <NavButton
                key={"nav" + index}
                visible={e.visible}
                icon={e.icon}
                iconAfter={e.iconAfter}
                iconUnread={e.iconUnread}
                i18Text={e.i18Text}
                noHighlight={e.noHighlight}
                path={e.path}
                onClickFn={e.onClickFn}
              />
            )
          })}
        </div>
      </div>
    )
  }
  return <></>
}
