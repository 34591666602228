import React, { useEffect, useState } from "react"
import { CaseStudyModalData } from "../../Model/Modal"
import { Trans } from "react-i18next"
import KOLPlanItem from "../../page-components/home/KOLPlanItem"
import CaseStudyResultIG from "../ModalController/CaseStudyModal/CaseStudyResultIG/CaseStudyResultIG"
import { getKOLPlan } from "../../HelpingFunction/KOL/kolDBHelper"

const CaseStudyModalComponent = (props: CaseStudyModalData) => {
  const [isLoadedKOLPlan, setIsLoadedKOLPlan] = useState<boolean>(false)
  const [KOLPlan, setKOLPlan] = useState({})
  const [isPlanNotAvailable, setIsPlanNotAvailable] = useState<boolean>(false)

  useEffect(() => {
    if (props.metaData.jobData) {
      getKOLPlan(
        props.metaData.jobData.KOLID,
        props.metaData.jobData.ServicePlanID
      )
        .then((result) => {
          if (result.success) {
            setKOLPlan(result.data)
            setIsLoadedKOLPlan(true)
          } else {
            setIsPlanNotAvailable(true)
            setIsLoadedKOLPlan(true)
          }
        })
        .catch((err) => console.log(err))
    }
  }, [props.jobData])
  return (
    <div className="container mx-auto  overflow-y-auto ">
      {props.metaData.hasOwnProperty("jobData") && (
        <div className="grid grid-cols-10 gap-2 lg:gap-4">
          <div className="col-span-10 lg:col-span-6">
            {Array.isArray(props.metaData.jobData.imageUrl) ? (
              props.metaData.jobData.imageUrl.length > 0 ? (
                props.metaData.jobData.imageUrl.map((e, index) => {
                  return (
                    <div
                      className="rounded overflow-hidden w-full h-auto"
                      key={"cim" + index}>
                      <img src={e} alt="job feature image" />
                    </div>
                  )
                })
              ) : (
                <div className="py-2 px-4 rounded">
                  <Trans>CaseStudyModal.no-image-was-found</Trans>
                </div>
              )
            ) : (
              ""
            )}
          </div>
          <div className="col-span-10 lg:col-span-4">
            {/*case data*/}
            <div className="my-8">
              <h2 className="text-xl my-4 font-bold">
                <Trans>CaseStudyModal.kol-plan</Trans>
              </h2>
              {isLoadedKOLPlan ? (
                !isPlanNotAvailable && (
                  <KOLPlanItem
                    data={KOLPlan}
                    KOLID={props.metaData.jobData.KOLID}
                    setContactKOLModal={props.setContactKOLModal}
                  />
                )
              ) : (
                <div className="py-2 px-4 rounded">
                  <Trans>CaseStudyModal.no-plan</Trans>
                </div>
              )}
            </div>

            <div className="my-8">
              <h2 className="text-xl my-4 font-bold">
                <Trans>CaseStudyModal.case-study-result</Trans>
              </h2>

              <CaseStudyResultIG data={props.metaData.jobData} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default CaseStudyModalComponent
