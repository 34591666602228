import {
  faChevronLeft,
  faFileSignature,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { title } from "process"
import React from "react"
import { HelpDocHeader } from "./HelpDocHeader/HelpDocHeader"
import { CONTRACT_HEADER } from "../../Utils/HelpButtonHelperFunction"
import { t } from "i18next"
import { getUserLang } from "../../Utils/utiltyHelper"

import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../Utils/ModalType"

const ContractHeader = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const createDraftContractModal = (): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.CREATE_DRAFT_CONTRACT_MODAL,
      metaData: null,
    })
  }
  return (
    <div
      className="h-18 my-auto w-full py-2 px-4 grid gap-4"
      style={{
        height: "60px",
        gridTemplateColumns: "40px auto  20px",
      }}>
      <div>
        <button
          className="my-auto h-full text-left my-2 mx-2 text-gray-600 font-bold text-xl"
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            history.push("/" + getUserLang())
          }}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      </div>
      <div className="my-auto overflow-ellipsis w-full text-lg font-medium uppercase">
        {t("ListContract.title")}
      </div>

      <div className="flex justify-end">
        <FontAwesomeIcon
          onClick={() => createDraftContractModal()}
          className="my-auto text-amber-600 text-xl mr-4"
          icon={faFileSignature}
        />
        <HelpDocHeader helpDocs={CONTRACT_HEADER} />
      </div>
    </div>
  )
}

export default ContractHeader
