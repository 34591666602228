import React, { useEffect, useImperativeHandle, useState } from "react"
import { Project } from "../../../Model/ProjectManagement"
import { useDispatch, useSelector } from "react-redux"
import CustomAlert from "../../NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../../Enum/ALERT_SYSYEM"
import { Trans, useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons"
import { deleteProjectDB } from "../../../HelpingFunction/ProjectManagement/ProjectDBHelper"
import { useHistory } from "react-router-dom"
import { getUserLang } from "../../../Utils/utiltyHelper"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"

interface Props {
  auth: any
  closeModal: () => void
  metaData: {
    project: Project
  }
}

const ProjectManagementModal = (props: Props, ref: any) => {
  const history = useHistory()
  const { t, i18n } = useTranslation()

  const dispatch = useDispatch()
  const project: Project = useSelector((state: any) => {
    return state.ProjectManager.currentProject
  })
  const projects: Project[] = useSelector((state: any) => {
    return state.ProjectManager.projects
  })

  const setProjectList = (state: Project[]) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_PROJECTS,
      data: state,
    })
  }

  const [editProject, setEditProject] = useState<Project>()
  const [isDeleteProject, setIsDeleteProject] = useState<boolean>(false)
  const [deleteProjectCheck, setDeleteProjectCheck] = useState<string>("")
  const [error, setError] = useState<{
    show: boolean
    message: string
  }>({ show: false, message: "" })

  const closeModal = () => {
    document.getElementsByTagName("body")[0].style.overflow = "scroll"
    props.closeModal()
  }
  const detectClickClose = (e: any) => {
    if (document.getElementById("modal-box")?.contains(e.target) === false) {
      closeModal()
    }
  }

  useEffect(() => {
    window.addEventListener("click", detectClickClose)

    // return window.removeEventListener("click", detectClickClose)
  }, [])

  useEffect(() => {
    if (project) {
      setEditProject(project)
    }
  }, [])

  useImperativeHandle(ref, () => ({
    returnData() {
      return {
        editProject: editProject,
        setError: setError,
      }
    },
  }))

  const deleteProject = async () => {
    if (deleteProjectCheck === (editProject?.title as string)) {
      await deleteProjectDB(editProject?.id as string).then((res) => {
        if (res.success) {
          let newProjects = projects.filter(
            (project) => project.id !== editProject?.id
          )
          setProjectList(newProjects)
          history.push("/" + getUserLang() + "/project")
          props.closeModal()
        }
      })
    } else {
      setError({
        show: true,
        message: t("ProjectManagement.value-error"),
      })
    }
  }

  return (
    <div>
      {/* Body Starts */}
      {editProject && (
        <div className="px-6">
          {!isDeleteProject && (
            <div>
              <h2 className="font-medium text-lg my-4">
                <Trans>ProjectOptionBar.title</Trans>
              </h2>
              <input
                type="text"
                max={25}
                placeholder="Project Title"
                value={editProject.title}
                onChange={(e) =>
                  setEditProject({ ...editProject, title: e.target.value })
                }
                className="input input-bordered w-full max-w-sm"
              />
              <p className="text-xs text-gray-500 float-right px-8 my-1">
                <span
                  className={
                    editProject.title.length > 25
                      ? "text-red-400"
                      : "text-gray-500"
                  }>
                  {editProject.title.length}
                </span>{" "}
                / 25
              </p>
              <h2 className="font-medium text-lg my-4">
                <Trans>ProjectOptionBar.description</Trans>
              </h2>
              <textarea
                className="textarea textarea-bordered max-w-sm  w-full"
                placeholder="Project Description"
                value={editProject.description}
                onChange={(e) =>
                  setEditProject({
                    ...editProject,
                    description: e.target.value,
                  })
                }></textarea>

              <h2 className="font-medium text-lg my-4">
                <Trans>ProjectOptionBar.budget</Trans>
              </h2>
              <label className="input-group">
                <input
                  type="number"
                  placeholder="Budget"
                  defaultValue={0}
                  min={0}
                  max={99999}
                  className="input input-bordered w-full max-w-xs"
                  value={editProject.budget}
                  onChange={(e) =>
                    setEditProject({
                      ...editProject,
                      budget: parseInt(e.target.value),
                    })
                  }
                />
                <span>HKD</span>
              </label>
            </div>
          )}

          <div className={isDeleteProject ? "hidden" : "block"}>
            <p
              onClick={() => {
                setIsDeleteProject(true)
              }}
              className="text-sm text-red-400 mt-4 font-medium">
              <Trans>ProjectManagement.delete</Trans>
            </p>
          </div>
        </div>
      )}
      <button
        className={isDeleteProject ? "block" : "hidden"}
        onClick={() => setIsDeleteProject(false)}>
        {" "}
        <FontAwesomeIcon icon={faArrowLeftLong} />
      </button>
      {isDeleteProject && (
        <div>
          <p className="text-lg font-semibold text-red-400 my-4">
            <Trans>ProjectManagement.delete-title</Trans>
          </p>

          <input
            type="text"
            placeholder={t("ProjectManagement.enter-title") as string}
            className="input input-bordered w-full max-w-xs"
            onChange={(e) => setDeleteProjectCheck(e.target.value)}
            value={deleteProjectCheck}
          />
          <p className="my-2 ml-1 text-sm">
            <Trans>ProjectManagement.delete-confirmation</Trans>{" "}
            <span className="font-bold">{editProject?.title}</span>
          </p>

          <button
            onClick={() => deleteProject()}
            className="btn btn-warning w-64 mt-8">
            <Trans>ProjectManagement.delete-button</Trans>
          </button>
        </div>
      )}
      <div className="my-4">
        {error.show && (
          <CustomAlert
            title={error.message}
            alertType={ALERT_TYPE.ERROR}
            buttonType={{ type: BUTTON_TYPE.NONE }}
          />
        )}
      </div>
    </div>
  )
}

export default React.forwardRef(ProjectManagementModal)
