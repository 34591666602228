import React from "react"
import { Route, Switch, useLocation } from "react-router-dom"

import { useTranslation } from "react-i18next"
import { getUserLang } from "../../Utils/utiltyHelper"

import CommonHeading from "../../Layout/Heading/CommonHeading"
import { NormalHeader } from "./NormalHeader"
import { KOLSettingHeader } from "./KOLSettingHeader"
import { HomeHeader } from "./HomeHeader"
import { KOLProfileHeader } from "./KOLProfileHeader"
import { ChatroomHeader } from "./ChatroomHeader"
import { CustomerSettingHeader } from "./CustomerSettingHeader"
import { SearchHeader } from "./SearchHeader"
import { SignupHeader } from "./SignupHeader"
import { ToolsHeader } from "./ToolsHeader"
import { VerifyPhoneHeader } from "./VerifyPhoneHeader"
import ProjectPageHeader from "./ProjectPageHeader"
import ContractHeader from "./ContractHeader"
import AssistantChatHeader from "./AssistantChatHeader"
import AssistantRecHeader from "./AssistantRecHeader"
import InvoiceHeader from "./InvoiceHeader"
import QuotationHeader from "./QuotationHeader"

interface MobileHeaderProps {
  path: string
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ path }) => {
  const { t } = useTranslation()
  const location = useLocation()

  const isAllContractPage = location.pathname.includes(
    "/" + getUserLang() + "/j"
  )
  const isSignUpPage = location.pathname.includes("/signup")

  const isChatroomPage = location.search.includes("chatroomID=")

  return (
    <div className="safe-are-detection-top">
      <CommonHeading />
      <Switch>
        <Route path={"/:lang/c"}>
          {isChatroomPage ? (
            <ChatroomHeader />
          ) : (
            <NormalHeader title={t("ListChatRoom.conversations")} />
          )}
        </Route>
        <Route path={"/:lang/j"}>
          {isAllContractPage ? (
            <ContractHeader />
          ) : (
            <NormalHeader title={t("ListContract.title")} />
          )}
        </Route>
        <Route path={"/:lang/message"}>
          <NormalHeader title={t("RouterManager.MessageCenter")} />
        </Route>
        <Route path={"/:lang/s"}>
          <SearchHeader />
        </Route>
        <Route path={"/:lang/setting"}>
          <NormalHeader title={t("AppSetting.title")} />
        </Route>
        <Route path={"/:lang/p/:id/:type?"} exact>
          <KOLProfileHeader />
        </Route>
        <Route path={"/:lang/signup"}>
          <SignupHeader />
        </Route>
        <Route path={"/:lang/verify-phone"}>
          <VerifyPhoneHeader />
        </Route>
        <Route path={"/:lang/project"} exact>
          <NormalHeader title={t("ProjectManagement.title")} />
        </Route>
        <Route path={"/:lang/project/:id"} exact>
          <ProjectPageHeader />
        </Route>
        <Route path={"/:lang/link/:id"} exact>
          <></>
        </Route>

        <Route path={"/:lang/customer/:path"} exact>
          <CustomerSettingHeader />
        </Route>
        <Route path={"/:lang/kol/:path"} exact>
          <KOLSettingHeader />
        </Route>
        <Route path={"/:lang/tools/:path?/:subPath?"}>
          <ToolsHeader />
        </Route>
        <Route path={"/:lang/assistant-chat"} exact>
          <AssistantChatHeader />
        </Route>
        <Route path={"/:lang/assistant-rec"} exact>
          <AssistantRecHeader />
        </Route>
        <Route path={"/:lang/invoice/:id"} exact>
          <InvoiceHeader />
        </Route>
        <Route path={"/:lang/create-invoice"} exact>
          <InvoiceHeader />
        </Route>
        <Route path={"/:lang/create-draft"} exact>
          <NormalHeader title={t("DraftContract.title")} />
        </Route>
        <Route path={"/:lang/kol-pro"} exact>
          <NormalHeader title={t("KOLPro.l4k-award")} />
        </Route>
        <Route path={"/:lang/quote"} exact>
          <QuotationHeader />
        </Route>
        <Route path={"/:lang/*"}>
          <NormalHeader title={""} />
        </Route>
        <Route path={"/:lang/link/:id"} exact>
          <HomeHeader />
        </Route>
        <Route path={"/:lang"}>
          <HomeHeader />
        </Route>
      </Switch>
    </div>
  )
}
