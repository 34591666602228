import React, { useEffect, useState } from "react"
import { Trans } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleExclamation, faStar } from "@fortawesome/free-solid-svg-icons"
import { returnIdTokenResult } from "../../../Utils/FirebaseUtils"
import { getAPIPath } from "../../../Utils/HelpingFunction"
import CustomAlert from "../../../Component/NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../../Enum/ALERT_SYSYEM"
import classNames from "classnames"

interface PropsType {
  isAdmin: boolean
  id: string
  score: number
  finalComment: string
}
const ContractFeedbackEdit: React.FC<PropsType> = ({
  isAdmin,
  id,
  score,
  finalComment,
}) => {
  const [feedback, setFeedback] = useState({
    score: score ? score : 0,
    finalComment: finalComment ? finalComment : "",
  })
  const [error, setError] = useState<{ visible: boolean; msg: string }>({
    visible: false,
    msg: "",
  })
  const [isSentFeedback, setIsSentFeedback] = useState<boolean>(score !== 0)
  const [isSendingFeedback, setIsSendingFeedback] = useState<boolean>(false)

  useEffect(() => {
    if (error.visible) {
      setTimeout(() => {
        setError({
          visible: false,
          msg: "",
        })
      }, 3000)
    }
  }, [error])

  const submitFeedback = async () => {
    setIsSendingFeedback(true)
    if (feedback.score > 0) {
      returnIdTokenResult().then(async (res) => {
        await fetch(getAPIPath("/api/job/" + id + "/feedback"), {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            idToken: res.token,
          },
          body: JSON.stringify({
            feedback: feedback,
          }),
        })
          .then((res) => res.json())
          .then((finalResponse) => {
            console.log(finalResponse)
            setIsSendingFeedback(false)
            setIsSentFeedback(true)
          })
      })
    } else {
      setIsSendingFeedback(false)
      setError({
        visible: true,
        msg: "Rating Should Be Greater Than 0",
      })
    }
  }

  return (
    <div className="w-full shadow rounded border-t-8 border-green-300 p-4 pb-4">
      <h2 className="text-2xl font-bold text-green-600 my-4">
        <Trans>Contract.status-3-c</Trans>
      </h2>
      <p className="text-md text-gray-700">
        <Trans>Contract.give-score</Trans>
      </p>

      <hr className="my-4" />

      <div className="text-lg mt-6">
        <p className="my-2">
          <Trans>Contract.score</Trans>
        </p>
        <div
          className="grid w-32 gap-2"
          style={{ gridTemplateColumns: "20% 20% 20% 20% 20%" }}>
          <div>
            <FontAwesomeIcon
              icon={faStar}
              className={[
                "cursor-pointer",
                feedback.score >= 1 ? "text-yellow-700" : "text-gray-300",
              ].join(" ")}
              onClick={() => {
                if (!isSentFeedback) {
                  setFeedback({ ...feedback, score: 1 })
                }
              }}
            />
          </div>
          <div>
            <FontAwesomeIcon
              icon={faStar}
              className={[
                "cursor-pointer",
                feedback.score >= 2 ? "text-yellow-700" : "text-gray-300",
              ].join(" ")}
              onClick={() => {
                if (!isSentFeedback) {
                  setFeedback({ ...feedback, score: 2 })
                }
              }}
            />
          </div>
          <div>
            <FontAwesomeIcon
              icon={faStar}
              className={[
                "cursor-pointer",
                feedback.score >= 3 ? "text-yellow-700" : "text-gray-300",
              ].join(" ")}
              onClick={() => {
                if (!isSentFeedback) {
                  setFeedback({ ...feedback, score: 3 })
                }
              }}
            />
          </div>
          <div>
            <FontAwesomeIcon
              icon={faStar}
              className={[
                "cursor-pointer",
                feedback.score >= 4 ? "text-yellow-700" : "text-gray-300",
              ].join(" ")}
              onClick={() => {
                if (!isSentFeedback) {
                  setFeedback({ ...feedback, score: 4 })
                }
              }}
            />
          </div>
          <div>
            <FontAwesomeIcon
              icon={faStar}
              className={[
                "cursor-pointer",
                feedback.score >= 5 ? "text-yellow-700" : "text-gray-300",
              ].join(" ")}
              onClick={() => {
                if (!isSentFeedback) {
                  setFeedback({ ...feedback, score: 5 })
                }
              }}
            />
          </div>
        </div>
      </div>

      <div className="text-lg my-4">
        <p className="my-2">
          <Trans>Contract.comment</Trans>
        </p>
        <div>
          <textarea
            className="w-full h-64 rounded bg-gray-50 p-4"
            value={feedback.finalComment}
            placeholder={"comment"}
            disabled={isSentFeedback}
            onChange={(e) =>
              setFeedback({ ...feedback, finalComment: e.target.value })
            }
          />
        </div>
      </div>
      <div className=" mb-3 text-right">
        <button
          className={classNames("btn btn-success w-40", {
            loading: isSendingFeedback,
          })}
          disabled={isSentFeedback || isAdmin}
          onClick={() => submitFeedback()}>
          {isSentFeedback ? (
            <Trans>Contract.submitted-feedback</Trans>
          ) : (
            <Trans>Contract.submit-feedback</Trans>
          )}
        </button>
      </div>

      {error.visible && (
        <CustomAlert
          alertType={ALERT_TYPE.ERROR}
          buttonType={{
            type: BUTTON_TYPE.NONE,
          }}
          icon={faCircleExclamation}
          title={error.msg}
        />
      )}
    </div>
  )
}
export default ContractFeedbackEdit
