import { getAPIPath } from "../../../Utils/HelpingFunction"
import { returnIdTokenResult } from "../../../Utils/FirebaseUtils"

export async function sendPayoutRequest(jobID, customerID, uid) {
  return new Promise(async (resolve, reject) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/job/" + jobID + "/complete"), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
      })
        .then((res) => res.json())
        .then(async (response) => {
          return resolve(response)
        })
        .catch((err) => {
          return resolve({
            success: false,
            message: err,
          })
        })
    })
  })
}
