import React, { useState } from "react"
import { Trans } from "react-i18next"

const ApplyAccountDelectionComponent: React.FC = () => {
  return (
    <div className="my-8">
      <p className="my-4 text-sm text-gray-500 ">
        <Trans>ApplyAccountDelectionComponent.des</Trans>
      </p>
    </div>
  )
}
export default ApplyAccountDelectionComponent
