import React from "react"
import InvoiceView from "../../page-components/invoice/InvoiceView"
import { useSelector } from "react-redux"
import { InvoiceDetails } from "../../Model/Invoice"
import { Job } from "../../Model/Job"

const InvoicePreviewModalComponent = (props: any) => {
  const job: Job = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomJob
  })

  const invoiceObj: InvoiceDetails = {
    id: job.id,
    title: job.ServicePlanName,
    price: job.price,
    description: job.quotationDetail,
  }
  return (
    <div>
      <InvoiceView invoiceDetails={invoiceObj} />
    </div>
  )
}

export default InvoicePreviewModalComponent
