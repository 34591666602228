import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"

const initialState = {
  draftMsg: {},
  isContainDraftMsg: false,
}

const setDraftMsg = (state = initialState, action) => {
  if (action.type === SUPPORTED_REDUX_FUNCTIONS.SHARE_DRAFT_MSG) {
    return {
      ...state,
      draftMsg: {
        ...action.draftMsg,
        createDate: {
          seconds: new Date().getTime() / 1000,
        },
      },
      isContainDraftMsg: true,
    }
  } else if (action.type === SUPPORTED_REDUX_FUNCTIONS.RESET_DRAFT_MSG) {
    return {
      ...state,
      draftMsg: {},
      isContainDraftMsg: false,
    }
  }

  return state
}

export default setDraftMsg
