import { Job, JOB_TYPE } from "../Model/Job"
import { BADGE_TYPE } from "../Enum/TABLE_SYSTEM"
import { JOB_STATUS_TYPE } from "../Enum/APP_TYPE"

const returnJobBadgeSetting = (
  job: Job
): {
  type: BADGE_TYPE
  label: string
  textColor: string
  bgColor: string
} => {
  const isWorking =
    job.accepted &&
    (!job.completePayout ||
      (job.status === JOB_STATUS_TYPE.STATUS_5 && !job.isFinish)) &&
    job.paid &&
    job.CustomerID

  const isJobDone =
    job.accepted && job.completePayout && job.paid && job.isFinish

  const isJobAccepted =
    job.accepted && !job.completePayout && !job.paid && job.CustomerID

  const isWaitingAcceptQuotation =
    !job.accepted && job.quotationDetail !== "" && job.CustomerID && job.priceID

  const isQuoting =
    !job.accepted &&
    job.quotationDetail !== "" &&
    job.CustomerID &&
    !job.priceID

  const isSigning =
    !job.accepted &&
    job.quotationDetail !== "" &&
    job.CustomerID &&
    job.isConfirmQuotation

  const isPending = !job.accepted && job.quotationDetail === ""
  const isInvoice = job.type === JOB_TYPE.INVOICE && job.CustomerID === ""

  const isDraft = job.CustomerID === "" && job.type !== JOB_TYPE.INVOICE

  if (isWorking) {
    return {
      type: BADGE_TYPE.WORKING,
      label: "MyTransaction.working",
      textColor: "text-fuchsia-900",
      bgColor: "bg-fuchsia-200",
    }
  } else if (isJobDone) {
    return {
      type: BADGE_TYPE.JOB_DONE,
      label: "MyTransaction.fulfilled",
      textColor: "text-green-900",
      bgColor: "bg-green-200",
    }
  } else if (isJobAccepted) {
    return {
      type: BADGE_TYPE.JOB_ACCEPTED,
      label: "MyTransaction.accepted",
      textColor: "text-yellow-900",
      bgColor: "bg-yellow-200",
    }
  } else if (isSigning) {
    return {
      type: BADGE_TYPE.CONTRACT,
      label: "MyTransaction.contract",
      textColor: "text-blue-900",
      bgColor: "bg-blue-200",
    }
  } else if (isWaitingAcceptQuotation) {
    return {
      type: BADGE_TYPE.WAIT_QUOTE_ACCEPT,
      label: "MyTransaction.price-sent",
      textColor: "text-indigo-900",
      bgColor: "bg-indigo-200",
    }
  } else if (isQuoting) {
    return {
      type: BADGE_TYPE.QUOTATION,
      label: "MyTransaction.quoting",
      textColor: "text-pink-900",
      bgColor: "bg-pink-200",
    }
  } else if (isPending) {
    return {
      type: BADGE_TYPE.PENDING,
      label: "MyTransaction.pending",
      textColor: "text-red-900",
      bgColor: "bg-red-200",
    }
  } else if (isDraft) {
    return {
      type: BADGE_TYPE.TAG,
      label: "MyTransaction.draft",
      textColor: "text-red-900",
      bgColor: "bg-red-200",
    }
  } else if (isInvoice) {
    return {
      type: BADGE_TYPE.INVOICE,
      label: "Invoice",
      textColor: "text-red-900",
      bgColor: "bg-red-200",
    }
  }
  return {
    type: BADGE_TYPE.FAIL,
    label: "unknown",
    textColor: "text-gray-900",
    bgColor: "bg-gray-200",
  }
}

export { returnJobBadgeSetting }
