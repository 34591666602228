import React from "react"

import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { Trans, useTranslation } from "react-i18next"
import {
  CHATGPT_SETTING_TYPE,
  COMMEND_TYPE,
  FORMAL_LEVEL,
  FRIENDLY_LEVEL,
  writingStyle,
} from "../../../Model/ChatMessage"
import ChatGPTIGSetting from "./ChatGPTIGSetting"
import ChatGPTTextSetting from "./ChatGPTTextSetting"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ChatGPTSetting: React.FC = () => {
  const dispatch = useDispatch()
  const chatgptSetting: CHATGPT_SETTING_TYPE = useSelector((state: any) => {
    return state.ChatGPTManager.setting
  })

  return (
    <div className="p-2 lg:py-0">
      <div className="p-3">
        <h2 className="text-2xl font-bold">
          {chatgptSetting.commendType === COMMEND_TYPE.TEXT && (
            <Trans>ChatGPTSetting.menu-text</Trans>
          )}
          {chatgptSetting.commendType === COMMEND_TYPE.IMAGE && (
            <Trans>ChatGPTSetting.menu-image</Trans>
          )}
          {chatgptSetting.commendType === COMMEND_TYPE.INSTAGRAM && (
            <Trans>ChatGPTSetting.menu-ig</Trans>
          )}
          {chatgptSetting.commendType === COMMEND_TYPE.RECOMMENDATION && (
            <Trans>ChatGPTSetting.menu-advisor</Trans>
          )}
        </h2>
      </div>
      {chatgptSetting.commendType === COMMEND_TYPE.TEXT && (
        <div>
          <ChatGPTTextSetting />
        </div>
      )}
      {chatgptSetting.commendType === COMMEND_TYPE.IMAGE && (
        <div>
          <div className="alert shadow-lg my-4 mx-4">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="stroke-info flex-shrink-0 w-6 h-6">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
              <span>
                {" "}
                <Trans>ChatGPTSetting.fill-in</Trans>
              </span>
            </div>
          </div>
        </div>
      )}
      {chatgptSetting.commendType === COMMEND_TYPE.INSTAGRAM && (
        <div>
          <ChatGPTIGSetting />
        </div>
      )}
    </div>
  )
}
export default ChatGPTSetting
