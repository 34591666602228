import React, { useEffect, useState } from "react"
import CustomerRegForm from "../page-components/login/CustomerRegForm"
import KOLRegForm from "../page-components/login/KOLRegForm"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import RequireLogin from "../page-components/login/RequireLogin"
import LoginInfo from "../page-components/login/LoginInfo"
import { Trans, useTranslation } from "react-i18next"
import Look4kolLogo from "../Component/Look4kolLogo"
import { Helmet } from "react-helmet"
import { getUserLang } from "../Utils/utiltyHelper"
import { getQuery, useQuery } from "../Utils/QueryHelper"
import { useSelector } from "react-redux"

export enum RegisterPageType {
  "KOL",
  "CUSTOMER",
  "REQUIRE_LOGIN",
}

const RegisterPage = () => {
  const { t } = useTranslation()

  const params = useSelector((state: any) => {
    return state.QueryManager.params
  })

  const [pageType, setPageType] = useState<RegisterPageType>(
    RegisterPageType.KOL
  )

  useEffect(() => {
    let redirectObj: string | RegisterPageType = localStorage.getItem(
      "redirectObj"
    ) as string
    localStorage.removeItem("redirectObj")
    if (JSON.parse(redirectObj) !== null) {
      setPageType(JSON.parse(redirectObj))
    } else {
      let type = getQuery(params, "type")
      if (type !== null) {
        if (type === "KOL") {
          setPageType(RegisterPageType.KOL)
        } else if (type === "Customer") {
          setPageType(RegisterPageType.CUSTOMER)
        }
      }
    }
  }, [localStorage.getItem("redirectObj")])

  useEffect(() => {
    if (document.getElementById("registerInfo")) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.getElementById("registerInfo").scrollIntoView()
    }
  }, [])

  return (
    <>
      {/* SEO tag*/}
      <Helmet>
        <title>
          {t("RegForm.register-as-kol") +
            " | " +
            t("RegForm.register-as-customer") +
            " | " +
            t("Feature.title")}
        </title>
        <meta property="fb:app_id" content="3080771285488915" />
        <meta property="og:type" content="website" />
        <meta property="site_name" content="look4kol" />
        <meta
          property="title"
          content={
            t("RegForm.register-as-kol") +
            " | " +
            t("RegForm.register-as-customer") +
            " | " +
            t("Feature.title")
          }
        />
        <meta
          name="description"
          content={
            t("RegForm.why-facebook-login") +
            t("RegForm.why-facebook-login-des")
          }
        />
        {/*<meta name="keywords"*/}
        {/*      content={t("HomePage.keywords")}/>*/}
        <meta
          property="og:title"
          content={
            t("RegForm.register-as-kol") +
            " | " +
            t("RegForm.register-as-customer") +
            " | " +
            t("Feature.title")
          }
        />
        <meta
          property="og:description"
          content={
            t("RegForm.why-facebook-login") +
            t("RegForm.why-facebook-login-des")
          }
        />
        <meta property="og:image" content={""} />

        <meta
          property="og:url"
          content={"https://look4kol.com/" + getUserLang() + "/login"}
        />

        <link
          rel="canonical"
          href={
            getUserLang() === "en"
              ? "https://look4kol.com/hk/login/"
              : "https://look4kol.com/en/login/"
          }
        />
      </Helmet>

      {/* Main Container*/}
      <div className="relative">
        <div id="registerInfo" className="h-full md:grid md:grid-cols-2">
          <div className="md:px-12 lg:px-20 px-10 pt-2 pb-8 md:py-8 mb-10">
            <div className="md:hidden">
              <Look4kolLogo />
            </div>

            {pageType !== RegisterPageType.REQUIRE_LOGIN && (
              <div>
                <h1 className="text-3xl font-semibold py-2">
                  <Trans>SignUp.title</Trans> -{" "}
                  <Trans>RegisterPage.title</Trans>
                </h1>
                <div className="tabs tabs-boxed bg-white">
                  <a
                    onClick={() => setPageType(RegisterPageType.KOL)}
                    className={
                      pageType === RegisterPageType.KOL
                        ? "tab tab-active rounded-lg text-base"
                        : "tab border rounded-lg text-base"
                    }>
                    <Trans>RegisterPage.kol</Trans>
                  </a>
                  <a
                    onClick={() => setPageType(RegisterPageType.CUSTOMER)}
                    className={
                      pageType === RegisterPageType.CUSTOMER
                        ? "tab tab-active rounded-lg ml-2 text-base"
                        : "tab border rounded-lg ml-2 text-base"
                    }>
                    <Trans>RegisterPage.customer</Trans>
                  </a>
                </div>
              </div>
            )}

            {pageType === RegisterPageType.KOL && (
              <KOLRegForm setPageType={setPageType} />
            )}
            {pageType === RegisterPageType.CUSTOMER && (
              <CustomerRegForm setPageType={setPageType} />
            )}
            {pageType === RegisterPageType.REQUIRE_LOGIN && <RequireLogin />}
          </div>
          <div>
            <LoginInfo />
          </div>
        </div>
      </div>
    </>
  )
}

export default RegisterPage
