import React, { useEffect, useState } from "react"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import look4kolIgImg from "../../Assets/look4kol-link-3.png"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import linkImg1 from "../../Assets/look4kol-link-1.png"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import linkImg2 from "../../Assets/look4kol-link-2.png"
import { Trans } from "react-i18next"
import { Carousel } from "react-responsive-carousel"
import { useSelector } from "react-redux"
import { getKOLUserName } from "../../HelpingFunction/KOL/kolDBHelper"
import { isAuth } from "../../Utils/FirebaseUtils"
import { getUserLang } from "../../Utils/utiltyHelper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy } from "@fortawesome/free-regular-svg-icons"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"

const KOLMissionTutorialModalComponent = () => {
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const [copied, setCopied] = useState<boolean>(false)
  const [username, setUsername] = useState<string>("")

  useEffect(() => {
    if (isAuth(auth)) {
      getKOLName()
    }
  }, [auth])

  const getKOLName = async () => {
    await getKOLUserName(auth.uid).then((res) => {
      if (res.success) {
        setUsername(res.userName)
      }
    })
  }

  const copyUrl = () => {
    const url = `https://look4kol.com/${getUserLang()}/link/${username}`

    navigator.clipboard.writeText(url).then(() => console.log("copied"))
    setCopied(true)
  }

  const data = [
    {
      title: "KOLMissionTutorial.copy-link-title",
      desc: "KOLMissionTutorial.copy-link-desc",
    },
    {
      title: "KOLMissionTutorial.ig-bio-title-1",
      desc: "KOLMissionTutorial.ig-bio-desc-1",
      img: linkImg1,
    },
    {
      title: "KOLMissionTutorial.ig-bio-title-2",
      desc: "KOLMissionTutorial.ig-bio-desc-2",
      img: linkImg2,
    },
    {
      title: "KOLMissionTutorial.upload-proof-title",
      desc: "KOLMissionTutorial.upload-proof-desc",
      img: look4kolIgImg,
    },
  ]
  return (
    <div className="w-full max-w-3xl mt-2">
      <Carousel
        infiniteLoop
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        className="w-full rounded-xl mb-4"
        showStatus={false}
        showThumbs={false}>
        {data.map((slide, index) => {
          return (
            <section className=" text-center">
              <p className="text-lg font-semibold text-gray-700">
                <Trans>{slide.title}</Trans>
              </p>
              <p className="my-2 text-sm font-medium text-gray-500">
                <Trans>{slide.desc}</Trans>
              </p>
              <hr className="mb-2" />
              {index === 0 && (
                <div className="input-group my-16 mx-auto w-full md:w-96 ">
                  <input
                    type="text"
                    className="w-full input input-bordered"
                    value={`https://look4kol.com/${getUserLang()}/link/${username}`}
                  />
                  <button className="btn btn-square" onClick={() => copyUrl()}>
                    <FontAwesomeIcon
                      className="cursor-pointer ml-2 text-base "
                      icon={copied ? faCheck : faCopy}
                    />
                  </button>
                </div>
              )}
              {slide.img && (
                <div
                  className={classNames(
                    "border border-gray-100 rounded py-4 mx-auto h-full"
                  )}>
                  <img
                    src={slide.img}
                    className="h-116 w-auto object-contain"
                    alt={"look4kol tutorial"}
                  />
                </div>
              )}
            </section>
          )
        })}
      </Carousel>
    </div>
  )
}

export default KOLMissionTutorialModalComponent
