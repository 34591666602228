import React, { useEffect, useState } from "react"
import {
  faBuilding,
  faCog,
  faHeadset,
  faLock,
  faSearch,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons"
import { Trans, useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SideBar from "../../Assets/SideBar/SideBar"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import { connect, useSelector } from "react-redux"
import { useLocation, withRouter } from "react-router-dom"
import { isAuth, logout, returnIdTokenResult } from "../../Utils/FirebaseUtils"
import firebaseRef from "../../Config/firebase"
import { getUserLang } from "../../Utils/utiltyHelper"
import { getQuery, useQuery } from "../../Utils/QueryHelper"
import { ROLE } from "../../Enum/APP_TYPE"

const CommonHeading = (props) => {
  const location = useLocation()
  const params = useSelector((state) => {
    return state.QueryManager.params
  })

  const [role, setRole] = useState("")
  const [isAdmin, setIsAdmin] = useState(false)

  const viewID = getQuery(params, "viewAs")

  useEffect(() => {
    //when path changed, sidebar will close
    props.onChangeSideBar({
      sideBarName: "HeadingSideBar",
      isOpen: false,
    })
  }, [props.location.pathname])

  useEffect(() => {
    if (isAuth(props.auth)) {
      checkRole()
    }
  }, [props.auth])

  const checkRole = async () => {
    returnIdTokenResult().then((res) => {
      if (res.success) {
        if (viewID) {
          setRole(ROLE.KOL)
        } else {
          setRole(res.role)
        }
        setIsAdmin(res.role === "administrator")
      }
    })
  }

  return (
    <div
      id="HeadingSideBar"
      className={[
        props.HeadingSideBar ? "inline" : "hidden",
        "safe-are-detection-top z-20 fixed top-16 p-0 md:static md:w-full md:inline-block md:col-span-4 lg:col-span-3 xl:col-span-2  md:border-r-2 md:border-gray-100  overflow-y-hidden",
      ].join(" ")}>
      <SideBar
        width={320}
        height={"100vh"}
        name={"HeadingSideBar"}
        isOpen={props.HeadingSideBar}>
        <div className="hidden md:inline w-full relative bottom-0 py-4">
          <div
            className="w-full h-12 py-3 px-4 grid rounded-lg bg-indigo-600 text-white"
            style={{ gridTemplateColumns: "30px auto", gridColumnGap: "5px" }}>
            <div className="">
              <FontAwesomeIcon icon={faBuilding} />
            </div>

            <div className="w-full">
              <Trans>Heading.title</Trans>
            </div>
          </div>
        </div>
        <ul className="w-full md:py-6 pl-1 pr-1 md:pr-0">
          <li
            className={[
              "my-4 md:my-2 text-left text-md p-2 cursor-pointer rounded text-gray-700",
              props.location.pathname.includes("/blog")
                ? "text-red-800  bg-red-100 rounded-xl"
                : " md:hover:bg-gray-50",
            ].join(" ")}
            onClick={() =>
              props.history.push(
                "/" +
                  (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
                  "/blog"
              )
            }>
            <FontAwesomeIcon icon={faStickyNote} className="mr-2" />
            <Trans>Heading.blog</Trans>
          </li>
          <li
            className={[
              "my-4 md:my-2 text-left text-md p-2 cursor-pointer rounded text-gray-700",
              props.location.pathname.includes("/about")
                ? "text-red-800  bg-red-100 rounded-xl"
                : " md:hover:bg-gray-50",
            ].join(" ")}
            onClick={() =>
              props.history.push(
                "/" +
                  (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
                  "/about"
              )
            }>
            <FontAwesomeIcon icon={faStickyNote} className="mr-2" />
            <Trans>Heading.about</Trans>
          </li>

          <li
            className="my-4 md:my-2 text-left text-md p-2 cursor-pointer rounded text-gray-700 md:hover:bg-gray-50"
            onClick={() => {
              window.location.href = "https://calendly.com/look4kol/look4kol"
            }}>
            <FontAwesomeIcon icon={faHeadset} className="mr-2" />
            <Trans>Heading.marketing</Trans>
          </li>
          <hr />
          <li
            className={[
              "my-4 md:my-2 text-left text-md p-2 cursor-pointer rounded text-gray-700",
              props.location.pathname.includes("/s") &&
              !props.location.pathname.includes("/setting") &&
              !props.location.pathname.includes("/search-stat/kol")
                ? "text-red-800  bg-red-100 rounded-xl"
                : " md:hover:bg-gray-50",
            ].join(" ")}
            onClick={() =>
              props.history.push(
                "/" +
                  (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
                  "/s"
              )
            }>
            <FontAwesomeIcon icon={faSearch} className="mr-2" />
            <Trans>Heading.search</Trans>
          </li>
          <hr />
          {isAuth(props.auth) && role === "customer" && (
            <>
              <li
                className={[
                  "my-4 md:my-2 text-left text-md p-2 cursor-pointer rounded text-gray-700",
                  props.location.pathname.includes("/tools")
                    ? "text-red-800  bg-red-100 rounded-xl"
                    : " md:hover:bg-gray-50",
                ].join(" ")}
                onClick={() =>
                  props.history.push(
                    "/" +
                      (localStorage.getItem("i18n-lang") === "en"
                        ? "en"
                        : "hk") +
                      "/tools?type=search"
                  )
                }>
                <FontAwesomeIcon icon={faCog} className="mr-2" />
                <Trans>Heading.tools</Trans>
              </li>
              <hr />
            </>
          )}

          {isAuth(props.auth) ? (
            <li
              className={[
                "my-4 md:my-2 text-left text-md p-2 cursor-pointer rounded text-gray-700",
                " md:hover:bg-gray-50",
              ].join(" ")}
              onClick={() => {
                logout(firebaseRef)

                let params = new URLSearchParams("")
                if (location.search.includes("viewAs")) {
                  params.append("returnToCustomer", "true")
                }
                history.push({
                  pathname: "/" + getUserLang() + "/login",
                  search: params.toString(),
                })
              }}>
              <FontAwesomeIcon icon={faLock} className="mr-2" />
              <Trans>Heading.logout</Trans>
            </li>
          ) : (
            <li
              className={[
                "my-4 md:my-2 text-left text-md p-2 cursor-pointer rounded text-gray-700",
                props.location.pathname.includes("/login")
                  ? "text-red-800  bg-red-100 rounded-xl"
                  : " md:hover:bg-gray-50",
              ].join(" ")}
              onClick={() =>
                props.history.push(
                  "/" +
                    (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
                    "/login"
                )
              }>
              <FontAwesomeIcon icon={faLock} className="mr-2" />
              <Trans>Heading.login</Trans>
            </li>
          )}
          <li
            className={[
              "my-4 md:my-2 text-left text-md p-2 cursor-pointer rounded text-gray-700",
              props.location.pathname.includes("/setting")
                ? "text-red-800  bg-red-100 rounded-xl"
                : " md:hover:bg-gray-50",
            ].join(" ")}
            onClick={() =>
              props.history.push(
                "/" +
                  (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
                  "/setting"
              )
            }>
            <FontAwesomeIcon icon={faCog} className="mr-2" />
            <Trans>Heading.setting</Trans>
          </li>
        </ul>
      </SideBar>
    </div>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    onChangeSideBar: (state) =>
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.SET_SIDEBAR_ACTIVE,
        sideBarName: state.sideBarName,
        isOpen: state.isOpen,
      }),
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    HeadingSideBar: state.SideBarManager.HeadingSideBar,
  }
}

const enhance = connect(mapStateToProps, mapDispatchToProps)

export default enhance(withRouter(CommonHeading))
