import React, { useEffect, useState } from "react"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logoFull from "../Assets/logo_top_nobg.png"
import { v4 } from "uuid"
import ReactQuill from "react-quill"
import { Trans, useTranslation } from "react-i18next"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReactQuillEditorCss from "../Utils/ReactQuillEditor.module.css"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReactQuillDisplayCss from "../Utils/ReactQuillDisplay.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestion } from "@fortawesome/free-solid-svg-icons"
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons"
import {
  QuotationObj,
  createDraftContract,
} from "../HelpingFunction/Job/JobDBHelper"
import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"
import toast, { Toaster } from "react-hot-toast"
import { SUPPORTED_REDUX_FUNCTIONS } from "../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../Utils/ModalType"
import { DRAFT_CONTRACT_TYPE } from "../Component/ModalComponents/CopyDraftContractModalComponent"
import { InvoiceDetails } from "../Model/Invoice"
import InvoiceView from "../page-components/invoice/InvoiceView"
import { JOB_DEFAULT, Job } from "../Model/Job"
import { useHistory } from "react-router-dom"
import { getUserLang } from "../Utils/utiltyHelper"
import { t } from "i18next"

const CreateInvoice = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const today = new Date()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const setJob = (j: Job) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_JOB,
      data: j,
    })
  }

  const [contractDetails, setContractDetails] = useState<InvoiceDetails>({
    id: v4(),
    title: "",
    price: 0,
    description: "",
  })

  const [isSaving, setIsSaving] = useState<boolean>(false)

  useEffect(() => {
    setJob({
      ...JOB_DEFAULT,
      id: contractDetails.id ? contractDetails.id : "",
      title: contractDetails.title,
      quotationDetail: contractDetails.description,
      price: contractDetails.price,
    })
  }, [contractDetails])

  //Code to create a new invoice from KOL end.
  const createInvoice = async () => {
    setIsSaving(true)
    console.log(contractDetails)
    if (
      contractDetails.title !== "" &&
      contractDetails.description !== "" &&
      contractDetails.price > 0
    ) {
      const quotationObj: QuotationObj = {
        price: contractDetails.price,
        ServicePlanName: contractDetails.title,
        ServicePlanID: "Custom",
        quotationDetail: contractDetails.description,
        accepted: true,
      }
      const state = await createDraftContract(auth.uid, auth.uid, quotationObj)
      if (state.success) {
        console.log("Job Created")
        setContractDetails({
          ...contractDetails,
          id: state.jobID,
        })
        setIsSaving(false)
        history.push("/" + getUserLang() + "/invoice/" + state.jobID)
        // openCopyDraftContractModal(state.jobID)
      }
    } else {
      setIsSaving(false)
      toast.error(t("CreateInvoice.please-fill-error"))
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const modules = React.useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        //'image'
        ["link"],
        ["clean"],
        ["code-block"],
      ],
      // handlers: {
      //     'image': () => quillImageCallBack()
      // },
      clipboard: {
        matchVisual: false,
      },
    },
  }))

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    // 'image'
  ]
  return (
    <div className="min-h-[90svh]">
      <div className="md:grid md:grid-cols-3 ">
        <section className="border-r p-8 col-span-2 bg-gray-100 md:block hidden">
          <InvoiceView invoiceDetails={contractDetails} />
        </section>
        <section className="md:p-6 p-4">
          <div className="mt-2">
            <p className=" font-semibold  uppercase text-lg">
              <Trans>CreateInvoice.contract-details</Trans>
            </p>
            <div className="my-8">
              <p>
                <Trans>CreateInvoice.title</Trans>
              </p>
              <input
                type="text"
                placeholder={t("CreateInvoice.title-placeholder") as string}
                className="input input-bordered w-full my-4"
                onChange={(e) =>
                  setContractDetails({
                    ...contractDetails,
                    title: e.target.value,
                  })
                }
              />
              <p>
                <Trans>CreateInvoice.desc</Trans>
              </p>
              <div className="block mt-4 mb-6">
                <div
                  className={[
                    "my-4",
                    ReactQuillEditorCss.ReactQuillEditor,
                  ].join(" ")}
                  style={{
                    maxHeight: "470px",
                    overflow: "hidden",
                  }}>
                  <ReactQuill
                    placeholder={
                      t("Contract.kol-requirement-draft-message") as string
                    }
                    value={contractDetails.description}
                    onChange={(e) => {
                      if (e === "<p><br></p>") {
                        // console.log("invalid input")
                        e = ""
                      }
                      setContractDetails({
                        ...contractDetails,
                        description: e,
                      })
                    }}
                    theme="snow"
                    modules={modules}
                    formats={formats}
                  />
                </div>
              </div>
              <p>
                <Trans>CreateInvoice.bill-to</Trans>
                <span
                  className="mx-1 text-sm tooltip hover:cursor-pointer"
                  data-tip="Look4KOL will collect the payment on your behalf">
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
              </p>
              <select className="select select-bordered w-full my-4">
                <option disabled>{t("CreateInvoice.who-to-bill")}</option>
                <option disabled selected>
                  {t("CreateInvoice.l4k")}
                </option>
              </select>

              <p>
                <Trans>CreateInvoice.price</Trans>
              </p>
              <div className="flex flex-row gap-4 items-center">
                <input
                  type="number"
                  placeholder={t("CreateInvoice.price") as string}
                  className="input input-bordered w-full my-4"
                  min={0}
                  max={999999}
                  onChange={(e) =>
                    setContractDetails({
                      ...contractDetails,
                      price: +e.target.value,
                    })
                  }
                />
                <p className="font-medium">HKD</p>
              </div>
            </div>
            <button
              onClick={() => createInvoice()}
              className={classNames("btn btn-primary btn-md w-full ", {
                loading: isSaving,
              })}>
              <Trans>CreateInvoice.create-invoice</Trans>
            </button>
          </div>
        </section>
      </div>
    </div>
  )
}

export default CreateInvoice
