import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { PhoneInput, usePhoneValidation } from "react-international-phone"
import "react-international-phone/style.css"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import verifyPhoneVector from "../Assets/verifyPhone.png"
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  PhoneAuthProvider,
  linkWithCredential,
  User,
} from "firebase/auth"
import { isAuth } from "../Utils/FirebaseUtils"
import { useHistory } from "react-router-dom"
import { getUserLang } from "../Utils/utiltyHelper"
import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../Redux/SUPPORTED_REDUX_FUNCTION"
import {
  getCustomer,
  verifyCustomer,
} from "../HelpingFunction/Customer/customerDBHelper"
import { CustomerType } from "../Model/Customer"
import classNames from "classnames"
import CustomAlert from "../Component/NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../Enum/ALERT_SYSYEM"
import { Trans, useTranslation } from "react-i18next"

enum VerifyStage {
  ENTER_PHONE,
  ENTER_OTP,
}

const VerifyPhone = (props: any) => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const authLocal = useSelector((state: any) => {
    return state.firebase.auth
  })
  const setForcePhoneVerify = (value: boolean) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SIGN_UP_CUSTOMER,
      forcePhoneVerify: value,
    })
  }
  const [phone, setPhone] = useState<string>()
  const [step, setStep] = useState<VerifyStage>(VerifyStage.ENTER_PHONE)
  const [otp, setOTP] = useState<string[]>(["", "", "", "", "", ""])
  const [confirmObj, setConfirmObj] = useState<any>()
  const [customerLocal, setCustomerLocal] = useState<CustomerType>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<{
    type: ALERT_TYPE
    visible: boolean
    msg: string
  }>({
    type: ALERT_TYPE.ERROR,
    visible: false,
    msg: "",
  })

  const phoneValidation = usePhoneValidation(phone as string)

  const auth = getAuth()

  useEffect(() => {
    if (!isAuth(authLocal)) {
      history.push("/" + getUserLang())
    } else {
      getCustomer(authLocal.uid).then((res) => {
        if (res.success) {
          setCustomerLocal(res.data)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (error.visible) {
      setTimeout(() => {
        setError({ type: ALERT_TYPE.ERROR, visible: false, msg: "" })
      }, 5000)
    }
  }, [error])

  async function setUpRecaptcha(phone: string) {
    phone = phone.replace(/\s/g, "")
    const recaptchaVerifier = new RecaptchaVerifier(
      "send-otp-button",
      {
        size: "invisible",
        callback: async (response: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          await signInWithPhoneNumber(auth, phone, recaptchaVerifier)
            .then((confirmation) => {
              setConfirmObj(confirmation)
              setLoading(false)
              setStep(VerifyStage.ENTER_OTP)
              setError({
                type: ALERT_TYPE.INFO,
                visible: true,
                msg: t("VerifyPhone.sent"),
              })
            })
            .catch((err) => {
              console.log(err)
              setLoading(false)
              setError({
                type: ALERT_TYPE.ERROR,
                visible: true,
                msg: t("VerifyPhone.again"),
              })
            })
        },
      },
      auth
    )

    await recaptchaVerifier.verify()
  }

  const sendOtp = async () => {
    setLoading(true)
    if (phoneValidation.isValid) {
      try {
        setUpRecaptcha(phone as string)
      } catch (err) {
        setLoading(false)
        setError({
          type: ALERT_TYPE.ERROR,
          visible: true,
          msg: t("VerifyPhone.again"),
        })
      }
    } else {
      setLoading(false)
      setError({
        type: ALERT_TYPE.ERROR,
        visible: true,
        msg: t("VerifyPhone.wrong"),
      })
    }
  }

  const verifyOtp = async () => {
    setLoading(true)
    const finalOtp = otp.join("")
    if (finalOtp.length !== 6) {
      setLoading(false)
      setError({
        type: ALERT_TYPE.ERROR,
        visible: true,
        msg: t("VerifyPhone.otp"),
      })
    } else {
      linkAccounts(confirmObj.verificationId, finalOtp)
    }
  }

  const setVerifiedCustomer = async () => {
    await verifyCustomer(authLocal.uid, phone as string).then((res) => {
      if (res.success) {
        setTimeout(() => {
          setForcePhoneVerify(false)
          props.closeModal()
          // history.push("/" + getUserLang())
        }, 1000)
      } else {
        setLoading(false)
        setError({
          type: ALERT_TYPE.ERROR,
          visible: true,
          msg: t("VerifyPhone.again"),
        })
      }
    })
  }

  const linkAccounts = (verificationId: string, finalOtp: string) => {
    let user = auth.currentUser
    const credential = PhoneAuthProvider.credential(
      verificationId,
      otp.join("")
    )
    linkWithCredential(user as User, credential)
      .then((res) => {
        setVerifiedCustomer()
      })
      .catch((err) => {
        setLoading(false)
        setError({
          type: ALERT_TYPE.ERROR,
          visible: true,
          msg: t("VerifyPhone.again"),
        })
        console.log(err)
      })
  }

  const handleOtpInput = (id: string, value: string) => {
    let newOtp = [...otp]
    if (id === "otp-1") {
      newOtp[0] = value
      setOTP(newOtp)
      if (value !== "") {
        document.getElementById("otp-2")?.focus()
      }
    } else if (id === "otp-2") {
      newOtp[1] = value
      setOTP(newOtp)
      if (value !== "") {
        document.getElementById("otp-3")?.focus()
      } else {
        document.getElementById("otp-1")?.focus()
      }
    } else if (id === "otp-3") {
      newOtp[2] = value
      setOTP(newOtp)
      if (value !== "") {
        document.getElementById("otp-4")?.focus()
      } else {
        document.getElementById("otp-2")?.focus()
      }
    } else if (id === "otp-4") {
      newOtp[3] = value
      setOTP(newOtp)
      if (value === "") {
        document.getElementById("otp-3")?.focus()
      } else {
        document.getElementById("otp-5")?.focus()
      }
    } else if (id === "otp-5") {
      newOtp[4] = value
      setOTP(newOtp)
      if (value === "") {
        document.getElementById("otp-4")?.focus()
      } else {
        document.getElementById("otp-6")?.focus()
      }
    } else if (id === "otp-6") {
      newOtp[5] = value
      setOTP(newOtp)
      if (value === "") {
        document.getElementById("otp-5")?.focus()
      }
    }
  }

  return (
    <div className="w-full h-full  mx-auto max-w-5xl md:grid md:grid-cols-2 gap-8 ">
      <div>
        {step === VerifyStage.ENTER_PHONE && (
          <div className="h-auto md:p-0 p-8">
            <h2 className="md:text-4xl text-3xl font-bold my-4">
              <Trans>VerifyPhone.t-1</Trans>
            </h2>
            <h3 className="font-medium max-w-sm md:text-base text-sm">
              <Trans>VerifyPhone.d-1</Trans>
            </h3>
            <section className="md:my-8 my-6">
              <PhoneInput
                inputClassName="w-full font-medium"
                countrySelectorStyleProps={{
                  buttonStyle: { padding: "10px" },
                }}
                defaultCountry="hk"
                value={phone}
                onChange={(phone) => setPhone(phone)}
              />
            </section>

            <section>
              <button
                id="send-otp-button"
                onClick={() => {
                  sendOtp()
                }}
                disabled={!phoneValidation.isValid}
                className={classNames("btn btn-primary w-48 normal-case", {
                  loading: loading,
                })}>
                <Trans>VerifyPhone.b-1</Trans>
              </button>
            </section>
          </div>
        )}
        {step === VerifyStage.ENTER_OTP && (
          <div className="h-auto">
            <div className="md:px-0 px-8">
              <button onClick={() => setStep(VerifyStage.ENTER_PHONE)}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <h2 className="md:text-4xl text-3xl font-bold my-4 ">
                <Trans>VerifyPhone.t-2</Trans>
              </h2>
              <h3 className="font-medium max-w-sm md:text-base text-sm ">
                <Trans>VerifyPhone.d-2</Trans> <br />
                {phone}.
              </h3>
            </div>

            <section className="my-8 px-6 md:px-0">
              <div className="flex flex-row gap-3">
                <input
                  id="otp-1"
                  className="h-16 w-12 border rounded-xl text-center text-2xl font-bold focus:shadow-md"
                  maxLength={1}
                  value={otp[0]}
                  onChange={(e) => handleOtpInput("otp-1", e.target.value)}
                  type="text"
                />
                <input
                  id="otp-2"
                  className="h-16 w-12 border rounded-xl text-center text-2xl font-bold focus:shadow-md"
                  maxLength={1}
                  value={otp[1]}
                  onChange={(e) => handleOtpInput("otp-2", e.target.value)}
                  type="text"
                />
                <input
                  id="otp-3"
                  className="h-16 w-12 border rounded-xl text-center text-2xl font-bold focus:shadow-md"
                  maxLength={1}
                  value={otp[2]}
                  onChange={(e) => handleOtpInput("otp-3", e.target.value)}
                  type="text"
                />
                <input
                  id="otp-4"
                  className="h-16 w-12 border rounded-xl text-center text-2xl font-bold focus:shadow-md"
                  maxLength={1}
                  value={otp[3]}
                  onChange={(e) => handleOtpInput("otp-4", e.target.value)}
                  type="text"
                />
                <input
                  id="otp-5"
                  className="h-16 w-12 border rounded-xl text-center text-2xl font-bold focus:shadow-md"
                  maxLength={1}
                  value={otp[4]}
                  onChange={(e) => handleOtpInput("otp-5", e.target.value)}
                  type="text"
                />
                <input
                  id="otp-6"
                  className="h-16 w-12 border rounded-xl text-center text-2xl font-bold focus:shadow-md"
                  maxLength={1}
                  value={otp[5]}
                  onChange={(e) => handleOtpInput("otp-6", e.target.value)}
                  type="text"
                />
              </div>
            </section>
            <section className="md:px-0 px-6">
              <button
                onClick={() => verifyOtp()}
                className={classNames("btn btn-success w-32 normal-case", {
                  loading: loading,
                })}>
                <Trans>VerifyPhone.b-2</Trans>
              </button>
            </section>
          </div>
        )}
        {error.visible && (
          <section className="my-4 md:px-0 px-8">
            <CustomAlert
              title={error.msg}
              alertType={error.type}
              buttonType={{
                type: BUTTON_TYPE.NONE,
                buttonText: undefined,
                buttonDisabledText: undefined,
                buttonIcon: undefined,
              }}
            />
          </section>
        )}
      </div>

      <div>
        <img
          className="w-96 md:w-full p-2 mx-auto  h-full object-cover md:block hidden"
          src={verifyPhoneVector}
        />
      </div>
    </div>
  )
}

export default VerifyPhone
