import React from "react"
import { ViewCaseStudyModalData } from "../../Model/Modal"
import CaseStudiesList from "../CaseStudiesList"

const ViewCaseStudyModalComponent = (props: ViewCaseStudyModalData) => {
  return (
    <div className="container mx-auto overflow-y-auto my-auto">
      <CaseStudiesList KOLID={props.metaData.KOLID} />
    </div>
  )
}

export default ViewCaseStudyModalComponent
