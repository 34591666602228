export const hkBankList = [
  {
    code: "003",
    bankCHI: "渣打銀行（香港）",
    bankENG: "Standard Chartered Bank (Hong Kong)",
  },
  {
    code: "004",
    bankCHI: "香港上海滙豐銀行",
    bankENG: "The Hongkong and Shanghai Banking Corporation",
  },
  {
    code: "009",
    bankCHI: "中國建設銀行（亞洲）",
    bankENG: "China Construction Bank (Asia)",
  },
  {
    code: "012",
    bankCHI: "中國銀行（香港）",
    bankENG: "Bank of China (Hong Kong)",
  },
  {
    code: "015",
    bankCHI: "東亞銀行",
    bankENG: "The Bank of East Asia",
  },
  {
    code: "016",
    bankCHI: "星展銀行（香港）",
    bankENG: "DBS Bank (Hong Kong)",
  },
  {
    code: "018",
    bankCHI: "中信銀行（國際）",
    bankENG: "China CITIC Bank International",
  },
  {
    code: "020",
    bankCHI: "招商永隆銀行",
    bankENG: "CMB Wing Lung Bank",
  },
  {
    code: "024",
    bankCHI: "恒生銀行",
    bankENG: "Hang Seng Bank",
  },
  {
    code: "025",
    bankCHI: "上海商業銀行",
    bankENG: "Shanghai Commercial Bank",
  },
  {
    code: "028",
    bankCHI: "大眾銀行（香港）",
    bankENG: "Public Bank (Hong Kong)",
  },
  {
    code: "035",
    bankCHI: "華僑永亨銀行",
    bankENG: "OCBC Wing Hang Bank",
  },
  {
    code: "039",
    bankCHI: "集友銀行",
    bankENG: "Chiyu Banking Corporation",
  },
  {
    code: "040",
    bankCHI: "大新銀行",
    bankENG: "Dah Sing Bank",
  },
  {
    code: "041",
    bankCHI: "創興銀行",
    bankENG: "Chong Hing Bank",
  },
  {
    code: "043",
    bankCHI: "南洋商業銀行",
    bankENG: "Nanyang Commercial Bank",
  },
  {
    code: "072",
    bankCHI: "中國工商銀行（亞洲）",
    bankENG: "Industrial and Commercial Bank of China (Asia)",
  },
  {
    code: "250",
    bankCHI: "花旗銀行（香港）",
    bankENG: "Citibank (Hong Kong)",
  },
  {
    code: "382",
    bankCHI: "交通銀行（香港）",
    bankENG: "Bank of Communications (Hong Kong)",
  },
  {
    code: "387",
    bankCHI: "眾安銀行",
    bankENG: "ZA Bank",
  },
  {
    code: "388",
    bankCHI: "Livi",
    bankENG: "Livi Bank",
  },
  {
    code: "389",
    bankCHI: "Mox",
    bankENG: "Mox Bank",
  },
  {
    code: "390",
    bankCHI: "匯立銀行",
    bankENG: "Welab Bank",
  },
  {
    code: "391",
    bankCHI: "富融銀行",
    bankENG: "Fusion Bank",
  },
  {
    code: "392",
    bankCHI: "平安壹賬通銀行（香港）",
    bankENG: "Ping An OneConnect Bank (Hong Kong)",
  },
  {
    code: "393",
    bankCHI: "螞蟻銀行（香港）",
    bankENG: "Ant Bank (Hong Kong)",
  },
  {
    code: "395",
    bankCHI: "天星銀行",
    bankENG: "Airstar Bank",
  },
]

export function getHKBankName(bankCode) {
  let matchBank = hkBankList.find((c) => {
    return c.code === bankCode
  })
  matchBank =
    typeof matchBank !== "undefined" && matchBank !== null
      ? localStorage.getItem("i18n-lang") === "en"
        ? matchBank.bankENG
        : matchBank.bankCHI
      : ""
  return matchBank
}

export function returnBankOptions() {
  let bankOptions = []
  hkBankList.map((e) => {
    bankOptions.push({
      value: e.code,
      label: localStorage.getItem("i18n-lang") === "en" ? e.bankENG : e.bankCHI,
    })
  })

  return bankOptions
}
