import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, withRouter } from "react-router-dom"
import { Chatroom, ChatroomJob } from "../../../Model/Chatroom"
import { Trans } from "react-i18next"
import {
  getAllChatData,
  getChatroomWithCondition,
  getMessage,
} from "../../../HelpingFunction/Chatroom/chatroomDBHelper"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import DivContentLoader from "../../../Component/DivContentLoader"
import { isAuth } from "../../../Utils/FirebaseUtils"
import JobMsg from "../../../Component/HeadingMessageManager/MessageItem/JobMsg"
import { motion } from "framer-motion"
import {
  routerPageDesktop,
  routerTransitionDesktop,
} from "../../../Utils/PageTransition"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "../../../Component/HeadingMessageManager/HeadingMessageManager.module.css"
import { setMessageAsRead } from "../../../HelpingFunction/Member/memberDBHelper"
import {
  CHATROOM_TYPE,
  SYSTEM_MESSAGE_TYPE,
  UNREAD_MESSAGE_TYPE,
} from "../../../Enum/APP_TYPE"
import { getAuthUID, getQuery } from "../../../Utils/QueryHelper"
import { getRole } from "../../../Utils/utiltyHelper"

const QuickJobAccess: React.FC = (props) => {
  let history = useHistory()
  const dispatch = useDispatch()

  const isLoaded: boolean = useSelector((state: any) => {
    return state.ChatRoomInfo.isLoadedChatrooms
  })

  const chatrooms: ChatroomJob[] = useSelector((state: any) => {
    return state.ChatRoomInfo.chatrooms
  })

  const messages = useSelector((state: any) => {
    return state.NotificationManager.msg
  })

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const params = useSelector((state: any) => {
    return state.QueryManager.params
  })

  const viewID = getQuery(params, "viewAs")

  useEffect(() => {
    if (!isLoaded || viewID || !viewID) {
      getAllChats()
    }
  }, [isLoaded, viewID])

  const getAllChats = async () => {
    await getChatroomWithCondition(getAuthUID(params), {
      type: "",
      startAfter: "",
    }).then(async (res) => {
      if (res.success) {
        if (res.chatrooms.length > 0) {
          const role = await getRole(viewID)
          await getAllChatData(auth.uid, role, res.chatrooms).then((res1) => {
            if (res1.success) {
              let chatrooms = res1.data
              if (viewID) {
                chatrooms = chatrooms.filter(
                  (e) =>
                    e.participateUID.every((id) => id !== auth.uid) &&
                    e.type !== CHATROOM_TYPE.CHATGPT
                )
              }
              setChatRoomReducer(chatrooms)
            }
          })
        } else {
          setChatRoomReducer([])
        }
      } else {
        setChatRoomReducer([])
      }
    })
  }

  useEffect(() => {
    if (isAuth(auth)) {
      getContractMessage()
    }
  }, [auth])

  const setMessages = (msg: any[]) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_MESSAGES,
      data: msg,
    })
  }

  const getContractMessage = () => {
    getMessage(
      { allMessage: false, isRead: false },
      false,
      getAuthUID(params),
      UNREAD_MESSAGE_TYPE.JUST_CHAT
    ).then((results) => {
      setMessages(results.data)
    })
  }

  const numOfUnreadChat = useSelector((state: any) => {
    return state.MessagingSystem.numOfUnreadChats
  })

  const setMsgIsRead = (msgID: string) => {
    setMessageAsRead(getAuthUID(params), msgID).then((result) => {
      if (result.success) {
        let allMsg = messages
        let newMsg = allMsg.find((e: { id: string }) => e.id === msgID)
        let index = allMsg.findIndex((e: { id: string }) => e.id === msgID)
        allMsg[index] = newMsg
        setMessages(allMsg)
      }
    })
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_NUM_OF_UNREAD_CHAT,
      numOfUnread: numOfUnreadChat - 1,
    })
  }

  const setAllMsgIsRead = (chatroomID: string) => {
    getMessage(
      { allMessage: false, isRead: false },
      true,
      getAuthUID(params),
      UNREAD_MESSAGE_TYPE.JUST_CHAT
    ).then((results) => {
      results.data.map((msg) => {
        if (
          (msg.url.includes("c/" + chatroomID) ||
            msg.url.includes("c?chatroomID=" + chatroomID)) &&
          msg.type !== SYSTEM_MESSAGE_TYPE.CONTRACT &&
          msg.type !== SYSTEM_MESSAGE_TYPE.SYSTEM
        ) {
          setMsgIsRead(msg.id)
        }
      })
      setMessages(results.data)
    })
  }

  const setChatRoomReducer = (state: Chatroom[]): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOMS,
      data: state,
    })
  }

  if (isLoaded) {
    return (
      <motion.div
        initial="out"
        exit="out"
        animate="in"
        variants={routerPageDesktop}
        transition={routerTransitionDesktop}
        className="h-full">
        <div
          className={[classes.HeadingMessageManager].join(" ")}
          style={{ maxHeight: "auto", overflow: "visible" }}
          aria-labelledby="noti-menu">
          <div
            className="h-full grid"
            style={{ gridTemplateRows: "50px auto 50px" }}>
            <h2 className="font-bold text-lg p-2">
              <Trans>QuickJobAccess.title</Trans>
            </h2>
            <ul
              style={{ paddingLeft: "0", height: "23rem" }}
              className="overflow-y-scroll">
              {Array.isArray(chatrooms) && chatrooms.length > 0 ? (
                chatrooms.map((chatroom: ChatroomJob, index: number) => {
                  if (index >= 0) {
                    return (
                      <JobMsg
                        key={chatroom.id + "index"}
                        chatroom={chatroom}
                        messages={messages}
                        setAllMsgIsRead={setAllMsgIsRead}
                      />
                    )
                  }
                  return <div />
                })
              ) : (
                <div className="p-4 text-center text-gray-500 font-bold">
                  <Trans>ErrorList.chat-not-found</Trans>
                </div>
              )}
            </ul>
            <button
              className="btn btn-sm btn-primary block rounded-2xl mt-2 w-full mx-auto"
              onClick={() => {
                history.push({
                  pathname:
                    "/" +
                    (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
                    "/c",
                })
              }}>
              <Trans>QuickJobAccess.access-chatroom</Trans>
            </button>
          </div>
        </div>
      </motion.div>
    )
  }
  return (
    <div>
      <h2 className="font-bold text-lg mb-2 p-2">
        <Trans>QuickJobAccess.title</Trans>
      </h2>
      <div>
        <DivContentLoader w={"98%"} h={60} r={8} />
      </div>
      <div>
        <DivContentLoader w={"98%"} h={60} r={8} />
      </div>
      <div>
        <DivContentLoader w={"98%"} h={60} r={8} />
      </div>
    </div>
  )
}

export default withRouter(QuickJobAccess)
