import React, { useImperativeHandle, useState } from "react"
import { NewFolderModalData } from "../../Model/Modal"
import { Trans } from "react-i18next"

const NewFolderModalComponent = (props: NewFolderModalData, ref: any) => {
  const [newFolderName, setNewFolderName] = useState<string>("")
  useImperativeHandle(ref, () => ({
    returnData() {
      return newFolderName
    },
  }))
  return (
    <div className="container mx-auto">
      <label>
        <Trans>NewFolderModal.folder-name</Trans>
      </label>

      <p>
        <Trans>NewFolderModal.folder-name-warning</Trans>
      </p>

      <input
        type="text"
        className="block w-full rounded bg-gray-100 mb-4 h-12"
        maxLength={50}
        value={newFolderName}
        onChange={(e) => {
          if (e.target.value.length <= 50) {
            let newStr = e.target.value.replace(
              /[^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFF0-9a-zA-Z0-9]+/g,
              ""
            )
            setNewFolderName(newStr)
          }
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            const str = newFolderName.replace(" ", "")
            if (str.length > 0) {
              props.returnFn(newFolderName)
              setNewFolderName("")
              props.closeModal()
            }
          }
        }}
      />
      <div
        className={[
          "block float-right text-xs",
          newFolderName.length >= 50 ? "text-red-500" : "text-gray-500",
        ].join(" ")}>
        {newFolderName.replace(/ /g, "").length + " / 50"}
      </div>
    </div>
  )
}
export default React.forwardRef(NewFolderModalComponent)
