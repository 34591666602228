import React from "react"
import parse from "html-react-parser"
import { NotiMessage } from "../../../Model/Message"

interface PropsType {
  msg: NotiMessage
}

const JobMsgItem: React.FC<PropsType> = ({ msg }) => {
  const enMsgDisplay = () => {
    return <>{parse(msg.msg)}</>
  }
  const chiMsgDisplay = () => {
    return <>{parse(msg.msgCHI)}</>
  }

  const isEn = localStorage.getItem("i18n-lang") === "en"
  const isNotEn = localStorage.getItem("i18n-lang") !== "en"
  return (
    <li className="mx-4 md:shadow-none md:mx-0 px-3 md:px-1">
      <div className="grid">
        <div className="pl-1" style={{ maxWidth: "200px" }}>
          <div className="truncate w-full text-xs text-gray-500 my-1">
            {isEn && msg.title !== "" && enMsgDisplay()}
            {isNotEn && msg.titleCHI
              ? chiMsgDisplay()
              : isNotEn && enMsgDisplay()}
          </div>
        </div>
      </div>
    </li>
  )
}
export default JobMsgItem
