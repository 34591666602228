import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getKOL } from "../../HelpingFunction/KOL/kolDBHelper"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import KOL from "../../Model/KOL"
import { useHistory, useLocation } from "react-router-dom"
import KOLSmallCard from "../../Component/KOLSmallCard"
import CaseStudiesList from "../../Component/CaseStudiesList"
import KOLFollowerGroup from "../p/KOLFollowerGroup"
import KOLPlanList from "../../Component/KOLPlanList"
import { Trans } from "react-i18next"
import { analytics } from "../../Config/firebase"
import { logEvent } from "firebase/analytics"
import { createChatroom } from "../../HelpingFunction/Chatroom/chatroomDBHelper"
import { isAuth, returnIdTokenResult } from "../../Utils/FirebaseUtils"
import { CHATROOM_PAGE_TYPE, CHATROOM_TYPE, ROLE } from "../../Enum/APP_TYPE"
import { getRole, getUserLang } from "../../Utils/utiltyHelper"
import KOLFollowerInterest from "../p/KOLFollowerInterest"
import KOLNewestIGStatus from "../s/KOLNewestIGStatus"
import { getQuery } from "../../Utils/QueryHelper"

interface PropsType {
  closeModal?: () => void
}

interface ContactKOLButtonPropsType {
  isInModal: boolean
  closeModal?: () => void
}

const ContactKOLButton: React.FC<ContactKOLButtonPropsType> = ({
  isInModal,
  closeModal,
}) => {
  const dispatch = useDispatch()
  let history = useHistory()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const paramList = useSelector((state: any) => {
    return state.QueryManager.params
  })

  const kol = useSelector((state: any) => {
    return state.ChatRoomInfo.kol
  })

  let params = {
    type: getQuery(paramList, "type"),
    chatroomID: getQuery(paramList, "chatroomID"),
    metaPath: getQuery(paramList, "metaPath"),
  }

  const isSearchPage = location.pathname.includes("/s")
  const isProjectPage = location.pathname.includes("/project")
  const isRecommendationPage = location.pathname.includes("/recommendation")
  const showRequestBtn = isSearchPage && !isProjectPage && !isRecommendationPage

  const [role, setRole] = useState<ROLE>(ROLE.KOL)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isCreatingChatroom, setIsCreatingChatroom] = useState<boolean>(false)

  const setContractType = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_PAGE_TYPE,
      data: CHATROOM_PAGE_TYPE.CONTRACT,
    })
  }
  const resetChatrooms = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.RESET_CHATROOMS,
    })
  }

  const setChatType = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_PAGE_TYPE,
      data: CHATROOM_PAGE_TYPE.CHAT_TEXT,
    })
  }

  useEffect(() => {
    const getRoleFn = async () => {
      const role = await getRole()
      setRole(role)
    }

    getRoleFn()
  }, [auth])

  const getCustomerUserName = (): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      const getState = await returnIdTokenResult()
      if (getState.success) {
        return resolve(
          typeof getState.token.userName !== "undefined"
            ? getState.token.userName
            : ""
        )
      }
      return resolve("")
    })
  }

  const createNewJobWithKOL = async (): Promise<void> => {
    history.push({
      pathname: "/" + getUserLang() + "/c",
      search:
        "?" +
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        new URLSearchParams({
          chatroomID: params.chatroomID,
          type: "contract",
          metaPath: "new",
        }),
    })
    if (isInModal && closeModal) {
      closeModal()
    }

    setContractType()
  }

  const submitRequest = async () => {
    setIsCreatingChatroom(true)
    resetChatrooms()

    const customerName = await getCustomerUserName()
    createChatroom(auth.uid, kol.id, kol.userName, customerName).then(
      (result) => {
        if (result.success) {
          setIsCreatingChatroom(false)

          // if this is mobile and `closeModal()` is not null
          if (isInModal && closeModal) {
            closeModal()
            setChatType()
          }

          history.push({
            pathname: "/" + getUserLang() + "/c",
            search:
              "?" + new URLSearchParams({ chatroomID: result.chatroomID }),
          })
        } else {
          setIsCreatingChatroom(false)
        }
      }
    )
  }

  if (showRequestBtn && role !== ROLE.KOL) {
    if (isAuth(auth)) {
      return (
        <div className="w-full md:-bottom-2 pt-4 pb-2 ">
          <button
            className={[
              "btn btn-primary w-full font-bold text-lg mb-8 lg:mb-0",
              isCreatingChatroom ? "loading" : "",
            ].join(" ")}
            disabled={isCreatingChatroom}
            onClick={() => {
              submitRequest()
            }}>
            <Trans>ChatKOLInfo.contact-kol</Trans>
          </button>
        </div>
      )
    }
    return (
      <div className="w-full md:-bottom-2 pt-4 pb-2 ">
        <button
          className="btn btn-primary w-full font-bold text-lg mb-8 lg:mb-0"
          onClick={() => {
            let redirectObj = {
              requiredRedirect: true,
              path: location.pathname,
            }
            localStorage.setItem("redirectObj", JSON.stringify(redirectObj))

            history.push(
              "/" +
                (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk") +
                "/login"
            )
          }}>
          <Trans>ChatKOLInfo.login-required</Trans>
        </button>
      </div>
    )
  } else if (!isProjectPage && !isRecommendationPage) {
    return (
      <div className="w-full md:-bottom-2 pt-4 pb-2 ">
        <button
          className={[
            "btn btn-primary w-full font-bold text-lg",
            isLoading ? "loading" : "",
          ].join(" ")}
          disabled={isLoading}
          onClick={() => {
            if (isAuth(auth)) {
              createNewJobWithKOL()
              logEvent(analytics, "create_contract", {
                kolID: kol.id,
                customerID: auth.uid,
              })
            } else {
              history.push("/" + getUserLang() + "/login")
            }
          }}>
          <Trans>ChatKOLInfo.create-job</Trans>
        </button>
        <span className="my-1 text-gray-400 text-xs">
          <Trans>ChatKOLInfo.create-job-des</Trans>
        </span>
      </div>
    )
  }
  return <div></div>
}

const ChatKOLInfo: React.FC<PropsType> = (props) => {
  const dispatch = useDispatch()

  const location = useLocation()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const chatroomInfo = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomInfo
  })

  const kol = useSelector((state: any) => {
    return state.ChatRoomInfo.kol
  })

  const isLoadedJobKOL = useSelector((state: any) => {
    return state.ChatRoomInfo.isLoadedJobKOL
  })

  const isInModal =
    typeof props.closeModal !== "undefined" && props.closeModal !== null

  const isSearchPage = location.pathname.includes("/s")
  const isProjectPage = location.pathname.includes("/project")
  const isRecommendationPage = location.pathname.includes("/recommendation")

  useEffect(() => {
    const isChatGPTChatroom = chatroomInfo.type === CHATROOM_TYPE.CHATGPT
    if (chatroomInfo && !isSearchPage && !isChatGPTChatroom) {
      const kolID = chatroomInfo.participateUID.find(
        (e: string) => e !== auth.uid
      )
      getKOL(kolID).then((result) => {
        if (result.success) {
          setChatroomKOL(result.data)
        }
      })
    }
  }, [chatroomInfo])

  const setChatroomKOL = (state: KOL) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_JOB_KOL,
      data: state,
    })

  if (isLoadedJobKOL) {
    return (
      <div className="relative bg-gray-100 pb-10">
        <div className="border-b shadow p-4 mb-2 bg-white">
          {!isInModal && (
            <h2 className="text-2xl my-2 font-bold">
              <Trans>ChatKOLInfo.kol-info</Trans>
            </h2>
          )}
          {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
          {/*@ts-ignore*/}
          <KOLSmallCard key={kol.id} data={kol} />

          <hr className="my-2" />
          <ContactKOLButton
            isInModal={isInModal}
            closeModal={props.closeModal}
          />
        </div>

        <div className="border shadow p-4 my-2 bg-white">
          <h2 className="my-2 text-2xl font-medium">
            <Trans>ChatKOLInfo.newest</Trans>
          </h2>
          <KOLNewestIGStatus KOLID={kol.id} />
        </div>

        <div className="border shadow p-4 my-2 bg-white">
          <h2 className="my-2 text-2xl font-medium">
            <Trans>ChatKOLInfo.case-study</Trans>
          </h2>
          <CaseStudiesList KOLID={kol.id} />
        </div>

        <div className="border shadow p-4 my-2 bg-white">
          <h2 className="my-2 text-2xl font-medium">
            <Trans>ChatKOLInfo.follower-group</Trans>
          </h2>
          <KOLFollowerGroup KOLID={kol.id} />
        </div>

        <div className="border shadow p-4 my-2 bg-white">
          <h2 className="my-2 text-2xl font-medium">
            <Trans>ChatKOLInfo.service-plan</Trans>
          </h2>
          <KOLPlanList KOLID={kol.id} isKOL={false} />
        </div>

        <div className="border shadow p-4 my-2 bg-white">
          <h2 className="my-2 text-2xl font-medium">
            <Trans>ChatKOLInfo.follower-percentage</Trans>
          </h2>
          <KOLFollowerInterest userName={kol.userName} />
        </div>

        <div className="px-2">
          <ContactKOLButton
            isInModal={isInModal}
            closeModal={props.closeModal}
          />
        </div>
      </div>
    )
  }
  return (
    <div className="relative bg-gray-100 pb-10">
      <div className="border-b shadow p-4 mb-2 bg-white">
        <h2 className="text-2xl my-2 font-bold">
          <Trans>ChatKOLInfo.kol-info</Trans>
        </h2>
        {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
        {/*@ts-ignore*/}
        <KOLSmallCard key={kol.id} data={kol} />
      </div>

      <div className="border shadow p-4 my-2 bg-white">
        <h2 className="my-2 text-2xl font-medium">
          <Trans>ChatKOLInfo.case-study</Trans>
        </h2>
        {/*<CaseStudiesList KOLID={kol.id} />*/}
      </div>

      <div className="border shadow p-4 my-2 bg-white">
        <h2 className="my-2 text-2xl font-medium">
          <Trans>ChatKOLInfo.follower-group</Trans>
        </h2>
        {/*<KOLFollowerGroup KOLID={kol.id} />*/}
      </div>

      <div className="border shadow p-4 my-2 bg-white">
        <h2 className="my-2 text-2xl font-medium">
          <Trans>ChatKOLInfo.service-plan</Trans>
        </h2>
        {/*<KOLPlanList KOLID={kol.id} isKOL={false} />*/}
      </div>

      {isSearchPage ? (
        <div className="w-full absolute bg-gradient-to-b from-transparent via-gray-50 to-gray-100 left-0 bottom-22 md:-bottom-2  px-4 pt-4 pb-4 mb-2">
          <button className="btn btn-primary w-full font-bold text-lg mb-8 lg:mb-0">
            <Trans>ChatKOLInfo.login-required</Trans>
          </button>
        </div>
      ) : (
        !isRecommendationPage &&
        !isProjectPage && (
          <div className="w-full absolute bg-gradient-to-b from-transparent via-gray-50 to-gray-100 bottom-22 md:-bottom-2 shadow px-4 pt-4 pb-4 mb-2">
            <h2 className="my-2 text-2xl font-medium">
              <Trans>ChatKOLInfo.create-job</Trans>
            </h2>
            <button
              className="btn w-full btn-outline font-bold text-lg"
              disabled={true}>
              <Trans>ChatKOLInfo.create-job</Trans>
            </button>
            <span className="my-1 text-gray-500 text-sm">
              <Trans>ChatKOLInfo.create-job-des</Trans>
            </span>
          </div>
        )
      )}
    </div>
  )
}
export default ChatKOLInfo
