export const KOLCombineDefault: KOLCombine = {
  objectID: "",
  id: "",
  media: "ig",
  userName: "",
  description: "",
  nationality: "",
  type: [],
  isPublish: false,
  isVerified: false,
  gender: "3",
  genre: -1,
  genreCategory: -1,
  accountType: -1,
  isFace: -1,
  jobType: -1,
  isLook4kolReg: false,
  minPrice: 0,
  maxPrice: 0,
  basicPrice: 0,
  basicScore: 0,
  noOfFollowers: 0,
  noOfFollowing: 0,
  audience_city: {},
  audience_country: {},
  audience_gender_age: {},
  audience_locale: {},
  percentages: [],
  topGenreID: "",
  topGenrePercentage: 0,
  topCateID: "",
  topCatePercentage: 0,
  topThreeGenre: [],
  topThreeCate: [],
  kolScore: 0,
}
export const KOLCombineWithAvatarDefault: KOLCombineWithAvatar = {
  ...KOLCombineDefault,
  avatarUrl: "",
}

export type KOLCombineWithAvatar = KOLCombine & { avatarUrl: string }

export type KOLCombine = KOLCombineDefaultType &
  KOLCombineKOLProfile &
  KOLCombineKOLPlan &
  KOLCombineCrawler &
  KOLCombineFB &
  KOLCombineNeo4j

export interface KOLCombineDefaultType {
  objectID: string
  id: string
  media: "ig"
  genre: -1 | string
  genreCategory: -1 | string
  accountType: -1 | string
  isFace: -1 | boolean
  jobType: -1 | string
}

export interface KOLCombineKOLProfile {
  objectID?: string
  userName: string
  coverImages?: string[]
  description: string
  nationality: string
  type: string[]
  isPublish: boolean
  gender?: "1" | "2" | "3"
  isVerified: boolean
  isLook4kolReg: boolean
}

export interface KOLCombineKOLPlan {
  objectID?: string
  minPrice: number
  maxPrice: number
}

export interface KOLCombineCrawler {
  objectID?: string
  basicPrice: number
  // from crawler engagement rate
  basicScore: number
  noOfFollowers: number
  noOfFollowing: number
}

export interface KOLCombineFB {
  objectID?: string
  //from facebook
  audience_city: object | string[]
  audience_country: object | string[]
  audience_gender_age: object | string[]
  audience_locale: object | string[]
}

export interface KOLCombineNeo4j {
  objectID?: string
  //from neo4j
  userID?: string
  percentages: KOLCombinePercentageType[]
  topGenreID: string
  topGenrePercentage: number
  topCateID: string
  topCatePercentage: number
  topThreeGenre: string[]
  topThreeCate: string[]
  kolScore?: number
}

export interface KOLCombinePercentageType {
  count: number
  item: string
  percentages: number
}
