import { SCROLL_DIRECTION } from "../Enum/AUTO_SCROLLER_TYPE"

export const autoScrollHelper = (
  ele: HTMLElement,
  setUpCondition: boolean,
  initiateScrollCondition: boolean,
  scrollFn: () => void,
  scrollDir: SCROLL_DIRECTION
) => {
  if (setUpCondition) {
    const scrollOffset = 5

    let scrollTop = ele!.scrollTop
    let scrollHeight = ele!.scrollHeight
    let offsetHeight = ele!.offsetHeight
    let contentHeight
    switch (scrollDir) {
      case SCROLL_DIRECTION.BOTTOM_DETECTION:
        contentHeight = scrollHeight - offsetHeight - scrollOffset
        if (contentHeight <= scrollTop && initiateScrollCondition) {
          scrollFn()
        }
        break
      case SCROLL_DIRECTION.TOP_DETECTION:
        contentHeight = scrollOffset
        if (contentHeight >= scrollTop && initiateScrollCondition) {
          scrollFn()
        }
        break
      default:
        break
    }
  }
}
