import React, { useEffect, useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Job } from "../../../Model/Job"
import QuotationPage from "./QuotationPage"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import ContractMain from "./ContractMain"
import { CONTRACT_STEP } from "../../../Enum/CONTRACT"
import ContractPayment from "./ContractPayment"
import { Schedule } from "../../../Model/Schedule"
import { doc, getFirestore, onSnapshot } from "firebase/firestore"
import firebaseApp from "../../../Config/firebase"
import { getQuery, useQuery } from "../../../Utils/QueryHelper"
import ContractToolBar from "./ContractToolBar"

const db = getFirestore(firebaseApp)

interface PropsType {
  id?: string
}

const Contract: React.FC<PropsType> = ({ id }) => {
  const dispatch = useDispatch()
  let history = useHistory()

  const paramList = useSelector((state: any) => {
    return state.QueryManager.params
  })

  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })

  const step = useSelector((state: any) => {
    return state.ContractManager.stepHeader
  })

  let params = {
    type: getQuery(paramList, "type"),
    chatroomID: getQuery(paramList, "chatroomID"),
    metaPath: getQuery(paramList, "metaPath"),
  }

  const updateJob = (state: any): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_JOB,
      data: state,
    })
  }

  const setSchedule = (schedules: Schedule[]) => {
    if (Array.isArray(schedules) && schedules) {
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.SET_SCHEDULES,
        data: schedules,
      })
    }
  }

  const setContractStep = (type: CONTRACT_STEP): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CONTRACT_STEP,
      data: type,
    })
  }

  //get job from router params
  useEffect(() => {
    let jID = id

    if (params.metaPath) {
      jID = params.metaPath
    }

    if (jID !== "") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onSnapshot(doc(db, "Job", jID), (doc) => {
        if (doc.exists()) {
          const j: Job = {
            id: doc.id,
            ...doc.data(),
          } as Job
          updateJob(j)
          setSchedule(j.schedules)
          //set default step
          const isQuotationPage = !j.isSubmitQuotation || !j.isConfirmQuotation

          const isContractPage =
            !j.accepted && j.isSubmitQuotation && j.isConfirmQuotation

          const isPaymentPage = j.accepted && j.isSubmitQuotation

          const q = CONTRACT_STEP.QUOTATION
          const c = CONTRACT_STEP.CONTRACT
          const p = CONTRACT_STEP.PAYMENT

          setContractStep(isQuotationPage ? q : isContractPage ? c : p)
        } else {
          history.push(
            "/" + (localStorage.getItem("i18n-lang") === "en" ? "en" : "hk")
          )
        }
      })
    }
  }, [params.metaPath, id])

  return (
    <div>
      <div className="sticky top-0 z-10 hidden md:inline-block w-full bg-white shadow rounded-lg p-2">
        <ContractToolBar />
      </div>

      <div className="mt-4">
        {step === CONTRACT_STEP.QUOTATION && (
          <div className="p-4 rounded-lg  bg-white">
            <QuotationPage />
          </div>
        )}

        {step === CONTRACT_STEP.CONTRACT && (
          <div className="p-4 rounded-lg  bg-white">
            <ContractMain />
          </div>
        )}

        {step === CONTRACT_STEP.PAYMENT && (
          <div className="p-2">
            <ContractPayment />
          </div>
        )}
      </div>
    </div>
  )
}
export default Contract
