import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"
import { Reducer } from "react"

interface actionTypes {
  type: string
  data: string
}

export interface HelpDocData {
  isOpened: boolean
  currentPath: string
}

export interface modalInitStateType {
  doc: HelpDocData | null
}

const initialState: modalInitStateType = {
  doc: {
    isOpened: false,
    currentPath: "",
  },
}

const HelpDocManager: Reducer<modalInitStateType, actionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SUPPORTED_REDUX_FUNCTIONS.DIRECT_TO_HELP_DOC:
      return {
        ...state,
        doc: {
          isOpened: true,
          currentPath:
            "/" +
            (localStorage.getItem("i18n-lang") === "en" ? "eng" : "zh") +
            "/" +
            action.data,
        },
      }
    case SUPPORTED_REDUX_FUNCTIONS.HELP_DOC_RESET:
      return {
        ...state,
        doc: {
          isOpened: false,
          currentPath: "",
        },
      }
    default:
      return state
  }
}

export default HelpDocManager
