import React from "react"
import Contract from "../../page-components/c/contract/Contract"

const ShowDraftContractModalComponent = (props: any) => {
  if (props.metaData !== null) {
    return (
      <div className="pb-20">
        <Contract id={props.metaData} />
      </div>
    )
  }
  return <div>Loading...</div>
}

export default ShowDraftContractModalComponent
