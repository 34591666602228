import { faChevronLeft, faFileInvoice } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { getUserLang } from "../../Utils/utiltyHelper"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../Utils/ModalType"
import { Trans } from "react-i18next"

const InvoiceHeader = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const isCreateInvoicePage =
    window.location.pathname.includes("create-invoice")

  const openInvoiceModal = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.INVOICE_PREVIEW_MODAL,
      metaData: null,
    })
  }

  return (
    <div className="py-2 px-4 border-b">
      <div className="grid grid-cols-5">
        <button
          className="col-span-1 my-auto h-full text-left  mx-2 text-gray-600 font-medium text-xl"
          onClick={() => {
            history.push("/" + getUserLang())
          }}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <h1 className="text-gray-700 font-semibold text-xl col-span-3 text-center">
          {isCreateInvoicePage ? (
            <Trans>CreateInvoice.mobile-header1</Trans>
          ) : (
            <Trans>CreateInvoice.mobile-header2</Trans>
          )}
        </h1>
        <button
          onClick={() => openInvoiceModal()}
          className="text-xl text-primary ">
          <FontAwesomeIcon className="my-auto" icon={faFileInvoice} />
        </button>
      </div>
    </div>
  )
}

export default InvoiceHeader
