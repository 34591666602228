import React, { useEffect, useState } from "react"
import { getKOLFollowerPercentage } from "../../HelpingFunction/KOL/kolDBHelper"
import { KOLFollowersPercentage } from "../../Model/KOLFollowersPercentage"
import { Chart } from "react-google-charts"
import { Trans } from "react-i18next"
import {
  returnCategoryName,
  returnCatePercentage,
  returnSelectedItem,
} from "../../Utils/TypeList"
import { useLocation } from "react-router-dom"
import classNames from "classnames"
import { useSelector } from "react-redux"

interface PropsType {
  userName: string
}

const options = {
  chartArea: { left: 0, top: 80, right: 0, bottom: 0 },
  titleTextStyle: {
    fontSize: "16",
    bold: true,
  },
  colors: [
    "#a7c957",
    "#b5b5b5",
    "#e69d45",
    "#f5d657",
    "#f2f2f2",
    "#4d4d4d",
    "#dedede",
    "#beaed4",
    "#8dd3c7",
    "#fdc086",
    "#ffff99",
    "#377eb8",
    "#66c2a5",
    "#6a3d9a",
    "#b3e2cd",
    "#e41a1c",
    "#1b9e77",
    "#d95f02",
    "#7570b3",
    "#cab2d6",
    "#fccde5",
    "#bc80bd",
    "#ccebc5",
    "#ff7f00",
    "#ffffb3",
    "#80b1d3",
    "#fdb462",
    "#bebada",
    "#fb8072",
    "#80cdc1",
  ],
}

const KOLFollowerInterest = ({ userName }: PropsType) => {
  let location = useLocation()

  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })

  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [percentages, setPercentages] = useState<KOLFollowersPercentage[]>([])

  const isSearchPage = location.pathname.includes("/s")

  useEffect(() => {
    if (userName) {
      getKOLPercentage(userName)
    }
  }, [userName])

  const getKOLPercentage = (kolUserName: string) => {
    setIsLoaded(false)
    setPercentages([])

    getKOLFollowerPercentage(kolUserName).then((result) => {
      if (result.success) {
        setPercentages(result.data)
        setIsLoaded(true)
      } else {
        setIsLoaded(true)
      }
    })
  }

  if (isLoaded && Array.isArray(percentages) && percentages.length) {
    return (
      <div
        className={classNames("my-2", {
          "grid grid-rows-2": !isSearchPage && screenWidth >= 768,
        })}>
        <div>
          {Array.isArray(percentages) && (
            <>
              <h3 className="mt-2 text-lg font-bold">
                <Trans>KOLFollowerInterest.genre</Trans>
              </h3>
              <Chart
                width={"100%"}
                height={"300px"}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={[
                  ["Genre", "Percentage"],
                  ...percentages
                    .filter((e) => e.item !== "12.8" && e.item !== "12.7")
                    .map((i, index) => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      const typeName = returnSelectedItem(i.item).label
                      return [typeName, i.percentages * 100]
                    }),
                ]}
                options={{
                  ...options,
                }}
                rootProps={{ "data-testid": "1" }}
              />
            </>
          )}
        </div>
        <div>
          {Array.isArray(returnCatePercentage(percentages)) && (
            <>
              <h3 className="mt-2 text-lg font-bold">
                <Trans>KOLFollowerInterest.category</Trans>
              </h3>
              <Chart
                width={"100%"}
                height={"300px"}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={[
                  ["Category", "Percentage"],
                  ...returnCatePercentage(percentages)
                    .filter((e) => e.label !== "12")
                    .map((i, index) => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      const categoryName = returnCategoryName(i.label)
                      return [categoryName, i.count]
                    }),
                ]}
                options={{
                  ...options,
                }}
                rootProps={{ "data-testid": "1" }}
              />
            </>
          )}
        </div>
      </div>
    )
  } else if (
    isLoaded &&
    Array.isArray(percentages) &&
    percentages.length === 0
  ) {
    return (
      <div className="my-4 py-4 px-4 border border-red-default text-red-default rounded-xl text-center">
        <Trans>KOLFollowerInterest.data-not-found</Trans>
      </div>
    )
  }
  return <div></div>
}
export default KOLFollowerInterest
