import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Trans } from "react-i18next"
import { HelpDocHeader } from "./HelpDocHeader/HelpDocHeader"
import { PROJECT_HEADER } from "../../Utils/HelpButtonHelperFunction"
import { getUserLang } from "../../Utils/utiltyHelper"
import { useHistory } from "react-router-dom"

const ProjectPageHeader = () => {
  const history = useHistory()
  return (
    <div
      className="h-18 my-auto w-full py-2 px-4 grid gap-4"
      style={{
        height: "60px",
        gridTemplateColumns: "40px auto 20px",
      }}>
      <div>
        <button
          className="my-auto h-full text-left my-2 mx-2 text-gray-600 font-bold text-xl"
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            history.push("/" + getUserLang() + "/project")
          }}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      </div>
      <div className="my-auto overflow-ellipsis w-full text-lg font-medium uppercase">
        <Trans>ProjectManagement.title</Trans>
      </div>

      <div className="flex justify-end">
        <HelpDocHeader helpDocs={PROJECT_HEADER} />
      </div>
    </div>
  )
}

export default ProjectPageHeader
