import { Reducer } from "react"
import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"
import {
  CHATGPT_SETTING_TYPE,
  COMMEND_TYPE,
  defaultChatGPTTextSettingType,
} from "../../Model/ChatMessage"
import { RecommendedKOLWithReason } from "../../Model/KOLAMessage"

interface actionTypes {
  type: string
  data: any
}

export enum CHATGPT_HELPER_TYPE {
  TYPE_OF_PROMPT,
  PROMPT_EXAMPLE,
  SETTING,
  HELP_DOC,
}

export interface chatGPTState {
  tabType: CHATGPT_HELPER_TYPE
  setting: CHATGPT_SETTING_TYPE
  pastChatBotMessages: { role: string; content: string }[]
  recommendationUIDs: RecommendedKOLWithReason[]
}

const initialState: chatGPTState = {
  tabType: CHATGPT_HELPER_TYPE.PROMPT_EXAMPLE,
  setting: defaultChatGPTTextSettingType,
  pastChatBotMessages: [],
  recommendationUIDs: [],
}

const updateCommendType = (data: COMMEND_TYPE) => {
  let newSetting = initialState.setting

  newSetting = { ...newSetting, commendType: data }

  return newSetting
}

const chatGPTReducer: Reducer<chatGPTState, actionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SUPPORTED_REDUX_FUNCTIONS.SET_CHATGPT_TAB:
      return {
        ...state,
        tabType: action.data,
      }

    case SUPPORTED_REDUX_FUNCTIONS.UPDATE_CHATGPT_SETTING:
      return {
        ...state,
        setting: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.UPDATE_COMMEND_TYPE:
      return {
        ...state,
        setting: updateCommendType(action.data),
      }
    case SUPPORTED_REDUX_FUNCTIONS.SET_PAST_CHATBOT_MESSAGES:
      return {
        ...state,
        pastChatBotMessages: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.UPDATE_RECOMMEND_KOLS:
      return {
        ...state,
        recommendationUIDs: action.data,
      }
    case SUPPORTED_REDUX_FUNCTIONS.RESET_RECOMMEND_KOLS:
      return {
        ...state,
        recommendationUIDs: [],
      }
    default:
      return state
  }
}
export default chatGPTReducer
