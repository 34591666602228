import React from "react"
import { LoginRoleModalData } from "../../Model/Modal"
import { Link } from "react-router-dom"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import iamkol from "../../Assets/i-am-kol.png"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import iamadv from "../../Assets/i-am-advertiser.png"
import { Trans } from "react-i18next"

const LoginRoleModalComponent = (props: LoginRoleModalData) => {
  return (
    <div>
      <div className="grid grid-cols-2 gap-1 lg:gap-4 mb-4 mx-1 lg:mx-4">
        <Link to="/en/register?type=KOL">
          <section
            onClick={() => props.closeModal()}
            className="bg-white shadow max-w-xl rounded-xl p-2 lg:p-4 h-full hover:bg-gray-100">
            <h2 className="text-xl lg:text-lg font-medium my-2">
              <Trans>LoginRoleModal.i-am-kol</Trans>
            </h2>
            <div className="bg-gray-200 rounded-xl h-60">
              <img src={iamkol} alt="I am kol" />
            </div>
          </section>
        </Link>
        <Link to="/en/register?type=Customer">
          <section
            onClick={() => props.closeModal()}
            className="bg-white shadow max-w-xl rounded-xl p-2 lg:p-4 h-full hover:bg-gray-100">
            <h2 className="text-xl lg:text-lg font-medium my-2">
              <Trans>LoginRoleModal.i-am-advertiser</Trans>
            </h2>
            <div className="bg-gray-200 rounded-xl h-60">
              <img src={iamadv} alt="I am kol" />
            </div>
          </section>
        </Link>
      </div>
    </div>
  )
}

export default LoginRoleModalComponent
