import React, { useEffect, useState } from "react"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import igIcon from "../../Assets/instagram-brands.svg"
import { FBAccount } from "../../Model/FacebookAPI"
import CaseStudyConnectIG from "./CaseStudyIGModal/CaseStudyConnectIG"
import CaseStudyApprovedPages from "./CaseStudyIGModal/CaseStudyApprovedPages"
import { CaseStudy, CSMediaListData } from "../../Model/CaseStudy"
import CaseStudySelectMedia from "./CaseStudyIGModal/CaseStudySelectMedia"
export enum CSModalStep {
  CONNECT,
  APPROVE,
  MEDIA,
}

export enum LocationType {
  JOB_NEW,
  JOB_OLD,
  KOL_PROFILE,
}

const CaseStudyIGModalComponent = ({
  closeModal,
  metaData,
}: {
  closeModal: () => void
  metaData: { caseStudy: CaseStudy; jobID?: string }
}) => {
  const [step, setStep] = useState<CSModalStep>(CSModalStep.CONNECT)
  const [approvedPages, setApprovedPages] = useState<FBAccount[]>([])
  const [imageList, setImageList] = useState<CSMediaListData[]>([])
  const [nextPage, setNextPage] = useState<string>("")
  const [type, setType] = useState<LocationType>(LocationType.KOL_PROFILE)

  useEffect(() => {
    if (metaData.jobID) {
      if (metaData.caseStudy === undefined) {
        setType(LocationType.JOB_NEW)
      } else {
        setType(LocationType.JOB_OLD)
      }
    } else {
      setType(LocationType.KOL_PROFILE)
    }
  }, [])

  useEffect(() => {
    if (approvedPages.length) {
      setStep(CSModalStep.APPROVE)
    }
  }, [approvedPages])
  return (
    <div className="h-full">
      {step === CSModalStep.CONNECT && (
        <CaseStudyConnectIG
          setStep={setStep}
          setApprovedPages={setApprovedPages}
        />
      )}
      {step === CSModalStep.APPROVE && (
        <CaseStudyApprovedPages
          setStep={setStep}
          approvedPages={approvedPages}
          setNextPage={setNextPage}
          setImageList={setImageList}
        />
      )}
      {step === CSModalStep.MEDIA && (
        <CaseStudySelectMedia
          metaData={metaData.caseStudy}
          closeModal={closeModal}
          imagesList={imageList}
          nextPage={nextPage}
          type={type}
          jobID={metaData.jobID ? metaData.jobID : ""}
        />
      )}
    </div>
  )
}

export default CaseStudyIGModalComponent
