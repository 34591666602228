import React, { useImperativeHandle, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Trans } from "react-i18next"

import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"

interface PropsType {
  metaData: string
}

const CreateNewCompareGroup: React.FC<PropsType> = ({ metaData }, ref: any) => {
  const dispatch = useDispatch()

  const [newGroupName, setNewGroupName] = useState<string>("")

  useImperativeHandle(ref, () => ({
    returnData() {
      return {
        groupName: newGroupName,
      }
    },
  }))

  return (
    <div
      className="z-20 h-96 w-full p-2 grid overflow-hidden"
      style={{ gridTemplateRows: "160px auto" }}>
      <div className="border-b border-gray-200 pb-2 ">
        <div className="text-sm text-gray-500 font-bold mt-2">
          <Trans>CreateNewCompareGroup.new-group</Trans>
        </div>

        <input
          className="mb-2 py-2 px-4 w-full rounded-md bg-gray-100 border border-gray-200 my-2"
          value={newGroupName}
          onChange={(e) => {
            setNewGroupName(e.target.value)
          }}
        />
      </div>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default React.forwardRef(CreateNewCompareGroup)
