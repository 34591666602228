import React, { useEffect, useState } from "react"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import waitingImg from "../../../Assets/waitinglady.png"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import workingImg from "../../../Assets/working.png"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import walletImg from "../../../Assets/wallet.png"
import { AnimatePresence, motion } from "framer-motion"
import { Trans } from "react-i18next"
import { Carousel } from "react-responsive-carousel"

const PendingPaymentHelp = () => {
  const data = [
    {
      title: "ContractPayment.waiting-title",
      desc: "ContractPayment.waiting-desc",
      img: waitingImg,
    },
    {
      title: "ContractPayment.work-title",
      desc: "ContractPayment.work-desc",
      img: workingImg,
    },
    {
      title: "ContractPayment.wallet-title",
      desc: "ContractPayment.wallet-desc",
      img: walletImg,
    },
  ]

  return (
    <div className="max-w-xs md:max-w-sm mx-auto mt-8">
      <Carousel
        autoPlay
        infiniteLoop
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        className="w-full rounded-xl mb-4"
        showStatus={false}
        showThumbs={false}>
        {data.map((slide, index) => {
          return (
            <section className="text-left">
              <p className="text-lg font-semibold text-gray-700">
                <Trans>{slide.title}</Trans>
              </p>
              <p className="my-2 text-sm font-medium text-gray-500">
                <Trans>{slide.desc}</Trans>
              </p>
              <div className="w-72 py-4 mx-auto">
                <img src={slide.img} />
              </div>
            </section>
          )
        })}
      </Carousel>
    </div>
  )
}

export default PendingPaymentHelp
