import React, { useEffect, useState } from "react"
import { FBAccount } from "../../../Model/FacebookAPI"
import { CSModalStep } from "../CaseStudyIGModalComponent"
import { listCSMedia } from "../../../HelpingFunction/FBLogin/FBLoginHelper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"
import { Trans } from "react-i18next"
import { igTab } from "./CaseStudySelectMedia"
import { useDispatch } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"

const CaseStudyApprovedPages = ({
  approvedPages,
  setStep,
  setNextPage,
  setImageList,
}: {
  approvedPages: FBAccount[]
  setStep: any
  setNextPage: any
  setImageList: any
}) => {
  const dispatch = useDispatch()

  const setFbPageID = (data: string) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_FACEBOOK_PAGE_ID,
      data: data,
    })
  }
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const getIGMedia = async (selectedPageID: string) => {
    setFbPageID(selectedPageID)
    setIsLoading(true)
    await listCSMedia(selectedPageID, igTab.POSTS).then((res) => {
      if (res.success) {
        setImageList(res.igMediaList)
        if (res.nextPage) {
          setNextPage(res.nextPage)
        }
        setIsLoading(false)
        setStep(CSModalStep.MEDIA)
      } else {
        setImageList([])
        setIsLoading(false)
      }
    })
  }

  return (
    <div>
      <div>
        <section className="my-12">
          <h1 className="text-2xl font-semibold text-center">
            <Trans>CaseStudyIGModal.title-2</Trans>
          </h1>
          <p className="my-8 md:text-sm text-xs text-gray-500 md:mx-40">
            <Trans>CaseStudyIGModal.desc-2</Trans>
          </p>
        </section>
        <section>
          {approvedPages.map((ap, index) => {
            return (
              <div
                onClick={() => getIGMedia(ap.id)}
                key={index}
                className="select-none bg-gray-100 mx-auto shadow rounded-xl p-2  lg:px-4 font-medium text-md w-64 lg:text-lg lg:w-96 text-center hover:bg-primary hover:text-white cursor-pointer">
                <button disabled={isLoading}>
                  <FontAwesomeIcon
                    className={isLoading ? "animate-spin mr-2" : "hidden"}
                    icon={faSpinner}
                  />

                  <span>{ap.name}</span>
                </button>
              </div>
            )
          })}
        </section>
      </div>
    </div>
  )
}

export default CaseStudyApprovedPages
