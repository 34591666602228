export interface Bonus {
  // see enum
  type: BONUS_TYPE
  createDate: Date
  uid?: string
}

export enum BONUS_TYPE {
  // ig post story + link tree
  BASIC = "basic",
  // kol refer new user
  REFERRAL = "referral",
}
