import { faPenToSquare } from "@fortawesome/free-regular-svg-icons"
import {
  faChevronDown,
  faMagnifyingGlassChart,
  faEnvelopesBulk,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { motion } from "framer-motion"
import React from "react"
import { Trans } from "react-i18next"

const AssistantTutorialModalComponent = (props: any) => {
  return (
    <div>
      <section className="md:block hidden text-center my-6">
        <motion.p
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1, transition: { duration: 0.3 } }}
          className="text-2xl font-semibold ">
          <Trans>AssistantTutorial.introducing</Trans>{" "}
          <span className="text-primary font-bold">
            {" "}
            <Trans>AssistantTutorial.kola</Trans>
          </span>
        </motion.p>
      </section>
      <section className=" md:mt-8 md:my-3 my-2">
        <motion.div
          initial={{ opacity: 0, y: -30 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.3, duration: 0.5 },
          }}
          className="shadow border rounded-lg max-w-md p-4 bg-white mx-auto">
          <p className="text-gray-700 font-semibold c">
            <FontAwesomeIcon className="mr-2" icon={faPenToSquare} />
            <Trans>AssistantTutorial.title-1</Trans>
          </p>
          <p className="my-2 text-gray-500 font-medium md:text-sm text-xs">
            <Trans>AssistantTutorial.desc-1</Trans>
          </p>
        </motion.div>
      </section>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{
          opacity: 1,
          scale: 1,
          transition: { delay: 0.8, duration: 0.3 },
        }}
        className="text-center">
        <FontAwesomeIcon
          className="md:text-base text-sm"
          icon={faChevronDown}
        />
      </motion.div>

      <section className="md:my-3 my-2">
        <motion.div
          initial={{ opacity: 0, y: -30 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { delay: 1.1, duration: 0.5 },
          }}
          className="shadow border rounded-lg max-w-md p-4 bg-white  mx-auto">
          <p className="text-gray-700 font-semibold md:text-xl text-lg">
            <FontAwesomeIcon className="mr-2" icon={faMagnifyingGlassChart} />
            <Trans>AssistantTutorial.title-2</Trans>
          </p>
          <p className="my-2 text-gray-500 font-medium  md:text-sm text-xs">
            <Trans>AssistantTutorial.desc-2</Trans>
          </p>
        </motion.div>
      </section>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{
          opacity: 1,
          scale: 1,
          transition: { delay: 1.6, duration: 0.3 },
        }}
        className="text-center">
        <FontAwesomeIcon
          className="md:text-base text-sm"
          icon={faChevronDown}
        />
      </motion.div>
      <section className="md:my-3 my-2">
        <motion.div
          initial={{ opacity: 0, y: -30 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { delay: 1.9, duration: 0.5 },
          }}
          className="shadow border rounded-lg max-w-md p-4 bg-white  mx-auto">
          <p className="text-gray-700 font-semibold md:text-xl text-lg">
            <FontAwesomeIcon className="mr-2" icon={faEnvelopesBulk} />
            <Trans>AssistantTutorial.title-3</Trans>
          </p>
          <p className="my-2 text-gray-500 font-medium  md:text-sm text-xs">
            <Trans>AssistantTutorial.desc-3</Trans>
          </p>
        </motion.div>
      </section>
      <section className="md:hidden block text-center mt-6">
        <motion.button
          onClick={() => props.closeModal()}
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{
            opacity: 1,
            scale: 1,
            transition: { delay: 2.2, duration: 0.3 },
          }}
          className="btn btn-primary w-48">
          <Trans>AssistantTutorial.button</Trans>
        </motion.button>
      </section>
    </div>
  )
}

export default AssistantTutorialModalComponent
