import React from "react"

interface PropsType {
  score: 0 | 1 | 2 | 3 | 4 | 5
  size: "xs" | "sm" | "md" | "lg" | "xl"
  index: number
}

export const Rating = ({ score, size, index }: PropsType) => {
  return (
    <div>
      <div className={`rating rating-${size}`}>
        <input
          type="radio"
          name={"rating-" + index}
          className="rating-hidden"
          checked={score === 0}
        />
        <input
          type="radio"
          name={"rating-" + index}
          className="mask mask-star-2 bg-amber-500"
          checked={score === 1}
        />
        <input
          type="radio"
          name={"rating-" + index}
          className="mask mask-star-2 bg-amber-500"
          checked={score === 2}
        />
        <input
          type="radio"
          name={"rating-" + index}
          className="mask mask-star-2 bg-amber-500"
          checked={score === 3}
        />
        <input
          type="radio"
          name={"rating-" + index}
          className="mask mask-star-2 bg-amber-500"
          checked={score === 4}
        />
        <input
          type="radio"
          name={"rating-" + index}
          className="mask mask-star-2 bg-amber-500"
          checked={score === 5}
        />
      </div>
    </div>
  )
}
