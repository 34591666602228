import React, { useEffect, useState } from "react"
import { Chart } from "react-google-charts"
import { AdminViewKOLPercentageModalData } from "../../Model/Modal"
import {
  returnCatePercentage,
  returnSelectedItem,
  returnCategoryName,
  stringToColour,
} from "../../Utils/TypeList"
import classNames from "classnames"
import CustomTable from "../NewTable"
import { t } from "i18next"
import { Column } from "../../Model/Table"
import {
  defaultIGPercentageTableSchema,
  getIGPercentageTableSchema,
} from "../../Utils/TableHelperFunction"
import { searchKOLAlgolia } from "../../HelpingFunction/KOL/kolDBHelper"
import { KOLSearchDefault } from "../../Redux/Reducer/ToolsManager"
import { KOLCombine } from "../../Model/KOLCombine"

enum ViewType {
  ALL,
  CATE,
}

interface singlePercentageItem {
  count: number
  item: string
  percentages: number
}

const AdminViewKOLPercentageModalComponent: React.FC<
  AdminViewKOLPercentageModalData
> = ({ metaData }) => {
  const catePer =
    metaData.percentages &&
    Array.isArray(returnCatePercentage(metaData.percentages))

  const [type, setType] = useState<ViewType>(ViewType.ALL)
  const [tableCol, setTableCol] = useState<Column[][]>([
    defaultIGPercentageTableSchema(),
  ])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [kols, setKOLs] = useState<KOLCombine[]>([])

  const getSearchResult = (topCateItem: singlePercentageItem) => {
    setIsLoading(true)

    searchKOLAlgolia({
      ...KOLSearchDefault,
      topCateID: topCateItem.item,
      topCateMin: topCateItem.percentages * 0.8,
      topCateMax: topCateItem.percentages * 1.2,
    })
      .then((response) => {
        console.log(response)
        if (response.success) {
          setKOLs(response.data as KOLCombine[])
        }
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    // getSearchResult(metaData.percentages[0] as singlePercentageItem)
  }, [])

  useEffect(() => {
    let tableSets: Column[][] = []
    if (type === ViewType.ALL) {
      let filteredPercentages = metaData.percentages.filter(
        (e) => e.item !== "12.8" && e.item !== "12.7"
      )

      const sum = filteredPercentages.reduce((a, b) => a + b.count, 0)

      filteredPercentages.map((i, index) => {
        tableSets.push(getIGPercentageTableSchema(sum, i))
      })
    }

    setTableCol(tableSets)
    setIsLoaded(true)
  }, [type])

  if (type === ViewType.ALL) {
    return (
      <div className="container mx-auto  overflow-y-hidden">
        <div className="mx-auto w-96">
          <div className="btn-group grid grid-cols-2">
            <button
              className={classNames(
                "btn-active w-full btn btn-ghost bg-gray-100 hover:text-gray-600 hover:bg-red-100"
              )}>
              All
            </button>
            <button
              className={classNames(
                "w-full btn btn-ghost bg-gray-100 hover:text-gray-600 hover:bg-red-100"
              )}
              onClick={() => setType(ViewType.CATE)}>
              Category
            </button>
          </div>
        </div>

        {Array.isArray(metaData.percentages) && (
          <Chart
            width={"100%"}
            height={"300px"}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ["RecomGenre", "Percentage"],
              ...metaData.percentages
                .filter((e) => e.item !== "12.8" && e.item !== "12.7")
                .map((i, index) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  const typeName = returnSelectedItem(i.item).label
                  return [typeName, i.percentages * 100]
                }),
            ]}
            options={{
              title: metaData.userID,
              colors: [
                ...metaData.percentages
                  .filter((e) => e.item !== "12.8" && e.item !== "12.7")
                  .map((i, index) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const typeName = returnSelectedItem(i.item).label
                    return stringToColour(typeName)
                  }),
              ],
            }}
            rootProps={{ "data-testid": ViewType.ALL }}
          />
        )}

        <div className="my-4">
          <CustomTable
            cols={tableCol}
            // tableFn={tableFn}
            isFirstColStatic={false}
            notFoundDes={t("MyPayoutRequest.record-not-found")}
            isLoading={!isLoaded}
            rowIsLoading={!isLoaded}
            isLastRecord={true}
          />
        </div>
      </div>
    )
  } else if (type === ViewType.CATE) {
    return (
      <div className="container mx-auto  overflow-y-hidden">
        <div className="mx-auto w-96">
          <div className="btn-group grid grid-cols-2">
            <button
              className={classNames(
                "w-full btn btn-ghost bg-gray-100 hover:text-gray-600 hover:bg-red-100"
              )}
              onClick={() => setType(ViewType.ALL)}>
              All
            </button>
            <button
              className={classNames(
                "btn-active w-full btn btn-ghost bg-gray-100 hover:text-gray-600 hover:bg-red-100"
              )}>
              Category
            </button>
          </div>
        </div>

        {catePer && (
          <Chart
            width={"100%"}
            height={"300px"}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ["Category", "Percentage"],
              ...returnCatePercentage(metaData.percentages)
                .filter((e) => e.label !== "12")
                .map((i, index) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  const categoryName = returnCategoryName(i.label)
                  return [categoryName, i.count]
                }),
            ]}
            options={{
              title: metaData.userID,
              colors: [
                ...returnCatePercentage(metaData.percentages)
                  .filter((e) => e.label !== "12")
                  .map((i, index) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const categoryName = returnCategoryName(i.label)
                    return stringToColour(categoryName)
                  }),
              ],
            }}
            rootProps={{ "data-testid": ViewType.ALL }}
          />
        )}
      </div>
    )
  }
  return <div />
}

export default AdminViewKOLPercentageModalComponent
