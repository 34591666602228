import { SUPPORTED_REDUX_FUNCTIONS } from "../SUPPORTED_REDUX_FUNCTION"
import { Reducer } from "react"
import { NotiMessage } from "../../Model/Message"

interface actionTypes {
  type: string
  data: NotiMessage[]
}

export interface modalInitStateType {
  msg: NotiMessage[]
  isLoaded: boolean
}

const initialState: modalInitStateType = {
  msg: [],
  isLoaded: false,
}

const NotificationManager: Reducer<modalInitStateType, actionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SUPPORTED_REDUX_FUNCTIONS.SET_MESSAGES:
      return {
        ...state,
        msg: action.data,
        isLoaded: true,
      }
    default:
      return state
  }
}

export default NotificationManager
