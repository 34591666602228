import { JOB_STATUS_TYPE } from "../Enum/APP_TYPE"
import { DateObj } from "./Utilities"
import { Schedule } from "./Schedule"
import { jobListCondition } from "../HelpingFunction/Job/JobDBHelper"

export enum PAYMENT_METHOD {
  CARD = "CARD",
  BANK = "BANK",
  SKIP = "SKIP",
  NONE = "",
}

export enum JOB_LIST_CONDITION_TYPE {
  ALL = "0",
  FULFILLED = "1",
  WAIT_FOR_PAYMENT = "2",
  CONTRACT = "3",
  QUOTING = "4",
  WORKING = "5",
  WAIT_QUOTE_ACCEPT = "6",
  DRAFT = "7",
}

export enum JOB_TYPE {
  CONTRACT = "CONTRACT",
  INVOICE = "INVOICE",
}

export interface JobCustomerInfo {
  name: string
  email: string
}

export const JobFilterOption = [
  { label: "MyTransaction.all", value: JOB_LIST_CONDITION_TYPE.ALL },
  {
    label: "MyTransaction.fulfilled",
    value: JOB_LIST_CONDITION_TYPE.FULFILLED,
  },
  {
    label: "MyTransaction.accepted",
    value: JOB_LIST_CONDITION_TYPE.WAIT_FOR_PAYMENT,
  },
  {
    label: "MyTransaction.contract",
    value: JOB_LIST_CONDITION_TYPE.CONTRACT,
  },
  {
    label: "MyTransaction.pending",
    value: JOB_LIST_CONDITION_TYPE.QUOTING,
  },
  {
    label: "MyTransaction.working",
    value: JOB_LIST_CONDITION_TYPE.WORKING,
  },
  {
    label: "MyTransaction.price-sent",
    value: JOB_LIST_CONDITION_TYPE.WAIT_QUOTE_ACCEPT,
  },
]

export const kolJobPageOption = [
  { label: "MyTransaction.all", value: JOB_LIST_CONDITION_TYPE.ALL },
  {
    label: "MyTransaction.fulfilled",
    value: JOB_LIST_CONDITION_TYPE.FULFILLED,
  },
  {
    label: "MyTransaction.accepted",
    value: JOB_LIST_CONDITION_TYPE.WAIT_FOR_PAYMENT,
  },
  {
    label: "MyTransaction.contract",
    value: JOB_LIST_CONDITION_TYPE.CONTRACT,
  },
  {
    label: "MyTransaction.pending",
    value: JOB_LIST_CONDITION_TYPE.QUOTING,
  },
  {
    label: "MyTransaction.working",
    value: JOB_LIST_CONDITION_TYPE.WORKING,
  },
  {
    label: "MyTransaction.price-sent",
    value: JOB_LIST_CONDITION_TYPE.WAIT_QUOTE_ACCEPT,
  },
  {
    label: "MyTransaction.draft",
    value: JOB_LIST_CONDITION_TYPE.DRAFT,
  },
]

export const JobFilterDefault: jobListCondition = {
  completePayout: "",
  accepted: "",
  paid: "",
  quotationDetail: "",
  paginationData: "",
  paginationDirection: "",
  uid: "",
  customerID: "",
  kolID: "",
  priceID: "",
  isConfirmQuotation: "",
}

export const JobFilter: jobListCondition[] = [
  {
    type: JOB_LIST_CONDITION_TYPE.ALL,
    completePayout: "",
    accepted: "",
    paid: "",
    quotationDetail: "",
    paginationData: "",
    paginationDirection: "",
    uid: "",
    customerID: "",
    kolID: "",
    priceID: "",
    isConfirmQuotation: "",
  },
  {
    type: JOB_LIST_CONDITION_TYPE.FULFILLED,
    completePayout: true,
    accepted: true,
    paid: true,
    quotationDetail: "",
    paginationData: "",
    paginationDirection: "",
    uid: "",
    customerID: "",
    kolID: "",
    priceID: "",
    isConfirmQuotation: "",
  },
  {
    type: JOB_LIST_CONDITION_TYPE.WAIT_FOR_PAYMENT,
    completePayout: false,
    accepted: true,
    paid: false,
    quotationDetail: true,
    paginationData: "",
    paginationDirection: "",
    uid: "",
    customerID: "",
    kolID: "",
    priceID: true,
    isConfirmQuotation: true,
  },
  {
    type: JOB_LIST_CONDITION_TYPE.CONTRACT,
    completePayout: false,
    accepted: false,
    paid: false,
    quotationDetail: true,
    paginationData: "",
    paginationDirection: "",
    uid: "",
    customerID: "",
    kolID: "",
    priceID: true,
    isConfirmQuotation: true,
  },
  {
    type: JOB_LIST_CONDITION_TYPE.QUOTING,
    completePayout: false,
    accepted: false,
    paid: false,
    quotationDetail: "",
    paginationData: "",
    paginationDirection: "",
    uid: "",
    customerID: "",
    kolID: "",
    priceID: false,
    isConfirmQuotation: false,
  },
  {
    type: JOB_LIST_CONDITION_TYPE.WORKING,
    completePayout: false,
    accepted: true,
    paid: true,
    quotationDetail: "",
    paginationData: "",
    paginationDirection: "",
    uid: "",
    customerID: "",
    kolID: "",
    priceID: true,
    isConfirmQuotation: true,
  },
  {
    type: JOB_LIST_CONDITION_TYPE.WAIT_QUOTE_ACCEPT,
    completePayout: false,
    accepted: false,
    paid: false,
    quotationDetail: "",
    paginationData: "",
    paginationDirection: "",
    uid: "",
    customerID: "",
    kolID: "",
    priceID: true,
    isConfirmQuotation: false,
  },
  {
    type: JOB_LIST_CONDITION_TYPE.DRAFT,
    completePayout: "",
    accepted: "",
    paid: "",
    quotationDetail: "",
    paginationData: "",
    paginationDirection: "",
    uid: "",
    customerID: "",
    kolID: "",
    draft: true,
    priceID: "",
    isConfirmQuotation: "",
  },
]

export interface ReferralJob {
  id: string
  KOLID: string
  amount: number
  price: number
  lastUpdate: Date
  paymentMethod: PAYMENT_METHOD
}

export interface Job {
  chatroomID?: string
  id: string
  title: string
  CustomerID: string
  KOLID: string
  accepted: boolean
  amount: number
  caseID?: string
  cConfirm: boolean
  cLastUpdate: Date
  cRequirement: string
  cRequirementLock: boolean
  cSignUrl: string
  //special ""
  completePayout: boolean | ""
  currency: string
  createDate: DateObj | Date
  endDate: DateObj | Date
  fee: number
  featureImages: string[]
  finalComment: string
  isConfirmQuotation: boolean
  isFinish: boolean
  isPublish: boolean
  isSubmitQuotation: boolean
  kolConfirm: boolean
  kolLastUpdate: Date
  kolRequirement: string
  kolRequirementLock: boolean
  kolSignUrl: string
  lastUpdate: Date
  paid: boolean
  participateUID: string[]
  payDate: DateObj | Date
  payoutAmount: number
  payoutDate: Date
  payoutRefID: string
  payRefID: string
  price: number
  priceID: string
  quotationDetail: string
  score: 0 | 1 | 2 | 3 | 4 | 5
  schedules: Schedule[]
  ServicePlanID: string
  ServicePlanName: string
  startDate: Date
  status: JOB_STATUS_TYPE
  projectID: string[]
  paymentMethod: PAYMENT_METHOD
  referralUser: string
  type?: JOB_TYPE
  customerInfo?: JobCustomerInfo
}

export const JOB_DEFAULT: Job = {
  id: "",
  title: "",
  CustomerID: "",
  KOLID: "",
  accepted: false,
  amount: 0,
  cConfirm: false,
  cLastUpdate: new Date(),
  cRequirement: "",
  cRequirementLock: false,
  cSignUrl: "",
  //special ""
  completePayout: "",
  currency: "",
  createDate: new Date(),
  endDate: new Date(),
  fee: 0,
  featureImages: [],
  finalComment: "",
  isConfirmQuotation: false,
  isFinish: false,
  isPublish: false,
  isSubmitQuotation: false,
  kolConfirm: false,
  kolLastUpdate: new Date(),
  kolRequirement: "",
  kolRequirementLock: false,
  kolSignUrl: "",
  lastUpdate: new Date(),
  paid: false,
  participateUID: [],
  payDate: new Date(),
  payoutAmount: 0,
  payoutDate: new Date(),
  payoutRefID: "",
  payRefID: "",
  price: 0,
  priceID: "",
  quotationDetail: "",
  score: 0,
  schedules: [],
  ServicePlanID: "",
  ServicePlanName: "",
  startDate: new Date(),
  status: JOB_STATUS_TYPE.STATUS_1,
  projectID: [],
  paymentMethod: PAYMENT_METHOD.NONE,
  referralUser: "",
  type: JOB_TYPE.CONTRACT,
  customerInfo: {
    name: "",
    email: "",
  },
}
