import React, { useState } from "react"
import parse from "html-react-parser"
import {
  chatroomUrlConvertor,
  timeStampToDateTimeString,
} from "../../../Utils/Converter"
import { Trans } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { useHistory } from "react-router-dom"
import { NotiMessage } from "../../../Model/Message"
import { getUserLang } from "../../../Utils/utiltyHelper"
import classNames from "classnames"

interface PropsType {
  setIsMessageDropDownOpen: () => void
  setIsRead: (id: string) => void
  msg: NotiMessage
}

const MessageItem: React.FC<PropsType> = ({
  setIsMessageDropDownOpen,
  setIsRead,
  msg,
}) => {
  let history = useHistory()
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isJustRead, setIsJustRead] = useState<boolean>(false)

  const numOfUnreadContract = useSelector((state: any) => {
    return state.MessagingSystem.numOfUnreadContracts
  })

  const decreaseNumOfUnread = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_NUM_OF_UNREAD_CONTRACT,
      numOfUnread: numOfUnreadContract - 1,
    })
  }

  const isEn = localStorage.getItem("i18n-lang") === "en"
  const isNotEn = localStorage.getItem("i18n-lang") !== "en"
  const isShowAllMessagePage = history.location.pathname.includes("/message")

  try {
    return (
      <li
        className={[
          "mx-4 shadow md:shadow-none md:mx-0 mb-2 md:my-2 p-3 md:p-2 cursor-pointer hover:bg-gray-100 rounded-md border border-gray-100",
          msg.isRead && "bg-gray-50",
          isJustRead && "opacity-50",
        ].join(" ")}>
        <div
          className="pl-1"
          style={{ maxWidth: isShowAllMessagePage ? "100%" : "270px" }}
          onClick={() => {
            if (!msg.isRead) {
              setIsRead(msg.id)
              setIsJustRead(true)
              decreaseNumOfUnread()
            }
            if (msg.url !== "") {
              if (setIsMessageDropDownOpen) {
                setIsMessageDropDownOpen()
              }
              const searchParams = chatroomUrlConvertor(msg.url)

              history.push({
                pathname: "/" + getUserLang() + "/c",
                search: "?" + searchParams,
              })
            }
          }}>
          <div
            className={[isJustRead ? "font-normal" : "font-semibold"].join()}>
            {isNotEn && msg.titleCHI ? <>{msg.titleCHI}</> : <>{msg.title}</>}
          </div>

          <div
            className={classNames(" w-full text-sm text-gray-500 my-1 ", {
              "inline-block h-auto": isOpen,
              "block h-6 text-ellipsis overflow-hidden": !isOpen,
            })}>
            {isEn && msg.title && parse(msg.msg)}
            {isNotEn && msg.titleCHI ? (
              <>{parse(msg.msgCHI)}</>
            ) : (
              <>{isNotEn && parse(msg.msg)}</>
            )}
          </div>
        </div>
        <div>
          <span className=" block w-full text-xs text-gray-300 mt-3">
            {timeStampToDateTimeString(msg.issueDate.seconds)}
            <button
              className={classNames(" float-right text-sm", {
                "text-gray-300": isOpen,
                "text-gray-600": !isOpen,
              })}
              onClick={() => {
                // if (!msg.isRead) {
                //   setIsRead(msg.id)
                //   setIsJustRead(true)
                //   decreaseNumOfUnread()
                // }
                console.log(msg)
                setIsOpen(!isOpen)
              }}>
              <Trans>HeadingMessageManager.open</Trans>
            </button>
          </span>
        </div>
      </li>
    )
  } catch (e) {
    console.log(e)
    return <></>
  }
}
export default MessageItem
