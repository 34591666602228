import React, { useEffect, useState } from "react"
import { Trans } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
  faCog,
  faEdit,
  faFileInvoice,
  faFileInvoiceDollar,
  faFileSignature,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons"

import { useHistory, useLocation, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { CONTRACT_STEP } from "../../../Enum/CONTRACT"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import classes from "./ContractToolBar.module.css"
import { isAuth, returnIdTokenResult } from "../../../Utils/FirebaseUtils"
import { getAPIPath } from "../../../Utils/HelpingFunction"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { CHATROOM_PAGE_TYPE, ROLE } from "../../../Enum/APP_TYPE"
import { getCustomerCompanyName } from "../../../HelpingFunction/Customer/customerDBHelper"
import { getKOLUserName } from "../../../HelpingFunction/KOL/kolDBHelper"
import { getUserLang } from "../../../Utils/utiltyHelper"
import { getAuthUID, getQuery } from "../../../Utils/QueryHelper"
import classNames from "classnames"

const ContractToolBar: React.FC = () => {
  let history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const stepHeader = useSelector((state: any) => {
    return state.ContractManager.stepHeader
  })

  const paramList = useSelector((state: any) => {
    return state.QueryManager.params
  })

  let params = {
    type: getQuery(paramList, "type"),
    chatroomID: getQuery(paramList, "chatroomID"),
    metaPath: getQuery(paramList, "metaPath"),
    viewAs: getQuery(paramList, "viewAs"),
  }

  const job = useSelector((state: any) => {
    return params.metaPath !== "new" ? state.ChatRoomInfo.chatroomJob : {}
  })

  const [userName, setUserName] = useState<string>("")
  const [role, setRole] = useState<ROLE>(ROLE.DEFAULT)
  const [isQuotationLoading, setIsQuotationLoading] = useState<boolean>(false)
  const [isContrastLoading, setIsContractLoading] = useState<boolean>(false)
  const [isInvoiceLoading, setIsInvoiceLoading] = useState<boolean>(false)

  const isProjectPage = location.pathname.includes("/project")
  const isListJobPage = location.pathname.includes("/j")
  const hasQuotation = job.isSubmitQuotation
  const hasContract = job.accepted
  const hasInvoice = job.isFinish

  const setPageType = (): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_PAGE_TYPE,
      data: CHATROOM_PAGE_TYPE.CONTRACT,
    })
  }

  const setContractStep = (type: CONTRACT_STEP): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CONTRACT_STEP,
      data: type,
    })
  }

  useEffect(() => {
    if (isAuth(auth)) {
      returnIdTokenResult().then(async (res) => {
        if (res.success) {
          if (params.viewAs) {
            setRole(ROLE.KOL)
          } else {
            setRole(res.role as ROLE)
          }
        }
      })
    }
  }, [params.chatroomID, auth, job])

  const callUserName = async () => {
    switch (role) {
      case ROLE.KOL:
        const cres = await getCustomerCompanyName(job.CustomerID)
        setUserName(cres.success ? cres.customerCompanyName : "")
        break
      case ROLE.CUSTOMER:
        const kres = await getKOLUserName(job.KOLID)
        setUserName(kres.success ? kres.userName : "")
        break
      default:
        break
    }
  }
  useEffect(() => {
    callUserName()
  }, [role, job])

  const onDownloadQuotation = async () => {
    setIsQuotationLoading(true)
    returnIdTokenResult().then(async (res) => {
      let customerName = ""
      let KOLName = ""
      await getCustomerCompanyName(job.CustomerID).then((res) => {
        if (res.success) {
          customerName = res.customerCompanyName
        }
      })
      await getKOLUserName(job.KOLID).then((res) => {
        if (res.success) {
          KOLName = res.userName
        }
      })
      let headerInfo: any = {
        "Content-Type": "application/json",
        idToken: res.token,
      }
      if (params.viewAs) {
        headerInfo = {
          ...headerInfo,
          agencyID: params.viewAs,
        }
      }
      await fetch(getAPIPath("/api/job/" + job.id + "/quotation/pdf"), {
        method: "GET",
        headers: headerInfo,
      })
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((href) => {
          setIsQuotationLoading(false)
          const a = document.createElement("a")
          document.body.appendChild(a)
          a.setAttribute("style", "display: none")
          a.href = href
          a.download = customerName + " x " + KOLName + " job quotation.pdf"
          a.click()
        })
    })
  }

  const onDownloadContract = () => {
    setIsContractLoading(true)
    returnIdTokenResult().then(async (res) => {
      let customerName = ""
      let KOLName = ""
      await getCustomerCompanyName(job.CustomerID).then((res) => {
        if (res.success) {
          customerName = res.customerCompanyName
        }
      })
      await getKOLUserName(job.KOLID).then((res) => {
        if (res.success) {
          KOLName = res.userName
        }
      })
      let headerInfo: any = {
        "Content-Type": "application/json",
        idToken: res.token,
      }
      if (params.viewAs) {
        headerInfo = {
          ...headerInfo,
          agencyID: params.viewAs,
        }
      }
      await fetch(getAPIPath("/api/job/" + job.id + "/contract/pdf"), {
        method: "GET",
        headers: headerInfo,
      })
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((href) => {
          setIsContractLoading(false)
          const a = document.createElement("a")
          document.body.appendChild(a)
          a.setAttribute("style", "display: none")
          a.href = href
          a.download = customerName + " x " + KOLName + " job contract.pdf"
          a.click()
        })
    })
  }

  const onDownloadInvoice = () => {
    setIsInvoiceLoading(true)
    returnIdTokenResult().then(async (res) => {
      let customerName = ""
      let KOLName = ""
      await getCustomerCompanyName(job.CustomerID).then((res) => {
        if (res.success) {
          customerName = res.customerCompanyName
        }
      })
      await getKOLUserName(job.KOLID).then((res) => {
        if (res.success) {
          KOLName = res.userName
        }
      })

      let headerInfo: any = {
        "Content-Type": "application/json",
        idToken: res.token,
      }
      if (params.viewAs) {
        headerInfo = {
          ...headerInfo,
          agencyID: params.viewAs,
        }
      }
      await fetch(getAPIPath("/api/job/" + job.id + "/invoice/pdf"), {
        method: "GET",
        headers: headerInfo,
      })
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((href) => {
          setIsInvoiceLoading(false)
          const a = document.createElement("a")
          document.body.appendChild(a)
          a.setAttribute("style", "display: none")
          a.href = href
          a.download = customerName + " x " + KOLName + " job invoice.pdf"
          a.click()
        })
    })
  }
  const redirectPage = () => {
    if (params.chatroomID) {
      setPageType()
      const query = new URLSearchParams({
        chatroomID: params.chatroomID,
        type: "contract",
      })
      history.push({
        pathname: "/" + getUserLang() + "/c",
        search: "?" + query,
      })
    }
  }

  return (
    <div>
      <div
        className="h-18 my-auto w-full py-2 px-4 flex justify-between gap-4"
        style={{
          height: "60px",
        }}>
        {!isProjectPage && !isListJobPage && (
          <button
            className="my-auto h-full text-left mx-2 text-gray-600 font-bold text-xl"
            onClick={() => {
              redirectPage()
            }}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        )}

        <div className="inline-block my-auto flex-auto shrink w-48 md:w-36 text-lg pr-2">
          <p className=" truncate  text-ellipsis overflow-hidden font-medium uppercase">
            {job.ServicePlanName}
          </p>
          <div className="text-xs text-gray-400">{userName}</div>
        </div>

        <div className="flex justify-end">
          {!isListJobPage && (
            <div
              className={[
                isQuotationLoading || isContrastLoading || isInvoiceLoading
                  ? "dropdown dropdown-end dropdown-open"
                  : "dropdown dropdown-end",
              ].join()}>
              <label tabIndex={9} className="btn  btn-circle btn-ghost">
                <FontAwesomeIcon
                  icon={faCog}
                  className=" text-gray-600 text-lg"
                />
              </label>
              <ul
                tabIndex={9}
                className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-64">
                <li className="my-1">
                  <button
                    className={[
                      isQuotationLoading
                        ? "btn btn-ghost w-full text-xs text-left gap-2 loading"
                        : "btn btn-ghost w-full text-left gap-2",
                    ].join()}
                    disabled={isQuotationLoading || !hasQuotation}
                    onClick={() => {
                      onDownloadQuotation()
                    }}>
                    <div>
                      {isQuotationLoading ? (
                        <div />
                      ) : (
                        <FontAwesomeIcon icon={faFileInvoiceDollar} />
                      )}
                    </div>
                    <Trans>Contract.download-quotation</Trans>
                  </button>
                </li>
                <li className="my-1">
                  <button
                    className={[
                      isContrastLoading
                        ? "btn btn-ghost w-full text-left gap-2 loading"
                        : "btn btn-ghost w-full text-left gap-2",
                    ].join()}
                    disabled={isContrastLoading || !hasContract}
                    onClick={() => {
                      onDownloadContract()
                    }}>
                    <div>
                      {isContrastLoading ? (
                        <div />
                      ) : (
                        <FontAwesomeIcon icon={faFileInvoice} />
                      )}
                    </div>
                    <Trans>Contract.download-contract</Trans>
                  </button>
                </li>
                <li className="my-1">
                  <button
                    className={[
                      isInvoiceLoading
                        ? "btn btn-ghost w-full text-left gap-2 loading"
                        : "btn btn-ghost w-full text-left gap-2",
                    ].join()}
                    disabled={isInvoiceLoading || !hasInvoice}
                    onClick={() => {
                      onDownloadInvoice()
                    }}>
                    <div>
                      {isInvoiceLoading ? (
                        <div />
                      ) : (
                        <FontAwesomeIcon icon={faFileInvoice} />
                      )}
                    </div>

                    <Trans>Contract.download-invoice</Trans>
                  </button>
                </li>
                {/*//suspend job*/}
                {/*<li className="my-1">*/}
                {/*  <button*/}
                {/*    className="grid btn btn-ghost w-full text-left gap-2"*/}
                {/*    style={{ gridTemplateColumns: "100px auto" }}*/}
                {/*    disabled={job.state === JOB_STATUS_TYPE.STATUS_0}*/}
                {/*    // onClick={() => applyJobSuspension()}*/}
                {/*  >*/}
                {/*    <div className="text-red-default">*/}
                {/*      <FontAwesomeIcon icon={faCircleStop} />*/}
                {/*    </div>*/}
                {/*    <div className="text-red-default">*/}
                {/*      <Trans>Contract.suspend-job</Trans>*/}
                {/*    </div>*/}
                {/*  </button>*/}
                {/*</li>*/}
              </ul>
            </div>
          )}
        </div>
      </div>

      <div className="py-2 text-center w-full ">
        {/*    step*/}
        <ul className="steps w-full">
          <li
            className={classNames("step cursor-pointer", {
              "step-primary border-red-default": job.isSubmitQuotation,
            })}
            data-content={
              !job.isSubmitQuotation ||
              job.price === 0 ||
              !job.isConfirmQuotation
                ? "*"
                : ""
            }
            onClick={(e) => {
              e.preventDefault()
              setContractStep(CONTRACT_STEP.QUOTATION)
            }}>
            <span
              className={classNames(
                "text-sm border-2 border-white hover:border-gray-200 px-2 py-0.5 rounded-2xl",
                {
                  "text-gray-400 hover:border-gray-200":
                    stepHeader !== CONTRACT_STEP.QUOTATION,
                  "text-red-default font-bold hover:border-red-default":
                    stepHeader === CONTRACT_STEP.QUOTATION,
                }
              )}>
              <Trans>Contract.step0</Trans>
            </span>
          </li>

          <li
            className={classNames("step cursor-pointer", {
              "step-primary border-red-default": job.isConfirmQuotation,
            })}
            data-content={
              job.isSubmitQuotation && !job.accepted && job.isConfirmQuotation
                ? "*"
                : ""
            }
            onClick={(e) => {
              e.preventDefault()
              setContractStep(CONTRACT_STEP.CONTRACT)
            }}>
            <span
              className={classNames(
                "text-sm border-2 border-white px-2 py-0.5 rounded-2xl",
                {
                  "text-gray-400 hover:border-gray-200":
                    stepHeader !== CONTRACT_STEP.CONTRACT,
                  "text-red-default font-bold hover:border-red-default":
                    stepHeader === CONTRACT_STEP.CONTRACT,
                }
              )}>
              <Trans>Contract.step1</Trans>
            </span>
          </li>

          <li
            className={classNames("step cursor-pointer", {
              "step-primary border-red-default": job.accepted,
            })}
            data-content={
              job.accepted && (!job.paid || job.isFinish) ? "*" : ""
            }
            onClick={(e) => {
              e.preventDefault()
              setContractStep(CONTRACT_STEP.PAYMENT)
            }}>
            <span
              className={classNames(
                "text-sm border-2 border-white hover:border-gray-200 px-2 py-0.5 rounded-2xl",
                {
                  "text-gray-400 hover:border-gray-200":
                    stepHeader !== CONTRACT_STEP.PAYMENT,
                  "text-red-default font-bold hover:border-red-default":
                    stepHeader === CONTRACT_STEP.PAYMENT,
                }
              )}>
              <Trans>Contract.step3</Trans>
            </span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ContractToolBar
