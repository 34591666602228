import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { Trans } from "react-i18next"
import { DateObj } from "../../../Model/Utilities"
import CustomAlert from "../../../Component/NewAlert"

interface PropsType {
  payRefID: string
  payDate: DateObj
  skipPayment?: boolean
}

const CheckoutRefCode: React.FC<PropsType> = ({
  payRefID,
  payDate,
  skipPayment,
}) => {
  return (
    <div className="w-full shadow rounded border-t-8 border-green-300 my-4 p-4 pb-4 bg-white">
      <h2 className="text-2xl font-bold text-green-600 my-4">
        <FontAwesomeIcon icon={faCheckCircle} className={"mr-2"} />
        <Trans>
          {skipPayment
            ? "ContractSkipPayment.skip-payment-successful"
            : "CheckoutForm.checkout-successful"}
        </Trans>
      </h2>
      {skipPayment && (
        <p className="text-sm text-gray-400">
          <Trans>ContractSkipPayment.modal-desc</Trans>
        </p>
      )}
      {!skipPayment && (
        <div className="text-sm font-medium  max-w-full flex-initial">
          <div>
            <p>
              <Trans>CheckoutForm.ref-code</Trans>
              {payRefID}
            </p>
            <p className="text-xs font-normal  text-gray-400 mt-4">
              {new Date(payDate.seconds * 1000).toISOString()}
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
export default CheckoutRefCode
