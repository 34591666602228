import React, { useEffect, useState } from "react"
import { SCROLL_DIR } from "../Enum/PROFILE_TYPE"
import classNames from "classnames"
import KOLPlanItem from "../page-components/home/KOLPlanItem"
import { Trans } from "react-i18next"
import { useSelector } from "react-redux"
import { CAROUSEL_TYPE } from "../Enum/CAROUSEL_SYSTEM"

interface carouselProps {
  indexName: CAROUSEL_TYPE
  isLoaded: boolean
  dataSets: any[]
  displayComponent: (data: any, index: number) => JSX.Element
  shownConditions?: (data: any, index: number) => boolean
  emptyDataMessage: string
  forceMobileLayout?: boolean
}

export const CustomCarousel: React.FC<carouselProps> = ({
  indexName,
  isLoaded,
  dataSets,
  displayComponent,
  shownConditions,
  emptyDataMessage,
  forceMobileLayout,
}) => {
  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })

  const [scrollBtn, setScrollBtn] = useState<{
    isLeftDisabled: boolean
    isRightDisabled: boolean
  }>({
    isLeftDisabled: false,
    isRightDisabled: false,
  })

  const indexID = indexName + "-carousel"

  function scrollCarousel(scrollDirection: SCROLL_DIR, id: string) {
    const carouselElement = document.getElementById(id)
    if (carouselElement) {
      let carouselWidth = carouselElement.clientWidth

      let scrollLeft = carouselElement.scrollLeft

      let targetXPixel = 0
      switch (scrollDirection) {
        case SCROLL_DIR.LEFT:
          targetXPixel = scrollLeft - carouselWidth + 1
          break
        case SCROLL_DIR.RIGHT:
          targetXPixel = scrollLeft + carouselWidth + 1
          break
        default:
          break
      }
      carouselElement.scrollTo(targetXPixel, 0)
    }
  }

  const scrollChecker = (id: string) => {
    setScrollBtn({
      isLeftDisabled: false,
      isRightDisabled: false,
    })
    let ele = document.getElementById(id)
    const scrollOffset = 5

    let scrollLeft = ele!.scrollLeft
    let scrollWidth = ele!.scrollWidth
    let offsetWidth = ele!.offsetWidth
    let contentWidth = scrollWidth - offsetWidth - scrollOffset

    let tmpScroll = {
      isLeftDisabled: false,
      isRightDisabled: false,
    }
    if (contentWidth <= scrollLeft || contentWidth <= 0) {
      tmpScroll = {
        isLeftDisabled: scrollBtn.isLeftDisabled,
        isRightDisabled: true,
      }
    }
    if (0 >= scrollLeft) {
      tmpScroll = {
        isLeftDisabled: true,
        isRightDisabled: scrollBtn.isRightDisabled,
      }
    }
    setScrollBtn(tmpScroll)
  }

  useEffect(() => {
    if (isLoaded) {
      const scrollElement = document.getElementById(indexID)
      if (scrollElement) {
        scrollChecker(indexID)
        //componentWillUnmount
        scrollElement.addEventListener("scroll", () => scrollChecker(indexID))
      }
    }

    return () => {
      // componentDidMount
      const scrollElement = document.getElementById(indexID)
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", () =>
          scrollChecker(indexID)
        )
      }
    }
  }, [isLoaded])
  return (
    <div className="my-4 pb-2 relative">
      <div className="absolute z-10 flex justify-between transform -translate-y-1/2 left-2 right-2 top-1/2">
        {scrollBtn.isLeftDisabled ? (
          <div />
        ) : (
          <button
            onClick={() => {
              scrollCarousel(SCROLL_DIR.LEFT, indexID)
            }}
            className={classNames(
              "btn btn-sm btn-circle btn-ghost opacity-75 active:bg-base-300 active:opacity-100",
              {
                glass: screenWidth > 768,
              }
            )}>
            ❮
          </button>
        )}

        {scrollBtn.isRightDisabled ? (
          <div />
        ) : (
          <button
            onClick={() => {
              scrollCarousel(SCROLL_DIR.RIGHT, indexID)
            }}
            className={classNames(
              "btn btn-sm btn-circle btn-ghost opacity-75 active:bg-base-300 active:opacity-100",
              {
                glass: screenWidth > 768,
              }
            )}>
            ❯
          </button>
        )}
      </div>
      <div
        id={indexID}
        className={classNames(
          "carousel carousel-center max-w-full px-4 space-x-4 rounded-box overflow-x-auto",
          {
            "w-full": screenWidth < 768 || forceMobileLayout,
          }
        )}>
        {dataSets.length > 0 ? (
          dataSets.map((data, index) => {
            const renderCondition =
              typeof shownConditions === "undefined"
                ? true
                : shownConditions(data, index)
            if (renderCondition) {
              return (
                <div
                  className={classNames("carousel-item py-4", {
                    "relative w-full flex justify-center":
                      screenWidth < 768 || forceMobileLayout,
                  })}
                  id={indexName + "-item-" + index}>
                  {displayComponent(data, index)}
                </div>
              )
            }
          })
        ) : (
          <div className="text-gray-500 px-2 font-medium text-base">
            <Trans>{emptyDataMessage}</Trans>
          </div>
        )}
      </div>
    </div>
  )
}
