import React from "react"
import { t } from "i18next"

import { useHistory, useLocation } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

import { getUserLang } from "../../Utils/utiltyHelper"
import { HelpDocHeader } from "./HelpDocHeader/HelpDocHeader"
import {
  CHATROOM_HEADER,
  CONTRACT_HEADER,
  ERROR_HEADER,
  headerHelpDocs,
  REGISTER_HEADER,
} from "../../Utils/HelpButtonHelperFunction"
import classNames from "classnames"

interface NormalHeaderProps {
  title: string
}

export const NormalHeader: React.FC<NormalHeaderProps> = ({ title }) => {
  let history = useHistory()

  const isChat = location.pathname.includes("/c")
  const isContractList = location.pathname.includes("/j")
  const isRegister = location.pathname.includes("/register")

  const helpDocPaths = (): headerHelpDocs => {
    if (isChat) {
      return CHATROOM_HEADER
    } else if (isContractList) {
      return CONTRACT_HEADER
    } else if (isRegister) {
      return REGISTER_HEADER
    }
    return ERROR_HEADER
  }

  return (
    <div
      className="h-18 my-auto w-full py-2 px-4 grid gap-4 items-center"
      style={{
        height: "60px",
        gridTemplateColumns: "40px auto 20px",
      }}>
      <div>
        <button
          className="my-auto h-full text-left my-2 mx-2 text-gray-600 font-bold text-xl"
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            history.push("/" + getUserLang())
          }}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      </div>
      <div className="my-auto overflow-ellipsis w-full text-lg font-medium uppercase">
        {title}
      </div>
      {(isChat || isRegister || isContractList) && (
        <div className="flex justify-end">
          <HelpDocHeader helpDocs={helpDocPaths()} />
        </div>
      )}
    </div>
  )
}
