import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Trans } from "react-i18next"

import ListKOLJobTable from "./ListKOLJobTable"

import {
  getJobList,
  jobListCondition,
} from "../../../HelpingFunction/Job/JobDBHelper"
import { getKOL } from "../../../HelpingFunction/KOL/kolDBHelper"
import { Job, JobFilterDefault } from "../../../Model/Job"
import KOL from "../../../Model/KOL"
import { PageType, Project } from "../../../Model/ProjectManagement"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import {
  defaultContractTableSort,
  getMySort,
  getPaginationData,
  PAGINATION_DIRECTION,
  SORT_OPTION,
} from "../../../Utils/TableHelperFunction"
import { autoScrollHelper } from "../../../Utils/hlaScrollerHelper"
import { SCROLL_DIRECTION } from "../../../Enum/AUTO_SCROLLER_TYPE"

const JobListProject = () => {
  const dispatch = useDispatch()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const selectedKOL: string = useSelector((state: any) => {
    return state.ProjectManager.selectedKOL
  })
  const selectedKOLName: string = useSelector((state: any) => {
    return state.ProjectManager.selectedKOLName
  })
  const isLoaded = useSelector((state: any) => {
    return state.ProjectManager.isLoadedKOL
  })
  const project: Project = useSelector((state: any) => {
    return state.ProjectManager.currentProject
  })
  const kols: KOL[] = useSelector((state: any) => {
    return state.ProjectManager.kolList
  })
  const jobs: Job[] = useSelector((state: any) => {
    return state.ProjectManager.jobList
  })
  const setPage = (page: PageType) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_PROJECT_PAGE,
      data: page,
    })
  }
  const setProject = (project: Project) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CURRENT_PROJECT,
      data: project,
    })
  }
  const setKOLList = (kols: KOL[]) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_KOL_LIST,
      data: kols,
    })
  }
  const setIsExpanded = (expand: boolean) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_PROJECT_PAGE_EXPAND,
      data: expand,
    })
  }

  const [KOLJob, setKOLJob] = useState<Job[]>([])
  const [isLoadedJob, setIsLoadedJob] = useState<boolean>(false)
  const [isGettingMoreJob, setIsGettingMoreJob] = useState(false)
  const [hasMore, setHasMore] = useState<boolean>(false)

  const [condition, setCondition] = useState<jobListCondition>(JobFilterDefault)

  const [sortOptions, setSortOptions] = useState<SORT_OPTION[]>(
    defaultContractTableSort
  )

  useEffect(() => {
    getJobList(condition, auth.uid, getMySort(sortOptions), 11).then(
      (result) => {
        if (result.success) {
          let finalData = result.data
          //always return hasMore (for nextBtn) as true when loading prev page, otherwise nextBtn might not work
          if (condition.paginationDirection === PAGINATION_DIRECTION.PREV) {
            finalData = finalData.reverse()
            setHasMore(true)
          } else {
            setHasMore(result.hasMore)
          }

          const isRepeatedJob = KOLJob.map((r) => r.id).some((r) =>
            finalData.map((r) => r.id).includes(r)
          )
          if (condition.paginationData === "") {
            //replace the whole jobArr
            setKOLJob(finalData)
          } else if (isGettingMoreJob && !isRepeatedJob) {
            setKOLJob([...KOLJob, ...finalData])
          }

          setIsLoadedJob(true)
          setIsGettingMoreJob(false)
        }
      }
    )
  }, [condition])

  useEffect(() => {
    setCondition({
      ...JobFilterDefault,
      uid: auth.uid,
      customerID: auth.uid,
      kolID: selectedKOL,
    })
  }, [selectedKOL])

  useEffect(() => {
    if (document.getElementById("JobListProjectContainer")) {
      //componentWillUnmount
      document
        .getElementById("JobListProjectContainer")!
        .addEventListener("scroll", hlaScroll)
    }
    return () => {
      // componentDidMount
      if (document.getElementById("JobListProjectContainer")) {
        document
          .getElementById("JobListProjectContainer")!
          .removeEventListener("scroll", hlaScroll)
      }
    }
  }, [isLoadedJob, KOLJob, condition])

  const getMoreProjectJobsFn = async () => {
    if (KOLJob.length > 0 && selectedKOL) {
      await setCondition({
        ...condition,
        kolID: selectedKOL,
        paginationData: getPaginationData(
          sortOptions,
          KOLJob[KOLJob.length - 1]
        ),
        paginationDirection: PAGINATION_DIRECTION.NEXT,
      })
    }
  }

  const hlaScroll = () => {
    let ele = document.getElementById("JobListProjectContainer")
    ele!.onscroll = async function () {
      if (ele) {
        autoScrollHelper(
          ele,
          hasMore,
          isLoadedJob && KOLJob.length % 10 === 0,
          getMoreProjectJobsFn,
          SCROLL_DIRECTION.BOTTOM_DETECTION
        )
      }
    }.bind(this)
  }

  const updateProject = (p: Project, kols: KOL[]) => {
    setProject(p)
    setKOLList(kols)
  }

  const handleClick = async () => {
    let found = jobs.find((job) => job.KOLID === selectedKOL)
    if (found && !project.kolList.includes(selectedKOL)) {
      let newKOLs = [...kols]
      project.kolList.push(selectedKOL)
      const res = await getKOL(selectedKOL)
      if (res.success) {
        newKOLs.push(res.data)
      }
      updateProject(project, newKOLs)
    }
    setPage(PageType.KOL)
  }

  return (
    <div className=" w-full relative">
      <div className="sticky h-16 top-0 px-1 w-full bg-white z-10 border-b">
        <button
          onClick={() => {
            setIsExpanded(true)
            if (project.id) {
              setPage(PageType.ProjectLinks)
            } else {
              setPage(PageType.KOL)
            }
          }}
          className="font-medium text-lg my-2 btn btn-ghost normal-case">
          <span className="cursor-pointer mr-2 ">
            <FontAwesomeIcon icon={faArrowLeft} />
          </span>
          <Trans>ProjectOptionBar.select</Trans> {selectedKOLName}{" "}
          <Trans>ProjectOptionBar.job</Trans>
        </button>
      </div>

      <div className="h-96 overflow-y-auto" id="JobListProjectContainer">
        <ListKOLJobTable
          data={KOLJob}
          isGettingMoreJob={isGettingMoreJob}
          hasMore={hasMore}
        />
      </div>

      <div className="sticky bottom-0 bg-white text-center w-full px-2 py-2 z-10">
        <button
          onClick={() => {
            setIsExpanded(false)
            handleClick()
          }}
          className="btn btn-info  w-full mx-auto ">
          <Trans>ProjectOptionBar.done</Trans>
        </button>
      </div>
    </div>
  )
}

export default JobListProject
