import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import { Route, Switch, useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Trans, useTranslation } from "react-i18next"
import { AnimatePresence } from "framer-motion"
import { getMessaging, onMessage } from "firebase/messaging"
import { getAuth, onAuthStateChanged } from "firebase/auth"

import ModalController from "../../Component/ModalController/ModalController"

import { isAuth, returnIdTokenResult } from "../../Utils/FirebaseUtils"
import * as cloudMessagingRegistration from "../../Utils/CloudMessaging"
import * as serviceWorkerRegistration from "../../serviceWorkerRegistration.js"

import HeadingMessageManager from "../../Component/HeadingMessageManager/HeadingMessageManager"

import Heading from "../../Layout/Heading/NewHeading"

import Footer from "../../page-components/Footer"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"

import firebaseApp from "../../Config/firebase"
import OverlaySystem from "../../page-components/OverlaySystem/OverlaySystem"
import { getKOLUserName } from "../../HelpingFunction/KOL/kolDBHelper"
import { ROLE } from "../../Enum/APP_TYPE"
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary"

import {
  NavButtonGroup,
  NavButtonGroupDir,
} from "../../Component/NavButtonGroup"
import { MobileHeader } from "../../Component/MobileHeader/MobileHeader"
import classNames from "classnames"
import {
  checkViewAsQuery,
  getUserLang,
  isPhoneDevice,
} from "../../Utils/utiltyHelper"
import { useQuery } from "../../Utils/QueryHelper"
import { SUPPORTED_MODAL_TYPE } from "../../Utils/ModalType"
import toast, { Toaster } from "react-hot-toast"
import CreateInvoice from "../../pages/create-invoice"

const fbAuth = getAuth()

const Blog = lazy(() => import("../../pages/blog"))
const AboutPage = lazy(() => import("../../pages/about"))
const Homepage = lazy(() => import("../../pages"))
const EditKOLProfile = lazy(() => import("../../pages/p/edit"))
const KOLSetting = lazy(() => import("../../pages/kol/[index]"))
const CustomerSetting = lazy(() => import("../../pages/customer/[index]"))
const SearchPage = lazy(() => import("../../pages/s"))
const AppSetting = lazy(() => import("../../pages/setting"))
const BlogPage = lazy(() => import("../../pages/blog/[url]"))
const VerifyPage = lazy(() => import("../../pages/verify"))
const CustomerTools = lazy(() => import("../../pages/tools/index"))
const ChatRoom = lazy(() => import("../../pages/c/[id]"))
const ListContract = lazy(() => import("../../page-components/c/ListContract"))
const SignUpPage = lazy(() => import("../../pages/signup"))
const ProfileLinksPage = lazy(() => import("../../pages/link/[id]"))
const KOLProfileComponent = lazy(() => import("../../pages/p/[id]"))
const JobPreviewComponent = lazy(() => import("../../pages/job-preview/[id]"))
const NewLogin = lazy(() => import("../../pages/login"))
const RegisterPage = lazy(() => import("../../pages/register"))
const Look4KOLPro = lazy(() => import("../../pages/kol-pro"))
const VerifyPhone = lazy(() => import("../../pages/verify-phone"))
const Onboarding = lazy(() => import("../../pages/onboarding"))
const fbHandler = lazy(() => import("../../pages/fbHandler"))
const ProjectManagement = lazy(() => import("../../pages/project"))
const Management = lazy(() => import("../../pages/management"))
const AssistantPage = lazy(() => import("../../pages/assistant"))
const AssistantRecommendation = lazy(() => import("../../pages/assistant-rec"))
const InvoiceComponent = lazy(() => import("../../pages/invoice/[id]"))
const QuotePage = lazy(() => import("../../pages/quote"))
const CreateDraftContract = lazy(() => import("../../pages/create-draft"))

const RouterManager: React.FC = (props: any) => {
  const { i18n } = useTranslation()
  const location = useLocation()
  let history = useHistory()
  const dispatch = useDispatch()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })

  const signUp = useSelector((state: any) => {
    return state.SystemManager.forceSignUp
  })
  const forcePhoneVerify = useSelector((state: any) => {
    return state.SystemManager.forcePhoneVerify
  })
  const fromSearchPage = useSelector((state: any) => {
    return state.KOLInfoManager.fromSearchPage
  })

  const [role, setRole] = useState<ROLE>(ROLE.DEFAULT)
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null)
  const [showReload, setShowReload] = useState<boolean>(false)

  useEffect(() => {
    if (
      location.pathname.split("/").length > 0 &&
      location.pathname.split("/")[1] !== ""
    ) {
      const langCode = location.pathname.split("/")[1]
      i18n.changeLanguage(langCode)
      localStorage.setItem("i18n-lang", langCode)
    } else {
      //set Default language to hk
      localStorage.setItem("i18n-lang", "hk")
      history.push("/hk")
    }
  }, [])

  function setForceSignUp(value: boolean) {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SIGN_UP_KOL,
      forceSignUp: value,
    })
  }

  function setForcePhoneVerify(value: boolean) {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SIGN_UP_CUSTOMER,
      forcePhoneVerify: value,
    })
  }

  const setSearchPageRoute = (isSearchPage: boolean) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_PAGE_ROUTE,
      data: isSearchPage,
    })
  }

  const updateQuery = (id: URLSearchParams) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_VIEW_ID,
      data: id,
    })
  }

  const openPhoneVerifyModal = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.VERIFY_PHONE_MODAL,
    })
  }

  let query = useQuery()
  // update query
  useEffect(() => {
    updateQuery(query)
  }, [query.toString()])
  // Override the history.push method

  const prevLocation = useRef({
    pathname: location.pathname,
    search: location.search,
  })

  const originalPush = history.push
  history.push = (path, state) => {
    let newPath = path
    if (typeof path === "string" && path.includes("?")) {
      //fix url if search query is inside pathname
      newPath = { pathname: path.split("?")[0], search: path.split("?")[1] }
    }
    let modifiedPath = checkViewAsQuery(prevLocation.current.search, newPath)
    prevLocation.current = modifiedPath

    if (
      modifiedPath.search.includes("viewAs") ||
      newPath.search?.toString().includes("returnToCustomer")
    ) {
      updateQuery(new URLSearchParams(modifiedPath.search))
      // Use history.replace function on paths that have viewAs or returnToCustomer params
      // since modifying query in middleware will cause unwanted looping if we use history.push
      history.replace(modifiedPath, state)
    } else {
      // Call the original history.push with the modified path and state
      originalPush.call(history, modifiedPath, state)
    }
  }

  useEffect(() => {
    if (isAuth(auth)) {
      cloudMessagingRegistration.register(auth)
      localStorage.setItem("look4kol-auth-uid", auth.uid)

      returnIdTokenResult().then((res) => {
        if (res.success) {
          setRole(res.role)
          if (res.role === "kol") {
            getKOLUserName(auth.uid).then((result) => {
              if (!result.success || result.userName === "") {
                setForceSignUp(true)
                history.push("/" + getUserLang() + "/signup")
              }
            })
          }
          // else if (res.role === "customer") {
          //   getCustomer(auth.uid).then((result) => {
          //     if (result.success) {
          //       if (!result.data.isVerified) {
          //         setForcePhoneVerify(true)
          //         openPhoneVerifyModal()
          //         // history.push("/" + getUserLang() + "/verify-phone")
          //       }
          //     }
          //   })
          // }
        }
      })
    }
  }, [auth])

  useEffect(() => {
    if (signUp === true) {
      history.push("/" + getUserLang() + "/signup")
    }
    if (forcePhoneVerify === true) {
      openPhoneVerifyModal()
    }
  }, [location.pathname])

  useEffect(() => {
    if (fromSearchPage) {
      if (!location.pathname.includes("/p")) {
        setSearchPageRoute(false)
      }
    }
  }, [location.pathname])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let data: any = JSON.parse(localStorage.getItem("look4kol-auth"))
    console.log(data)
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.LOGIN,
      data: data,
    })
  }, [])

  useEffect(() => {
    try {
      const messaging = getMessaging(firebaseApp)
      onMessage(messaging, (payload) => {
        if (payload.data) {
          // eslint-disable-next-line no-new
          new Notification(
            payload.data.title ? payload.data.title : "look4kol",
            {
              body: payload.data.body,
              icon: payload.data.icon,
              image: payload.data.image,
              data: payload.data.click_action,
            }
          )
        }
      })
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    onAuthStateChanged(fbAuth, (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          dispatch({
            type: SUPPORTED_REDUX_FUNCTIONS.LOGIN,
            data: {
              email: user.email,
              uid: user.uid,
              photoURL: user.photoURL,
              displayName: user.displayName,
              token: idTokenResult.token,
              role: idTokenResult.claims.role as ROLE,
              expirationTime: idTokenResult.expirationTime,
              custID: idTokenResult.claims.custID
                ? idTokenResult.claims.custID
                : null,
            },
          })
        })
      } else {
        // logout()
      }
    })
  }, [fbAuth])

  useLayoutEffect(() => {
    function updateSize() {
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.SET_SCREEN_WIDTH,
        screenWidth: window.innerWidth,
      })
      dispatch({
        type: SUPPORTED_REDUX_FUNCTIONS.SET_SCREEN_HEIGHT,
        screenHeight: window.innerHeight,
      })
    }

    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])

  // registration onUpdate.
  const onSWUpdate = useCallback((registration: ServiceWorkerRegistration) => {
    setShowReload(true)
    setWaitingWorker(registration.waiting)
  }, [])

  const reloadPage = useCallback(() => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" })
    setShowReload(false)
    window.location.reload()
  }, [waitingWorker])

  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate,
    })
  }, [onSWUpdate])

  // decides when to show the toast
  useEffect(() => {
    if (showReload && waitingWorker) {
      toast(
        <div className="flex flex-row items-center">
          <p>
            <Trans>ServiceWorkerToast.title</Trans>
          </p>
          <button
            className="w-fit bg-gray-700 text-white rounded-lg mx-4 p-2"
            onClick={() => reloadPage()}>
            <Trans>ServiceWorkerToast.button-text</Trans>
          </button>
        </div>
      )
    }
  }, [waitingWorker, showReload, reloadPage])

  const specialPaths = [
    "/c",
    "/kol/",
    "/customer/",
    "/tools",
    "/j",
    "/s",
    "/link",
    "/login",
    "/register",
    "/project",
    "/p",
    "/mobile",
    "/assistant-chat",
    "/assistant-rec",
    "/invoice",
  ]
  const isSpecialPath = (str: string): boolean =>
    specialPaths.some((path) => str.includes(path))

  const isInUrl = (path: string) => {
    return location.pathname.includes("/" + getUserLang() + "/" + path)
  }

  return (
    <>
      <ModalController />
      <OverlaySystem />

      <div className="flex flex-col h-screen" style={{ maxHeight: "100svh" }}>
        {/*Header*/}
        <header className="z-20 sticky md:static top-0 w-screen bg-white ">
          <div className="md:hidden">
            {!isInUrl("onboarding") && <MobileHeader path={""} />}
          </div>
          <div className="hidden md:inline-block">
            <Heading />
          </div>
        </header>

        {/* main */}
        <div
          className={classNames(
            "md:mb-0 md:grid overflow-y-scroll md:py-0 h-full",
            {
              "mb-24": window.location.pathname === "/c",
              "select-none": isPhoneDevice,
              "mt-0": window.location.pathname.includes("/link/"),
            }
          )}
          style={{ gridTemplateColumns: "90px auto" }}>
          <div className={classNames("hidden md:inline-block z-30", {})}>
            <NavButtonGroup dir={NavButtonGroupDir.COL} />
          </div>
          <div
            className={classNames("overflow-x-hidden h-full", {
              "!mt-0": isInUrl("link/"),
            })}>
            <main
              id="mainContainer"
              className={classNames({
                "h-full":
                  (isInUrl("c") || isInUrl("message")) &&
                  (!isInUrl("blog") || !isInUrl("kol-pro")),
              })}>
              <Suspense fallback={<div></div>}>
                <AnimatePresence exitBeforeEnter initial={screenWidth < 768}>
                  <ErrorBoundary>
                    <Switch location={location} key={location.pathname}>
                      <Route path={"/fb_handler"} exact component={fbHandler} />

                      <Route path={"/:lang"} exact component={Homepage} />
                      <Route
                        path={"/:lang/about"}
                        exact
                        component={AboutPage}
                      />
                      <Route
                        path={"/:lang/p/:id/edit"}
                        exact
                        component={EditKOLProfile}
                      />
                      <Route
                        path={"/:lang/p/:id/:type?"}
                        exact
                        component={KOLProfileComponent}
                      />
                      <Route path={"/:lang/j"} exact component={ListContract} />

                      <Route path={"/:lang/kol"} component={KOLSetting} />
                      <Route
                        path={"/:lang/customer"}
                        component={CustomerSetting}
                      />
                      <Route path={"/:lang/tools"} component={CustomerTools} />
                      <Route path={"/:lang/c"} component={ChatRoom} />
                      <Route
                        path={"/:lang/s/:searchStr?"}
                        component={SearchPage}
                      />
                      <Route
                        path={"/:lang/message"}
                        exact
                        component={HeadingMessageManager}
                      />
                      <Route
                        path={"/:lang/blog/:url"}
                        exact
                        component={BlogPage}
                      />
                      <Route path={"/:lang/blog"} exact component={Blog} />
                      <Route path={"/:lang/setting"} component={AppSetting} />
                      <Route
                        path={"/:lang/verify/:token?"}
                        component={VerifyPage}
                      />

                      <Route path={"/:lang/login"} exact component={NewLogin} />
                      <Route
                        path={"/:lang/register"}
                        exact
                        component={RegisterPage}
                      />
                      <Route
                        path={"/:lang/signup"}
                        exact
                        component={SignUpPage}
                      />
                      <Route
                        path={"/:lang/link/:id"}
                        exact
                        component={ProfileLinksPage}
                      />

                      <Route
                        path={"/:lang/management"}
                        exact
                        component={Management}
                      />
                      <Route
                        path={"/:lang/kol-pro"}
                        exact
                        component={Look4KOLPro}
                      />
                      <Route
                        path={"/:lang/verify-phone"}
                        exact
                        component={VerifyPhone}
                      />
                      <Route
                        path={"/:lang/onboarding"}
                        exact
                        component={Onboarding}
                      />
                      <Route
                        path={"/:lang/project/:projectID?"}
                        exact
                        component={ProjectManagement}
                      />
                      <Route
                        path={"/:lang/job-preview/:id"}
                        exact
                        component={JobPreviewComponent}
                      />
                      <Route
                        path={"/:lang/assistant-chat"}
                        exact
                        component={AssistantPage}
                      />
                      <Route
                        path={"/:lang/assistant-rec"}
                        exact
                        component={AssistantRecommendation}
                      />
                      <Route
                        path={"/:lang/create-invoice"}
                        exact
                        component={CreateInvoice}
                      />
                      <Route
                        path={"/:lang/invoice/:id"}
                        exact
                        component={InvoiceComponent}
                      />
                      <Route
                        path={"/:lang/create-draft"}
                        exact
                        component={CreateDraftContract}
                      />
                      <Route
                        path={"/:lang/quote"}
                        exact
                        component={QuotePage}
                      />
                    </Switch>
                  </ErrorBoundary>
                </AnimatePresence>
              </Suspense>
            </main>

            {!isSpecialPath(location.pathname) && (
              <div className=" lg:block w-full hidden md:inline-block md:mb-0">
                <Footer />
              </div>
            )}
          </div>
          <Toaster
            position="bottom-center"
            toastOptions={{
              duration: 7000,
            }}
          />
        </div>

        {/* bottom */}

        {!(
          window.location.pathname.includes("/chat") ||
          window.location.pathname.includes("/link/") ||
          window.location.pathname.includes("/onboarding") ||
          window.location.pathname.includes("assistant-chat")
        ) && (
          <div className="z-10 min-h-16 w-full bottom-0 md:hidden safe-are-detection-bottom">
            <NavButtonGroup dir={NavButtonGroupDir.ROW} />
          </div>
        )}
      </div>
    </>
  )
}

export default RouterManager
