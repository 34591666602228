export enum RECOMMEND_TYPE {
  DEFAULT = "default",
  JOB_TYPE = "type",
  AGE = "age",
  GENDER = "gender",
  LOCATION = "location",
}

export enum SCROLL_DIR {
  DEFAULT = "default",
  LEFT = "left",
  RIGHT = "right",
}
