import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons"
import {
  faArrowLeft,
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { getUserLang } from "../../Utils/utiltyHelper"
import { Trans } from "react-i18next"

const AssistantChatHeader = () => {
  const history = useHistory()

  const progress: number = useSelector((state: any) => {
    return state.AssistantManager.progress
  })

  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  return (
    <div className="shadow relative">
      <section className="px-4 py-4 flex flex-row items-center gap-2">
        <button
          className="text-gray-700 "
          onClick={() => history.push("/" + getUserLang())}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <h1 className="text-lg text-gray-700 font-medium">
          <Trans>AssistantChat.assistant-name</Trans>
        </h1>
        <div className="ml-auto">
          <a target={"_blank"} href="https://calendly.com/look4kol/look4kol">
            <button className="btn btn-sm btn-primary mr-4">
              <Trans>Heading.marketing</Trans>
            </button>
          </a>

          <button
            className="text-gray-700 text-right   text-lg"
            onClick={() => setIsExpanded(!isExpanded)}>
            <FontAwesomeIcon
              icon={isExpanded ? faChevronCircleUp : faChevronCircleDown}
            />
          </button>
        </div>
      </section>
      <AnimatePresence>
        {isExpanded && (
          <motion.section
            className="p-3"
            key="content"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{
              height: 0,
              opacity: 0,
              transition: { duration: 0.4, ease: "easeOut" },
            }}
            transition={{ duration: 0.4, ease: "easeInOut" }}>
            <h3 className="text-lg text-gray-700 font-semibold">
              <Trans>AssistantChat.mobile-title</Trans>
            </h3>
            <div className="text-gray-500 font-medium text-sm my-2">
              <p>
                <Trans>AssistantChat.tutorial-1</Trans>
              </p>
              <p>
                <Trans>AssistantChat.tutorial-2</Trans>
              </p>
              <p>
                <Trans>AssistantChat.tutorial-3</Trans>
              </p>
            </div>
            <p className="text-primary text-sm font-semibold text-center">
              <Trans>AssistantChat.mobile-des</Trans>
            </p>
          </motion.section>
        )}
      </AnimatePresence>
      <progress
        className="progress progress-primary w-full h-1 absolute bottom-0"
        value={progress}
        max="100"></progress>
    </div>
  )
}

export default AssistantChatHeader
