import { dateObjToString } from "./Converter"
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  setDoc,
} from "firebase/firestore"
import firebaseApp from "../Config/firebase"

const db = getFirestore(firebaseApp)

export function createNewChatRoom(chatroomID, data) {
  return new Promise(async (resolve, reject) => {
    await setDoc(doc(db, "ChatRoom", chatroomID), data)
      .then((res) => {
        resolve({
          success: true,
        })
      })
      .catch((err) => {
        console.log(err)
        resolve({
          success: false,
        })
      })
  })
}

export function sendNewChatRoomMessage(
  chatroomID,
  JobObject,
  attachmentData,
  requestForm
) {
  /**
   * @param {json} JobObject from ContactKOLModal
   * @param {Array} array of uploaded attachment
   */
  let dateNum = new Date().getTime()

  const welcomeMsg = [
    {
      createDate: new Date(dateNum),
      createUserID: JobObject.CustomerID,
      type: "text",
      msg: "Hi, I am interested for your service plan:",
    },
    {
      createDate: new Date(dateNum + 1000),
      createUserID: JobObject.CustomerID,
      type: "link-text",
      msg:
        "<a href='/p/" +
        JobObject.KOLID +
        "/service/" +
        JobObject.ServicePlanID +
        "'>" +
        JobObject.ServicePlanName +
        "</a>",
    },
    {
      createDate: new Date(dateNum + 2000),
      createUserID: JobObject.CustomerID,
      type: "text",
      msg: requestForm.description,
    },
    {
      createDate: new Date(dateNum + 3000),
      createUserID: JobObject.CustomerID,
      type: "text",
      msg: "Start Date: " + dateObjToString(requestForm.dateFrom),
    },
    {
      createDate: new Date(dateNum + 4000),
      createUserID: JobObject.CustomerID,
      type: "text",
      msg: "End Date: " + dateObjToString(requestForm.dateEnd),
    },
  ]

  return new Promise((resolve, reject) => {
    let allPromise = []
    welcomeMsg.map((e) => {
      allPromise.push(
        new Promise(async (resolve1, reject1) => {
          await addDoc(collection(db, "ChatRoom", chatroomID, "Message"), e)
            .then((res) => {
              resolve1({
                success: true,
              })
            })
            .catch((err) => {
              resolve1({
                success: false,
              })
            })
        })
      )
    })

    Promise.all(allPromise).then((res) => {
      if (Array.isArray(attachmentData) && attachmentData.length > 0) {
        dateNum = dateNum + 5000
        let allAttachmentMsgArr = []
        attachmentData.map((e, index) => {
          let newMsg = {
            createDate: new Date(dateNum + index * 1000),
            createUserID: JobObject.CustomerID,
            type: e.type,
            fileName: e.fileName,
            fileSize: e.fileSize,
            url: e.url,
          }
          if (e.type === "image") {
            newMsg.thumbnailUrl = e.thumbnailUrl
          }
          allAttachmentMsgArr.push(
            new Promise(async (resolve1, reject1) => {
              await addDoc(collection(db, "ChatRoom", chatroomID, "Message"), e)
                .then((res) => {
                  resolve({
                    success: true,
                  })
                })
                .catch((err) => {
                  resolve({
                    success: false,
                  })
                })
            })
          )
        })

        Promise.all(allAttachmentMsgArr)
          .then((res) => {
            resolve({
              success: true,
            })
          })
          .catch((err) => console.error(err))
      } else {
        resolve({
          success: true,
        })
      }
    })
  })
}
