import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import KOL from "../../../Model/KOL"
import { PageType } from "../../../Model/ProjectManagement"
import { getKOL } from "../../../HelpingFunction/KOL/kolDBHelper"
import classNames from "classnames"
import { isChatroomExists } from "../../../HelpingFunction/Chatroom/chatroomDBHelper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowLeft,
  faBriefcase,
  faComment,
  faUser,
} from "@fortawesome/free-solid-svg-icons"
import { Trans } from "react-i18next"

const ProjectLinks = () => {
  const dispatch = useDispatch()

  const selectedKOLID = useSelector((state: any) => {
    return state.ProjectManager.selectedKOL
  })
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const projectKOLTab = useSelector((state: any) => {
    return state.ProjectManager.allKOLsTab
  })
  const selectedKOLName = useSelector((state: any) => {
    return state.ProjectManager.selectedKOLName
  })

  const setKOLProfile = (kolProfile: KOL) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_JOB_KOL,
      data: kolProfile,
    })
  }

  const setProjectPage = (page: PageType, pagePropsID?: string) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_PROJECT_PAGE,
      data: page,
      pagePropsID: pagePropsID,
    })
  }

  const [isLoadedProfile, setIsLoadedProfile] = useState<boolean>(false)
  const [isLoadedChatroom, setIsLoadedChatroom] = useState<boolean>(false)
  const [chatroomID, setChatroomID] = useState<string>("")

  useEffect(() => {
    if (selectedKOLID) {
      getKOLDetails()
    }
  }, [])

  const getKOLDetails = async () => {
    setIsLoadedProfile(false)
    setIsLoadedChatroom(false)
    const result = await getKOL(selectedKOLID)

    if (result.success) {
      setKOLProfile(result.data)
      setIsLoadedProfile(true)
      if (!projectKOLTab) {
        getChatroomDetails(result.data.id)
      }
    }
  }

  const getChatroomDetails = (kolID: string) => {
    isChatroomExists(auth.uid, kolID).then((res) => {
      if (res.success) {
        if (res.exists) {
          setChatroomID(res.chatroomID)
          setIsLoadedChatroom(true)
        } else {
          setIsLoadedChatroom(true)
        }
      } else {
        console.log("Error in fetching data")
      }
    })
  }

  return (
    <div>
      <div className="sticky h-16 top-0 px-1 w-full bg-white z-10 border-b">
        <button
          onClick={() => {
            setProjectPage(PageType.KOL)
          }}
          className="font-medium text-xl my-2 btn btn-ghost normal-case">
          <span className="cursor-pointer mr-2 ">
            <FontAwesomeIcon icon={faArrowLeft} />
          </span>
          {selectedKOLName}
        </button>
      </div>
      <section className="my-2 p-2">
        <p className="text-gray-500 text-sm font-medium px-2">
          <Trans>ProjectOptionBar.link-desc</Trans>
        </p>
        <div className=" text-center mt-12">
          <div className="my-4">
            <button
              onClick={() => {
                if (isLoadedProfile) {
                  setProjectPage(PageType.KOLPreview)
                }
              }}
              className={classNames(
                " hover:bg-gray-100 text-xl  text-gray-700 bg-white border-b w-full p-3 rounded-lg",
                {
                  "btn-disabled bg-gray-200 cursor-wait": !isLoadedProfile,
                }
              )}>
              <FontAwesomeIcon className="text-lg mr-2" icon={faUser} />{" "}
              <Trans>ProjectOptionBar.profile</Trans>
            </button>
          </div>
          <div className="my-4">
            <button
              onClick={() => {
                setProjectPage(PageType.Job)
              }}
              className=" hover:bg-gray-100 text-xl  text-gray-700 bg-white border-b w-full rounded-lg p-3">
              <FontAwesomeIcon className="text-lg mr-2" icon={faBriefcase} />{" "}
              <Trans>ProjectOptionBar.job</Trans>
            </button>
          </div>
          {!projectKOLTab && (
            <div className="my-4">
              <button
                onClick={() => {
                  if (isLoadedChatroom && chatroomID) {
                    setProjectPage(PageType.ChatPreview, chatroomID)
                  } else if (isLoadedChatroom && !chatroomID) {
                    setProjectPage(PageType.ChatNotExist)
                  }
                }}
                className={classNames(
                  " hover:bg-gray-100 text-xl  text-gray-700 bg-white border-b w-full rounded-lg p-3",
                  {
                    "btn-disabled bg-gray-200 cursor-wait": !isLoadedChatroom,
                  }
                )}>
                <FontAwesomeIcon className="text-lg mr-2" icon={faComment} />{" "}
                <Trans>ProjectOptionBar.chat</Trans>
              </button>
            </div>
          )}
        </div>
      </section>
    </div>
  )
}

export default ProjectLinks
