import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { Trans } from "react-i18next"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../../Utils/ModalType"
import { useDispatch } from "react-redux"
import { Job } from "../../../Model/Job"

interface PropsType {
  isAdmin: boolean
  job: Job
  skipPayment?: boolean
}

const ConfirmJobFinish: React.FC<PropsType> = ({
  isAdmin,
  job,
  skipPayment,
}) => {
  const dispatch = useDispatch()
  const setSubmitJobFulfillmentModal = (state: any) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.SUBMIT_JOB_FULFILLMENT,
      returnFn: state.returnFn,
      metaData: state.metaData,
    })

  const onAcceptJobFulfillment = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_JOB,
      data: {
        status: "status-3",
        completePayout: true,
        isFinish: true,
      },
    })
  }

  const setApplyJobAppealModal = (state: any) =>
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.APPLY_JOB_APPEAL_MODAL,
      returnFn: state.returnFn,
      metaData: state.metaData,
    })

  return (
    <div className="w-full shadow rounded border-t-8 border-red-300 my-4 p-4 pb-16 bg-white">
      <h2 className="text-2xl font-bold text-red-600 my-4">
        <FontAwesomeIcon icon={faExclamationCircle} className={"mr-2"} />
        <Trans>Contract.confirm-job-finish</Trans>
      </h2>
      <p className="text-md text-red-500 font-medium">
        <Trans>Contract.confirm-job-finish-des</Trans>
      </p>

      <span className="block text-sm text-gray-400 w-full mt-1">
        <Trans>
          {skipPayment
            ? "ContractSkipPayment.deal-payment"
            : "Contract.auto-payment"}
        </Trans>
      </span>
      <div className="my-2">
        <button
          className="float-right btn w-32 bg-red-500 border-0 hover:bg-red-700 text-sm"
          disabled={isAdmin}
          onClick={() =>
            setSubmitJobFulfillmentModal({
              returnFn: onAcceptJobFulfillment,
              metaData: {
                job: job,
              },
            })
          }>
          <Trans>Contract.confirm-job-fulfilled</Trans>
        </button>
        <a
          className=" float-right btn btn-ghost "
          href="mailto:team@look4kol.com"
          // disabled={isAdmin}
          onClick={() => {
            // setApplyJobAppealModal({
            //   returnFn: () => console.log("hi"),
            //   metaData: {
            //     job: job,
            //   },
            // })
          }}>
          <Trans>Contract.appeal</Trans>
        </a>
      </div>
    </div>
  )
}
export default ConfirmJobFinish
