import { PureComponent } from "react"
import { withRouter } from "react-router-dom"

class ScrollToTop extends PureComponent {
  componentDidUpdate(prevProps) {
    const { location } = this.props

    if (location.pathname !== prevProps.location.pathname) {
      // console.log('scroll to top')
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 350)
    }
  }

  render() {
    const { children } = this.props

    return children
  }
}

export default withRouter(ScrollToTop)
