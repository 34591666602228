import React from "react"
import { CreateNewContractModalData } from "../../Model/Modal"
import { Trans } from "react-i18next"
import { useSelector } from "react-redux"

const CreateNewContractModalComponent = (props: CreateNewContractModalData) => {
  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const isKOLAndAgnecy =
    auth.uid === props.metaData.kolProfile.id ||
    props.metaData.agencyID === props.metaData.kolProfile.id
  return (
    <div className="container mx-auto overflow-y-auto">
      {isKOLAndAgnecy ? (
        <Trans>ContractCreateModal.kol-des</Trans>
      ) : (
        <Trans>ContractCreateModal.des</Trans>
      )}
    </div>
  )
}

export default CreateNewContractModalComponent
