import React, { useState } from "react"
import { returnIdTokenResult } from "../../Utils/FirebaseUtils"
import { getAPIPath } from "../../Utils/HelpingFunction"
import { getUserLang } from "../../Utils/utiltyHelper"
import { useHistory, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { Job } from "../../Model/Job"
import classNames from "classnames"
import CustomAlert from "../NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../Enum/ALERT_SYSYEM"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import dealImg from "../../Assets/skip_payment_modal.jpg"
import { Trans } from "react-i18next"
import { t } from "i18next"

const AcceptDraftContractModalComponent = (props: any) => {
  const history = useHistory()
  const params = useParams<{
    lang: string
    id: string
  }>()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })
  const job: Job = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomJob
  })

  const [isLoading, setIsLoading] = useState(false)

  const connectContract = (uid: string) => {
    setIsLoading(true)
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/job/" + job.id + "/connect"), {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          idToken: res.token,
        },
        body: JSON.stringify({
          customerID: uid,
        }),
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          if (finalResponse.success) {
            setIsLoading(false)
            console.log("connected")
            props.closeModal()
            history.push(
              "/" +
                getUserLang() +
                "/c?chatroomID=" +
                finalResponse.cid +
                "&type=contract&metaPath=" +
                job.id
            )
          } else {
            if (finalResponse.linkInvalid) {
              localStorage.removeItem("draft-contract-id")
            }
            console.log("couldnt connect")
          }
        })
    })
  }

  return (
    <div className="relative h-full">
      <section className="md:p-8 p-2">
        <CustomAlert
          title={t("ContractPreviewPage.step-cannot-be-undone")}
          alertType={ALERT_TYPE.WARNING}
          icon={faExclamationTriangle}
          buttonType={{
            type: BUTTON_TYPE.NONE,
          }}
        />
        <img className="w-[300px] object-cover mx-auto" src={dealImg} />
        <p className="text-gray-600 font-medium text-sm">
          <Trans>ContractPreviewPage.accept-modal</Trans>
        </p>
      </section>
      <button
        onClick={() => connectContract(auth.uid)}
        className={classNames("btn absolute md:bottom-0 bottom-10 w-full", {
          loading: isLoading,
          "btn-disabled": job.CustomerID !== "",
          "btn-success": job.CustomerID === "",
        })}>
        <Trans>ContractPreviewPage.accept-contract</Trans>
      </button>
    </div>
  )
}

export default AcceptDraftContractModalComponent
