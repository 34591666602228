import { KOLCombine, KOLCombinePercentageType } from "../Model/KOLCombine"
import { isCentralizedGenre } from "./TypeList"

export const returnKOLRecomText = (
  percentages: KOLCombinePercentageType[]
): string => {
  if (Array.isArray(percentages)) {
    // if an array
    //type 1: centralized (75%) interest group
    if (
      percentages.length > 0 &&
      typeof percentages.find((e) => e.percentages > 0.75) !== "undefined"
    ) {
      return "KOLItemRecommendation.interest-group-very-centralized"
    } else if (
      //type 2: centralized (40%) interest group
      percentages.length > 0 &&
      typeof percentages.find((e) => e.percentages > 0.4) !== "undefined"
    ) {
      return "KOLItemRecommendation.interest-group-centralized"
    } else if (
      //type 3: centralized interest group
      isCentralizedGenre(percentages)
    ) {
      return "KOLItemRecommendation.genre-centralized"
    }
  }

  return ""
}

export const topCategoryUpdate = (params: any, searchResult: KOLCombine[]) => {
  const topCateID = params.topCateID
  const topCateMin = params.topCateMin ? params.topCateMin : 0
  const topCateMax = params.topCateMax ? params.topCateMax : 100

  let finalFilteredData: KOLCombine[] = []
  searchResult.forEach((searchData) => {
    const topCatePercentage = searchData.topCatePercentage
      ? searchData.topCatePercentage * 100
      : 0
    const cateID = searchData.topCateID
    if (topCateID) {
      if (
        topCateID === cateID &&
        topCateMin <= topCatePercentage &&
        topCateMax >= topCatePercentage
      ) {
        finalFilteredData.push(searchData)
      }
    } else {
      finalFilteredData.push(searchData)
    }
  })
  return finalFilteredData
}
