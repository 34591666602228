import React, { useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DatePicker } from "react-responsive-datepicker"
import "react-responsive-datepicker/dist/index.css"
import classNames from "classnames"

import {
  ButtonFunction,
  CustomFunction,
  DateFunction,
  DropDownFunction,
  InputFunction,
  MultiSelectFunction,
  SelectFunction,
} from "../Model/Table"
import { BUTTON_TYPE, TABLE_FUNCTIONS, WIDTH_SIZE } from "../Enum/TABLE_SYSTEM"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

export const btnIconTypeStyle = (data: BUTTON_TYPE) => {
  switch (data) {
    case BUTTON_TYPE.FAIL:
      return ""
    case BUTTON_TYPE.WARNING:
      return "text-yellow-100"
    case BUTTON_TYPE.SUCCESS:
      return "text-white"
    case BUTTON_TYPE.INFO:
      return "text-gray-100"
    default:
      break
  }
}

export const btnTypeStyle = (data: BUTTON_TYPE) => {
  switch (data) {
    case BUTTON_TYPE.FAIL:
      return "btn-outline btn-error"
    case BUTTON_TYPE.WARNING:
      return "btn-warning"
    case BUTTON_TYPE.SUCCESS:
      return "btn-success"
    case BUTTON_TYPE.INFO:
      return ""
    case BUTTON_TYPE.GHOST:
      return "btn-ghost text-gray-500 border-1 border-gray-200"
    default:
      break
  }
}

export const widthStyle = (width: WIDTH_SIZE) => {
  switch (width) {
    case WIDTH_SIZE.SM:
      return "w-12"
      break
    case WIDTH_SIZE.MD:
      return "w-24"
      break
    case WIDTH_SIZE.LG:
      return "w-36"
      break
    case WIDTH_SIZE.XL:
      return "w-48"
      break
    case WIDTH_SIZE.XXL:
      return "w-64"
      break
    case WIDTH_SIZE.XXXL:
      return "w-96"
      break
    case WIDTH_SIZE.FULL:
      return "w-full"
    default:
      break
  }
}

const btnIconExists = (
  data: ButtonFunction | DateFunction | DropDownFunction | MultiSelectFunction,
  fakeSuccess?: boolean
) => {
  if (data.icon && !fakeSuccess) {
    return (
      <FontAwesomeIcon
        className={classNames(
          "hidden md:inline text-lg",
          btnIconTypeStyle(data.buttonType)
        )}
        icon={data.icon}
      />
    )
  }
  return <div />
}

interface tableFunctions {
  title: string
  data:
    | ButtonFunction
    | SelectFunction
    | DateFunction
    | InputFunction
    | MultiSelectFunction
    | DropDownFunction
    | CustomFunction
}

const tableToolBars: React.FC<tableFunctions> = ({ title, data }) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [fakeSuccess, setFakeSuccess] = useState<boolean>(false)

  switch (data.type) {
    case TABLE_FUNCTIONS.BUTTON:
      return (
        <button
          disabled={fakeSuccess || data.disableButton}
          className={classNames(
            " btn btn-md py-1 px-3 ",
            btnTypeStyle(data.buttonType),
            data.width ? widthStyle(data.width) : "",
            { loading: fakeSuccess, "gap-2": data.text }
          )}
          onClick={() => {
            if (data.fakeSuccess) {
              setFakeSuccess(true)
              setTimeout(() => {
                setFakeSuccess(false)
              }, 1500)
            }
            if (data.onClick) {
              data.onClick()
            }
          }}>
          {btnIconExists(data, fakeSuccess)}
          <span className="inline">
            <Trans>{data.text}</Trans>
          </span>
        </button>
      )
    case TABLE_FUNCTIONS.SELECT:
      return (
        <select
          className={classNames(
            "block select border-1 border-gray-200 hover:border-gray-400 select-bordered max-w-xs",
            data.width ? widthStyle(data.width) : "w-auto"
          )}
          onChange={data.onChange}>
          <option disabled selected>
            {t(title)}
          </option>
          {data.selectData.map((o) => {
            return (
              <option key={o.value} selected={data.labeledValue === o.value}>
                {t(o.label)}
              </option>
            )
          })}
        </select>
      )
    case TABLE_FUNCTIONS.MULTISELECT:
      return (
        <>
          <div className="my-auto shrink-0 dropdown dropdown-bottom">
            <label
              tabIndex={0}
              className={classNames(
                " btn btn-md gap-2 py-1",
                btnTypeStyle(data.buttonType),
                data.width ? widthStyle(data.width) : "",
                {
                  "btn-disabled": data.disableButton,
                }
              )}>
              {btnIconExists(data, fakeSuccess)}
              <span className="hidden lg:inline">
                <Trans>{title}</Trans>
              </span>
            </label>
            <div className="fixed z-50">
              <ul
                tabIndex={0}
                className="dropdown-content menu mt-1 p-2 shadow-lg bg-base-100 border-gray-200	border-2  rounded-box w-52 h-48 md:w-64 md:h-72 overflow-y-scroll">
                {data.selectData.map((group, index) => {
                  return (
                    <>
                      <div className="bg-gray-50 rounded-2xl">
                        {data.groupFormat && data.groupFormat(group)}
                      </div>
                      {group.options.map((opt) => {
                        if (
                          !data.labeledValue.find((e) => e.value === opt.value)
                        ) {
                          return (
                            <li id={"dropdown-" + index}>
                              <a
                                className="font-medium"
                                onClick={() => {
                                  data.onClick(opt)
                                }}>
                                <Trans>{opt.label}</Trans>
                              </a>
                            </li>
                          )
                        }
                      })}
                    </>
                  )
                })}
              </ul>
            </div>
          </div>
          {data.labeledValue.length > 0 && (
            <div className="mx-3 w-full h-12 flex overflow-x-auto border rounded-lg">
              {data.labeledValue.map((labeledItem) => {
                return (
                  <label
                    className={classNames(
                      "flex justify-between rounded-lg shadow-md mx-2 my-auto py-1 bg-gray-50 gap-2 shrink-0 w-52"
                    )}>
                    <div className="pl-3 truncate">
                      <Trans>{labeledItem.label}</Trans>
                    </div>
                    <label
                      className="text-xs mx-2 btn btn-xs btn-outline btn-circle"
                      onClick={() => {
                        data.onClick(labeledItem)
                      }}>
                      ✕
                    </label>
                  </label>
                )
              })}
            </div>
          )}
        </>
      )
    case TABLE_FUNCTIONS.DATE:
      return (
        <div>
          <button
            className={classNames(
              "btn btn-md gap-2",
              btnTypeStyle(data.buttonType),
              data.width ? widthStyle(data.width) : "w-24"
            )}
            onClick={() => {
              setIsOpen(true)
            }}>
            {btnIconExists(data)}
            <Trans>{data.text}</Trans>
          </button>
          <DatePicker
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            onChange={(e) => {
              data.onClick(e)
            }}
            defaultValue={new Date()}
            maxDate={new Date()}
            headerFormat="DD, MM dd"
          />
        </div>
      )
    case TABLE_FUNCTIONS.INPUT:
      return (
        <div
          className="grid md:border md:border-gray-300 rounded-lg p-3"
          style={{ gridTemplateColumns: "25px auto" }}>
          <div className="border-r">
            <FontAwesomeIcon
              className="mx-auto text-gray-400"
              icon={faSearch}
            />
          </div>
          <input
            type="text"
            placeholder={data.placeHolder}
            className={classNames(
              "max-w-xs px-2",
              data.width ? widthStyle(data.width) : ""
            )}
            onChange={data.onChange}
            value={data.textValue}
          />
        </div>
      )
    case TABLE_FUNCTIONS.CUSTOM:
      return data.component
    default:
      return <div />
  }
}
export default tableToolBars
