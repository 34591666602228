import React from "react"
import { ContractDisplayModalData } from "../../Model/Modal"
import Contract from "../../page-components/c/contract/Contract"

const ContractDisplayModalComponent = (props: ContractDisplayModalData) => {
  return (
    <div
      className="container mx-auto overflow-hidden  "
      style={{
        minHeight: "150px",
        minWidth: "50vw",
        marginBottom: "40px",
        maxHeight: "67vh",
      }}>
      <Contract />
    </div>
  )
}

export default ContractDisplayModalComponent
