import React from "react"
import { Link, useLocation } from "react-router-dom"
import { Carousel } from "react-responsive-carousel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobeAsia, faStar, faUser } from "@fortawesome/free-solid-svg-icons"
import { getCountry } from "../Utils/Countries"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LoadingPlaceLoader from "../Assets/LoadingPlaceLoader.png"

import { getUserLang, returnThumbnailUrl } from "../Utils/utiltyHelper"
import { SUPPORTED_REDUX_FUNCTIONS } from "../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../Utils/ModalType"
import { useDispatch } from "react-redux"
import classNames from "classnames"
import KOL from "../Model/KOL"
import { KOLCombine } from "../Model/KOLCombine"
import { IG_USER_DATA } from "../Enum/LOGIN_PAGE_TYPE"
import { faHeart } from "@fortawesome/free-regular-svg-icons"
import { followersCount } from "../Utils/Converter"
import { Trans } from "react-i18next"

type PropsType = {
  key: string
  data: KOL
  isContentLoader?: boolean
  isChatGPTRecm?: {
    igData: IG_USER_DATA
    KOLCombine: KOLCombine
  }
}
const KOLSmallCard: React.FC<PropsType> = ({
  key,
  data,
  isContentLoader,
  isChatGPTRecm,
}) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const isRecommendationPage = location.pathname.includes("/recommendation")

  const setViewImageModal = (state: { returnFn: any; metaData: any }) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.VIEW_IMAGE_MODAL,
      returnFn: state.returnFn,
      metaData: state.metaData,
    })
  }

  const coverWithLinkFn = Array.isArray(data.coverImages) ? (
    data.coverImages
      .filter((img: string) => img !== "")
      .map((e, index) => {
        if (e !== "") {
          return (
            <Link
              key={"lk" + data.id + index}
              to={{
                pathname: "/" + getUserLang() + "/p/" + data.userName,
                state: { kol: data },
              }}>
              <div className="w-full rounded-xl border border-gray-200">
                <img
                  alt="find kol"
                  className={classNames("rounded-xl object-cover lazyload", {
                    "h-48": isChatGPTRecm,
                    "h-72": !isChatGPTRecm,
                  })}
                  // style={{height: "240px"}}
                  src={returnThumbnailUrl(e)}
                />
              </div>
            </Link>
          )
        }
      })
  ) : (
    <></>
  )
  const coverImageFn = Array.isArray(data.coverImages) ? (
    data.coverImages
      .filter((img: string) => img !== "")
      .map((e: string, index: any) => {
        if (e !== "")
          return (
            <div
              className="w-full rounded-xl border border-gray-200 cursor-pointer"
              onClick={() => {
                setViewImageModal({
                  returnFn: null,
                  metaData: {
                    imageUrl: returnThumbnailUrl(e),
                  },
                })
              }}>
              <img
                alt="find kol"
                className="rounded-xl object-cover lazyload h-72"
                // style={{height: "240px"}}
                src={returnThumbnailUrl(e)}
              />
            </div>
          )
      })
  ) : (
    <></>
  )
  const emptyCoverImageComponent = (e: any) => {
    return (
      <div
        onClick={() => {
          setViewImageModal({
            returnFn: null,
            metaData: {
              imageUrl: returnThumbnailUrl(e),
            },
          })
        }}
        className="w-full">
        <img
          alt="find kol"
          className="rounded-3xl object-cover lazyload h-72"
          src={LoadingPlaceLoader}
        />
      </div>
    )
  }
  const emptyCoverWithLinkComponent = () => {
    return (
      <Link
        to={{
          pathname: "/" + getUserLang() + "/p/" + data.userName,
          state: { kol: data },
        }}>
        <div className="w-full">
          <img
            alt="find kol"
            className={classNames("rounded-3xl object-cover lazyload", {
              "h-48": isChatGPTRecm,
              "h-72": !isChatGPTRecm,
            })}
            src={LoadingPlaceLoader}
          />
        </div>
      </Link>
    )
  }
  if (isRecommendationPage) {
    return (
      <div className="inline-block rounded-md  w-full">
        <Carousel
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
          className="w-full rounded-xl mb-4"
          showStatus={false}
          showThumbs={false}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*@ts-ignore*/}
          {Array.isArray(data.coverImages) &&
          data.coverImages.length > 0 &&
          typeof data.coverImages.find((e: string) => e !== "") !== "undefined"
            ? coverImageFn
            : emptyCoverImageComponent(undefined)}
        </Carousel>

        <div>
          <div className="grid" style={{ gridTemplateColumns: "50px auto" }}>
            <div
              className={
                "overflow-hidden h-10 w-10 bg-red-50 rounded-full flex flex-shrink-0 justify-center items-center text-red-900 text-2xl font-mono"
              }>
              {data.avatarUrl !== "" ? (
                <img src={data.avatarUrl} />
              ) : (
                <FontAwesomeIcon icon={faUser} className="text-red-200" />
              )}
            </div>
            <div>
              <p className="font-semibold text-lg text-gray-700 overflow-hidden overflow-ellipsis">
                {data.userName}
              </p>
            </div>
          </div>
          <hr className="w-8 text-gray-500 my-2" />
          <p className="text-gray-500 mt-4 my-6 text-sm h-10 overflow-y-hidden overflow-hidden overflow-ellipsis">
            {data.description}
          </p>

          <div className="flex gap-2 my-2">
            <div className="mx-2 h-10 flex gap-1 text-sm font-light text-gray-500">
              <FontAwesomeIcon
                icon={faGlobeAsia}
                className="mr-2 text-red-default my-auto"
              />{" "}
              {getCountry(data.nationality).label}
            </div>
            <div className="mx-2 h-10 flex gap-1 text-sm font-light text-gray-500">
              <FontAwesomeIcon
                icon={faStar}
                className="mr-2 text-red-default my-auto"
              />
              {typeof data.score === "number"
                ? Math.round(data.score * 10) / 10
                : ""}
              ({data.jobScored})
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div
      className={classNames(" rounded-md", {
        "w-72": isChatGPTRecm,
        "inline-block": !isChatGPTRecm,
      })}>
      <Carousel
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        className="w-full rounded-xl mb-4"
        showStatus={false}
        showThumbs={false}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/*@ts-ignore*/}
        {Array.isArray(data.coverImages) &&
        data.coverImages.length > 0 &&
        typeof data.coverImages.find((e: string) => e !== "") !== "undefined"
          ? coverWithLinkFn
          : emptyCoverWithLinkComponent()}
      </Carousel>

      <div>
        <div className="grid" style={{ gridTemplateColumns: "50px auto" }}>
          <div
            className={
              "overflow-hidden h-10 w-10 bg-red-50 rounded-full flex flex-shrink-0 justify-center items-center text-red-900 text-2xl font-mono"
            }>
            {data.avatarUrl !== "" ? (
              <img src={data.avatarUrl} />
            ) : (
              <FontAwesomeIcon icon={faUser} className="text-red-200" />
            )}
          </div>
          <div>
            <Link
              className="block"
              to={{
                pathname: "/" + getUserLang() + "/p/" + data.userName,
                state: { kol: data },
              }}>
              <p className="font-semibold text-lg text-gray-700 overflow-hidden overflow-ellipsis">
                {data.userName}
                {/*    • {data.type}*/}
              </p>
            </Link>
          </div>
        </div>

        {isChatGPTRecm && (
          <div className="flex gap-1">
            <h3 className="text-sm text-gray-500 font-normal my-auto">
              <Trans>SignUp.followers</Trans>
            </h3>
            <p className={"font-semibold my-auto"}>
              {isChatGPTRecm.igData.numOfFollowers
                ? followersCount(isChatGPTRecm.igData.numOfFollowers)
                : 0}
            </p>
          </div>
        )}
        <hr className="w-full text-gray-500 my-2" />
        {!isChatGPTRecm && (
          <p className="text-gray-500 mt-4 my-6 text-sm h-10 overflow-y-hidden overflow-hidden overflow-ellipsis">
            {data.description}
          </p>
        )}

        <div className="flex gap-2 mx-2 mt-1">
          <div className="mx-2 h-10 flex gap-1 text-sm font-light text-gray-500">
            <FontAwesomeIcon
              icon={faGlobeAsia}
              className="mr-2 text-red-default my-auto"
            />
            <span className="my-auto">
              {" "}
              {getCountry(data.nationality).label}
            </span>
          </div>
          <div className="mx-2 h-10 flex gap-1 text-sm font-light text-gray-500">
            <FontAwesomeIcon
              icon={faStar}
              className="mr-2 text-red-default my-auto"
            />
            <span className="my-auto">
              {typeof data.score === "number"
                ? Math.round(data.score * 10) / 10
                : ""}
              ({data.jobScored})
            </span>
          </div>

          {/*Engagement for kol Recommendation*/}
          {isChatGPTRecm && (
            <div className="mx-2 h-10 flex gap-1 text-sm font-light text-gray-500">
              <FontAwesomeIcon
                icon={faHeart}
                className="mr-2 text-red-default my-auto"
              />
              <span className="my-auto">
                {isChatGPTRecm.KOLCombine && isChatGPTRecm.KOLCombine.basicScore
                  ? isChatGPTRecm.KOLCombine.basicScore + "%"
                  : "--%"}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default KOLSmallCard
