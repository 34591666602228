import { v4 as uuidv4 } from "uuid"
import { SupportingImageType } from "./FileTypeImageConverter"

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export function rebuildFileArr(e) {
  return new Promise((resolve, reject) => {
    let files = e.target.files

    if (files !== null) {
      let newFileList = []
      let allNewFile = []
      Array.from(files).forEach((file) => {
        newFileList.push(
          new Promise(async (resolve, reject) => {
            const fileType =
              file.name.split(".")[file.name.split(".").length - 1]
            if (SupportingImageType.includes(fileType)) {
              const base64 = await toBase64(file)
              allNewFile.push({
                img: base64,
                fileType: fileType,
                file: file,
                id: uuidv4(),
              })
              resolve()
            } else {
              allNewFile.push({
                fileType: fileType,
                file: file,
                id: uuidv4(),
              })
              resolve()
            }
          })
        )
      })

      Promise.all(newFileList).then((res) => {
        resolve({
          success: true,
          data: allNewFile,
        })
      })
    } else {
      resolve({
        success: false,
        data: [],
      })
    }
  })
}
