import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faFilter,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons"
import { useHistory, useLocation } from "react-router-dom"
import { Trans } from "react-i18next"
import { getUserLang } from "../../Utils/utiltyHelper"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"

export const SearchHeader: React.FC = () => {
  let history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()

  const isExpand = useSelector((state: any) => {
    return state.SearchManager.isDropdown
  })

  const [totalParams, setTotalParams] = useState<number>(0)

  const onClickDropdown = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_DROPDOWN,
    })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const mergedObject = Object.fromEntries(searchParams.entries())

    const filteredKeys = Object.keys(mergedObject).filter(
      (key) => key !== "stype" && key !== "pageNum"
    )

    let totalParams = filteredKeys.length

    if (
      mergedObject.stype === "text" &&
      mergedObject.hasOwnProperty("searchStr")
    ) {
      totalParams = 1
    }

    if (
      mergedObject.stype === "filter" &&
      mergedObject.hasOwnProperty("searchStr")
    ) {
      totalParams--
    }

    setTotalParams(totalParams)
  }, [location.search])

  return (
    <div className="rounded-b-md">
      <div
        className="h-18 my-auto w-full py-2 px-4 grid gap-4"
        style={{
          height: "60px",
          gridTemplateColumns: "40px auto 40px ",
        }}>
        <div>
          <button
            className="my-auto h-full text-left my-2 mx-2 text-gray-600 font-medium text-xl"
            onClick={() => {
              history.push("/" + getUserLang())
            }}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        </div>
        <div className="text-lg my-auto font-bold ">
          <Trans>SearchPage.title</Trans>
        </div>
        <div>
          <div className=" indicator">
            <div
              className={classNames("indicator-item badge  !top-2 !right-2", {
                hidden: totalParams <= 0,
                "badge-primary": !isExpand,
                "badge-ghost": isExpand,
              })}>
              {totalParams}
            </div>
            <button
              className={classNames("btn btn-circle", {
                "text-red-default": totalParams > 0 && !isExpand,
                "btn-primary": isExpand,
                "btn-ghost": !isExpand,
              })}
              onClick={() => onClickDropdown()}>
              <FontAwesomeIcon icon={isExpand ? faPaperPlane : faFilter} />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
