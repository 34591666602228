import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { Trans } from "react-i18next"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import {
  LOGIN_CREDENTIALS_TYPE,
  LOGIN_PAGE_TYPE,
} from "../../Enum/LOGIN_PAGE_TYPE"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import { useDispatch } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import { ROLE } from "../../Enum/APP_TYPE"
import CustomAlert from "../../Component/NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../Enum/ALERT_SYSYEM"
import { assignCustomerToContract } from "../../HelpingFunction/Job/JobDBHelper"

const auth = getAuth()

const LoginForm = (props: any) => {
  const dispatch = useDispatch()

  const isInvoicePage = location.pathname.includes("/invoice")
  const [loginCredentials, setLoginCredentials] =
    useState<LOGIN_CREDENTIALS_TYPE>({
      email: "",
      password: "",
    })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [status, setStatus] = useState<string>("")

  const [loggedIn, setIsLoggedIn] = useState<boolean>(false)

  const loginHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setIsLoading(true)

    if (loginCredentials.email === "" || loginCredentials.password === "") {
      setIsLoading(false)
      setStatus("required-all-fields")
    } else {
      signInWithEmailAndPassword(
        auth,
        loginCredentials.email,
        loginCredentials.password
      )
        .then(async (userCredential) => {
          if (auth.currentUser) {
            localStorage.setItem("look4kol-require-login", "false")
            auth.currentUser
              .getIdTokenResult()
              .then((idTokenResult) => {
                dispatch({
                  type: SUPPORTED_REDUX_FUNCTIONS.LOGIN,
                  data: {
                    email: userCredential.user.email,
                    uid: userCredential.user.uid,
                    photoURL: userCredential.user.photoURL,
                    displayName: userCredential.user.displayName,
                    // token: idTokenResult.token,
                    role: idTokenResult.claims.role as ROLE,
                    expirationTime: idTokenResult.expirationTime,
                    custID: idTokenResult.claims.custID
                      ? idTokenResult.claims.custID
                      : null,
                  },
                })
              })
              .catch((err) => console.log(err))
          }

          // For Assigning customer to job
          if (props.jobID) {
            await assignCustomerToContract(
              userCredential.user.uid,
              props.jobID
            ).then((res) => {
              if (res.success) {
                console.log("Successfully connected")
              }
            })
          }

          // Signed in
          setIsLoading(false)
          setIsLoggedIn(true)

          //For Login Modal
          if (props.closeModal) {
            props.closeModal()
            window.location.reload()
          }
          // ...
        })
        .catch((error) => {
          if (error.code === "auth/user-not-found") {
            setIsLoading(false)
            setStatus(error.code)
          }
          if (error.code === "auth/wrong-password") {
            setIsLoading(false)
            setStatus(error.code)
          }
          if (error.code === "auth/too-many-requests") {
            setIsLoading(false)
            setStatus(error.code)
          }
          if (error.code === "auth/invalid-email") {
            setIsLoading(false)
            setStatus(error.code)
          }
          let errorCode = error.code
          let errorMessage = error.message
        })
    }
  }

  const requiredLogin =
    localStorage.getItem("look4kol-require-login") === "true"

  if (!loggedIn) {
    return (
      <form className="py-8 text-base leading-6 space-y-4 text-gray-600 text-md sm:text-lg sm:leading-7">
        {requiredLogin && (
          //RequireLoginAlert
          <CustomAlert
            title={"RequireLoginAlert.title"}
            alertType={ALERT_TYPE.INFO}
            buttonType={{
              type: BUTTON_TYPE.NONE,
            }}
            description={"RequireLoginAlert.des"}
            icon={faCheck}
          />
        )}

        <div className="flex flex-col">
          <label className="leading-loose">
            <Trans>RegForm.email</Trans>
          </label>
          <input
            id={"LoginForm_Email"}
            type="text"
            className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            // placeholder={t("RegForm.email")}
            disabled={isLoading}
            value={loginCredentials.email}
            onChange={(e) =>
              setLoginCredentials({
                ...loginCredentials,
                email: e.target.value,
              })
            }
          />
        </div>
        <div className="flex flex-col">
          <label className="leading-loose ">
            <Trans>RegForm.password</Trans>
          </label>
          <input
            id={"LoginForm_Password"}
            type="password"
            className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
            // placeholder={t("RegForm.password")}
            disabled={isLoading}
            value={loginCredentials.password}
            onChange={(e) =>
              setLoginCredentials({
                ...loginCredentials,
                password: e.target.value,
              })
            }
          />
        </div>

        <button
          id={"LoginForm_LoginButton"}
          className={[
            "btn btn-primary rounded w-full py-2 px-4 text-center ",
            isLoading ? " loading" : "",
          ].join("")}
          disabled={isLoading}
          onClick={(e) => loginHandler(e)}>
          {isLoading ? (
            <div>
              <Trans>RegForm.login-loading</Trans>
            </div>
          ) : (
            <div>
              <Trans>RegForm.login</Trans>
            </div>
          )}
        </button>
        {!window.location.toString().includes("link") ||
          (!window.location.toString().includes("job-preview") && (
            <div>
              <button
                id={"LoginForm_ForgotPassword"}
                className="text-gray-400 px-2 text-xs text-right float-right my-2"
                onClick={() => props.setType(LOGIN_PAGE_TYPE.RESET_PASSWORD)}>
                <Trans>RegForm.forget-password</Trans>
              </button>
            </div>
          ))}

        {status !== "" && (
          <span className="w-full text-sm text-red-400 text-center">
            {status === "required-all-fields" && (
              <Trans>ErrorList.fill-in-the-field</Trans>
            )}
            {status === "auth/wrong-password" && (
              <Trans>RegForm.error-wrong-password</Trans>
            )}

            {status === "auth/user-not-found" && (
              <Trans>RegForm.error-user-not-found</Trans>
            )}
          </span>
        )}
      </form>
    )
  }

  return (
    <div className=" py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 w-full">
        <div>
          <div className="w-full mx-auto">
            <div className="block pl-2 font-semibold text-xl self-start text-gray-700">
              <h2 className="leading-relaxed text-center">
                <Trans>RegForm.login-intro</Trans>
              </h2>
              <p className="text-sm text-gray-500 font-normal leading-relaxed text-center">
                <Trans>RegForm.login-intro-des</Trans>
              </p>
              <div className="w-full pl-2 md:pl-0 my-6">
                <button
                  id={"LoginForm_LoginButton"}
                  className="btn btn-primary rounded w-full py-2 px-4 text-center loading"
                  disabled={isLoading}>
                  <div>
                    <Trans>RegForm.login-loading</Trans>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(LoginForm)
