import { NEW_CUSTOMER } from "../../Enum/LOGIN_PAGE_TYPE"
import { getAPIPath } from "../../Utils/HelpingFunction"
import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore"
import firebaseApp from "../../Config/firebase"
import { CustomerType, CustomerUpdateType } from "../../Model/Customer"
import { returnIdTokenResult } from "../../Utils/FirebaseUtils"
import { KOLType } from "../../Model/KOL"
import { getKOLs } from "../KOL/kolDBHelper"

const db = getFirestore(firebaseApp)

/**
 * Set Customer for registration
 * @param {string} uid - firebase uid
 * @param {NEW_CUSTOMER} data - data will be store in Customer/{:uid}
 */

const setCustomer = (
  uid: string,
  data: CustomerType
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await setDoc(doc(db, "Customer", uid), data)
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => console.log(err))
  })
}

const getCustomer = (
  customerID: string
): Promise<
  | {
      success: true
      data: CustomerType
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "Customer", customerID))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            data: {
              id: doc.id,
              ...doc.data(),
            } as CustomerType,
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => {
        console.log(err)
        return resolve({
          success: false,
        })
      })
  })
}

const getCustomers = (
  customerIDs: string[]
): Promise<{ success: true; data: CustomerType[] } | { success: false }> => {
  return new Promise(async (resolve) => {
    if (Array.isArray(customerIDs) && customerIDs.length) {
      let customerList: any[] = []
      await getDocs(
        query(
          collection(db, "Customer"),
          where(documentId(), "in", customerIDs)
        )
      )
        .then((docs) => {
          if (!docs.empty) {
            docs.forEach((doc) => {
              customerList.push({
                ...doc.data(),
                id: doc.id,
              })
            })
            return resolve({
              success: true,
              data: customerList,
            })
          }
          return resolve({
            success: true,
            data: [],
          })
        })
        .catch((e) => {
          console.log(e)
          return resolve({
            success: false,
          })
        })
    } else {
      return resolve({
        success: false,
      })
    }
  })
}

/**
 * Create a new Customer account using email and password
 * @param {NEW_CUSTOMER} newCustomer - data for new customer user
 * @param {string} password - user password
 */

const createCustomer = (
  newCustomer: NEW_CUSTOMER,
  password: string
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await fetch(getAPIPath("/api/customer"), {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...newCustomer,
        password: password,
      }),
    })
      .then((res) => res.json())
      .then((finalResponse) => {
        return resolve(finalResponse)
      })
  })
}

/**
 * Create a new Customer account using email and password
 * @param {string} customerID - customerID
 */

const getCustomerCompanyName = (
  customerID: string
): Promise<
  | {
      success: true
      customerCompanyName: string
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    if (customerID) {
      await fetch(getAPIPath("/api/customer/" + customerID + "/company"), {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          return resolve(finalResponse)
        })
        .catch((err) => console.log(err))
    } else {
      return resolve({
        success: true,
        customerCompanyName: "KOL Draft",
      })
    }
  })
}

/**
 * updateCustomer
 * @param {string} customerID - customerID
 * @param {CustomerUpdateType} data
 */
const updateCustomer = (
  customerID: string,
  data: CustomerUpdateType
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await updateDoc(doc(db, "Customer", customerID), {
      ...data,
    })
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => console.log(err))
  })
}

/**
 * updateCompareGroup
 * @param {string} customerID - customerID
 * @param {object} compareGroup
 */
const updateCompareGroup = (
  customerID: string,
  compareGroup: [
    {
      data: string[]
      id: string
      title: string
      type: string
    }
  ]
): Promise<{
  success: boolean
}> => {
  return new Promise(async (resolve) => {
    await updateDoc(doc(db, "Customer", customerID), {
      kolCompareGroup: compareGroup,
    })
      .then((res) => {
        return resolve({
          success: true,
        })
      })
      .catch((err) => console.log(err))
  })
}

/**
 * getKOLCompareGroup
 * @param {string} customerID - customerID
 */
const getKOLCompareGroup = (
  customerID: string
): Promise<
  | {
      success: true
      data: [
        {
          data: string[]
          id: string
          title: string
          type: string
        }
      ]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "Customer", customerID))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data: doc.data().kolCompareGroup ? doc.data().kolCompareGroup : [],
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => console.log(err))
  })
}

const verifyCustomer = (
  id: string,
  phone: string
): Promise<{ success: boolean }> => {
  return new Promise((resolve) => {
    returnIdTokenResult().then(async (res) => {
      await fetch(getAPIPath("/api/customer/" + id + "/verify"), {
        method: "PATCH",
        body: JSON.stringify({
          phone: phone,
        }),
        headers: { "Content-Type": "application/json", idToken: res.token },
      })
        .then((res) => res.json())
        .then((finalResponse) => {
          return resolve(finalResponse)
        })
        .catch((err) => {
          console.log(err)
        })
    })
  })
}

/**
 * get Customer OwnedKOL
 * @param {string} customerID - customerID
 */
const getOwnedKOL = (
  customerID: string
): Promise<
  | {
      success: true
      data: string[]
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    await getDoc(doc(db, "Customer", customerID))
      .then((doc) => {
        if (doc.exists()) {
          return resolve({
            success: true,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data: doc.data().ownedKOL ? doc.data().ownedKOL : [],
          })
        }
        return resolve({
          success: false,
        })
      })
      .catch((err) => console.log(err))
  })
}

/**
 * verify if viewAs ID belongs to Customer's owned KOL
 * @param {string} authID - auth.uid (customer uid)
 * @param {string} kolID - owned KOL ID
 */
const isKOLOwned = (
  authID: string,
  kolID: string
): Promise<
  | {
      success: true
      data: KOLType
    }
  | {
      success: false
    }
> => {
  return new Promise(async (resolve) => {
    getOwnedKOL(authID)
      .then((result) => {
        if (result.success) {
          getKOLs(result.data).then((resultKOL) => {
            if (resultKOL.success) {
              const ownedKOL = resultKOL.data.find((e) => e.id === kolID)
              if (ownedKOL) {
                return resolve({
                  success: true,
                  data: ownedKOL,
                })
              }
              return resolve({
                success: false,
              })
            }
          })
        } else {
          return resolve({
            success: false,
          })
        }
      })
      .catch((err) => console.log(err))
  })
}

export {
  setCustomer,
  createCustomer,
  getCustomerCompanyName,
  updateCustomer,
  updateCompareGroup,
  getKOLCompareGroup,
  getCustomer,
  verifyCustomer,
  getOwnedKOL,
  isKOLOwned,
  getCustomers,
}
