import React, { useEffect } from "react"
import { Schedule } from "../../../Model/Schedule"
import TimeScheduleItem from "./TimeScheduleItem"
import { useDispatch, useSelector } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { Trans } from "react-i18next"

interface PropsType {
  isEditMode: boolean
}

const TimeScheduleController: React.FC<PropsType> = (props) => {
  const dispatch = useDispatch()

  const schedules: Schedule[] = useSelector((state: any) => {
    return state.ContractManager.schedules
  })

  const isEditMode: boolean = useSelector((state: any) => {
    return state.ContractManager.isEditMode
  })

  useEffect(() => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_IS_SCHEDULE_EDIT,
      data: props.isEditMode,
    })
  }, [props.isEditMode])

  const addSchedule = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.ADD_NEW_SCHEDULE,
    })
  }

  return (
    <>
      <div className="rounded-2xl my-4  overflow-hidden md:p-2">
        {schedules.map((e: Schedule, index: number) => {
          return <TimeScheduleItem data={e} index={index} key={e.id} />
        })}
      </div>
      {isEditMode && (
        <button
          className="my-2 btn btn-outline btn-primary btn-wide max-w-2xl w-full mx-auto"
          onClick={(e) => addSchedule()}>
          <Trans>TimeScheduleController.add-schedule</Trans>
        </button>
      )}
    </>
  )
}
export default TimeScheduleController
