import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faComment as faCommentAfter,
  faCopy,
  faFileContract,
  faFolder,
  faReply,
  faTrash,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons"
import { faComment } from "@fortawesome/free-regular-svg-icons"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { useHistory } from "react-router-dom"

import { useTranslation } from "react-i18next"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import {
  ASSET_TYPE,
  CHATROOM_PAGE_TYPE,
  CHATROOM_TYPE,
} from "../../Enum/APP_TYPE"
import { isAuth } from "../../Utils/FirebaseUtils"
import {
  getChatroomAvatar,
  getChatroomTitle,
} from "../../HelpingFunction/Chatroom/chatroomDBHelper"
import { SUPPORTED_MODAL_TYPE } from "../../Utils/ModalType"
import { TabLinkButton } from "../TabLinkButton/TabLinkButton"
import { getUserLang } from "../../Utils/utiltyHelper"
import classNames from "classnames"
import { HelpDocHeader } from "./HelpDocHeader/HelpDocHeader"
import { CONTRACT_HEADER } from "../../Utils/HelpButtonHelperFunction"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import chatgptIcon from "../../Assets/openai.svg"
import { v4 as uuidv4 } from "uuid"
import { returnChatGPTSetting } from "../../HelpingFunction/Chatroom/chatgptHelper"
import {
  CHATGPT_SETTING_TYPE,
  COMMEND_TYPE,
  defaultChatGPTTextSettingType,
} from "../../Model/ChatMessage"
import { CHATGPT_HELPER_TYPE } from "../../Redux/Reducer/ChatGPTManager"
import { getAuthUID, getQuery } from "../../Utils/QueryHelper"
import { CONTRACT_STEP } from "../../Enum/CONTRACT"
import ContractToolBar from "../../page-components/c/contract/ContractToolBar"

interface tabLink {
  name: CHATROOM_PAGE_TYPE
  isAfter: boolean
  icon: IconDefinition
  iconAfter: IconDefinition
  i18Text: string
  path: string
  hidden?: boolean
  onClickFn?: any
}

export const ChatroomHeader: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  let history = useHistory()

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const paramList = useSelector((state: any) => {
    return state.QueryManager.params
  })

  const chatroomInfo = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomInfo
  })

  const chatroomInfoLocal = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomInfoLocal
  })

  const isContainDraftMsg = useSelector((state: any) => {
    return state.FileExplorer.isContainDraftMsg
  })

  const hasSelectedMessage = useSelector((state: any) => {
    return state.ChatRoomInfo.hasSelectedMessage
  })

  const selectedMessage = useSelector((state: any) => {
    return state.ChatRoomInfo.selectedMessage
  })

  const pageType = useSelector((state: any) => {
    return state.ChatRoomInfo.pageType
  })

  const setChatRoomLocal = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_INFO_LOCAL,
      chatroomInfoLocal: state,
    })
  }

  const onChangeChatGPTTabType = (type: CHATGPT_HELPER_TYPE) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATGPT_TAB,
      data: type,
    })
  }

  const updateGPTCommend = (data: COMMEND_TYPE | "") => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.UPDATE_COMMEND_TYPE,
      data: data,
    })
  }

  const updateChatGPTSetting = (data: CHATGPT_SETTING_TYPE) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.UPDATE_CHATGPT_SETTING,
      data: data,
    })
  }

  let params = {
    type: getQuery(paramList, "type"),
    chatroomID: getQuery(paramList, "chatroomID"),
    metaPath: getQuery(paramList, "metaPath"),
    viewAs: getQuery(paramList, "viewAs"),
  }
  const ChatroomHeaderLink: tabLink[] = [
    {
      name: CHATROOM_PAGE_TYPE.CHAT_TEXT,
      isAfter: false,
      icon: faComment,
      iconAfter: faCommentAfter,
      i18Text: t("ChatroomHeader.chat"),
      path: "c?chatroomID=" + params.chatroomID,
      onClickFn: () => setPageType(CHATROOM_PAGE_TYPE.CHAT_TEXT),
    },
    {
      name: CHATROOM_PAGE_TYPE.CONTRACT,
      isAfter: false,
      icon: faFileContract,
      iconAfter: faFileContract,
      i18Text: t("ChatroomHeader.job"),
      path: "c?chatroomID=" + params.chatroomID + "&type=contract",
      hidden:
        chatroomInfo.type === CHATROOM_TYPE.CHATGPT ||
        chatroomInfo.type === CHATROOM_TYPE.ADMIN,
      onClickFn: () => setPageType(CHATROOM_PAGE_TYPE.CONTRACT),
    },
    {
      name: CHATROOM_PAGE_TYPE.FILE,
      isAfter: false,
      icon: faFolder,
      iconAfter: faFolder,
      i18Text: t("ChatroomHeader.file"),
      path: "c?chatroomID=" + params.chatroomID + "&type=file",
      hidden:
        chatroomInfo.type === CHATROOM_TYPE.CHATGPT ||
        chatroomInfo.type === CHATROOM_TYPE.ADMIN,
      onClickFn: () => setPageType(CHATROOM_PAGE_TYPE.FILE),
    },
  ]
  const [title, setTitle] = useState("")
  const [avatarUrl, setAvatarUrl] = useState("")
  const [isLoadedAvatar, setIsLoadedAvatar] = useState(false)
  const [isLoadedTitle, setIsLoadedTitle] = useState(false)
  const [chatHeaderData, setChatHeaderData] =
    useState<tabLink[]>(ChatroomHeaderLink)

  useEffect(() => {
    if (isAuth(auth)) {
      if (
        (chatroomInfoLocal.participateUID[0] !==
          chatroomInfo.participateUID[0] ||
          chatroomInfoLocal.participateUID[1] !==
            chatroomInfo.participateUID[1] ||
          chatroomInfoLocal.title !== chatroomInfo.title ||
          title === "") &&
        (!isLoadedAvatar || !isLoadedTitle)
      ) {
        setChatRoomLocal(chatroomInfo)
        getAllDetails()
      }
    }
  }, [chatroomInfo])

  const getAllDetails = () => {
    if (isAuth(auth)) {
      setIsLoadedAvatar(false)
      setIsLoadedTitle(false)
      getChatTitle()
      getChatAvatar()
    }
  }

  const getChatTitle = async () => {
    let role = ""
    let pid = ""
    if (getAuthUID(paramList) === chatroomInfo.participateUID[1]) {
      role = "kol"
      pid = chatroomInfo.participateUID[0]
    } else {
      role = "customer"
      pid = chatroomInfo.participateUID[1]
    }

    await getChatroomTitle(pid, role, "").then((res) => {
      setTitle(res.title)
      setIsLoadedTitle(true)
    })
  }
  const getChatAvatar = async () => {
    await getChatroomAvatar(getAuthUID(paramList), chatroomInfo).then((res) => {
      if (res.success) {
        setAvatarUrl(res.avatarURL)
        setIsLoadedAvatar(true)
      }
    })
  }

  const setPageType = (type: CHATROOM_PAGE_TYPE): void => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATROOM_PAGE_TYPE,
      data: type,
    })
  }

  const deleteMessageModal = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.DELETE_MESSAGE_MODAL,
      returnFn: state.returnFn,
      metaData: state.metaData,
    })
  }

  const addDraftMsgToChat = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SHARE_DRAFT_MSG,
      draftMsg: state,
    })
  }

  useEffect(() => {
    if (typeof params.type === "undefined") {
      if (params.chatroomID) {
        setPageType(CHATROOM_PAGE_TYPE.CHAT_TEXT)
      } else {
        setPageType(CHATROOM_PAGE_TYPE.LIST_CHAT)
      }
    } else if (params.type === "contract") {
      setPageType(CHATROOM_PAGE_TYPE.CONTRACT)
    } else if (params.type === "file") {
      setPageType(CHATROOM_PAGE_TYPE.FILE)
    }
  }, [window.location.pathname])

  useEffect(() => {
    let newHeader = [...ChatroomHeaderLink]
    let visibleType: false | string = false
    switch (pageType) {
      case CHATROOM_PAGE_TYPE.CHAT_TEXT:
        visibleType = CHATROOM_PAGE_TYPE.CHAT_TEXT
        break
      case CHATROOM_PAGE_TYPE.CONTRACT:
        visibleType = CHATROOM_PAGE_TYPE.CONTRACT
        break
      case CHATROOM_PAGE_TYPE.FILE:
        visibleType = CHATROOM_PAGE_TYPE.FILE
        break
      default:
        break
    }
    let tmpHeader: tabLink[] = []
    newHeader.forEach((h) => {
      tmpHeader.push({
        ...h,
        path:
          "c?chatroomID=" +
          params.chatroomID +
          (h.name !== CHATROOM_PAGE_TYPE.CHAT_TEXT ? "&type=" + h.name : ""),
        isAfter: visibleType && h.name === visibleType,
      } as tabLink)
    })
    setChatHeaderData(tmpHeader)
  }, [pageType, params.chatroomID])

  const isLoaded =
    isLoadedAvatar &&
    isLoadedTitle &&
    chatroomInfo.type !== CHATROOM_TYPE.ADMIN &&
    chatroomInfo.type !== CHATROOM_TYPE.CHATGPT &&
    params.chatroomID

  const msgIsFromChatGPT =
    chatroomInfo.type === CHATROOM_TYPE.CHATGPT &&
    selectedMessage.createUserID !== auth.uid

  const nonTextChatGPTMsg =
    selectedMessage.type === ASSET_TYPE.CHATGPT_IG ||
    selectedMessage.type === ASSET_TYPE.CHATGPT_IMAGE ||
    selectedMessage.type === ASSET_TYPE.IMAGE

  const isMobileChatPanel = params.type !== "contract" && params.type !== "file"

  if (params.type === "contract" && params.metaPath) {
    return (
      <div className="rounded-b-md shadow-md">
        <ContractToolBar />
      </div>
    )
  }
  return (
    <div
      className={classNames({
        "grid grid-rows-2  ": params.chatroomID,
        "shadow-md rounded-b-md": params.type !== "contract",
      })}>
      {/* KOL Header*/}
      <div
        className="h-18 my-auto w-full py-2 px-4 grid gap-4 items-center"
        style={{
          height: "60px",
          gridTemplateColumns: "40px auto 120px",
        }}>
        <div>
          <button
            className="my-auto h-full text-left my-2 mx-2 text-gray-600 font-medium text-xl"
            onClick={() => {
              setPageType(CHATROOM_PAGE_TYPE.LIST_CHAT)
              history.push(
                "/" +
                  getUserLang() +
                  "/c" +
                  (params.viewAs ? "?viewAs=" + params.viewAs : "")
              )
            }}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        </div>
        {isLoaded ? (
          <div className="flex gap-2 flex-row">
            <img
              className="h-8 w-8 my-auto rounded-full"
              src={avatarUrl ? avatarUrl : "https://look4kol.com/logo.png"}
            />
            <div
              onClick={() => {
                if (auth.uid === chatroomInfo.participateUID[0]) {
                  history.push(
                    "/" + getUserLang() + "/p/" + chatroomInfo.participateUID[1]
                  )
                }
              }}
              className="text-lg my-auto font-medium truncate">
              {title}
            </div>
          </div>
        ) : (
          ""
        )}

        {chatroomInfo.type === CHATROOM_TYPE.CHATGPT && (
          <div className="flex gap-2 flex-row">
            <img className="h-8 w-8 my-auto rounded-full" src={chatgptIcon} />
            <div className="text-lg my-auto font-medium truncate">ChatGPT</div>
          </div>
        )}

        {chatroomInfo.type === CHATROOM_TYPE.ADMIN && (
          <div className="flex gap-2 flex-row">
            <img
              className="h-8 w-8 my-auto rounded-full"
              src="https://look4kol.com/logo.png"
            />
            <div className="text-lg my-auto font-medium truncate">
              Admin
              <FontAwesomeIcon
                className="text-green-600 ml-1 text-base"
                icon={faCircleCheck}
              />
            </div>
          </div>
        )}

        <div className="my-auto">
          {isMobileChatPanel && hasSelectedMessage && !isContainDraftMsg && (
            <div className=" w-32 ">
              <button className="w-8 h-8 py-0.5 mx-1 rounded-full md:bg-gray-50 hover:bg-gray-100">
                <FontAwesomeIcon
                  icon={faCopy}
                  className="text-lg text-gray-500"
                  onClick={() => {
                    //new message don't require id field
                    let newMsg = selectedMessage
                    delete newMsg.id
                    addDraftMsgToChat({
                      ...newMsg,
                    })
                  }}
                />
              </button>
              {selectedMessage.createUserID === auth.uid && (
                <button
                  className="w-8 h-8 py-0.5 mx-1 rounded-full md:bg-gray-50 hover:bg-gray-100"
                  disabled={
                    Math.floor(
                      (new Date().valueOf() / 1000 -
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        new Date(selectedMessage.createDate.seconds)) /
                        60
                    ) > 10
                  }
                  onClick={() => {
                    deleteMessageModal({
                      returnFn: () => {
                        console.log("done")
                      },
                      metaData: {
                        inTenMins:
                          Math.floor(
                            (new Date().valueOf() / 1000 -
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              new Date(selectedMessage.createDate.seconds)) /
                              60
                          ) <= 10,
                        chatroomID: params.chatroomID,
                        messageID: selectedMessage.id,
                      },
                    })
                  }}>
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="text-lg text-gray-500"
                  />
                </button>
              )}

              {msgIsFromChatGPT && (
                <button
                  className="w-8 h-8 py-0.5 mx-1 rounded-full md:bg-gray-50 hover:bg-gray-100"
                  disabled={nonTextChatGPTMsg}
                  onClick={() => {
                    onChangeChatGPTTabType(CHATGPT_HELPER_TYPE.SETTING)
                    updateGPTCommend(COMMEND_TYPE.TEXT)
                    updateChatGPTSetting(defaultChatGPTTextSettingType)
                    addDraftMsgToChat({
                      ...selectedMessage,
                      id: uuidv4(),
                      setting: returnChatGPTSetting(
                        COMMEND_TYPE.TEXT,
                        defaultChatGPTTextSettingType
                      ),
                      type: ASSET_TYPE.CHATGPT_TEXT_REPLY,
                      replyMsg: selectedMessage.msg,
                    })
                  }}>
                  <FontAwesomeIcon
                    icon={faReply}
                    className="text-lg text-gray-500"
                  />
                </button>
              )}
            </div>
          )}

          {/*{isChatGPTChatroom && (*/}
          {/*  <div className="btn btn-ghost rounded-3xl w-12 h-12 float-right ">*/}
          {/*    <FontAwesomeIcon icon={faCog} />*/}
          {/*  </div>*/}
          {/*)}*/}
          {params.type === "contract" && (
            <div className="flex justify-end">
              <HelpDocHeader helpDocs={CONTRACT_HEADER} />
            </div>
          )}
        </div>
      </div>

      {/* KOL Function*/}
      {params.chatroomID && (
        <div className="tabs tab-boxed my-auto gap-2 py-2 px-2 bg-gray-50 rounded-3xl mx-2">
          {Array.isArray(chatHeaderData) &&
            chatHeaderData
              .filter((e) => !e.hidden)
              .map((item, key) => {
                return (
                  <TabLinkButton
                    key={"item" + key}
                    path={item.path}
                    isAfter={item.isAfter}
                    i18Text={item.i18Text}
                    icon={item.icon}
                    iconAfter={item.iconAfter}
                    onClickFn={item.onClickFn}
                  />
                )
              })}
        </div>
      )}
    </div>
  )
}
