import React, { useEffect, useState } from "react"
import { CHATGPT_HELPER_TYPE } from "../../../Redux/Reducer/ChatGPTManager"
import { useDispatch, useSelector } from "react-redux"
import ChatGPTPromptExample from "./ChatGPTPromptExample"
import ChatGPTSetting from "./ChatGPTSetting"
import classNames from "classnames"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { getUserLang } from "../../../Utils/utiltyHelper"
import { Trans } from "react-i18next"

const ChatGPTToolsBox: React.FC = () => {
  const dispatch = useDispatch()

  const tabType: CHATGPT_HELPER_TYPE = useSelector((state: any) => {
    return state.ChatGPTManager.tabType
  })

  const onChangeChatGPTTabType = (type: CHATGPT_HELPER_TYPE) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_CHATGPT_TAB,
      data: type,
    })
  }

  const [containerHeight, setContainerHeight] = useState<number>(500)
  let container = document.getElementById("itemSelector")
  useEffect(() => {
    if (container) {
      setContainerHeight(container.offsetHeight - 60)
    }
  }, [container, container?.offsetHeight])

  return (
    <div
      className="w-full h-full overflow-hidden grid "
      style={{ gridTemplateRows: "50px auto" }}>
      <div className="px-4">
        <div className="tabs">
          <a
            onClick={() =>
              onChangeChatGPTTabType(CHATGPT_HELPER_TYPE.PROMPT_EXAMPLE)
            }
            className={classNames("tab tab-lg tab-lifted", {
              "tab-active": tabType === CHATGPT_HELPER_TYPE.PROMPT_EXAMPLE,
            })}>
            <Trans>ChatGPTToolsBox.example</Trans>
          </a>
          <a
            onClick={() => onChangeChatGPTTabType(CHATGPT_HELPER_TYPE.SETTING)}
            className={classNames("tab tab-lg tab-lifted", {
              "tab-active": tabType === CHATGPT_HELPER_TYPE.SETTING,
            })}>
            <Trans>ChatGPTToolsBox.setting</Trans>
          </a>
          <a
            onClick={() => onChangeChatGPTTabType(CHATGPT_HELPER_TYPE.HELP_DOC)}
            className={classNames("tab tab-lg tab-lifted", {
              "tab-active": tabType === CHATGPT_HELPER_TYPE.HELP_DOC,
            })}>
            <Trans>ChatGPTToolsBox.help-doc</Trans>
          </a>
        </div>
      </div>

      <div className="overflow-y-scroll ">
        {tabType === CHATGPT_HELPER_TYPE.PROMPT_EXAMPLE && (
          <ChatGPTPromptExample />
        )}

        {tabType === CHATGPT_HELPER_TYPE.SETTING && <ChatGPTSetting />}

        {tabType === CHATGPT_HELPER_TYPE.HELP_DOC && (
          <div
            id="helpDocContainer"
            className="fixed sm:static md:h-full w-screen sm:w-auto left-0 overflow-hidden"
            style={{
              height: containerHeight,
            }}>
            <div className=" h-full md:h-full left-0 -top-14 z-0">
              <iframe
                src={`https://docs.look4kol.com/${
                  getUserLang() === "hk" ? "zh" : "eng"
                }/chatgpt-for-creators`}
                className="w-full h-full"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default ChatGPTToolsBox
