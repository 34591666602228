import React, { useEffect, useImperativeHandle, useState } from "react"
import { SignUpCoverImageModalData } from "../../Model/Modal"
import { Trans } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons"
import { useSelector } from "react-redux"
import classNames from "classnames"
import { handleIGMediaData } from "../../HelpingFunction/FBLogin/FBLoginHelper"
import DivContentLoader from "../DivContentLoader"

const SignUpCoverImageModalComponent = (
  props: SignUpCoverImageModalData,
  ref: any
) => {
  const currentFbPageID = useSelector((state: any) => {
    return state.MemberManager.currentFbPageID
  })

  const [mediaList, setMediaList] = useState<string[]>(
    props.metaData.data.imagesList
  )
  const [igPost, setIgPost] = useState<string>("")
  const [isGettingMedia, setIsGettingMedia] = useState<boolean>(false)
  const [nextPage, setNextPage] = useState<string>(
    props.metaData.nextPage ? props.metaData.nextPage : ""
  )

  const getAllMediaData = async () => {
    setIsGettingMedia(true)
    const result = await handleIGMediaData(currentFbPageID, 0, nextPage)
    if (result.success) {
      let newMediaList = mediaList.concat(result.data.imagesList)
      setMediaList(newMediaList)
      setNextPage(result.nextPage ? result.nextPage : "")
      setIsGettingMedia(false)
    } else {
      setIsGettingMedia(false)
    }
  }
  useImperativeHandle(ref, () => ({
    returnData() {
      return igPost
    },
  }))

  return (
    <div className="container mx-auto overflow-y-auto overflow-x-hidden">
      <div className={"flex flex-row place-content-center"}>
        <div className={"text-center"}>
          <p className={"text-lg font-semibold"}>
            {props.metaData.data.numOfPosts}
          </p>
          <h3>
            <Trans>SignUp.posts</Trans>
          </h3>
        </div>
        <div className={"text-center ml-12"}>
          <p className={"text-lg font-semibold"}>
            {props.metaData.data.numOfFollowers}
          </p>
          <h3>
            <Trans>SignUp.followers</Trans>
          </h3>
        </div>
        <div className={"text-center ml-12"}>
          <p className={"text-lg font-semibold"}>
            {props.metaData.data.numOfFollowing}
          </p>
          <h3>
            <Trans>SignUp.following</Trans>
          </h3>
        </div>
      </div>
      <div className={"mt-8 grid grid-cols-3 grid-flow-row gap-2 mb-4 p-4 "}>
        {!isGettingMedia &&
          Array.isArray(mediaList) &&
          mediaList.length > 0 &&
          mediaList.map((post, index) => {
            return (
              <div
                key={index}
                className={"relative cursor-pointer"}
                onClick={() => setIgPost(post)}>
                <img
                  src={post}
                  className={
                    post === igPost
                      ? "opacity-40 border-4 border-green-900 w-full h-full object-cover"
                      : "w-full h-full object-cover"
                  }
                />
                {post === igPost && (
                  <FontAwesomeIcon
                    className={
                      "bg-white rounded-full absolute top-4 right-4 text-green-700 text-3xl animated animatedFadeInUp fadeInUp"
                    }
                    icon={faCircleCheck}
                  />
                )}
              </div>
            )
          })}
        {isGettingMedia && (
          <>
            <DivContentLoader w={"100%"} h={150} r={8} />
            <DivContentLoader w={"100%"} h={150} r={8} />
            <DivContentLoader w={"100%"} h={150} r={8} />
            <DivContentLoader w={"100%"} h={150} r={8} />
            <DivContentLoader w={"100%"} h={150} r={8} />
            <DivContentLoader w={"100%"} h={150} r={8} />
          </>
        )}
      </div>
      {nextPage && (
        <div className="mx-auto text-center  md:pb-12 pb-32  flex-grow">
          <button
            onClick={() => getAllMediaData()}
            className={classNames("w-44 normal-case btn btn-primary", {
              loading: isGettingMedia,
            })}>
            <Trans>CaseStudyIGModal.get-posts</Trans>
          </button>
        </div>
      )}
    </div>
  )
}
export default React.forwardRef(SignUpCoverImageModalComponent)
