import { getAPIPath } from "./HelpingFunction"
import { isAuth } from "./FirebaseUtils"
import { logEvent } from "firebase/analytics"

import { analytics } from "../Config/firebase"
import { getToken, onMessage } from "firebase/messaging"

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

export function register(auth) {
  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
    if (publicUrl.origin !== window.location.origin) {
      return
    }

    window.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, auth)

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log("ready for message")
        })
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, auth)
      }
    })
  }
}

function registerValidSW(swUrl, auth) {
  try {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
        .then(function (registration) {
          console.log("Registration successful, scope is:", registration.scope)
        })
        .catch(function (err) {
          console.log("Service worker registration failed, error:", err)
        })
    }
  } catch (e) {
    console.log(e)
  }

  try {
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        issueNewToken(auth)
      } else if (Notification.permission === "denied") {
        logEvent(analytics, "messaging_permission_rejected")
      } else {
        Notification.requestPermission()
      }

      onMessage((payload) => {
        console.log("Message received. ", payload)
      })
    }
  } catch (e) {
    console.log(e)
  }
}

const issueNewToken = (auth) => {
  getToken({
    vapidKey:
      "BALiUneYw0V4b-U5QvtpU5G2SvcgqlWsJnzZfXEdPDjcqmND01geOkjaoBn7WgZy6THFSjKiWVyZgVgfToBfcLY",
  })
    .then((currentToken) => {
      if (currentToken) {
        if (
          localStorage.getItem("look4kol-gcm-id" + "-" + auth.uid) !==
          currentToken
        ) {
          logEvent(analytics, "messaging_permission_accepted")
          localStorage.setItem("look4kol-gcm-id" + "-" + auth.uid, currentToken)
        }
        if (isAuth(auth)) {
          return new Promise(async (resolve, reject) => {
            await fetch(getAPIPath("/api/gcm/addID"), {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                id: currentToken,
                uid: auth.uid,
              }),
            })
          })
        }
      } else {
        // Show permission request.
        console.log(
          "No registration token available. Request permission to generate one."
        )
        // Show permission UI.
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err)
    })
}

function checkValidServiceWorker(swUrl) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { "Service-Worker": "script" },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get("content-type")
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf("javascript") === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload()
          })
        })
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl)
      }
    })
    .catch(() => {
      console.log(
        "No internet connection found. App is running in offline mode."
      )
    })
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister()
      })
      .catch((error) => {
        console.error(error.message)
      })
  }
}
