import { getAPIPath, isAndroid, isWeb } from "./HelpingFunction"
import { getAuth, GoogleAuthProvider } from "firebase/auth"
import firebaseApp, { analytics } from "../Config/firebase"
import { logEvent } from "firebase/analytics"
import { getMessaging, getToken, isSupported } from "firebase/messaging"
import { getUserLang } from "./utiltyHelper"

export const uiConfig = {
  signInFlow: "popup",
  signInOptions: [
    new GoogleAuthProvider(),
    // firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    signInSuccess: () => {
      if (isHost()) {
        logEvent(analytics, "login")
      }
      // this.props.history.push({pathname: "/login"})
    },
  },
}

export function isAuth(authObj) {
  return (
    authObj !== null &&
    typeof authObj !== "undefined" &&
    authObj.uid !== "" &&
    authObj.uid
  )
}

export function logout() {
  const auth = getAuth(firebaseApp)
  auth
    .signOut()
    .then(function () {
      // Sign-out successful.
      window.location.reload(false)
      localStorage.setItem("look4kol-require-logout", false)
      localStorage.setItem("look4kol-auth", null)
      localStorage.setItem("look4kol-fb-code", "")
      localStorage.setItem("look4kol-fb-code-expiry", "")
    })
    .catch(function (error) {
      // An error happened
    })
}

export function returnIdTokenResult() {
  return new Promise((resolve, reject) => {
    const auth = getAuth(firebaseApp)
    if (auth.currentUser) {
      auth.currentUser.getIdTokenResult().then((idTokenResult) => {
        return resolve({
          success: true,
          email: auth.currentUser.email,
          uid: auth.currentUser.uid,
          photoURL: auth.currentUser.photoURL,
          displayName: auth.currentUser.displayName,
          token: idTokenResult.token,
          role: idTokenResult.claims.role,
          expirationTime: idTokenResult.expirationTime,
          custID: idTokenResult.claims.custID
            ? idTokenResult.claims.custID
            : null,
        })
      })
    } else {
      return resolve({ success: false })
    }
  })
}

export function isHost() {
  return window.location.host === "look4kol.com"
}

export function requestNotificationAccess(auth) {
  try {
    return new Promise(async (resolve, reject) => {
      if ((await isSupported()) || isAndroid()) {
        const messaging = getMessaging(firebaseApp)
        // Let's check whether notification permissions have already been granted
        // Also check if its android or not (Notification is not supported in android)
        if (isWeb() && Notification) {
          if (Notification.permission === "granted") {
            // If it's okay let's create a notification

            localStorage.setItem("look4kol-reject-gcm", "false")

            getToken(messaging, {
              vapidKey:
                "BALiUneYw0V4b-U5QvtpU5G2SvcgqlWsJnzZfXEdPDjcqmND01geOkjaoBn7WgZy6THFSjKiWVyZgVgfToBfcLY",
            })
              .then((currentToken) => {
                if (currentToken) {
                  if (
                    localStorage.getItem("look4kol-gcm-id" + "-" + auth.uid) !==
                    currentToken
                  ) {
                    localStorage.setItem(
                      "look4kol-gcm-id" + "-" + auth.uid,
                      currentToken
                    )
                    return new Promise(async (resolve, reject) => {
                      await fetch(getAPIPath("/api/gcm/addID"), {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                          uid: auth.uid,
                          id: currentToken,
                        }),
                      })
                        .then((res) => res.json())
                        .then((response) => {
                          return returnIdTokenResult(firebaseApp).then(
                            async (res) => {
                              await fetch(
                                getAPIPath("/api/gcm/" + auth.uid + "/test"),
                                {
                                  method: "POST",
                                  headers: {
                                    "Content-Type": "application/json",
                                    idToken: res.token,
                                  },
                                  body: JSON.stringify({
                                    token: [currentToken],
                                    isWeb: isWeb(),
                                  }),
                                }
                              )
                                .then((res) => res.json())
                                .then((finalRes) => {
                                  if (finalRes.success) {
                                    return resolve({ success: true })
                                  }
                                  return resolve({
                                    success: false,
                                    message: "msg not sent successfully",
                                  })
                                })
                                .catch((err) => {
                                  console.log(err)
                                  return resolve({
                                    success: false,
                                    message: err,
                                  })
                                })
                            }
                          )
                        })
                    })
                  } else {
                    returnIdTokenResult(firebaseApp).then(async (res) => {
                      await fetch(
                        getAPIPath("/api/gcm/" + auth.uid + "/test"),
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            idToken: res.token,
                          },
                          body: JSON.stringify({
                            token: [currentToken],
                            isWeb: isWeb(),
                          }),
                        }
                      )
                        .then((res) => res.json())
                        .then((response) => {
                          if (response.success) {
                            return resolve({ success: true })
                          }
                          return resolve({
                            success: false,
                            message: "msg not sent successfully",
                          })
                        })
                        .catch((err) => {
                          console.log(err)
                          return resolve({
                            success: false,
                            message: err,
                          })
                        })
                    })
                  }
                } else {
                  // Show permission request.
                  // Show permission UI.
                  resolve({ success: false })
                }
              })
              .catch((err) => console.log(err))
          }

          // Otherwise, we need to ask the user for permission
          else if (Notification.permission === "denied") {
            localStorage.removeItem("look4kol-gcm-id" + "-" + auth.uid)
            logEvent(analytics, "messaging_permission_rejected")
            return resolve({
              success: false,
              message: "request denied",
            })
          }
        } else {
          return returnIdTokenResult(firebaseApp).then(async (res) => {
            localStorage.setItem("firebase-auth" + "-" + auth.uid, res.token)

            window.location.replace("/" + getUserLang() + "/setting/sendMsg")
          })
        }
      } else {
        return resolve({
          success: false,
          message: "not support",
        })
      }
    })
  } catch (e) {
    console.log("error occurred in firebaseUtil!")
    console.log(e)
  }
}
