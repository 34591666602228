import React from "react"
import { Trans } from "react-i18next"
import { getUserLang } from "../../Utils/utiltyHelper"

const SignupTutorialModalComponent = () => {
  return (
    <div>
      <iframe
        className="mx-auto md:w-[450px] w-full rounded-xl my-6"
        height="315"
        src="https://www.youtube.com/embed/WAMXhfAUe1Q"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
      <p className="text-base text-gray-700 font-medium text-center">
        <Trans>SignUp.signup-tutorial-desc</Trans>
        <a
          href={
            getUserLang() === "en"
              ? "https://docs.look4kol.com/eng/kol-registration/why-look4kol-needs-you-to-connect-with-facebook"
              : "https://docs.look4kol.com/zh/kol-registration/why-look4kol-need-facebook-login"
          }
          target="_blank"
          className="text-primary font-semibold">
          <Trans>SignUp.click-here</Trans>
        </a>
      </p>
    </div>
  )
}
export default SignupTutorialModalComponent
