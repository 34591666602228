import React, { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import {
  faBookmark,
  faComment,
  faPaperPlane,
} from "@fortawesome/free-regular-svg-icons"
import {
  faCircleCheck,
  faEye,
  faUsers,
  faHandshake,
  faHeart,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { followersCount } from "../Utils/Converter"
import { Carousel } from "react-responsive-carousel"
import classNames from "classnames"
import { useSelector } from "react-redux"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import igIcon from "../Assets/instagram-brands.svg"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import caseStudyImage from "../Assets/case-placeholder.jpg"
import { returnThumbnailUrl } from "../Utils/utiltyHelper"
import { IgMediaType } from "../HelpingFunction/FBLogin/FBLoginHelper"

const CaseStudyCard: React.FC = (props: any) => {
  const { t, i18n } = useTranslation()
  const screenWidth = useSelector((state: any) => {
    return state.SystemManager.screenWidth
  })
  const [isVerified, setIsVerified] = useState<boolean>(false)

  useEffect(() => {
    checkVerified()
  }, [])
  const checkVerified = () => {
    let haveField = props.data.hasOwnProperty("verified")
    if (haveField) {
      if (props.data.verified) {
        setIsVerified(true)
      } else {
        setIsVerified(false)
      }
    } else {
      setIsVerified(false)
    }
  }

  return (
    <div
      className="inline-block mx-2 rounded-lg bg-white border border-gray-50 shadow px-3 pt-5 pb-2 text-gray-800 w-72 relative"
      onClick={() => {
        if (screenWidth < 768) {
          props.setCaseStudyModal({
            returnFn: () => null,
            metaData: {
              jobData: props.data,
            },
          })
        }
      }}>
      {isVerified && props.data.ig_media_type && (
        <div>
          {props.data.ig_media_type === IgMediaType.REEL && (
            <div className="bg-amber-200  rounded-full w-16 text-sm text-amber-500 text-center">
              <Trans>CaseStudyCard.reel</Trans>
            </div>
          )}
          {props.data.ig_media_type === IgMediaType.IMAGE && (
            <div className="bg-blue-200  rounded-full w-16 text-sm text-blue-500 text-center">
              <Trans>CaseStudyCard.image</Trans>
            </div>
          )}
          {props.data.ig_media_type === IgMediaType.CAROUSEL && (
            <div className="bg-purple-200  rounded-full w-20 text-sm text-purple-500 text-center">
              <Trans>CaseStudyCard.carousel</Trans>
            </div>
          )}
          {props.data.ig_media_type === IgMediaType.STORY && (
            <div className="bg-red-200  rounded-full w-20 text-sm text-red-500 text-center">
              <Trans>CaseStudyCard.story</Trans>
            </div>
          )}
        </div>
      )}

      <div className="grid grid-cols-5 mt-2 mb-4">
        <div className="col-span-4 text-left w-full">
          <h3 className="text-xl font-medium px-2 overflow-hidden overflow-ellipsis">
            {props.data.title}
          </h3>
        </div>
        {isVerified && (
          <div className="col-span-1 grid grid-cols-2 items-center  gap-2 w-12">
            <div className="col-span-1">
              <div
                className="tooltip tooltip-bottom"
                data-tip={t("EditCaseStudy.verified-post")}>
                <FontAwesomeIcon
                  className="bg-white rounded-full text-green-700 text-xl mt-1"
                  icon={faCircleCheck}
                />
              </div>
            </div>
            <div className="col-span-1 mt-0">
              <div
                className="tooltip tooltip-bottom cursor-pointer "
                data-tip={
                  props.data.ig_media_type !== IgMediaType.STORY
                    ? t("EditCaseStudy.view-post")
                    : t("CaseStudyCard.expire")
                }
                onClick={() => {
                  if (props.data.ig_media_type !== IgMediaType.STORY) {
                    window.open(props.data.ig_permalink)
                  }
                }}>
                <img className="w-6 h-6 " src={igIcon} />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="md:block hidden">
        {props.data.imageUrl.length > 0 ? (
          <Carousel
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            className="h-72 md:h-64  w-full rounded-xl invisible md:visible"
            showStatus={false}
            showThumbs={false}>
            {props.data.imageUrl.map((e: string, index: number) => {
              if (e !== "") {
                return (
                  <div key={index} className="w-full rounded-xl">
                    <img
                      alt="find kol"
                      className="rounded-xl object-cover lazyload h-72 md:h-64"
                      src={returnThumbnailUrl(e as string)}
                    />
                  </div>
                )
              }
              return <div></div>
            })}
          </Carousel>
        ) : (
          <div className="h-72 md:h-64  w-full rounded-xl invisible md:visible border">
            <img
              src={caseStudyImage}
              className="rounded-xl object-cover lazyload h-72 md:h-64"
            />
          </div>
        )}
      </div>
      <div className="grid grid-cols-2 gap-2 my-4 block md:hidden">
        <div
          className={classNames("w-full max-h-48", {
            block: props.data.imageUrl.length >= 0,
            "col-span-2": props.data.imageUrl.length < 2,
          })}>
          <picture className="cursor-pointer">
            <img
              loading="lazy"
              src={
                typeof props.data.imageUrl[0] !== "undefined"
                  ? props.data.imageUrl[0]
                  : caseStudyImage
              }
              alt={"website example hor"}
              className="lazyload w-full rounded-md h-48 object-cover"
            />
          </picture>
        </div>
        <div
          className={classNames("w-full max-h-48", {
            hidden: props.data.imageUrl.length < 2,
            block: props.data.imageUrl.length >= 2,
          })}>
          <picture className="cursor-pointer">
            {/*<source srcSet={props.data.coverImages[0]} type="image/webp" className="lazyload"/>*/}
            <img
              src={
                typeof props.data.imageUrl[1] !== "undefined"
                  ? props.data.imageUrl[1]
                  : ""
              }
              alt={"website example hor"}
              className="lazyload w-full rounded-md h-48 object-cover"
            />
          </picture>
        </div>
      </div>
      <div className="grid grid-cols-4 md:my-4 mb-10 md:mb-0 mx-4 gap-6">
        <div className="mx-auto text-center">
          <FontAwesomeIcon icon={faHeart} className="text-gray-600" />
          <p
            className={classNames("font-medium", {
              "text-base": props.caseLength <= 2,
              "text-xs": props.caseLength > 2,
            })}>
            {followersCount(props.data.ig_like)}
          </p>
        </div>
        <div className="mx-auto text-center">
          <FontAwesomeIcon icon={faComment} className="text-gray-600" />
          <p
            className={classNames("font-medium", {
              "text-base": props.caseLength <= 2,
              "text-xs": props.caseLength > 2,
            })}>
            {followersCount(props.data.ig_comment)}
          </p>
        </div>

        <div className="mx-auto text-center">
          <FontAwesomeIcon icon={faPaperPlane} className="text-gray-600" />
          <p
            className={classNames("font-medium", {
              "text-base": props.caseLength <= 2,
              "text-xs": props.caseLength > 2,
            })}>
            {followersCount(props.data.ig_share)}
          </p>
        </div>
        <div className="mx-auto text-center">
          <FontAwesomeIcon icon={faBookmark} className="text-gray-600" />
          <p
            className={classNames("font-medium", {
              "text-base": props.caseLength <= 2,
              "text-xs": props.caseLength > 2,
            })}>
            {followersCount(props.data.ig_save)}
          </p>
        </div>
      </div>
      <div className="w-full md:my-2 my-4">
        <button
          className="btn btn-info rounded-xl text-sm normal-case w-full py-2 px-4 hidden"
          onClick={() => {
            props.setCaseStudyModal({
              returnFn: () => console.log("hi"),
              metaData: {
                jobData: props.data,
              },
            })
          }}>
          <Trans>CaseStudyCard.learn-more</Trans>
        </button>
        <hr className="md:block hidden" />
        <div className="text-left py-2">
          <section className="text-sm font-medium text-gray-500 flex justify-between mx-2">
            <div>
              <span className="mr-2 ">
                <FontAwesomeIcon icon={faHandshake} />
              </span>
              <span className="ml-2 ">
                <Trans>CaseStudyCard.engagement</Trans>
              </span>
            </div>
            <span className="mr-2 text-base text-black">
              {props.data.ig_total_engagement}
            </span>
          </section>
          <section className="text-sm font-medium text-gray-500 my-2 flex justify-between mx-2">
            <div>
              <span className="w-6 mr-2 ">
                <FontAwesomeIcon icon={faEye} />
              </span>
              <span className="ml-2">
                <Trans>CaseStudyCard.clicks</Trans>
              </span>
            </div>
            <span className="mr-2 text-base text-black">
              {props.data.ig_total_action}
            </span>
          </section>
          <section className="text-sm font-medium text-gray-500 my-2 flex justify-between mx-2">
            <div>
              <span className="w-6 mr-2">
                <FontAwesomeIcon icon={faUsers} />
              </span>
              <span className="ml-2 ">
                <Trans>CaseStudyCard.reach</Trans>
              </span>
            </div>
            <span className="mr-2 text-base text-black">
              {props.data.ig_reach}
            </span>
          </section>
        </div>
      </div>
    </div>
  )
}
export default CaseStudyCard
