import { CHATROOM_TYPE } from "../Enum/APP_TYPE"
import { ChatMessage } from "./ChatMessage"
import { DateObj } from "./Utilities"

export interface ChatroomJob {
  id?: string
  adminUID: string
  createDate: DateObj | Date
  customTitle: string[]
  lastUpdate: DateObj | Date
  participateUID: string[]
  title: string
  avatarUrl?: string
  isLoadedAvatarAndTitle?: boolean
  type: CHATROOM_TYPE
}

export const ChatroomJobDefault: ChatroomJob = {
  id: "",
  adminUID: "",
  createDate: new Date(),
  customTitle: [],
  lastUpdate: new Date(),
  participateUID: [],
  title: "",
  avatarUrl: "",
  isLoadedAvatarAndTitle: false,
  type: CHATROOM_TYPE.DEFAULT,
}

export interface ChatroomWithLastMessage {
  id: string
  avatar: string
  adminUID: string
  createDate: DateObj
  customTitle: string[] | any
  lastUpdate: DateObj
  participateUID: string[]
  title: string
  type: CHATROOM_TYPE
  hasLastMessage: boolean
  lastMessage: ChatMessage
}

export interface ChatLastMessage {
  createDate: DateObj | Date
  createUserID: string
  msg: string
  type: string
}

export interface ChatLastMessageWithId {
  chatroomID: string
  lastMessage: ChatMessage | null
}

export type Chatroom = ChatroomJob | ChatroomWithLastMessage
