import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBriefcase,
  faFile,
  faFileCirclePlus,
  faFileContract,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import { getFilePath, SUPPORTED_FILE_TYPE } from "../../../Utils/FilePath"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../../Redux/SUPPORTED_REDUX_FUNCTION"
import { SUPPORTED_MODAL_TYPE } from "../../../Utils/ModalType"
import { useDispatch, useSelector } from "react-redux"
import KOL, { KOLDefault } from "../../../Model/KOL"
import { getKOL } from "../../../HelpingFunction/KOL/kolDBHelper"
import { Trans } from "react-i18next"
import { ChatMessage } from "../../../Model/ChatMessage"
import { CHATROOM_TYPE } from "../../../Enum/APP_TYPE"
import { CHATGPT_HELPER_TYPE } from "../../../Redux/Reducer/ChatGPTManager"
import ChatGPTToolsBox from "./ChatGPTToolsBox"
import classNames from "classnames"

enum ChatNewItemType {
  FILE,
  CONTRACT,
}

interface propsState {
  sendMessage: (msg: ChatMessage) => void
  agencyID: string | null
}

const NewItemSelector: React.FC<propsState> = (props: any) => {
  const dispatch = useDispatch()

  const chatroomInfo = useSelector((state: any) => {
    return state.ChatRoomInfo.chatroomInfo
  })

  const screenHeight = useSelector((state: any) => {
    return state.SystemManager.screenHeight
  })

  const [type, setType] = useState<ChatNewItemType | "">("")
  const [kolProfile, setKolProfile] = useState<KOL>(KOLDefault)
  const [isLoadedKOLProfile, setIsLoadedKOLProfile] = useState<boolean>(false)

  useEffect(() => {
    //chatroomInfo.participateUID[1] is the kol id
    getKOL(chatroomInfo.participateUID[1]).then((result) => {
      if (result.success) {
        setKolProfile(result.data)
        setIsLoadedKOLProfile(true)
      }
    })
  }, [chatroomInfo])

  const confirmContractCreateModal = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.CREATE_NEW_CONTRACT_MODAL,
      returnFn: state.returnFn,
      metaData: state.metaData,
    })
  }

  const setUploadFileModal = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.TOGGLE_MODAL,
      typeOfModal: SUPPORTED_MODAL_TYPE.UPLOAD_FILE_MODAL,
      returnFn: state.returnFn,
      metaData: state.metaData,
      uploadPath: state.uploadPath,
      typeLimit: state.typeLimit,
      limit: state.limit,
    })
  }

  const returnUploadedImage = (metaData: any, files: any) => {
    if (Array.isArray(files)) {
      // addChatAttachmentRecord(props.chatroomID, files)
      files.map((file) => {
        let newMsg: any = {
          type: file.type,
          fileName: file.fileName,
          fileSize: file.fileSize,
          url: file.url,
        }
        if (file.type === "image") {
          newMsg.thumbnailUrl = file.thumbnailUrl
        }
        props.sendMessage(newMsg)
      })
    }
  }

  const isChatGPTChatroom = chatroomInfo.type === CHATROOM_TYPE.CHATGPT

  if (isChatGPTChatroom) {
    return (
      <div
        id="itemSelector"
        className="block w-full   p-2 mb-2 md:mb-4"
        style={{ height: screenHeight * 0.75 - 60 }}>
        <ChatGPTToolsBox />
      </div>
    )
  }
  return (
    <div className="  block w-full  p-2 mb-2 md:mb-4">
      {type === "" && (
        <div className="grid grid-cols-3 gap-4 p-6">
          <div className="text-center">
            <button
              className={classNames(
                "block btn btn-circle btn-primary h-16 w-16 mx-auto"
              )}
              onClick={() => {
                setUploadFileModal({
                  returnFn: returnUploadedImage,
                  //1 or greater than 1
                  limit: 10,
                  uploadPath: getFilePath(
                    SUPPORTED_FILE_TYPE.UPLOAD_CHAT_ATTACHMENT,
                    props.authID,
                    chatroomInfo.id
                  ),
                  typeLimit: "*",
                  metaData: { agencyID: props.agencyID },
                })
              }}>
              <FontAwesomeIcon icon={faFileCirclePlus} className="text-2xl" />
            </button>
            <div className="block text-gray-600 mt-2 text-sm">
              <Trans>NewItemSelector.add-file</Trans>
            </div>
          </div>

          <div className="text-center">
            <button
              className={classNames(
                "block btn btn-circle btn-primary h-16 w-16 mx-auto"
              )}
              onClick={() => {
                if (isLoadedKOLProfile) {
                  confirmContractCreateModal({
                    returnFn: () => console.log("done"),
                    metaData: {
                      kolProfile: kolProfile,
                      agencyID: props.agencyID,
                    },
                  })
                }
              }}>
              <FontAwesomeIcon icon={faBriefcase} className="text-2xl" />
            </button>
            <div className="block text-gray-600 mt-2 text-sm">
              <Trans>NewItemSelector.new-contract</Trans>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default NewItemSelector
