import React, { useEffect } from "react"
import ContractDraftKOL from "../../page-components/c/contract/ContractDraftKOL"
import Contract from "../../page-components/c/contract/Contract"
import { useDispatch } from "react-redux"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import CustomAlert from "../NewAlert"
import { ALERT_TYPE, BUTTON_TYPE } from "../../Enum/ALERT_SYSYEM"
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons"

const CreateDraftContractModal = (props: any) => {
  const dispatch = useDispatch()

  useEffect(() => {
    emptyReduxJob()
  }, [])

  const emptyReduxJob = () => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.EMPTY_CHATROOM_JOB,
    })
  }

  return (
    <div>
      <CustomAlert
        alertType={ALERT_TYPE.INFO}
        buttonType={{ type: BUTTON_TYPE.NONE }}
        title="DraftContractModal.alert"
        icon={faCircleExclamation}
      />
      <ContractDraftKOL closeModal={props.closeModal} />
    </div>
  )
}

export default CreateDraftContractModal
