export enum LOGIN_PAGE_TYPE {
  RESET_PASSWORD,
  LOGIN,
  KOL,
  CUSTOMER,
  REQUIRE_LOGIN,
  REQUIRE_LOGIN_ORG,
}

export type REDIRECT_OBJECT_TYPE =
  | ""
  | {
      requiredRedirect: boolean
      path: string
      registerType?: LOGIN_PAGE_TYPE
    }

export interface LOGIN_CREDENTIALS_TYPE {
  email: string
  password: string
}

export type IG_USER_INSIGHTS_DATA = {
  lastUpdate: Date
  audience_city: object | string[]
  audience_country: object | string[]
  audience_gender_age: object | string[]
  audience_locale: object | string[]
}

export type IG_USER_NORMAL_DATA = {
  id: string
  ig_id: string
  username: string
  biography: string
  numOfPosts: number
  numOfFollowers: number
  numOfFollowing: number
  avatar_url: string
}

// data come from look4kol crawler
export type IG_USER_CRAWLER_DATA = {
  engagementRate: number
  imagesList: string[]
  lastUpdate: Date
  nextPage?: string
}

//registration and look4kol crawler data
export type IG_USER_PROFILE_DATA = IG_USER_INSIGHTS_DATA &
  IG_USER_NORMAL_DATA &
  IG_USER_CRAWLER_DATA

export const igUserDataDefault: IG_USER_DATA = {
  lastUpdate: new Date(),
  audience_city: [],
  audience_country: [],
  audience_gender_age: [],
  audience_locale: [],
  id: "",
  ig_id: "",
  username: "",
  biography: "",
  numOfPosts: 0,
  numOfFollowers: 0,
  numOfFollowing: 0,
  avatar_url: "",
  imagesList: [],
  engagementRate: 0,
}

//only used in registration
export type IG_USER_DATA = IG_USER_INSIGHTS_DATA &
  IG_USER_NORMAL_DATA &
  IG_USER_CRAWLER_DATA

export interface NEW_KOL {
  uid: string
  email: string
  avatarUrl: string
  nationality: string
  userName: string
  firstName: string
  lastName: string
  description: string
  type: string[]
  platform: string[]
  coverImages: string[]
  referralUser: string
}

export interface NEW_CUSTOMER {
  uid?: string
  contactEmail: string
  avatarUrl: string
  companyName: string
  companySize: string
  roleInCompany: string
  companyType: string | ""
  firstName: string
  lastName: string
  contactNumber: string
  objectID?: string
  website?: string
  description?: string
}
