import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faCheck,
  faShare,
  faUser as faUserAfter,
  faChartBar as faChartBarAfter,
  faImage as faImageAfter,
  faPen,
  faHeart,
  faUsers,
} from "@fortawesome/free-solid-svg-icons"
import {
  faUser,
  faChartBar,
  faImage,
} from "@fortawesome/free-regular-svg-icons"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { useHistory, useParams } from "react-router-dom"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import logo from "../../Assets/icons-72.png"
import { Trans, useTranslation } from "react-i18next"
import { SUPPORTED_REDUX_FUNCTIONS } from "../../Redux/SUPPORTED_REDUX_FUNCTION"
import { isKOLBookmarked } from "../../HelpingFunction/Member/memberDBHelper"
import { getUserLang, toggleShare } from "../../Utils/utiltyHelper"
import { isAuth } from "../../Utils/FirebaseUtils"
import { BadgeLinkButton } from "../BadgeLinkButton"
import { HelpDocHeader } from "./HelpDocHeader/HelpDocHeader"
import {
  KOL_PROFILE_HEADER,
  LINKTREE_KOL,
  PROFILE_KOL_HOW_TO_ATTRACT,
  PROFILE_KOL_HOW_TO_EDIT,
} from "../../Utils/HelpButtonHelperFunction"

export const KOLProfileHeader: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const params = useParams<{
    lang: "hk" | "en"
    id: string
    type: string
  }>()
  let history = useHistory()

  const [isShare, setIsShare] = useState(false)

  const setMemberBookmark = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.BOOK_MARK_KOL,
      uid: state.uid,
      kolID: state.kolID,
    })
  }
  const setSearchPageRoute = (isSearchPage: boolean) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_SEARCH_PAGE_ROUTE,
      data: isSearchPage,
    })
  }

  const setKOLInfo = (state: any) => {
    dispatch({
      type: SUPPORTED_REDUX_FUNCTIONS.SET_KOL_INFO,
      kolInfo: state,
    })
  }
  const kolInfo = useSelector((state: any) => {
    return state.KOLInfoManager.kolInfo
  })

  const auth = useSelector((state: any) => {
    return state.firebase.auth
  })

  const fromSearchPage = useSelector((state: any) => {
    return state.KOLInfoManager.fromSearchPage
  })

  const [isBookmarked, setIsBookmarked] = useState<boolean>(false)

  useEffect(() => {
    if (isAuth(auth)) {
      checkMember()
    }
  }, [auth])

  useEffect(() => {
    setKOLInfo({
      id: kolInfo.id,
      avatarUrl: kolInfo.avatarUrl,
      userName: kolInfo.userName,
      isBookmarked: isBookmarked,
    })
  }, [isBookmarked])

  const checkMember = async () => {
    await isKOLBookmarked(auth.uid, kolInfo.id).then((res) => {
      if (res.success) {
        setIsBookmarked(res.bookmarked)
      } else {
        setIsBookmarked(false)
      }
    })
  }

  const shareKOL = async () => {
    toggleShare({
      title: t("ShareModal.share-title"),
      text:
        t("ShareModal.share-mobile-title") +
        " " +
        kolInfo.userName +
        t("ShareModal.share-mobile-desc"),
      url: window.location.href,
    }).then((result) => {
      setIsShare(result.success)
    })
  }

  const KOLProfileLink: {
    visible?: boolean
    icon: IconDefinition
    iconAfter: IconDefinition
    i18Text: string
    path: string
    color?: string
  }[] = [
    {
      icon: faUser,
      iconAfter: faUserAfter,
      i18Text: t("KOLProfile.profile"),
      path: "p/" + kolInfo.userName,
      visible: true,
    },
    {
      icon: faChartBar,
      iconAfter: faChartBarAfter,
      i18Text: t("KOLProfile.followers-data"),
      path: "p/" + kolInfo.userName + "?type=stat",
      visible: true,
    },
    {
      icon: faImage,
      iconAfter: faImageAfter,
      i18Text: t("KOLProfile.pricing-case-study"),
      path: "p/" + kolInfo.userName + "?type=case",
      visible: true,
    },
    {
      icon: faUsers,
      iconAfter: faImageAfter,
      i18Text: t("KOLProfile.recommend"),
      path: "p/" + kolInfo.userName + "?type=similar",
      visible: true,
    },
    // {
    //   icon: faUser,
    //   iconAfter: faUserAfter,
    //   i18Text: t("KOLProfile.recommend"),
    //   path: "p/" + kolInfo.userName + "?type=recommend",
    //   visible: true,
    // },
    // {
    //   icon: faStar,
    //   iconAfter: faStarAfter,
    //   i18Text: t("KOLProfile.review"),
    //   path: "p/" + kolInfo.id + "/review",
    //   visible: true,
    // },
  ]

  return (
    <div className="shadow-md rounded-b-md">
      {/* KOL Header*/}
      <div
        className="h-18 my-auto w-full py-2 px-4 grid gap-4 "
        style={{
          height: "60px",
          gridTemplateColumns: "40px 30px auto auto",
        }}>
        <div>
          <button
            className="my-auto h-full text-left my-2 mx-2 text-gray-600 font-bold text-xl"
            onClick={() => {
              if (fromSearchPage) {
                history.push("/" + getUserLang() + "/s")
                setSearchPageRoute(false)
              } else {
                history.push("/" + getUserLang())
                setSearchPageRoute(false)
              }
            }}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        </div>
        <div className="my-auto h-8 w-8 bg-red-200 rounded-full flex flex-shrink-0 justify-center items-center text-red-900 text-2xl font-mono">
          <img
            className="rounded-full object-cover w-full h-full"
            src={
              typeof kolInfo.avatarUrl !== "undefined" &&
              kolInfo.avatarUrl !== ""
                ? kolInfo.avatarUrl
                : logo
            }
          />
        </div>
        <div className="my-auto overflow-ellipsis w-full text-lg font-medium">
          {kolInfo.userName}
        </div>

        {isAuth(auth) && auth.uid !== params.id ? (
          <div className="my-auto flex flex-row-reverse">
            <button
              className={[
                "inline-block text-center rounded-full p-2 mx-1 w-10 h-10",
                isBookmarked
                  ? "bg-red-50 text-red-400 hover:bg-red-100"
                  : "text-gray-600 hover:bg-gray-100",
              ].join(" ")}
              onClick={() => {
                if (isAuth(auth)) {
                  if (isBookmarked) {
                    setIsBookmarked(false)
                    setMemberBookmark({
                      uid: auth.uid,
                      kolID: kolInfo.id,
                    })
                  } else {
                    setIsBookmarked(true)
                    setMemberBookmark({
                      uid: auth.uid,
                      kolID: kolInfo.id,
                    })
                  }
                } else {
                  history.push("/" + getUserLang() + "/login")
                }
              }}>
              <FontAwesomeIcon icon={faHeart} className="justify-center" />
            </button>
            <button
              className="inline-block text-center  text-gray-600 hover:bg-gray-100 rounded-full p-2 mx-1 w-10 h-10"
              onClick={() => shareKOL()}>
              {isShare ? (
                <FontAwesomeIcon icon={faCheck} className="justify-center" />
              ) : (
                <FontAwesomeIcon icon={faShare} className="justify-center" />
              )}
            </button>
          </div>
        ) : (
          isAuth(auth) &&
          auth.uid === params.id && (
            <div>
              <div className="flex justify-end">
                <HelpDocHeader helpDocs={KOL_PROFILE_HEADER} />
                {params.type !== "edit" && (
                  <button
                    className="btn btn-sm h-10 my-auto btn-primary rounded-2xl gap-2"
                    onClick={() =>
                      history.push(
                        "/" + getUserLang() + "/p/" + auth.uid + "/edit"
                      )
                    }>
                    <FontAwesomeIcon icon={faPen} className="mr-2" />
                    <div>
                      <Trans>KOLProfile.edit</Trans>
                    </div>
                  </button>
                )}
              </div>
            </div>
          )
        )}
      </div>
      {/* KOL Function*/}
      <div className="pt-1 pb-2 px-2 overflow-x-scroll whitespace-nowrap ">
        {Array.isArray(KOLProfileLink) &&
          KOLProfileLink.map((item, key) => {
            return (
              <BadgeLinkButton
                key={"item" + key}
                path={item.path}
                i18Text={item.i18Text}
                icon={item.icon}
                color={item.color}
                visible={item.visible}
              />
            )
          })}
      </div>
    </div>
  )
}
