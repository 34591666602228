import React, { useEffect, useState } from "react"
import { KOLCombineWithAvatar } from "../../Model/KOLCombine"
import { Trans } from "react-i18next"

const SendAllContractsModalComponent = (props: {
  metaData: KOLCombineWithAvatar[]
}) => {
  const selectedKOLs: KOLCombineWithAvatar[] = props.metaData

  console.log("selected", selectedKOLs)
  return (
    <div className="container">
      <div className="text-gray-500">
        <p>
          <Trans>SendAllContractsModal.des</Trans>
        </p>
      </div>
      <h2 className="my-4 font-semibold">
        <Trans>SendAllContractsModal.list-of-kols</Trans>
      </h2>
      <section className="px-4 mt-8 overflow-y-auto">
        {selectedKOLs.map((kol) => {
          return (
            <div key={kol.userName}>
              <div className="flex flex-row gap-2 my-2">
                <img className="rounded-full w-6 h-6" src={kol.avatarUrl} />
                <p>{kol.userName}</p>
              </div>
              <hr className="w-48" />
            </div>
          )
        })}
      </section>
    </div>
  )
}

export default SendAllContractsModalComponent
